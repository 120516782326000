import services from './index.jsx';
import httpcommon from "./httpcommon"
class Doctors {
    getDoctorsSliderDetails() {
        return services.get("doctors")
    };

    getAllSpecialities() {
        return httpcommon.post("/getAllSpecialities");
    }
    getDoctorslistByCoOrd() {
        return services.get(`${process.env.REACT_APP_ELASTIC_BASEURL}doctors?freeTextSearch=&pageNo=1&pageSize=100&latitude=20.2961&longitude=85.8245`);
        // return services.get("https://patient-api-stage.curebay.in/es/api/v1/doctors?pageNo=1&pageSize=100&latitude=8.6130176&longitude=77.2308992");
    };
    getDoctorsData() {
        return services.get("/doctors-pages?populate=deep");
    };

}
export default new Doctors();