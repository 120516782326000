import services from "./index";
import httpcommon from "./httpcommon";

class CarePlans {

    gethelthCareServices() {
        return services.get("/health-package-our-service-details")
    }

    getHowItWorks() {
        return services.get('/heart-package-how-it-works')
    }

    exploreHealth() {
        return services.get('/health-package-explore-care-plans')
    }
    compareAllPlan(CareplanId) {
        return httpcommon.post('/careplans/compareAllPlans', { id: CareplanId })
    }
    getBenifits() {
        return services.get('/healthcare-benfits')
    }

    getWhyPlansWorks() {
        return services.get('/why-our-care-plans-works?populate=*')
    }
    getHealthSol() {
        return services.get('/one-stop-health-solns?populate=*')
    }
    getHealthPlan() {
        return services.get('/heath-packs?populate=deep')
    }
    getHeartHealth() {
        return services.get('/health-packages/1/?populate=deep')
    }
    getDiabeticsCare() {
        return services.get('/health-packages/3/?populate=deep')
    }
    getGeneraalHealth() {
        return services.get('/health-packages/5/?populate=deep')
    }
    getOrthopediCare() {
        return services.get('/health-packages/6/?populate=deep')
    }
    getWomensHealthCare() {
        return services.get('/health-packages/8/?populate=deep')
    }
    getCarePlan() {
        return services.get('/care-plan-pages?populate=deep')
    }


}

export default new CarePlans();