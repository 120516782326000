import React from "react";
import { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MyprofileSidemenu from "../homepage/myprofile-sidemenu";
// import Profileimg from "../../images/profile.jpg";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import mydetailsservice from "../../store/services/mydetailsservice";
import { getmyList } from "../../store/actions/mydetails";
import { checkIsLoggedIn } from "../../store/actions/useractions";
import { useSnackbar } from "../common/snackbar";
import { useSnackbarerror } from "../common/snackbarerror";
// import { FormHelperText, MenuItem, Select, TextField } from "@mui/material";
import { FormHelperText, MenuItem, TextField } from "@mui/material";
import camera from "../../assets/images/camera.svg";
import userImage from "../../assets/images/userImage.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CalculateAge, formatDateToDDMMYYYY, shouldShowError } from "../common";

import { fetchEClinics } from "../../store/actions/location";
import { fetchUserList } from "../../store/actions/dashboard";
import styled from "@emotion/styled";
import FooterMobileVersion from "../../components/homepage/FooterMobileVersion";
import MobileFooter from "./../homepage/mobileFooter";
import Footer from "../../components/webflow/postloginfooter";
import {
  fetchBloodGroupDetails,
  fetchCitiesByStates,
  fetchCountryDetails,
  fetchStatesByCountries,
} from "../../store/actions/myprofile";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import PhoneInput from "react-phone-input-2";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { fetchConsent, consentData } from "../../store/actions/login";
// import {
//   Dialog,
//   DialogActions,
//   DialogContent,
//   DialogTitle,
// } from "@mui/material";
import Tab from "@mui/material/Tab";
import axios from "axios";
import { updateUserData } from "../../store/actions/useractions";
import { TabView, TabPanel } from "primereact/tabview";
import { Dialog } from "primereact/dialog";
import dashboardservice from "../../store/services/dashboardservice";
import http from "../../store/services/httpcommon";

const YesNo = ({ onYesChange, allowedToSeeLabRep, disabled }) => {
  const [yes, setYes] = useState(false);
  console.log(allowedToSeeLabRep, "ugyui");
  useEffect(() => {
    if (allowedToSeeLabRep == 1) {
      setYes(true);
    } else {
      setYes(false);
    }
  }, [allowedToSeeLabRep]);
  const handleClick = (value) => {
    setYes(value);
    onYesChange(value);
  };
  return (
    <div className="flex gap-5 justify-center sm:justify-start ">
      <label>
        <input
          type="radio"
          disabled={!disabled}
          className="mr-10 "
          checked={yes}
          // className={`${yes
          //   ? "bg-chathams-blue-200 text-white "
          //   : "hover:text-white  hover:bg-chathams-blue-200 text-chathams-blue-200 "
          //   }border-chathams-blue-200 border-2 rounded-md  font-semibold w-20`}
          // style={{ border: "1px solid #396DDD" }}
          onClick={() => handleClick(true)}
        ></input>
        Yes
      </label>
      <label>
        <input
          type="radio"
          disabled={!disabled}
          className="mr-10"
          checked={!yes}
          // className={`${!yes
          //   ? " bg-chathams-blue-200 text-white   "
          //   : "hover:text-white  hover:bg-chathams-blue-200 text-chathams-blue-200  "
          //   }  rounded-md  font-semibold w-20`}

          onClick={() => handleClick(false)}
          style={{ border: "1px solid #396DDD" }}
        />
        No
      </label>
    </div>
  );
};

const YesNo2 = ({ onYesChange, allowedToSeePrescrp, disabled }) => {
  const [yes, setYes] = useState(false);
  console.log(allowedToSeePrescrp, "ugyui");
  useEffect(() => {
    if (allowedToSeePrescrp == 1) {
      setYes(true);
    } else {
      setYes(false);
    }
  }, [allowedToSeePrescrp]);
  const handleClick = (value) => {
    setYes(value);
    onYesChange(value);
  };
  return (
    <div className="flex gap-5 justify-center sm:justify-start ">
      <label>
        <input
          type="radio"
          disabled={!disabled}
          className="mr-10 "
          checked={yes}
          // className={`${yes
          //   ? "bg-chathams-blue-200 text-white "
          //   : "hover:text-white  hover:bg-chathams-blue-200 text-chathams-blue-200 "
          //   }border-chathams-blue-200 border-2 rounded-md  font-semibold w-20`}
          // style={{ border: "1px solid #396DDD" }}
          onClick={() => handleClick(true)}
        ></input>
        Yes
      </label>
      <label>
        <input
          type="radio"
          disabled={!disabled}
          className="mr-10"
          checked={!yes}
          // className={`${!yes
          //   ? " bg-chathams-blue-200 text-white   "
          //   : "hover:text-white  hover:bg-chathams-blue-200 text-chathams-blue-200  "
          //   }  rounded-md  font-semibold w-20`}

          onClick={() => handleClick(false)}
          style={{ border: "1px solid #396DDD" }}
        />
        No
      </label>
    </div>
  );
};

function Mydetails() {
  const navigate = useNavigate();
  const [bloodGroups, setBloodGroups] = useState([]);
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
    ? userObj?.parentCode
    : null;
  const isParentLoggedIn = userObj?.parentCode ? true : false;
  const [disableFields, setdisableFields] = useState(false);
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const { snackbarerrorMessage, setSnackbarerrorMessage } = useSnackbarerror();
  const [PhotoValue, setPhotoValue] = useState("");
  const [selectedPhoto, setselectedPhoto] = useState("");
  const [eclinic, setEclinic] = useState(null);
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [userList, setUserList] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [mobileno, setMobileno] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [users, setUserLists] = useState([]);
  const [emergencyContact, setEmergencyContact] = useState();
  const [wpmobileno, setWpMobileno] = useState();
  const [dialcodeMobile, setDialcodeMobile] = useState();
  const [dialcodeWpMobile, setDialcodeWpMobile] = useState();
  const [screen, setscreen] = useState(window.innerWidth);
  const [patientData, setpatientData] = useState({
    // firstName: '', lastName: '', parentCode: '', salutation: '', gender: '', mobile: '', email: '', dob: '', bloodGroup: '', address1: '', address2: '', pinCode: '', state: '', city: ''
  });
  console.log(patientData, "patientData");
  const [eClinicsData, seEclinicsData] = useState([]);
  const [consentopennew, setConsentpennew] = useState(false);
  const [consentdata, setConsentdata] = useState();
  const [value, setValue] = React.useState("1");
  const [privacyPolicyConsentcompany, setprivacyPolicyConsentcompany] =
    useState("");
  const [termsAndConditionConsentcompany, settermsAndConditionConsentcompany] =
    useState("");
  const [tcPdf, setTcPdf] = useState("");
  const [privacyPdf, setPrivacyPdf] = useState("");

  const [yesValue1, setYesValue1] = useState();
  const handleYesChange1 = (newValue) => {
    setYesValue1(newValue);
  };
  const [yesValue, setYesValue] = useState();
  const handleYesChange = (newValue) => {
    setYesValue(newValue);
  };
  const [allowedToSeePrescrp, setAllowedToSeePrescp] = useState(true);
  const [allowedToSeeLabRep, setAllowedToSeeLabPrep] = useState(true);

  const [consent, setConsent] = useState();
  useEffect(() => {
    async function fetchConsentForm() {
      const res = await fetchConsent();
      if (res.status === 200 && res?.data) {
        setConsent(res?.data?.data[0]);
      }
    }
    fetchConsentForm();
  }, []);
  const getUserLists = async (userCode) => {
    const reqObj = {
      code: userCode,
      email: userObj?.email,
      mobile: userObj?.mobile,
    };
    const res = await dashboardservice.getUserList(reqObj);
    setUserLists(res?.data?.succesObject);
    setAllowedToSeePrescp(res?.data?.succesObject[0]?.consentDrRemark);
    setAllowedToSeeLabPrep(res?.data?.succesObject[0]?.consentMedReport);
    if (res?.data?.succesObject[0]?.consentMedReport == 1) {
      setYesValue(true);
    } else {
      setYesValue(false);
    }
    if (res?.data?.succesObject[0]?.consentDrRemark == 1) {
      setYesValue1(true);
    } else {
      setYesValue1(false);
    }
  };

  useEffect(() => {
    getUserLists(userCode);
  }, []);

  // useEffect(() => {
  //   console.log(yesValue1, yesValue, allowedToSeeLabRep, allowedToSeePrescrp, "sdfgh")

  //   if (allowedToSeeLabRep == 1) {
  //     setYesValue(true)
  //   } else {
  //     setYesValue(false)
  //   }
  //   if (allowedToSeePrescrp == 1) {
  //     setYesValue1(true)
  //   } else {
  //     setYesValue1(false)
  //   }
  // }, []);

  const getVersion = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=PATIENT`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(
        data?.data[0]?.versionNumber,
        data?.data[1]?.versionNumber,
        "ddffgghh"
      );
      setprivacyPolicyConsentcompany(data?.data[0]?.versionNumber);
      settermsAndConditionConsentcompany(data?.data[1]?.versionNumber);
      setTcPdf(data?.data[1]?.consentFile);
      setPrivacyPdf(data?.data[0]?.consentFile);
    } catch (e) {
      console.log("Error fetching data:", e);
    }
  };

  useEffect(() => {
    getVersion();
  }, []);

  useEffect(() => {
    async function fetchConsentForm() {
      const res = await fetchConsent();
      if (res.status === 200 && res?.data) {
        setConsentdata(res?.data?.data[0]);
      }
    }
    fetchConsentForm();
  }, []);
  const handleChangenew = (event, newValue) => {
    setValue(newValue);
  };
  const handleCloseconsent = () => {
    setConsentpennew(false);
  };
  const handleAgree = () => {
    setConsentpennew(false);
    consentformversion();
    setIsChecked(true);
  };
  const handleDisagree = () => {
    setConsentpennew(false);
    withdrawconsentformversion();
    setIsChecked(false);
  };
  const consentformversion = async () => {
    if (!isParentLoggedIn) {
      const payload = {
        createdBy: "CUREBAYADMIN",
        privacyPolicyConsent: 1,
        sourceChannel: "Totalcare",
        status: 1,
        termsAndConditionConsent: 1,
        type: "U",
        userCode: userObj?.userCode,
        privacyVersionNumber: privacyPolicyConsentcompany,
        tcVersionNumber: termsAndConditionConsentcompany,
      };
      const postResponse = await http.post(
        `${process.env.REACT_APP_MEDICARE_BASEURL}userConsent/save`,
        payload
      );
      console.log(postResponse?.data?.responseMessage, "postResponse");
      if (postResponse.status === 200) {
        setSnackbarMessage(postResponse?.data?.responseMessage, "success");
      }
    } else {
      const payload = {
        createdBy: "CUREBAYADMIN",
        privacyPolicyConsent: 1,
        sourceChannel: "Totalcare",
        status: 1,
        termsAndConditionConsent: 1,
        type: "P",
        patientId: userObj?.code,
        privacyVersionNumber: privacyPolicyConsentcompany,
        tcVersionNumber: termsAndConditionConsentcompany,
      };

      console.log("Payload:", payload);
      const postResponse = await http.post(
        `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
        payload
      );

      console.log("POST Request Response for test", postResponse);
      if (postResponse.status === 200) {
        setSnackbarMessage("Consent Save Successfully.", "success");
      }
    }
  };

  const withdrawconsentformversion = async () => {
    if (!isParentLoggedIn) {
      const payload = {
        createdBy: "CUREBAYADMIN",
        privacyPolicyConsent: 0,
        sourceChannel: "Totalcare",
        status: 1,
        termsAndConditionConsent: 0,
        type: "U",
        userCode: userObj?.userCode,
        privacyVersionNumber: privacyPolicyConsentcompany,
        tcVersionNumber: termsAndConditionConsentcompany,
      };
      const postResponse = await http.post(
        `${process.env.REACT_APP_MEDICARE_BASEURL}userConsent/save`,
        payload
      );
      if (postResponse.status === 200) {
        logout();
      }
    } else {
      const payload = {
        createdBy: "CUREBAYADMIN",
        privacyPolicyConsent: 0,
        sourceChannel: "Totalcare",
        status: 1,
        termsAndConditionConsent: 0,
        type: "P",
        patientId: userObj?.code,
        privacyVersionNumber: privacyPolicyConsentcompany,
        tcVersionNumber: termsAndConditionConsentcompany,
      };

      console.log("Payload:", payload);
      const postResponse = await http.post(
        `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
        payload
      );

      console.log("POST Request Response for test", postResponse);
      if (postResponse.status === 200) {
        logout();
      }
    }
  };
  const logout = () => {
    dispatch(updateUserData({}));
    localStorage.clear();
    navigate(APP_ROUTES.DASHBOARD);
    if (
      typeof window !== "undefined" &&
      typeof window.location !== "undefined"
    ) {
      window.location.reload();
    }
  };
  useEffect(() => {
    async function fetchData() {
      const bloodGroupDetailsResponse = await fetchBloodGroupDetails();
      if (
        bloodGroupDetailsResponse?.status === 200 &&
        bloodGroupDetailsResponse?.data?.succesObject
      ) {
        setBloodGroups(bloodGroupDetailsResponse.data.succesObject);
      }
      const countryDetailsResponse = await fetchCountryDetails();
      if (
        countryDetailsResponse?.status === 200 &&
        countryDetailsResponse?.data?.succesObject
      ) {
        setCountries(countryDetailsResponse.data.succesObject);
      }
    }
    fetchData();
  }, []);

  console.log(
    yesValue1,
    yesValue,
    allowedToSeeLabRep,
    allowedToSeePrescrp,
    "sdfgh"
  );

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    console.log(countries, "hgvhjbj");
    async function fetchData() {
      if (patientData?.country) {
        let payload = {
          countrycode: patientData?.country,
        };
        const statesDetailsResponse = await fetchStatesByCountries(payload);
        if (
          statesDetailsResponse?.status === 200 &&
          statesDetailsResponse?.data?.succesObject
        ) {
          setStates(statesDetailsResponse.data.succesObject);
          setCities([]);
        }
      }

      if (patientData?.state) {
        let payload = {
          countrycode: patientData?.country,
          statecode: patientData?.state,
        };
        const citiesDetailsResponse = await fetchCitiesByStates(payload);
        if (
          citiesDetailsResponse?.status === 200 &&
          citiesDetailsResponse?.data?.succesObject
        ) {
          setCities(citiesDetailsResponse.data.succesObject);
        }
      }
    }

    fetchData();
  }, [patientData?.country, patientData?.state]);

  useEffect(() => {
    async function fetchData() {
      const eClinicsResponse = await fetchEClinics();
      if (
        eClinicsResponse?.status === 200 &&
        eClinicsResponse?.data?.succesObject
      ) {
        seEclinicsData(eClinicsResponse.data.succesObject);
      }
    }
    fetchData();
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    getActivedetails();
    console.log(userCode, "result");
  }, []);

  useEffect(() => {
    async function fetchUserDetails() {
      const user = await fetchUserList({
        code: userCode,
        email: userObj?.email,
        mobile: userObj?.mobile,
      });

      if (user.status === 200 && user?.data) {
        const temp = user?.data?.succesObject?.filter(
          (p) => Object.keys(p).length !== 0
        );
        setUserList(temp);
      }
    }
    fetchUserDetails();
  }, []);

  const [phone, setPhone] = useState("");
  // dispatch(getmyList(payload));
  const handleChangemob = (e, countryData) => {
    if (countryData) {
      const name = "ContactNumber";
      const { dialCode, format } = countryData;

      setpatientData((patientData) => ({
        ...patientData,
        [name]: {
          countryCode: format.charAt(0) + dialCode,
          mobile: e.slice(dialCode.length).trim(),
        },
      }));
      setPhone(e.slice(dialCode.length).trim());
    }
  };

  const handlePhoneChange = (newPhone, countryData) => {
    const dialCode = `+${countryData.dialCode}`;

    const formattedPhone = `+${newPhone}`;
    setMobileno(formattedPhone);
    setDialcodeMobile(dialCode);
  };

  const handleWpPhoneChange = (newPhone, countryData) => {
    const dialCode = `+${countryData.dialCode}`;

    const formattedPhone = `+${newPhone}`;
    setWpMobileno(formattedPhone);
    setDialcodeWpMobile(dialCode);
  };

  console.log(mobileno, "ffghh");

  const handleChangewp = (e, countryData) => {
    if (countryData) {
      const name = "wpcontactnumber";
      const { dialCode, format } = countryData;

      setpatientData((patientData) => ({
        ...patientData,
        [name]: {
          whatsAppNoCountryCode: format.charAt(0) + dialCode,
          whatsAppNumber: e.slice(dialCode.length).trim(),
        },
      }));
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "salutation") {
      let genderValue = "";
      if (value === "Mr.") {
        genderValue = "M";
      } else if (value === "Mrs." || value === "Miss") {
        genderValue = "F";
      }

      setpatientData({
        ...patientData,
        [name]: value,
        gender: genderValue,
      });
    } else if (name === "firstName" || name === "lastName") {
      const alphaRegex = /^[a-zA-Z\s]*$/;
      if (alphaRegex.test(value) || value === "") {
        setpatientData({ ...patientData, [name]: value });
      }
    }
    // else if (name === "mobile" || name === "whatsAppNumber") {

    //     let re = /^[0-9+\b]+$/;
    //     if (re.test(value) || value === "") {
    //         setpatientData({ ...patientData, [name]: value });
    //     }
    // }
    else if (name === "pinCode") {
      let re = /^[0-9\b]+$/;
      if (re.test(value) || value === "") {
        setpatientData({ ...patientData, [name]: value });
      }
    } else if (name === "country") {
      setpatientData({
        ...patientData,
        [name]: value,
        state: "",
        city: "",
      });
    } else if (name === "state") {
      setpatientData({
        ...patientData,
        [name]: value,
        city: "",
      });
    } else {
      const selectedValue = value;
      const selectedOption = eClinicsData?.find(
        (option) => option.hospitalCode === selectedValue
      );
      setpatientData({
        ...patientData,
        [name]: selectedOption || value,
      });
    }
  };

  const handleDateChange = (selectedDate) => {
    const formattedDate = formatDateToDDMMYYYY(selectedDate);
    setDateOfBirth(selectedDate);

    setpatientData((prevData) => ({
      ...prevData,
      dob: formattedDate,
    }));
  };

  const myDetail = useSelector((state) => state.myDetail);
  console.log(myDetail);
  const breadcrumbItems = [
    { label: "Home", onClick: () => navigate(APP_ROUTES.DASHBOARD) },
    { label: "My Details" },
    { label: "My Profile" },
  ];

  const getActivedetails = (userDetails) => {
    if (userDetails) {
      let payload = {
        code: userDetails?.code,
      };
      mydetailsservice.getmyListapi(payload).then((res) => {
        setpatientData(res?.data?.succesObject);
      });
    } else {
      if (userObj?.parentCode) {
        let payload = {
          userCode: userCode,
          code: userObj?.code,
        };
        mydetailsservice.getmyListapi(payload).then((res) => {
          setpatientData(res?.data?.succesObject);
        });
      } else {
        let payload = {
          userCode: userCode,
          code: userObj?.code,
        };
        mydetailsservice.getmyLischildapi(payload).then((res) => {
          setpatientData(res.data.succesObject);
        });
      }
    }
  };
  const changeHandler = async (file) => {
    let b64File = await encodeBase64File(file);
    setPhotoValue(b64File);
    // setaddmember({ ...addmember, ['photo']: b64File })
  };
  const encodeBase64File = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        setselectedPhoto(baseURL);
        // console.log(baseURL.split(',')[1]);
        resolve(baseURL.split(",")[1]);
      };
      console.log(fileInfo);
    });
  };

  const payloadMobile = mobileno?.substring(dialcodeMobile.length);
  const payloadWpMobile = wpmobileno?.substring(dialcodeWpMobile.length);
  console.log(
    dialcodeMobile,
    dialcodeWpMobile,
    patientData?.whatsAppNumber,
    patientData?.countryCode,
    patientData?.whatsAppNoCountryCode,
    patientData?.mobile,
    "getapi"
  );

  const updateDetails = (e) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^\d{10}$/;

    if (
      !patientData.salutation ||
      !patientData.firstName ||
      !patientData.lastName ||
      !patientData.gender ||
      // !payloadMobile ||
      // !patientData?.mobile ||
      !patientData.email ||
      !patientData.dob ||
      !patientData.bloodGroup ||
      !patientData.address1 ||
      !patientData.pinCode ||
      !patientData.country ||
      !patientData.state ||
      !patientData.city
    ) {
      setSnackbarerrorMessage("Please fill in all mandatory fields");
      return false;
    }

    // if (patientData?.mobile?.length < 8) {
    // setErr("mobile no should be exact 10 digit!");
    // setShow(true);
    // setSnackbarerrorMessage("Mobile no should not be less than 8 digit!");
    // return;
    // } else
    else if (!patientData?.pinCode?.length) {
      setSnackbarerrorMessage("Please enter a valid pincode!");
    }

    if (!(patientData?.firstName && patientData?.firstName.length >= 3)) {
      setSnackbarerrorMessage(
        "Please write the firstname with at least 3 letters"
      );
      return;
    }
    if (isParentLoggedIn && !patientData?.mobile) {
      setSnackbarerrorMessage("Please enter mobile No.");
      return;
    }
    if (isParentLoggedIn && patientData?.mobile.length !== 10) {
      setSnackbarerrorMessage("Please enter valid mobile No.");
      return;
    }
    if (isParentLoggedIn && !patientData?.emergencyContact) {
      setSnackbarerrorMessage("Please enter Emergency No.");
      return;
    }
    if (isParentLoggedIn && patientData?.emergencyContact.length !== 10) {
      setSnackbarerrorMessage("Please enter valid Emergency No.");
      return;
    }
    if (isParentLoggedIn && !patientData?.aadharId) {
      setSnackbarerrorMessage("Please enter last 4 digit of your aadhaar no.");
      return;
    }
    if (isParentLoggedIn && patientData?.aadharId.length !== 4) {
      setSnackbarerrorMessage("Please enter last 4 digit of your aadhaar no.");
      return;
    }
    if (
      // (!isParentLoggedIn && !payloadMobile) ||
      !isParentLoggedIn &&
      !patientData?.mobile
    ) {
      setSnackbarerrorMessage("Please enter a valid mobile number");
      return;
    } else if (!isParentLoggedIn && !payloadMobile) {
      setSnackbarerrorMessage("Please enter a valid mobile number");
      return;
    }
    if (
      !isParentLoggedIn &&
      //  && !payloadWpMobile
      !patientData?.whatsAppNumber
    ) {
      setSnackbarerrorMessage("Please enter a valid WhatsApp number");
      return;
    } else if (!isParentLoggedIn && !payloadWpMobile) {
      setSnackbarerrorMessage("Please enter a valid WhatsApp number");
      return;
    }
    if (dialcodeMobile === "+91" && !phoneRegex.test(payloadMobile)) {
      setSnackbarerrorMessage("Please enter a valid 10-digit mobile number");
      return;
    }
    if (dialcodeMobile === "+1" && !phoneRegex.test(payloadMobile)) {
      setSnackbarerrorMessage("Please enter a valid 10-digit number");
      return;
    }
    if (dialcodeWpMobile === "+91" && !phoneRegex.test(payloadWpMobile)) {
      setSnackbarerrorMessage("Please enter a valid 10-digit WhatsApp number");
      return;
    }
    if (dialcodeWpMobile === "+1" && !phoneRegex.test(payloadWpMobile)) {
      setSnackbarerrorMessage("Please enter a valid 10-digit WhatsApp number");
      return;
    }

    // else if (shouldShowError(patientData?.mobile) === true) {
    // setErr("mobile no should be exact 10 digit!");
    // setShow(true);
    // setSnackbarerrorMessage("Please enter valid phone number");
    // return;
    // } else if (
    //   patientData.whatsAppNumber &&
    //   shouldShowError(patientData?.whatsAppNumber) === true
    // ) {
    //   // setErr("mobile no should be exact 10 digit!");
    //   // setShow(true);
    //   setSnackbarerrorMessage("Please enter valid whatsApp number");
    //   // return;
    // }
    else if (!emailRegex.test(patientData.email)) {
      // setErr("mobile no should be exact 10 digit!");
      // setShow(true);
      setSnackbarerrorMessage("Please enter valid mail Id");
      // return;
    } else {
      enapleInput();
      let params = {
        salutation: patientData?.salutation ? patientData.salutation : "",
        firstName: patientData?.firstName ? patientData.firstName : "",
        lastName: patientData?.lastName ? patientData.lastName : "",
        gender: patientData?.gender ? patientData.gender : "",
        // mobile: patientData?.mobile ? patientData.mobile : '',
        countryCode: dialcodeMobile ? dialcodeMobile : patientData?.countryCode,
        mobile: payloadMobile ? payloadMobile : patientData?.mobile,

        email: patientData?.email ? patientData.email : "",
        dob: patientData?.dob ? patientData.dob : "",
        bloodGroup: patientData?.bloodGroup ? patientData.bloodGroup : "",
        age: patientData?.age ? patientData.age : "",
        address1: patientData?.address1 ? patientData.address1 : "",
        address2: patientData?.address2 ? patientData.address2 : "",
        pinCode: patientData?.pinCode ? patientData.pinCode : "",
        country: patientData?.country ? patientData.country : "",
        state: patientData?.state ? patientData.state : "",
        city: patientData?.city ? patientData.city : "",
        emergencyContact: patientData?.emergencyContact
          ? patientData.emergencyContact
          : "",

        // whatsAppNumber: patientData?.whatsAppNumber ? patientData.whatsAppNumber : '',
        // whatsAppNoCountryCode: patientData?.wpcontactnumber
        //   ?.whatsAppNoCountryCode
        //   ? patientData?.wpcontactnumber?.whatsAppNoCountryCode
        //   : "",
        // whatsAppNumber: patientData?.whatsAppNumber
        //   ? patientData?.whatsAppNumber
        //   : "",

        whatsAppNumber: payloadWpMobile
          ? payloadWpMobile
          : patientData?.whatsAppNumber,
        whatsAppNoCountryCode: dialcodeWpMobile
          ? dialcodeWpMobile
          : patientData?.whatsAppNoCountryCode,

        cheId: patientData?.eClinics?.hospitalCode,
        cheLocationId: patientData?.eClinics?.locationCode,
        photo: PhotoValue ? PhotoValue : "",
        aadharId: patientData?.aadharId,
      };

      if (userObj?.parentCode) {
        // patientData.gender = "F"
        params.parentCode = userObj?.parentCode;
        params.code = selectedUser?.code ? selectedUser?.code : userObj?.code;
        params.userId = 21;
        // params.cheId = patientData?.eClinics?.hospitalCode;
        // params.cheLocationId = patientData?.eClinics?.locationCode;
        mydetailsservice.updatePatientapi(params).then((res) => {
          if (res?.data?.responseCode === "200") {
            getActivedetails();
            setSnackbarMessage(res?.data?.responseMessage, "success");
            console.log(
              yesValue1,
              yesValue,
              allowedToSeeLabRep,
              allowedToSeePrescrp,
              "bhghbhhbgghr"
            );
            const DrRemark =
              yesValue === true
                ? 1
                : yesValue === false
                ? 0
                : allowedToSeePrescrp;
            const MedRemark =
              yesValue1 === true
                ? 1
                : yesValue1 === false
                ? 0
                : allowedToSeeLabRep;
            async function fetchData1() {
              const res = await consentData({
                patientCode: userObj?.code,
                consentDrRemark: yesValue1 ? 1 : 0,
                consentMedReport: yesValue ? 1 : 0,
                //         consentDrRemark: yesValue ? 1 : 0,
                // consentMedReport: yesValue1 ? 1 : 0,
                consentForm: 1,
              });
              if (res.status === 200 && res?.data) {
              }
              // } else {
              //   setSnackbarMessage("Please Read and Sign Consent Form", "error");
              // }
            }
            fetchData1();
          } else if (res?.data?.responseCode === "500") {
            setSnackbarMessage(res?.data?.responseMessage, "error");
          } else {
            setSnackbarMessage("Please try after sometime", "error");
          }
        });
      } else {
        // patientData.gender = "F"
        // patientData.userId = userObj?.code
        params.userCode = userObj?.userCode;
        params.userSeqId = userObj?.userSeqId;
        params.userType = userObj?.userType;

        mydetailsservice.getmyListupdateapi(params).then((res) => {
          if (res?.data?.responseCode === "200") {
            getActivedetails();
            setSnackbarMessage(res?.data?.responseMessage, "success");
          } else if (res?.data?.responseCode === "500") {
            setSnackbarMessage(res?.data?.responseMessage, "error");
          } else {
            setSnackbarMessage("Please try after sometime", "error");
          }
        });
      }
    }
  };

  useEffect(() => {
    getUserLists(userCode);
  }, []);

  const enapleInput = () => {
    setdisableFields((e) => !e);
  };
  const onEClinicSelection = (value) => {
    setEclinic(value);
  };

  const onUserChange = (event) => {
    setSelectedUser(event.target.value);
    getActivedetails(event.target.value);
  };

  console.log(patientData?.wpcontactnumber);

  return (
    <>
      {snackbarMessage}
      {snackbarerrorMessage}
      <div className="p-4 sm:p-8 ">
        {/* <div className="flex items-center mb-5">
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">Home</p>
                    <ArrowForwardIosIcon sx={{ fontSize: 15 }} className="text-chathams-blue-500 mx-2" />
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">My Profile</p>
                    <ArrowForwardIosIcon sx={{ fontSize: 15 }} className="text-chathams-blue-500 mx-2" />
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">My Details</p>
                </div> */}
        <div className="flex items-center mb-5 mt-[100px]">
          {breadcrumbItems.map((item, index) => (
            <React.Fragment key={index}>
              <span className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">
                <a>{item.label}</a>
              </span>
              {index !== breadcrumbItems.length - 1 && (
                <ArrowForwardIosIcon
                  sx={{ fontSize: 15 }}
                  className="text-chathams-blue-500 mx-2"
                />
              )}
            </React.Fragment>
          ))}
        </div>
        {isParentLoggedIn && (
          <div className="flex flex-col sm:flex-row justify-between ">
            <h2 className="text-xl font-bold my-5 w-full text-chathams-blue-500">
              {/* My Profile - Details */}
            </h2>
            <div className="sm:px-4 py-2 h-20 mb-2 flex items-center justify-start mr-[39rem] w-full rounded-md">
              <div className="flex flex-col">
                <p className="text-chathams-blue font-semibold text-base">
                  Member Profile
                </p>
                <TextField
                  select
                  value={
                    selectedUser ||
                    userList?.find((user) => user.code === userObj?.code) ||
                    ""
                  }
                  onChange={onUserChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ width: "13rem", marginBottom: "20px" }}
                >
                  {userList &&
                    userList?.map((value, index) => (
                      <MenuItem value={value} key={index}>
                        <p className="text-base font-semibold">{value.name}</p>
                      </MenuItem>
                    ))}
                </TextField>
              </div>
            </div>
          </div>
        )}
        <div className="flex scroll-px-2">
          <div className="w-1/4 mr-4 border p-4 rounded-md hidden sm:block">
            <MyprofileSidemenu />
          </div>
          {patientData && (
            <div className="w-full sm:w-3/4 ml-0 sm:ml-4 border-0 sm:border p-0 sm:p-8 rounded-md">
              <div className="flex flex-col sm:flex-row gap-6 sm:items-center items-start justify-between border-b pb-6">
                <div className="flex items-center">
                  {/* <img className="profileimg" src={Profileimg} />  */}
                  <div className="relative">
                    {/* {props?.editmembers ? */}
                    <img
                      src={
                        selectedPhoto ||
                        patientData?.photoName ||
                        patientData?.patientPhotoName ||
                        userImage
                      }
                      alt="userImage"
                      className="sm:border md:w-24 md:h-24 h-20 mt-6 rounded-full"
                    />
                    {/* // : <img src={addmember.photo ? `${'data:image;base64,'}` + addmember.photo : steth} alt="stethescope" className="lg:w-24 lg:h-24 h-20 mt-6 rounded-full" />} */}
                    <div className="-mt-4 justify-end flex6 absolute left-16 bottom-0">
                      <label className="w-6 h-6 flex justify-center cursor-pointer text-xs bg-brand-primary font-normal rounded-full ">
                        <input
                          type="file"
                          accept="image/*"
                          onChange={(e) => {
                            changeHandler(e.target.files[0]);
                          }}
                          className="hidden"
                          disabled={!disableFields}
                        />
                        <img src={camera} alt="camera" className="w-5" />
                      </label>
                    </div>
                  </div>
                  <div className="ml-4 sm:ml-6 sm:mt-0 mt-5">
                    <h3 className="text-xl text-seegreen font-bold">
                      {(patientData?.firstName ? patientData?.firstName : "") +
                        " " +
                        (patientData?.lastName
                          ? patientData?.lastName
                          : "")}{" "}
                    </h3>
                    {/* <p className="text-sm pt-1 font-semibold">Patient ID: <span className="font-normal">{patientData?.parentCode}</span></p> */}
                  </div>
                </div>

                <div className="flex  justify-between gap-2">
                  <Button
                    disabled={!disableFields}
                    variant="outlined"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      updateDetails();
                    }}
                    // onClick={(() => { enapleInput() })}
                  >
                    Save Details
                  </Button>

                  <Button
                    disabled={disableFields}
                    variant="outlined"
                    sx={{ textTransform: "capitalize" }}
                    onClick={() => {
                      enapleInput();
                    }}
                  >
                    Edit Details
                  </Button>
                </div>
              </div>
              <div>
                <h6 className="text-sm font-bold my-5 text-chathams-blue-500">
                  Personal Details
                </h6>
                <div className="grid grid-cols-2 sm:grid-cols-5 gap-2">
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 ">
                      Salutation
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>

                    <select
                      disabled={!disableFields}
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 "
                      }before:!border-0 after:!border-0 profileinput pt-1.5 `}
                      name="salutation"
                      value={
                        patientData?.salutation ? patientData?.salutation : ""
                      }
                      onChange={handleChange}
                      style={{ fontSize: "13px" }}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="Mr.">Mr.</option>
                      <option value="Miss">Miss</option>
                      <option value="Mrs.">Mrs.</option>
                    </select>
                  </div>
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 ">
                      First Name{" "}
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>
                    <Input
                      disabled={!disableFields}
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 "
                      }before:!border-0 after:!border-0 profileinput `}
                      onChange={handleChange}
                      name="firstName"
                      value={patientData.firstName}
                      onKeyPress={(e) => {
                        // Allow only alphabetic characters
                        const isValidInput = /^[A-Za-z]+(\s[A-Za-z]*)?$/.test(
                          e.target.value + e.key
                        );
                        if (!isValidInput) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 ">
                      Last Name{" "}
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>
                    <Input
                      disabled={!disableFields}
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 "
                      }before:!border-0 after:!border-0 profileinput `}
                      onChange={handleChange}
                      name="lastName"
                      value={patientData.lastName}
                      onKeyDown={(e) => {
                        if (e.key === " ") {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 ">
                      Gender{" "}
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>
                    <select
                      // disabled={!disableFields}
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 "
                      }before:!border-0 after:!border-0 profileinput pt-1.5 `}
                      name="gender"
                      value={patientData?.gender ? patientData.gender : ""}
                      onChange={handleChange}
                      style={{ fontSize: "13px" }}
                      disabled
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </select>
                  </div>
                  <div className="relative flex flex-col mt-1">
                    <label className="text-xs font-normal text-shuttle-gray-500">
                      Mobile Number{" "}
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>
                    {/* <Input
                                        disabled={!disableFields}
                                        className={`${disableFields ? "border-b-2 border-gray-300 " : "border-0 "}before:!border-0 after:!border-0 profileinput`}
                                        onChange={handleChange}
                                        name="mobile"
                                        value={patientData.mobile}
                                        error={shouldShowError(patientData.mobile)}
                                        aria-describedby="mobile-error-text"
                                    /> */}
                    {isParentLoggedIn && (
                      <input
                        autocomplete="off"
                        id="mobile"
                        minLength="10"
                        maxLength="10"
                        name="mobile"
                        type="tel"
                        placeholder="Enter Phonenumber"
                        disabled={!disableFields}
                        className={`${
                          disableFields
                            ? "border-b-2 border-gray-300 "
                            : "border-0  text-zinc-400"
                        } text-[13px] before:!border-0 after:!border-0 profileinput`}
                        onChange={handleChange}
                        value={patientData?.mobile}
                        onKeyPress={(e) => {
                          // Allow only numeric input and limit length to 13 characters
                          const isValidInput = /^[0-9]*$/.test(e.key);
                          if (!isValidInput || e.target.value.length >= 10) {
                            e.preventDefault();
                          }
                        }}
                      />
                    )}

                    {!isParentLoggedIn && (
                      <>
                        {!disableFields && (
                          <input
                            autocomplete="off"
                            id="mobile"
                            minLength="8"
                            maxLength="13"
                            name="mobile"
                            type="tel"
                            placeholder="Enter Phonenumber"
                            disabled={!disableFields}
                            className={`${
                              disableFields
                                ? "border-b-2 border-gray-300 "
                                : "border-0  text-zinc-400 "
                            } text-[13px] before:!border-0 after:!border-0 profileinput`}
                            onChange={handleChange}
                            value={`${patientData?.mobile}`}
                            // error={shouldShowError(patientData.mobile)}
                            // aria-describedby="mobile-error-text"
                          />
                        )}
                        {disableFields && (
                          <>
                            <PhoneInput
                              id="ContactNumber"
                              minLength="8"
                              maxLength="13"
                              name="mobile"
                              type="tel"
                              placeholder="Enter Phonenumber"
                              disabled={!disableFields}
                              className={`${
                                disableFields
                                  ? "border-b-2 border-gray-300  "
                                  : "border-0  text-zinc-400"
                              } text-[13px] before:!border-0 after:!border-0 profileinput`}
                              onChange={handlePhoneChange}
                              // country={}
                              enableSearch={true}
                              // value={mobileno}
                              value={
                                mobileno ||
                                patientData?.countryCode + patientData?.mobile
                              }

                              // error={shouldShowError(mobileno)}
                              // aria-describedby="mobile-error-text"
                              // helperText={
                              //   shouldShowError(patientData?.mobile) &&
                              //   "Invalid mobile number"
                              // }
                            />
                          </>
                        )}
                      </>
                    )}

                    {/* {shouldShowError(patientData?.mobile) && (
                      <FormHelperText
                        style={{ color: "red" }}
                        id="mobile-error-text"
                      >
                        Invalid mobile number
                      </FormHelperText>
                    )} */}
                  </div>
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 mt-1">
                      Email ID{" "}
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>
                    <Input
                      disabled={!disableFields}
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 "
                      }before:!border-0 after:!border-0 profileinput `}
                      onChange={handleChange}
                      name="email"
                      value={patientData.email}
                    />
                  </div>
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 ">
                      Date of Birth{" "}
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>
                    <DatePicker
                      disabled={!disableFields}
                      locale="es"
                      id="dob"
                      name="dob"
                      dropdownMode="select"
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy"
                      maxDate={new Date()}
                      value={patientData?.dob}
                      selected={dateOfBirth}
                      onChange={handleDateChange}
                      disabledKeyboardNavigation={true}
                      autoFocus={false}
                      placeholder=""
                      className={`before:!border-0 after:!border-0 profileinput w-full pt-3 text-xs bg-transparent focus:outline-none ${
                        disableFields
                          ? "border-b-2 border-gray-300 text-gray-900"
                          : "border-0 text-gray-500"
                      }`}
                    />
                  </div>

                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 mt-[2px] ">
                      Blood Group{" "}
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>
                    {/* <Input disabled={!disableFields} 
                                    className={`${disableFields ? "border-b-2 border-gray-300 " : "border-0 "}before:!border-0 after:!border-0 profileinput `}
                                     onChange={handleChange} 
                                     name="bloodGroup"
                                      value={patientData.bloodGroup} /> */}
                    <select
                      disabled={!disableFields}
                      name="bloodGroup"
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 text-zinc-400 "
                      }text-[13px] mt-2 before:!border-0 after:!border-0 profileinput `}
                      onChange={handleChange}
                      // value={patientData.bloodGroup}
                      // value={patientData.bloodGroup}
                      value={
                        patientData?.bloodGroup ? patientData?.bloodGroup : ""
                      }
                      fullWidth
                      size="small"
                    >
                      <option value="" disabled>
                        <p className="text-base font-semibold">
                          Select Blood Group
                        </p>
                      </option>
                      {bloodGroups &&
                        bloodGroups?.map((value, index) => (
                          <option value={value.code} key={index}>
                            <p className="text-base font-semibold">
                              {value.code}
                            </p>
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 mt-[4px]">
                      Age
                    </label>
                    <Input
                      disabled={!disableFields}
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 "
                      }before:!border-0 after:!border-0 profileinput `}
                      onChange={handleChange}
                      value={CalculateAge(patientData?.dob)}
                    />
                  </div>
                  {!isParentLoggedIn && (
                    <div className="relative flex flex-col">
                      <label className="text-xs font-normal text-shuttle-gray-500  mt-2">
                        WhatsApp No{" "}
                        {disableFields ? (
                          <spam style={{ color: "#F34040", fontSize: "17px" }}>
                            *
                          </spam>
                        ) : (
                          ""
                        )}
                      </label>
                      {/* <Input
                                            disabled={!disableFields}
                                            className={`${disableFields ? "border-b-2 border-gray-300 " : "border-0 "}before:!border-0 after:!border-0 profileinput`}
                                            onChange={handleChange}
                                            name="whatsAppNumber"
                                            value={patientData.whatsAppNumber}
                                            error={shouldShowError(patientData.whatsAppNumber)}
                                            aria-describedby="whatsapp-error-text"
                                        /> */}
                      {!disableFields && (
                        <input
                          autocomplete="off"
                          minLength="8"
                          maxLength="13"
                          type="tel"
                          placeholder="Enter Phonenumber"
                          disabled={!disableFields}
                          className={`${
                            disableFields
                              ? "border-b-2 border-gray-300 "
                              : "border-0  text-zinc-400"
                          } text-[13px] before:!border-0 after:!border-0 profileinput`}
                          onChange={handleChange}
                          name="whatsAppNumber"
                          // value={`${patientData?.wpcontactnumber?.whatsAppNoCountryCode} ${patientData?.wpcontactnumber?.whatsAppNumber}`}
                          value={`${patientData?.whatsAppNumber}`}

                          // error={shouldShowError(patientData.whatsAppNumber)}
                          // aria-describedby="whatsapp-error-text"
                        />
                      )}
                      {disableFields && (
                        <PhoneInput
                          id="whatsAppNumber"
                          minLength="8"
                          maxLength="13"
                          name="whatsAppNumber"
                          type="tel"
                          placeholder="Enter Phonenumber"
                          disabled={!disableFields}
                          className={`${
                            disableFields
                              ? "border-b-2 border-gray-300  "
                              : "border-0  text-zinc-400"
                          } text-[13px] before:!border-0 after:!border-0 profileinput`}
                          onChange={handleWpPhoneChange}
                          country={"us"}
                          enableSearch={true}
                          // value={wpmobileno}
                          value={
                            wpmobileno ||
                            patientData?.whatsAppNoCountryCode +
                              patientData?.whatsAppNumber
                          }
                          // error={shouldShowError(wpmobileno)}
                          // aria-describedby="mobile-error-text"
                          // helperText={
                          //   shouldShowError(patientData.whatsAppNumber) &&
                          //   "Invalid mobile number"
                          // }
                        />
                      )}
                      {/* {shouldShowError(patientData.whatsAppNumber) && (
                        <FormHelperText
                          style={{ color: "red" }}
                          id="whatsapp-error-text"
                        >
                          Invalid WhatsApp number
                        </FormHelperText>
                      )} */}
                    </div>
                  )}
                  {isParentLoggedIn && (
                    <div className="relative flex flex-col">
                      <label className="text-xs font-normal text-shuttle-gray-500 ">
                        eClinics
                      </label>
                      <select
                        disabled={!disableFields}
                        className={`${
                          disableFields
                            ? "border-b-2 border-gray-300 "
                            : "border-0 "
                        }before:!border-0 after:!border-0 profileinput`}
                        onChange={handleChange}
                        name="eClinics"
                        style={{
                          minWidth: "10px",
                          height: "40px",
                          borderRadius: "2px",
                          fontSize: "0.75rem",
                          paddingRight: "24px",
                        }}
                        value={
                          patientData?.eClinics?.hospitalCode ||
                          patientData?.cheId ||
                          ""
                        }
                      >
                        {eClinicsData?.map((option, index) => (
                          <option key={index} value={option.hospitalCode}>
                            {option.hospitalCode}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}

                  {isParentLoggedIn && (
                    <>
                      <div className="relative flex flex-col">
                        <label className="text-xs font-normal text-shuttle-gray-500 ">
                          Aadhaar No(Last 4 digit only){" "}
                          {disableFields ? (
                            <spam
                              style={{ color: "#F34040", fontSize: "17px" }}
                            >
                              *
                            </spam>
                          ) : (
                            ""
                          )}
                        </label>
                        <input
                          id="aadharId"
                          minLength="4"
                          maxLength="4"
                          name="aadharId"
                          type="tel"
                          placeholder="Enter Aadhaar Number"
                          disabled={!disableFields}
                          className={`${
                            disableFields
                              ? "border-b-2 border-gray-300 "
                              : "border-0  text-zinc-400"
                          } text-[13px] before:!border-0 after:!border-0 profileinput`}
                          onChange={handleChange}
                          value={patientData?.aadharId}
                          onKeyPress={(e) => {
                            // Allow only numeric input and limit length to 13 characters
                            const isValidInput = /^[0-9]*$/.test(e.key);
                            if (!isValidInput || e.target.value.length > 4) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                      <div className="relative flex flex-col">
                        <label className="text-xs font-normal text-shuttle-gray-500 ">
                          Emergency Contact No.{" "}
                          {disableFields ? (
                            <spam
                              style={{ color: "#F34040", fontSize: "17px" }}
                            >
                              *
                            </spam>
                          ) : (
                            ""
                          )}
                        </label>
                        {isParentLoggedIn && (
                          <input
                            autocomplete="off"
                            id="emergencyContact"
                            minLength="10"
                            maxLength="10"
                            name="emergencyContact"
                            type="tel"
                            placeholder="Enter emergency contact number"
                            disabled={!disableFields}
                            className={`${
                              disableFields
                                ? "border-b-2 border-gray-300 "
                                : "border-0  text-zinc-400"
                            } text-[13px] before:!border-0 after:!border-0 profileinput`}
                            onChange={handleChange}
                            value={patientData?.emergencyContact}
                            // error={shouldShowError(patientData?.mobile)}
                            // aria-describedby="mobile-error-text"
                            // helperText={
                            //   shouldShowError(patientData?.mobile) &&
                            //   "Invalid mobile number"
                            // }
                          />
                        )}
                      </div>
                    </>
                  )}
                </div>
                <hr className="my-10" />
                <h6 className="text-sm font-bold my-5 text-chathams-blue-500">
                  Contact Details
                </h6>
                <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 sm:gap-2">
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 ">
                      House No/Street Name{" "}
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>
                    <Input
                      disabled={!disableFields}
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 "
                      }before:!border-0 after:!border-0 profileinput `}
                      onChange={handleChange}
                      name="address1"
                      value={patientData.address1}
                    />
                  </div>
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 ">
                      Address
                    </label>
                    <Input
                      disabled={!disableFields}
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 "
                      }before:!border-0 after:!border-0 profileinput `}
                      onChange={handleChange}
                      name="address2"
                      value={patientData.address2}
                    />
                  </div>
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 mt-[5px]">
                      Pincode{" "}
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>
                    <input
                      disabled={!disableFields}
                      maxLength="10"
                      type="tel"
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 text-zinc-400"
                      } text-[13px] before:!border-0 after:!border-0 profileinput `}
                      onChange={handleChange}
                      name="pinCode"
                      value={patientData.pinCode}
                    />
                  </div>
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 mt-[-1px] ">
                      Country{" "}
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>
                    <select
                      disabled={userObj?.parentCode}
                      name="country"
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 text-zinc-400 "
                      }text-[13px] mt-2 before:!border-0 after:!border-0 profileinput `}
                      onChange={handleChange}
                      value={patientData?.country || ""}
                      fullWidth
                      size="small"
                    >
                      <option value="" disabled>
                        <p className="text-base font-semibold">
                          Select Country
                        </p>
                      </option>
                      {countries &&
                        countries?.map((value, index) => (
                          <option value={value.code} key={index}>
                            <p className="text-base font-semibold">
                              {value.description}
                            </p>
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 mt-[-1px] ">
                      State{" "}
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>
                    <select
                      disabled={!disableFields}
                      name="state"
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 text-zinc-400 "
                      }text-[13px] mt-2 before:!border-0 after:!border-0 profileinput `}
                      onChange={handleChange}
                      value={patientData?.state || ""}
                      fullWidth
                      size="small"
                    >
                      <option value="">Select State</option>
                      {states &&
                        states?.map((value, index) => (
                          <option value={value.code} key={index}>
                            <p className="text-base font-semibold">
                              {value.description}
                            </p>
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="relative flex flex-col">
                    <label className="text-xs font-normal text-shuttle-gray-500 ">
                      District{" "}
                      {disableFields ? (
                        <spam style={{ color: "#F34040", fontSize: "17px" }}>
                          *
                        </spam>
                      ) : (
                        ""
                      )}
                    </label>
                    <select
                      disabled={!disableFields}
                      name="city"
                      className={`${
                        disableFields
                          ? "border-b-2 border-gray-300 "
                          : "border-0 text-zinc-400 "
                      }text-[13px] mt-2 before:!border-0 after:!border-0 profileinput `}
                      onChange={handleChange}
                      value={patientData?.city || ""}
                      fullWidth
                      isSearchable
                      size="small"
                    >
                      <option value="">Select City</option>
                      {cities &&
                        cities?.map((value, index) => (
                          <option value={value.description} key={index}>
                            <p className="text-base font-semibold">
                              {value.description}
                            </p>
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {isParentLoggedIn && (
                  <>
                    <div className="">
                      <div className="sm:m-0 m-3 sm:mt-7 flex flex-col">
                        <div className="flex gap-5">
                          <span className="  mb-6 sm:text-[15px] text-base font-medium">
                            {
                              consent?.attributes?.firstcomp?.Questions[0]
                                ?.label
                            }
                          </span>
                          <YesNo
                            onYesChange={handleYesChange}
                            // allowedToSeePrescrp={allowedToSeePrescrp}
                            allowedToSeeLabRep={allowedToSeeLabRep}
                            disabled={disableFields}
                          />
                        </div>

                        <div className="flex gap-4">
                          <span className="mb-6 sm:text-[15px] text-base font-medium">
                            {
                              consent?.attributes?.firstcomp?.Questions[1]
                                ?.label
                            }
                          </span>

                          <YesNo2
                            onYesChange={handleYesChange1}
                            allowedToSeePrescrp={allowedToSeePrescrp}
                            // allowedToSeeLabRep={allowedToSeeLabRep}
                            disabled={disableFields}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sm:flex block ">
                      <h6 className="text-lg font-bold  text-chathams-blue-500 mt-1 ">
                        {/* Want to change cosent to your care sponsor ?{" "} */}
                        Update your consent permissions.
                      </h6>{" "}
                      &nbsp;&nbsp;&nbsp;
                      <div>
                        <button
                          className=" border-chathams-blue-200 border-2 py-[4px] px-[10px] rounded-md  text-white bg-[#396DDD] font-semibold"
                          // onClick={() => navigate(APP_ROUTES.CONSENT)}
                          onClick={() => setConsentpennew(true)}
                        >
                          Consent Form
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {!isParentLoggedIn && (
                  <div className="sm:flex block mt-[45px]">
                    <h6 className="text-lg font-bold my-5 text-chathams-blue-500 ">
                      {/* Want to withdraw your cosent ?{" "} */}
                      Update your consent permissions.
                    </h6>{" "}
                    &nbsp;&nbsp;&nbsp;
                    <div>
                      <button
                        className="text-chathams-blue-200 border-chathams-blue-200 border-2 sm:mt-[11px] py-[8px] px-[14px] rounded-md  hover:text-white hover:bg-[#396DDD] font-semibold"
                        onClick={() => setConsentpennew(true)}
                      >
                        Consent Form
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {!patientData && (
            <div className="w-full sm:w-3/4 ml-0 sm:ml-4 border p-0 sm:p-8 rounded-md">
              {" "}
              <h1>No Records Found</h1>
            </div>
          )}
        </div>
      </div>

      <Dialog
        modal
        showHeader={false}
        visible={consentopennew}
        className="w-full sm:w-2/3 h-screen"
        // onHide={() => setIsVisible(!isVisible)}
      >
        <div style={{ minHeight: "max-content" }}>
          <h1 className="text-xl text-black font-semibold">Consent</h1>
          <div>
            <TabView>
              <TabPanel header="Terms and condition">
                <iframe
                  className="border rounded cursor-pointer h-96 w-full"
                  src={`${process.env.REACT_APP_IMAGE_URL}${tcPdf}`}
                />
              </TabPanel>
              <TabPanel header="Privacy Policy">
                <iframe
                  className="border rounded cursor-pointer h-96 w-full"
                  src={`${process.env.REACT_APP_IMAGE_URL}${privacyPdf}`}
                />
              </TabPanel>
            </TabView>
          </div>
          <div className="flex justify-end p-4">
            <button
              onClick={handleAgree}
              className=" text-white  text-sm font-bold px-2 py-2 rounded cursor-pointer lg:px-18 mr-4"
              style={{ backgroundColor: "#66B889" }}
            >
              Accept
            </button>
            <button
              onClick={handleDisagree}
              className=" text-white  text-sm font-bold px-2 py-2 rounded cursor-pointer lg:px-18"
              style={{ backgroundColor: "red" }}
            >
              Decline
            </button>
          </div>
        </div>
      </Dialog>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </>
  );
}

export default Mydetails;
