import React, { useState, useEffect } from "react";
import login from "./image/Login image.png";
// import Footer from "../homepage/footer";
import Footer from "../../components/webflow/postloginfooter";
import { Card, Typography, Button } from "@mui/material";
import logo from "../../assets/images/Web Logo.png";
import {
  fetchOtp,
  fetchEmail,
  isSelfLogIn,
  selfCareGiverLogin,
} from "../../store/actions/login";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate } from "react-router";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useRef } from "react";
import { getToken } from "../../store/actions/useractions";
import { useDispatch } from "react-redux";
import { updateUserData } from "../../store/actions/useractions";
import MobileFooter from "./../homepage/mobileFooter";
import loginmob from "../../assets/images/loginmob.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { updateCart } from "../../store/actions/login";
import loginservice from "../../store/services/loginservice";
import { COMMONCONSTANTS } from "../../store/constants/commonconstants";

import "aos/dist/aos.css";
import Aos from "aos";

function InputWithIcon({ type, onChange, onKeyDown }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative">
      <input
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        onChange={onChange}
        onKeyDown={onKeyDown}
        required
        className="w-full py-2 pr-10 pl-3 border rounded-[132px] shadow-sm focus:outline-none focus:bg-password-gray  sm:text-sm "
      />
      {type === "password" && (
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          {showPassword ? (
            <VisibilityIcon className="h-5 w-5" />
          ) : (
            <VisibilityOffIcon className="h-5 w-5" />
          )}
        </button>
      )}
    </div>
  );
}

const Index = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // = /^[0-9]{10}$/;
  const phoneRegex = /^[6-9]\d{9}$/;
  const [form, setForm] = useState("nothing");
  const [isSent, setSent] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState("");
  const [validateMessage, setValidateMessage] = useState("");
  const [seconds, setTimer] = useState(60);
  const [screen, setscreen] = useState(window.innerWidth);
  const [showModal, setShowModal] = useState(false);
  const [loginDetails, setLoginDetails] = useState();
  const location = useLocation();
  const cartdata = location.state;

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const [OTPrec, setOTPrec] = useState({
    otp01: "",
    otp02: "",
    otp03: "",
    otp04: "",
    otp05: "",
    otp06: "",
  });
  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  useEffect(() => {
    async function fetchToken() {
      dispatch(getToken());
    }
    fetchToken();
  }, [dispatch]);

  const handleNext = () => {
    if (emailRegex.test(inputValue)) {
      setForm("email");
    } else if (phoneRegex.test(inputValue)) {
      setForm("phone");
    } else if (/^\d+$/.test(inputValue)) {
      setValidateMessage("Please enter a valid number");
    } else if (/^[a-zA-Z]+$/.test(inputValue)) {
      setValidateMessage("Please enter a valid email");
    } else {
      setValidateMessage("Please enter a valid credentials");
    }
  };

  const handleNextPhone = () => {
    let otpvalues = Object.values(OTPrec);
    let sum = otpvalues.reduce(add);
    function add(accumulator, a) {
      return accumulator + a;
    }
    if (sum.length === 6) {
      async function fetchOTP() {
        const otpVerifiedResponse = await fetchOtp({
          userMobileNumber: inputValue,
          mobileCheck: 2,
          userOTP: sum,
        });
        if (
          otpVerifiedResponse.status === 200 &&
          otpVerifiedResponse?.data?.succesObject
        ) {
          localStorage.setItem(
            "loginObj",
            JSON.stringify(otpVerifiedResponse.data.succesObject)
          );
          dispatch(
            updateUserData(
              JSON.stringify(otpVerifiedResponse.data.succesObject)
            )
          );
          if (otpVerifiedResponse?.data?.succesObject) {
            setLoginDetails(otpVerifiedResponse.data.succesObject);
            const isSelfLoginResponse = await isSelfLogIn({
              userMobileNumber:
                otpVerifiedResponse.data.succesObject?.[0]?.mobile,
              email: otpVerifiedResponse.data.succesObject?.[0]?.email,
            });
            if (
              isSelfLoginResponse.status === 200 &&
              isSelfLoginResponse?.data?.succesObject
            ) {
              if (isSelfLoginResponse.data.succesObject.self === "N") {
                if (otpVerifiedResponse?.data?.succesObject[0]?.patientCode) {
                  // otpVerifiedResponse.data.succesObject[0].consentForm === "Y"
                  otpVerifiedResponse.data.succesObject[1]?.[0]?.consentForm ==
                  1
                    ? navigate(APP_ROUTES.MAINDASHBOARD, { state: "dashboard" })
                    : // : navigate(APP_ROUTES.CONSENT);
                      navigate(APP_ROUTES.MAINDASHBOARD, {
                        state: "dashboard",
                      });
                } else {
                  const userCode =
                    otpVerifiedResponse.data.succesObject?.[0]?.userCode;
                  if (cartdata) {
                    let payload = {
                      userCode: userCode,
                      cartType: 1,
                      cartVOList: [
                        {
                          userId: 110,
                          userCode: userCode,
                          planId: cartdata.prop2,
                          planName: cartdata.prop1,
                          period: cartdata?.prop3,
                        },
                      ],
                    };

                    const res = await updateCart(payload);
                    if (res?.status === 200 && res?.data?.responseCode) {
                      let userData = {
                        userCode: userCode,
                      };
                      loginservice
                        .cartList(userData)
                        .then((res) => {
                          dispatch({
                            type: COMMONCONSTANTS.SUCCESS_FETCHCART,
                            payload: res?.data?.succesObject,
                          });
                        })
                        .catch((error) => {});
                    }
                  }
                  navigate(APP_ROUTES.MAINDASHBOARD, { state: "dashboard" });
                }
              } else if (isSelfLoginResponse.data.succesObject.self === "Y") {
                setShowModal(true);
              } else {
                if (otpVerifiedResponse?.data?.succesObject[0]?.patientCode) {
                  // otpVerifiedResponse.data.succesObject[0].consentForm === "Y"
                  otpVerifiedResponse.data.succesObject[1]?.[0]?.consentForm ==
                  1
                    ? navigate(APP_ROUTES.MAINDASHBOARD, { state: "dashboard" })
                    : // : navigate(APP_ROUTES.CONSENT);
                      navigate(APP_ROUTES.MAINDASHBOARD, {
                        state: "dashboard",
                      });
                } else {
                  const userCode =
                    otpVerifiedResponse.data.succesObject?.[0]?.userCode;
                  if (cartdata) {
                    let payload = {
                      userCode: userCode,
                      cartType: 1,
                      cartVOList: [
                        {
                          userId: 110,
                          userCode: userCode,
                          planId: cartdata.prop2,
                          planName: cartdata.prop1,
                          period: cartdata?.prop3,
                        },
                      ],
                    };

                    const res = await updateCart(payload);
                    if (res?.status === 200 && res?.data?.responseCode) {
                      let userData = {
                        userCode: userCode,
                      };
                      loginservice
                        .cartList(userData)
                        .then((res) => {
                          dispatch({
                            type: COMMONCONSTANTS.SUCCESS_FETCHCART,
                            payload: res?.data?.succesObject,
                          });
                        })
                        .catch((error) => {});
                    }
                  }
                  navigate(APP_ROUTES.MAINDASHBOARD, { state: "dashboard" });
                  // console.log(cartdata,"dataaaarecoming2");
                }
              }
            }
          }
        } else {
          setErr(otpVerifiedResponse?.data?.responseMessage);
        }
      }
      fetchOTP();
    }
  };

  const handleNextEmail = () => {
    if (form === "email") {
      async function newEmail() {
        const emailLoginResponse = await fetchEmail({
          passWord: password,
          email: inputValue,
        });
        if (
          emailLoginResponse.status === 200 &&
          emailLoginResponse?.data?.succesObject
        ) {
          localStorage.setItem(
            "loginObj",
            JSON.stringify(emailLoginResponse.data.succesObject)
          );
          dispatch(
            updateUserData(JSON.stringify(emailLoginResponse.data.succesObject))
          );

          window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
          window.dataLayer.push({
            event: "login",
            ecommerce: {
              value: 100,
              items: [
                {
                  passWord: password,
                  email: inputValue,
                },
              ],
            },
          });

          if (emailLoginResponse?.data?.succesObject) {
            setLoginDetails(emailLoginResponse.data.succesObject);
            const isSelfLoginResponse = await isSelfLogIn({
              userMobileNumber:
                emailLoginResponse.data.succesObject?.[0]?.mobile,
              email: emailLoginResponse.data.succesObject?.[0]?.email,
            });
            if (
              isSelfLoginResponse.status === 200 &&
              isSelfLoginResponse?.data?.succesObject
            ) {
              if (isSelfLoginResponse.data.succesObject.self === "N") {
                if (emailLoginResponse?.data?.succesObject[0]?.patientCode) {
                  // emailLoginResponse.data.succesObject[0].consentForm === "Y"
                  emailLoginResponse.data.succesObject[1]?.[0]?.consentForm == 1
                    ? navigate(APP_ROUTES.MAINDASHBOARD, { state: "dashboard" })
                    : // : navigate(APP_ROUTES.CONSENT);
                      navigate(APP_ROUTES.MAINDASHBOARD, {
                        state: "dashboard",
                      });
                } else {
                  const userCode =
                    emailLoginResponse?.data?.succesObject?.[0]?.userCode;
                  if (cartdata) {
                    let payload = {
                      userCode: userCode,
                      cartType: 1,
                      cartVOList: [
                        {
                          userId: 110,
                          userCode: userCode,
                          planId: cartdata.prop2,
                          planName: cartdata.prop1,
                          period: cartdata?.prop3,
                        },
                      ],
                    };

                    const res = await updateCart(payload);
                    if (res?.status === 200 && res?.data?.responseCode) {
                      let userData = {
                        userCode: userCode,
                      };
                      loginservice
                        .cartList(userData)
                        .then((res) => {
                          dispatch({
                            type: COMMONCONSTANTS.SUCCESS_FETCHCART,
                            payload: res?.data?.succesObject,
                          });
                        })
                        .catch((error) => {});
                      // setSnackbarMessage('Added to the cart', 'success')
                    }
                  }

                  navigate(APP_ROUTES.MAINDASHBOARD, { state: "dashboard" });
                }
              } else if (isSelfLoginResponse.data.succesObject.self === "Y") {
                setShowModal(true);
              } else {
                if (emailLoginResponse?.data?.succesObject[0]?.patientCode) {
                  // emailLoginResponse.data.succesObject[0].consentForm === "Y"
                  emailLoginResponse.data.succesObject[1]?.[0]?.consentForm == 1
                    ? navigate(APP_ROUTES.MAINDASHBOARD, { state: "dashboard" })
                    : // : navigate(APP_ROUTES.CONSENT);
                      navigate(APP_ROUTES.MAINDASHBOARD, {
                        state: "dashboard",
                      });
                } else {
                  const userCode =
                    emailLoginResponse?.data?.succesObject?.[0]?.userCode;
                  if (cartdata) {
                    let payload = {
                      userCode: userCode,
                      cartType: 1,
                      cartVOList: [
                        {
                          userId: "",
                          userCode: userCode,
                          planId: cartdata.prop2,
                          planName: cartdata.prop1,
                          period: cartdata?.prop3,
                        },
                      ],
                    };

                    const res = await updateCart(payload);
                    if (res?.status === 200 && res?.data?.responseCode) {
                      let userData = {
                        userCode: userCode,
                      };
                      loginservice
                        .cartList(userData)
                        .then((res) => {
                          dispatch({
                            type: COMMONCONSTANTS.SUCCESS_FETCHCART,
                            payload: res?.data?.succesObject,
                          });
                        })
                        .catch((error) => {});
                      // setSnackbarMessage('Added to the cart', 'success')
                    }
                  }

                  navigate(APP_ROUTES.MAINDASHBOARD, { state: "dashboard" });
                }
              }
            }
          }
        } else {
          setErr(emailLoginResponse?.data?.responseMessage);
        }
      }
      newEmail();
    }
  };

  // const keyDownHandler = event => {
  //   console.log(event)
  //   if (event.key === 'Enter') {
  //     event.preventDefault();

  //     handleNextEmail()
  //   }
  // };
  // document.addEventListener("keydown", (e) => { keyDownHandler(e) })

  const setSeconds = async () => {
    clearInputFields();
    setTimer(60);
    async function fetchOTP() {
      const otp = await fetchOtp({
        userMobileNumber: inputValue,
        mobileCheck: 1,
      });
      if (otp.status == 200) {
        // setOTP(otp?.data?.data);
        setSent(true);
      } else {
        setSent(false);
      }
    }
    fetchOTP();
  };

  useEffect(() => {
    if (isSent) {
      if (!seconds) return;
      const interval = setInterval(() => {
        const newTime = seconds - 1;
        setTimer(newTime);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, isSent]);

  useEffect(() => {
    if (form === "phone") {
      async function fetchOTP() {
        const otp = await fetchOtp({
          userMobileNumber: inputValue,
          mobileCheck: 1,
        });
        if (otp?.data?.responseCode === "200") {
          // setOTP(otp?.data?.data);
          setSent(true);
        } else if (otp?.data?.responseCode === "500") {
          setErr(otp?.data?.responseMessage);
        }
      }
      fetchOTP();
    }
  }, [form]);

  // const onOTPChange = (e) => {
  //   let data = { ...OTPrec };
  //   const { id, value } = e.target;
  //   data[id] = value;
  //   setOTPrec(data);
  // }

  const inputs = useRef([]);

  const onOTPChange = (index, event) => {
    let data = { ...OTPrec };
    const { id, value } = event.target;
    data[id] = value;
    setOTPrec(data);
    const otp = event.target.value;
    if (otp.match(/^[0-9]$/)) {
      // If a valid number is entered, move focus to the next input field
      if (index < inputs.current.length - 1) {
        inputs.current[index + 1].focus();
      }
    } else if (otp === "") {
      // If backspace is pressed, move focus to the previous input field
      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };
  const clearInputFields = () => {
    [0, 1, 2, 3, 4, 5].forEach((index) => {
      const inputField = document.getElementById(`otp${index}`);
      if (inputField) {
        inputField.value = "";
      }
    });
  };

  const handleYes = async () => {
    if (loginDetails?.[0]?.patientCode) {
      loginDetails?.[0].consentForm === "Y"
        ? navigate(APP_ROUTES.MAINDASHBOARD, { state: "dashboard" })
        : // : navigate(APP_ROUTES.CONSENT);
          navigate(APP_ROUTES.MAINDASHBOARD, { state: "dashboard" });
    } else {
      navigate(APP_ROUTES.MAINDASHBOARD, { state: "dashboard" });
    }
    const userCode = loginDetails?.[0]?.userCode;
    if (cartdata) {
      let payload = {
        userCode: userCode,
        cartType: 1,
        cartVOList: [
          {
            userId: 110,
            userCode: userCode,
            planId: cartdata.prop2,
            planName: cartdata.prop1,
            period: cartdata?.prop3,
          },
        ],
      };

      const res = await updateCart(payload);
      if (res?.status === 200 && res?.data?.responseCode) {
        let userData = {
          userCode: userCode,
        };
        loginservice
          .cartList(userData)
          .then((res) => {
            dispatch({
              type: COMMONCONSTANTS.SUCCESS_FETCHCART,
              payload: res?.data?.succesObject,
            });
          })
          .catch((error) => {});
      }
    }
    setShowModal(false);
  };

  const handleNo = async () => {
    const selfCareGiverResponse = await selfCareGiverLogin({
      userMobileNumber: loginDetails?.[0]?.email
        ? null
        : loginDetails?.[0]?.mobile,
      email: loginDetails?.[0]?.email,
    });
    if (
      selfCareGiverResponse.status === 200 &&
      selfCareGiverResponse?.data?.succesObject
    ) {
      localStorage.setItem(
        "loginObj",
        JSON.stringify(selfCareGiverResponse.data.succesObject)
      );
      dispatch(
        updateUserData(JSON.stringify(selfCareGiverResponse.data.succesObject))
      );
      navigate(APP_ROUTES.MAINDASHBOARD, { state: "dashboard" });
    }
    setShowModal(false);
  };

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className=" select-none xs-overflow-x-hidden  md:absolute md:top-10 w-full">
      {showModal && (
        <div className="fixed inset-0 z-10 flex items-center justify-center bg-gray-900 bg-opacity-75">
          <div className="modal bg-white rounded-lg p-6">
            <h2 className="text-2xl font-bold mb-4">
              Would you like to continue as a caregiver?
            </h2>
            <div className="flex justify-center">
              <button
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded mr-2"
                onClick={handleYes}
              >
                Yes
              </button>
              <button
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded mr-2"
                onClick={handleNo}
              >
                No
              </button>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          position: "absolute",
          width: "100%",
          // height: "100%",
          display: "flex",
          maxWidth: "1400px",
          flexDirection: "column",

          // backgroundImage: `linear-gradient(175.92deg, rgba(34, 68, 119, 0.47) 6.62%,rgba(57, 97, 158, 0.06) 92.88%)`,
        }}
        className="max-w-1400"
      >
        <div className="w-full flex flex-col items-center pt-10">
          <h1
            className="text-center sm:text-[35px] text-3xl pl-5 sm:pl-0 pr-5 sm:pr-0 leading-10 sm:leading-none font-poppins font-bold sm:mt-10 tracking-normal text-white sm:text-[#0B4881]
          
            sm:pt-10 pt-12"
          >
            Protect the hands that raised you
          </h1>
          <p className="text-white sm:text-[#0B4881] text-center  sm:leading-8 sm:text-[20px] text-sm  font-poppins font-normal sm:mb-0 mb-5 hidden sm:block">
            Round the clock access to our advanced healthcare solutions
            <br></br> Curated for the unique needs of the elderly
          </p>
        </div>

        <div className="flex sm:justify-end justify-center sm:pr-44 sm:py-16 pr-4 pl-4 ht-unset pb-div-btm-20 mt-12 sm:mt-0">
          {form === "phone" ? (
            <Card
              sx={{
                width: "410px",
                height: "450px",
                borderColor: "#396DDD52",
                borderWidth: "0.5px",
                borderRadius: "20px",
                backgroundColor: "#F4F8FE",
              }}
            >
              <div className="grid justify-center px-8 ">
                <img
                  className="px-20 pt-4 h-auto"
                  src={logo}
                  alt="curebay"
                ></img>
                <Typography
                  sx={{
                    fontSize: "22px",
                    fontWeight: 700,
                    fontFamily: "sans-serif",
                    paddingTop: "1rem",
                    color: "#0B4881",
                  }}
                >
                  Enter OTP
                </Typography>

                <div className="grid ">
                  <label className=" text-brand-lightBlack font-poppins pl-2 pt-4 text-[14px]">
                    {" "}
                    Email/Mobile Number
                  </label>
                  <input
                    className="focus:bg-password-gray px-2 border h-10 focus:outline-none rounded-sm"
                    type="text"
                    value={inputValue}
                  ></input>
                </div>
                {!err && (
                  <div className="grid pt-2">
                    <label className=" text-brand-lightBlack font-poppins pl-2 pt-4">
                      {" "}
                      OTP
                    </label>
                    <div className="flex pt-7">
                      {[0, 1, 2, 3, 4, 5].map((index) => (
                        <input
                          key={index}
                          className="border-2 m-2  border-chathams-blue-50 sm:w-12 w-8 rounded-md text-center sm:h-12 h-8"
                          id={`otp${index}`}
                          type="text"
                          maxLength="1"
                          min="0"
                          max="9"
                          onChange={(e) => onOTPChange(index, e)}
                          onKeyDown={(e) => {
                            if (index === 5) {
                              if (e.key === "Enter") {
                                handleNextPhone();
                              }
                            }
                          }}
                          ref={(input) => (inputs.current[index] = input)}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {!err && (
                  <span
                    onClick={() => {
                      // seconds <= 0 &&
                      setSeconds();
                    }}
                    className={`${
                      seconds === 0
                        ? "text-brand-secondary cursor-pointer "
                        : "text-gray-secondary cursor-not-allowed "
                    } text-blue-500 text-lg font-bold font-sans ml-auto capitalize cursor-pointer"`}
                  >
                    {`Resend ${seconds > 0 ? "in 00:" + seconds : ""}`}{" "}
                  </span>
                )}
                {err && (
                  <span
                    className={`${
                      err === "OTP sent" ? "text-green-500 " : "text-red-600 "
                    }  font-normal text-sm  tracking-widest py-4`}
                  >
                    {err}
                  </span>
                )}
                <button
                  variant="contained"
                  className="bg-[#004171] focus:outline-none px-2 border h-10 rounded-[132px] w-full text-white"
                  onClick={(e) => {
                    handleNextPhone();
                  }}
                >
                  Next
                </button>
              </div>
            </Card>
          ) : form === "email" ? (
            <Card
              sx={{
                width: "400px",
                height: "450px",
                borderColor: "#396DDD52",
                borderWidth: "0.5px",
                borderRadius: "20px",
                backgroundColor: "#F4F8FE",
              }}
            >
              <div className="grid justify-center px-8   ">
                <img
                  className="px-20 pt-2 h-auto"
                  src={logo}
                  alt="curebay"
                ></img>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 700,
                    fontFamily: "poppins",
                    paddingTop: "1rem",
                    color: "#0B4881",
                  }}
                >
                  Enter Password
                </Typography>
                <div className="grid pt-1">
                  <label className=" font-poppins pl-1 pt-3 pb-1 text-[14px]">
                    {" "}
                    Email/Mobile Number
                  </label>
                  <input
                    className="focus:bg-password-gray px-2 focus:outline-none border h-10 rounded-[132px]"
                    type="text"
                    onChange={handleInputChange}
                    value={inputValue}
                  ></input>
                </div>
                <div className="grid py-1">
                  <label className=" font-poppins pl-1 pt-2 pb-1 text-[14px]">
                    {" "}
                    Password
                  </label>
                  <InputWithIcon
                    type="password"
                    onChange={(e) => setPassword(e?.target?.value)}
                    onKeyDown={(e) => {
                      console.log(e.key);
                      if (e.key === "Enter") {
                        handleNextEmail();
                      }
                    }}
                  />
                  {/* <input
                      type="password"
                      onChange={(e) => setPassword(e?.target?.value)}
                      onKeyDown={(e) => { console.log(e.key); if (e.key === "Enter") { handleNextEmail(); } }}
                    /> */}
                </div>
                {err && (
                  <span
                    className={`${
                      err === "OTP sent" ? "text-green-500 " : "text-red-600 "
                    }  font-normal text-sm  tracking-widest`}
                  >
                    {err}
                  </span>
                )}
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "sans-serif",
                    paddingTop: "1rem",
                    color: "#215DAB",
                  }}
                >
                  <span
                    className="text-[#215DAB] cursor-pointer font-poppins  "
                    onClick={() => {
                      navigate(APP_ROUTES.FORGOTPSWD, {
                        state: { customData: inputValue },
                      });
                    }}
                  >
                    Forgot Password?
                  </span>
                </Typography>

                <button
                  variant="contained"
                  className="bg-[#004171] focus:outline-none  border h-10 rounded-[132px] w-full text-white mt-4"
                  onClick={() => handleNextEmail()}
                >
                  Next
                </button>
              </div>
            </Card>
          ) : (
            <Card
              sx={{
                width: "340px",
                height: "400px",
                borderColor: "#396DDD52",
                borderWidth: "0.5px",
                borderRadius: "20px",
                backgroundColor: "#F4F8FE",
              }}
            >
              <div className="grid justify-center px-8">
                <img
                  className="px-14 pt-4 h-auto"
                  src={logo}
                  alt="curebay"
                ></img>
                <Typography
                  sx={{
                    fontSize: "24px",
                    fontWeight: 700,
                    fontFamily: "poppins",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                    color: "#0B4881",
                  }}
                >
                  Login
                </Typography>

                <div className="grid pb-6 ">
                  <label className="font-poppins font-normal text-[14px] pb-1 ">
                    {" "}
                    Email/Mobile Number
                  </label>
                  <input
                    className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px]"
                    type="text"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleNext();
                      }
                    }}
                    onChange={handleInputChange}
                  ></input>
                </div>
                {validateMessage && (
                  <span
                    className={
                      "text-red-600  font-normal text-xs  tracking-widest"
                    }
                  >
                    {validateMessage}
                  </span>
                )}
                <button
                  className="bg-[#004171] focus:outline-none px-2 border h-10 rounded-[132px] w-full text-white"
                  variant="contained"
                  onClick={handleNext}
                >
                  Next
                </button>

                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    fontFamily: "poppins",
                    paddingTop: "2rem",
                    textAlign: "center",
                  }}
                >
                  Don’t have an account?
                  <span
                    className="text-bluish cursor-pointer font-poppins "
                    onClick={() => {
                      navigate(APP_ROUTES.SIGNUP);
                    }}
                  >
                    {" "}
                    Sign Up
                  </span>
                </Typography>
              </div>
            </Card>
          )}
        </div>
      </div>
      <img
        className="w-full sm:h-[867px] h-full sm:block hidden "
        src={login}
        alt={login}
        id="login"
        style={{}}
      />

      <img
        className="w-full h-screen sm:hidden block"
        src={loginmob}
        alt={login}
        id="login"
      />

      <div className="hidden sm:block">
        {/* {screen > 500 ? <Footer i={1} /> : <MobileFooter />} */}
        <Footer />
      </div>
    </div>
  );
};

export default Index;
