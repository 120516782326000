import React, { useEffect, useState } from "react";
import logoimg from "../../webflowimages/curebay_logo.png";

import Frame500 from "../../webflowimages/Frame-1171277694-1-p-500.png";
import Frame800 from "../../webflowimages/Frame-1171277694-1-p-800.png";
import Frame1080 from "../../webflowimages/Frame-1171277694-1-p-1080.png";
import Frame1 from "../../webflowimages/Frame-1171277694-1.png";
import care from "../../webflowimages/care.png";
import services from '../../store/services'
import Aos from "aos";
import 'aos/dist/aos.css'
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import Footer from "../../components/webflow/footer";

const Servicepartnernew = () => {
  const [data, setData] = useState([]);
  const [screen, setscreen] = useState(window.innerWidth);
  useEffect(() => {
    const getAllData = async () => {
      const res = await services.get("/service-partners?populate=deep");
      setData(res?.data?.data)
    }
    getAllData()

  }, [])

  console.log(data, "hgjhjkhjhj")




  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <>
      <div>
        <section className="section" style={{ paddingBottom: "0px" }}>
          <div className="container-default position-relative">
            <div className="section hero home-v1" style={{ paddingBottom: "10px" }}>
              <div className="w-layout-grid grid-2-columns home-hero-grid-2-col v1">
                <div
                  id="w-node-_12c2f87a-71e1-0816-e76c-397f488e8382-4d0e8e62"
                  data-w-id="12c2f87a-71e1-0816-e76c-397f488e8382"

                  className="inner-container minw-480px _100---tablet"
                >
                  <h1 className="display-1 mg-bottom-8px" data-aos="fade-right" >
                    Finest Service Partners
                  </h1>
                  <div className="inner-container _518px _100---tablet" data-aos="fade-right">
                    <p className="mg-bottom-32px mg-bottom-20px-tablet">{data?.[0]?.attributes?.firstcomp?.description}</p>
                  </div>
                  <div className="buttons-row mg-bottom-52px mg-bottom-32px-tablet" />
                  <div>
                    <div className="mg-bottom-24px mg-bottom-16px-tablet" />
                  </div>
                </div>
                <div data-w-id="12c2f87a-71e1-0816-e76c-397f488e839e"

                  className="home-hero-picture v1">
                  <img src={data?.[0]?.attributes?.firstcomp?.image_url}
                    loading="eager"

                    //  sizes="(max-width: 479px) 83vw, (max-width: 767px) 90vw, (max-width: 991px) 92vw, (max-width: 1439px) 42vw, 610px" 
                    //  srcSet="images/Frame-1171277694-1-p-500.png 500w, images/Frame-1171277694-1-p-800.png 800w, images/Frame-1171277694-1-p-1080.png 1080w, images/Frame-1171277694-1-p-1600.png 1600w, images/Frame-1171277694-1.png 1653w" 
                    alt="" className="fit-cover _w-h-100" data-aos="fade-left" /></div>

              </div>
              <section className="section" style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                <h2 className="display-2 sm:mt-20 mt-7" data-aos="slide-up">Top Partners</h2>
                <div className="container-default w-container">
                  <div className="div-block-8">
                    {data?.[0]?.attributes?.ThirdComp?.content?.map((value) => (
                      <div
                        id="w-node-ca9bb868-25ac-3515-1acd-4272385fbadc-4d0e8e62"
                        className="grid patnercard mt-6"
                      >
                        <div className="text-center">
                          <img
                            src={value.image_url}
                            loading="lazy"
                            width={119}
                            alt=""
                            className="icon"
                          />
                        </div>
                        <div>
                          <h5 className="text-center mt-[25px]">{value.name}</h5>
                          <div className="tab-body">
                            {value.description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                </div>
              </section>
            </div>
            <div className="half-bg-right home-v1-bg-right" />
          </div>
        </section>
        <section className="section">
          <div className="w-layout-blockcontainer container-default w-container">
            <div
              data-w-id="ef32ff7f-9d8e-37e4-9d8a-e75df427482f"
              data-aos="slide-up"
              className="heading-flex align-bottom"
            >
              <div
                id="w-node-ef32ff7f-9d8e-37e4-9d8a-e75df4274830-4d0e8e62"
                className="inner-container _690px _100---tablet"
              >
                <h2 className="display-2 mg-bottom-0">
                  Industry Leading Partner
                </h2>
              </div>
              <div id="w-node-ef32ff7f-9d8e-37e4-9d8a-e75df4274835-4d0e8e62" />
            </div>
            <div className="div-block-2">


              {data?.[0]?.attributes?.SecondComp?.content1?.map((value) => (
                <>
                  <div id="w-node-ef32ff7f-9d8e-37e4-9d8a-e75df4274839-4d0e8e62" >
                    <h3 id="w-node-ef32ff7f-9d8e-37e4-9d8a-e75df427483a-4d0e8e62" className="card-title-dark"><strong className="bold-text-2">{value?.name}</strong></h3>


                    <div className="sm:grid-cols-8 grid-cols-3 logobox items-center sm:mb-0 mb-4">
                      {value?.label?.map((value1) => (
                        <div>
                          <img src={value1?.image_url} loading="lazy" alt="" className="logo sm:w-[7vw] w-[17vw]" />
                        </div>
                      ))}
                    </div>

                  </div>
                </>
              ))}

              {/* <div id="w-node-ef32ff7f-9d8e-37e4-9d8a-e75df427483d-4d0e8e62" className="card partners"><img src="images/652e9e00ab66a14f1140e56c_hosp5-1.svg.png" loading="lazy" width={77} alt="" className="logo" /><img src="images/652e9e0016f63b469c44082b_download-1-1.svg.png" loading="lazy" width={77} alt="" className="logo" /><img src="images/652e9e011df6961283ce3681_hosp2-1.svg.png" loading="lazy" width={77} alt="" className="logo" />
  <img src="images/652e9e00b7b888907638560c_hosp3-1.svg.png" loading="lazy" width={77} alt="" className="logo" />
  <img src="images/652e9e01fdbcb368db8fdd25_Care-Hospitals-logo-1.svg.png" loading="lazy" width={77} alt="" className="logo" /><img src="images/652e9e62223ed68013685883_Sunflower-nursing-home-1.svg.png" loading="lazy" width={77} alt="" className="logo" /><img src="images/652e9e00befbcffd62c2bb8d_hosp12-1.svg.png" loading="lazy" width={77} alt="" className="logo" />
  <img src="images/652e9e626f3c78eb86c6646b_Maa-shakti-hospital-logo-1.svg.png" loading="lazy" width={77} alt="" className="logo" /><img src="images/652e9e00878288ffe9501793_hosp7-1.svg.png" loading="lazy" width={77} alt="" className="logo" />
  </div> */}

            </div>
          </div>
        </section>

        {screen > 500 ? (
          <Footer />
        ) : (
          <>
            <FooterMobileVersion />
            <MobileFooter />
          </>
        )}
      </div>
    </>
  );
};

export default Servicepartnernew;
