import React, { useEffect, useState } from "react";
import phone from "./image/phn.png";
import Mail from "./image/mail.png";
import Dashboard from "./image/dashboard icon.png";
import fb from "./image/fb.svg";
import insta from "./image/insta.svg";
import yt from "./image/yt.svg";
import logo from "./image/Medicare_Logo.png";
import homee from "./image/homee.svg";
import shop from "./image/shoping.svg";
import Button from "@mui/material/Button";
import caregiver from "./image/caregiver.svg";
import login from "./image/login.png";
import notif from "./image/noti.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Avatar,
  Icon,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { APP_ROUTES } from "../../constants/approutes";
import { Link, useLocation, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";
import { getCoords } from "../../store/actions/commonactions";
import {
  getCureBayExpContent,
  getCureBayStatistics,
  getDoctorsPageContent,
  getHomePageContent,
} from "../../store/actions/homepage";
import { getOurCareplans } from "../../store/actions/howitworks";
import { updateUserData } from "../../store/actions/useractions";
import { COMMONCONSTANTS } from "../../store/constants/commonconstants";
import loginservice from "../../store/services/loginservice";
import Userprofilesidebar from "./userProfilesidebar";
import { LogoutOutlined } from "@mui/icons-material";

const navigation = [
  { name: "CureBay Experience", route: APP_ROUTES.HOWITWORKS, current: true },
  {
    name: "Care Plans",
    href: APP_ROUTES.CARE_PLANS,
    routes: [
      { route: APP_ROUTES.GENERAL_HEALTH, name: "General Health" },
      { route: APP_ROUTES.DIABETICS_CARE, name: "Diabetes Care" },
      { route: APP_ROUTES.HEARTHEALTH_CARE, name: "Cardiac Care" },
      { route: APP_ROUTES.WOMENSHEALTH_CARE, name: "Womens' Health" },
      { route: APP_ROUTES.ORTHOPEDICS_CARE, name: "Ortho Care" },
    ],
    // routes: carePlans?.map(carePlan => ({
    //   route: carePlan?.planName,
    //   name: carePlan?.planName
    // })),
  },
  // { name: "Doctors", route: APP_ROUTES.DOCTORS, current: false },
  // { name: "Locations", route: APP_ROUTES.LOCATIONSPAGE, current: false },
  // { name: "Reviews", route: APP_ROUTES.REVIEWS, current: false },
  // {
  //   name: "About Us",
  //   route: APP_ROUTES.ABOUTUS,
  //   // routes: [
  //   //   { route: APP_ROUTES.ABOUTUS, name: "Gallery" },

  //   // ],

  // },
];

const HEADER = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isHovered, setIshovered] = useState(false);
  const [open, setOpen] = useState(null);
  const [carePlans, setCarePlans] = useState([]);
  const { statisticsCount } = useSelector((state) => state.HomePageReducer);

  useEffect(() => {
    if (localStorage.getItem("loginObj"))
      dispatch(
        updateUserData(JSON.stringify(localStorage.getItem("loginObj")))
      );
    dispatch(getCureBayStatistics());
  }, [dispatch]);


  const handleClick = (e, itemName) => {
    setOpen(itemName);
    setAnchorEl(e.currentTarget);
  };
  const handleHoverClose = () => {
    setIshovered(false);

    console.log(isHovered);
  };
  const handleHoverOpen = () => {
    setIshovered(true);
    console.log(isHovered);
  };
  const handleClose = (e) => {
    setOpen(null);
    setAnchorEl(null);
  };

  const handleMenuClick = (item) => {
    handleClose();
    if (item.href) {
      navigate(item.href);
    } else if (item.route) navigate(item.route);
  };

  const handleAvatarClick = (e) => {
    const ID = e?.target?.id;
    handleClose();

    const actions = {
      myProfile: () => navigate(APP_ROUTES.MYDETAILS),
      changePassword: () => navigate(APP_ROUTES.PASSCHANGE),
      signOut: logout,

      myDetails: () => navigate(APP_ROUTES.MYDETAILS),
      myPlan: () => navigate(APP_ROUTES.MYPLAN),
      memberProfile: () => navigate(APP_ROUTES.MEMBERDETAILS),
      myPrescription: () => navigate(APP_ROUTES.MYPRESCRIPTION),
      myLabreports: () => navigate(APP_ROUTES.MYLABREPORTS),
      myVitals: () => navigate(APP_ROUTES.MYVITALS),
      myReview: () => navigate(APP_ROUTES.MYREVIEW),
      myDashboard: () => navigate(APP_ROUTES.MAINDASHBOARD),
    };
    const action = actions[ID];
    if (action) {
      action();
    }
  };

  useEffect(() => {
    dispatch(getHomePageContent());
    dispatch(getDoctorsPageContent());
    dispatch(getCureBayExpContent());
  }, [dispatch]);

  const [userAvatar, setUserAvatar] = useState(null);
  const { cartListLength } = useSelector((state) => state.CommonReducer);

  useEffect(() => {
    if (userAvatar === null) {
      let loginObjStr = localStorage.getItem("loginObj");
      let loginObj = JSON.parse(loginObjStr);
      let userName = loginObj?.[0]?.firstName ? loginObj?.[0]?.firstName : "";
      let userAvatar = Array.from(userName)[0];
      setUserAvatar(userAvatar);
    }
  }, [userAvatar]);

  const coords = useSelector((state) => state.CommonReducer.coords);
  useEffect(() => {
    if (!coords) {
      navigator.geolocation.getCurrentPosition(function (position) {
        dispatch(getCoords(position));
      });
    }
  }, [dispatch, coords]);

  const userData = useSelector((state) => state.AuthReducer.userData);
  useEffect(() => {
    if (!userData.length && !localStorage.getItem("loginObj")) return;

    let data = {};

    if (userData.length) {
      data = JSON.parse(userData);
    } else {
      data = JSON.parse(localStorage.getItem("loginObj"));
    }
    if (data?.[0]?.userCode || data?.[0]?.code) {
      let userName = data?.[0]?.firstName?.[0]
        ? Array.from(data[0].firstName[0])
        : "P";
      setUserAvatar(userName);
    }
    let user = {
      userCode: data?.[0]?.userCode,
    };
    loginservice
      .cartList(user)
      .then((res) => {
        dispatch({
          type: COMMONCONSTANTS.SUCCESS_FETCHCART,
          payload: res?.data?.succesObject,
        });
      })
      .catch((error) => { });
  }, [userData, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const carePlansResponse = await getOurCareplans();
      if (
        carePlansResponse.status === 200 &&
        carePlansResponse?.data?.succesObject
      ) {
        setCarePlans(carePlansResponse.data.succesObject);
      }
    };

    fetchData();
  }, []);

  const logout = () => {
    dispatch(updateUserData({}));
    localStorage.clear();
    navigate(APP_ROUTES.DASHBOARD);
    if (
      typeof window !== "undefined" &&
      typeof window.location !== "undefined"
    ) {
      window.location.reload();
    }
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [screen, setscreen] = useState(window.innerWidth);
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    window.fwSettings = {
      widget_id: 1070000000524,
      position: "bottom-left",
      alignment: "left",
    };

    if (typeof window.FreshworksWidget !== "function") {
      class n {
        constructor() {
          n.q.push(arguments);
        }
      }
      n.q = [];
      window.FreshworksWidget = n;
    }

    const script = document.createElement("script");
    script.src = "https://ind-widget.freshworks.com/widgets/1070000000524.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="select-none relative top-0 z-10">
      <div>
        <div className="sm:flex justify-center md:shrink-0 lg:h-12 bg-darkBlue flex-wrap hidden">
          <img className="h-5 pr-2 mt-3" src={homee}></img>
          <p className="text-white items-center pt-3 font-poppins font-normal text-[16px]">
            With <span className="font-bold">{statisticsCount?.succesObject?.eclinicsCount} eClinic </span>{" "}
            currently functioning and 100 more on their way! Find an{" "}
            <span
              className="font-bold underline underline-offset-2 cursor-pointer"
              onClick={() => {
                navigate(APP_ROUTES.LOCATIONSPAGE);
              }}
            >
              {" "}
              eClinic near{" "}
            </span>{" "}
            Care Receiver's location.
          </p>
          {/* <div className='flex flex-wrap px-8 text-white'>
            <div className='flex items-center px-5 '>
              <img className='lg:w-3 lg:h-3 ' src={phone} alt='phone' />
              <div className='pl-2 '>+91-8334 000 999</div>
            </div>
            <div className='flex items-center px-5'>
              <img className='lg:w-3 lg:h-3 ' src={Mail} alt='Mail' />
              <div className='pl-2'>contact@curebay.com</div>
            </div>
          </div> */}
          {/* <div className='sm:flex sm:flex-row-reverse border-x-2 border-darkerBlue relative right-16 lg:right-20 hidden'>
            <img className=' p-3 px-3' src={yt} alt='yt' />
            <img className=' border-x-2 p-3 border-darkerBlue ' src={insta} alt='insta' />
            <img className=' p-3 px-4' src={fb} alt='fb' />
          </div> */}
        </div>
        <div className="flex justify-around bg-darkBlue flex-wrap sm:hidden">
          {/* <div className='flex flex-col justify-start ml-1 text-white'>
            <div className='flex items-center pl-5 '>
              <img className='lg:w-3 lg:h-3 ' src={phone} alt='phone' />
              <div className='pl-2 text-sm'>+91-8334 000 999</div>
            </div>
            <div className='flex items-center pl-5 '>
              <img className='lg:w-3 lg:h-3 ' src={Mail} alt='Mail' />
              <div className='pl-2 text-sm'>medicare@curebay.com</div>
            </div>
          </div> */}
          {/* <div className='flex flex-row-reverse border-2 border-darkerBlue ml-3 '>
            <img className=' p-3 px-3' src={yt} alt='yt' />
            <img className=' border-x-2 p-3 border-darkerBlue ' src={insta} alt='insta' />
            <img className=' p-3 px-4' src={fb} alt='fb' />
          </div> */}
        </div>
      </div>
      <div className="flex justify-around items-center  md:h-20  px-2 py-3  ">
        <div className="lg:hidden">
          {/* <IconButton >
            <MenuIcon  
            // state={state}
            // toggleDrawer={toggleDrawer}
            onClick={() =>navigate(APP_ROUTES.SIDEBAR)} />
            </IconButton> */}
          <Userprofilesidebar />
          <div>
            {/* <Userprofilesidebar state={state}
            toggleDrawer={toggleDrawer}/> */}
            {/* <i class="fa fa-bars" aria-hidden="true"  state={state}
            toggleDrawer={toggleDrawer}  onClick={() =>navigate(APP_ROUTES.SIDEBAR)} ></i> */}
          </div>
        </div>
        <img
          className="w-2/6 sm:w-[218px] sm:h-auto sm:mt-3 mr-12 sm:mr-0 cursor-pointer transition-all ease-linear sm:hover:-translate-y-1"
          src={logo}
          alt="CureBay"
          draggable={false}
          onClick={() => {
            navigate(APP_ROUTES.DASHBOARD);
          }}
        />
        <div className="lg:flex w-[28rem] hidden justify-start mr-[15rem]">
          {navigation.map((item) => (
            <div className="flex flex-row w-full justify-start" key={item.name}>
              {item.route && (
                <Link key={item.name} to={item.route}>
                  <p
                    key={item?.name}
                    className={` my-auto mx-auto font-poppins font-bold text-[18px]  ${location.pathname === item.route
                      ? "text-brand-lightgreen underline "
                      : "text-shuttle-gray-800 transition-all ease-linear sm:hover:text-seegreen sm:hover:underline sm:hover:-translate-y-1"
                      } `}
                  >
                    {item.name}
                  </p>
                </Link>
              )}
              {item.routes && (
                <div
                  className="cursor-pointer"
                  key={item.name}
                  onMouseEnter={(e) => handleClick(e, item.name)}
                >
                  <div className="flex flex-row items-center justify-center">
                    <p
                      className={`ml-3 my-auto mx-auto   font-poppins font-bold text-[18px] ${location.pathname === item.href
                        ? "text-brand-lightgreen underline"
                        : "text-shuttle-gray-800 "
                        } `}
                      onClick={() => {
                        handleMenuClick(item);
                      }}
                    >
                      {item.name}
                    </p>
                    {/* <IconButton
                      id={item.name}
                      aria-controls={open === item.name ? item.name : undefined}
                      aria-haspopup="true"
                      aria-expanded={open === item.name ? 'true' : undefined}
                      onClick={(e) => handleClick(e, item.name)}
                      sx={{ borderRadius: 0, maxWidth: '18px', maxHeight: '18px', }}
                    >
                      <KeyboardArrowDownIcon fontSize='small' />
                    </IconButton> */}
                  </div>
                  <Menu
                    id={item.name}
                    anchorEl={anchorEl}
                    open={open === item.name}
                    onClose={handleClose}
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    transformOrigin={{ vertical: "top", horizontal: "center" }}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    {item.routes.map((e, index) => {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => handleMenuClick(e)}
                        >
                          {e.name}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="flex gap-0 sm:gap-6 mr-0 sm:mr-4">
          {userData.length && JSON.parse(userData)?.[0]?.userCode ? (
            <div className="relative flex gap-3">
              {/* <img className='w-6 h-7 mt-2' src={notif}></img> */}
              <button
                onClick={(e) => navigate(APP_ROUTES.CART)}
                className="p-2 lg:flex  md:block "
              >
                <span className="sr-only">Cart</span>
                {cartListLength?.length > 0 || cartListLength?.length > 0 ? (
                  <div>
                    <p className="text-size absolute text-center text-xs -mt-1 text-white font-medium bg-brand-lightgreen rounded-xl h-4 w-4">
                      {cartListLength.length <= 9
                        ? cartListLength.length
                        : "9+"}
                    </p>
                    <img
                      className="w-6 h-6 md:w-8 md:h-7"
                      src={shop}
                      alt={shop}
                      id="shoping"
                    />
                  </div>
                ) : (
                  //<h1>{cartList?.patientDrugPrescriptionOrder?.length}</h1>
                  <img
                    className="w-6 h-6 md:w-8 md:h-7"
                    src={shop}
                    alt={shop}
                    id="shoping"
                  />
                )}
              </button>
            </div>
          ) : (
            <div className="relative" />
          )}

          {/* 
          {!(isLoggedIn && ((userData.length && JSON.parse(userData)?.[0]?.userCode) || (userData.length && JSON.parse(userData)?.[0]?.code))) ? (
            <button className='flex h-8 w-24 items-center mr-1 sm:mr-2 justify-center bg-seegreen capitalize border-2 border-seegreen z-50 rounded' onClick={() => { navigate(APP_ROUTES.SIGNUP) }}>
              <img className='p-1' src={login} alt='img' />
              <span className='text-xs p-1 text-white sm:text-sm font-poppins'>Sign Up</span>
            </button>
          ) : (
            <button onClick={() => { navigate(APP_ROUTES.MAINDASHBOARD) }} className='flex h-10 w-40 items-center  justify-center bg-white capitalize border-2 border-chathams-blue-500 z-50 rounded'>
              <img className='p-1' src={Dashboard} alt='img' />
              <span className='text-[15px] font-poppins  font-semibold'>My Dashboard</span>
            </button>
          )} */}

          <div className="flex flex-row justify-center items-center ">
            {!(
              (userData.length && JSON.parse(userData)?.[0]?.userCode) ||
              (userData.length && JSON.parse(userData)?.[0]?.code)
            ) ? (
              <button
                className="font-poppins font-bold text-[18px] mr-5 transition-all ease-linear sm:hover:text-seegreen sm:hover:underline sm:hover:-translate-y-1"
                // variant="outlined"
                onClick={() => {
                  navigate(APP_ROUTES.LOGIN);
                }}
                sx={{
                  textTransform: "capitalize",
                  fontFamily: "poppins",
                  fontWeight: "600",
                  fontSize: "17px",
                }}
              // startIcon={<img src={login} alt='img' />}
              >
                Login
              </button>
            ) : (
              <button
                onClick={() => {
                  navigate(APP_ROUTES.MAINDASHBOARD);
                }}
                className="sm:flex bg-[#e6f4fd] hidden sm:block h-10 w-40 items-center  justify-center bg-white capitalize border-2 border-chathams-blue-500 z-50 rounded"
              >
                <img className="p-1" src={Dashboard} alt="img" />
                <span className="text-[15px] font-poppins  font-semibold">
                  My Dashboard
                </span>
              </button>
            )}

            {!(
              (userData.length && JSON.parse(userData)?.[0]?.userCode) ||
              (userData.length && JSON.parse(userData)?.[0]?.code)
            ) ? (
              <button
                className="flex h-8 w-24 items-center mr-1 sm:mr-2 justify-center bg-seegreen capitalize border-2 border-seegreen z-50 rounded"
                onClick={() => {
                  navigate(APP_ROUTES.SIGNUP);
                }}
              >
                <img className="p-1" src={login} alt="img" />
                <span className="text-xs p-1 text-white sm:text-sm font-poppins font-semibold">
                  Sign Up
                </span>
              </button>
            ) : userAvatar?.length > 0 ? (
              <div className="ml-2 sm:ml-5">
                <Avatar
                  sx={{
                    bgcolor: "#0272DA",
                    height: "41px",
                    width: "41px",
                    color: "white",
                    cursor: "pointer",
                  }}
                  onClick={(e) => handleClick(e, userAvatar)}
                >
                  {userAvatar}{" "}
                </Avatar>
                {screen < 500 ? (
                  <>
                    <Menu
                      anchorEl={anchorEl}
                      open={open === userAvatar}
                      onClose={handleClose}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        id="myDashboard"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        Dashboard
                      </MenuItem>
                      <MenuItem
                        id="myProfile"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        My Profile
                      </MenuItem>

                      <MenuItem
                        id="myDetails"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        {" "}
                        My Details
                      </MenuItem>
                      <MenuItem
                        id="myPlan"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        {" "}
                        My Plan
                      </MenuItem>
                      <MenuItem
                        id="memberProfile"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        Member Profile
                      </MenuItem>
                      <MenuItem
                        id="myPrescription"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        {" "}
                        Prescription
                      </MenuItem>
                      <MenuItem
                        id="myLabreports"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        {" "}
                        Lab Reports
                      </MenuItem>
                      <MenuItem
                        id="myVitals"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        {" "}
                        Vitals
                      </MenuItem>
                      <MenuItem
                        id="myReview"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        {" "}
                        Review
                      </MenuItem>

                      <MenuItem
                        id="changePassword"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        Change Password
                      </MenuItem>
                      <MenuItem
                        id="signOut"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        Sign Out
                      </MenuItem>
                    </Menu>
                  </>
                ) : (
                  <>
                    <Menu
                      anchorEl={anchorEl}
                      open={open === userAvatar}
                      onClose={handleClose}
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      transformOrigin={{ vertical: "top", horizontal: "right" }}
                      MenuListProps={{
                        "aria-labelledby": "basic-button",
                      }}
                    >
                      <MenuItem
                        id="myProfile"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        My Profile
                      </MenuItem>

                      <MenuItem
                        id="changePassword"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        Change Password
                      </MenuItem>
                      <MenuItem
                        id="signOut"
                        onClick={(e) => handleAvatarClick(e)}
                      >
                        Sign Out
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </div>
            ) : (
              ""
            )}

            {/* <Button variant="outlined"  onClick={() => { navigate(APP_ROUTES.LOGIN) }} sx={{ textTransform: "capitalize" }} startIcon={<img src={login} alt='img' />}>Login</Button> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default HEADER;
