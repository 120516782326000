import React, { useState, useEffect } from "react";
import {
  Box,
  Card,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Tab,
} from "@mui/material";
import { Typography, Button } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useNavigate, useNavigation } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import { fetchConsent, consentData } from "../../store/actions/login";
import { useSnackbar } from "../common/snackbar";
import dashboardservice from "../../store/services/dashboardservice";
import axios from "axios";
import { updateUserData } from "../../store/actions/useractions";
import { useDispatch } from "react-redux";
import http from "../../store/services/httpcommon";
const YesNo = ({ onYesChange, allowedToSeeLabRep }) => {
  const [yes, setYes] = useState(false);
  console.log(allowedToSeeLabRep, "ugyui");
  useEffect(() => {
    if (allowedToSeeLabRep == 1) {
      setYes(true);
    } else {
      setYes(false);
    }
  }, [allowedToSeeLabRep]);
  const handleClick = (value) => {
    setYes(value);
    onYesChange(value);
  };
  return (
    <div className="flex gap-5 justify-center sm:justify-start ">
      <button
        className={`${
          yes
            ? "bg-chathams-blue-200 text-white "
            : "hover:text-white  hover:bg-chathams-blue-200 text-chathams-blue-200 "
        }border-chathams-blue-200 border-2 px-0 sm:px-2 py-0 sm:py-2 rounded-md  font-semibold w-20`}
        style={{ border: "1px solid #396DDD" }}
        onClick={() => handleClick(true)}
      >
        Yes
      </button>
      <button
        className={`${
          !yes
            ? " bg-chathams-blue-200 text-white "
            : "hover:text-white  hover:bg-chathams-blue-200 text-chathams-blue-200 "
        } border-chathams-blue-200 border-2 px-0 sm:px-2 py-0 sm:py-2 rounded-md  font-semibold w-20`}
        onClick={() => handleClick(false)}
      >
        No
      </button>
    </div>
  );
};

const YesNo2 = ({ onYesChange, allowedToSeePrescrp }) => {
  const [yes, setYes] = useState(false);
  console.log(allowedToSeePrescrp, "szxdcfg");
  useEffect(() => {
    if (allowedToSeePrescrp == 1) {
      setYes(true);
    } else {
      setYes(false);
    }
  }, [allowedToSeePrescrp]);
  const handleClick = (value) => {
    setYes(value);
    onYesChange(value);
  };
  return (
    <div className="flex gap-5 justify-center sm:justify-start ">
      <button
        className={`${
          yes
            ? "bg-chathams-blue-200 text-white "
            : "hover:text-white  hover:bg-chathams-blue-200 text-chathams-blue-200 "
        }border-chathams-blue-200 border-2 px-0 sm:px-2 py-0 sm:py-2 rounded-md  font-semibold w-20`}
        style={{ border: "1px solid #396DDD" }}
        onClick={() => handleClick(true)}
      >
        Yes
      </button>
      <button
        className={`${
          !yes
            ? " bg-chathams-blue-200 text-white "
            : "hover:text-white  hover:bg-chathams-blue-200 text-chathams-blue-200 "
        } border-chathams-blue-200 border-2 px-0 sm:px-2 py-0 sm:py-2 rounded-md  font-semibold w-20`}
        onClick={() => handleClick(false)}
      >
        No
      </button>
    </div>
  );
};

const Consent = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [yesValue, setYesValue] = useState(false);
  const handleYesChange = (newValue) => {
    setYesValue(newValue);
  };
  const [yesValue1, setYesValue1] = useState(false);
  const handleYesChange1 = (newValue) => {
    setYesValue1(newValue);
  };
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("1");

  const [isChecked, setIsChecked] = useState(false);
  const [users, setUserLists] = useState([]);
  const [allowedToSeePrescrp, setAllowedToSeePrescp] = useState(true);
  const [allowedToSeeLabRep, setAllowedToSeeLabPrep] = useState(true);
  const [privacyPolicyConsentcompany, setprivacyPolicyConsentcompany] =
    useState("");
  const [termsAndConditionConsentcompany, settermsAndConditionConsentcompany] =
    useState("");
  const handleDailog = () => {
    setOpen(true);
  };
  const handleAgree = () => {
    setIsChecked(true);
    setOpen(false);
    consentformversion();
  };
  const handleDisagree = () => {
    setIsChecked(false);
    setOpen(false);
    withdrawconsentformversion();
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCheck = () => {
    setIsChecked(!isChecked);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
    ? userObj.parentCode
    : null;
  const getVersion = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=PATIENT`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(
        data?.data[0]?.versionNumber,
        data?.data[1]?.versionNumber,
        "ddffgghh"
      );
      setprivacyPolicyConsentcompany(data?.data[0]?.versionNumber);
      settermsAndConditionConsentcompany(data?.data[1]?.versionNumber);
    } catch (e) {
      console.log("Error fetching data:", e);
    }
  };

  useEffect(() => {
    getVersion();
  }, []);
  const handleMove = () => {
    async function fetchData() {
      if (isChecked) {
        consentformversion();
      }
      const res = await consentData({
        patientCode: userObj?.code,
        consentDrRemark: yesValue1 ? 1 : 0,
        consentMedReport: yesValue ? 1 : 0,
        consentForm: isChecked ? 1 : 0,
      });
      if (res.status === 200 && res?.data) {
        navigate(APP_ROUTES.MAINDASHBOARD);
      }
      // } else {
      //   setSnackbarMessage("Please Read and Sign Consent Form", "error");
      // }
    }
    fetchData();
  };

  const consentformversion = async () => {
    // const privacyVersion =
    //   privacyPolicyVersion || getVersionData("PrivacyPolicy");
    // const termsVersion =
    //   termsAndConditionVersion || getVersionData("TermsAndCondition");
    const payload = {
      createdBy: "CUREBAYADMIN",
      privacyPolicyConsent: 1,
      sourceChannel: "Totalcare",
      status: 1,
      termsAndConditionConsent: 1,
      type: "P",
      patientId: userObj?.code,
      privacyVersionNumber: privacyPolicyConsentcompany,
      tcVersionNumber: termsAndConditionConsentcompany,
    };

    console.log("Payload:", payload);
    const postResponse = await http.post(
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
      payload
    );

    console.log("POST Request Response for test", postResponse);
    if (postResponse.status === 200) {
      // setIsVisible(false);
    }
  };

  const withdrawconsentformversion = async () => {
    const payload = {
      createdBy: "CUREBAYADMIN",
      privacyPolicyConsent: 0,
      sourceChannel: "Totalcare",
      status: 1,
      termsAndConditionConsent: 0,
      type: "P",
      patientId: userObj?.code,
      privacyVersionNumber: privacyPolicyConsentcompany,
      tcVersionNumber: termsAndConditionConsentcompany,
    };

    console.log("Payload:", payload);
    const postResponse = await http.post(
      `${process.env.REACT_APP_NEWBASEURL}PatientConsent/addPatientConsent`,
      payload
    );

    console.log("POST Request Response for test", postResponse);
    if (postResponse.status === 200) {
      logout();
    }
  };
  const logout = () => {
    dispatch(updateUserData({}));
    localStorage.clear();
    navigate(APP_ROUTES.DASHBOARD);
    if (
      typeof window !== "undefined" &&
      typeof window.location !== "undefined"
    ) {
      window.location.reload();
    }
  };

  const [consent, setConsent] = useState();
  useEffect(() => {
    async function fetchConsentForm() {
      const res = await fetchConsent();
      if (res.status === 200 && res?.data) {
        setConsent(res?.data?.data[0]);
      }
    }
    fetchConsentForm();
  }, []);
  useEffect(() => {
    getUserLists(userCode);
  }, []);
  const getUserLists = async (userCode) => {
    const reqObj = {
      code: userCode,
      email: userObj?.email,
      mobile: userObj?.mobile,
    };
    const res = await dashboardservice.getUserList(reqObj);
    setUserLists(res?.data?.succesObject);
    setAllowedToSeePrescp(res?.data?.succesObject[0]?.consentDrRemark);
    setAllowedToSeeLabPrep(res?.data?.succesObject[0]?.consentMedReport);
    // console.log(res?.data?.succesObject[0]?.consentDrRemark, "ugbjhnklj");
  };
  return (
    <>
      {snackbarMessage}
      <div className=" flex flex-col justify-center sm:mt-[124px]">
        <div className=" flex w-full mt-36 sm:mt-8 justify-center p-6 sm:p-0">
          <Card
            variant="outlined"
            elevation={1}
            sx={{
              boxShadow: " 0px 10px 13px rgba(0, 0, 0, 0.04)",
              paddingX: "2%",
              height: "33rem",
              borderRadius: "10px",
            }}
          >
            <div className="mx-3 my-6  flex justify-center ">
              <div>
                <p className="text-chathams-blue text-2xl sm:text-3xl font-bold text-center">
                  {consent?.attributes?.firstcomp?.Title}
                </p>
                <p className="text-brand-lightBlack text-sm sm:text-base font-medium mt-5 text-center">
                  {consent?.attributes?.firstcomp?.description}
                </p>
              </div>
            </div>

            <div className="sm:m-5 m-3 ">
              <span className=" mt-0 sm:mt-16 mb-6 sm:text-lg text-base font-medium">
                1.{consent?.attributes?.firstcomp?.Questions[0]?.label}
              </span>
              <YesNo
                onYesChange={handleYesChange}
                // allowedToSeePrescrp={allowedToSeePrescrp}
                allowedToSeeLabRep={allowedToSeeLabRep}
              />
              <span className="mt-10 mb-6 sm:text-lg text-base font-medium">
                2.{consent?.attributes?.firstcomp?.Questions[1]?.label}
              </span>
              <YesNo2
                onYesChange={handleYesChange1}
                allowedToSeePrescrp={allowedToSeePrescrp}
                // allowedToSeeLabRep={allowedToSeeLabRep}
              />
            </div>
            <div className="mt-6 mb-6 pl-6  text-base sm:text-lg font-medium">
              <div className="grid py-2">
                <div className="flex justify-start items-start ">
                  <input
                    className="mt-2 mr-3"
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheck}
                  ></input>
                  Read and Sign{" "}
                  <span
                    onClick={handleDailog}
                    className="text-seegreen font-bold underline cursor-pointer pl-1"
                  >
                    {" "}
                    Consent Form{" "}
                  </span>{" "}
                  {/* {consent?.attributes?.SecondComp?.name} */}
                </div>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  fullWidth={true}
                  maxWidth={"md"}
                >
                  <DialogTitle id="alert-dialog-title">
                    <p className="w-full text-center"> Consent Form</p>
                    {/* {consent?.attributes?.SecondComp?.name} */}
                  </DialogTitle>
                  <DialogContent
                    sx={{
                      borderWidth: 2,
                      borderColor: "divider",
                      marginX: "3rem",
                      padding: "3rem",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        height: "18rem",
                        typography: "body1",
                        margin: "0rem",
                      }}
                    >
                      <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: "#000000" }}>
                          <TabList
                            onChange={handleChange}
                            sx={{
                              "& .Mui-selected": {
                                color: "#66B889 !important",
                              },
                            }}
                            TabIndicatorProps={{
                              style: { backgroundColor: "#66B889" },
                            }}
                          >
                            <Tab
                              label={consent?.attributes?.SecondComp?.title1}
                              value="1"
                            />
                            <Tab
                              label={consent?.attributes?.SecondComp?.title2}
                              value="2"
                            />
                          </TabList>
                        </Box>

                        <TabPanel value="1">
                          <div className="text-justify">
                            {consent?.attributes?.SecondComp?.description1}
                          </div>
                        </TabPanel>
                        <TabPanel value="2">
                          <div className="text-justify">
                            {consent?.attributes?.SecondComp?.description2}
                          </div>
                        </TabPanel>
                      </TabContext>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    {/* <Button onClick={handleClose}>Disagree</Button>
                                    <Button onClick={handleClose} autoFocus>
                                        Agree
                                    </Button> */}
                    <div className="mx-10 my-10">
                      <button
                        className="text-chathams-blue-200 mr-4 border-chathams-blue-200 border-2 px-3 py-2 rounded-md hover:text-white hover:bg-chathams-blue-200 font-semibold w-28 "
                        style={{ border: "1px solid #396DDD" }}
                        onClick={handleAgree}
                      >
                        Accept
                      </button>
                      <button
                        className="text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 rounded-md hover:text-white hover:bg-chathams-blue-200 font-semibold w-28"
                        style={{ border: "1px solid #396DDD" }}
                        onClick={handleDisagree}
                      >
                        Decline
                      </button>
                    </div>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          </Card>
        </div>
        <div className="flex justify-center m-4">
          <button
            className="text-white border-seegreen bg-seegreen border-2 px-3 py-2 rounded-md hover:text-seegreen hover:bg-white hover:border-seegreen font-semibold w-72"
            onClick={handleMove}
          >
            Save and go to Dashboard
          </button>
        </div>
      </div>
    </>
  );
};

export default Consent;
