import React, { useEffect, useState } from "react";
import DashboardSidemenu from "./dashboard-sidemenu";
import { ArrowForwardIos } from "@mui/icons-material";
import {
  Box,
  MenuItem,
  Select,
  Tab,
  TablePagination,
  TextField,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Footer from "../../components/webflow/postloginfooter";
import UpcomingAppointments from "./upcomingAppointment";
import PreviousAppointments from "./previousAppointments";
import { fetchCarePlan, fetchUserList } from "../../store/actions/dashboard";
import dashboardservice from "../../store/services/dashboardservice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import mydetailsservice from "../../store/services/mydetailsservice";

import { SingleInputDateRangeField } from "@mui/x-date-pickers-pro/SingleInputDateRangeField";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import MobileFooter from "./../homepage/mobileFooter";
import Responsivemenu from "./responsive-menu";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate, useLocation } from "react-router-dom";
import { formatDateToDDMMYYYY } from "../common";

const Appointments = () => {
  const [Actvic, setActiv] = useState();
  const navigate = useNavigate();
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
      ? userObj.parentCode
      : null;
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [value, setValue] = React.useState("1");
  const [screen, setscreen] = useState(window.innerWidth);
  let today = new Date();
  let endDate1 = new Date(today.getTime() + 30 * 24 * 60 * 60 * 1000);
  let startDate1 = new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000);
  const [selectedFromDate, setSelectedFromDate] = useState(startDate1);
  const [selectedToDate, setSelectedToDate] = useState(endDate1);

  function handleFromDateChange(date) {
    setSelectedFromDate(date);
  }
  function handleTodateChange(date) {
    setSelectedToDate(date);
  }

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  const handleDateRange = (dateRange) => {
    setStartDate(dateRange[0]?.$d);
    setEndDate(dateRange[1]?.$d);
  };

  const [age, setAge] = React.useState(0);
  const [age1, setAge1] = React.useState(1);
  const [users, setUsers] = React.useState();
  const [user, setUser] = useState();
  const [care, setCare] = useState();

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const handleChange2 = (event) => {
    setAge1(event.target.value);
  };

  useEffect(() => {
    async function fetchUserDetails() {
      const user = await fetchUserList({
        code: userCode,
        email: userObj?.email,
        mobile: userObj?.mobile,
      });

      if (user.status === 200 && user?.data) {
        const temp = user?.data?.succesObject?.filter(
          (p) => Object.keys(p).length !== 0
        );
        setUsers(temp);
        setAge(temp[0]?.code);
        getActive();

        // setUser(user?.data?.succesObject[age]?.code)
      }
    }
    fetchUserDetails();
  }, []);
  // console.log(user)
  useEffect(() => {
    getActive();
  }, [age]);
  const getActive = () => {
    let payload = {
      code: age ? age : "",
    };
    mydetailsservice.getmyListapi(payload).then((res) => {
      console.log(res?.data?.succesObject);
      setActiv(res?.data?.succesObject);
    });
  };
  useEffect(() => {
    async function fetchCares() {
      console.log(userCode, "result");
      const res = await fetchCarePlan({
        userCode: userCode,
        patientcode: age,
      });
      if (res?.status === 200 && res?.data) {
        // console.log(res?.data,"result");
        setCare(res?.data);
        setAge1(res?.data?.succesObject[0]?.id);
      }
    }
    fetchCares();
  }, [age]);

  const [patientData, setpatientData] = useState();
  const [latest, setLatest] = useState();
  const [latestDate, setLatestDate] = useState();
  useEffect(() => {
    const getUpcoming = () => {
      let payload = {
        patientCode: age ? age : null,
        userCode: userCode,
        planId: age1,
        appointmentType: 1,
        startDate: formatDateToDDMMYYYY(selectedFromDate),
        endDate: formatDateToDDMMYYYY(selectedToDate),
      };
      dashboardservice.getappointment(payload).then((res) => {
        setpatientData(res?.data);
        console.log(res?.data);

        const temp =
          res?.data?.succesObject?.length > 0
            ? res?.data?.succesObject?.reduce((acc, curr) => {
              return curr.whenAppointment < acc.whenAppointment ? curr : acc;
            }, res.data.succesObject[0])
            : null;

        setLatest(temp);
        setLatestDate(temp?.whenAppointment);
      });
    };
    if (selectedFromDate && selectedToDate && userCode && age1) {
      getUpcoming();
    }
  }, [age, users, age1]);
  console.log(latestDate);

  const datePickerStyles = {
    input: {
      width: "100%",
      padding: "0.375rem 0.75rem",
      fontSize: "0.875rem",
      lineHeight: "3",
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    },
  };

  const breadcrumbItems = [
    { label: "Home", onClick: () => navigate(APP_ROUTES.DASHBOARD) },
    { label: "Dashboard", onClick: () => navigate(APP_ROUTES.MAINDASHBOARD) },
    { label: "Appointments" },
  ];
  return (
    <>
      <div className="md:p-8 p-4">
        <div className="flex items-center mb-5 mt-[100px]">
          {/* <div className="block md:hidden">
            <Responsivemenu />
          </div> */}
          {breadcrumbItems.map((item, index) => (
            <React.Fragment key={index}>
              <span className="md:text-base text-sm font-normal leading-7 text-chathams-blue-500 cursor-pointer">
                {item.onClick ? (
                  <a onClick={item.onClick}>{item.label}</a>
                ) : (
                  <a>{item.label}</a>
                )}
              </span>
              {index !== breadcrumbItems.length - 1 && (
                <ArrowForwardIos
                  sx={{ fontSize: 15 }}
                  className="text-chathams-blue-500 mx-2"
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="flex scroll-px-2">
          <div className="w-[300px] hidden md:block h-max mr-6  border items-start justify-center rounded-md ">
            <DashboardSidemenu />
          </div>
          <div className="w-full ">
            <div className=" md:h-20 md:mb-4 mb-2 flex  justify-start w-full rounded-md ">
              <div className="md:flex w-full ">
                <div className="flex flex-col md:mr-4 md:0 ">
                  <span className="text-chathams-blue md:text-[15px] text-sm">
                    Member Profile
                  </span>
                  <Select
                    value={age}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      height: "2 rem",
                      width: "13rem",
                      width: "100%",
                      boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                    }}
                    className="resp-select"
                  >
                    {users &&
                      users?.map((value, index) => (
                        <MenuItem value={value.code} key={index}>
                          <span className="md:text-[15px] text-sm">
                            {value.name}
                          </span>
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                {/* <div className="flex flex-col  ">
                  <span className="text-chathams-blue text-[15px]">
                    Careplans
                  </span>
                  <Select
                    value={age1}
                    onChange={handleChange2}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      height: "2 rem",
                      width: "100%",
                      boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                    }}
                    className="resp-select"
                  >
                    {care &&
                      care?.succesObject?.map((value, index) => (
                        <MenuItem value={value.id} key={index}>
                          <span className="text-[15px]">
                            {value.name}
                          </span>
                        </MenuItem>
                      ))}
                  </Select>
                </div> */}
                <div className="flex flex-col mt-2 sm:-mt-[9px]  ">
                  <span className="text-chathams-blue text-[15px]">
                    Careplans
                  </span>
                  <Select
                    value={age1}
                    onChange={handleChange2}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ height: "2 rem", width: "13rem" }}
                  >
                    {care &&
                      care?.succesObject?.map((value, index) => (
                        <MenuItem value={value.id} key={index}>
                          <span className="text-[15px]">
                            {value.name}
                          </span>
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <div className="md:px-4 py-3 h-22 mb-1 flex items-center">
                  {/* <p className='md:text-lg text-16 pr-2 whitespace-nowrap'>Select Date</p> */}
                  {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['SingleInputDateRangeField']}>
                                    <DateRangePicker slots={{ field: SingleInputDateRangeField }} onChange={handleDateRange} />
                                </DemoContainer>
                            </LocalizationProvider> */}
                  {/* <BasicDatePicker label="From" value={startDate} onChange={(newValue) => { setStartDate(newValue) }} />
                            <p className='mx-4 text-4xl'>-</p>
                            <BasicDatePicker label="To" value={endDate} onChange={(newValue) => { setEndDate(newValue) }} /> */}
                  <div className="flex gap-4 flex-row items-center">
                    {/* <p className='text-md pr-4 pt-4'>Select Date</p> */}
                    <div className="flex flex-col sm:pr-4">
                      <span className="text-[15px] text-[#495057]">From Date</span>
                      <DatePicker
                        selected={selectedFromDate}
                        onChange={handleFromDateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        customInput={<input style={datePickerStyles.input} />}
                      />
                    </div>
                    <div className="flex flex-col">
                      <span className="text-[15px] text-[#495057]">To Date</span>
                      <DatePicker
                        selected={selectedToDate}
                        onChange={handleTodateChange}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/YYYY"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        customInput={<input style={datePickerStyles.input} />}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {patientData?.length > 0 ? (
              <div>
                <span className="md:text-lg text-16 font-bold text-chathams-blue md:pt-5 pt-3">
                  Doctors Appointments
                </span>

                <div className="flex p-4 justify-between items-center rounded-md bg-light-sky-blue text-white w-full h-20">
                  <span className="md:text-base text-sm font-medium">
                    Next Appointment is on {latestDate} with{" "}
                    {latest?.userSalutation} {latest?.name}
                  </span>
                </div>
              </div>
            ) : (
              <></>
            )}

            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={handleChange1}
                    aria-label="lab API tabs example"
                  >
                    <Tab label="Upcoming Appointment" value="1" />
                    <Tab label="Previous Appointment" value="2" />
                  </TabList>
                </Box>
                <TabPanel key={1} value="1">
                  <UpcomingAppointments
                    user={age ? age : null}
                    start={formatDateToDDMMYYYY(selectedFromDate)}
                    end={formatDateToDDMMYYYY(selectedToDate)}
                    planId={age1 ? age1 : null}
                    active={Actvic}
                  />
                </TabPanel>
                <TabPanel key={2} value="2">
                  <PreviousAppointments
                    user={age ? age : null}
                    start={formatDateToDDMMYYYY(selectedFromDate)}
                    end={formatDateToDDMMYYYY(selectedToDate)}
                    planId={age1 ? age1 : null}
                  />
                </TabPanel>
              </TabContext>
            </Box>
          </div>
        </div>
      </div>
      {screen > 500 ? (
        <Footer i={1} />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
      {/* <Footer i={1} /> */}
    </>
  );
};

export default Appointments;
