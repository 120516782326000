import httpCommon from "./httpcommon";
import services from "./index.jsx";
class Login {
  generateOtp(payload) {
    return httpCommon.post("login/mobileLogin/", payload);
  }
  pswdCheck(payload) {
    return httpCommon.post("login/emailLogin/", payload);
  }
  signup(payload) {
    return httpCommon.post("/signUp", payload);
  }
  landingpage(payload) {
    return httpCommon.post("/saveVisitors", payload);
  }

  patientsave(payload) {
    return httpCommon.post("/patient/save", payload);
  }
  subscriptionssave(payload) {
    return httpCommon.post("/subscriptions/save", payload);
  }
  fetchUserList(payload) {
    return httpCommon.post("/dropdown/userlist", payload);
  }
  updateCart(payload) {
    return httpCommon.post("/subscriptions/cart-save", payload);
  }
  cartList(payload) {
    return httpCommon.post("/subscriptions/cart-list", payload);
  }

  getConsent() {
    return services.get("/consent-forms?populate=deep");
  }
  consent(payload) {
    return httpCommon.post("/login/consentForm", payload);
  }
  preAssesmentSave(payload) {
    return httpCommon.post("/preAssessment/save", payload);
  }
  isSelfLogIn(payload) {
    return httpCommon.post("/login/selfLogin", payload);
  }
  selfCareGiverLogin(payload) {
    return httpCommon.post("/login/selfParentLogin", payload);
  }

  saveVitalsdata(payload) {
    return httpCommon.post("/dashboard/saveVitals", payload);
  }
}

export default new Login();
