import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Line,Bar } from "react-chartjs-2";

function Chartlinetitan() {
    const[timestamp,setTimestampSlot] = useState()
    const [datachart,setDatachart]= useState()
    const [date,setDate]= useState();
    const [time,setTime]=useState();
const timeStampSlot = timestamp



  useEffect (() => {
   
    const data = {
      patientCode:"NPUoV3789p",
      startDate:"01/12/2023",
      endDate:"30/12/2023"
    };
    axios
      .post(
        "https://medicareapi-stage.curebay.in/medicare/titan/getVitals",
        data,
       
      )
      .then((result) => {
        
        setDatachart(result?.data?.succesObject)
      let plans =result?.data?.succesObject?.map((obj) => {
        return obj?.timeStampSlot;
      });
      setTimestampSlot(plans)
      console.log(plans,result?.data?.succesObject,"hdbfhdnhvjundjuvnjgvn")
      })
      .catch((err) => {
        console.log("Err", err);
      });
  }
  );

 

  {timeStampSlot?.map((timestamp, index) => {
      
    const [year, month, day, hours, minutes] = timestamp || []; // Use default values to avoid undefined

    if (year === undefined) {
      console.warn(`Invalid timestamp at index ${index}: ${JSON.stringify(timestamp)}`);
      return null; // Skip rendering for invalid timestamps
    }

    // Format the date and time strings
    const formattedDate = `${year}-${month}-${day}`;
    const formattedTime = `${hours}:${minutes}`;
    setDate(formattedDate)
    setTime(formattedTime)
    console.log(timestamp,formattedDate,formattedTime,"gbbhjnjnjnh")
    return (
      <div key={index}>
        <p>Date: {formattedDate}</p>
        <p>Time: {formattedTime}</p>
        <hr />
      </div>
    );
  })}
 
   
    const colors = [
      "rgb(54, 162, 235, 0.2)",
      "rgb(75, 192, 192, 0.2)",
      "rgb(255, 99, 132, 0.2)",
  ];
  const border = [
      "rgb(54, 162, 235, 1)",
      "rgb(75, 192, 192, 1)",
      "rgb(255, 99, 132, 1)",
  ];
  const data = {
    labels:  date 
,
    //labels: moment(props.givendate, "yyyy-MM-DD HH:mm:ss").format("MMM Do YY"),
    
    datasets: [
      {
       data: datachart,
        backgroundColor: colors[colors.length],
        borderColor: border[border.length],
        fill: true,
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.5,
      },
      {
        // data:datachart?.heartRate ? datachart?.heartRate : [] ,
       backgroundColor: colors[colors.length],
        borderColor: border[border.length],
        fill: true,
        borderWidth: 2,
        borderRadius: 5,
        lineTension: 0.5,
      },
    ]
    
  };
  
  
  
  
 
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: false,
        min: 0,
        max: 200,
        stepSize: 10,
      },
      x: {
        grid: {
          color: "rgba(0, 0, 0, 0)",
        }
      },
    }
  };
  
  
    // console.log(props.data.length,"uguijk")
    
  
    return (
      <div className="App">
       <Line
            data={data}
            options={options}
          />
        
  
      </div>
    );
  }
 

export default Chartlinetitan