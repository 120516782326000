import dashboardservice from "../../services/dashboardservice";
import requestforchange from "../../services/requestforchange";
export const fetchDashboardData = async () => {
  const res = dashboardservice.getDashboardData();
  return res;
};
export const fetchUserList = async (payload) => {
  const res = dashboardservice.getUserList(payload);
  return res;
};
export const fetchCarePlan = async (payload) => {
  const res = dashboardservice.getCarePlanList(payload);
  return res;
};
export const requestForChange = async (payload) => {
  const res = requestforchange.getRequestChange(payload);
  return res;
};
export const schedule = async (payload) => {
  const res = dashboardservice.getSchedule(payload);
  return res;
};
