import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { requestForChange } from '../../store/actions/dashboard';
import { Modal } from '@mui/material';
import { IconButton as MuiIconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { useSnackbar } from '../common/snackbar';
import { styled } from '@mui/material/styles';

const ROC = ({ open, onClose, props }) => {
    const { snackbarMessage, setSnackbarMessage } = useSnackbar();
    const { state } = useLocation();
    const patientData = props;
    const [loading, setLoading] = useState(false);
    const [slot, setSlot] = useState('');

    const IconButton = styled(MuiIconButton)({
        backgroundColor: 'white', borderColor: '#66B889 !important', borderWidth: '1px !important', border: 'solid', color: '#66B889',
        '&:hover': {
            backgroundColor: '#66B889',
            color: 'white',
        },
    });
    const handleClose = () => {
        onClose();
    };

    const handleClick = (value) => {
        setSlot(value);
    };

    const Slot = ({ timeFrom, timeTo, interval, slotChange }) => {
        function getTimeSlots(startTime, endTime, interval) {
            const slots = [];
            const start = new Date(`01/01/2023 ${startTime}`);
            const end = new Date(`01/01/2023 ${endTime}`);

            while (start <= end) {
                const time = start.toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                });
                slots.push(time);
                start.setMinutes(start.getMinutes() + interval);
            }

            return slots;
        }

        const slots = getTimeSlots(timeFrom, timeTo, interval);

        const [time, setTime] = useState('');
        const handleClick = (value) => {
            setTime(value);
            slotChange(value);
        };

        return (
            <div className="flex flex-wrap">
                {slots.map((value) => (
                    <button
                        className={`${value === time
                            ? 'bg-darkerBlue-50 text-white'
                            : 'border-darkerBlue-50 text-darkerBlue-50 hover:bg-darkerBlue-50 hover:text-white'
                            } border-2 py-1 px-2 m-2 h-10 w-32 text-sm font-semibold`}
                        onClick={() => handleClick(value)}
                    >
                        {value}
                    </button>
                ))}
            </div>
        );
    };
    const currentDate = new Date();
    const [value, setValue] = React.useState(dayjs(new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000)));
    const formattedDate = moment(value?.$d).format('YYYY-MM-DD');
    const [data, setData] = useState();
    // const disablePastDates = (date) => {
    //     return date < new Date();
    // };
    // const disableFutureDates = (date) => {
    //     return date > new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
    // };
    const disableDates = (date) => {
        const currentDate = new Date();
        currentDate.setDate(currentDate.getDate() + 1);
        const maxDate = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);
        return date < currentDate || date > maxDate;
    };




    const handlerequest = async () => {
        setLoading(true);
        try {
            const res = await requestForChange({
                cpscSeqId: patientData?.cpscSeqId,
                subSeqId: patientData?.subSeqId,
                cpSeqId: patientData?.cpSeqId,
                cpfSeqId: patientData?.cpfSeqId,
                patientCode: patientData?.patientId,
                originalModifiedDate: formattedDate + ' 00:00:00',
            });
            if (res.status === 200 && res?.data?.responseCode === '200') {
                setSnackbarMessage(res.data?.responseMessage, 'success');
                setTimeout(() => {
                    handleClose();
                }, 3000);
                setData(res?.data);
            }
            else {
                setSnackbarMessage(res.data?.responseMessage, 'error');
            }
        } catch (error) {
            setSnackbarMessage('An error occurred while processing the request.', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <>
                {snackbarMessage}
                <div className="flex items-center justify-center h-screen">
                    <div className="bg-white p-4 sm:w-[600px] w-[341px]">
                        <div className="flex justify-between items-center mb-6">
                            <p className="text-xl font-bold text-center">Request for Change</p>
                            <IconButton onClick={handleClose} aria-label="Close">
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className="flex justify-center mb-6">
                            <div className="sm:w-[400px]">
                                <p className="text-lg text-blacky font-bold mt-2 text-center">Select Date</p>
                                <p className="bg-beigerfc text-md py-1 px-4 mt-2 text-center">
                                    Your current appointment is with{' '}
                                    <span className="font-bold">
                                        {patientData?.name} on {patientData?.whenAppointment} at {patientData?.fromTime}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="flex justify-center mb-6">
                            <div className="w-[400px]">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar value={value}
                                        // shouldDisableDate={disablePastDates}
                                        shouldDisableDate={disableDates}
                                        onChange={(newValue) => setValue(newValue)}

                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        <div className="flex justify-center">
                            <button
                                onClick={handlerequest}
                                className="bg-chathams-blue-200 border-2 px-4 py-2 text-white font-semibold"
                                disabled={loading}
                            >
                                {loading ?
                                    <div className="flex flex-wrap justify-center">
                                        <div className="loader float-center ease-linear text-center rounded-full border-2 border-t-2 border-gray-200 h-10 w-10" />
                                    </div>
                                    :
                                    'Change Request'
                                }
                            </button>
                        </div>
                    </div>
                </div>
            </>
        </Modal >
    );

};

export default ROC;
