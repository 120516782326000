import React, { useEffect, useState } from 'react';
import MyprofileSidemenu from "../homepage/myprofile-sidemenu";


// import { getReviewDetails } from '../../store/actions/reviews';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { Button, Card, CardContent, Grid, TextField, Typography } from '@mui/material';

function Myhealth() {
    const breadcrumbItems = [
        { label: "Home" },
        { label: "My Details" },
        { label: "My Details", url: "/details" },

    ];
    // const [review, setReview] = useState();

    // // const navigate = useNavigate();

    // useEffect(() => {

    //     async function fetchOurReview() {

    //         const review = await getReviewDetails();

    //         if (review.status === 200 && review?.data) {

    //             setReview(review?.data);

    //         };

    //     }

    //     fetchOurReview();

    // }, []);

    // console.log(review);
    const CardWithButtons = ({ question }) => {

        const [selected, setSelected] = useState(null);

        const Button1 = ({ value }) => {

            const handleClick = () => {

                if (selected === value) {

                    setSelected(null);

                } else {

                    setSelected(value);

                }

            };
            return (

                <button

                    className={`${selected === value ? 'bg-bluish text-white ' : 'bg-white hover:bg-bluish hover:text-white'} w-6 h-6 border-2 border-bluish rounded-full text-center text-sm mx-3 first:ml-0 last:mr-0 cursor-pointer`}

                    onClick={handleClick}>

                    {value}

                </button>
            );

        };

        return (
            <Card elevation={1} >

                <div className='m-5 flex justify-between flex-col customheight-70'>

                    <p className='font-semibold text-base h-12 mb-2'>{question}</p>

                    <div>
                        <div className='flex bg-sea-blue rounded-full justify-between'>

                            {[1, 2, 3, 4, 5, 6, 7].map((i) => (

                                <Button1 key={i} value={i} />

                            ))}

                        </div>

                        <div className='flex justify-between mx-0 my-2'>

                            <p style={{ fontSize: '12px' }}>Disagree</p>

                            <p className="pr-2" style={{ fontSize: '12px' }}>Agree</p>

                        </div>
                    </div>

                </div>

            </Card>
        );

    };
    return (

        <div className="p-8 ">
            {/* <div className="flex items-center mb-5">
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">Home</p>
                    <ArrowForwardIosIcon sx={{ fontSize: 15 }} className="text-chathams-blue-500 mx-2" />
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">My Profile</p>
                    <ArrowForwardIosIcon sx={{ fontSize: 15 }} className="text-chathams-blue-500 mx-2" />
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">My Details</p>
                </div> */}
            <div className="flex items-center mb-5">
                {breadcrumbItems.map((item, index) => (
                    <React.Fragment key={index}>
                        <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">
                            <a>{item.label}</a>
                        </p>
                        {index !== breadcrumbItems.length - 1 && (
                            <ArrowForwardIosIcon
                                sx={{ fontSize: 15 }}
                                className="text-chathams-blue-500 mx-2"
                            />
                        )}
                    </React.Fragment>
                ))}
            </div>
            <h2 className="text-xl font-bold my-5 text-chathams-blue-500">My Profile - Details</h2>
            <div className="flex scroll-px-2">
                <div className="w-1/4 mr-4 border p-4 rounded-md">
                    <MyprofileSidemenu value={2} />
                </div>


            </div>
        </div>

    );

};

export default Myhealth;