import React, { useEffect, useState } from "react";
import DashboardSidemenu from "./dashboard-sidemenu";
import { ArrowForwardIos } from "@mui/icons-material";
import { fetchCarePlan, fetchUserList } from "../../store/actions/dashboard";
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Select,
  Tab,
  TablePagination,
  TextField,
  Typography,
  CardContent,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import mydetailsservice from "../../store/services/mydetailsservice";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import { fetchvitals } from "../../store/actions/myprofile";
import moment from "moment";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import "react-datepicker/dist/react-datepicker.css";
import { CalculateAge, formatDateToDDMMYYYY } from "../common";

import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import Footer from "../../components/webflow/postloginfooter";
import MobileFooter from "../homepage/mobileFooter";
import ReactPaginate from "react-paginate";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import BloodPressure from "../../assets/images/BP icon.svg";
import Newtrends from "../../assets/images/new trends.svg";
import Dataupdate from "../../assets/images/data update.svg";

import temperature from "../../assets/images/Temp icon.svg";
import heartrate from "../../assets/images/Heart Rate icon.svg";
import pulse from "../../assets/images/Respiration Rate ixon.svg";
import heights from "../../assets/images/Height icon.svg";
import weights from "../../assets/images/weight.svg";
import bmi from "../../assets/images/BMI icon.svg";
import spo2 from "../../assets/images/SPo2 icon.svg";
import BloodGlucose from "../../assets/images/Blood Glucose ixon.svg";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton as MuiIconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import { FormHelperText, Modal, InputLabel } from "@mui/material";
import { saveVitalsdata } from "../../store/actions/login";
import { useSnackbar } from "../common/snackbar";

const Healthrecords = () => {
  const navigate = useNavigate();
  let today = new Date();
  let startDate1 = new Date(today.getTime() - 180 * 24 * 60 * 60 * 1000);
  const filePath = `${process.env.REACT_APP_IMAGE_URL}`;

  const breadcrumbItems = [
    { label: "Home", onClick: () => navigate(APP_ROUTES.DASHBOARD) },
    { label: "Dashboard", onClick: () => navigate(APP_ROUTES.MAINDASHBOARD) },
    { label: "Health Records" },
  ];

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  const [value, setValue] = React.useState("1");
  const [selectedFromDate, setSelectedFromDate] = useState(startDate1);
  const [selectedToDate, setSelectedToDate] = useState(new Date());
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const itemsPerPage = 3; // Number of items to display per
  const [currentPagenew, setCurrentPagenew] = useState(1);
  const [currentPagenewlab, setCurrentPagenewlab] = useState(1);
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();

  const handlePageChangenew = (pageNumber) => {
    setCurrentPagenew(pageNumber);
  };
  const handlePageChangenewlab = (pageNumber) => {
    setCurrentPagenewlab(pageNumber);
  };

  const handleFromDateChange = (date) => {
    setSelectedFromDate(date);

    if (selectedToDate && date > selectedToDate) {
      setSelectedToDate(null);
    }
  };

  const handleTodateChange = (date) => {
    if (selectedFromDate && date < selectedFromDate) {
      setSelectedFromDate(null);
    } else {
      setSelectedToDate(date);
    }
  };

  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const [userCode, setuserCode] = useState(
    userObj?.code
      ? userObj.code
      : userObj?.parentCode
      ? userObj.parentCode
      : userObj?.userCode
      ? userObj.userCode
      : null
  );
  const isParentLoggedIn = userObj?.parentCode ? true : false;
  const [users, setUsers] = React.useState();
  const [age, setAge] = React.useState(0);
  const [age1, setAge1] = React.useState(1);
  const [labData, setlabData] = useState();
  const [care, setCare] = useState();
  const [currentPageNu, setcurrentPageNu] = useState(1);
  const [pagecLimit, setPageLimit] = useState(1);
  const [currentPageNulab, setcurrentPageNulab] = useState(1);
  const [labLimit, setLabLimit] = useState(1);
  const [screen, setscreen] = useState(window.innerWidth);
  const [allowedToSeePrescrp, setAllowedToSeePrescp] = useState(true);
  const [allowedToSeeLabRep, setAllowedToSeeLabPrep] = useState(true);
  const [isaddvitalOpen, setIsaddvitalOpen] = useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
    getOverAll(event.target.value);
    getOverAll1(event.target.value);
  };

  const handleChange2 = (event) => {
    setAge1(event.target.value);
  };

  useEffect(() => {
    if (!userObj?.parentCode) {
      const temp = users?.filter((p) => p.code === age)[0];
      setAllowedToSeePrescp(!(temp?.consentDrRemark === 0));
      setAllowedToSeeLabPrep(!(temp?.consentMedReport === 0));
    }
  }, [age]);

  const datePickerStyles = {
    input: {
      width: "100%",
      padding: "0.375rem 0.75rem",
      fontSize: "0.875rem",
      lineHeight: "3",
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    },
  };

  useEffect(() => {
    async function fetchUserDetails() {
      const user = await fetchUserList({
        code: userObj?.userCode
          ? userObj.userCode
          : userObj?.parentCode
          ? userObj.parentCode
          : null,
        email: userObj?.email,
        mobile: userObj?.mobile,
      });

      if (user.status === 200 && user?.data) {
        const temp = user?.data?.succesObject?.filter(
          (p) => Object.keys(p).length !== 0
        );
        let first = temp;
        getFirstPatient(first);
        setUsers(temp);
      }
    }
    fetchUserDetails();
  }, []);

  useEffect(() => {
    async function fetchCares() {
      const res = await fetchCarePlan({
        userCode: userObj?.userCode
          ? userObj.userCode
          : userObj?.parentCode
          ? userObj.parentCode
          : null,
        patientcode: age,
      });
      if (res?.status === 200 && res?.data) {
        setCare(res?.data);
        setAge1(res?.data?.succesObject[0]?.id);
      }
    }
    fetchCares();
  }, [age]);

  const [prescriptionData, setprescriptionData] = useState();
  const getFirstPatient = (e) => {
    if (userObj?.userCode) {
      setAge(e[0].code);
      getOverAll(e[0].code);
      getOverAll1(e[0].code);
    } else {
      setAge(userCode);
      getOverAll(userCode);
      getOverAll1(userCode);
    }
  };

  useEffect(() => {
    if (age && age1) getOverAll("");
  }, [age, currentPageNu, age1]);

  const handlePagination = (event, value) => {
    setcurrentPageNu(value);
  };
  const handlePagination1 = (event, value) => {
    setcurrentPageNulab(value);
  };

  const getOverAll = (e) => {
    let payload;
    if (e == "") {
      payload = {
        patientId: age,
        planId: age1,
        // pageLimit: 5,
        // pageNo: currentPageNu,
      };
    } else {
      payload = {
        patientId: e,
        planId: age1,
        // pageLimit: 5,
        // pageNo: currentPageNu,
      };
    }
    if (age != 0 && age1) {
      mydetailsservice
        .getmypPrescriptionlist(payload)
        .then((res) => {
          setprescriptionData(res.data.succesObject);
          setPageLimit(Math.ceil(res.data.totalRecords / 5));
        })
        .catch((error) => {
          console.error("Failed to fetch prescription data:", error);
        });
    }
  };

  const viewPrescription = (e) => {
    let payload = {
      appointmentId: e?.appointmentId,

      visitSummary: "Y",
      status: "1",

      stampRequired: "Y",

      documentRequired: "Y",

      vitalRequired: "Y",
    };
    mydetailsservice
      .getpresDocName(payload)
      .then((res) => {
        // if (res?.data?.succesObject[0]?.patientDrugPrescriptionList?.[0]?.docName) {
        //     let docName = res?.data?.succesObject[0]?.patientDrugPrescriptionList?.[0]?.docName
        //     window.open(filePath + docName, '_blank');
        // }
        // else {
        // if (res?.data?.succesObject[0]?.patientDrugPrescriptionList) {
        if (res?.data?.succesObject?.[0]) {
          let epres = res.data.succesObject[0];
          navigate(
            APP_ROUTES.EPRECRIPTION,
            { state: { epres: epres, dashboard: "myhealthrecords" } },
            { replace: true }
          );
        }
        // }
      })
      .catch((error) => {
        console.error("Failed to fetch prescription data:", error);
      });
  };

  const viewHealthGraph = (key, value) => {
    console.log(key, value, "data are coming");
    let props1 = {
      name: key,
      fromDate: selectedFromDate,
      toDate: selectedToDate,
      patient: age,
    };
    navigate(APP_ROUTES.MYHCARDS, { state: props1 }, { replace: true });
  };

  useEffect(() => {
    getOverAll1("");
  }, [currentPageNu, age1, age]);

  const getOverAll1 = (e) => {
    let payload;
    // let data = JSON.parse(userData);
    // console.log(data)
    if (e == "") {
      payload = {
        // "patientId": data?.[1]?.patientCode ? data[1].patientCode : null,
        patientId: age,
        docmentRequired: "Y",
        planId: age1,
        // pageLimit: 5,
        // pageNo: currentPageNulab,

        //         "userCode": userCode,
        //  "patientCode": userObj.code,
      };
    } else {
      payload = {
        // "patientId": data?.[1]?.patientCode ? data[1].patientCode : null,
        patientId: e,
        docmentRequired: "Y",
        planId: age1,
        // pageLimit: 5,
        // pageNo: currentPageNulab,

        //         "userCode": userCode,
        //  "patientCode": userObj.code,
      };
    }
    // if (data?.[1]?.patientCode) {
    mydetailsservice
      .getmylablist(payload)
      .then((res) => {
        setlabData(res?.data?.succesObject);
        setPageLimit(Math.ceil(res?.data?.totalRecords / 5));
      })
      .catch((error) => {
        console.error("Failed to fetch lab reports:", error);
      });
  };

  const downloadReport = (e) => {
    let payload = {
      fileName: e.labReportDoc,
    };
    mydetailsservice
      .downloadLabDoc(payload)
      .then((res) => {
        let payload2 = {
          fileData: res?.data?.succesObject.fileData,
          fileName: "Test",
        };
        // Get the base64 string from your API response
        const base64String = payload2.fileData;

        // Convert the base64 string to a Blob object

        // Convert the base64 string to a Blob object
        const base64PdfContent = base64String;
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${base64PdfContent}`;
        link.download = "document.pdf"; // Specify the desired filename for download
        link.click();
      })
      .catch((error) => {
        console.error("Failed to fetch lab reports:", error);
      });
  };

  const [myVitals, setMyVital] = useState();

  function formatDateTime(date) {
    if (!(date instanceof Date)) {
      return "";
    }

    const formattedDate = dayjs(date).format("DD-MM-YYYY HH:mm:ss");

    return formattedDate;
  }

  useEffect(() => {
    async function fetchMyVitalsDetails() {
      let today = new Date();
      let startDate1 = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
      today.setDate(today.getDate());
      const myVitals = await fetchvitals({
        patientCode: age ? age : userCode ? userCode : null,
        startDate: formatDateToDDMMYYYY(selectedFromDate),
        endDate: formatDateToDDMMYYYY(selectedToDate),
      });
      if (myVitals.status === 200 && myVitals?.data) {
        setMyVital(myVitals?.data);
        fetchHealthRecords(myVitals?.data);
      }
    }
    fetchMyVitalsDetails();
  }, [age, selectedFromDate, selectedToDate, age1]);

  const [value1, setValue1] = React.useState(null);
  const [result_obj, setResult_obj] = useState({});
  // useEffect(() => {
  //     const temp = {};
  //     for (const key in myVitals?.succesObject?.vitalsDetails) {
  //         const selectedDate1 = moment(value.$d).format("DD-MM-YYYY");
  //         myVitals?.succesObject?.vitalsDetails[key]?.forEach((element) => {
  //             // if (
  //             //     moment(element.givenDate, "DD-MM-YYYY HH:mm:ss").format(
  //             //         "DD-MM-YYYY"
  //             //     ) === selectedDate1
  //             // ) {
  //             temp[key] = element.value;
  //             //    setResult_obj({ ...result_obj, key });
  //             // }
  //         });
  //     }
  //     setResult_obj(temp);
  // }, [value1]);
  const fetchHealthRecords = (data) => {
    const temp = {};
    for (const key in data?.succesObject?.vitalsDetails) {
      const selectedDate1 = moment(value.$d).format("DD-MM-YYYY");
      data?.succesObject?.vitalsDetails[key]?.forEach((element) => {
        // if (
        //     moment(element.givenDate, "DD-MM-YYYY HH:mm:ss").format(
        //         "DD-MM-YYYY"
        //     ) === selectedDate1
        // ) {
        if (key === "BP") {
          temp[key] = element.data;
        } else temp[key] = element.value;
        //    setResult_obj({ ...result_obj, key });
        // }
      });
    }
    setResult_obj(temp);
  };
  const getBPStatus = (bpValue) => {
    const [systolic, diastolic] = bpValue.split("/").map(Number);
    // Your BP status logic here
    if (systolic <= 120 && diastolic <= 80) {
      return "Normal";
    } else if (
      systolic > 120 &&
      systolic <= 139 &&
      diastolic >= 81 &&
      diastolic <= 89
    ) {
      return "Pre-hypertension";
    } else if (
      systolic >= 140 &&
      systolic <= 159 &&
      diastolic >= 90 &&
      diastolic <= 99
    ) {
      return "Stage-1 Hypertension";
    } else if (systolic > 159 && diastolic > 99) {
      return "Stage-2 Hypertension";
    } else {
      return " ";
    }
  };
  const headerStyle = {
    backgroundColor: " rgb(230 244 253)",
    borderBottom: "1px solid #e6f4fd", // Border
  };
  const IconButton = styled(MuiIconButton)({
    backgroundColor: "white",
    borderColor: "#000 !important",
    borderWidth: "1px !important",
    border: "solid",
    color: "#000",
    width: "26px",
    height: "26px",
  });

  const handleOpenvital = () => {
    setIsaddvitalOpen(true);
  };
  const handleClose = () => {
    setIsaddvitalOpen(false);
  };
  const [addvitals, setaddvitals] = useState({
    patientCode: age,
    givenDate: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    createdBy: "",
    updatedBy: "",
    status: 1,
    height: "",
    weight: "",
    bloodGroup: "",
    temperature: "",
    systolic: "",
    diastolic: "",
    spo2: "",
    heartRate: "",
    pulseRate: "",
    respirationRate: "",
    bloodGlucose: "",
    bmi: 0,
    bp: 0,
    hdl: 0,
    ldl: 0,
    medication: "",
    notes: "",
    notes1: "",
    notes2: "",
    totalCholesterol: 0,
    triglycerides: 0,
    appointmentId: 0,
  });
  const handlevitalChange = (e) => {
    setaddvitals({ ...addvitals, [e.target.name]: e.target.value });
  };

  const savevitals = async (e) => {
    console.log(userCode, userObj.userCode, age, "dxcfvgbhnj");
    e.preventDefault();
    const bmi = (
      addvitals.weight /
      ((addvitals.height / 100) * (addvitals.height / 100))
    ).toFixed(2);

    if (
      addvitals?.height == undefined ||
      addvitals?.height == "" ||
      addvitals?.height == null ||
      addvitals?.height == "0"
    ) {
      setSnackbarMessage("Please Enter Height", "error");
      return;
    } else if (
      addvitals?.weight == undefined ||
      addvitals?.weight == "" ||
      addvitals?.weight == null ||
      addvitals?.weight == "0"
    ) {
      setSnackbarMessage("Please Enter Weight", "error");
      return;
    } else if (
      addvitals?.temperature == undefined ||
      addvitals?.temperature == "" ||
      addvitals?.temperature == null ||
      addvitals?.temperature == "0"
    ) {
      setSnackbarMessage("Please Enter Temperature", "error");
      return;
    } else if (
      addvitals?.respirationRate == undefined ||
      addvitals?.respirationRate == "" ||
      addvitals?.respirationRate == null ||
      addvitals?.respirationRate == "0"
    ) {
      setSnackbarMessage("Please Enter Respiration Rate", "error");
      return;
    } else if (
      addvitals?.systolic == undefined ||
      addvitals?.systolic == "" ||
      addvitals?.systolic == null ||
      addvitals?.systolic == "0"
    ) {
      setSnackbarMessage("Please Enter Systolic", "error");
      return;
    } else if (
      addvitals?.diastolic == undefined ||
      addvitals?.diastolic == "" ||
      addvitals?.diastolic == null ||
      addvitals?.diastolic == "0"
    ) {
      setSnackbarMessage("Please Enter Diastolic", "error");
      return;
    } else if (
      addvitals?.spo2 == undefined ||
      addvitals?.spo2 == "" ||
      addvitals?.spo2 == null ||
      addvitals?.spo2 == "0"
    ) {
      setSnackbarMessage("Please Enter Pulse Ox", "error");
      return;
    } else if (
      addvitals?.heartRate == undefined ||
      addvitals?.heartRate == "" ||
      addvitals?.heartRate == null ||
      addvitals?.heartRate == "0"
    ) {
      setSnackbarMessage("Please Enter Heart Rate", "error");
      return;
    } else if (
      addvitals?.bloodGlucose == undefined ||
      addvitals?.bloodGlucose == "" ||
      addvitals?.bloodGlucose == null ||
      addvitals?.bloodGlucose == "0"
    ) {
      setSnackbarMessage("Please Enter Blood Glucose", "error");
      return;
    }

    const payload = {
      patientCode: age,
      givenDate: addvitals.givenDate,
      createdBy: isParentLoggedIn ? age : userCode,
      updatedBy: isParentLoggedIn ? age : userCode,
      status: addvitals.status,
      height: addvitals.height,
      weight: addvitals.weight,
      bloodGroup: addvitals.bloodGroup,
      temperature: addvitals.temperature,
      systolic: addvitals.systolic,
      diastolic: addvitals.diastolic,
      spo2: addvitals.spo2,
      heartRate: addvitals.heartRate,
      pulseRate: addvitals.pulseRate,
      respirationRate: addvitals.respirationRate,
      bloodGlucose: addvitals.bloodGlucose,
      bmi: addvitals.weight && addvitals.height ? bmi : "",
      bp: addvitals.bp,
      hdl: addvitals.hdl,
      ldl: addvitals.ldl,
      medication: addvitals.medication,
      notes: addvitals.notes,
      notes1: addvitals.notes1,
      notes2: addvitals.notes2,
      totalCholesterol: addvitals.totalCholesterol,
      triglycerides: addvitals.triglycerides,
      appointmentId: addvitals.appointmentId,
    };

    const res = await saveVitalsdata(payload);
    if (res?.data?.responseCode === "200") {
      setSnackbarMessage("Vitals Added Successfully.", "success");
      setIsaddvitalOpen(false);
      window.location.reload();
    }
  };

  return (
    <>
      <div className="p-8">
        <div className="flex items-center mb-5 mt-[100px]">
          {breadcrumbItems.map((item, index) => (
            <React.Fragment key={index}>
              <span className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">
                {item.onClick ? (
                  <a onClick={item.onClick}>{item.label}</a>
                ) : (
                  <a>{item.label}</a>
                )}
              </span>
              {index !== breadcrumbItems.length - 1 && (
                <ArrowForwardIos
                  sx={{ fontSize: 15 }}
                  className="text-chathams-blue-500 mx-2"
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="flex scroll-px-2">
          <div className="w-[300px] hidden md:block h-max mr-6  border items-start justify-center rounded-md ">
            <DashboardSidemenu />
          </div>
          <div className="w-full">
            <div className="flex flex-col sm:flex-row justify-start sm:m-4 w-full">
              <div className="flex flex-col sm:mr-4">
                <span className="text-chathams-blue text-[15px]">
                  Member Profile
                </span>
                <Select
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "2 rem",
                    width: "100%",
                    boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                  }}
                >
                  {users &&
                    users?.map((value, index) => (
                      <MenuItem value={value.code} key={index}>
                        <span className="text-[15px]">{value.name}</span>
                      </MenuItem>
                    ))}
                </Select>
              </div>
              {!(value === "3") && (
                <div className="flex flex-col mt-2 sm:mt-0  ">
                  <span className="text-chathams-blue text-[15px]">
                    Careplans
                  </span>
                  <Select
                    value={age1}
                    onChange={handleChange2}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{
                      height: "2 rem",
                      width: "13rem",
                      width: "100%",
                      boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                    }}
                  >
                    {care &&
                      care?.succesObject?.map((value, index) => (
                        <MenuItem value={value.id} key={index}>
                          <span className="text-[15px]">{value.name}</span>
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              )}
            </div>
            <div className="w-full">
              <Box sx={{ width: "100%", typography: "body1" }}>
                <TabContext value={value}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList
                      onChange={handleChange1}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="scrollable force tabs example"
                    >
                      <Tab label="Prescriptions" value="1" />
                      <Tab label="Lab reports" value="2" />
                      <Tab label="Vitals" value="3" />
                    </TabList>
                  </Box>

                  <TabPanel
                    key={1}
                    value="1"
                    style={{ padding: "0px" }}
                    className="mt-5"
                  >
                    <div className="w-full border sm:p-8 p-4 rounded-md items-center flex flex-col">
                      <h2 className="w-full flex items-start mt-0 sm:text-lg font-bold my-5 text-chathams-blue-500">
                        See Your Prescription
                      </h2>

                      <div className="flex flex-col gap-3  w-full  ">
                        {allowedToSeePrescrp ? (
                          prescriptionData && prescriptionData.length > 0 ? (
                            <>
                              <DataTable
                                value={prescriptionData}
                                paginator
                                rows={50}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                tableStyle={{ padding: "10px" }}
                                rowClassName={(rowData) =>
                                  rowData.serviceStatus === 2 ? "rowcolor" : ""
                                }
                              >
                                <Column
                                  field="appointmentId"
                                  header="Appointment ID"
                                  sortable
                                  headerStyle={headerStyle}
                                  style={{ fontSize: "16px" }}
                                />
                                <Column
                                  field="vistConsultationsReason"
                                  header="Symptoms"
                                  sortable
                                  headerStyle={headerStyle}
                                  style={{ fontSize: "16px" }}
                                />

                                <Column
                                  field="userName"
                                  header="Doctor Name"
                                  sortable
                                  headerStyle={headerStyle}
                                  body={(rowData, column) => (
                                    <>
                                      <div class=" text-[15px]  ">
                                        {rowData.userSalutation} &nbsp;
                                        <span class="  text-[15px] ">
                                          {rowData.userName}
                                        </span>
                                      </div>
                                    </>
                                  )}
                                />
                                <Column
                                  field=""
                                  header="Any Allergy"
                                  sortable
                                  headerStyle={headerStyle}
                                  body={(rowData, column) => (
                                    <>
                                      <div class=" text-[15px]  ">No</div>
                                    </>
                                  )}
                                />
                                <Column
                                  field="visitDate"
                                  header="Consultation Date"
                                  sortable
                                  headerStyle={headerStyle}
                                  style={{ fontSize: "16px" }}
                                />

                                <Column
                                  field=""
                                  header="View Prescription"
                                  sortable
                                  headerStyle={headerStyle}
                                  body={(rowData, column) => (
                                    <>
                                      <div className="text-center sm:text-left">
                                        {" "}
                                        <Button
                                          variant="text"
                                          sx={{
                                            width: "20%",
                                            marginX: "0.5rem",
                                            marginY: "1rem",
                                            padding: "0",
                                            color: "#1976d2",
                                          }}
                                        >
                                          <span
                                            className=" text-sm font-semibold capitalize whitespace-nowrap"
                                            onClick={(e) =>
                                              viewPrescription(rowData)
                                            }
                                          >
                                            <i
                                              class="fa fa-eye text-xl"
                                              aria-hidden="true"
                                              title="View Prescription"
                                            ></i>
                                          </span>
                                        </Button>
                                      </div>
                                      {/* <div class="  ">
                                        {rowData?.visitType === "P" ||
                                        rowData?.visitType === "H" ? (
                                          <div className="text-center sm:text-left">
                                            <a
                                              href={
                                                filePath +
                                                "" +
                                                rowData.prescriptionDocName
                                              }
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <Button
                                                variant="text"
                                                sx={{
                                                  width: "20%",
                                                  marginX: "0.5rem",
                                                  marginY: "1rem",
                                                  padding: "0",
                                                  color: "#1976d2",
                                                }}
                                              >
                                                <span className="underline text-sm font-semibold capitalize whitespace-nowrap">
                                                  View Prescription
                                                </span>
                                              </Button>
                                            </a>
                                          </div>
                                        ) : (
                                          <div className="text-center sm:text-left">
                                            {" "}
                                            <Button
                                              variant="text"
                                              sx={{
                                                width: "20%",
                                                marginX: "0.5rem",
                                                marginY: "1rem",
                                                padding: "0",
                                                color: "#1976d2",
                                              }}
                                            >
                                              <span
                                                className=" text-sm font-semibold capitalize whitespace-nowrap"
                                                onClick={(e) =>
                                                  viewPrescription(rowData)
                                                }
                                              >
                                                <i
                                                  class="fa fa-eye text-xl"
                                                  aria-hidden="true"
                                                  title="View Prescription"
                                                ></i>
                                              </span>
                                            </Button>
                                          </div>
                                        )}
                                      </div> */}
                                    </>
                                  )}
                                />

                                <Column
                                  field="sourceChannel"
                                  header=" Source Channel"
                                  sortable
                                  headerStyle={headerStyle}
                                  style={{
                                    fontFamily: "poppins",
                                    fontSize: "16px",
                                  }}
                                  body={(rowData) =>
                                    rowData.sourceChannel ===
                                    "Backoffice-Medicare"
                                      ? "Totalcare"
                                      : rowData.sourceChannel === "ECLINIC"
                                      ? "ECLINIC"
                                      : rowData.sourceChannel === "B2C"
                                      ? "Consumer App"
                                      : rowData.sourceChannel ==
                                        "Swasthya Mitra"
                                      ? "Swasthya Mitra"
                                      : ""
                                  }
                                />
                              </DataTable>
                            </>
                          ) : (
                            <span>No Prescription Found</span>
                          )
                        ) : (
                          <span>
                            Due to parental restrictions, we regret to inform
                            you that you are not currently authorized to view
                            the Prescriptions.
                          </span>
                        )}
                      </div>
                      {/* <div className="flex align-center justify-between px-5 sm:px-10 mt-3 ">
                                            <button
                                                type="button"
                                                className="disabled:opacity-50 border rounded-lg sm:w-20 w-10 h-12 pl-0 sm:pl-5"
                                                disabled={currentPageNu == 1}

                                                onClick={() => { setcurrentPageNu(currentPageNu - 1) }} >
                                                <ArrowBackIosIcon className="w-8" />
                                            </button>
                                            <div>
                                                <span>
                                                    <b className="text-xl font-medium mt-2 text-black-700">{currentPageNu}</b>
                                                </span>
                                            </div>
                                            <button
                                                type="button"
                                                className="disabled:opacity-50 border rounded-lg sm:w-20 w-10 h-12 pl-0 sm:pl-5"
                                                disabled={currentPageNu == pagecLimit}
                                                onClick={() => { setcurrentPageNu(currentPageNu + 1) }}
                                            >
                                                <ArrowForwardIosIcon className="w-8" />
                                            </button>
                                        </div> */}
                    </div>
                    {prescriptionData?.length > 0 && allowedToSeePrescrp ? (
                      <>
                        {" "}
                        {/* <div className="paginationcontainer mt-3">
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={Math.ceil(
                              prescriptionData.length / itemsPerPage
                            )}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={(selected) => {
                              handlePageChangenew(selected.selected + 1);
                            }}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div> */}
                      </>
                    ) : (
                      ""
                    )}
                  </TabPanel>
                  <TabPanel
                    key={2}
                    value="2"
                    style={{ padding: "0px" }}
                    className="mt-5"
                  >
                    <div className="w-full border sm:p-8 p-4 rounded-md items-center flex flex-col">
                      <span className="w-full flex items-start mt-0 sm:text-lg font-bold  text-chathams-blue-500">
                        See Your Lab Reports
                      </span>

                      <div className="flex flex-col gap-3  sm:mt-5  w-full  mb-8">
                        {allowedToSeeLabRep ? (
                          // (
                          //   labData && labData.length > 0 ? (
                          //     labData.map((lab, index) => (
                          //       <Card
                          //         elevation={1}
                          //         sx={{
                          //           boxShadow:
                          //             " 0px 10px 13px rgba(0, 0, 0, 0.04)",
                          //         }}
                          //       >
                          //         <div className="flex flex-col">
                          //           <div className="flex flex-col sm:flex-row sm:px-4 border-b-2 pb-5 justify-between sm:pt-4">
                          //             <div className="flex flex-col sm:mb-0 mb-2">
                          //               <span className="text-xs font-normal text-shuttle-gray-500 ">
                          //                 Test Name
                          //               </span>
                          //               <span className="text-sm">
                          //                 {lab.labTestCode}
                          //               </span>
                          //             </div>
                          //             <div className="flex flex-col sm:pl-16 sm:mb-0 mb-2">
                          //               <span className="text-xs font-normal text-shuttle-gray-500 ">
                          //                 Lab Name
                          //               </span>
                          //               <span className="text-sm">{lab.name}</span>
                          //             </div>
                          //             <div className="flex flex-col sm:pl-16 sm:mb-0 mb-2">
                          //               <span className="text-xs font-normal text-shuttle-gray-500 ">
                          //                 Date
                          //               </span>
                          //               <span className="text-sm">
                          //                 {lab.createdDate}
                          //               </span>
                          //             </div>
                          //             <div className="flex flex-col sm:px-16 sm:mb-0 mb-2">
                          //               <span className="text-xs font-normal text-shuttle-gray-500 ">
                          //                 Order ID
                          //               </span>
                          //               <span className="text-sm">{lab.orderId}</span>
                          //             </div>
                          //           </div>
                          //           <div
                          //             style={{ display: "flex" }}
                          //             className="sm:flex-row flex-col"
                          //           >
                          //             <div className="text-center sm:text-left pl-10">
                          //               <a
                          //                 href={filePath + "" + lab.labReportDoc}
                          //                 target="_blank"
                          //                 rel="noopener noreferrer"
                          //               >
                          //                 <Button
                          //                   variant="text"
                          //                   sx={{
                          //                     width: "20%",
                          //                     marginY: "1rem",
                          //                     padding: "0",
                          //                     color: "#1976d2",
                          //                   }}
                          //                 >
                          //                   <span className="underline text-sm font-semibold capitalize whitespace-nowrap">
                          //                     View Lab Report
                          //                   </span>
                          //                 </Button>
                          //               </a>
                          //             </div>
                          //             <div className="text-center sm:text-left pl-10">
                          //               <Button
                          //                 variant="text"
                          //                 sx={{
                          //                   width: "20%",
                          //                   marginY: "1rem",
                          //                   padding: "0",
                          //                   color: "#1976d2",
                          //                 }}
                          //               >
                          //                 <span
                          //                   className="underline text-sm font-semibold capitalize"
                          //                   onClick={(e) => downloadReport(lab)}
                          //                 >
                          //                   Download
                          //                 </span>
                          //               </Button>
                          //             </div>
                          //           </div>
                          //         </div>
                          //       </Card>
                          //     ))
                          //   ) : (
                          //     <h1>No Lab Report Found</h1>
                          //   )
                          // )
                          <>
                            <DataTable
                              value={labData}
                              paginator
                              rows={50}
                              rowsPerPageOptions={[5, 10, 25, 50]}
                              tableStyle={{ padding: "10px" }}
                              rowClassName={(rowData) =>
                                rowData.serviceStatus === 2 ? "rowcolor" : ""
                              }
                            >
                              <Column
                                field="labTestCode"
                                header=" Test Name"
                                sortable
                                headerStyle={headerStyle}
                                style={{ fontSize: "16px" }}
                              />
                              <Column
                                field="name"
                                header="Lab Name"
                                sortable
                                headerStyle={headerStyle}
                                style={{ fontSize: "16px" }}
                              />
                              <Column
                                field="createdDate"
                                header="Date"
                                sortable
                                headerStyle={headerStyle}
                                style={{ fontSize: "16px" }}
                              />
                              <Column
                                field="orderId"
                                header=" Order ID"
                                sortable
                                headerStyle={headerStyle}
                                style={{ fontSize: "16px" }}
                              />

                              <Column
                                field=""
                                header="View Lab Report"
                                sortable
                                headerStyle={headerStyle}
                                body={(rowData, column) => (
                                  <>
                                    <a
                                      href={
                                        filePath + "" + rowData.labReportDoc
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <Button
                                        variant="text"
                                        sx={{
                                          marginX: "0.5rem",
                                          marginY: "1rem",
                                          padding: "0",
                                          color: "#1976d2",
                                          width: "auto",
                                        }}
                                      >
                                        <span className=" text-sm font-semibold capitalize">
                                          <i
                                            class="fa fa-eye text-xl"
                                            aria-hidden="true"
                                            title="View Labreport"
                                          ></i>
                                        </span>
                                      </Button>
                                    </a>
                                  </>
                                )}
                              />

                              <Column
                                field=""
                                header="Download Lab Report"
                                sortable
                                headerStyle={headerStyle}
                                body={(rowData, column) => (
                                  <>
                                    <Button
                                      variant="text"
                                      sx={{
                                        marginX: "0.5rem",
                                        marginY: "1rem",
                                        padding: "0",
                                        color: "#1976d2",
                                        width: "auto",
                                      }}
                                      onClick={(e) => downloadReport(rowData)}
                                    >
                                      <span className=" text-sm font-semibold capitalize">
                                        <i
                                          class="fa fa-download text-xl"
                                          aria-hidden="true"
                                          title="Download Labreport"
                                        ></i>
                                      </span>
                                    </Button>
                                  </>
                                )}
                              />

                              <Column
                                field="sourceChannel"
                                header=" Source Channel"
                                sortable
                                headerStyle={headerStyle}
                                style={{
                                  fontFamily: "poppins",
                                  fontSize: "16px",
                                }}
                                body={(rowData) =>
                                  rowData.sourceChannel ===
                                  "Backoffice-Medicare"
                                    ? "Totalcare"
                                    : rowData.sourceChannel === "ECLINIC"
                                    ? "ECLINIC"
                                    : rowData.sourceChannel === "B2C"
                                    ? "Consumer App"
                                    : rowData.sourceChannel == "Swasthya Mitra"
                                    ? "Swasthya Mitra"
                                    : ""
                                }
                              />
                            </DataTable>
                          </>
                        ) : (
                          <span>
                            Due to parental restrictions, we regret to inform
                            you that you are not currently authorized to view
                            the lab reports.
                          </span>
                        )}
                      </div>

                      {/* <div className="flex align-center justify-between px-5 sm:px-10 mt-3 ">
                                            <button
                                                type="button"
                                                className="disabled:opacity-50 border rounded-lg sm:w-20 w-10 h-12 pl-0 sm:pl-5"
                                                disabled={currentPageNulab == 1}

                                                onClick={() => { setcurrentPageNulab(currentPageNulab - 1) }} >
                                                <ArrowBackIosIcon className="w-8" />
                                            </button>
                                            <div>
                                                <span>
                                                    <b className="text-xl font-medium mt-2 text-black-700">{currentPageNulab}</b>
                                                </span>
                                            </div>
                                            <button
                                                type="button"
                                                className="disabled:opacity-50 border rounded-lg sm:w-20 w-10 h-12 pl-0 sm:pl-5"
                                                disabled={labLimit == currentPageNulab}
                                                onClick={() => { setcurrentPageNulab(currentPageNulab + 1) }}>
                                                <ArrowForwardIosIcon className="w-8" />
                                            </button>
                                        </div> */}
                    </div>
                    {labData?.length > 0 && allowedToSeePrescrp ? (
                      <>
                        {" "}
                        {/* <div className="paginationcontainer">
                          <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            pageCount={Math.ceil(labData.length / itemsPerPage)}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={(selected) => {
                              handlePageChangenewlab(selected.selected + 1);
                            }}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                          />
                        </div> */}
                      </>
                    ) : (
                      ""
                    )}
                  </TabPanel>
                  <TabPanel
                    key={3}
                    value="3"
                    style={{ padding: "0px" }}
                    className="mt-5"
                  >
                    <div className="flex flex-col sm:flex-row justify-between items-center w-full">
                      <span className="font-bold text-[20px] text-[#495057]">
                        See Your Vitals
                      </span>
                      <div className="flex flex-col sm:flex-row items-center">
                        <span className="text-md pr-4 pt-4">Select Date</span>
                        <div className="flex flex-col sm:pr-4">
                          <span className="text-md">From</span>
                          <DatePicker
                            selected={selectedFromDate}
                            onChange={handleFromDateChange}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={
                              <input style={datePickerStyles.input} />
                            }
                          />
                        </div>
                        <div className="flex flex-col">
                          <span className="text-md">To</span>
                          <DatePicker
                            selected={selectedToDate}
                            onChange={handleTodateChange}
                            dateFormat="dd/MM/yyyy"
                            placeholderText="DD/MM/YYYY"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={
                              <input style={datePickerStyles.input} />
                            }
                            minDate={selectedFromDate}
                          />
                        </div>
                      </div>

                      <p
                        className="font-medium text-sm text-[#005D8D] cursor-pointer"
                        onClick={() => handleOpenvital()}
                      >
                        <i
                          className="fa fa-plus addvital"
                          aria-hidden="true"
                        ></i>{" "}
                        Add New Vitals
                      </p>
                    </div>

                    <div className="w-full border sm:p-8 p-4 rounded-md items-center flex flex-col mt-4 ">
                      <Grid container spacing={2}>
                        {Object.entries(result_obj).map(([key, value2]) => (
                          <>
                            {" "}
                            {key == "BloodGlucose" ? (
                              ""
                            ) : (
                              <Grid item xs={12} sm={6} md={4} key={key}>
                                <Card
                                  sx={{
                                    boxShadow:
                                      "rgba(0, 0, 0, 0.1) 0px 2px 28px",
                                    width: "100%",
                                    paddingX: "2%",
                                    borderRadius: "7px",
                                    padding: "8px 12px",
                                  }}
                                >
                                  <div class="flex justify-between align-center sm:mt-1">
                                    <div>
                                      <span class="text-black-900 font-semibold font-poppins text-lg ">
                                        {key}
                                      </span>
                                      <div className="mt-2">
                                        <h2 class="text-medium font-poppins font-medium text-2xl text-chathams-blue  sm:mb-3">
                                          {value2}
                                          &nbsp;
                                          {key == "Temperature" ? (
                                            <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                              °F
                                            </span>
                                          ) : key == "HeartRate" ? (
                                            <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                              mmHg
                                            </span>
                                          ) : key == "BloodGlucose" ? (
                                            <span class="text-sm  font-rubik text-brand-secondary text-center "></span>
                                          ) : key == "SPo2" ? (
                                            <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                              %
                                            </span>
                                          ) : key == "Height" ? (
                                            <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                              cm
                                            </span>
                                          ) : key == "Weight" ? (
                                            <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                              kg
                                            </span>
                                          ) : key == "RespirationRate" ? (
                                            <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                              breath/min
                                            </span>
                                          ) : key == "BP" ? (
                                            <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                              mmHg
                                            </span>
                                          ) : key == "BMI" ? (
                                            <span class="text-sm  font-rubik text-brand-secondary text-center ">
                                              kg/m2
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </h2>
                                      </div>
                                    </div>
                                    <div>
                                      {key == "Temperature" ? (
                                        <img
                                          className="w-[30px]"
                                          src={temperature}
                                        />
                                      ) : key == "HeartRate" ? (
                                        <img
                                          className="w-[30px]"
                                          src={heartrate}
                                        />
                                      ) : key == "BloodGlucose" ? (
                                        <img
                                          className="w-[30px]"
                                          src={BloodGlucose}
                                        />
                                      ) : key == "SPo2" ? (
                                        <img className="w-[30px]" src={spo2} />
                                      ) : key == "Height" ? (
                                        <img
                                          className="w-[30px]"
                                          src={heights}
                                        />
                                      ) : key == "Weight" ? (
                                        <img
                                          className="w-[30px]"
                                          src={weights}
                                        />
                                      ) : key == "RespirationRate" ? (
                                        <img className="w-[34px]" src={pulse} />
                                      ) : key == "BP" ? (
                                        <img
                                          className="w-[30px]"
                                          src={BloodPressure}
                                        />
                                      ) : key == "BMI" ? (
                                        <img className="w-[30px]" src={bmi} />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </div>
                                  <hr
                                    style={{
                                      borderColor: "#CDE6FF",
                                      borderWidth: "1px",
                                    }}
                                  />

                                  <div className="flex justify-between align-center mt-3 sm:mb-4">
                                    <div className="flex align-center">
                                      {key == "HeartRate" ? (
                                        <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                                          {value2 >= 60 && value2 <= 100 ? (
                                            <span
                                              style={{
                                                color: "green",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Normal)
                                            </span>
                                          ) : value2 >= 101 && value2 <= 119 ? (
                                            <span
                                              style={{
                                                color: "orange",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Abnormal)
                                            </span>
                                          ) : value2 >= 120 ? (
                                            <span
                                              style={{
                                                color: "red",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Critical)
                                            </span>
                                          ) : null}
                                        </span>
                                      ) : key == "Temperature" ? (
                                        <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                                          {value2 >= 97 && value2 <= 99 ? (
                                            <span
                                              style={{
                                                color: "green",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Normal)
                                            </span>
                                          ) : (value2 < 97 && value2 >= 94) ||
                                            (value2 >= 100 && value2 <= 106) ? (
                                            <span
                                              style={{
                                                color: "orange",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Abnormal)
                                            </span>
                                          ) : value2 > 106 || value2 < 94 ? (
                                            <span
                                              style={{
                                                color: "red",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Critical)
                                            </span>
                                          ) : null}
                                        </span>
                                      ) : key == "SPo2" ? (
                                        <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                                          {value2 >= 95 && value2 <= 100 ? (
                                            <span
                                              style={{
                                                color: "green",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Normal)
                                            </span>
                                          ) : value2 >= 85 && value2 <= 94 ? (
                                            <span
                                              style={{
                                                color: "orange",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Abnormal)
                                            </span>
                                          ) : value2 < 85 ? (
                                            <span
                                              style={{
                                                color: "red",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Critical)
                                            </span>
                                          ) : null}
                                        </span>
                                      ) : key == "RespirationRate" ? (
                                        <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                                          {value2 >= 12 && value2 <= 18 ? (
                                            <span
                                              style={{
                                                color: "green",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Normal)
                                            </span>
                                          ) : value2 >= 19 && value2 <= 25 ? (
                                            <span
                                              style={{
                                                color: "orange",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Abnormal)
                                            </span>
                                          ) : value2 > 25 ? (
                                            <span
                                              style={{
                                                color: "red",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Critical)
                                            </span>
                                          ) : null}
                                        </span>
                                      ) : key == "BMI" ? (
                                        <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                                          {value2 >= 18.5 && value2 <= 25 ? (
                                            <span
                                              style={{
                                                color: "green",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Normal)
                                            </span>
                                          ) : value2 >= 25.1 &&
                                            value2 <= 29.9 ? (
                                            <span
                                              style={{
                                                color: "orange",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Abnormal)
                                            </span>
                                          ) : value2 >= 30 && value2 <= 35 ? (
                                            <span
                                              style={{
                                                color: "red",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Obese)
                                            </span>
                                          ) : value2 > 35 ? (
                                            <span
                                              style={{
                                                color: "red",
                                                fontWeight: "bold",
                                                fontSize: "15px",
                                              }}
                                            >
                                              (Morbidly Obese)
                                            </span>
                                          ) : null}
                                        </span>
                                      ) : key == "BP" ? (
                                        <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                                          {value2.split("/").length === 2 ? (
                                            // Call the function to calculate BP status
                                            getBPStatus(value2) === "Normal" ? (
                                              <span
                                                style={{
                                                  color: "green",
                                                  fontWeight: "bold",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                (Normal)
                                              </span>
                                            ) : getBPStatus(value2) ===
                                              "Pre-hypertension" ? (
                                              <span
                                                style={{
                                                  color: "orange",
                                                  fontWeight: "bold",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                (Pre-hypertension)
                                              </span>
                                            ) : getBPStatus(value2) ===
                                              "Stage-1 Hypertension" ? (
                                              <span
                                                style={{
                                                  color: "red",
                                                  fontWeight: "bold",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                (Stage-1 Hypertension)
                                              </span>
                                            ) : getBPStatus(value2) ===
                                              "Stage-1 Hypertension" ? (
                                              <span
                                                style={{
                                                  color: "purple",
                                                  fontWeight: "bold",
                                                  fontSize: "15px",
                                                }}
                                              >
                                                (Stage-2 Hypertension)
                                              </span>
                                            ) : (
                                              ""
                                            )
                                          ) : null}
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    <button
                                      onClick={() => {
                                        viewHealthGraph(key, value2);
                                      }}
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "600",
                                      }}
                                      className=" flex update-font text-seegreen
                                          font-semibold "
                                    >
                                      {" "}
                                      <img
                                        src={Newtrends}
                                        width="20px"
                                        className="mr-2 cursor"
                                      />
                                      Track Progress
                                    </button>
                                  </div>
                                </Card>
                              </Grid>
                            )}
                          </>
                        ))}
                      </Grid>
                    </div>
                  </TabPanel>
                </TabContext>
              </Box>
            </div>
          </div>

          <Modal open={isaddvitalOpen} onClose={handleClose}>
            <>
              <div
                className="fixed inset-0 flex items-center justify-center "
                style={{
                  borderBottom: "1px solid #80808042",
                  paddingBottom: "10px",
                  marginBottom: "20px",
                }}
              >
                <div className="w-[79rem] sm:h-[30rem] h-[33rem] bg-white p-4 relative flex flex-col overflow-y-auto">
                  <div className="w-full flex justify-between items-center">
                    <h2 className="text-xl font-bold text-chathams-blue-500">
                      Add Vitals
                    </h2>
                    <IconButton onClick={handleClose} aria-label="Close">
                      <CloseIcon />
                    </IconButton>
                  </div>

                  <div className="sm:px-4">
                    <div className="grid sm:grid-cols-4 grid-cols-2  sm:gap-x-10 gap-x-4 gap-y-3">
                      <div
                        sx={{ marginTop: "33px" }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                      >
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Height<spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>

                        <TextField
                          fullWidth
                          size="small"
                          id="height"
                          name="height"
                          type="text"
                          value={addvitals.height}
                          onChange={handlevitalChange}
                          placeholder="Type here"
                          className="text-[#000]"
                          inputProps={{ maxLength: 6 }}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  cm
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div
                        sx={{ marginTop: "33px" }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                      >
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Weight<spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          id="weight"
                          name="weight"
                          type="text"
                          placeholder="Type here"
                          className="text-[#000]"
                          value={addvitals.weight}
                          onChange={handlevitalChange}
                          inputProps={{ maxLength: 6 }}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  kg
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div
                        sx={{ marginTop: "33px" }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                      >
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Temperature
                          <spam style={{ color: "#F34040" }}> * </spam>
                          {addvitals.temperature && (
                            <span class="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                              {addvitals.temperature >= 97 &&
                              addvitals.temperature <= 99 ? (
                                <span
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  (Normal)
                                </span>
                              ) : (addvitals.temperature < 97 &&
                                  addvitals.temperature >= 94) ||
                                (addvitals.temperature >= 100 &&
                                  addvitals.temperature <= 106) ? (
                                <span
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold",
                                  }}
                                >
                                  (Abnormal)
                                </span>
                              ) : addvitals.temperature > 106 ||
                                addvitals.temperature < 94 ? (
                                <span
                                  style={{ color: "red", fontWeight: "bold" }}
                                >
                                  (Critical)
                                </span>
                              ) : null}
                            </span>
                          )}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          id="temperature"
                          name="temperature"
                          type="text"
                          placeholder="Type here"
                          className="text-[#000]"
                          inputProps={{ maxLength: 6 }}
                          value={addvitals.temperature}
                          onChange={handlevitalChange}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  °F
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div
                        sx={{ marginTop: "33px" }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                      >
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Respiration Rate
                          <spam style={{ color: "#F34040" }}> * </spam>
                          {addvitals.respirationRate && (
                            <span class="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                              {addvitals.respirationRate >= 12 &&
                              addvitals.respirationRate <= 18 ? (
                                <span
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  (Normal)
                                </span>
                              ) : addvitals.respirationRate >= 19 &&
                                addvitals.respirationRate <= 25 ? (
                                <span
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold",
                                  }}
                                >
                                  (Abnormal)
                                </span>
                              ) : addvitals.respirationRate > 25 ? (
                                <span
                                  style={{ color: "red", fontWeight: "bold" }}
                                >
                                  (Critical)
                                </span>
                              ) : null}
                            </span>
                          )}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          id="respirationRate"
                          name="respirationRate"
                          type="text"
                          placeholder="Type here"
                          className="text-[#000]"
                          inputProps={{ maxLength: 6 }}
                          value={addvitals.respirationRate}
                          onChange={handlevitalChange}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  breaths/min
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div
                        sx={{ marginTop: "33px" }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                      >
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Blood Pressure (Systolic)
                          <spam style={{ color: "#F34040" }}> * </spam>
                          {addvitals.systolic && (
                            <span className="font-montserrat text-xs font-normal ml-1">
                              {addvitals.systolic <= 120 ? (
                                <span
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  Normal
                                </span>
                              ) : addvitals.systolic > 120 &&
                                addvitals.systolic <= 139 ? (
                                <span
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Pre-hypertension
                                </span>
                              ) : addvitals.systolic >= 140 &&
                                addvitals.systolic <= 159 ? (
                                <span
                                  style={{ color: "red", fontWeight: "bold" }}
                                >
                                  Stage-1 Hypertension
                                </span>
                              ) : addvitals.systolic > 159 ? (
                                <span
                                  style={{
                                    color: "purple",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Stage-2 Hypertension
                                </span>
                              ) : null}
                            </span>
                          )}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          id="systolic"
                          name="systolic"
                          type="text"
                          placeholder="Type here"
                          className="text-[#000]"
                          inputProps={{ maxLength: 6 }}
                          value={addvitals.systolic}
                          onChange={handlevitalChange}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  mmHg
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div
                        sx={{ marginTop: "33px" }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                      >
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Blood Pressure (Diastolic)
                          <spam style={{ color: "#F34040" }}> * </spam>
                          {addvitals.diastolic && (
                            <span class="font-montserrat text-xs font-normal ml-1">
                              (
                              {addvitals.diastolic <= 80 ? (
                                <span class="text-green-500 font-bold">
                                  (Normal)
                                </span>
                              ) : addvitals.diastolic >= 81 &&
                                addvitals.diastolic <= 89 ? (
                                <span class="text-yellow-500 font-bold">
                                  (Pre-hypertension)
                                </span>
                              ) : addvitals.diastolic >= 90 &&
                                addvitals.diastolic <= 99 ? (
                                <span class="text-orange-500 font-bold">
                                  (Stage-1 Hypertension)
                                </span>
                              ) : addvitals.diastolic > 99 ? (
                                <span class="text-red-500 font-bold">
                                  (Stage-2 Hypertension)
                                </span>
                              ) : null}
                              )
                            </span>
                          )}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          id="diastolic"
                          name="diastolic"
                          type="text"
                          placeholder="Type here"
                          className="text-[#000]"
                          value={addvitals.diastolic}
                          onChange={handlevitalChange}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{ maxLength: 6 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  mmHg
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div
                        sx={{ marginTop: "33px" }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                      >
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Pulse Ox.
                          <spam style={{ color: "#F34040" }}> * </spam>
                          {addvitals.spo2 && (
                            <span class="font-montserrat text-xs font-normal ml-1">
                              (
                              {addvitals.spo2 >= 95 && addvitals.spo2 <= 100 ? (
                                <span
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  Normal
                                </span>
                              ) : addvitals.spo2 >= 85 &&
                                addvitals.spo2 <= 94 ? (
                                <span
                                  style={{
                                    color: "orange",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Abnormal
                                </span>
                              ) : addvitals.spo2 < 85 ? (
                                <span
                                  style={{ color: "red", fontWeight: "bold" }}
                                >
                                  Critical
                                </span>
                              ) : null}
                              )
                            </span>
                          )}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          id="spo2"
                          name="spo2"
                          type="text"
                          placeholder="Type here"
                          className="text-[#000]"
                          value={addvitals.spo2}
                          onChange={handlevitalChange}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{ maxLength: 6 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  %
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div
                        sx={{ marginTop: "33px" }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                      >
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Heart Rate
                          <spam style={{ color: "#F34040" }}> * </spam>
                          {addvitals.heartRate && (
                            <span className="font-montserrat text-xs font-normal text-gray-secondary ml-1">
                              (
                              {addvitals.heartRate >= 60 &&
                              addvitals.heartRate <= 100 ? (
                                <span
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  Normal
                                </span>
                              ) : addvitals.heartRate >= 101 &&
                                addvitals.heartRate <= 119 ? (
                                <span
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  Abnormal
                                </span>
                              ) : addvitals.heartRate >= 120 ? (
                                <span
                                  style={{ color: "green", fontWeight: "bold" }}
                                >
                                  Critical
                                </span>
                              ) : null}
                              )
                            </span>
                          )}
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          id="heartRate"
                          name="heartRate"
                          type="text"
                          placeholder="Type here"
                          className="text-[#000]"
                          value={addvitals.heartRate}
                          onChange={handlevitalChange}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{ maxLength: 6 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Beats/min
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div
                        sx={{ marginTop: "33px" }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                      >
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Blood Glucose
                          <spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          fullWidth
                          size="small"
                          id="bloodGlucose"
                          name="bloodGlucose"
                          type="text"
                          placeholder="Type here"
                          className="text-[#000]"
                          value={addvitals.bloodGlucose}
                          onChange={handlevitalChange}
                          onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          inputProps={{ maxLength: 6 }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  mg/dL
                                </Typography>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                    </div>
                    <div>
                      <button
                        className="bg-[#004171] text-white sm:mt-14 mt-7 px-5 py-1 rounded-[20px]"
                        onClick={savevitals}
                      >
                        Save Data
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          </Modal>
        </div>
      </div>
      {screen > 500 ? (
        <Footer i={1} />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </>
  );
};

export default Healthrecords;
