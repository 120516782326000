import httpCommon from './httpcommon';
class Location {
    getCities() {
        return httpCommon.post('location/city/dropdown');
    };
    getLocation(payload) {
        return httpCommon.post('location/getAll', payload || {});
    };
    getEclinicsDetails() {
        return httpCommon.post('location/getAllEclinics');
    };
    getEclinicsByPincode(payload) {
        return httpCommon.post('dropdown/eclinics', payload);
    };
    getEclinicsBypincode(payload) {
        return httpCommon.post('location/eclinics', payload);
    };
    fetchMapMyIndiaToken() {
        let payload = {
            code: 'web'
        }
        return httpCommon.post('mapMyIndia/token', payload)
    }

}
export default new Location();
