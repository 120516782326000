import React from 'react';

const WhatsAppLink = ({ phoneNumber, children }) => {
    const handleWhatsAppClick = () => {
        const whatsappUrl = `https://wa.me/${phoneNumber}`;
        // window.location.href = whatsappUrl;
        window.open(whatsappUrl, '_blank');
    };

    return (
        <a
            href={`tel:${phoneNumber}`}
            onClick={handleWhatsAppClick}
            className="pl-4 w-full break-keep text-white link2"
        >
            {children}
        </a>
    );
};
export default WhatsAppLink;
