import services from "./index";
import httpcommon from "./httpcommon"
import axios from "axios";
class myprofilereview {
  getreview() {
    return httpcommon.post(
      "/review/getAllQuestion"
    );
  };

  save(payload) {
    return httpcommon.post("/review/save", payload)
  }

  getuser(payload) {
    return httpcommon.post("/review/getReviewDetails", payload)
  }
  update(payload) {
    return httpcommon.post("/review/update", payload)
  }

}
export default new myprofilereview();