import React, { useEffect, useReducer, useState } from "react";
import moment from "moment";

import { jsPDF } from "jspdf";
import { useNavigate } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import { Button } from "@mui/material";

import { postDataLogin } from "../../store/actions/buymedicine";
import mydetailservice from "../../store/services/mydetailsservice";
// import { APP_ROUTES } from '../constants/approutes';
// import { IMG_URL } from "../config/constant";
// import { jsPDF } from "jspdf";
// import { useHistory, useLocation } from "react-router";

function ConsulatationReportEC(props) {
  // const history = useHistory();
  const location = useLocation();

  //const { postConsultation, isLoading } = useSelector(state => state.doctorconsultationlist);
  const [postConsultation, setpostConsultation] = useState(
    location.state?.epres
  );
  const [locationdata, setLocationdata] = useState(location.state?.dashboard);
  const [click, setClick] = useState(false);

  //const { patientLabTestsList, patientDrugPrescriptionList } = postConsultation;
  const [patientLabTestsList, setpatientLabTestsList] = useState();
  const [patientDrugPrescriptionList, setpatientDrugPrescriptionList] =
    useState(postConsultation.patientDrugPrescriptionList);
  const [labTestList, setLabTestList] = useState(
    postConsultation.labTestPrescriptionVOList
  );
  const [stamp, setStamp] = useState([]);

  const [signature, setSignature] = useState([]);
  // const { state } = location;

  const navigate = useNavigate();

  useEffect(() => {
    console.log(postConsultation?.userSignature, "ytruytiuy");
    let payload = {
      fileName: postConsultation?.userSignature,
    };
    if (postConsultation?.patientName) {
      mydetailservice.downloadLabDoc(payload).then(
        (res) => {
          setTimeout(() => {
            setClick(true);
          }, 1000);
          if (res.data) {
            setSignature(
              "data:image/jpg;base64," + res.data.succesObject.fileData
            );
          }
          console.log(res.data.succesObject.fileData, "response");
        },
        (err) => {
          console.log(err);
        }
      );
      let payload1 = {
        fileName: postConsultation?.hospitalStamp,
      };

      mydetailservice.downloadLabDoc(payload1).then(
        (res) => {
          console.log(res);
          if (res.data) {
            setStamp("data:image/jpg;base64," + res.data.succesObject.fileData);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }, [postConsultation?.patientName]);

  console.log(signature, stamp, "signature");

  const download = () => {
    const input = document.getElementById("abc");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: "A4",
      userUnit: "px",
    });
    pdf
      .html(input, {
        html2canvas: { scale: 0.3, allowTaint: false },
        margin: [1, 0, 1, 6],
        autoPaging: true,
      })
      .then(() => {
        const fileName =
          "e-Prescription-" + postConsultation?.patientName + ".pdf";
        console.log(fileName);
        pdf.save(fileName);
        //history.goBack();
      });
  };

  const calage = (dateString) => {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const Closedata = () => {
    if (locationdata == "myhealthrecords") {
      navigate(APP_ROUTES.MYHEALTHRECORDS);
    } else if (locationdata == "dashboard") {
      navigate(APP_ROUTES.MAINDASHBOARD);
    } else if (locationdata == "healthrecords") {
      navigate(APP_ROUTES.MYPRESCRIPTION);
    }
  };

  const redirectToB2C = (searchQuery) => {
    const baseUrl = "https://curebay.com"; // Remove the trailing slash here
    const queryParams = `/cart?purchase_token=${encodeURIComponent(
      searchQuery
    )}&source=Medicare`;

    const url = `${baseUrl}${queryParams}`;
    window.open(url, "_blank");
  };

  const redirectToB2Clabtest = (searchQuery) => {
    const baseUrl = "https://curebay.com"; // Remove the trailing slash here
    const queryParams = `/labtests?purchase_token=${encodeURIComponent(
      searchQuery
    )}`;

    const url = `${baseUrl}${queryParams}`;
    window.open(url, "_blank");
  };

  const handleBuymedicine = async () => {
    if (postConsultation?.patientDrugPrescriptionList.length) {
      let payload = {
        prescriptionId: postConsultation?.patientDrugPrescriptionList.length
          ? postConsultation?.patientDrugPrescriptionList[0].prescriptionId
          : null,
        patientCode: postConsultation.patientId,
        appointmentId: postConsultation.appointmentId,
      };
      console.log(payload, "uggujhjk");
      // let url = 'order/getPurchaseToken';
      // const token = await AsyncStorage.getItem('token');
      let res = await postDataLogin(payload);
      if (res.data.responseCode == "200" && res.data.succesObject) {
        // console.log(res.data.succesObject, "uggujhjk")
        redirectToB2C(res.data.succesObject.purchaseToken);
      }
    }
  };

  const handleBuylabtest = async () => {
    if (postConsultation?.labTestPrescriptionVOList.length) {
      let payload = {
        prescriptionId: null,
        patientCode: postConsultation.patientId,
        appointmentId: postConsultation.appointmentId,
      };
      let res = await postDataLogin(payload);
      if (res.data.responseCode == "200" && res.data.succesObject) {
        redirectToB2Clabtest(res.data.succesObject.purchaseToken);
      }
    }
  };
  console.log(postConsultation, "postConsultation");

  return (
    <>
      <div className="w-full overflow-x-auto pt-[153px]">
        <div className="flex  justify-end gap-7 sm:mr-14">
          <div className="">
            {/* <button onClick={Closedata} className="text-[#004171] rounded-[23px] text-[16px] font-semibold cursor-pointer px-5 py-3 font-poppins" style={{ border: "1px solid #004171" }}>
                        Close
                    </button> */}
            <Button
              variant="contained"
              onClick={Closedata}
              className="text-[13px] w-full font-semibold text-center text-white  rounded-lg"
              style={{ backgroundColor: "#f36367", borderRadius: "11px" }}
            >
              <i class="fa fa-times-circle text-lg mr-2" aria-hidden="true"></i>
              Close
            </Button>
          </div>
          <div className="">
            <Button
              variant="contained"
              onClick={download}
              className="text-[13px] w-full font-semibold text-center text-white  rounded-lg"
              style={{ backgroundColor: "#759e61", borderRadius: "11px" }}
            >
              <i
                class="fa fa-cloud-download  text-lg mr-2"
                aria-hidden="true"
              ></i>
              Download
            </Button>
          </div>
          <div className="">
            {/* <a
                        href="https://www.curebay.com/medicine"
                        target="_blank"
                        className="cursor-pointer"
                    > */}

            <Button
              variant="contained"
              onClick={handleBuymedicine}
              className="text-[13px] w-full font-semibold text-center text-white  rounded-lg"
              style={{ backgroundColor: "#306791", borderRadius: "11px" }}
            >
              <i class="fa fa-cart-plus  text-lg mr-2" aria-hidden="true"></i>
              Buy Medicine
            </Button>
            {/* </a> */}
          </div>
          <div className="">
            <a className="cursor-pointer">
              <Button
                variant="contained"
                onClick={handleBuylabtest}
                className="text-[13px] w-full font-semibold text-center text-white  rounded-lg"
                style={{ backgroundColor: "#03b0be", borderRadius: "11px" }}
              >
                <i class="fa fa-flask  text-lg mr-2" aria-hidden="true"></i>
                Book Labtest
              </Button>
            </a>
          </div>
        </div>

        <div
          id="abc"
          className="p-5 bg-white-100 mt-10 mb-0 mx-4 w-90 justify-center  border dark:border-slate-700"
          style={{ width: "1360px", marginBottom: "75px" }}
        >
          <div className="px-2">
            <div className="flex justify-between mb-4  ml-1">
              <div></div>
              <div></div>
            </div>

            <div className="lg:flex flex flex-col">
              {
                <h6 className="font-bold ">
                  {postConsultation?.userSalutation
                    ? postConsultation?.userSalutation
                    : "Dr."}{" "}
                  {postConsultation?.doctorName
                    ? postConsultation.doctorName
                    : postConsultation.userName}
                </h6>
              }

              <h6 className="font-bold ">
                {postConsultation?.userQualification}
              </h6>

              <h6 className="font-bold ">
                {postConsultation?.userSpecialityDept}
              </h6>

              <h6 className="font-bold  mb-2">
                Regn No:{postConsultation?.userMCIVerification}
              </h6>

              <hr className="hr-line" />
            </div>

            <div className="grid grid-cols-6 mb-5">
              <div className="border dark:border-slate-700 px-3 py-1 font-bold">
                Name:
              </div>
              <div className="border dark:border-slate-700 px-3 py-1">
                {postConsultation?.patientsalutation +
                  " " +
                  postConsultation?.patientName}
              </div>

              <div className="border dark:border-slate-700 px-3 py-1 font-bold">
                Age/Sex:
              </div>
              <div className="border dark:border-slate-700 px-3 py-1">
                {calage(postConsultation?.patientDOB) +
                  " / " +
                  (postConsultation?.patientgender === "M"
                    ? "Male"
                    : postConsultation?.patientgender === "F"
                    ? "Female"
                    : "")}
              </div>

              <div className="border dark:border-slate-700 px-3 py-1 font-bold">
                Hospital:
              </div>
              <div className="border dark:border-slate-700 px-3 py-1">
                {postConsultation?.patientHospitalName}
              </div>

              <div className="border dark:border-slate-700 px-3 py-1 font-bold">
                Phone Number:
              </div>
              <div className="border dark:border-slate-700 px-3 py-1">
                {postConsultation?.patientMobileNo}
              </div>

              <div className="border dark:border-slate-700 px-3 py-1 font-bold">
                Visit Type:
              </div>
              <div className="border dark:border-slate-700 px-3 py-1">
                {postConsultation?.consultationType === "Q"
                  ? "Quick Consultation"
                  : postConsultation?.consultationType === "V"
                  ? "Video Consultation"
                  : postConsultation?.consultationType === "I"
                  ? "In-person Consultation"
                  : postConsultation?.consultationType === "A"
                  ? "Audio Consultation"
                  : "Quick Consultation"}
              </div>
              <div className="border dark:border-slate-700 px-3 py-1 font-bold">
                Visit Date:
              </div>
              <div className="border dark:border-slate-700 px-3 py-1" v>
                {moment(postConsultation?.visitDate).format("DD/MM/yyyy")}
              </div>
            </div>

            {/* <img src={''} style={{ width: "25px" }} /> */}

            {/* <div className="grid grid-cols-1 my-2">
                            <p>
                                <b className="underline underline-offset-1">
                                    Chief Complaint:
                                </b>{" "}
                                {postConsultation?.consultationsReason}
                            </p>
                        </div> */}

            {postConsultation?.consultReason && (
              <div className="grid grid-cols-1 my-2">
                <p className="mb-2">
                  <b className="underline underline-offset-4">
                    Reason of Consultation:
                  </b>{" "}
                  {postConsultation?.consultReason}
                </p>
              </div>
            )}

            {(postConsultation?.primarySymptoms ||
              postConsultation?.primaryDiagnosis) && (
              <div className="grid grid-cols-1 my-2 mb-4">
                <p className="">
                  <b className="underline underline-offset-4">
                    Primary Diagnosis :
                  </b>{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  ICD-10 : {postConsultation?.primarySymptoms}{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  Description : {postConsultation?.primaryDiagnosis}{" "}
                </p>
              </div>
            )}
            {(postConsultation?.secondarySymptoms ||
              postConsultation?.secondaryDiagnosis) && (
              <div className="grid grid-cols-1 my-2">
                <p className="">
                  <b className="underline underline-offset-4">
                    Secondary Diagnosis :
                  </b>{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  ICD-10 : {postConsultation?.secondarySymptoms}{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  Description : {postConsultation?.secondaryDiagnosis}{" "}
                </p>
              </div>
            )}
            {postConsultation?.symptoms && (
              <div className="mb-5">
                <p className="underline underline-offset-4">
                  <b className="underline underline-offset-4">Symptoms :</b>{" "}
                </p>
                <p className="text-base font-montserrat  font-thin ">
                  {postConsultation?.symptoms}
                </p>
              </div>
            )}
            {postConsultation?.patientVitalList.length == 0 ? (
              ""
            ) : (
              <>
                {postConsultation?.patientVitalList &&
                  postConsultation?.patientVitalList.length && (
                    <>
                      <div className="grid grid-cols-1 my-2">
                        <p>
                          <b className="underline underline-offset-4">
                            Vitals :
                          </b>{" "}
                          {postConsultation?.patientVitalList[0].height && (
                            <>
                              {" "}
                              Height -{" "}
                              {
                                postConsultation?.patientVitalList[0].height
                              } CM{" "}
                            </>
                          )}{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {postConsultation?.patientVitalList[0].weight && (
                            <>
                              {" "}
                              Weight -{" "}
                              {
                                postConsultation?.patientVitalList[0].weight
                              } KG{" "}
                            </>
                          )}{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {postConsultation?.patientVitalList[0].bmi && (
                            <>
                              {" "}
                              BMI - {
                                postConsultation?.patientVitalList[0].bmi
                              }{" "}
                              KG/M2{" "}
                            </>
                          )}{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {postConsultation?.patientVitalList[0].systolic && (
                            <>
                              {" "}
                              Blood Pressure (SYS) &nbsp;&nbsp; -{" "}
                              {
                                postConsultation?.patientVitalList[0].systolic
                              }{" "}
                              mmHg{" "}
                            </>
                          )}{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {postConsultation?.patientVitalList[0].diastolic && (
                            <>
                              {" "}
                              Blood Pressure (DIA) -{" "}
                              {
                                postConsultation?.patientVitalList[0].diastolic
                              }{" "}
                              mmHg{" "}
                            </>
                          )}{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {postConsultation?.patientVitalList[0].spo2 && (
                            <>
                              {" "}
                              Pulse Ox. -{" "}
                              {
                                postConsultation?.patientVitalList[0].spo2
                              } %{" "}
                            </>
                          )}{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {postConsultation?.patientVitalList[0].heartRate && (
                            <>
                              {" "}
                              Heart Rate -{" "}
                              {
                                postConsultation?.patientVitalList[0].heartRate
                              }{" "}
                              Beats/min{" "}
                            </>
                          )}{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {postConsultation?.patientVitalList[0]
                            .respirationRate && (
                            <>
                              {" "}
                              Respiration Rate -{" "}
                              {
                                postConsultation?.patientVitalList[0]
                                  .respirationRate
                              }{" "}
                              Breaths/min{" "}
                            </>
                          )}{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {postConsultation?.patientVitalList[0]
                            .temperature && (
                            <>
                              {" "}
                              Temperature -{" "}
                              {
                                postConsultation?.patientVitalList[0]
                                  .temperature
                              }{" "}
                              °F{" "}
                            </>
                          )}{" "}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </p>
                      </div>
                    </>
                  )}
              </>
            )}
            <div className="mb-2">
              {patientDrugPrescriptionList &&
                patientDrugPrescriptionList.length > 0 && (
                  <>
                    <p className="  mb-2 underline underline-offset-4">
                      <b className="underline underline-offset-4">
                        Medicines Prescribed :
                      </b>{" "}
                    </p>

                    <table className="table-auto w-full mb-5">
                      <thead>
                        <tr>
                          <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left"></th>
                          <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                            Medication
                          </th>
                          <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                            Dosage
                          </th>
                          <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                            Qty
                          </th>
                          <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                            Notes
                          </th>
                          <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                            Instruction
                          </th>
                          {/* <th className="border-2 border-inherit">Periodically</th> */}
                          <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                            Duration
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {patientDrugPrescriptionList &&
                          patientDrugPrescriptionList.length > 0 &&
                          patientDrugPrescriptionList.map((res, i) => (
                            <>
                              <tr>
                                <td className="border dark:border-slate-700 px-3 py-1">
                                  {i + 1}
                                </td>
                                <td className="border dark:border-slate-700 px-3 py-1">
                                  {res?.drugName}
                                  <br></br>
                                  <span className="text-xs">
                                    {res?.composition}
                                  </span>
                                </td>
                                <td className="border dark:border-slate-700 px-3 py-1">
                                  {res?.dosage}
                                </td>
                                <td className="border dark:border-slate-700 px-3 py-1">
                                  {res?.quantity}
                                </td>
                                <td className="border dark:border-slate-700 px-3 py-1">
                                  {res?.notes}
                                </td>
                                <td className="border dark:border-slate-700 px-3 py-1">
                                  {res.instruction === "0"
                                    ? "After Meal"
                                    : "Before Meal"}
                                </td>
                                <td className="border dark:border-slate-700 px-3 py-1">
                                  {" "}
                                  {res?.duration} days
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}
              <div className="mt-2  mb-5">
                {labTestList && (
                  <p className="  underline underline-offset-4  mb-2">
                    <b className="underline underline-offset-4">
                      Lab Test Prescribed :
                    </b>{" "}
                  </p>
                )}

                <table className="table-auto w-full mb-5">
                  <thead>
                    <tr>
                      <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                        Test Name
                      </th>
                      <th className="border dark:border-slate-700 px-3 py-2 mb-2 text-left">
                        Test Type
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {labTestList &&
                      labTestList?.map((labres, i) => (
                        <>
                          <tr>
                            <td className="border dark:border-slate-700 px-3 py-1">
                              {labres.labTestName}
                            </td>
                            <td className="border dark:border-slate-700 px-3 py-1">
                              {labres.labTestType}
                            </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="my-2">
              <div className="w-6/6  my-2">
                {/* <div>
                    <p className="">Cheif Complaint</p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.consultReason}
                    </p>
                  </div> */}

                {postConsultation?.recommendation && (
                  <div className="mb-5">
                    <p className="underline underline-offset-4">
                      <b className="underline underline-offset-4">
                        Recommendation :
                      </b>{" "}
                    </p>
                    <p className="text-base font-montserrat  font-thin ">
                      {postConsultation?.recommendation}
                    </p>
                  </div>
                )}

                {postConsultation?.followUpVisitDate && (
                  <div>
                    <p className=" my-2">
                      <b className="underline underline-offset-4">
                        Scheduled follow up visit date :
                      </b>{" "}
                      {moment(postConsultation?.followUpVisitDate).format(
                        "DD/MM/YYYY"
                      )}
                    </p>
                  </div>
                )}
              </div>
            </div>

            {props && props.data ? (
              ""
            ) : (
              <div className="flex  justify-between">
                <div className="mt-6">
                  {!postConsultation?.hospitalStamp ? (
                    ""
                  ) : (
                    <>
                      {" "}
                      {stamp && (
                        <img
                          // onClick={download}
                          src={stamp}
                          // src={process.env.REACT_APP_IMAGE_URL + postConsultation?.hospitalStamp}
                          alt="Stamp"
                          width={150}
                        />
                      )}
                    </>
                  )}
                </div>

                <div className="mt-6">
                  {signature && (
                    <>
                      <img
                        id="signatureImage"
                        src={signature}
                        alt="Signature"
                        width={150}
                      />
                      <p className="font-rubik text-gray-primary text-base">
                        Dr.
                        {postConsultation?.doctorName
                          ? postConsultation.doctorName
                          : postConsultation.userName}
                      </p>
                      <p className="font-rubik text-gray-primary text-base">
                        {postConsultation?.userQualification}{" "}
                      </p>
                      <p className="font-rubik text-gray-primary text-base">
                        Regn No. {postConsultation?.userMCIVerification}
                      </p>
                    </>
                  )}
                </div>
              </div>
            )}
          </div>

          <div className="grid grid-cols-1 ">
            <div className="px-1 py-1">
              <p className="text-gray-500 text-sm ">
                <b>Disclaimer :</b> This is an ONLINE consultation response. The
                patient has not been physically examined. The prescriptionor
                advice is based on the patient's description of the problem
                which is given above and also explained over video consultation{" "}
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 ">
            <div className="px-1 py-1">
              {postConsultation?.consultationType === "I" ? (
                <span> </span>
              ) : (
                <p className="text-gray-500 text-sm ">
                  <b>Disclaimer :</b>
                  <ul className="list-disc ml-8">
                    <li>
                      The information and advice provided here is provisional in
                      nature as it is based on the limited information made
                      available by the patient
                    </li>
                    <li>
                      The patient is advised to visit in person for thorough
                      examination at the earliest
                    </li>
                    <li>
                      The information is confidential in nature and for
                      recipient's use only
                    </li>
                    <li>The Prescription is generated on a Teleconsultation</li>
                    <li>Not valid for medico - legal purp</li>{" "}
                  </ul>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ConsulatationReportEC;
