import React, { useEffect, useState } from "react";
import Footer from '../../components/webflow/footer';
import { getAllSpecialities } from "../../store/actions/doctors";
import { getDoctorslist } from "../../store/actions/doctors";
import {
  fetchDoctorsData,
  getDoctorsSliderDetails,
} from "../../store/actions/doctors";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import { Dialog } from 'primereact/dialog';
import Aos from "aos";
import 'aos/dist/aos.css';
import services from '../../store/services';
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";



const Doctornew = () => {
  const [list, setList] = useState([]);
  const [doctorSpecialities, setDoctorSpecialities] = useState([]);
  const [doctordata, setDoctordata] = useState();
  const [individualdocDescription, setindividualdocDescription] = useState();
  const navigation = useNavigate();
  const [visible, setVisible] = useState(false);
  const [screen, setscreen] = useState(window.innerWidth);
  var nloop = null;
  useEffect(() => {
    async function fetchData() {
      const res = await getAllSpecialities();
      if (res?.status === 200 && res?.data?.succesObject) {
        setDoctorSpecialities(res.data.succesObject);
        nloop = 9 * Math.ceil(res.data.succesObject?.length / 9);
      }
    }
    fetchData();
  }, []);
  useEffect(() => {
    async function docList() {
      const res = await getDoctorslist();
      if (res?.status === 200 && res?.data) {
        setList(res?.data);
      }
    }
    docList();

    async function docData() {
      const res = await fetchDoctorsData();
      if (res?.status === 200 && res?.data?.data[0]) {
        setDoctordata(res?.data?.data[0]);
      }
    }
    docData();
  }, []);
  const eliteDoc = list.filter((value) => (value.doctorType = "null"));
  console.log(eliteDoc, "doctordata");

  const handleSelectChange = (e) => {
    const speciality = e.target.value;
    navigation(APP_ROUTES.DOCTORSPECIALITY, { state: { id: "docsp", name: speciality } });
  };
  const individualDescription = (e) => {
    setindividualdocDescription(e)
    setVisible(true);
  }
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  const [getdoctordata, setGetdoctorData] = useState([]);
  const getAllData = async () => {
    const res = await services.get("doctors-pages?populate=deep");
    setGetdoctorData(res?.data?.data[0].attributes)
  }

  useEffect(() => { getAllData() }, [])
  console.log(getdoctordata, "getdoctordataaa")
  return (
    <>
      <div className="page-wrapper">
        <div className="position-relative">
          <div className="section hero doctor-hero">
            <div className="container-default w-container">
              <div className="z-index-2">
                <div data-w-id="44863fe2-998c-7388-f408-e708728f0f3a" data-aos="slide-up"
                  // style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}}

                  className="inner-container _858px center">
                  <div className="text-center mg-bottom-48px">

                    <h1 className="display-1 color-neutral-100 mg-bottom-32px">Meet our doctors</h1>
                    <div>
                      <div className="w-layout-grid grid-3-columns navigation">
                        <div id="w-node-_75d7267e-46a6-f7ac-c381-5c9f900330b9-900330b7" className="navigation-box">
                          <div data-hover="true" data-delay={0} id="w-node-_75d7267e-46a6-f7ac-c381-5c9f900330ba-900330b7" data-w-id="75d7267e-46a6-f7ac-c381-5c9f900330ba" className="navigation-dropdown-wrapper w-dropdown">
                            <div className="navigation-dropdown-toggle w-dropdown-toggle">
                              <div className="flex-horizontal justify-start">
                                <div className="mg-right-8px">
                                </div>
                                <select onChange={handleSelectChange} className="w-full">
                                  <option value="">Speciality</option>
                                  {doctorSpecialities?.map((e) => (


                                    <option value={e?.speciality} >
                                      {e?.speciality}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div id="w-node-_75d7267e-46a6-f7ac-c381-5c9f900330df-900330b7" className="navigation-box">
                          <div data-hover="true" data-delay={0} id="w-node-_75d7267e-46a6-f7ac-c381-5c9f900330e0-900330b7" data-w-id="75d7267e-46a6-f7ac-c381-5c9f900330e0" className="navigation-dropdown-wrapper w-dropdown">
                            <div className="navigation-dropdown-toggle w-dropdown-toggle">
                              <div className="flex-horizontal justify-start">
                                <div className="mg-right-8px">
                                   </div>
                                <div>Location</div>
                              </div>
                              <div className="line-rounded-icon dropdown-arrow"></div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="z-index-1">
                <div data-w-id="907a57a0-26df-1191-4bd3-3c784c3811b9" data-aos="fade-up"
                  //  style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}} 

                  className="w-dyn-list">
                  <div role="list" className="grid-3-columns gap-row-32px w-dyn-items" data-aos="slide-up">
                    {getdoctordata.ForthComponent?.map((value) => (

                      <>
                        <div role="listitem" className="w-dyn-item ">
                          <div className="card doctor" >
                            <a data-w-id="907a57a0-26df-1191-4bd3-3c784c3811bd" href="#" className="display-block w-inline-block">
                              <div className="card-picture-wrapper">
                                <img
                                  // src={value.userPhoto} 
                                  src={value.image_url}
                                  alt="" className="sm:h-[200px] sm:w-[200px] w-[226px] rounded-3xl" />
                                {/* <div className="card-details-flex-container doctors-card-tag v2">
                              <div className="badge-secondary small badge-special-left-bottom" />
                              {value.specialities}
                            </div>
                            <div className="card-details-flex-container doctors-card-tag v2">
                              <div className="badge-secondary small badge-special-left-bottom" />
                              {value.specialities}
                            </div> */}
                              </div>
                            </a>
                            <div className="card-description doctors-description">
                              <a data-w-id="907a57a0-26df-1191-4bd3-3c784c3811c3" href="#" className="text-decoration-none w-inline-block">

                                <h2 className="card-title-dark heading-h4-size mg-bottom-4px" style={{ color: "rgb(19, 24, 47)" }}>{value.name} </h2>
                              </a>
                              <p className=" sm:h-[43px] mb-0 h-auto  mt-1 font-semibold text-base  text-[#3cba84]" >{value.specliality}</p>
                              <p className="mt-1 mb-0 text-base font-semibold text-[#3cba84]" >Experience:&nbsp;{value.experience}</p>
                              <p className="mt-1 font-bold color-neutral-700 text-sm" >Qualification:&nbsp;{value.qualification}</p>

                              <div>
                                <div className="sm:h-24 overflow-hidden text-left sm:text-center"  >
                                  {value.description}

                                </div >
                                <span onClick={() => individualDescription(value?.description)} className="text-blue font-bold cursor-pointer hidden sm:block" >Read more...</span>
                              </div>


                              <div />
                            </div>


                          </div>

                        </div>

                      </>
                    ))}

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="half-bg-top doctor-bg-top" />
        </div>
      </div>
      <Dialog header="About Doctor" visible={visible}  style={{ width: '50vw' }} onHide={() => setVisible(false)}>


        <p className="m-0">
          {individualdocDescription}
        </p>

      </Dialog>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </>

  );

};

export default Doctornew;
