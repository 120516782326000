import React from "react";
import { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import logo from './image/curebay.footer logo.svg';
import iig from './image/footer iig.svg';
import ffb from './image/footer fb.svg';
import twt from './image/footer twt.svg';
import lndn from './image/footer lndn.svg';
import whatsAppLogo from './image/whatsapp.svg';
import playstore from './image/play store.svg'
import appstore from './image/app store.svg'
import { APP_ROUTES } from '../../constants/approutes';
import WhatsAppLink from '../webflow/whatsapplink'

import footerlogoimg from "../../assets/images/footerlogo.png"
// import { APP_ROUTES } from "../application/Router/constants/AppRoutes";
// import { USERPROFILE_ROUTES } from "../application/Router/constants/UserProfileRoutes";
export default function FooterMobileVersion() {

    let location = useLocation();



    // useEffect(() => {
    //   getLocation();
    //   dispatch(getCartDetails(UserData.code));
    // }, [])
    const redirectToPlayStore = () => {
        window.open("https://play.google.com/store/apps/details?id=com.curebay.totalcare", "_blank");
    };

    const redirectToAppStore = () => {
        window.open("https://apps.apple.com/in/app/curebay-medicare/id6459267188", "_blank");
    };
    return (
        <section class="footer-cta" >


            <footer class="footer-wrapper">
                <div class="container-default w-container">
                    <div class="footer-top">
                        <div class="menu-main-wrapper">

                            <div class="menu-wrapper _01">
                                <a href={APP_ROUTES.DASHBOARD} className="footer-logo-wrapper w-inline-block">
                                    <img src={footerlogoimg} alt="Totalcare Logo" />
                                </a>

                                <div className="menu-wrapper">
                                    <h4 className="text-300 bold footer-title ">About Us</h4>
                                    <ul className="nav-menu-list-wrapper">
                                        <li><a href={APP_ROUTES.OURTEANNEW} className="nav-link white">Our Team</a></li>
                                        <li><a href={APP_ROUTES.DOCTORNEW} className="nav-link white">Our Doctors</a></li>
                                        <li><a href={APP_ROUTES.NEWMEDIA} className="nav-link white">Blogs & Press</a></li>
                                        <li><a href={APP_ROUTES.CUREBAYEXPERIENCENEW} className="nav-link white">CureBay Experience</a></li>
                                        <li><a href={APP_ROUTES.SERVICEPARTNERNEW} className="nav-link white">Service Partners</a></li>
                                        <li><a href={APP_ROUTES.REVIEWPAGENEW} className="nav-link white">Reviews</a></li>
                                        <li><a href={APP_ROUTES.PRIVACYPOLICY} className="nav-link white">Privacy Policy</a></li>
                                    </ul>
                                </div>


                                <div className="menu-wrapper">
                                    <h4 className="text-300 bold footer-title mt-[30px]">Services</h4>
                                    <ul className="nav-menu-list-wrapper">

                                        <li><a href={APP_ROUTES.ECLINICS} className="nav-link white">eClinics</a></li>
                                        <li><a href="https://www.curebay.com/medicine" target="_blank" className="nav-link white">Order Medicine</a></li>
                                        <li><a href="https://www.curebay.com/labtests" target="_blank" className="nav-link white">Book a Lab Test</a></li>
                                        <li><a href="https://www.curebay.com/doctors" target="_blank" className="nav-link white">Find Doctor </a></li>
                                        <li><a href="https://www.curebay.com/hospital" target="_blank" className="nav-link white">Book a Hospital Bed </a></li>

                                    </ul>
                                </div>


                                <div className="menu-wrapper">
                                    <h4 className="text-300 bold footer-title mt-[30px]">Care Plans</h4>
                                    <ul className="nav-menu-list-wrapper">
                                        <li><a href={APP_ROUTES.GENERAL_HEALTH} className="nav-link white">General Care</a></li>
                                        <li><a href={APP_ROUTES.ORTHOPEDICS_CARE} className="nav-link white">Ortho Care</a></li>
                                        <li><a href={APP_ROUTES.WOMENSHEALTH_CARE} className="nav-link white">Women's health</a></li>
                                        <li><a href={APP_ROUTES.CANCERSCREENING_CARE} className="nav-link white">Cardiac Care</a></li>
                                        <li><a href={APP_ROUTES.DIABETICS_CARE} className="nav-link white">Diabetes Care</a></li>

                                    </ul>
                                </div>


                                <div className="mt-4 max-w-md mx-auto mb-4">
                                    <div className="flex">
                                        <i class="fa fa-envelope text-white pt-1" aria-hidden="true"></i>
                                        <p><a className="pl-3 text-white link2" href="mailto:totalcare@curebay.com">totalcare@curebay.com</a></p>
                                    </div>
                                    <div className="flex mt-3">
                                        <i class="fa fa-phone-square text-white pt-1" aria-hidden="true"></i>
                                        {/* <p className="pl-3 w-full break-keep text-white"> +91-8335 000 999 </p> */}
                                        <a href="tel:+918335000999" className="pl-4 w-full break-keep text-white link2">
                                            +91-8335 000 999
                                        </a>
                                    </div>

                                    <div className="flex mt-3">
                                        <i className="fa fa-whatsapp text-white pt-1" aria-hidden="true"></i>
                                        {/* <p className="pl-4 w-full break-keep text-white"> +91-8334 000 999 </p> */}
                                        <WhatsAppLink phoneNumber="918334000999">+91-8334 000 999</WhatsAppLink>
                                    </div>



                                </div>







                                <p class="color-neutral-300 mg-bottom-24px">
                                    CureBay Technologies Pvt Ltd<br />
                                    Fourth Floor, OCAC Tower, Acharya Vihar, Bhubaneswar, Odisha - 751013
                                </p>

                                <div class="social-media-flex-container gap-column-16px">

                                    <a href="https://facebook.com/CureBay-106822021819382/"
                                        target="_blank" class="social-icon-single white w-inline-block">
                                        <div class="social-icon-font font-size-20px-mbl"></div>
                                    </a>
                                    <a href="https://twitter.com/cure_bay?s=11"
                                        target="_blank" class="social-icon-single white w-inline-block">
                                        <div class="social-icon-font font-size-20px-mbl"></div>
                                    </a>
                                    <a href="https://instagram.com/cure_bay?utm_medium=copy_link"
                                        target="_blank" class="social-icon-single white w-inline-block">
                                        <div class="social-icon-font font-size-20px-mbl"></div>
                                    </a>
                                    <a href="https://www.linkedin.com/company/80637697/admin/"
                                        target="_blank" class="social-icon-single white w-inline-block">
                                        <div class="social-icon-font font-size-20px-mbl"></div>
                                    </a>


                                </div>
                                <div class="divider white-10 footer"></div>


                                <div class="flex flex-row justify-evenly" >
                                    <img
                                        className='cursor-pointer w-[100px]'
                                        src={playstore}
                                        alt="Play Store"
                                        onClick={redirectToPlayStore}
                                    />
                                    <img
                                        className='cursor-pointer w-[100px]'
                                        src={appstore}
                                        alt="App Store"
                                        onClick={redirectToAppStore}
                                    />
                                </div>
                            </div>







                        </div>
                    </div>
                    <div class="footer-bottom">
                        <p class="color-neutral-300 mg-bottom-0">
                            Copyright © CureBay Totalcare
                        </p>
                    </div>
                </div>
            </footer>
        </section>
    );
}
