import React from 'react'

import footerlogoimg from "../../assets/images/footerlogo.png"
import { APP_ROUTES } from '../../constants/approutes';
import playstore from "../../webflowimages/play-store.d559e139c90be1ea1ade3ccdd21f26ee.svg";
import appstore from "../../webflowimages/app-store.c8032fb40c95347c535dc2e01fd9de80.svg";







const redirectToPlayStore = () => {
    window.open(
        "https://play.google.com/store/apps/details?id=com.curebay.totalcare",
        "_blank"
    );
};

const redirectToAppStore = () => {
    window.open(
        "https://apps.apple.com/in/app/curebay-medicare/id6459267188",
        "_blank"
    );
};




const landingpagefooter = () => {
    return (
        <section class="footer-cta" >


            <footer class="footer-wrapper " style={{ borderRadius: "78px 78px 0px 0px" }}>
                <div class="container-default w-container">
                    <div class="footer-top">
                        <div class="">

                            <div class="sm:flex sm:justify-between">
                                <div>
                                    <a href={APP_ROUTES.DASHBOARD} className="footer-logo-wrapper w-inline-block">
                                        <img src={footerlogoimg} alt="Totalcare Logo" />
                                    </a>
                                    <p class="color-neutral-300 mg-bottom-24px">
                                        CureBay Technologies Pvt Ltd<br />
                                        Fourth Floor, OCAC Tower, Acharya Vihar,<br /> Bhubaneswar, Odisha - 751013
                                    </p>
                                </div>




                                <div class="">
                                    <p class="color-neutral-300 sm:mg-bottom-24px mg-bottom-18px sm;mt-0 ">Follow Us On:</p>
                                    <div className='social-media-flex-container gap-column-16px'>
                                        <a href="https://facebook.com/CureBay-106822021819382/"
                                            target="_blank" class="social-icon-single white w-inline-block">
                                            <div class="social-icon-font font-size-20px-mbl"></div>
                                        </a>
                                        <a href="https://twitter.com/cure_bay?s=11"
                                            target="_blank" class="social-icon-single white w-inline-block">
                                            <div class="social-icon-font font-size-20px-mbl"></div>
                                        </a>
                                        <a href="https://instagram.com/cure_bay?utm_medium=copy_link"
                                            target="_blank" class="social-icon-single white w-inline-block">
                                            <div class="social-icon-font font-size-20px-mbl"></div>
                                        </a>
                                        <a href="https://www.linkedin.com/company/80637697/admin/"
                                            target="_blank" class="social-icon-single white w-inline-block">
                                            <div class="social-icon-font font-size-20px-mbl"></div>
                                        </a>
                                    </div>



                                </div>
                                <div class=" ">
                                    <p class="color-neutral-300 sm:mg-bottom-24px mg-bottom-18px sm:mt-0 mt-9">Available On:</p>
                                    <img
                                        className='cursor-pointer'
                                        src={playstore}
                                        alt="Play Store"
                                        onClick={redirectToPlayStore}
                                    />
                                    <img
                                        className='cursor-pointer'
                                        src={appstore}
                                        alt="App Store"
                                        onClick={redirectToAppStore}
                                    />
                                </div>




                            </div>


                            {/* 
                            <div class="menu-wrapper">
                                <h4 class="text-300 bold footer-title">About Us</h4>
                                <ul class="nav-menu-list-wrapper">
                                    <li><a href={APP_ROUTES.OURTEANNEW} className="nav-link white">Our Team</a></li>
                                    <li><a href={APP_ROUTES.DOCTORNEW} className="nav-link white">Our Doctors</a></li>
                                    <li><a href={APP_ROUTES.NEWMEDIA} className="nav-link white">Blogs & Press</a></li>
                                    <li><a href={APP_ROUTES.CUREBAYEXPERIENCENEW} className="nav-link white">CureBay Experience</a></li>
                                    <li><a href={APP_ROUTES.SERVICEPARTNERNEW} className="nav-link white">Service Partners</a></li>
                                    <li><a href={APP_ROUTES.REVIEWPAGENEW} className="nav-link white">Reviews</a></li>
                                    <li><a href={APP_ROUTES.PRIVACYPOLICY} className="nav-link white">Privacy Policy</a></li>
                                </ul>
                            </div> */}

                            {/* 
                            <div class="menu-wrapper">
                                <h4 class="text-300 bold footer-title">Services</h4>
                                <ul class="nav-menu-list-wrapper">
                                    <li><a href={APP_ROUTES.ECLINICS} className="nav-link white">eClinics</a></li>
                                    <li><a href="https://www.curebay.com/medicine" target="_blank" className="nav-link white">Order Medicine</a></li>
                                    <li><a href="https://www.curebay.com/labtests" target="_blank" className="nav-link white">Book a Lab Test</a></li>
                                    <li><a href="https://www.curebay.com/doctors" target="_blank" className="nav-link white">Find Doctor </a></li>
                                    <li><a href="https://www.curebay.com/hospital" target="_blank" className="nav-link white">Book a Hospital Bed </a></li>
                                </ul>
                            </div> */}


                            {/* <div class="menu-wrapper">
                                <h4 class="text-300 bold footer-title">Care Plans</h4>
                                <ul class="nav-menu-list-wrapper">
                                    <li><a href={APP_ROUTES.GENERAL_HEALTH} className="nav-link white">General Care</a></li>
                                    <li><a href={APP_ROUTES.ORTHOPEDICS_CARE} className="nav-link white">Ortho Care</a></li>
                                    <li><a href={APP_ROUTES.WOMENSHEALTH_CARE} className="nav-link white">Women's health</a></li>
                                    <li><a href={APP_ROUTES.CANCERSCREENING_CARE} className="nav-link white">Cardiac Care</a></li>
                                    <li><a href={APP_ROUTES.DIABETICS_CARE} className="nav-link white">Diabetes Care</a></li>

                                </ul>
                            </div> */}
                        </div>

                    </div>
                    {/* <div class="footer-bottom">
                        <p class="color-neutral-300 mg-bottom-0">
                            Copyright © CureBay Medicare
                        </p>
                    </div> */}
                </div>
            </footer>
        </section>
    );
};
export default landingpagefooter;