import React from "react";

import { Line,Bar } from "react-chartjs-2";
import Chart from 'chart.js/auto';
import moment from "moment";
function ChartLine(props) {
  console.log(props.data,"dataaa")
  const colors = [
    "rgb(54, 162, 235, 0.2)",
    "rgb(75, 192, 192, 0.2)",
    "rgb(255, 99, 132, 0.2)",
];
const border = [
    "rgb(54, 162, 235, 1)",
    "rgb(75, 192, 192, 1)",
    "rgb(255, 99, 132, 1)",
];
const data = {
  labels: props.givendate,
  //labels: moment(props.givendate, "yyyy-MM-DD HH:mm:ss").format("MMM Do YY"),
  
  datasets: [
    {
      data: props.data,
      backgroundColor: colors[colors.length],
      borderColor: border[border.length],
      fill: true,
      borderWidth: 2,
      borderRadius: 5,
      lineTension: 0.5,
    },
    {
      data:props.pulseRT ? props.pulseRT : [] ,
     backgroundColor: colors[colors.length],
      borderColor: border[border.length],
      fill: true,
      borderWidth: 2,
      borderRadius: 5,
      lineTension: 0.5,
    },
  ]
  
};




console.log(props.data,"asdkjjkfg")
const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: false,
      min: 0,
      max: 200,
      stepSize: 10,
    },
    x: {
      grid: {
        color: "rgba(0, 0, 0, 0)",
      }
    },
  }
};


  // console.log(props.data.length,"uguijk")
  

  return (
    <div className="App">
      {props.data?.length == 1 ?
        <Bar
          data={data}
          options={options}
        />
        : <Line
          data={data}
          options={options}
        />
      }

    </div>
  );
}
export default ChartLine