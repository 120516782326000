import AuthService from "../services/authsevices";
import { AUTH } from '../constants/authconstants';
import { secureStorage } from "../reducer/authreducer";

export const getToken = (data) => async (dispatch) => {
    request();

    const res = await AuthService.callToken();
    try {
        success(res);
        secureStorage.setItem('token', res.data?.access_token)
        secureStorage.setItem('refreshToken', res.data?.refresh_token)
        return Promise.resolve(res.data);
    } catch (err) {
        failure(err)
        return Promise.reject(err);
    }

    function request() {
        dispatch({ type: AUTH.REQUEST_PATIENT });
    };

    function success(res) {
        dispatch({ type: AUTH.SETOKEN, payload: res.data });
    };
    function failure(err) {
        dispatch({
            type: AUTH.FAILURE_PATIENT,
            payload: err
        });
    };
};

export const updateUserData = (newUserData) => {
    return {
        type: AUTH.USERDATA,
        payload: newUserData
    };
}

export const checkIsLoggedIn = () => {
    // Retrieve the loginObj string value from localStorage
    const loginObjStr = localStorage.getItem('loginObj');

    // Parse the loginObj string value as a JSON array
    const loginObj = JSON.parse(loginObjStr);

    // Access the first element of the loginObj array (which contains the user object)
    const userObj = loginObj?.[0];

    const userCode = userObj?.userCode ? userObj.userCode : userObj?.parentCode ? userObj.parentCode : null;
    return userCode;
}
