
import services from './index.jsx';
class Footerlinks {
    getWhyCurebay() {
        return services.get("/why-curebays?populate=deep");
    }


    getOurteam() {
        return services.get("/our-teams?populate=deep");
    }
    getMedia() {
        return services.get("/article-blogs-presses?populate=deep");
    }
};

export default new Footerlinks();