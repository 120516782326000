import services from "../../store/services/index";
import httpcommon from "../../store/services/httpcommon"
import axios from "axios";
class myvitalsservice {
  getmyvitalapi(payload) {
    return httpcommon.post(
      "/dashboard/getVitals", payload
    );
  };
}
export default new myvitalsservice();