export const APP_ROUTES = {
   
    DASHBOARD: '/',
    SIDEBAR: '/sidebar',
    ABOUTUS: '/aboutus',
    DOCTORS: '/doctors',
    HOWITWORKS: '/howitworks',
    LOCATIONSPAGE: '/locations',
    REVIEWS: '/reviews',
    OURGALLERY: '/aboutus/ourgallery',
    DOCTOR_SEARCH: '/doctorsearch',
    CARE_PLANS: '/careplans',
    GENERAL_HEALTH: '/generalHealth',
    DIABETICS_CARE: '/diabeticsCare',
    WOMENSHEALTH_CARE: '/womensHealthCare',
    HEARTHEALTH_CARE: '/heartHealthCare',
    CANCERSCREENING_CARE: '/CardiacCare',
    ORTHOPEDICS_CARE: '/orthopedicsCare',
    ALL_DOCTORS: '/seeAll',
    DOC: '/doctors/:userId',
    LOGIN: '/login',
    SIGNUP: '/signUp',
    FORGOTPSWD: '/forgotPswd',
    ADDMEMBER: '/addMember',
    REVIEW: '/review',
    BUYCAREPLAN: '/buyCarePlan',
    MAINDASHBOARD: '/dashboard',
    MYDETAILS: '/mydetails',
    MYHCARDS :'/myHealthCards',
    MYPLAN: '/myplan',
    MYREVIEW: '/myreview',
    HEALTHRECORDS: '/healthRecords',
    MYPRESCRIPTION: '/myprescription',
    MYLABREPORTS: '/mylabreports',
    MYVITALS: '/myvitals',
    MEMBERDETAILS: '/memberdetails',
    SUBSCRIPTIONSERVICES: '/subscriptionservices',
    APPOINTMENTS: '/appointments',
    CART: '/cart',
    FAQ: '/faq',
    PASSCHANGE: '/passchange',
    ALLPARTNERS: '/seeAllPartners',
    EPRECRIPTION: '/eprescription',
    MYHEALTHRECORDS: '/myhealthrecords',
    OURTEAM: '/ourteam',
    WHYCUREBAY: '/whycurebay',
    MEDIA: '/media',
    CONSENT: '/consent',
    RFC:'/requestforchange',
    MYSCHEDULE:'/myschedule',
    AllPlans:'/allplans',

    // new page
    CUREBAYEXPERIENCENEW: '/curebayexperiencenew',
    SERVICEPARTNERNEW: '/servicepartnernew',
    REVIEWPAGENEW: '/reviewpagenew',
    NEWMEDIA:'/newmedia',
    NEWAPPOINMENT:'/appoinment',
    ECLINICS:'/eclinicnearme',
    CAREPLANNEW:'/careplannew',
    OURTEANNEW:'/ournewteam',
    DOCTORNEW: '/doctornew',
    DOCTORSPECIALITY:'/doctorspeciality',
    PRIVACYPOLICY: '/privacypolicy',
    TNC: 'tearmsandcondition',
    CANCEL: 'cancellation',
    LANDINGPAGE: 'careplan',
    LANDINGPAGE2: 'careplantwo',
    LANDINGPAGEFOOTER:'landingpagefooter',
    THANKYOU : "/thankyoupage",
    CHART:"/chartlinetitan",
    

  


}