import { Icon, IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
// import { getFAQData } from '../../store/actions/homepage';
import { useLocation } from 'react-router-dom';
import { APP_ROUTES } from '../../constants/approutes';
import Footer from '../homepage/footer';
import services from '../../store/services';
import MobileFooter from './../homepage/mobileFooter';

const Accordion = ({ title, content }) => {

    const [isActive, setIsActive] = useState(false);
return (
            <div className="border-2 rounded-10px  w-full m-auto mt-5 bg-white ">
                <div className="flex cursor-pointer justify-between items-center p-3 md:p-4" onClick={() => setIsActive(!isActive)}>
                    <h2 className={`md:w-11/12  md:text-[22px] text-sm font-poppins  text-chathams-blue-500  font-semibold `}>{title}</h2>
                    <div className="flex justify-end text-3xl font-semibold  text-brand-primary md:w-96"><IconButton size='medium' sx={{ backgroundColor: '#E4FAFF' }}>{isActive ? <Icon fontSize='inherit' color='#262626'>keyboard_arrow_up</Icon> : <Icon fontSize='inherit' color='#262626'>keyboard_arrow_down</Icon>}</IconButton></div>
                </div>
                {isActive && <div className="md:w-11/12  px-4 md:text-[22px] text-16 font-custom font-normal text-black pb-15">{content}</div>}
            </div>
        );
    };



const FAQ = () => {
    const [data, setData] = useState([]);
    const getAllData = async () => {
        const res = await services.get("homepage-news?populate=deep");
        setData(res?.data?.data[0].attributes)
    }

    const [screen, setscreen] = useState(window.innerWidth);


    useEffect(() => {
        const updateWindowDimensions = () => {
            const newWidth = window.innerWidth;
            setscreen(newWidth);
        };
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions);
    }, []);

    useEffect(() => { getAllData() }, [])

    const location = useLocation()
    return (
        <div className='container  mx-auto bg-faq px-5 pb-6'>
            <div className='flex justify-center items-center flex-col  '>
                {/* <div className='flex justify-center bg-tutu text-mediumredviolet text-14px w-20 h-8 items-center'>FAQ</div> */}
                <div className='md:flex md:text-[44px] text-xl font-normal font-poppins pt-10 pb-2 '>
                    Frequently &nbsp;
                    <span className='md:text-[46px] text-xl font-medium text-newgreen'>Asked Questions</span>
                </div>
                <div className='content w-full   '>
                    <ul className="m-auto rounded-lg md:my-2 md:mx-6 md:mt-8  "
                    //  style={{ boxShadow: "rgba(226, 226, 226, 0.5) 12px 20px 23px" }}
                    >
                        {data?.seventh_comp?.faq
                            ?.map(({ ques, ans }, i) => (
                                <li key={i}>
                                    <Accordion title={ques} content={ans} />
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
            {screen > 500 ? location.pathname === APP_ROUTES.FAQ ?
                <Footer i={1} /> : <></> : <MobileFooter />}

        </div>
    )
}

export default FAQ;
