import React, { useEffect, useState } from "react";
import DashboardSidemenu from "./dashboard-sidemenu";
import { ArrowForwardIos } from "@mui/icons-material";
import { Card, Chip, Paper } from "@mui/material";
import Footer from "../../components/webflow/postloginfooter";
import { fetchPlans } from "../../store/actions/myprofile";
import dashboardservice from "../../store/services/dashboardservice";
import moment from "moment/moment";
import { fetchDashboardData } from "../../store/actions/dashboard";
import userImage from "../../assets/images/userImage.svg";
import MobileFooter from "./../homepage/mobileFooter";
import Responsivemenu from "./responsive-menu";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import { useNavigate, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import Image from "../../assets/images/subscription.png";
import { fetchCarePlan, fetchUserList } from "../../store/actions/dashboard";
import hearthealth from "../../assets/images/Heart Health.svg";
import diabatic from "../../assets/images/Diabetic Care.svg";
import general from "../../assets/images/General Health.svg";
import Womens from "../../assets/images/Womens' Health.svg";
import ortho from "../../assets/images/Ortho Care.svg";
import Img from "../../assets/images/cart.png";
import subscription1 from "../../assets/images/subscription1.png"
import subscription2 from "../../assets/images/subscription2.png"
import subscription3 from "../../assets/images/subscription3.png"
import subscription4 from "../../assets/images/subscription4.png"




import {
  Box,
  Button,
  MenuItem,
  Select,
  Tab,
  TablePagination,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const Subscriptionservices = () => {
  const navigate = useNavigate();
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
      ? userObj.parentCode
      : null;
  const [myPlan, setMyPlan] = useState();
  const [plan, setPlan] = useState();
  const [screen, setscreen] = useState(window.innerWidth);
  const curebayExpContent = useSelector(

    (state) => state.HomePageReducer.curebayExpContent

  );
  const [value, setValue] = React.useState("1");
  const [users, setUsers] = React.useState();
  const [care, setCare] = useState();
  const [age, setAge] = React.useState(0);
  const [age1, setAge1] = React.useState(1);
  const handleChange1 = (event, newValue) => {
    setValue(newValue);

  };

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  const handleChange2 = (event) => {
    setAge1(event.target.value);
    positionset(event.target.value);
  };
  const getFirstPatient = (e) => {
    if (userObj?.userCode) {
      setAge(e[0].code);
    } else {
      setAge(userCode);
    }

  };

  useEffect(() => {

    async function fetchUserDetails() {
      const user = await fetchUserList({
        code: userObj?.userCode
          ? userObj.userCode
          : userObj?.parentCode
            ? userObj.parentCode
            : null,
        email: userObj?.email,
        mobile: userObj?.mobile,

      });

      if (user.status === 200 && user?.data) {
        const temp = user?.data?.succesObject?.filter(

          (p) => Object.keys(p).length !== 0

        );

        let first = temp;

        getFirstPatient(first);

        setUsers(temp);

      }

    }

    fetchUserDetails();

  }, []);
  useEffect(() => {

    async function fetchCares() {

      const res = await fetchCarePlan({

        userCode: userObj?.userCode

          ? userObj.userCode

          : userObj?.parentCode

            ? userObj.parentCode

            : null,

        patientcode: age,

      });
      if (res?.status === 200 && res?.data) {
        setCare(res?.data);
        setAge1(res?.data?.succesObject[0]?.id);
        positionset(res?.data?.succesObject[0]?.id);
      }
    }
    fetchCares();

  }, [age]);



  const [position, setPosition] = React.useState();

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    async function fetchMyPlanDetails() {
      const myPlan = await fetchPlans({
        userCode: userCode,
        // "patientCode": userObj.code
      });
      if (myPlan.status === 200 && myPlan?.data) {
        setMyPlan(myPlan?.data);
      }
    }
    fetchMyPlanDetails();
    getSubscriptionList();

    async function fetchSubscription() {
      const res = await fetchDashboardData();
      if (res.status === 200 && res?.data?.data[0]) {
        setPlan(res?.data?.data[0]);
      }
    }
    fetchSubscription();
  }, []);
  console.log(plan);

  const [mysub, setMySub] = useState();
  const getSubscriptionList = () => {
    let payload = {
      userCode: userCode,
    };
    dashboardservice.getSubscription(payload).then((res) => {
      setMySub(res.data.succesObject);
    });
  };
  console.log(mysub, "subscriptionHeaderVoList");

  const breadcrumbItems = [
    { label: "Home", onClick: () => navigate(APP_ROUTES.DASHBOARD) },
    { label: "Dashboard", onClick: () => navigate(APP_ROUTES.MAINDASHBOARD) },
    { label: "Subscription Services" },
  ];
  console.log(myPlan?.succesObject?.patientVOList, "list details");


  const positionset = (planid) => {
    if (planid == 6) {
      setPosition(4)
    }
    else if (planid == 3) {
      setPosition(2)
    }
    else if (planid == 5) {
      setPosition(3)
    }
    else if (planid == 1) {
      setPosition(0)
    }
    else if (planid == 4) {
      setPosition(1)
    }
  }


  return (
    <>
      <div className="md:p-8 p-4 md:w-[80.7rem] w-94">
        <div className="flex items-center md:mb-5 mb-2 mt-[100px]">
          {breadcrumbItems.map((item, index) => (
            <React.Fragment key={index}>
              <span className="md:text-base text-xs font-normal leading-7 text-chathams-blue-500 cursor-pointer">
                {item.onClick ? (
                  <a onClick={item.onClick}>{item.label}</a>
                ) : (
                  <a>{item.label}</a>
                )}
              </span>
              {index !== breadcrumbItems.length - 1 && (
                <ArrowForwardIos
                  sx={{ fontSize: 15 }}
                  className="text-chathams-blue-500 mx-2"
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="flex scroll-px-2 ">
          <div className="w-[300px] hidden md:block h-max mr-6  border items-start justify-center rounded-md ">
            <DashboardSidemenu />
          </div>

          <div className="w-full ">

            <div className="flex flex-col sm:flex-row justify-start  w-full sm:pb-5">
              <div className="flex flex-col mr-4">
                <span className="text-chathams-blue text-[15px]">
                  Member Profile
                </span>

                <Select
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "2 rem",
                    width: "13rem",
                    width: "100%",
                    boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                  }}>

                  {users &&
                    users?.map((value, index) => (
                      <MenuItem value={value.code} key={index}>
                        <span className="text-[15px] ">{value.name}</span>
                      </MenuItem>
                    ))}
                </Select>
              </div>
              {!(value === "3") && (
                <div className="flex flex-col mt-2 sm:mt-0  ">
                  <span className="text-chathams-blue text-[15px]">
                    Careplans
                  </span>
                  <Select
                    value={age1}
                    onChange={handleChange2}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ height: "2 rem", width: "13rem" }}
                  >
                    {care &&
                      care?.succesObject?.map((value, index) => (
                        <MenuItem value={value.id} key={index}>
                          <span className="text-[15px]">
                            {value.name}
                          </span>
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              )}
            </div>







            {position == null ?
              <div className=" rounded-md  bg-white  mx-auto flex flex-col items-center justify-center">
                <img className="w-[19rem]" src={Img} alt="" />
                <div className=" text-gray-800  text-[20px] font-semibold text-center">
                  Currently you do not have an active subscription. Please consider purchasing one.
                </div>
                {/* <div className="textgray-3 text-base  text-center">{message.message2}</div> */}
                <button
                  onClick={() => {
                    navigate(APP_ROUTES.BUYCAREPLAN);
                  }}
                  className={`mt-3 mb-6 py-2 px-4 text-white disabled:cursor-default rounded transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none font-poppins`}
                  style={{ background: "#66B889" }}
                >
                  {" "}
                  Continue Shopping
                </button>
              </div>
              :
              <>
                <span className="text-chathams-blue font-semibold md:text-lg text-16 mt-[25px]">
                  Subscription service details:
                </span>
                <div className="border mt-[30px] rounded-lg">
                  <h2 className="bg-[#e6f4fd] font-semibold text-xl py-[20px] flex mb-0"><img src={Image} alt="Cardiac Care" className="w-[40px] sm:ml-[30px] sm:mr-[30px] ml-[16px] mr-[16px]"
                  /> <span className="mt-[4px] text-lg ">Subscription History</span></h2>
                  <h2 className="font-semibold text-base  pl-[32px]"></h2>
                  <div className="overflow-x-auto">
                    <table className=" w-full subscriptiontable">
                      <thead className="bg-[#ecf0ff]  text-[#0B4881]">
                        <tr>
                          <th>Name</th>
                          <th>Date</th>
                          <th>Plan</th>
                          <th>Amount</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mysub?.map((value) =>
                          value?.subscriptionHeaderVoList?.map((value1) => (
                            <tr>
                              <td className="whitespace-nowrap px-2 font-medium sm:text-[15px] text-[#0B4881] "> {value.parentName}</td>
                              <td className="whitespace-nowrap px-2 font-medium sm:text-[15px] text-[#0B4881] " >{moment(value1.date).format("DD-MM-YYYY")}</td>
                              <td className="whitespace-nowrap px-2 font-medium sm:text-[15px] text-[#0B4881] ">{value1.planName}</td>
                              <td className="whitespace-nowrap px-2 font-medium sm:text-[15px] text-[#0B4881] ">{value1.totalAmount}</td>
                              <td className="whitespace-nowrap px-2 font-medium sm:text-[15px] text-[#0B4881] " ><span className="font-semibold bg-green-200 text-green-500 py-[8px] px-[12px] rounded-md"> {value1.status}</span></td>
                            </tr>
                          )))}
                      </tbody>


                    </table>
                  </div>
                </div>
                <div className="w-full border rounded-md mt-[50px] ">

                  <div className="flex px-[20px] py-[10px] bg-[#e6f4fd] border-b-0 h-20 items-center w-full sm:justify-between">
                    <div className="flex items-center">


                      <>
                        {curebayExpContent?.data[0]?.attributes?.FourthComp
                          ?.careplans[position]?.name === "General health" ? (
                          <img src={general} alt="Cardiac Care" className="sm:w-[40px] sm:ml-[30px] sm:mr-[30px] w-[33px] ml-[0px] mr-[10px] bg-white " style={{ padding: "8px 9px 8px 8px", borderRadius: "17px 17px 17px 17px" }} />
                        ) : curebayExpContent?.data[0]?.attributes?.FourthComp
                          ?.careplans[position]?.name === "Diabetes Care" ? (
                          <img src={diabatic} alt="Cardiac Care" className="sm:w-[40px] sm:ml-[30px] sm:mr-[30px] w-[33px] ml-[0px] mr-[10px] bg-white " style={{ padding: "8px 9px 8px 8px", borderRadius: "17px 17px 17px 17px" }} />
                        ) : curebayExpContent?.data[0]?.attributes?.FourthComp
                          ?.careplans[position]?.name === "Cardiac Care" ? (
                          <img src={hearthealth} alt="Cardiac Care" className="sm:w-[40px] sm:ml-[30px] sm:mr-[30px] w-[33px] ml-[0px] mr-[10px] bg-white " style={{ padding: "8px 9px 8px 8px", borderRadius: "17px 17px 17px 17px" }} />
                        ) : curebayExpContent?.data[0]?.attributes?.FourthComp
                          ?.careplans[position]?.name === "Ortho care" ? (
                          <img src={ortho} alt="Cardiac Care" className="sm:w-[40px] sm:ml-[30px] sm:mr-[30px] w-[33px] ml-[0px] mr-[10px] bg-white " style={{ padding: "8px 9px 8px 8px", borderRadius: "17px 17px 17px 17px" }} />
                        ) : curebayExpContent?.data[0]?.attributes?.FourthComp
                          ?.careplans[position]?.name === "Womens' Health" ? (
                          <img src={Womens} alt="Cardiac Care" className="sm:w-[40px] sm:ml-[30px] sm:mr-[30px] w-[33px] ml-[0px] mr-[10px] bg-white " style={{ padding: "8px 9px 8px 8px", borderRadius: "17px 17px 17px 17px" }} />
                        ) : (
                          <></>
                        )}
                      </>



                      <div>
                        <h2 className=" sm:text-[20px] text-[15px]  text-black font-semibold">
                          {
                            curebayExpContent?.data[0]?.attributes?.FourthComp
                              ?.careplans[position]?.name
                          }
                          &nbsp;
                          (Services Included)


                        </h2>
                        <span className="text-black text-[16px]  font-medium  block sm:hidden">
                          {curebayExpContent?.data[0]?.attributes?.FourthComp?.careplans[
                            position
                          ]?.price?.substring(0, 3)}
                          <span className="text-black text-[16px]  font-medium">
                            {curebayExpContent?.data[0]?.attributes?.FourthComp?.careplans[
                              position
                            ]?.price?.substring(
                              3,
                              curebayExpContent?.data[0]?.attributes?.FourthComp
                                ?.careplans[position]?.price?.length
                            )}
                          </span>
                        </span>
                      </div>


                    </div>

                    <div className=" text-right  sm:flex hidden">
                      <span className="text-black text-[20px] font-bold float-right">
                        {curebayExpContent?.data[0]?.attributes?.FourthComp?.careplans[
                          position
                        ]?.price?.substring(0, 3)}
                        <span className="text-black text-[20px]  font-bold">
                          {curebayExpContent?.data[0]?.attributes?.FourthComp?.careplans[
                            position
                          ]?.price?.substring(
                            3,
                            curebayExpContent?.data[0]?.attributes?.FourthComp
                              ?.careplans[position]?.price?.length
                          )}
                        </span>
                      </span>
                    </div>
                  </div>

                  {/* <div className="flex px-[20px] py-[10px] border-b-0 mt-5 items-center gap-7 bg-[#ecf0ff]">
                <img src={Image} alt="Cardiac Care" className="ml-9" />
                <h2 className=" text-[17px]  text-black font-poppins font-semibold pr-[700px]">
                  Services Included :
                </h2>
              </div> */}


                  <div className="md:grid sm:grid-cols-3 grid-cols-1  p-4 space-y-4 sm:space-y-0 mt-0 sm:mt-2">
                    {curebayExpContent?.data[0]?.attributes?.FourthComp?.careplans[position]?.points?.map((value) => (
                      <div className="flex items-start justify-start gap-4 sm:gap-0 pr-2">
                        <img className="sm:mt-4 sm:h-auto h-4" src={curebayExpContent?.data[0]?.attributes?.FourthComp
                          ?.careplans[position]?.tick_url}></img>
                        <h4 className="font-medium sm:text-[15px] text-sm sm:m-4">
                          {value?.points_}
                        </h4>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            }

            {/*  */}

            {/* <div className="md:flex w-full md:mt-8 mt-4">
              <div className="md:w-1/2 w-full md:mr-8 mr-4 ">
                <div
                  variant="outlined"
                  className="h-80 w-full overflow-y-scroll border rounded-md"
                  elevation={1}
                  style={{
                    boxShadow: "0px 10px 13px rgba(0, 0, 0, 0.04)",
                    paddingX: "2%",
                  }}
                >
                  <div className="">
                    <div className="md:mx-3 mx-1 md:my-4 my-1 flex justify-between items-center border-b-2">
                      <p className="text-brand-lightBlack md:text-base text-sm font-semibold">
                        Your Family package details
                      </p>
                    </div>

                    <div className=" md:mx-3 overflow-x-auto">
                      {myPlan?.succesObject?.patientVOList.map((value) => (
                        <div className="border rounded-md mb-4 px-4 py-2">
                          <div className="flex flex-row md:mr-12 mr-6 w-full">
                           <div className=" justify-center w-2/5">
                              <img
                                src={
                                  value?.patientPhotoName
                                    ? value.patientPhotoName
                                    : userImage
                                }
                                alt="userImage"
                                className=" md:w-24 md:h-24 h-20 rounded-full"
                              />
                              <p className="md:mb-5 mb-2 ml-5">
                                {value.relation}
                              </p>
                            </div>
                            <div className=" justify-center w-3/5 pt-3">
                              <p className="md:text-base text-sm text-brand-lightBlack font-bold mb-2">
                                {value.relation} Package Name
                              </p>

                              {value?.carePlanList.map((res) => (
                                <>
                                  <li className="md:text-base text-sm text-brand-lightBlack ">
                                    {res.planName}
                                  </li>
                                </>
                              ))}
                            </div>

                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="md:w-1/2 w-full mt-20 md:mt-0 h-80">
                <Card
                  variant="outlined"
                  elevation={1}
                  sx={{
                    boxShadow: " 0px 10px 13px rgba(0, 0, 0, 0.04)",
                    width: "100%",
                    height: "100%",
                    paddingX: "2%",
                    borderRadius: "5px",
                    overflowY: "scroll",
                  }}
                >
                  <div className="md:mx-2 mx-0 md:px-2  px-0 md:my-4 my-2 md:h-10 h-5">
                    <p className="text-brand-lightBlack md:text-base text-sm font-semibold">
                      Subscription History
                    </p>
                  </div>
                  <div className="w-full">
                    <table className="w-full ">
                      <thead className="sticky top-0 bg-royal-blue-200">
                        <tr>
                          <th className="w-1/4 px-1 py-2">Name</th>
                          <th className="w-1/4 px-1 py-2">Date</th>
                          <th className="w-1/4 px-1 py-2">Plan</th>
                          <th className="w-1/4 px-1 py-2">Amount</th>
                          <th className="w-1/4 px-1 py-2">Status</th>
                        </tr>
                      </thead>
                      <tbody className="bg-white text-center md:text-base text-sm ">
                        {mysub?.map((value) =>
                          value?.subscriptionHeaderVoList?.map((value1) => (
                            <tr>
                              <td className="w-1/4 px-1 py-2">
                                {value.parentName}
                              </td>
                              <td className="w-1/3 px-1 py-2">
                                {moment(value1.date).format("DD-MM-YYYY")}
                              </td>
                              <td className="w-1/4 px-1 py-2">
                                {value1.planName}
                              </td>
                              <td className="w-1/4 px-1 py-2">
                                {value1.totalAmount}
                              </td>
                              <td className="w-1/4 pr-1 pl-9 py-2">
                                <p className="w-16 h-8 font-semibold flex justify-center items-center bg-green-200 text-green-500">
                                  {value1.status}
                                </p>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </Card>
              </div>
            </div> */}
            <div className="md:mt-10 mt-[30px]">
              <span className="font-bold md:text-xl text-16">
                {plan?.attributes?.Subs_Service[0]?.Heading}
              </span>
              <img
                className="w-full md:pb-28 pb-14 hidden sm:block"
                src={plan?.attributes?.Subs_Service[0]?.image_url}
              />

              <div className="grid grid-cols-1 sm:hidden ">
                <div>
                  <img className="w-full " src={subscription1} />
                </div>
                <div>
                  <img className="w-full" src={subscription2} />
                </div>
                <div>
                  <img className="w-full" src={subscription3} />
                </div>
                <div>
                  <img className="w-full" src={subscription4} />
                </div>

              </div>
            </div>


          </div>
        </div>
      </div>
      {screen > 500 ? (
        <Footer i={1} />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </>
  );
};

export default Subscriptionservices;
