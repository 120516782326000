import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate } from "react-router-dom";
import Footer from "./footer";
import { getPlans } from "../../store/actions/allfiveplans";
import MobileFooter from "./../homepage/mobileFooter";

const AllPlans = () => {
  const navigate = useNavigate();
  const [reviewsData, setReviewsData] = useState([]);
  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  useEffect(() => {
    async function fetchData() {
      const reviewResponse = await getPlans();
      if (
        reviewResponse.status === 200 &&
        Array.isArray(reviewResponse?.data?.data)
      ) {
        setReviewsData(reviewResponse.data.data[0]);
        // nloop = (reviewResponse?.data?.data[0]?.reviewsData?.attributes?.ThirdComponent?.video_content?.length)
      }
    }
    fetchData();
  }, []);
  console.log(reviewsData);

  const onReadMoreClick = (id) => {
    console.log(id);
    switch (id) {
      case 1:
        return navigate(APP_ROUTES.GENERAL_HEALTH);
      case 2:
        return navigate(APP_ROUTES.HEARTHEALTH_CARE);
      case 3:
        return navigate(APP_ROUTES.DIABETICS_CARE);
      case 4:
        return navigate(APP_ROUTES.ORTHOPEDICS_CARE);
      case 5:
        return navigate(APP_ROUTES.WOMENSHEALTH_CARE);
    }
  };
  return (
    <>
      <div className="select-none xs-overflow-x-hidden  md:absolute md:top-10 w-full md:w-[87.5rem]">
        <div className="block md:hidden">
          <div
            className="bg-position-right "
            style={{
              display: "flex",
              justifyContent: "flex-start",
              backgroundImage: `url("${reviewsData?.attributes?.frst_comp?.image_url}")`,
              // position: 'sticky',
              backgroundSize: "cover",
              width: "100%",
              height: "200px",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
        </div>
        <div
          className="mainDiv bg-white flex justify-start  xs-bg-white"
          style={{
            backgroundImage: `url("${reviewsData?.attributes?.frst_comp?.image_url}")`,

            backgroundImage: `url("${reviewsData?.attributes?.frst_comp?.image_url}")`,

            // display: 'flex',
            // justifyContent: 'left',
            // alignItems: 'center',
            // paddingLeft: '20px',
            // maxWidth: '100%',
            // height: '100%'
          }}
        >
          <div
            id="subDiv_01 flex items-center h-full"
            style={{
              paddingLeft: "20px",
              // maxWidth: '100%',
            }}
          >
            <div className="container mx-auto md:px-3 px-2">
              {/* <div className=" w-200 h-8 bg-shakespeare bg-opacity-[8%] m-1 py-2 px-2 rounded-md">
                                <p className='text-14px md:text-sm  text-center md:font-normal text-chathams-blue-500 whitespace-nowrap	'>{data && data.FirstComponent?.name || ''}</p>
                            </div> */}
              <div className="  mt-10 md:mt-36  bg-transparent">
                {/* <p className='md:text-40px md:font-bold leading-54px text-chathams-blue-500'>{data && data.FirstComponent?.title || ''}</p> */}
                <p className="md:text-40px text-xl font-semibold md:leading-54px  md:w-[47.666%] text-newblue font-poppins">
                  {reviewsData?.attributes?.frst_comp?.name?.substring(0, 31)}
                </p>
                <h1 className="font-light md:text-40px text-xl md:leading-54px font-poppins text-newblue md:w-6/12 pb-5 ">
                  {" "}
                  {reviewsData?.attributes?.frst_comp?.name?.substring(
                    31,
                    reviewsData?.attributes?.frst_comp?.name?.length
                  )}
                </h1>
                <h1 className="font-normal text-16px  leading-[30.5px] font-poppins md:w-5/12 md:mb-24">
                  {reviewsData?.attributes?.frst_comp?.description}
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="md:px-4 px-2 md:py-8 py-4">
          <div className="md:flex flex-col md:px-8 px-4 ">
            <h1 className="md:text-[40px] text-xl font-poppins font-[300]  md:h-12 ">
              {reviewsData?.attributes?.secnd_comp?.heading?.substring(0, 23)}
            </h1>
            <div className="md:flex">
              <h1 className="md:text-[50px] text-xl font-medium  font-poppins text-newgreen md:w-[36rem] ">
                {reviewsData?.attributes?.secnd_comp?.heading?.substring(
                  23,
                  reviewsData?.attributes?.secnd_comp?.heading?.length
                )}
              </h1>
              <div className="w-full flex flex-col justify-center align-middle">
                <h1 className="border-b-2 w-full border-newgreen"></h1>
                <h1></h1>
              </div>
            </div>
            <h1 className="md:text-lg md:font-medium  md:text-[20px] font-poppins md:my-8 my-4  ">
              {reviewsData?.attributes?.secnd_comp?.sub_heading}
            </h1>
          </div>
          <div className="md:flex md:flex-col divide-y-2 px-2 md:px-0 md:mx-8 mx-0 ">
            {reviewsData?.attributes?.secnd_comp?.content?.map(
              (value, index) => (
                <div className="md:flex md:gap-4 md:py-10" key={index}>
                  <div className="w-1/4">
                    <img
                      className="rounded-[10px] h-[200px]"
                      src={value?.image_url}
                    ></img>
                  </div>
                  <div className="w-9/12">
                    <h1 className="font-poppins md:text-[26px] text-xl font-medium md:my-4 my-2 px-2">
                      {value?.name}
                    </h1>
                    <h2 className="font-poppins text-[16px] font-normal md:mb-6 mb-2 px-2">
                      {value?.heading}
                    </h2>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        onReadMoreClick(value?.id);
                      }}
                      sx={{ textTransform: "capitalize", marginY: "1rem" }}
                    >
                      Read More
                    </Button>
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        {screen > 500 ? <Footer i={1} /> : <MobileFooter />}
        {/* <Footer i={1} /> */}
      </div>
    </>
  );
};

export default AllPlans;
