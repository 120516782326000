import { Icon } from "@mui/material";

export const SubTitle = (props) => {
  const { topheading, title, desc } = props;
  return (
    <div className="container mx-auto bg-transparent flex flex-col justify-center items-center">
      <p className="bg-sky bg-opacity-[8%] text-chathams-blue-500 py-2 px-2 text-center">
        {topheading}
      </p>
      <p className="text-4xl mt-5 font-bold text-chathams-blue-500 text-center">
        {title}
      </p>
      <p className="text-lg mt-4 font-semibold text-chathams-blue-500 text-center">
        {desc}
      </p>
    </div>
  );
};

export const RightComponent = (props) => {
  const { image, content, li_detail_steps } = props;
  return (
    <div className="flex flex-row py-2 px-2 my-2 mx-2 items-center">
      <div className="w-1/2">
        <img className="w-full h-[354px]" src={image} alt="service Images" />
      </div>

      <div className="w-1/2">
        <p className="text-chathams-blue-500 font-semibold text-3xl">
          {content}
        </p>
        {li_detail_steps?.length > 0 &&
          li_detail_steps?.map((element, index) => (
            <div className="flex flex-row my-2" key={index}>
              <img src={element?.icon} alt="service Images" />
              <p className="pl-2 text-base leading-27px text-chathams-blue font-semibold">
                {element?.detail}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export const LeftComponent = (props) => {
  const { image, content, li_detail_steps } = props;
  return (
    <div className="flex flex-row py-2 px-2 my-2 mx-2 items-center">
      <div className="w-1/2">
        <p className="text-chathams-blue-500 font-semibold text-3xl">
          {content}
        </p>
        {li_detail_steps?.length > 0 &&
          li_detail_steps?.map((element, index) => {
            return (
              <div className="inline-flex my-2" key={index}>
                <img src={element?.icon} alt="service Images" />
                <p className="pl-2  text-base leading-27px text-chathams-blue font-semibold">
                  {element?.detail}
                </p>
              </div>
            );
          })}
      </div>
      <div className="w-1/2">
        <img className=" h-[354px] w-full" src={image} alt="service Images" />
      </div>
    </div>
  );
};

export const CarePlanTable = (e) => {
  const compareHealth = e?.data;

  return (
    <table className="main-table table-fixed w-5/6 mx-24 my-8">
      <thead>
        <tr className="border-b border-slate-300">
          <th className="border-r border-slate-300 w-[427px] text-base font-semibold text-chathams-blue">
            Services
          </th>
          <th className="border-r border-slate-300 w-[156px] text-[14px] font-bold text-tundora">
            Monthly
          </th>
          <th className="border-r border-slate-300 w-[156px] text-[14px] font-bold text-tundora">
            Quaterly
          </th>
          <th className="border-r border-slate-300 w-[156px] text-[14px] font-bold text-tundora">
            Half Yearly
          </th>
          <th className="border-r border-slate-300 w-[156px] text-[14px] font-bold text-tundora">
            Yearly
          </th>
          <th className="border-slate-300  w-[156px] text-[14px] font-bold text-tundora">
            On Demand
          </th>
        </tr>
      </thead>
      {compareHealth?.careplanFeturesVos?.length > 0 &&
        compareHealth?.careplanFeturesVos.map((element, index) => (
          <tbody key={index}>
            <tr className="border-b border-slate-300">
              <td className="border border-slate-300  ">
                <div>
                  <p className="mx-2 my-2 font-bold text-sm  text-tundora">
                    {element?.feature}
                  </p>
                  <p className="mx-2 my-2 text-[12px] font-normal text-chathams-blue-500">
                    <span className="pr-2 font-semibold text-[12px] text-chathams-blue"></span>
                    {element?.descriptions}
                  </p>
                </div>
              </td>
              <td align="center" className="border border-slate-300">
                {element?.monthlys === "Y" ? (
                  <Icon sx={{ color: "#008A39", fontSize: 24 }}>
                    check_circle
                  </Icon>
                ) : (
                  <Icon sx={{ color: "#C5C5C5", fontSize: 24 }}>cancel</Icon>
                )}
              </td>
              <td align="center" className="border border-slate-300 ">
                {element?.quarterlys === "Y" ? (
                  <Icon sx={{ color: "#008A39", fontSize: 24 }}>
                    check_circle
                  </Icon>
                ) : (
                  <Icon sx={{ color: "#C5C5C5", fontSize: 24 }}>cancel</Icon>
                )}
              </td>
              <td align="center" className="border border-slate-300 ">
                {element?.halfYearlys === "Y" ? (
                  <Icon sx={{ color: "#008A39", fontSize: 24 }}>
                    check_circle
                  </Icon>
                ) : (
                  <Icon sx={{ color: "#C5C5C5", fontSize: 24 }}>cancel</Icon>
                )}
              </td>
              <td align="center" className="border border-slate-300 ">
                {element?.yearlys === "Y" ? (
                  <Icon sx={{ color: "#008A39", fontSize: 24 }}>
                    check_circle
                  </Icon>
                ) : (
                  <Icon sx={{ color: "#C5C5C5", fontSize: 24 }}>cancel</Icon>
                )}
              </td>
              <td align="center" className="border border-slate-300 ">
                {element?.onDemands === "Y" ? (
                  <Icon sx={{ color: "#008A39", fontSize: 24 }}>
                    check_circle
                  </Icon>
                ) : (
                  <Icon sx={{ color: "#C5C5C5", fontSize: 24 }}>cancel</Icon>
                )}
              </td>

              {/* <td align='center' className="border border-slate-300">{element.monthlys == 'Y' ? <Icon sx={{ color: '#008A39', fontSize: 24 }} >check_circle</Icon> : <Icon sx={{ color: '#C5C5C5', fontSize: 24 }} >cancel</Icon>}</td>
                        <td align='center' className="border border-slate-300 ">{element.quarterlys== 'Y' ? <Icon sx={{ color: '#008A39', fontSize: 24 }} >check_circle</Icon> : <Icon sx={{ color: '#C5C5C5', fontSize: 24 }} >cancel</Icon>}</td>
                        <td align='center' className="border border-slate-300 ">{element.halfYearlys == 'Y' ? <Icon sx={{ color: '#008A39', fontSize: 24 }} >check_circle</Icon> : <Icon sx={{ color: '#C5C5C5', fontSize: 24 }} >cancel</Icon>}</td>
                        <td align='center' className="border border-slate-300 ">{element?.frequency !== '1' && element?.frequency !== '3' && element?.frequency !== '12' ? <Icon sx={{ color: '#008A39', fontSize: 24 }} >check_circle</Icon> : <Icon sx={{ color: '#C5C5C5', fontSize: 24 }} >cancel</Icon>}</td> */}
            </tr>
          </tbody>
        ))}
    </table>
  );
};

// export const UserData = () => {
//     const userData = useSelector((state) => state.AuthReducer.userData);

//     if (!userData.length) {
//         return {
//             userCode: null,
//             patientCode: null,
//         };
//     }

//     const data = JSON.parse(userData);

//     return {
//         userCode: data?.[0]?.userCode || null,
//         patientCode: data?.[0]?.patientCode || null,
//     };
// }

export const CalculateAge = (dateOfBirth) => {
  if (dateOfBirth) {
    const currentDate = new Date();
    let birthDate;

    if (typeof dateOfBirth === "string") {
      // Check if the dateOfBirth string is in "dd/MM/yyyy" format
      const dateParts = dateOfBirth.split("/");
      if (dateParts.length === 3) {
        const [day, month, year] = dateParts.map(Number);
        if (!isNaN(day) && !isNaN(month) && !isNaN(year)) {
          birthDate = new Date(year, month - 1, day);
        }
      }
    } else if (dateOfBirth instanceof Date) {
      // Check if dateOfBirth is a valid Date object
      birthDate = dateOfBirth;
    }

    if (!birthDate || isNaN(birthDate.getTime())) {
      return "Invalid date. Please provide a valid date in dd/MM/yyyy format or as a Date object.";
    }

    const age = currentDate.getFullYear() - birthDate.getFullYear();

    if (
      currentDate.getMonth() < birthDate.getMonth() ||
      (currentDate.getMonth() === birthDate.getMonth() &&
        currentDate.getDate() < birthDate.getDate())
    ) {
      return age - 1;
    }

    return age;
  }
};

export const formatDateToDDMMYYYY = (date) => {
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
};

export const isMobileValid = (mobile) => {
  if (mobile?.trim() === "") {
    return true;
  }
  const repeatingDigitsPattern = /^(.)\1+$/;
  if (repeatingDigitsPattern.test(mobile)) {
    return false; // Invalid mobile number with repeating identical digits
  }

  const allowedPattern = /^[+]?\d{0,3}?\d{10}$/;
  return allowedPattern.test(mobile);
};

// export const isAdharValid = (aadharId) => {
//   if (aadharId?.trim() === "") {
//     return true;
//   }

//   const repeatingDigitsPattern = /^(.)\1+$/;
//   if (repeatingDigitsPattern.test(aadharId)) {
//     return true; // Invalid Aadhar ID with repeating identical digits
//   }

//   //const allowedPattern = /^\d{4}$/; // Only four digits allowed
//   // const allowedPattern = /^(\d)\1{3}$|^\d{4}$/;
//   const allowedPattern = /^(\d)\1{0,3}$|^\d{4}$/;
//   return allowedPattern.test(aadharId);
// };

// export const isAdharValid = (aadharId) => {
//   if (aadharId?.trim() === "") {
//     return false; // Aadhar ID should not be considered valid if it's empty
//   }

//   const allowedPattern = /^(\d)\1{0,3}$|^\d{4}$/;
//   return allowedPattern.test(aadharId);
// };

export const isAdharValid = (aadharId) => {
  if (aadharId?.trim() === "") {
    return false; // Aadhar ID should not be considered valid if it's empty
  }

  const fourDigitsPattern = /^\d{4}$/;
  return fourDigitsPattern.test(aadharId);
};

export const shouldShowErrorAdhar = (aadharId) => {
  return aadharId?.trim() !== "" && !isAdharValid(aadharId);
};

export const shouldShowErrorAdharedit = (aadharId) => {
  return aadharId?.trim() !== "" && !isAdharValid(aadharId);
};
export const isAdharValidedit = (aadharId) => {
  if (typeof aadharId !== "string" || aadharId.trim() === "") {
    return true;
  }

  const repeatingDigitsPattern = /^(.)\1+$/;
  if (repeatingDigitsPattern.test(aadharId)) {
    return false; // Invalid Aadhar ID with repeating identical digits
  }

  const allowedPattern = /^\d{4}$/; // Only four digits allowed
  return allowedPattern.test(aadharId);
};

export const isNameValid = (firstName) => {
  if (firstName?.trim() === "") {
    return true;
  }
  const allowedPattern = /^[A-Za-z\s]+$/;
  return allowedPattern.test(firstName);
};

export const isNameValid2 = (lastName) => {
  if (lastName?.trim() === "") {
    return true;
  }
  const allowedPattern = /^[A-Za-z\s]+$/;
  return allowedPattern.test(lastName);
};

export const shouldShowError = (mobile) => {
  return mobile?.trim() !== "" && !isMobileValid(mobile);
};
export const shouldShowErrorName = (firstName) => {
  return firstName?.trim() !== "" && !isNameValid(firstName);
};
export const shouldShowErrorName2 = (lastName) => {
  return lastName?.trim() !== "" && !isNameValid2(lastName);
};
