import React, { useEffect, useState } from "react";
import { Card, CardContent, Paper, containerClasses } from "@mui/material";
import {
  fetchAboutUsData,
  fetchOurAssistedData,
  fetchOurGallery,
  getAboutUsDetails,
} from "../../store/actions/aboutus";
import Button from "@mui/material/Button";
import checkIcon from "../../images/icons/checkIcon.svg";
import arrow2 from "./image/doubarrow.svg";
import vectoricon4 from "./image/vectoricon4.svg";
import Footer from "../homepage/footer";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import { useDispatch, useSelector } from "react-redux";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";

const Aboutus = () => {
  const [ourAssisted, setOurAssisted] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [aboutUsDetails, setAboutUsDetails] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  useEffect(() => {
    async function fetchOurAssistedDetails() {
      const ourAssisted = await fetchOurAssistedData();
      if (ourAssisted.status === 200 && ourAssisted?.data?.data[0]) {
        setOurAssisted(ourAssisted?.data?.data?.[0]);
      }
    }
    async function fetchOurgallery() {
      let res = await fetchOurGallery();
      if (res.status === 200 && res.data.data[0].attributes) {
        setGalleryImages(res.data.data[0].attributes);
      }
    }
    fetchOurgallery();
    fetchOurAssistedDetails();
  }, []);

  useEffect(() => {
    dispatch(getAboutUsDetails());
  }, [dispatch]);

  const aboutUsContent = useSelector(
    (state) => state.HomePageReducer.aboutUsContent
  );
  useEffect(() => {
    if (aboutUsContent) {
      let data = aboutUsContent?.data?.[0]?.attributes
        ? aboutUsContent.data[0].attributes
        : [];
      setAboutUsDetails(data);
    }
  }, [aboutUsContent]);

  console.log(aboutUsDetails);

  // const [aboutUsdata, setAboutUsData] = useState()

  // useEffect(() => {
  //   async function fetchData() {
  //     const res = fetchAboutUsData();
  //     console.log(res)
  //     if (res?.status == 200 && res?.data) {
  //       setAboutUsData(res?.data)
  //     }
  //   }
  //   fetchData()
  // }, [])

  const test21 = ["#4FAFD9", "#60AE81", "#265C89"];
  function chunkArray(array, size) {
    let result = [];
    let arrayCopy = [...array];
    while (arrayCopy.length > 0) {
      result.push(arrayCopy.splice(0, size));
    }
    return result;
  }
  function CompHealthcare(props) {
    let content = chunkArray(props?.content, 3);
    let content2 = [];
    for (let i = 0; i < content?.length; i++) {
      content[i].map((e, index) => {
        content2.push(
          <div
            key={index}
            // style={{ height: '360px', width: '424px', borderRadius: '4px', margin: '7px', backgroundColor:test21[index] }}
          >
            <Card
              className="sm:h-[365px] h-auto"
              sx={{
                Width: "400px",
                borderRadius: "4px",
                boxShadow: "0px 20px 29px rgba(0, 0, 0, 0.04)",
                borderTop: `4px solid ${test21[index]}`,
              }}
            >
              <CardContent>
                <div className="hidden sm:block">
                  <span className=" inline-block py-1 px-1 pr-2 ">
                    <img
                      src={e?.image_url}
                      draggable={false}
                      className="w-12 h-12"
                    ></img>
                  </span>
                  <h2 className="text-[20px] font-semibold font-poppins">
                    {e?.name}
                  </h2>
                </div>
                <div className=" sm:hidden flex ">
                  <div className=" flex  py-1 px-1 pr-2 -ml-2">
                    <img
                      src={e?.image_url}
                      draggable={false}
                      className="w-8 h-8"
                    ></img>
                  </div>
                  <div className="flex">
                    <p className="sm:text-[20px] text-[18px] font-semibold font-poppins mt-2">
                      {e?.name}
                    </p>
                  </div>
                </div>
                {e?.sub_content?.length &&
                  e?.sub_content.map((e, index) => (
                    <div className="flex pt-3" key={index}>
                      <img src={e?.label1} draggable={false}></img>
                      <h3 className="text-[16px] font-normal font-poppins pl-5">
                        {e?.label}
                      </h3>
                    </div>
                  ))}
              </CardContent>
            </Card>
          </div>
        );
      });
    }
    return content2;
  }

  return (
    <div className="select-none xs-overflow-x-hidden  md:absolute md:top-0 w-full md:w-[87.5rem]">
      <div className="sm:pt-10" style={{ backgroundColor: "#f1f3f5" }}>
        <div
          className="mainDiv h-28 sm:h-auto "
          style={{
            display: "flex",
            justifyContent: "flex-start",
            backgroundImage: `url("${aboutUsDetails?.frstComp?.image_url}")`,
            backgroundSize: "cover",
          }}
        >
          <div
            className="sm:pl-14 pl-0"
            id="subDiv_01"
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "center",
              // paddingLeft: '60px',
              // maxWidth: '100%',
              height: "100%",
            }}
          >
            <div className="sm:mt-36 mt-96 bg-transparent px-3">
              <h1 className="text-[22px] sm:text-40px font-semibold sm:leading-54px  sm:w-4/12 text-newblue font-poppins sm:mt-0 ">
                {aboutUsDetails?.frstComp?.title?.substring(0, 12)}
              </h1>
              <h2 className="font-light  text-[18px] sm:text-40px sm:leading-54px font-poppins text-newblue sm:w-9/12 sm:pb-7  pb-4">
                {" "}
                {aboutUsDetails?.frstComp?.title?.substring(
                  12,
                  aboutUsDetails?.frstComp?.title?.length
                )}
              </h2>
              <p className="font-normal text-[16px]  sm:w-5/12 sm:leading-[30.5px] font-poppins sm:mb-10">
                {aboutUsDetails?.frstComp?.description}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="max-h-max max-w-full justify-center sm:mt-2 mt-60 p-2 mx-4 my-8 py-8">
        <h2 className="sm:text-[40px] text-xl font-poppins font-[300] sm:h-12">
          {" "}
          {aboutUsDetails?.second_comp?.title?.substring(0, 23)}
        </h2>
        <div className="md:flex w-full">
          <h3 className="sm:text-[53px] text-2xl leading-3rem font-medium text-newgreen w-[90rem]">
            {aboutUsDetails?.second_comp?.title?.substring(
              23,
              aboutUsDetails?.second_comp?.title?.length
            )}
          </h3>
          <div className="md:w-4/5 md:flex flex-col justify-center align-middle">
            <p className="border-b-2 w-full border-newgreen"></p>
            <p></p>
          </div>
        </div>

        <div className="flex flex-col sm:flex-row mt-4 px-5 justify-center">
          {aboutUsDetails?.second_comp?.content?.map((item, index) => (
            <React.Fragment key={index}>
              <div className="w-full sm:w-4/12 flex-col flex items-center space-y-4 text-center pt-4">
                <img
                  src={item?.image_url}
                  className="h-16 sm:h-auto "
                  draggable={false}
                  alt={item?.name}
                />
                <h2
                  className="text-[18px] font-poppins font-semibold text-[#0B4881]"
                  style={{ color: "rgba(38, 92, 137, 1)" }}
                >
                  {item.name}
                </h2>
                <h3 className="text-[16px] font-poppins font-normal">
                  {item?.description}
                </h3>
              </div>
              {index < aboutUsDetails?.second_comp?.content?.length - 1 && (
                <div className="flex items-center justify-center mt-4">
                  <img src={arrow2} alt="#" className="rotate-90 sm:rotate-0" />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>

      <div className="sm;px-8 sm:mt-24  bg-[#F0FBFFBA]">
        <div className="flex flex-col px-8 sm:pt-16 pt-4 ">
          <p className="sm:text-[40px] text-xl font-poppins font-[300] sm:h-12">
            {" "}
            {aboutUsDetails?.third_compo?.title?.substring(0, 8)}
          </p>
          <div className="md:flex w-full">
            <p className="sm:text-[53px] text-[18px] leading-3rem font-medium text-newgreen w-[50rem]">
              {aboutUsDetails?.third_compo?.title?.substring(
                8,
                aboutUsDetails?.third_compo?.title?.length
              )}
            </p>
            <div className="md:w-4/5 md:flex flex-col justify-center align-middle">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
        </div>

        <div className="flex flex-col md:flex-row justify-between items-center sm:py-24 py-2 sm:px-8 sm:gap-24 gap-3">
          <div className="my-10 flex relative">
            <div className="relative bg-[#0272DA2B] rotate-12 rounded-md sm:h-[340px] h-[240px] sm:w-[400px] w-[260px]"></div>
            <img
              className="absolute sm:left-0 h-[248px]sm:h-[340px] sm:w-[400px] w-[300px] rounded-md "
              src={aboutUsDetails?.third_compo?.image_url}
              alt="img"
            />
          </div>

          <div className="bg-white sm:p-8 p-2">
            <h2 className="text-[#0B4881] font-semibold text-[20px] font-poppins">
              {aboutUsDetails?.third_compo?.content?.heading}
            </h2>
            {aboutUsDetails?.third_compo?.content?.points?.map((value) => (
              <div className="flex justify-center items-start gap-4 mx-4 my-7">
                <img className="mt-1" src={value?.label1} alt="tick" />
                <h3 className=" font-normal text-[16px] font-poppins">
                  {value?.label}
                </h3>
              </div>
            ))}
          </div>
        </div>

        {/* <div className="sm:flex sm:ecliniccontentdiv1 justify-evenly items-center "> */}
      </div>

      {/* <div className="sm:grid grid-cols-3 gap-4 py-14">
        {aboutUsDetails?.LastMileHealthCareContent?.map((value) => (
          // <Paper>
          <div className="  p-8 " >
            <div className={`flex justify-start ${value?.title.length > 30 ? "items-start" : "items-center"} w-full`}>
              <img src={value.image_url} alt="#" className="mr-6" />
              <p className=" text-2xl font-bold text-chathams-blue text-left ">{value?.title}</p>
            </div>
            <div className="flex mt-8   ">
              <ul className="">
                {value?.sub_points?.map((item) =>
                  <li className="flex items-start py-2">
                    <img src={checkIcon} alt="#" className="pt-2 pr-4" />
                    <p className="text-chathams-blue text-lg font-medium">{item?.label}</p>
                  </li>
                )}
              </ul>
            </div>
          </div>
          // </Paper>

        ))}
      </div> */}

      {/* <div className='flex flex-col justify-center items-center '>
        <div className='flex justify-center bg-light-sky text-chathams-blue text-sm w-24 h-8 mb-7 items-center  '>Gallery</div>
        <p className='flex justify-center text-4xl w-4/5 font-bold text-justify pb-10 text-chathams-blue'>View Our Gallery</p>
      </div>
      <div className="grid grid-cols-3  container mx-auto ">
        {galleryImages?.SecondComp?.Image.slice(0, 3).map((e) => (
          <img src={e?.label} className='w-96 h-96'></img>
        ))}

      </div>

      <div className='flex justify-center py-9 '>
        <Button variant="contained" onClick={() => { navigate(APP_ROUTES.OURGALLERY) }} sx={{ textTransform: "capitalize" }}>View Our Gallery</Button>
      </div> */}

      <div className="sm:px-8 ">
        <div className="flex flex-col px-8 pt-16 pb-14 ">
          <h2 className="sm:text-[40px] text-xl font-poppins font-[300] sm:h-12">
            {" "}
            {aboutUsDetails?.fourth_comp?.title?.substring(0, 27)}
          </h2>
          <div className="md:flex w-full">
            <h3 className="sm:text-[53px] text-2xl leading-3rem font-medium text-newgreen w-[50rem]">
              {aboutUsDetails?.fourth_comp?.title?.substring(
                27,
                aboutUsDetails?.fourth_comp?.title?.length
              )}
            </h3>
            <div className="md:w-4/5 md:flex flex-col justify-center align-middle">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
        </div>
        <div>
          <img
            src={aboutUsDetails?.fourth_comp?.image_url}
            alt="#"
            className="w-full px-3 sm:px-0"
          />
        </div>

        {/* <div className=" flex flex-col items-center">
          <button className="consumerbutton cursor-default">Consumer Application</button>
          <p className='text-lg pt-6  font-semibold text-center w-5/12 text-chathams-blue'>We provide a range of other services. Check out our website to know more on  <a href="https://www.curebay.com/" className="hyperlink">
            www.curebay.com
          </a>
          </p>
        </div> */}
      </div>

      <div className="sm:px-8 sm:my-24 my-10">
        <div className="flex flex-col px-8  sm:pb-14 ">
          <h2 className="sm:text-[40px] text-xl font-poppins font-[300] sm:h-12">
            {" "}
            {aboutUsDetails?.fifth_comp?.name?.substring(0, 13)}
          </h2>
          <div className="md:flex w-full">
            <h3 className="sm:text-[53px] text-2xl leading-3rem font-medium text-newgreen w-[50rem]">
              {aboutUsDetails?.fifth_comp?.name?.substring(
                13,
                aboutUsDetails?.fourth_comp?.name?.length
              )}
            </h3>
            <div className="md:w-4/5 md:flex flex-col justify-center align-middle">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
          <p className="font-normal sm:text-lg text-base  sm:w-8/12 sm:leading-[30.5px] font-poppins mb-10 text-[20px] mt-8">
            {aboutUsDetails?.fifth_comp?.description}
          </p>
        </div>
        <div className="flex flex-col px-8  flex-wrap">
          <div className="grid md:grid-cols-3 grid-cols-1 gap-x-10 gap-y-10">
            {aboutUsDetails?.fifth_comp?.content?.length && (
              <CompHealthcare content={aboutUsDetails?.fifth_comp?.content} />
            )}
          </div>
        </div>
      </div>

      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </div>
  );
};

export default Aboutus;
