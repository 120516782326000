import { CAREPLANS } from "../constants/careplansconstants";

const initialState = {
    howItWorksDetails : null
};

const CarePlansReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CAREPLANS.HOW_IT_WORKS:
            return {
                ...state,
                howItWorksDetails: payload,
            };
        default:
            return state;
    }
};

export default CarePlansReducer;