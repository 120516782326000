import React, { useEffect, useState } from "react";
import MyprofileSidemenu from "../homepage/myprofile-sidemenu";
import { fetchPlans } from "../../store/actions/myprofile";

// import { getReviewDetails } from '../../store/actions/reviews';
import { APP_ROUTES } from "../../constants/approutes";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import Myplan from "./myplan";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import EditProfile from "./editprofile";
import userImage from "../../assets/images/userImage.svg";
import FooterMobileVersion from "../../components/homepage/FooterMobileVersion";
import MobileFooter from "./../homepage/mobileFooter";
import Footer from '../../components/webflow/postloginfooter';

function Memberdetails() {
  const navigate = useNavigate();
  const breadcrumbItems = [
    { label: "Home", onClick: () => navigate(APP_ROUTES.DASHBOARD) },
    { label: "My Details", onClick: () => navigate(APP_ROUTES.MYDETAILS) },
    { label: "Member Profile", url: "/details" },
  ];
  const [userCode, setUserCode] = useState(null);
  const [patientCode, setPatientCode] = useState(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editMember, setEditMember] = useState({});
  const [screen, setscreen] = useState(window.innerWidth);

  const userData = useSelector((state) => state.AuthReducer.userData);
  // useEffect(() => {
  //     if (!userData.length) return;

  //     let data = JSON.parse(userData);

  //     if (data?.[0]?.userCode)
  //         setUserCode(data.userCode)
  //     if (data?.[0]?.patientCode) {
  //         setPatientCode(data[0].patientCode)
  //     }
  // }, [userData]);

  const [memberDetails, setMemberDetails] = useState();
  useEffect(() => {
    async function fetchMyPlanDetails() {
      let data = JSON.parse(userData);

      const myPlan = await fetchPlans({
        userCode: data?.[0]?.userCode,
        patientCode: data?.[1]?.patientCode,
      });

      if (myPlan.status === 200 && myPlan?.data?.succesObject) {
        setMemberDetails(myPlan.data.succesObject);
      }
    }

    fetchMyPlanDetails();
  }, [userData]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const handleCloseEdit = async () => {
    setIsEditOpen(false);
    let data = JSON.parse(userData);

    const myPlan = await fetchPlans({
      userCode: data?.[0]?.userCode,
      patientCode: data?.[1]?.patientCode,
    });

    if (myPlan.status === 200 && myPlan?.data?.succesObject) {
      setMemberDetails(myPlan.data.succesObject);
    }
  };

  const handleOpenEdit = (value) => {
    setEditMember({ ...value });
    setIsEditOpen(true);
  };

  return (
    <>
      <div className="p-8 ">
        <div className="flex items-center mb-5 mt-[100px]">
          {breadcrumbItems.map((item, index) => (
            <React.Fragment key={index}>
              <span className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">
                {item.onClick ? (
                  <a onClick={item.onClick}>{item.label}</a>
                ) : (
                  <a>{item.label}</a>
                )}
              </span>
              {index !== breadcrumbItems.length - 1 && (
                <ArrowForwardIosIcon
                  sx={{ fontSize: 15 }}
                  className="text-chathams-blue-500 mx-2"
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <h2 className="text-lg sm:text-xl font-bold my-5 text-chathams-blue-500">
          {/* My Profile - Member Profile */}
        </h2>
        <div className="flex scroll-px-2">
          <div className="w-1/4 mr-4 border p-4 rounded-md hidden sm:block">
            <MyprofileSidemenu />
          </div>
          <div className=" w-full sm:w-3/4 ml-0 sm:ml-4 border p-4 sm:p-8 rounded-md items-center flex flex-col  ">
            <div className="flex flex-col sm:flex-row justify-between w-full mt-2 sm:mt-0">
              <p className="w-full flex items-start font-bold  text-chathams-blue text-center sm:text-left gap-2 text-base sm:text-lg">
                See Profiles
              </p>
              <div className="flex mt-2 sm:mt-0">
                <button
                  className=" w-full  rounded-lg px-1 sm:px-4 py-2 border text-[#396DDD] border-royal-blue-400 text-blue-500 hover:bg-royal-blue-500/80 hover:text-white  duration-300 text-xs sm:text-lg" style={{ border: "1px solid #396DDD" }}
                  onClick={() => {
                    navigate(APP_ROUTES.ADDMEMBER);
                  }}
                >
                  {" "}
                  + Add Member
                </button>
                <button
                  variant="outlined"
                  className="  w-full  ml-5 sm:w-48 rounded-lg px-1 sm:px-4  py-2 border text-[#396DDD] border-royal-blue-400 text-blue-500 hover:bg-royal-blue-500/80 hover:text-white  duration-300 text-xs sm:text-lg " style={{ border: "1px solid #396DDD" }}
                  onClick={() => {
                    navigate(APP_ROUTES.BUYCAREPLAN);
                  }}
                >
                  {" "}
                  Buy Plan
                </button>
              </div>
            </div>

            <div className="flex flex-col justify-between w-full my-4 h-[340px] px-0 sm:px-4  overflow-auto">
              <div className="flex flex-col gap-3  mt-5  w-full ">
                {memberDetails?.patientVOList?.map((value, index) => {
                  return (
                    <Card
                      elevation={0}
                      variant="outlined"
                      sx={{
                        boxShadow: "0px 10px 13px rgba(0, 0, 0, 0.04)",
                        width: "100%",
                        paddingX: "2%",
                      }}
                      key={index}
                    >
                      <div className="flex flex-col w-full p-2 sm:p-0">
                        <div className="flex  border-b-2 pb-3 justify-start pt-0 sm:pt-4  w-full">
                          <div className="flex flex-col w-full">
                            <div className="flex justify-between  border-b-2">
                              <div className="flex gap-6 mb-2">
                                <img
                                  src={
                                    value?.patientPhotoName
                                      ? value.patientPhotoName
                                      : userImage
                                  }
                                  alt="userImage"
                                  className="border md:w-24 md:h-24 h-16 rounded-full"
                                />
                                <div className="mt-3 sm:mt-6">
                                  <p className="text-newgreen font-semibold pb-1 text-sm sm:text-lg font-poppins w-full">
                                    {value.name}
                                  </p>
                                  <p className=" text-sm sm:text-md font-semibold font-poppins text-chathams-blue pb-3">
                                    {value.relation}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <IconButton
                                  aria-label="edit"
                                  onClick={() => handleOpenEdit(value)}
                                  className="text-sm sm:text-xs mt-3 sm:mt-0"
                                >
                                  <EditIcon />
                                </IconButton>
                              </div>
                            </div>
                            <div className="flex justify-between gap-4">
                              <div>
                                <p className=" text-xs sm:text-base font-poppins pt-3 text-chathams-blue">
                                  <span className="  font-semibold font-poppins  text-sm sm:text-lg text-brand-lightBlack">
                                    Gender:{" "}
                                  </span>
                                  {value?.gender}
                                </p>
                                <p className=" text-xs sm:text-base font-medium text-chathams-blue font-poppins">
                                  <span className=" font-semibold font-poppins  text-sm sm:text-lg text-brand-lightBlack">
                                    Relation:{" "}
                                  </span>{" "}
                                  {value?.relation}
                                </p>
                                <p className=" text-xs sm:text-base font-medium font-poppins text-chathams-blue">
                                  <span className=" font-semibold font-poppins  text-sm sm:text-lg text-brand-lightBlack">
                                    Package Name:{" "}
                                  </span>{" "}
                                  {value?.planNameList}
                                </p>
                              </div>
                              <div className="pt-3 mr-4">
                                <p className=" text-xs sm:text-base font-medium font-poppins text-chathams-blue">
                                  <span className=" font-semibold font-poppins  text-sm sm:text-lg text-brand-lightBlack">
                                    Blood Group:{" "}
                                  </span>{" "}
                                  {value?.bloodGroup}
                                </p>
                                <p className=" text-xs sm:text-base font-medium font-poppins text-chathams-blue">
                                  <span className=" font-semibold font-poppins  text-sm sm:text-lg text-brand-lightBlack">
                                    DOB:{" "}
                                  </span>
                                  {value?.dob}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {isEditOpen ? (
          <EditProfile
            props={editMember}
            open={isEditOpen}
            onClose={handleCloseEdit}
          />
        ) : null}

      </div>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )
      }
    </>

  );
}
export default Memberdetails;
