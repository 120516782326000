import React, { useEffect, useState } from "react";
import Footer from "../../components/webflow/footer";
import wholemap from "../../webflowimages/wholemap.png";
import wholemap500 from "../../webflowimages/wholemap500.png";
import wholemap800 from "../../webflowimages/wholemap800.png";
import wholemap1080 from "../../webflowimages/wholemap1080.png";
import wholemap1600 from "../../webflowimages/wholemap1600.png";
import starsvg from "../../webflowimages/starsvg.svg";
import maploactionsvg from "../../webflowimages/maploactionsvg.svg";
import MapmyIndia from "mapmyindia-react";
import {
  fetchCities,
  fetchEclinicsBypincode,
  fetchLocation,
  fetchMapMyIndiaToken,
} from "../../store/actions/location";
import axios from "axios";
import "aos/dist/aos.css";
import Aos from "aos";
import FooterMobileVersion from "../../components/homepage/FooterMobileVersion";
import MobileFooter from "./../homepage/mobileFooter";

const MapWithMarker = ({ cities }) => {
  const [marker, setMarker] = useState([]);

  useEffect(() => {
    if (Array.isArray(cities)) {
      const newMarkers = cities
        .filter((city) => city?.latitude > 0 && city?.longitude > 0)
        .map((city) => ({
          position: [city.latitude, city.longitude],
          draggable: false,
          title: city.name,
        }));
      setMarker(newMarkers);
    }
  }, [cities]);

  return (
    <div style={{ width: "100%", height: "60vh" }}>
      <MapmyIndia key={JSON.stringify(marker)} zoom={8} markers={marker} />
    </div>
  );
};

const MapWithMarkerEclinic = ({ location }) => {
  let marker = [];
  if (Array.isArray(location)) {
    for (let i = 0; i < location?.length; i++) {
      if (location[i].latitude > 0 && location[i].longitude > 0) {
        marker.push({
          position: [location[i]?.latitude, location[i]?.longitude],
          draggable: false,
          title: location[i]?.name,
        });
      }
    }
  } else if (typeof location === "object") {
    marker.push({
      position: [location?.latitude, location?.longitude],
      draggable: false,
      title: location.name,
    });
  }

  return (
    <div style={{ width: "100%", height: "60vh" }}>
      <MapmyIndia
        key={JSON.stringify(marker)}
        zoom={8}
        markers={marker}
        style={{ width: "100%", height: "100%" }}
      />
    </div>
  );
};

const Eclinics = () => {
  const [filteredCity, setFilteredCity] = useState([]);
  const [location, setlocation] = useState();
  const [inputValue, setInputValue] = useState("");
  const [cities, setcities] = useState();
  const [curCity, setcurCity] = useState(null);
  const [selectedEclinic, setSelectedEclinic] = useState(null);
  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    async function fetchLocations() {
      const eClinincsDetails = await fetchLocation();
      if (eClinincsDetails?.status === 200 && eClinincsDetails?.data) {
        setlocation(eClinincsDetails?.data?.succesObject);
      }
    }
    fetchLocations();
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    async function fetchEclinics() {
      if (inputValue.length === 6) {
        const tokenResponse = await fetchMapMyIndiaToken();
        if (
          tokenResponse?.data?.responseCode === "200" &&
          tokenResponse.data?.succesObject
        ) {
          const token = tokenResponse.data.succesObject.access_token;

          try {
            const response = await axios.get(
              `https://patient.curebay.com/dhp/places/geocode?address=${inputValue}&itemCount=1`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            let eLoc = response?.data?.copResults?.eLoc;

            if (eLoc) {
              const latLongResponse = await axios.get(
                `https://patient.curebay.com/dhp/O2O/entity/${eLoc}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              if (latLongResponse) {
                let reqData = {
                  lat: latLongResponse.data?.latitude,
                  longt: latLongResponse.data?.longitude,
                  pincode: inputValue,
                };
                const eClinic = await fetchEclinicsBypincode(reqData);
                if (eClinic?.status === 200 && eClinic?.data) {
                  setlocation(eClinic?.data?.succesObject);
                }
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
      if (inputValue.length === 0) {
        async function fetchLocations() {
          const eClinincsDetails = await fetchLocation();
          if (eClinincsDetails?.status === 200 && eClinincsDetails?.data) {
            setlocation(eClinincsDetails?.data?.succesObject);
          }
        }
        fetchLocations();
      }
    }
    fetchEclinics();
  }, [inputValue]);

  useEffect(() => {
    async function fetchCity() {
      const cities = await fetchCities();
      if (cities?.status === 200 && cities?.data) {
        setcities(cities?.data?.succesObject);
      }
    }
    fetchCity();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const locationResponse = await fetchLocation({ city: curCity });
        if (locationResponse?.status === 200 && locationResponse?.data) {
          const locationData = locationResponse.data.succesObject;
          setFilteredCity(locationData);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    }
    fetchData();
  }, [curCity]);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const maxLength = 6;

    if (inputValue?.length > maxLength) {
      setInputValue(inputValue.slice(0, maxLength));
    } else {
      setInputValue(inputValue);
    }
  };

  const onCityChange = async (e) => {
    setcurCity(e.target.value);
    setSelectedEclinic(null);
  };

  const onEclinicChange = async (e) => {
    const selectedName = e.target.value;
    const selectedEclinic = location.find(
      (value) => value.name === selectedName
    );

    setSelectedEclinic(selectedEclinic);
    setcurCity(null);
  };

  useEffect(() => {
    Aos.init({ duration: 3000 });
  }, []);

  return (
    <>
      <section className="section">
        <div
          className="w-layout-blockcontainer container-default w-container"
          style={{ marginTop: "66px" }}
        >
          <div className="div-block-6">
            <div className="w-layout-grid grid-3-columns navigation z-[2]">
              <div
                id="w-node-_14d6f731-833e-52d0-e501-c26e987d1d06-4d0e8e40"
                className="navigation-box"
              >
                <div
                  data-hover="true"
                  data-delay={0}
                  id="w-node-_14d6f731-833e-52d0-e501-c26e987d1d07-4d0e8e40"
                  data-w-id="14d6f731-833e-52d0-e501-c26e987d1d07"
                  className="navigation-dropdown-wrapper w-dropdown"
                >
                  {inputValue ? (
                    <div className="navigation-dropdown-toggle w-dropdown-toggle ">
                      <div className="flex-horizontal justify-start">
                        <div className="mg-right-8px">
                          <img
                            src={starsvg}
                            alt="Star Speciality Icon - Doctr X Webflow Template"
                          />
                        </div>
                        {
                          <select
                            id="eclinics"
                            value={selectedEclinic?.name || ""}
                            onChange={(e) => onEclinicChange(e)}
                          >
                            <option value="" disabled>
                              eClinics
                            </option>
                            {location?.map((value, index) => (
                              <option key={index} value={value.name}>
                                {value.name}
                              </option>
                            ))}
                          </select>
                        }
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <nav
                    style={{
                      display: "none",
                      WebkitTransform:
                        "translate3d(0, 0, 0) scale3d(0.95, 0.95, 1.001) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 0, 0) scale3d(0.95, 0.95, 1.001) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 0, 0) scale3d(0.95, 0.95, 1.001) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 0, 0) scale3d(0.95, 0.95, 1.001) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transformStyle: "preserve-3d",
                    }}
                    className="card navigation-dropdown-list w-dropdown-list"
                  >
                    <div className="navigation-dropdown-list-wrapper">
                      <div className="w-dyn-list">
                        <div role="list" className="w-dyn-items">
                          <div
                            role="listitem"
                            className="categories-item-wrapper w-dyn-item"
                          >
                            <a
                              href="#"
                              className="text-200 medium category-link"
                            />
                          </div>
                        </div>
                        <div className="empty-state transparent w-dyn-empty">
                          <div>No items found.</div>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
              <div
                id="w-node-_14d6f731-833e-52d0-e501-c26e987d1d19-4d0e8e40"
                className="navigation-box sm:flex"
              >
                <div
                  data-hover="true"
                  data-delay={0}
                  id="w-node-_14d6f731-833e-52d0-e501-c26e987d1d2d-4d0e8e40"
                  data-w-id="14d6f731-833e-52d0-e501-c26e987d1d2d"
                  className="navigation-dropdown-wrapper w-dropdown"
                >
                  {inputValue ? (
                    ""
                  ) : (
                    <div className="navigation-dropdown-toggle w-dropdown-toggle">
                      <div className="flex-horizontal justify-start">
                        <div className="mg-right-8px">
                          <img
                            src={maploactionsvg}
                            alt="Map Location Icon - Doctr X Webflow Template"
                          />
                        </div>
                        <select
                          id="city"
                          value={curCity || ""}
                          onChange={(e) => onCityChange(e)}
                        >
                          <option value="" disabled>
                            Select City
                          </option>
                          {cities?.map((value, index) => (
                            <option key={index} value={value.city}>
                              {value.city}
                            </option>
                          ))}
                        </select>
                      </div>
                      {/* <div className="line-rounded-icon dropdown-arrow"></div> */}
                    </div>
                  )}
                  <nav
                    style={{
                      display: "none",
                      WebkitTransform:
                        "translate3d(0, 0, 0) scale3d(0.95, 0.95, 1.001) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      MozTransform:
                        "translate3d(0, 0, 0) scale3d(0.95, 0.95, 1.001) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      msTransform:
                        "translate3d(0, 0, 0) scale3d(0.95, 0.95, 1.001) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transform:
                        "translate3d(0, 0, 0) scale3d(0.95, 0.95, 1.001) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      transformStyle: "preserve-3d",
                      //   opacity: 0
                    }}
                    className="card navigation-dropdown-list w-dropdown-list"
                  >
                    <div className="navigation-dropdown-list-wrapper">
                      <div className="w-dyn-list">
                        <div role="list" className="w-dyn-items">
                          <div
                            role="listitem"
                            className="categories-item-wrapper w-dyn-item"
                          >
                            <a
                              href="#"
                              className="text-200 medium category-link"
                            />
                          </div>
                        </div>
                        <div className="empty-state transparent w-dyn-empty">
                          <div>No items found.</div>
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
                <h1 className="text-white sm:pl-[170px] pl-[120px] pt-[30px] text-3xl m-0">
                  OR
                </h1>
              </div>

              <input
                id="state"
                type="text"
                pattern="[0-9]{1,6}"
                className="div-block-7"
                onChange={handleInputChange}
                value={inputValue}
                placeholder="Enter Pincode"
              />
            </div>
            <div className="sticky h-full w-full">
              {/* {curCity ? (
                                <MapWithMarker cities={filteredCity} />
                            ) : selectedEclinic ? (
                                <MapWithMarkerEclinic location={selectedEclinic} />
                            ) : (
                                <MapWithMarkerEclinic location={location} />
                            )}
                            {selectedEclinic ? (
                                <MapWithMarkerEclinic location={selectedEclinic} />
                            ) : curCity ? (<MapWithMarker cities={filteredCity} />) :
                                <MapWithMarker cities={filteredCity} />
                            } */}
              {selectedEclinic ? (
                <MapWithMarkerEclinic location={selectedEclinic} />
              ) : curCity ? (
                <MapWithMarker cities={filteredCity} />
              ) : (
                <MapWithMarkerEclinic location={location} /> || (
                  <MapWithMarker cities={filteredCity} />
                )
              )}
            </div>
          </div>
        </div>
        <div className="inner-container _518px center" />
      </section>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </>
  );
};

export default Eclinics;
