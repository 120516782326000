import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getDoctorslist } from '../../store/actions/homepage'
import SchoolIcon from '@mui/icons-material/School';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import StarIcon from '@mui/icons-material/Star';
import Footer from './footer';
import MobileFooter from './../homepage/mobileFooter';
import FooterMobileVersion from './FooterMobileVersion';


const DoctorProfile = () => {
    const { userId } = useParams();

    const [screen, setscreen] = useState(window.innerWidth);

    useEffect(() => {
        const updateWindowDimensions = () => {
            const newWidth = window.innerWidth;
            setscreen(newWidth);
        };
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions);
    }, []);

    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getDoctorslist(coords, "", 1, 100));
    }, [dispatch])

    const coords = { lat: 8.6130176, long: 77.2308992 }
    const [doctorsData, setDoctorsData] = useState(null);
    var eliteDoc = []
    const doctorsList = useSelector(state => state.HomePageReducer.doctorsList);

    useEffect(() => {
        setDoctorsData(doctorsList?.length ? doctorsList : '');
    }, [doctorsList]);



    eliteDoc = doctorsList?.filter((value) => {
        if (value?.userId === userId)
            return value
    })

    console.log(eliteDoc)
    return (
        <>
            <div className='mb-24'>
                <p className='px-8 pt-8 pb-2 text-3xl font-bold text-chathams-blue'>Doctor Details</p>
                {
                    eliteDoc?.map((value) =>
                        <div className='border-2' style={{ width: '95%', margin: '2rem' }}  >
                            <div className='flex flex-col'>
                                <div className='flex mx-8 mt-8 mb-6'>
                                    <img className='w-40 rounded-lg' src={value?.userPhoto ? `${process.env.REACT_APP_IMAGE_URL}${value.userPhoto}` : ''} alt='img'></img>
                                    <div className='m-4'>
                                        <p className="text-xl font-bold  text-start text-chathams-blue-500 ">Dr. {value?.userName}</p>
                                        <p className='text-base font-semibold text-start text-chathams-blue'>{value?.specialities}</p>
                                        <div className='flex pt-5'>
                                            {value?.userQualification !== null ?
                                                <div className='flex'>
                                                    <SchoolIcon />
                                                    <div className='pl-2 flex flex-col'>
                                                        <p>Qualification</p>
                                                        <p className='text-base font-semibold text-start text-seegreen'>{value?.userQualification} </p>
                                                    </div>
                                                </div> :
                                                <></>
                                            }
                                            <div className='flex'>
                                                <StarIcon />
                                                <div className='pl-2 flex flex-col'>
                                                    <p>Years of Experience</p>
                                                    <p className='text-base font-semibold text-start text-seegreen'>{value?.yearOfExperience} years</p>
                                                </div>
                                            </div>
                                            <div className='flex px-10'>
                                                <LocationOnIcon />
                                                <div className='pl-2 flex flex-col'>
                                                    <p>City</p>
                                                    <p className='text-base font-semibold text-start text-seegreen'>{value?.city}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='mx-8 mb-8'>
                                    <p className='text-lg text-chathams-blue font-bold'>
                                        Bio
                                    </p>
                                    <p className='text-chathams-blue '>
                                        {value?.description}
                                    </p>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>

            {screen > 500 ? <Footer i={1} /> :
                <>
                    <FooterMobileVersion />
                    <MobileFooter />
                </>
            }
        </>
    );
};

export default DoctorProfile;
