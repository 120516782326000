import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorslist } from "../../store/actions/homepage";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  SvgIcon,
  TablePagination,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
// import DoctorImage from "./image/DoctorImge.png";
// import DoctorProfile from "./doctorProfile";
import DefaultDoctorImage from "../../assets/images/defaultDoctorImage.png";
// import noDataFound from "../../assets/images/No data-found.svg";
// import videoimg from "./image/videoimg.svg";
// import docytIcon from "../careplans/image/docIcon.svg";
import Footer from '../../components/webflow/footer';
import { APP_ROUTES } from "../../constants/approutes";
import Image from "../../assets/images/image.png";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";


const Doctorspeciality = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [freeTextSearch, setFreeTextSearch] = useState(location?.state?.name);
  const [fromDoc, setFromDoc] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const coords = { latitude: 20.2961, longitude: 85.8245 };
  const [doctorsData, setDoctorsData] = useState(null);
  let eliteDoc = [];
  const doctorsList = useSelector((state) => state.HomePageReducer.doctorsList);
  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    setDoctorsData(doctorsList?.length ? doctorsList : "");
  }, [doctorsList]);

  useEffect(() => {
    if (location?.state?.id === "docsp" && fromDoc) {
      setFreeTextSearch(location?.state?.name);
    } else {
      window.history.replaceState({}, "", APP_ROUTES.ALL_DOCTORS);
    }
  }, [location]);
  console.log(location?.state?.name, "location");

  useEffect(() => {
    if (freeTextSearch?.length > 2 || doctorsData === null) {
      dispatch(getDoctorslist(coords, freeTextSearch, page + 1, rowsPerPage));
    }
  }, [dispatch, freeTextSearch, rowsPerPage, page]);
  console.log(coords, freeTextSearch, page + 1, rowsPerPage, "freetextnew");

  // for (let i = 0, j = 0; i < doctorsData?.length; i++) {
  //     if (doctorsData[i]?.doctorType === 'E')
  //         eliteDoc[j++] = doctorsData[i];
  // }

  const viewprofile = (e, doct) => {
    e.preventDefault();
    navigate(`/doctors/${doct.userId}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setDoctorsData(null);
  };
  const [expand, setExpand] = useState(false);
  const handleExpand = () => {
    setExpand(!expand);
  };
  const Description = ({ value }) => {
    const [expand, setExpand] = useState(false);
    const handleExpand = () => {
      setExpand(!expand);
    };
    return (
      <div>
        <p
          className="font-semibold text-xl my-3 text-seegreen"
          onClick={handleExpand}
        >
          About
        </p>
        {/* {expand ? <p className="font-medium text-lg">{value}</p> : <></>} */}
        <p className="font-medium text-lg">{value}</p>
      </div>
    );
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    setDoctorsData(null);
  };

  const SearchChange = (e) => {
    setFromDoc(false);
    if (e?.target?.value?.length === 0) {
      setDoctorsData(null);
      setFreeTextSearch("");
    } else {
      setFreeTextSearch(e.target.value);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="position-relative">
        <div className="section hero doctor-hero">
          <div className="container-default w-container">
            <div className="">
              <div data-w-id="44863fe2-998c-7388-f408-e708728f0f3a"
                // style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', opacity: 0}}

                className="inner-container _858px center">
                <h1 className="md:text-38px mt-4 md:font-bold leading-54px text-center text-chathams-blue-500 ">
                  All available doctors
                </h1>

              </div>

              <div
                data-w-id="effd4578-3d33-67d7-197c-a2fcd16ed4d8"
                style={{
                  transform:
                    "translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                  opacity: 1,
                  transformStyle: "preserve-3d"
                }}
                className="w-dyn-list"
              >
                <div
                  role="list"
                  className="grid-3-columns card-grid-3-cols w-dyn-items"
                >
                  {doctorsData?.length > 0 &&
                    doctorsData.map((value, index) => (

                      <div role="listitem" className="w-dyn-item">
                        <div className="card">
                          <a
                            data-w-id="effd4578-3d33-67d7-197c-a2fcd16ed4dc"
                            href="/doctors/dr-manas-ranjan-panda"
                            className="display-block w-inline-block"
                          >
                            <div className="card-picture-wrapper">
                              <img
                                src={
                                  value?.userPhoto
                                    ? `${process.env.REACT_APP_IMAGE_URL}${value.userPhoto}`
                                    : DefaultDoctorImage
                                }
                                alt="doctorimga"
                                className="sm:h-[200px] w-[171px] sm:w-[200px] mt-[33px] rounded-2xl"
                              //   style={{
                              //     transform:
                              //       "translate3d(0px, 0px, 0px) scale3d(1, 1, 1.001) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                              //     transformStyle: "preserve-3d"
                              //   }}
                              />
                              <div className="card-details-flex-container doctors-card-tag v2">
                                <div className="badge-secondary small badge-special-left-bottom w-dyn-bind-empty" />
                              </div>
                            </div>
                          </a>
                          <div className="card-description doctors-description">
                            <a
                              data-w-id="effd4578-3d33-67d7-197c-a2fcd16ed4e2"
                              href="/doctors/dr-manas-ranjan-panda"
                              className="text-decoration-none w-inline-block"
                            >
                              <h2
                                className="card-title-dark heading-h4-size mg-bottom-8px"
                                style={{ color: "rgb(19, 24, 47)" }}
                              >
                                Dr. {value?.userName}
                              </h2>
                              <h4
                                className="heading-h5-size mg-bottom-8px text-center"
                              >
                                {value?.specialities}
                              </h4>
                            </a>
                            <p className="color-neutral-600 mg-bottom-24px">
                              {value?.description}
                            </p>
                          </div>
                        </div>
                      </div>


                    ))}



                </div>
              </div>
            </div></div></div></div>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}    </div>
  );
};

export default Doctorspeciality;
