import aboutusservices from "../../services/aboutusservices";
import { HOMEPAGE } from "../../constants/homepageconstants";

export const fetchOurAssistedData = async () => {
    const res = await aboutusservices.getOurAssistedDetails();
    //console.log(res)
    return res;
};
export const fetchOurgallery = async () => {
    const res = await aboutusservices.getPhotos();
    //console.log(res)
    return res;
};
export const fetchOurvideos = async () => {
    const res = await aboutusservices.getVideos();
    return res;
};

export const getAboutUsDetails = () => async (dispatch) => {
    const res = await aboutusservices.fetchAboutUsDetails();
    try {
        dispatch({
            type: HOMEPAGE.SUCCESS_ABOUTUSCONTENT,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: HOMEPAGE.FAILED_ABOUTUSCONTENT,
            payload: err
        });
    }
};

export const fetchOurGallery = async () => {
    const res = await aboutusservices.fetchAboutUsGallery();
    return res;
}
