import React, { useEffect, useState } from "react";
import logoimg from "../../webflowimages/curebay_logo.png"
import DSC0046review from "../../webflowimages/_DSC0046review-2.jpg"
import Aos from "aos";
import 'aos/dist/aos.css';
import {
  getReviewsContent,
  getReviewScreenDetails,
} from "../../store/actions/reviews";
import Footer from '../../components/webflow/footer';
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";




const Reviewpagenew = () => {



  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  const [screen, setscreen] = useState(window.innerWidth);
  const [reviewsData, setReviewsData] = useState([]);
  useEffect(() => {
    async function fetchData() {
      const reviewResponse = await getReviewsContent();
      if (
        reviewResponse.status === 200 &&
        Array.isArray(reviewResponse?.data?.data)
      ) {
        setReviewsData(reviewResponse.data.data[0]);
        // nloop = (reviewResponse?.data?.data[0]?.reviewsData?.attributes?.ThirdComponent?.video_content?.length)
      }
    }
    fetchData();
  }, []);
  console.log(reviewsData, "jbjknhjk");

  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      imgUrl: reviewsData?.attributes?.ThirdComponent?.video_content[0].video_url

    },
    {
      imgUrl: reviewsData?.attributes?.ThirdComponent?.video_content[1].video_url
    },
    {
      imgUrl: reviewsData?.attributes?.ThirdComponent?.video_content[2].video_url
    },
    {
      imgUrl: reviewsData?.attributes?.ThirdComponent?.video_content[3].video_url
    },
    {
      imgUrl: reviewsData?.attributes?.ThirdComponent?.video_content[4].video_url
    },
    {
      imgUrl: reviewsData?.attributes?.ThirdComponent?.video_content[5].video_url
    },
    {
      imgUrl: reviewsData?.attributes?.ThirdComponent?.video_content[6].video_url
    },

  ];
  const handleLeftArrowClick = () => {
    if (currentSlide === 0) {
      setCurrentSlide(slides.length - 1);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };
  const handleRightArrowClick = () => {
    if (currentSlide === slides.length - 1) {
      setCurrentSlide(0);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };
  return (
    <>
      <div>

        <div className="page-wrapper">
          <div className="section hero testimonial">
            <div className="container-default w-container">
              <div className="mg-bottom-48px">
                <div data-w-id="3fcf44d3-ac14-9906-fedb-314944ce9d39"
                  style={{ WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }}

                  className="heading-flex align-bottom">
                  <div className="inner-container _690px _100---tablet" data-aos="fade-left">
                    <h1 className="display-2 mg-bottom-0 m-0">Happy Patients Speak</h1>
                  </div>
                  <div id="w-node-_3fcf44d3-ac14-9906-fedb-314944ce9d3f-4d0e8e92" />
                </div>
              </div>
              <div data-w-id="3fcf44d3-ac14-9906-fedb-314944ce9d42" data-aos="slide-up"
                // style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}}

                className="w-layout-grid grid-3-columns gap-row-32px">
                {reviewsData?.attributes?.SecondComp?.[0]?.Content?.map((value, index) => (
                  <div className="card testimonial-card">
                    {/* <img srcSet="images/_DSC0046review-2-p-500.jpg 500w, images/_DSC0046review-2-p-800.jpg 800w, images/_DSC0046review-2-p-1080.jpg 1080w, images/_DSC0046review-2-p-1600.jpg 1600w, images/_DSC0046review-2-p-2000.jpg 2000w, images/_DSC0046review-2.jpg 6000w" 
                alt=""
                 sizes="(max-width: 479px) 81vw, (max-width: 767px) 86vw, (max-width: 991px) 40vw, (max-width: 1439px) 24vw, 348.65625px" 
                 src={DSC0046review} className="avatar-image" /> */}
                    <img src={value.image_url} className="avatar-image" />
                    <p className="mg-bottom-24px">{value?.description}</p>
                    <div className="mg-bottom-8px">
                      <div className="text-200 bold color-neutral-800"> <strong>{value?.name}</strong></div>
                    </div>
                    <div className="text-200 medium color-accent-1">{value?.location}</div>
                  </div>
                ))}
              </div>
              <div className="div-block-15">
                <a href="#" className="btn-primary w-button">See More</a>
              </div>
              <div className="w-layout-blockcontainer container-default w-container">
                <div className="mg-bottom-48px">
                  <div data-w-id="b87c1713-3cee-2cb2-f586-f6bea5ffe500" style={{ WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="heading-flex align-bottom text-center">
                    <div className="inner-container _690px _100---tablet" data-aos="slide-up">
                      <h2 className="display-2 text-center">Patients Experience</h2>
                    </div>
                    <div id="w-node-b87c1713-3cee-2cb2-f586-f6bea5ffe506-4d0e8e92" />
                  </div>
                </div>
                <div className="div-block-4">
                  <div data-delay={4000} data-animation="cross" className="slider-wrapper arrows-bottom-center-mbl mg-bottom-40px w-slider" data-autoplay="false" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-w-id="47d70c53-21a7-a4ec-73bc-dadd113b8eef" data-autoplay-limit={0} data-nav-spacing={3} data-duration={500} data-infinite="true">
                    <div className="slider-mask overflow-visible w-slider-mask">
                      <div className="w-slide">
                        <div className="inner-container _986px center">
                          <div className="card testimonial-card-container" data-aos="flip-right">
                            <div
                              // style={{paddingTop: '56.17021276595745%'}} 
                              className="w-embed-youtubevideo">
                              {/* <iframe src="https://www.youtube.com/embed/QVj9vkqbmQE?rel=0&controls=1&autoplay=0&mute=0&start=0" frameBorder={0} style={{position: 'absolute', left: 0, top: 0, width: '100%', height: '100%', pointerEvents: 'auto'}} allow="autoplay; encrypted-media" allowFullScreen title="Curebay To Provide Medical Services In Remote And Undeveloped Areas" />
                             */}
                              <video
                                src={slides[currentSlide].imgUrl}
                                controls="controls"
                                allowFullScreen
                              />

                            </div>
                          </div>

                        </div>
                      </div>



                    </div>
                    <div className="btn-circle-secondary slider-arrow left-arrow---center w-slider-arrow-left" onClick={handleLeftArrowClick}>
                      <div className="line-rounded-icon"></div>
                    </div>
                    <div className="btn-circle-secondary slider-arrow right-arrow---center w-slider-arrow-right" onClick={handleRightArrowClick}>
                      <div className="line-rounded-icon"></div>
                    </div>
                    <div className="hidden-on-desktop w-slider-nav w-round" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {screen > 500 ? (
            <Footer />
          ) : (
            <>
              <FooterMobileVersion />
              <MobileFooter />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Reviewpagenew;
