import { HOMEPAGE } from "../constants/homepageconstants";

const initialState = {
    homePageImage: null,
    homePageContent: null,
    homePageIntegrative: null,
    doctorslist: null,
    labsList: null,
    aboutUsContent: null,
    carePlanContent: null,
    doctorPageContent: null,
    curebayExpContent: null,
    statisticsCount: null
};

const HomePageReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case HOMEPAGE.SUCCESS_HOMEPAGEIMAGE:
            return {
                ...state,
                homePageImage: payload,
            };
        case HOMEPAGE.SUCCESS_HOMEPAGECONTENT:
            return {
                ...state,
                homePageContent: payload,
            };
        case HOMEPAGE.SUCCESS_HOMEPAGEINTEGRATIVE:
            return {
                ...state,
                homePageIntegrative: payload,
            };
        case HOMEPAGE.SUCCESS_ESDOCTORLIST:
            return {
                ...state,
                doctorsList: payload,
            };
        case HOMEPAGE.SUCCESS_LABSLIST:
            return {
                ...state,
                labsList: payload,
            };
        case HOMEPAGE.SUCCESS_ABOUTUSCONTENT:
            return {
                ...state,
                aboutUsContent: payload
            };
        case HOMEPAGE.SUCCESS_CAREPLANCONTENT:
            return {
                ...state,
                carePlanContent: payload
            }
        case HOMEPAGE.SUCCESS_DOCTORPAGECONTENT:
            return {
                ...state,
                doctorPageContent: payload
            }
        case HOMEPAGE.SUCCESS_CUREBAYEXPCONTENT:
            return {
                ...state,
                curebayExpContent: payload
            }
        case HOMEPAGE.STATISTICSCOUNT:
            return {
                ...state,
                statisticsCount: action.payload,
            };

        default:
            return state;
    }
};

export default HomePageReducer;