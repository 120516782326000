import React, { useEffect, useState } from "react";
import MyprofileSidemenu from "../homepage/myprofile-sidemenu";
import mydetailsservice from "../../store/services/mydetailsservice";
import {
  Box,
  MenuItem,
  Pagination,
  Select,
  Tab,
  TablePagination,
} from "@mui/material";
import { APP_ROUTES } from "../../constants/approutes";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

// import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { fetchCarePlan, fetchUserList } from "../../store/actions/dashboard";
// import { useHistory, useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReactPaginate from "react-paginate";

function Myprescription() {
  // const [docName, setDocName] = useState('')

  const navigate = useNavigate();
  const [currentPageNu, setcurrentPageNu] = useState(1);
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const [userCode, setuserCode] = useState(
    userObj?.code
      ? userObj.code
      : userObj?.parentCode
      ? userObj.parentCode
      : userObj?.userCode
      ? userObj.userCode
      : null
  );
  // const [userCode, setuserCode] = useState(userObj?.code ? userObj.code : userObj?.parentCode ? userObj.parentCode : userObj?.userCode ? userObj.userCode : null)

  const [prescriptionData, setprescriptionData] = useState();
  const filePath = `${process.env.REACT_APP_IMAGE_URL}`;

  const breadcrumbItems = [
    { label: "Home" },
    { label: "My Details", onClick: () => navigate(APP_ROUTES.MYDETAILS) },
    { label: "Health Records" },
    { label: "My Prescriptions", url: "/details" },
  ];

  const [value, setValue] = React.useState(1);
  const [age, setAge] = React.useState(0);
  const [users, setUsers] = useState();
  const [age1, setAge1] = React.useState(1);
  const [care, setCare] = useState();
  const [allowed, setAllowed] = useState(true);
  const [pagecLimit, setPageLimit] = useState(1);

  const itemsPerPage = 4; // Number of items to display per
  const [currentPagenew, setCurrentPagenew] = useState(1);
  const handlePageChangenew = (pageNumber) => {
    setCurrentPagenew(pageNumber);
  };

  const handleChange1 = (event) => {
    setAge(event.target.value);
    getOverAll(event.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange2 = (event) => {
    setAge1(event.target.value);
  };

  // useEffect(() => {
  //     async function fetchCares() {
  //         const res = await fetchCarePlan({
  //             "userCode": userCode,
  //             "patientcode": age
  //         })
  //         if (res?.status === 200 && res?.data) {
  //             setCare(res?.data)
  //             setAge1(res?.data?.succesObject[0]?.id)
  //         }
  //     }
  //     fetchCares()
  // }, [age])
  useEffect(() => {
    async function fetchCares() {
      const res = await fetchCarePlan({
        userCode: userObj?.userCode
          ? userObj.userCode
          : userObj?.parentCode
          ? userObj.parentCode
          : null,
        patientcode: age,
      });
      if (res?.status === 200 && res?.data) {
        setCare(res?.data);
        setAge1(res?.data?.succesObject[0]?.id);
      }
    }
    fetchCares();
  }, [age]);

  useEffect(() => {
    async function fetchUserDetails() {
      const user = await fetchUserList({
        code: userObj?.userCode
          ? userObj.userCode
          : userObj?.parentCode
          ? userObj.parentCode
          : null,
        email: userObj?.email,
        mobile: userObj?.mobile,
      });

      if (user.status === 200 && user?.data) {
        const temp = user?.data?.succesObject?.filter(
          (p) => Object.keys(p).length !== 0
        );
        let first = temp;
        getFirstPatient(first);
        setUsers(temp);
      }
    }
    fetchUserDetails();
  }, []);

  console.log(users);

  useEffect(() => {
    const temp = users?.filter((p) => p.code == age);
    if (!userObj?.parentCode) {
      if (temp && temp[0]?.consentDrRemark == 1) {
        setAllowed(true);
      } else setAllowed(false);
    }
  }, [age]);

  const getFirstPatient = (e) => {
    if (userObj?.userCode) {
      setAge(e[0].code);
      getOverAll(e[0].code);
    } else {
      setAge(userCode);
      getOverAll(userCode);
    }
  };

  // const userData = useSelector((state) => state.AuthReducer.userData);
  // console.log(userData)
  useEffect(() => {
    getOverAll("");
  }, [age, age1, care, currentPageNu]);

  const getOverAll = (e) => {
    let payload;
    // let data = JSON.parse(userData);
    // console.log(data)

    const temp = care?.succesObject?.filter((p) => p?.id === age1);
    console.log(temp ? temp[0]?.subscriptionId : null);
    if (e == "") {
      payload = {
        // "patientId": data?.[1]?.patientCode ? data[1].patientCode : null,
        patientId: age,
        planId: age1,
        // "subscriptionId": temp ? temp[0]?.subscriptionId : null,
        // pageLimit: 5,
        // pageNo: currentPageNu,

        //         "userCode": userCode,
        //  "patientCode": userObj.code,
      };
    } else {
      payload = {
        // "patientId": data?.[1]?.patientCode ? data[1].patientCode : null,
        patientId: age,
        // "subscriptionId": temp ? temp[0]?.subscriptionId : null,
        // pageLimit: 5,
        // pageNo: currentPageNu,
        //         "userCode": userCode,
        //  "patientCode": userObj.code,
      };
    }

    // if (data?.[1]?.patientCode) {
    mydetailsservice
      .getmypPrescriptionlist(payload)
      .then((res) => {
        setprescriptionData(res.data.succesObject);
        setPageLimit(Math.ceil(res.data.totalRecords / 5));

        console.error("Failed to fetch prescription data:", res);
      })
      .catch((error) => {
        console.error("Failed to fetch prescription data:", error);
        // handle error as appropriate
      });
    console.log(prescriptionData, "coming");
  };
  console.log(prescriptionData);
  const downloadReport = (e) => {
    let payload = {
      appointmentId: e.appointmentId,

      visitSummary: "Y",
      status: "1",

      stampRequired: "Y",

      documentRequired: "Y",

      vitalRequired: "Y",
    };
    mydetailsservice
      .getpresDocName(payload)
      .then((res) => {
        if (res.data.succesObject[0]?.prescriptionDoc) {
          let docName = res.data.succesObject[0].prescriptionDoc;
          let payload1 = {
            fileName: docName,
          };
          mydetailsservice
            .downloadLabDoc(payload1)
            .then((res) => {
              let payload2 = {
                fileData: res?.data?.succesObject.fileData,
                fileName: "Test",
              };
              // Get the base64 string from your API response
              const base64String = payload2.fileData;

              // Convert the base64 string to a Blob object
              const byteCharacters = atob(base64String);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], {
                type: "application/octet-stream",
              });

              // Create a URL object from the Blob object
              const url = window.URL.createObjectURL(blob);

              // Create a link element and programmatically click it to initiate the file download
              const a = document.createElement("a");
              a.href = url;
              a.download = payload2.fileName;
              document.body.appendChild(a);
              a.click();
              a.remove();
            })
            .catch((error) => {
              console.error("Failed to fetch lab reports:", error);
            });
        } else {
          let epres = res?.data?.succesObject[0];
          navigate(
            APP_ROUTES.EPRECRIPTION,
            { state: epres },
            { replace: true }
          );
        }
      })
      .catch((error) => {
        console.error("Failed to fetch prescription data:", error);
      });
  };
  const viewPrescription = (e) => {
    let payload = {
      appointmentId: e.appointmentId,

      visitSummary: "Y",
      status: "1",

      stampRequired: "Y",

      documentRequired: "Y",

      vitalRequired: "Y",
    };
    mydetailsservice
      .getpresDocName(payload)
      .then((res) => {
        // if (res.data.succesObject[0]?.prescriptionDoc) {
        //     let docName = res.data.succesObject[0].prescriptionDoc
        //     window.open(filePath + docName, '_blank');
        // }
        // else {
        if (res?.data?.succesObject?.[0]) {
          // if (res?.data?.succesObject[0]?.patientDrugPrescriptionList) {
          let epres = res.data.succesObject[0];
          navigate(
            APP_ROUTES.EPRECRIPTION,
            { state: { epres: epres, dashboard: "healthrecords" } },
            { replace: true }
          );
        }
        // }
      })
      .catch((error) => {
        console.error("Failed to fetch prescription data:", error);
      });
  };
  const headerStyle = {
    backgroundColor: " rgb(230 244 253)",
    borderBottom: "1px solid #e6f4fd", // Border
  };
  return (
    <div className="p-8 ">
      {/* <div className="flex items-center mb-5">
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">Home</p>
                    <ArrowForwardIosIcon sx={{ fontSize: 15 }} className="text-chathams-blue-500 mx-2" />
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">My Profile</p>
                    <ArrowForwardIosIcon sx={{ fontSize: 15 }} className="text-chathams-blue-500 mx-2" />
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">My Details</p>
                </div> */}
      <div className="flex items-center mb-5 mt-[100px]">
        {breadcrumbItems.map((item, index) => (
          <React.Fragment key={index}>
            <span className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">
              {item.onClick ? (
                <a onClick={item.onClick}>{item.label}</a>
              ) : (
                <a>{item.label}</a>
              )}
            </span>
            {index !== breadcrumbItems.length - 1 && (
              <ArrowForwardIosIcon
                sx={{ fontSize: 15 }}
                className="text-chathams-blue-500 mx-2"
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="flex flex-col sm:flex-row justify-between ">
        <h2 className="text-xl font-bold my-5 w-full text-chathams-blue-500">
          {/* My Profile - My Prescriptions */}
        </h2>
        <div className=" pt-[66px] sm:pt-0 sm:h-20 mb-2 flex sm:items-center justify-start mr-[33rem] w-full rounded-md flex-col sm:flex-row">
          <div className="flex flex-col sm:mr-4">
            <span className="text-chathams-blue font-semibold text-base">
              Member Profile
            </span>
            <Select
              value={age}
              onChange={handleChange1}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "2 rem",
                width: "13rem",
                width: "100%",
                boxShadow: "0 2px 28px rgba(0,0,0,.1)",
              }}
            >
              {users &&
                users?.map((value, index) => (
                  <MenuItem value={value.code} key={index}>
                    <span className="text-base font-semibold">
                      {value?.name}
                    </span>
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="flex flex-col  ">
            <span className="text-chathams-blue font-semibold text-base">
              Careplans
            </span>
            {/* <Select
                            value={age1}
                            onChange={handleChange2}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            sx={{ height: "2 rem", width: "13rem" }}
                        >
                            {care && care?.succesObject?.map((value, index) => (
                                <MenuItem value={value.id} key={index}>
                                    <p className='text-base font-semibold'>{value.name}</p>
                                </MenuItem>
                            ))}

                        </Select> */}
            <Select
              value={age1}
              onChange={handleChange2}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "2 rem",
                width: "13rem",
                width: "100%",
                boxShadow: "0 2px 28px rgba(0,0,0,.1)",
              }}
            >
              {care &&
                care?.succesObject?.map((value, index) => (
                  <MenuItem value={value.id} key={index} className="w-full">
                    <span className="text-base font-semibold">
                      {value.name}
                    </span>
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
      </div>

      <div className="flex scroll-px-2">
        <div className="w-1/4 mr-4 border p-4 rounded-md hidden sm:block">
          <MyprofileSidemenu />
        </div>

        {allowed ? (
          <div className="sm:w-3/4 w-full sm:ml-4 border sm:p-8 p-3 rounded-md items-center flex flex-col">
            <h2 className="w-full flex items-start mt-0 sm:text-lg font-bold my-5 text-chathams-blue-500">
              See Your Prescription
            </h2>

            <div className="flex flex-col gap-3  w-full  ">
              <DataTable
                value={prescriptionData}
                paginator
                rows={50}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ padding: "10px" }}
                rowClassName={(rowData) =>
                  rowData.serviceStatus === 2 ? "rowcolor" : ""
                }
              >
                <Column
                  field="appointmentId"
                  header="Appointment ID"
                  sortable
                  headerStyle={headerStyle}
                  style={{ fontFamily: "poppins", fontSize: "16px" }}
                />
                <Column
                  field="vistConsultationsReason"
                  header="Symptoms"
                  sortable
                  headerStyle={headerStyle}
                  style={{ fontFamily: "poppins", fontSize: "16px" }}
                />

                <Column
                  field="userName"
                  header="Doctor Name"
                  sortable
                  headerStyle={headerStyle}
                  body={(rowData, column) => (
                    <>
                      <div class="font-poppins text-base ">
                        {rowData.userSalutation} &nbsp;
                        <span class="font-poppins text-base ">
                          {rowData.userName}
                        </span>
                      </div>
                    </>
                  )}
                />
                <Column
                  field=""
                  header="Any Allergy"
                  sortable
                  headerStyle={headerStyle}
                  body={(rowData, column) => (
                    <>
                      <div class="font-poppins text-base   ">No</div>
                    </>
                  )}
                />
                <Column
                  field="visitDate"
                  header="Consultation Date"
                  sortable
                  headerStyle={headerStyle}
                  style={{ fontFamily: "poppins", fontSize: "16px" }}
                />

                <Column
                  field=""
                  header="View Prescription"
                  sortable
                  headerStyle={headerStyle}
                  body={(rowData, column) => (
                    <>
                      <div class="  ">
                        <div className="text-center sm:text-left">
                          {" "}
                          <Button
                            variant="text"
                            sx={{
                              width: "20%",
                              marginX: "0.5rem",
                              marginY: "1rem",
                              padding: "0",
                              color: "#1976d2",
                            }}
                          >
                            <p
                              className=" text-sm font-semibold capitalize whitespace-nowrap"
                              onClick={(e) => viewPrescription(rowData)}
                            >
                              <i
                                class="fa fa-eye text-xl"
                                aria-hidden="true"
                                title="View Prescription"
                              ></i>
                            </p>
                          </Button>
                        </div>
                        {/* {rowData?.visitType === "P" ||
                        rowData?.visitType === "H" ? (
                          <div className="text-center sm:text-left">
                            <a
                              href={filePath + "" + rowData.prescriptionDocName}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Button
                                variant="text"
                                sx={{
                                  width: "20%",
                                  marginX: "0.5rem",
                                  marginY: "1rem",
                                  padding: "0",
                                  color: "#1976d2",
                                }}
                              >
                                <p className="underline text-sm font-semibold capitalize whitespace-nowrap">
                                  View Prescription
                                </p>
                              </Button>
                            </a>
                          </div>
                        ) : (
                          <div className="text-center sm:text-left">
                            {" "}
                            <Button
                              variant="text"
                              sx={{
                                width: "20%",
                                marginX: "0.5rem",
                                marginY: "1rem",
                                padding: "0",
                                color: "#1976d2",
                              }}
                            >
                              <p
                                className=" text-sm font-semibold capitalize whitespace-nowrap"
                                onClick={(e) => viewPrescription(rowData)}
                              >
                                <i
                                  class="fa fa-eye text-xl"
                                  aria-hidden="true"
                                  title="View Prescription"
                                ></i>
                              </p>
                            </Button>
                          </div>
                        )} */}
                      </div>
                    </>
                  )}
                />

                <Column
                  field="sourceChannel"
                  header=" Source Channel"
                  sortable
                  headerStyle={headerStyle}
                  style={{
                    fontFamily: "poppins",
                    fontSize: "16px",
                  }}
                  body={(rowData) =>
                    rowData.sourceChannel === "Backoffice-Medicare"
                      ? "Totalcare"
                      : rowData.sourceChannel === "ECLINIC"
                      ? "ECLINIC"
                      : rowData.sourceChannel === "B2C"
                      ? "Consumer App"
                      : rowData.sourceChannel == "Swasthya Mitra"
                      ? "Swasthya Mitra"
                      : ""
                  }
                />
              </DataTable>

              {!prescriptionData && <span> No Prescription Found</span>}
            </div>
          </div>
        ) : (
          <span className="pt-[72px]">
            Due to parental restrictions, we regret to inform you that you are
            not currently authorized to view the Prescriptions.{" "}
          </span>
        )}
      </div>
      <div className="flex justify-center ">
        {prescriptionData?.length > 0 && allowed ? (
          <>
            {" "}
            {/* <div className="paginationcontainer sm:ml-[294px] sm:mt-0 mt-[50px] ">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={Math.ceil(prescriptionData.length / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(selected) => {
                  handlePageChangenew(selected.selected + 1);
                }}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div> */}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Myprescription;
