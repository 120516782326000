import React from "react";
import { useState, useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, MenuItem, Select, Tab, TablePagination } from "@mui/material";
import MyprofileSidemenu from "../homepage/myprofile-sidemenu";
import Button from "@mui/material/Button";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Divider } from "@mui/material";
import { fetchPlans } from "../../store/actions/myprofile";
import { fetchUserList } from "../../store/actions/dashboard";
import { getOurCareplans } from "../../store/actions/howitworks";
import Tabs from "@mui/material/Tabs";
import { useNavigate, useLocation } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import cartimg from "../../assets/images/cart.png";
import Footer from '../../components/webflow/postloginfooter';
import FooterMobileVersion from "../../components/homepage/FooterMobileVersion";
import MobileFooter from "./../homepage/mobileFooter";


const CustomAccordion = ({ head, body1 }) => {


  return (
    <Accordion sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ color: "#224477", fontWeight: "bold" }}>
          {head}
        </Typography>
      </AccordionSummary>

      <AccordionDetails>
        <div className="border-t-2 pt-3">
          <Typography
            style={{ color: "#224477", display: "flex", marginBottom: "1rem" }}
          >
            <ArrowForwardIcon
              sx={{ color: "#37B7C4", paddingRight: "1rem", width: "4%" }}
            />
            {body1}
          </Typography>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

function Myplan() {
  const navigate = useNavigate();
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const [screen, setscreen] = useState(window.innerWidth);
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
      ? userObj.parentCode
      : null;
  const userCodenew = userObj?.userCode ? userObj.userCode : null;

  const [users, setUsers] = useState();
  const [carePlans, setCarePlans] = useState([]);

  useEffect(() => {
    async function fetchUserDetails() {
      const user = await fetchUserList({
        code: userCode,
        email: userObj?.email,
        mobile: userObj?.mobile,
      });

      if (user.status === 200 && user?.data) {
        const temp = user?.data?.succesObject?.filter(
          (p) => Object.keys(p).length !== 0
        );
        setUsers(temp);
      }
    }
    fetchUserDetails();
  }, []);

  const [age, setAge] = useState(0);
  const [value, setValue] = React.useState(0);
  const breadcrumbItems = [
    { label: "Home" },
    { label: "My Details", onClick: () => navigate(APP_ROUTES.MYDETAILS) },
    { label: "My Plans", url: "/details" },
  ];

  const handleChange1 = (event) => {
    setAge(event.target.value);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [myPlan, setMyPlan] = useState();
  const [user, setUser] = useState();

  useEffect(() => {
    async function fetchMyPlanDetails() {
      const myPlan = await fetchPlans({
        userCode: userCode,
        patientCode: users?.[age]?.code,
      });

      if (myPlan.status === 200 && myPlan?.data) {
        setMyPlan(myPlan?.data);

        // const temp = myPlan?.data?.succesObject?.patientVOList.find(e=>e.code==))
        const temp = myPlan?.data?.succesObject?.patientVOList.filter(
          (p) => p.code == users[age]?.code
        );
        if (temp) {
          setUser(temp[0]);
        }
      }
      const carePlansResponse = await getOurCareplans();
      if (
        carePlansResponse.status === 200 &&
        carePlansResponse?.data?.succesObject
      ) {
        setCarePlans(carePlansResponse.data.succesObject);
      }
    }
    fetchMyPlanDetails();
  }, [age, users]);

  return (
    <>
      <div className="p-8 ">
        {/* <div className="flex items-center mb-5">
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">Home</p>
                    <ArrowForwardIosIcon sx={{ fontSize: 15 }} className="text-chathams-blue-500 mx-2" />
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">My Profile</p>
                    <ArrowForwardIosIcon sx={{ fontSize: 15 }} className="text-chathams-blue-500 mx-2" />
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">My Details</p>
                </div> */}
        <div className="flex items-center mb-5 mt-[100px]">
          {breadcrumbItems.map((item, index) => (
            <React.Fragment key={index}>
              <span className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">
                {item.onClick ? (
                  <a onClick={item.onClick}>{item.label}</a>
                ) : (
                  <a>{item.label}</a>
                )}
              </span>
              {index !== breadcrumbItems.length - 1 && (
                <ArrowForwardIosIcon
                  sx={{ fontSize: 15 }}
                  className="text-chathams-blue-500 mx-2"
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="flex flex-col sm:flex-row justify-between ">
          <h2 className="text-xl font-bold my-5 w-full text-chathams-blue-500">
            {/* My Profile - My Plan */}
          </h2>
          <div className="sm:px-4 py-2 h-20 mb-2 flex items-center justify-start mr-[39rem] w-full rounded-md">
            <div className="flex flex-col">
              <span className="text-chathams-blue font-semibold text-base">
                Member Profile
              </span>
              <Select
                value={age}
                onChange={handleChange1}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                sx={{
                  height: "2 rem",
                  width: "13rem",
                  width: "100%",
                  boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                  marginBottom: "25px"
                }}
              >
                {users &&
                  users?.map((value, index) => (
                    <MenuItem value={index} key={index}>
                      <span className="text-base font-semibold">{value.name}</span>
                    </MenuItem>
                  ))}
              </Select>
            </div>
          </div>
        </div>
        <div className="flex scroll-px-2">
          <div className="w-1/4 mr-4 border p-4 rounded-md hidden sm:block">
            <MyprofileSidemenu />
          </div>
          <div className="sm:w-3/4 w-full sm:ml-4 border sm:p-8 rounded-md">
            <div className="flex justify-between gap-2"></div>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleChange}
                  // aria-label="lab API tabs example"
                  variant="scrollable"
                  scrollButtons
                  allowScrollButtonsMobile
                  aria-label="scrollable force tabs example"
                >
                  {user &&
                    user?.carePlanList?.map((value1, index) => (
                      <Tab label={value1.planName} value={index} />
                    ))}
                </TabList>
              </Box>
              <>
                {userCodenew && (
                  <>
                    {user?.carePlanList.length ? (
                      <></>
                    ) : (
                      <div
                        className="block"
                        style={{ textAlign: "-webkit-center" }}
                      >
                        <img className="w-1/4" src={cartimg} alt="" />
                        <p>
                          You don't have plan. Please purchase our plan now{" "}
                        </p>
                        <button
                          onClick={() => {
                            navigate(APP_ROUTES.BUYCAREPLAN);
                          }}
                          className={`mt-3 mb-6 py-2 px-4 text-white disabled:cursor-default rounded transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none font-poppins`}
                          style={{ background: "#66B889" }}
                        >
                          {" "}
                          Buy Plan
                        </button>
                      </div>
                    )}
                  </>
                )}

                {user?.carePlanList.length ? (
                  <>
                    {user &&
                      user?.carePlanList?.map((value2, index) => {
                        const carePlanId = value2.carePlanId;
                        const matchingCarePlan = carePlans.find(
                          (carePlan) => carePlan.planId === carePlanId
                        );
                        const price = matchingCarePlan?.price || "N/A";

                        return (
                          <TabPanel value={index} key={index}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="flex sm:h-6  px-1 sm:px-0 py-3 sm:py-0 overflow-x-auto sm:overflow-x-clip  border sm:border-none">
                                <span className="pr-5 whitespace-nowrap sm:whitespace-normal">
                                  Validity:{" "}
                                  <span className="">
                                    {value2.durationMonths} Months
                                  </span>
                                </span>
                                <span className="pl-5 whitespace-nowrap sm:whitespace-normal border-l-4 pr-5">
                                  Paid Amount:{" "}
                                  <span className="">
                                    ₹ {value2?.paidAmount}{" "}
                                  </span>
                                </span>
                                <span className="pl-5 whitespace-nowrap sm:whitespace-normal border-l-4">
                                  Next Renewal Date:{" "}
                                  <span className="">
                                    {value2.renewalDate}
                                  </span>
                                </span>
                              </div>
                              {/* <Button variant="outlined" sx={{ textTransform: "capitalize", borderColor: '#1976d2', color: '#1976d2' }}>Upgrade Plan</Button> */}
                            </div>

                            <h6
                              style={{
                                fontWeight: "bold",
                                marginBottom: "20px",
                                color: "#224477",
                                marginTop: "15px",
                              }}
                            >
                              Plan Details
                            </h6>
                            <div className="w-fit">
                              {value2?.careplanFeturesVos.map((value3) => (
                                <CustomAccordion
                                  head={value3.feature}
                                  body1={value3.descriptions}
                                />
                              ))}
                            </div>
                          </TabPanel>
                        );
                      })}
                  </>
                ) : (
                  <></>
                )}
              </>
            </TabContext>
          </div>
        </div>
      </div>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </>
  );
}

export default Myplan;
