import { useState } from "react";
import { Snackbar, Typography } from "@mui/material";
import Slide from '@mui/material/Slide';
import { Alert } from "@mui/material";

export const useSnackbar = () => {
    const [open, setOpen] = useState(false);
    const [message, setMessage] = useState();
    const [severity, setSeverity] = useState("success");

    const setSnackbarMessage = (message, severity = "success") => {
        setMessage(message);
        setSeverity(severity);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const snackbarMessage = (
        <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            TransitionComponent={Slide}
            TransitionProps={{ direction: 'up' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <Alert
                onClose={handleClose}
                severity={severity}
                sx={{
                    fontSize: '12px',
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                <div>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', lineHeight: 1, marginBottom: '4px' }}>
                        {severity === 'success' ? 'Success' : 'Error'}
                    </Typography>
                    <Typography>{message}</Typography>
                </div>
            </Alert>
        </Snackbar>


    );


    return { snackbarMessage, setSnackbarMessage };
};
