import reviewsservices from "../../services/reviewsservices";

export const getReviewsContent = async () => {
    const res = await reviewsservices.getReviewsContent();
    return res;
};

export const getReviewScreenDetails = async () => {
    const res = await reviewsservices.getReviewScreenData();
    return res;
}
export const getReviewDetails = async () => {
    const res = await reviewsservices.getReviewDashboad();
    return res;
}

