import services from "../../services";
import howitworksservices from "../../services/howitworksservices";

export const getSmartToolsData = async () => {
    const res = await howitworksservices.getSmartToolsData();
    return res;
};

export const getRecordsData = async () => {
    const res = await howitworksservices.getRecordsData();
    return res;
}

export const getOurServices = async () => {
    const res = await howitworksservices.getOurServices();
    return res;
}

export const getOurCareplans = async () => {
    const res = await howitworksservices.getOurCareplans();
    return res;
}

export const getOurAims = async () => {
    const res = await howitworksservices.getOurAim();
    return res;
}


