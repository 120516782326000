import React from "react";
import { useState, useEffect } from "react";
import ChartLine from "../common/ChartLine";
import MultiChartLine from "../common/MultiChatLine";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MyprofileSidemenu from "../homepage/myprofile-sidemenu";
import DatePicker from "react-datepicker";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchvitals } from "../../store/actions/myprofile";
import dayjs from "dayjs";
import { fetchUserList } from "../../store/actions/dashboard";
import {
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Select,
  Tab,
  TablePagination,
  TextField,
} from "@mui/material";
import { formatDateToDDMMYYYY } from "../common";
import { APP_ROUTES } from "../../constants/approutes";

function MyHealthCards(props) {
  const location = useLocation();
  const [users, setUsers] = React.useState();

  const [selectedFromDate, setSelectedFromDate] = useState(null);
  const [selectedToDate, setSelectedToDate] = useState(null);
  const [myVitals, setMyVital] = useState([]);
  const [activPatient, setActive] = useState(null);
  const [myVitalgivendate, setMyVitalgivendate] = useState(null);
  const [activeVital, setActivevital] = useState(location.state.name);

  const navigate = useNavigate();

  const [max1, setmax1] = useState(null);
  const allVitals = [
    { name: "Blood Pressure", value: "BP" },
    { name: "Temperature", value: "Temperature" },
    { name: "Heart Rate", value: "HeartRate" },
    { name: "Pulse Oxygen", value: "SPo2" },
    { name: "RespirationRate", value: "RespirationRate" },
    { name: "Height", value: "Height" },
    { name: "Weight", value: "Weight" },
    { name: "BMI", value: "BMI" },
  ];
  console.log(location);

  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
      ? userObj?.parentCode
      : null;
  const datePickerStyles = {
    input: {
      width: "100%",
      padding: "0.375rem 0.75rem",
      fontSize: "0.875rem",
      lineHeight: "3",
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    },
  };

  const breadcrumbItems = [
    { label: "Home", onClick: () => navigate(APP_ROUTES.DASHBOARD) },
    { label: "My Details" },
    { label: "My Profile" },
  ];

  useEffect(() => {
    if (location.state) {
      const { fromDate, toDate, patient } = location.state;
      setSelectedFromDate(fromDate);
      setSelectedToDate(toDate);
      setActive(patient);
    }
  }, [location.state]);
  console.log(location.state, "asdfghj");
  useEffect(() => {
    async function fetchUserDetails() {
      const user = await fetchUserList({
        code: userObj?.userCode
          ? userObj.userCode
          : userObj?.parentCode
            ? userObj.parentCode
            : null,
        email: userObj?.email,
        mobile: userObj?.mobile,
      });

      if (user.status === 200 && user?.data) {
        const temp = user?.data?.succesObject?.filter(
          (p) => Object.keys(p).length !== 0
        );
        let first = temp;
        setUsers(temp);
      }
    }
    fetchUserDetails();
  }, []);
  const handleChange = (event) => {
    setActive(event.target.value);
  };
  const handleChange1 = (event) => {
    setActivevital(event.target.value);
  };
  console.log(activeVital, "activeVital");
  const handleFromDateChange = (date) => {
    setSelectedFromDate(date);
    // calculateToDate(date);

    if (selectedToDate && date > selectedToDate) {
      setSelectedToDate(null);
    }
  };

  const calculateToDate = (fromDate) => {
    const toDate = new Date(fromDate);
    toDate.setDate(toDate.getDate() + 6);
    setSelectedToDate(toDate);
  };

  const handleTodateChange = (date) => {
    if (selectedFromDate && date < selectedFromDate) {
      setSelectedFromDate(null);
    } else {
      setSelectedToDate(date);
    }
  };
  console.log(selectedFromDate, selectedToDate, "selectedFromDate");
  useEffect(() => {
    async function fetchMyVitalsDetails() {
      const res = await fetchvitals({
        patientCode: activPatient ? activPatient : "",
        startDate: formatDateToDDMMYYYY(selectedFromDate),
        endDate: formatDateToDDMMYYYY(selectedToDate),
      });
      if (res.status === 200 && res?.data) {
        // const values = Object.values( res?.data?.succesObject?.vitalsDetails).filter(element => element.key === location.state.name ).map(element => element.value);
        const vitalsDetails = res?.data?.succesObject?.vitalsDetails;
        const filteredData = [];
        const filteregivendate = [];
        // if (Array.isArray(vitalsDetails)) {
        //   const filteredData = vitalsDetails.filter(element => element.key === location.state.name);

        vitalsDetails[activeVital].forEach((element) => {
          setMyVital([]);
          setMyVitalgivendate([]);

          if (activeVital === "BP") {
            // //console.log(element.data , "Element Value:");
            // // var stringNumber = element.data;
            // // // console.log(stringNumber , "Element Value:");
            // // var result = stringNumber.split('/');
            // // const numerator = parseInt(result[0]);
            // // const denominator = parseInt(result[1]);
            // //console.log(numerator , "individual value");
            // //console.log(denominator , "individual value");
            // //console.log(numerator+"/"+denominator , "individual value");
            // // filteredData.push(numerator+"/"+denominator)
            // filteredData?.push(element?.data)
            // const date = element.givenDate.split(' ')[0];
            // filteregivendate.push(date)
            // console.log("hiii","Element Value:")
            filteredData.push(element.data);
          } else {
            filteredData.push(element.value);
          }
          const date = element.givenDate.split(" ")[0];
          filteregivendate.push(date);
        });
        console.log(filteredData, "Element Value:");
        console.log(filteredData[0], "Element Value final:");
        setMyVital(filteredData);

        setMyVitalgivendate(filteregivendate);
        setmax1(Math.max(filteredData));
        // } else {
        //   // Handle the case when vitalsDetails is not an array
        //   console.log('vitalsDetails is not an array');
        // }
      }
      // };
    }
    fetchMyVitalsDetails();
  }, [selectedFromDate, selectedToDate, activPatient, activeVital]);

  const specialoption = {
    plugins: {
      title: {
        display: true,
        align: "start",
        text: "Bitcoin Mining Difficulty",
      },
    },
  };
  // console.log(activeVital,'activeVital')

  return (
    <>
      <div className="p-8 ">
        <div className="flex items-center mb-5  mt-[100px]">
          {breadcrumbItems.map((item, index) => (
            <React.Fragment key={index}>
              <span className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">
                <a>{item.label}</a>
              </span>
              {index !== breadcrumbItems.length - 1 && (
                <ArrowForwardIosIcon
                  sx={{ fontSize: 15 }}
                  className="text-chathams-blue-500 mx-2"
                />
              )}
            </React.Fragment>
          ))}
        </div>
        <h2 className="text-xl font-bold my-5 text-chathams-blue-500">
          My Profile - Details
        </h2>
        <div className="flex flex-col sm:flex-row mb-3">
          <div className="sm:w-1/4 mr-4 border p-4 rounded-md hidden sm:block">
            <MyprofileSidemenu value={2} />
          </div>
          <div className="w-full sm:w-3/4 sm:ml-4">
            <div className="flex justify-start mb-3">
              <div>
                <p className="text-chathams-blue font-semibold text-base">
                  Member Profile
                </p>
                <Select
                  value={activPatient}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{ height: "2 rem", width: "100%" }}
                >
                  {users &&
                    users?.map((value, index) => (
                      <MenuItem value={value.code} key={index}>
                        <span className="text-base font-semibold">{value.name}</span>
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>
            <div className="border p-4 rounded-md">
              {/* <p className="font-bold text-lg">{location.state.name}</p> */}
              <div className="flex flex-col sm:flex-row items-center w-full mb-3">
                <div className="mb-2 sm:mb-0 sm:mr-4 w-full sm:w-1/5">
                  <Select
                    value={activeVital}
                    onChange={handleChange1}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ height: "2 rem", width: "100%" }}
                    placeholderText="Select Vital"
                  >
                    {allVitals &&
                      allVitals?.map((vitals, index) => (
                        <MenuItem value={vitals.value} key={index}>
                          <span className="text-base font-semibold">
                            {vitals.name}
                          </span>
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <div className="flex">
                  <div className="pr-4 ">
                    <DatePicker
                      selected={selectedFromDate}
                      onChange={handleFromDateChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select FromDate"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={<input style={datePickerStyles.input} />}
                    />
                  </div>
                  <div className="">
                    <DatePicker
                      selected={selectedToDate}
                      onChange={handleTodateChange}
                      dateFormat="dd/MM/yyyy"
                      placeholderText="Select ToDate"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      customInput={<input style={datePickerStyles.input} />}
                      minDate={selectedFromDate}
                    // disabled
                    // className="disabled:opacity-50"
                    />
                  </div>
                </div>
              </div>
              <div className="w-full">
                {activeVital === "BP" ? (
                  <MultiChartLine
                    data={myVitals}
                    maxvalue={max1}
                    options={specialoption}
                    givendate={myVitalgivendate}
                  />
                ) : (
                  <ChartLine
                    data={myVitals}
                    maxvalue={max1}
                    options={specialoption}
                    givendate={myVitalgivendate}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyHealthCards;
