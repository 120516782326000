import { ReactChild, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { fetchOurGallery, fetchOurgallery } from "../../store/actions/aboutus";
import { fetchOurvideos } from "../../store/actions/aboutus";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MobileFooter from "./../homepage/mobileFooter";

// import Footer from './footer';
// import phoneicon from "../Assets/Images/phoneicon.svg";
import axios from "axios";
import Footer from "../homepage/footer";
import { getMedia } from "../../store/actions/footerlinks";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import cal from "../homepage/image/cal.png";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const Media = () => {
  const [title, setTitle] = useState("Print Media");
  const [media, setMedia] = useState(true);
  const [blog, setblog] = useState(false);

  const [mediaData, setMediaData] = useState([]);
  const [printMediaData, setPrintMediaData] = useState([]);
  const [mediaSelected, setSelectedMedia] = useState("e");

  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await getMedia();
      console.log(res);
      setData(res?.data.data[0]);
    };

    fetchData();
  }, []);
  console.log(data, "dataaa");

  const [value, setValue] = useState("1");
  const [gallery, setgallery] = useState();
  const [galleryImages, setGalleryImages] = useState([]);
  useEffect(() => {
    async function fetchOurphotos() {
      const gallery = await fetchOurgallery();
      if (gallery.status === 200 && gallery?.data) {
        setgallery(gallery?.data);
      }
    }
    async function fetchOurgallery() {
      let res = await fetchOurGallery();
      if (res?.status === 200 && res?.data?.data?.[0].attributes) {
        setGalleryImages(res.data.data[0].attributes);
      }
    }
    fetchOurgallery();
    fetchOurphotos();
  }, []);
  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const [video, setVideo] = useState();
  useEffect(() => {
    async function fetchVideos() {
      const video = await fetchOurvideos();
      if (video.status === 200 && video?.data) {
        setVideo(video?.data);
        console.log(video);
      }
    }
    fetchVideos();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [selectedImage, setSelectedImage] = useState(null);
  const [openvideo, setOpenvideo] = useState(false);
  const [selectedvideo, setSelectedvideo] = useState(null);
  const handleClickOpen = (value) => {
    setSelectedImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickVideo = (value) => {
    setSelectedvideo(value);
    setOpenvideo(true);
  };
  const handleClosevideo = () => {
    setOpenvideo(false);
  };
  console.log(data?.attributes?.frstComp, "asdfgh");
  return (
    <div className="select-none absolute top-15 sm:top-10 sm:w-[87.5rem] w-full">
      <div style={{ backgroundColor: "#f1f3f5" }} className="sm:pt-2">
        <div
          className="mainDiv h-28 sm:h-auto"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            backgroundImage: `url("${data?.attributes?.frstComp?.image_url}")`,
            // position: 'sticky',
            backgroundSize: "cover",
            // width: '87.5rem'
          }}
        >
          <div
            className="sm:pl-14 pl-0"
            id="subDiv_01"
            style={{
              // display: 'flex',
              justifyContent: "left",
              alignItems: "center",
              // paddingLeft: '60px',
              // maxWidth: '100%',
              height: "100%",
            }}
          >
            <div className="container mx-auto    px-3">
              {/* <div className=" w-200 h-8 bg-shakespeare bg-opacity-[8%] m-1 py-2 px-2 rounded-md">
                                <p className='text-14px md:text-sm  text-center md:font-normal text-chathams-blue-500 whitespace-nowrap	'>{data && data.FirstComponent?.name || ''}</p>
                            </div> */}
              <div className="  mt-36  bg-transparent">
                {/* <p className='md:text-40px md:font-bold leading-54px text-chathams-blue-500'>{data && data.FirstComponent?.title || ''}</p> */}
                <h1 className=" text-[22px] md:text-40px  font-semibold sm:leading-54px  sm:w-3/12 text-newblue font-poppins">
                  {data?.attributes?.frstComp?.Title?.substring(0, 12)}
                </h1>
                <h2 className="font-light text-[20px] md:text-40px sm:leading-54px font-poppins text-newblue sm:w-6/12 sm:pb-7 sm:mt-0 mt-1 sm:mb-0 mb-2">
                  {" "}
                  {data?.attributes?.frstComp?.Title?.substring(
                    12,
                    data?.attributes?.frstComp?.Title?.length
                  )}
                </h2>
                <p className="font-normal text-[16px]  sm:leading-[30.5px] font-poppins sm:w-5/12 mb-24 ">
                  {" "}
                  {data?.attributes?.frstComp?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col sm:px-12 px-3 pt-4 sm:pt-12 sm:pb-8 mt-64 mb-2 sm:mb-0 sm:mt-0">
        <h2 className="md:text-[40px] text-xl font-poppins font-[300]  md:h-12 ">
          {" "}
          Take a glimpse at
        </h2>
        <div className="md:flex w-full">
          <h3 className="md:text-[53px] text-2xl font-medium  font-poppins text-newgreen md:w-[80rem] ">
            {" "}
            our accomplishments.
          </h3>
          <div className=" w-full flex flex-col justify-center align-middle ">
            <p className="border-b-2 w-full border-newgreen"></p>
            <p></p>
          </div>
        </div>
      </div>

      <Box sx={{ width: "100%", typography: "body1", margin: "0rem" }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              paddingLeft: "4rem ",
            }}
          >
            <TabList
              onChange={handleChange}
              sx={{
                "& .Mui-selected": {
                  color: "#66B889 !important",
                },
              }}
              TabIndicatorProps={{
                style: { backgroundColor: "#66B889" },
              }}
            >
              <Tab label="Images" value="1" />
              <Tab label="Video" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="grid sm:grid-cols-3 grid-cols-1 sm:gap-y-12 gap-y-8 justify-items-stretch sm:gap-x-12 gap-x-8 ">
              {data?.attributes?.frstComp?.images.map((value) => (
                // <a  href={value.label_url}  target="_blank" >
                // <div className='contents'>

                //     <img className='' src={value?.label} alt={value?.label} ></img>

                // </div>
                // </a>
                <div className="flex  flex-col overflow-hidden w-full rounded-lg border border-[#005d8d87] p-[18px]">
                  {/* <a href={value.label_url} target="_blank"> */}
                  <img
                    className=" overflow-hidden   rounded-md  object-contain sm:h-[288px]"
                    src={value?.label}
                    alt={value?.label}
                  />
                  {/* </a> */}
                  <h3 className="font-semibold sm:text-[20px] text-[17px] mt-[10px] sm:h-[60px] h-auto">
                    {value?.title}
                  </h3>
                  <div className="flex gap-3 py-[9px]">
                    <div className="">
                      <h3
                        className="flex pr-[13px]"
                        style={{ color: "#615d5d" }}
                      >
                        <img src={cal} className="w-5 mt-1" />
                        <span className="ml-[10px]">{value?.Date}</span>
                      </h3>
                    </div>
                    {/* <div className="">
                   <h3 className="flex"  style={{color:"#615d5d"}}>   <AccessTimeIcon /><span className="ml-[10px]">{value?.time}</span></h3>
                  </div> */}
                  </div>
                  <div>
                    <a href={value.label_url} target="_blank">
                      <button className=" px-[20px] py-[7px] sm:text-[18px] text-[15px] font-poppins font-semibold bg-seegreen text-white w-full">
                        Click To Read
                      </button>
                    </a>
                  </div>
                  {/* <div className="p-1 linkbtnmobile">
                    <a
                      href={value.label_url}
                      target="_blank"
                      className="text-[14px] font-medium font-poppins medialink "
                    >
                      <button className="linkbtnmobile">Click To Read </button>
                    </a>
                    </div> */}
                </div>
              ))}
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="grid sm:grid-cols-3 grid-cols-2 sm:gap-y-10 gap-y-10 sm:gap-x-20  gap-x-5 justify-items-stretch">
              {data?.attributes?.frstComp?.video.map((item) => (
                <iframe
                  src={item?.label_url}
                  // onClick={() => handleClickOpen(item)}
                  // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  // allowFullScreen
                  // title="Embedded youtube"
                />
              ))}
            </div>
          </TabPanel>
        </TabContext>
      </Box>

      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </div>
  );
};

export default Media;
