import React, { useEffect, useState } from "react";
import Timely from "../../assets/images/Timely diagnosis.png";
import { APP_ROUTES } from "../../constants/approutes";
import logoimg from "../../assets/images/Web Logo.png";
import healthcare from "../../assets/images/healthcare package.png";
import landingimg from "../../assets/images/landingpage2.png";
import { useDispatch, useSelector } from "react-redux";
import arrowicon from "../../webflowimages/arrow-right-1.svg";
import { useNavigate } from "react-router-dom";
import services from "../../store/services";
import Aos from "aos";
import "aos/dist/aos.css";
import { getHomePageContent } from "../../store/actions/homepage";
import { Card, Grid } from "@mui/material";
import PhoneInput from "react-phone-input-2";
import { fetchCitiesByStates } from "../../store/actions/myprofile";
import loginservice from "../../store/services/loginservice";
import Landingpagefooter from "../../components/webflow/landingpagefooter";

function Landingpage2() {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const { statisticsCount } = useSelector((state) => state.HomePageReducer);
  const dispatch = useDispatch();
  const nameRegex = /^[A-Za-z\s]+$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex =
    /^\+?\d{1,4}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{6,}(?=\D*\d\D*$)/;
  const wspRegex = /^\+?\d{1,3}\s?\d{1,14}$/;
  const [isChecked, setIsChecked] = useState(false);
  const [err1, setErr1] = useState("");
  const [cities, setCities] = useState([]);

  const handleCheck = () => {
    setIsChecked(!isChecked);
  };
  const onSubmitClick = async () => {
    if (userDetails?.Name && !nameRegex.test(userDetails?.Name)) {
      setErr1("Enter a valid name");
    }
    //  else if (userDetails?.City === "") {
    //   setErr1("Please select district");
    // }
    else if (
      userDetails?.ContactNumber?.mobileNumber &&
      !phoneRegex.test(userDetails?.ContactNumber?.mobileNumber)
    ) {
      setErr1("Enter valid Phone no");
    } else if (
      !isChecked &&
      userDetails?.wpcontactnumber?.wspNo &&
      !wspRegex.test(userDetails?.wpcontactnumber?.wspNo)
    ) {
      setErr1("Enter valid WhatsApp Number");
    } else if (userDetails?.Email && !emailRegex.test(userDetails?.Email)) {
      setErr1("Enter valid Email id");
    }
    // if (
    //   (userDetails?.Name !== "" &&
    //     userDetails?.City !== "" &&
    //     emailRegex.test(userDetails?.Email) &&
    //     phoneRegex.test(userDetails?.ContactNumber?.mobileNumber) &&
    //     !isChecked &&
    //     wspRegex.test(userDetails?.wpcontactnumber?.wspNo)) ||
    //   (userDetails?.Name !== "" &&
    //     userDetails?.City !== "" &&
    //     emailRegex.test(userDetails?.Email) &&
    //     phoneRegex.test(userDetails?.ContactNumber?.mobileNumber) &&
    //     isChecked)
    // )
    else {
      setErr1("");
      const payload = {
        firstName: userDetails?.Name,
        countryCode: userDetails?.ContactNumber?.countryCode,
        mobile: userDetails?.ContactNumber?.mobileNumber,
        email: userDetails?.Email?.toLowerCase(),
        whatsAppNumber: !isChecked
          ? userDetails?.wpcontactnumber?.wspNo
          : userDetails?.ContactNumber?.mobileNumber,
        whatsAppNoCountryCode: !isChecked
          ? userDetails?.wpcontactnumber?.whatsAppNoCountryCode
          : userDetails?.ContactNumber?.countryCode,
        country: "India",
        state: "Odisha",
        city: userDetails?.City,
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "landingpagetwo_lead_generate",
        ecommerce: {
          value: 100,
          items: [
            {
              firstName: userDetails?.Name,
              countryCode: userDetails?.ContactNumber?.countryCode,
              mobile: userDetails?.ContactNumber?.mobileNumber,
              email: userDetails?.Email?.toLowerCase(),
              whatsAppNumber: !isChecked
                ? userDetails?.wpcontactnumber?.wspNo
                : userDetails?.ContactNumber?.mobileNumber,
              whatsAppNoCountryCode: !isChecked
                ? userDetails?.wpcontactnumber?.whatsAppNoCountryCode
                : userDetails?.ContactNumber?.countryCode,
              country: "India",
              state: "Odisha",
              city: userDetails?.City,
            },
          ],
        },
      });
      try {
        const res = await loginservice.landingpage(payload);
        // setLoading(false);
        // handleClose();
        if (res?.status && res?.data?.responseCode === "200") {
          // setSnackbarMessage(res.data?.responseMessage, "success");
          navigate("/thankyoupage");
        }
        //else if (res?.data?.responseCode === "500") {
        //   setShowresponsedata(res?.data?.responseMessage);
        //   setShowModal(true);
        // } else {
        //   setSnackbarMessage(res?.data?.responseMessage, "error");
        // }
      } catch (error) {
        console.error("Failed to save", error);
      }
    }
  };

  const homePageContent = useSelector(
    (state) => state.HomePageReducer.homePageContent
  );
  useEffect(() => {
    dispatch(getHomePageContent());
  }, [dispatch]);

  useEffect(() => {
    async function fetchData() {
      let payload = {
        countrycode: "IND",
        statecode: "OD",
      };
      const citiesDetailsResponse = await fetchCitiesByStates(payload);
      if (
        citiesDetailsResponse?.status === 200 &&
        citiesDetailsResponse?.data?.succesObject
      ) {
        setCities(citiesDetailsResponse.data.succesObject);
      }
    }

    fetchData();
  }, ["IND", "OD"]);
  const [userDetails, setUserDetails] = useState({
    Name: "",
    Email: "",
    City: "",
    ConfirmPassword: "",
  });

  // const handleInputChange = (e, countryData) => {
  //   if (countryData) {
  //     const name = "ContactNumber";
  //     const { dialCode, format } = countryData;

  //     setUserDetails((prevUserDetails) => ({
  //       ...prevUserDetails,
  //       [name]: {
  //         countryCode: format.charAt(0) + dialCode,
  //         mobileNumber: e.slice(dialCode.length).trim(),
  //       },
  //     }));
  //   } else {
  //     const { name, value } = e?.target;

  //     const onlyLettersRegex = /^[a-zA-Z]+$/;

  //     if (onlyLettersRegex.test(value) || value === '') {
  //       setUserDetails((prevUserDetails) => ({
  //         ...prevUserDetails,
  //         [name]: value,
  //       }));
  //     }

  //   }
  // };

  const handleInputChange = (e) => {
    const { name, value } = e?.target;

    if (name === "Name") {
      const onlyLettersRegex = /^[a-zA-Z]+$/;
      if (onlyLettersRegex.test(value) || value === "") {
        setUserDetails((prevUserDetails) => ({
          ...prevUserDetails,
          [name]: value,
        }));
      }
    } else {
      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        [name]: value,
      }));
    }
  };

  const handleInputChangenumber = (e, countryData) => {
    if (countryData) {
      const name = "ContactNumber";
      const { dialCode, format } = countryData;

      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        [name]: {
          countryCode: format.charAt(0) + dialCode,
          mobileNumber: e.slice(dialCode.length).trim(),
        },
      }));
    }
  };

  const handleInputChangewpnumber = (e, countryData) => {
    if (countryData) {
      const name = "wpcontactnumber";
      const { dialCode, format } = countryData;

      setUserDetails((prevUserDetails) => ({
        ...prevUserDetails,
        [name]: {
          whatsAppNoCountryCode: format.charAt(0) + dialCode,
          wspNo: e.slice(dialCode.length).trim(),
        },
      }));
    }
  };

  const onReadMoreClick = (plan_id) => {
    switch (plan_id) {
      case 1:
        navigate(APP_ROUTES.GENERAL_HEALTH);
        break;
      case 4:
        navigate(APP_ROUTES.CANCERSCREENING_CARE);
        break;
      case 3:
        navigate(APP_ROUTES.DIABETICS_CARE);
        break;
      case 5:
        navigate(APP_ROUTES.ORTHOPEDICS_CARE);
        break;
      case 6:
        navigate(APP_ROUTES.WOMENSHEALTH_CARE);
        break;
      default:
        break;
    }

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };

  return (
    <div className="">
      <div className="container-default w-container">
        <div className="mt-7 sm:mb-[100px] mb-[80px]">
          <a
            href={APP_ROUTES.DASHBOARD}
            aria-current="page"
            className="sm:ml-[115px] ml-5  header-logo-link w-nav-brand w--current "
          >
            <img src={logoimg} className="" alt="" />
          </a>
        </div>

        <img src={landingimg} className="rounded-2xl" />

        <div className="flex justify-center items-center sm:mt-24 mt-10">
          <div className="flex sm:flex-row flex-col gap-2 sm:w-4/5 w-full">
            <div className="sm:w-1/2 w-full font-extrabold sm:text-5xl text-lg text-[#004171] ">
              {" "}
              Best Healthcare you can provide your loved ones from miles away!
            </div>
            <div className="sm:w-1/2 w-full text-[#004171]  ">
              <p className="text-base">
                Our personalised services include timely diagnosis, instant
                emergency support, daily health updates, and periodic check-ups.
                Choose from 5 healthcare packages with real time monitoring of
                your loved one's health reports.
              </p>
              <span className="text-[20px] font-bold">
                Now, take care from anywhere.
              </span>
              <div className="buttons-row mg-bottom-52px mg-bottom-32px-tablet mt-4">
                <a
                  href={APP_ROUTES.NEWAPPOINMENT}
                  className="btn-primary2 button-row w-button hover:text-white"
                >
                  <span className="doctr-custom-icon btn-icon-left"></span>Book
                  a Health Assessment
                </a>
              </div>
            </div>
          </div>
        </div>

        <section className="section">
          <div className="w-layout-blockcontainer container-default w-container">
            <div
              data-w-id="06baaee7-3cac-e682-19b5-d99e81fc3b13"
              style={{
                WebkitTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              }}
              className="heading-flex align-bottom"
            >
              <div
                id="w-node-_06baaee7-3cac-e682-19b5-d99e81fc3b14-4d0e8e3c"
                className="inner-container _690px _100---tablet"
                data-aos="slide-up"
              >
                <h2 className="display-2 mg-bottom-0">Healthcare Plans</h2>
              </div>
              <div
                id="w-node-_06baaee7-3cac-e682-19b5-d99e81fc3b19-4d0e8e3c"
                data-aos="slide-up"
              >
                <a
                  href={APP_ROUTES.CAREPLANNEW}
                  className="btn-secondary sm:mb-[30px]"
                >
                  Explore More
                </a>
              </div>
            </div>
            <div className="div-block">
              {homePageContent?.data[0]?.attributes?.seccomp?.care_content
                ?.length > 0 &&
                homePageContent?.data[0]?.attributes?.seccomp?.care_content.map(
                  (rec, index) => (
                    <div
                      className="card blog-v1-card w-inline-block"
                      key={index}
                      data-aos="slide-up"
                    >
                      <div className="card-picture-wrapper">
                        <img
                          src={rec?.image_url}
                          loading="lazy"
                          sizes="100vw"
                          // srcSet="images/Frame-9-p-500.png 500w, images/Frame-9.png 550w"
                          alt={rec?.alt}
                          className="image-3"
                        />
                      </div>
                      <div className="card-description blog-v1">
                        <h3 className="card-title-dark">{rec?.title}</h3>
                        <p className="color-neutral-600" data-aos="slide-up">
                          {rec?.description}
                        </p>
                        <h3
                          className="card-title-dark textcta cursor-pointer"
                          data-aos="slide-up"
                          onClick={() => {
                            onReadMoreClick(rec?.plan_id);
                          }}
                        >
                          Explore More{" "}
                          <img
                            src={arrowicon}
                            loading="lazy"
                            alt=""
                            className="image-10"
                          />
                        </h3>
                      </div>
                    </div>
                  )
                )}
            </div>
          </div>
        </section>

        <div className="inner-container _986px center my-20">
          <div
            style={{
              position: "relative",
              paddingBottom: "56.25%",
              height: 0,
              overflow: "hidden",
            }}
          >
            <iframe
              src="https://www.youtube.com/embed/IGAq_N_OLoY?si=Zr29uGPKfLYn0-1C"
              frameBorder="0"
              allowFullScreen
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              // title={`Slide ${index + 1}`}
            />
          </div>

          <div className="text-center mt-[62px]">
            <a
              href={APP_ROUTES.DASHBOARD}
              className="btn-primary py-[14px] px-[48px] "
            >
              Know More
            </a>
          </div>
        </div>

        <div className="pb-12">
          <p className="font-bold sm:text-[54px] text-2xl text-center text-[#004171]">
            So what are you waiting for
          </p>

          <Card
            sx={{
              borderColor: "#396DDD52",
              // borderWidth: "0.5px",
              // borderRadius: "20px",
              marginTop: "50px",
              backgroundColor: "#F4F8FE",
            }}
            className="max-w-1400"
          >
            <div className="grid justify-center px-6 pt-2 pb-2">
              <div>
                <div className="flex justify-between gap-4 mt-10">
                  <div className="grid w-2/4">
                    <label className="text-brand-lightBlack pt-3 capitalize text-[14px]">
                      Name
                      {/* <span className="text-red-500"> *</span> */}
                    </label>
                    <input
                      name="Name"
                      className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px] w-full"
                      type="text"
                      required
                      value={userDetails?.Name}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="grid w-2/4">
                    <label className="text-brand-lightBlack pt-3 capitalize text-[14px]">
                      District
                      {/* <span className="text-red-500"> *</span> */}
                    </label>
                    <select
                      onChange={handleInputChange}
                      value={userDetails?.City || ""}
                      fullWidth
                      isSearchable
                      size="small"
                      name="City"
                      required
                      className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px] w-full"
                    >
                      <option value="">Select District</option>
                      {cities &&
                        cities?.map((value, index) => (
                          <option value={value.description} key={index}>
                            {value.description}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="flex justify-between gap-4 mt-10 ">
                  <div className="grid w-2/4">
                    <label className="text-brand-lightBlack font-poppins pt-1 capitalize text-[14px]">
                      Mobile Number
                      {/* <span className="text-red-500"> *</span> */}
                    </label>
                    <div className="flex items-center">
                      <PhoneInput
                        country={"us"}
                        enableSearch={true}
                        onChange={handleInputChangenumber}
                        value={`${userDetails?.ContactNumber?.countryCode} ${userDetails?.ContactNumber?.mobileNumber}`}
                      />
                    </div>
                  </div>

                  <div className="grid w-2/4 ">
                    <label className="text-brand-lightBlack capitalize font-poppins text-[14px] ">
                      Email
                      {/* <span className="text-red-500"> *</span> */}
                    </label>
                    <input
                      name="Email"
                      className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px]  w-full"
                      type="text"
                      onChange={(e) => handleInputChange(e)}
                      value={userDetails?.Email}
                      required
                    />
                  </div>
                </div>
                <div className="grid  mt-10">
                  <div className="flex justify-start items-start font-poppins ">
                    <input
                      className="mr-3"
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheck}
                    ></input>
                    <label className="font-poppins text-[12px] ">
                      Whatsapp number is the same as the Mobile number
                      {/* <span className="text-red-500"> *</span> */}
                    </label>
                  </div>

                  {!isChecked ? (
                    <PhoneInput
                      country={"us"}
                      enableSearch={true}
                      onChange={handleInputChangewpnumber}
                      value={`${userDetails?.wpcontactnumber?.whatsAppNoCountryCode} ${userDetails?.wpcontactnumber?.wspNo}`}
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              {err1 && (
                <span className="text-red-600 font-normal text-xs pb-4 text-center tracking-widest">
                  {err1}
                </span>
              )}

              <button
                type="submit"
                className="bg-[#004171] focus:outline-none px-2 border h-10 rounded-[132px] w-full text-white mt-10 mb-10"
                variant="contained"
                onClick={() => onSubmitClick()}
              >
                Submit
              </button>
            </div>
          </Card>
        </div>
      </div>
      <Landingpagefooter />
    </div>
  );
}

export default Landingpage2;
