import React, { useEffect, useState } from "react";
import Footer from "../homepage/footer";
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  ImageList,
  ImageListItem,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  SvgIcon,
  Typography,
  styled,
} from "@mui/material";
import { blue } from "@mui/material/colors";
import {
  getReviewsContent,
  getReviewScreenDetails,
} from "../../store/actions/reviews";
import { Button, Icon, IconButton as MuiIconButton } from "@mui/material";
import location from "../homepage/image/location.footer.svg";
import cal from "../homepage/image/cal.png";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";

function createTitle(e) {
  return (
    // <div className='sm:mx-10 mx-3'>
    //     <h1 className='sm:text-[40px] text-xl font-poppins font-[300]  sm:h-12 '> {e?.titleLine1}</h1>
    //     <div className='flex w-full'>
    //         <h1 className='sm:text-[53px] text-2xl font-medium text-newgreen w-[40rem] '> {e?.titleLine2}</h1>
    //         <div className=' w-full flex flex-col justify-center align-middle '>
    //             <h1 className='border-b-2 w-full border-newgreen'></h1><h1></h1>
    //         </div>
    //       </div>
    // </div>

    <div className=" sm:mx-10 mx-3">
      <h2 className="md:text-[40px] text-xl font-poppins font-[300]  md:h-12 ">
        {e?.titleLine1}
      </h2>
      <div className="md:flex w-full">
        <h3 className="md:text-[53px] text-2xl font-medium  font-poppins text-newgreen md:w-[41rem] ">
          {e?.titleLine2}
        </h3>
        <div className=" w-full flex flex-col justify-center align-middle ">
          <p className="border-b-2 w-full border-newgreen"></p>
          <p></p>
        </div>
      </div>
    </div>
  );
}

var nloop = 6;
const Reviews = () => {
  const [reviews, setReviews] = useState([]);
  const [reviewsData, setReviewsData] = useState([]);
  const [take, setTake] = useState(6);
  const [player, setPlayer] = useState();

  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const [position, setPosition] = React.useState({
    skip: 0,
    take: 4,
  });

  const handleServiceChange = () => {
    if (take === 6) {
      setTake(reviewsData?.attributes?.SecondComp?.[0]?.Content.length);
    } else {
      setTake(6);
    }
  };
  const IconButton = styled(MuiIconButton)({
    backgroundColor: "white",
    borderColor: "#66B889 !important",
    borderWidth: "1px !important",
    border: "solid",
    color: "#66B889",
    "&:hover": {
      backgroundColor: "#66B889",
      color: "white",
    },
  });

  useEffect(() => {
    async function fetchData() {
      const reviewResponse = await getReviewsContent();
      if (
        reviewResponse.status === 200 &&
        Array.isArray(reviewResponse?.data?.data)
      ) {
        setReviewsData(reviewResponse.data.data[0]);
        // nloop = (reviewResponse?.data?.data[0]?.reviewsData?.attributes?.ThirdComponent?.video_content?.length)
      }
    }
    fetchData();
  }, []);
  console.log(reviewsData);

  const changeDateFormat = (date) => {
    var d = new Date(date);
    return d.toString().substring(4, 15);
  };

  const handleScroll = (e, dir) => {
    if (dir === "rightArrow" && position.take < nloop) {
      let data = {
        skip: position.skip + 1,
        take: position.take + 1,
      };
      setPosition(data);
    } else if (dir === "leftArrow" && position.skip !== 0) {
      let data = {
        skip: position.skip - 1,
        take: position.take - 1,
      };
      setPosition(data);
    }
  };

  return (
    <div className="select-none absolute top-10 sm:w-[87.5rem] w-full">
      <div
        className="mainDiv h-28 sm:h-auto"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          backgroundImage: `url('${reviewsData?.attributes?.firstcomp?.[0]?.image_url}')`,
          backgroundSize: "cover",
          // width: '87.5rem',
        }}
      >
        <div
          className="sm:pl-14 pl-0"
          id="subDiv_01"
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            // paddingLeft: '60px',
            // maxWidth: '100%',
            height: "100%",
          }}
        >
          {/* <div className="container mx-10  px-4">
                        <div className=" w-32 h-8   bg-light-sky  m-1 py-2 px-2 rounded-md">
                            <p className='text-base md:text-sm  text-center md:font-normal text-chathams-blue'>{reviewsData?.attributes?.firstcomp?.[0]?.name}</p>
                        </div>
                        <div className='w-7/12 p-3 my-3  '>
                            <p className='md:text-4xl md:font-bold leading-54px text-chathams-blue-500 '>{reviewsData?.attributes?.firstcomp?.[0]?.title}</p>
                        </div>
                        <div className='w-7/12 h-22 m-1 p-3 '>
                            <p className='text-lg font-semibold leading-7 text-chathams-blue-500 '>{reviewsData?.attributes?.firstcomp?.[0]?.description}</p>
                        </div>
                    </div> */}

          <div className="  sm:mt-36  mt-96  bg-transparent px-3">
            <h1 className=" text-[22px] sm:text-40px font-semibold sm:leading-54px  sm:w-[34.33%] text-newblue font-poppins sm:mt-0 mt-1">
              {reviewsData?.attributes?.firstcomp?.[0]?.title?.substring(0, 18)}
            </h1>
            <h2 className="font-light  text-[20px] sm:text-40px sm:leading-54px font-poppins text-newblue sm:w-9/12 sm:pb-7  pb-4">
              {reviewsData?.attributes?.firstcomp?.[0]?.title?.substring(
                18,
                reviewsData?.attributes?.firstcomp?.[0]?.title?.length
              )}
            </h2>

            <p className="font-normal text-[16px] sm:w-5/12 sm:leading-[30.5px] font-poppins mb-10 ">
              {" "}
              {reviewsData?.attributes?.firstcomp?.[0]?.description}
            </p>
          </div>
        </div>
      </div>
      <div
        id="subDiv_05"
        className="  max-h-max max-w-full justify-center sm:mt-5 mt-60"
      >
        {createTitle({ titleLine1: "Member", titleLine2: " Experiences" })}
        <div className="grid sm:grid-cols-3 grid-cols-1 justify-between items-center gap-x-10 gap-y-10 mx-8 mt-14  ">
          {reviewsData?.attributes?.SecondComp?.[0]?.Content?.slice(
            0,
            take
          ).map((value, index) => (
            // <Card sx={{ maxWidth: '410px', height: '100%', paddingLeft: '7%', paddingRight: '7%', boxShadow: ' 0px 12px 19px 0px #0000000F' }}  >
            <div
              className=" justify-between items-start  flex flex-col sm:px-10 px-3"
              style={{ boxShadow: "0px 12px 19px rgba(0, 0, 0, 0.06)" }}
            >
              <div className="block  text-center">
                <div className="sm:flex sm:h-16 h-auto my-4 ">
                  <img className="" src={value.image_url}></img>
                  <div className="flex flex-col mx-3 ">
                    <h2 className="text-silver-tree-500 text-xl leading-7 font-bold text-left mt-3 sm:mt-0">
                      {value?.name}
                    </h2>
                    <div className="flex mt-1 font-semibold gap-1">
                      <img src={location}></img>
                      <p>{value?.location}</p>
                    </div>
                  </div>
                </div>
                <h3 className="text-[18px] font-normal  text-base leading-7 text-justify sm:w-[350px] w-full sm:h-[400px] h-auto">
                  {value?.description}
                </h3>
              </div>

              <div className="flex mt-5 items-end relative bottom-0 pb-5 pl-0 border-t-2 w-full pt-4 gap-1">
                <img className="w-5 pb-1" src={cal}></img>
                <p className="text-[#1A1A1A8F] sm:text-[19px] text-[16px]  text-center font-semibold">
                  {changeDateFormat(value?.Date)}
                </p>
              </div>
            </div>
            // </Card>
          ))}
        </div>
      </div>
      <div className="flex flex-row my-6 justify-center ">
        <Button
          endIcon={take === 6 ? <Icon>keyboard_arrow_right</Icon> : ""}
          startIcon={take > 6 ? <Icon>keyboard_arrow_left</Icon> : ""}
          onClick={() => {
            handleServiceChange();
          }}
          variant="contained"
          className=" text-base font-semibold text-center text-royal-blue-500 underline"
        >
          {" "}
          {take === 6 ? "See All" : "See Less"}
        </Button>
      </div>
      {createTitle({
        titleLine1: "Hear it straight",
        titleLine2: "from the source.",
      })}
      <div className="flex items-center w-full sm:pl-11 sm:mt-20 -mt-20 ">
        <div className="flex-shrink-0 mx-auto w-full px-4 sm:px-0">
          <video
            src={
              player?.video_url
                ? player?.video_url
                : reviewsData
                ? reviewsData?.attributes?.ThirdComponent?.video_content[0]
                    ?.video_url
                : ""
            }
            controls="controls"
            autoPlay={player?.video_url ? true : false}
            poster={player?.ImageThumbnailurl ? player.ImageThumbnailurl : ""}
            style={{ width: 1300, height: 430 }}
          />
        </div>
      </div>
      <div className="flex  items-center justify-evenly sm:mr-0 mr-2 sm:ml-0 ml-2">
        <IconButton
          size="medium"
          onClick={(event) => {
            handleScroll(event, "leftArrow");
          }}
          className="sm:h-auto h-6 sm:w-auto w-6"
        >
          <Icon fontSize="large" className="sm:text-inherit text-3xl">
            keyboard_arrow_left
          </Icon>
        </IconButton>
        <div className="grid grid-cols-4 sm:gap-8 gap-2 mt-9 pb-6">
          {reviewsData?.attributes?.ThirdComponent?.video_content
            .slice(position.skip, position.take)
            .map((e) => (
              <img
                src={e?.ImageThumbnailurl}
                className="w-64 sm:h-72 h-24"
                // style={{ width: '250px', height: '280px' }}
                onClick={() => {
                  setPlayer(e);
                }}
                alt="img"
              />
            ))}
        </div>
        {/* {position.take !== nloop && */}
        <IconButton
          size="medium"
          onClick={(event) => {
            handleScroll(event, "rightArrow");
          }}
          className="sm:h-auto h-6 sm:w-auto w-6"
        >
          <Icon fontSize="large" className="sm:text-inherit text-3xl">
            keyboard_arrow_right
          </Icon>
        </IconButton>
      </div>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </div>
  );
};

export default Reviews;
