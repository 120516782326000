import React, { useEffect, useState } from "react";
import Footer from '../../components/webflow/footer';
import CBfounder500 from "../../webflowimages/CBfounderpic_500.jpg";
import CBfounder800 from "../../webflowimages/CBfounderpic_800.jpg";
import CBfounder1400 from "../../webflowimages/CBfounderpic_1400.jpg";
import CBfounder1080 from "../../webflowimages/CBfounderpic_1080.jpg";
import barrectangle from "../../webflowimages/bar-rectangle-shape-bg-doctr-x-webflow-template.svg";
import { getOurteam } from "../../store/actions/footerlinks";
import 'aos/dist/aos.css';
import Aos from "aos";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";




const Ourteamnew = () => {
  const [data, setData] = useState([]);
  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getOurteam();
      setData(res?.data?.data[0]);
    };
    fetchData();
  }, []);

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  return (
    <>
      <div className="page-wrapper">
        <div className="section hero about inner-container _1128px center">
          <div className="section hero home-v1">
            <div className="inner-container _690px sm:block none">
              <div
                data-w-id="823cd561-0b81-a545-3d6c-2c6da0bf3b18"

                className="home-hero-picture v1 sm:hidden mr-6 ml-6"
              >
                <img
                  src={data?.attributes?.frstComp?.content?.image_url}
                  className="fit-cover _w-h-100"
                />
              </div>
              <div
                id="w-node-e9850865-e972-8a48-3aa3-41409d84351a-4d0e8d94"
                className="subtitle sm:pt-0 pt-6"
              >
                About us
              </div>
            </div>
            <div className="sm:w-layout-grid grid-2-columns home-hero-grid-2-col v1">

              <div
                id="w-node-_823cd561-0b81-a545-3d6c-2c6da0bf3af8-4d0e8d94"
                data-w-id="823cd561-0b81-a545-3d6c-2c6da0bf3af8"

                className="inner-container minw-480px _100---tablet"
              >
                <h1 className="display-1 mg-bottom-0">
                  {data?.attributes?.frstComp?.content?.title}
                </h1>
                <div className="inner-container _518px _100---tablet">
                  <p className="mg-bottom-32px mg-bottom-20px-tablet">
                    {data?.attributes?.frstComp?.content?.description}
                  </p>
                </div>
                <div>
                  <div className="mg-bottom-24px mg-bottom-16px-tablet" />
                </div>
              </div>
              <div
                data-w-id="823cd561-0b81-a545-3d6c-2c6da0bf3b18"

                className="home-hero-picture v1 sm:block hidden"
              >
                <img
                  src={data?.attributes?.frstComp?.content?.image_url}
                  className="fit-cover _w-h-100"
                />
              </div>
            </div>
          </div>

        </div>
        <section class="section-2 small">
          <div className="w-layout-blockcontainer container-default-2 w-container">
            <div className="inner-container _1128px center">
              <div class="">
                <div class="inner-container _690px">
                  {/* <h1 class="display-5 mg-bottom-0" data-aos="fade-up">{data?.attributes?.second?.content1?.heading1}</h1> */}
                </div>
              </div>
              <h2 className="display-5 mg-bottom-0" data-aos="fade-up">Meet the Dreamers</h2>
              {data?.attributes?.second?.content1?.map((rec, index) => (
                <div key={index} className="w-layout-grid grid-2-columns hero-grid-image team" data-aos="fade-up">
                  <img
                    src={rec?.image_url}
                    loading="eager"
                    width="394"
                    alt=""
                    className="border-radius-24px-2"
                  />
                  <div>
                    <h3 className="text-[20px] sm:text-left text-center">
                      {rec?.name}
                    </h3>
                    <div className="sm:text-left text-center text-seegreen  sm:-mt-2 -mt-2 sm:mb-3 mb-4">{rec?.role}</div>
                    <p
                      className="mg-bottom-2"
                    >
                      {rec?.description}
                      <br />
                      <br />
                      {rec?.description2}
                    </p>
                  </div>
                </div>
              ))}
              <h2 className="display-5 mg-bottom-0" data-aos="fade-up">Board of Directors</h2>
              {data?.attributes?.third_comp?.content?.map((rec, index) => (
                <div className="w-layout-grid grid-2-columns hero-grid-image  team" data-aos="fade-up">
                  <img
                    src={rec?.image_url}
                    loading="eager"
                    width="394"
                    style={{
                      transform:
                        "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    }}
                    alt=""
                    className="border-radius-24px-2"
                  />
                  <div>
                    <h3
                      className="text-[20px] sm:text-left text-center"
                    >
                      {rec?.name}
                    </h3>
                    <div className="sm:text-left text-center text-seegreen  sm:-mt-2 -mt-2 sm:mb-3 mb-4">{rec?.role}</div>
                    <p
                      style={{
                        transform:
                          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                      }}
                      className="mg-bottom-2"
                    >
                      {rec?.description}
                      <br />
                      <br />
                      {rec?.description2}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <div className="container-default w-container">
          <div className="divider _0px"></div>
        </div>
        {screen > 500 ? (
          <Footer />
        ) : (
          <>
            <FooterMobileVersion />
            <MobileFooter />
          </>
        )}
      </div>
    </>
  );
};

export default Ourteamnew;
