import React, { useEffect } from "react";
import { useState } from "react";
import { getOurteam } from "../../store/actions/footerlinks";
import Footer from "../homepage/footer";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";


const Ourteam = () => {
  const [data, setData] = useState([]);
  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const fetchData = async () => {
      const res = await getOurteam();
      setData(res?.data?.data[0]);
    };
    fetchData();
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });
  return (
    <div className="select-none xs-overflow-x-hidden  md:absolute md:top-10 w-full md:w-[87.5rem]">
      <div
        className="mainDiv  h-28 sm:h-auto"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          backgroundImage: `url("${data?.attributes?.frstComp?.content?.image_url}")`,
          backgroundSize: "cover",
        }}
      >
        <div
          className="sm:pl-14 pl-0"
          id="subDiv_01"
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            height: "100%",
          }}
        >
          <div className="sm:mt-36 mt-96  bg-transparent px-3">
            <h1 className="text-[22px] sm:text-40px font-semibold sm:leading-54px  sm:w-4/12 text-newblue font-poppins sm:mt-0 mt-24">
              {data?.attributes?.frstComp?.content?.title?.substring(0, 16)}
            </h1>
            <h2 className="font-light  text-[20px] sm:text-40px sm:leading-54px font-poppins text-newblue sm:w-9/12 sm:pb-7  pb-4">
              {data?.attributes?.frstComp?.content?.title?.substring(
                16,
                data?.attributes?.frstComp?.content?.title?.length
              )}{" "}
            </h2>
            <p className="font-normal text-[16px]  sm:w-5/12 sm:leading-[30.5px] font-poppins mb-10">
              {data?.attributes?.frstComp?.content?.description}{" "}
            </p>
          </div>
        </div>
      </div>
      <div className="max-h-max max-w-full justify-center sm:mt-2 mt-72 p-2">
        <h2 className="sm:text-[40px] text-xl font-poppins font-[300] sm:h-12 mt-9 sm:mt-0">
          {data?.attributes?.second?.heading1?.substring(0, 9)}
        </h2>
        <div className="md:flex w-full">
          <h3 className="sm:text-[53px] text-2xl font-medium text-newgreen w-[40rem]">
            {data?.attributes?.second?.heading1?.substring(
              9,
              data?.attributes?.second?.heading1?.length
            )}
          </h3>
          <div className="md:w-full md:flex flex-col justify-center align-middle">
            <p className="border-b-2 w-full border-newgreen"></p>
            <p></p>
          </div>
        </div>
        <div className="mb-2">
          {data?.attributes?.second?.content1?.map((value, i) => (
            <>
              {i % 2 === 0 ? (
                <div className="flex flex-col mt-6 justify-start mb-10 md:flex-row p-2">
                  <img
                    src={value?.image_url}
                    alt="more"
                    className="h-64 md:hidden m-auto rounded-tl-3xl rounded-br-3xl border-2 border-brand-lightgreen"
                  />
                  <div className="sm:w-3/4">
                    <h2 className="mt-3 font-bold sm:text-[26px] text-[18px] text-[#103c7e] font-poppins">
                      {" "}
                      {value?.name}{" "}
                    </h2>
                    <p className="mt-2 text-[15px] font-normal font-poppins">
                      {" "}
                      {value?.role}{" "}
                    </p>
                    <p className="mt-2 text-justify text-[15px] font-normal font-poppins md:w-[58rem]">
                      {" "}
                      {value?.description}{" "}
                    </p>
                  </div>
                  <img
                    src={value?.image_url}
                    alt="more"
                    className="h-64 mt-8 ml-8 rounded-tl-3xl rounded-br-3xl border-2 border-brand-lightgreen hidden md:block"
                  />
                </div>
              ) : (
                <>
                  <div className="flex flex-col mt-6 justify-start mb-10 md:flex-row p-4">
                    <div className="sm:w-1/4">
                      <img
                        className="h-64 rounded-tl-3xl rounded-br-3xl border-2 border-brand-lightgreen hidden md:block"
                        src={value?.image_url}
                        alt="more"
                      />
                      <img
                        className="h-64 m-auto rounded-tl-3xl rounded-br-3xl border-2 border-brand-lightgreen md:hidden"
                        src={value?.image_url}
                        alt="more"
                      />
                    </div>
                    <div className="md:ml-4 sm:w-3/4">
                      <h2 className="mt-3 font-bold sm:text-[26px] text-[18px] text-[#103c7e] font-poppins">
                        {" "}
                        {value?.name}{" "}
                      </h2>
                      <p className="mt-2 text-[15px] font-normal font-poppins">
                        {" "}
                        {value?.role}{" "}
                      </p>
                      <p className="mt-2 text-justify text-[15px] font-normal font-poppins md:w-[58rem]">
                        {" "}
                        {value?.description}{" "}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </>
          ))}
        </div>
      </div>
      <div className="max-h-max max-w-full justify-center sm:mt-2 p-2">
        <h2 className="sm:text-[40px] text-xl font-poppins font-[300] sm:h-12">
          {data?.attributes?.third_comp?.heading?.substring(0, 9)}
        </h2>
        <div className="md:flex w-full">
          <h3 className="sm:text-[53px] text-2xl font-medium text-newgreen w-[40rem]">
            {data?.attributes?.third_comp?.heading?.substring(
              9,
              data?.attributes?.third_comp?.heading?.length
            )}
          </h3>
          <div className="w-full flex flex-col justify-center align-middle">
            <p className="border-b-2 w-full border-newgreen"></p>
            <p></p>
          </div>
        </div>
        <div className="mb-24">
          {data?.attributes?.third_comp?.content?.map((value, i) => (
            <>
              {i % 2 == 0 ? (
                <div className="flex flex-col mt-6 justify-start mb-10 md:flex-row p-2">
                  <img
                    src={value?.image_url}
                    alt="more"
                    className="h-64 md:hidden m-auto rounded-tl-3xl rounded-br-3xl border-2 border-brand-lightgreen"
                  />
                  <div className="sm:w-3/4">
                    <h2 className="mt-2 font-bold sm:text-[26px] text-[18px] text-[#103c7e] font-poppins ">
                      {" "}
                      {value?.name}{" "}
                    </h2>
                    <p className="mt-2 text-[15px] font-normal font-poppins">
                      {" "}
                      {value?.role}{" "}
                    </p>
                    <p className="mt-2 text-justify text-[15px] font-normal font-poppins md:w-[58rem]">
                      {" "}
                      {value?.description}{" "}
                    </p>
                  </div>
                  <img
                    src={value?.image_url}
                    alt="more"
                    className="h-64 mt-8 ml-8 rounded-tl-3xl rounded-br-3xl border-2 border-brand-lightgreen hidden md:block"
                  />
                </div>
              ) : (
                <>
                  <div className="flex flex-col mt-6 justify-start mb-10 md:flex-row p-4">
                    <div className="sm:w-1/4">
                      <img
                        src={value?.image_url}
                        alt="More"
                        className="h-64 rounded-tl-3xl rounded-br-3xl border-2 border-brand-lightgreen hidden md:block"
                      />
                      <img
                        src={value?.image_url}
                        alt="More"
                        className="h-64 m-auto rounded-tl-3xl rounded-br-3xl border-2 border-brand-lightgreen md:hidden"
                      />
                    </div>
                    <div className="md:ml-4 sm:w-3/4">
                      <h2 className="mt-3  font-bold sm:text-[26px] text-[18px] text-[#103c7e] font-poppins  ">
                        {" "}
                        {value?.name}{" "}
                      </h2>
                      <p className="mt-2 text-[15px] font-normal font-poppins ">
                        {" "}
                        {value?.role}{" "}
                      </p>
                      <p className="mt-2 text-justify text-[15px] font-normal font-poppins md:w-[58rem]">
                        {" "}
                        {value?.description}{" "}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </>
          ))}
        </div>
      </div>

      {screen > 500 ? (
        <Footer i={1} />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </div>
  );
};
export default Ourteam;
