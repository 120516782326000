import React, { useEffect, useState } from "react";
import "../../css/webflow.css";
import "../../css/curebay-medicare.webflow.css";
import diabetic from "../../webflowimages/diabetic.png"
import glucose from "../../webflowimages/glucose.svg"
import Footer from '../../components/webflow/footer';
import Aos from "aos";
import 'aos/dist/aos.css'
import { useLocation } from 'react-router-dom';
import {
  getHowItWorksAction,
  healtchCareService,
  exportComparePlan,
  getBenifits,
  fetchGeneralHealth,
  healthCareService,
} from "../../store/actions/careplans";
import { getOurCareplans } from "../../store/actions/howitworks/index.jsx";
import { useSnackbar } from "../common/snackbar";
import { IconButton } from "@mui/material";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";

import { useDispatch, useSelector } from "react-redux";
import { getHomePageContent } from "../../store/actions/homepage/index.jsx";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import { updateCart } from "../../store/actions/login";
import loginservice from "../../store/services/loginservice";
import { COMMONCONSTANTS } from "../../store/constants/commonconstants";
import arrowicon from '../../webflowimages/arrow-right-1.svg';

function DiabeticCare() {
  const userData = useSelector((state) => state.AuthReducer.userData);
  const [diabeticHealthContent, setDiabeticHealthContent] = useState([]);
  const location = useLocation();
  const selectedItem = location?.state?.selectedItem;
  const [generalHealthContent, setGeneralHealthContent] = useState([]);
  const [carePlans, setCarePlans] = useState([])
  const [compareHealth, setCompareHealthCare] = useState([]);
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const [healthCareDetails, setHealthCareDetails] = useState([]);
  const [compareHealth1, setCompareHealthCare1] = useState([]);
  const [compareHealth2, setCompareHealthCare2] = useState([]);
  const [compareHealth3, setCompareHealthCare3] = useState([]);
  const [compareHealth4, setCompareHealthCare4] = useState([]);
  const [generalHealth, setGeneralHealth] = useState([]);
  const [screen, setscreen] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const [loggedin, setLoggedIn] = useState(false);
  const navigate = useNavigate();
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
      ? userObj.parentCode
      : null;
  console.log(userCode);
  useEffect(() => {
    if (userCode) setLoggedIn(true);
  }, [userCode]);


  useEffect(() => {
    async function fetchHelthPlan() {
      const resposne = await healthCareService();
      if (resposne?.data?.data.length > 0) {
        setDiabeticHealthContent(resposne?.data?.data?.filter((e) => e?.id === 3)?.[0]);
      }
    }
    fetchHelthPlan();
  }, [])

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])

  const [activeTab, setActiveTab] = useState('yearly');

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };




  useEffect(() => {
    async function fetchHelthCareData() {
      const resposne = await healtchCareService();
      if (resposne?.data?.data.length > 0) {
        setHealthCareDetails(resposne?.data?.data);
      }
    }
    async function fetchData() {
      let CareplanId = 3;
      const comparePlanResponse = await exportComparePlan(1);
      if (
        comparePlanResponse?.data?.responseCode === "200" &&
        comparePlanResponse?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare(comparePlanResponse?.data?.succesObject);
      }
      const comparePlanResponse1 = await exportComparePlan(3);
      if (
        comparePlanResponse1?.data?.responseCode === "200" &&
        comparePlanResponse1?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare1(comparePlanResponse1?.data?.succesObject);
      }
      const comparePlanResponse2 = await exportComparePlan(4);
      if (
        comparePlanResponse2?.data?.responseCode === "200" &&
        comparePlanResponse2?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare2(comparePlanResponse2?.data?.succesObject);
      }
      const comparePlanResponse3 = await exportComparePlan(6);
      if (
        comparePlanResponse3?.data?.responseCode === "200" &&
        comparePlanResponse3?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare3(comparePlanResponse3?.data?.succesObject);
      }
      const comparePlanResponse4 = await exportComparePlan(5);
      if (
        comparePlanResponse4?.data?.responseCode === "200" &&
        comparePlanResponse4?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare4(comparePlanResponse4?.data?.succesObject);
      }

      // const benefitsResponse = await getBenifits();
      // if (benefitsResponse?.status === 200 && benefitsResponse?.data?.data) {
      //   setBenefits(benefitsResponse.data.data[0]);
      // }
    }
    async function fetchGeneralHealthDetails() {
      const response = await fetchGeneralHealth();
      if (response?.data?.data) {
        setGeneralHealth(response?.data?.data);
      }
    }
    fetchData();
    fetchHelthCareData();
    fetchGeneralHealthDetails();
    dispatch(getHowItWorksAction());
  }, [dispatch]);




  const homePageContent = useSelector(
    (state) => state.HomePageReducer.homePageContent
  );

  const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(title === "Yearly" ? true : false);
    const handleOnClick = () => {
      setIsActive(!isActive);
      // setShow(!show)
    };

    console.log(content);
    return (
      <div className="accordion-item-wrapper v2 " style={{ display: "block", borderTop: "0" }}>
        {snackbarMessage}
        <div
          className="flex cursor-pointer justify-between items-center md:py-2 w-full"
          onClick={handleOnClick}
        >
          <div class="accordion-content-wrapper v2" > <div class="accordion-header" > <h2 class="accordion-title display-4" >
            {title}
          </h2></div></div>
          <div className="md:flex justify-end md:text-3xl text-sm font-semibold  text-brand-primary sm:w-[44px]">
            {/* <IconButton size="medium" sx={{ backgroundColor: "#E4FAFF" }}> */}
            {isActive ? (

              <div className="accordion-side right-side">
                <div className="accordion-icon-wrapper">
                  <div className="accordion-btn-line v2 horizontal"></div>
                </div>
              </div>
            ) : (
              <div className="accordion-side right-side">
                <div className="accordion-icon-wrapper">
                  <div className="accordion-btn-line v2 vertical"></div>
                  <div className="accordion-btn-line v2 horizontal"></div>
                </div>
              </div>
            )}
            {/* </IconButton> */}
          </div>
        </div>
        {isActive ? (
          <div className="w-full">
            {content}
          </div>
        ) : null}
      </div>
    );
  };


  const monthlyDia = compareHealth1?.careplanFeturesVos?.filter(
    (e) => e?.monthlys === "Y"
  );
  const quarterlyDia = compareHealth1?.careplanFeturesVos?.filter(
    (e) => e?.quarterlys === "Y"
  );
  const hYearlyDia = compareHealth1?.careplanFeturesVos?.filter(
    (e) => e?.halfYearlys === "Y"
  );
  const yearlyDia = compareHealth1?.careplanFeturesVos?.filter(
    (e) => e?.yearlys === "Y"
  );
  const onDemandDia = compareHealth1?.careplanFeturesVos?.filter(
    (e) => e?.onDemands === "Y"
  );

  const addToCart = async (e) => {
    if (!loggedin) {
      const data = {
        prop1: "Diabetes Care",
        prop2: 3,
        prop3: e === "monthly" || e === "yearly" ? e : undefined,
      };
      // navigate(APP_ROUTES.LOGIN)
      navigate(APP_ROUTES.LOGIN, { state: data });
    } else {
      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
          value: 100,
          items: [
            {
              // userId: "",
              userCode: userCode,
              planId: 3,
              planName: "Diabetes Care",
              period: e === "monthly" || e === "yearly" ? e : undefined,
            },
          ],
        },
      });
      let data = JSON.parse(userData);
      let payload = {
        userCode: userCode,
        cartType: 1,
        cartVOList: [
          {
            userId: "",
            userCode: userCode,
            planId: 3,
            planName: "Diabetes Care",
            period: e === "monthly" || e === "yearly" ? e : undefined,
          },
        ],
      };

      const res = await updateCart(payload);
      if (res?.status === 200 && res?.data?.responseCode) {
        let userData = {
          userCode: data?.[0]?.userCode,
        };
        loginservice
          .cartList(userData)
          .then((res) => {
            dispatch({
              type: COMMONCONSTANTS.SUCCESS_FETCHCART,
              payload: res?.data?.succesObject,
            });
          })
          .catch((error) => { });
        setSnackbarMessage("Added to the cart", "success");
      }
    }
  };
  const navigateToPlans = async () => {
    if (!loggedin) {
      navigate(APP_ROUTES.LOGIN);
    } else {
      navigate(APP_ROUTES.BUYCAREPLAN);
    }
  };
  const onReadMoreClick = (plan_id) => {

    switch (plan_id) {
      case 1:
        navigate(APP_ROUTES.GENERAL_HEALTH);
        break;
      case 4:
        navigate(APP_ROUTES.CANCERSCREENING_CARE);
        break;
      case 3:
        navigate(APP_ROUTES.DIABETICS_CARE);
        break;
      case 5:
        navigate(APP_ROUTES.ORTHOPEDICS_CARE);
        break;
      case 6:
        navigate(APP_ROUTES.WOMENSHEALTH_CARE);
        break;
      default:
        break;
    }

    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  return (
    <>

      <div className="page-wrapper">
        <div className="relative">
          {/* <div class="half-bg-top package-single-bg-top"></div> */}
          {/* <div className="mt-20 bg-[#004171] h-[60vw] rounded-bl-[40px] rounded-br-[40px] relative" >
            <div className="pt-[20px]">
              <h1 className="text-center text-[68px] font-bold text-white ">{diabeticHealthContent?.attributes?.name}</h1>
              <div className="grid grid-cols-2 gap-x-16 ">
                <div className="pl-[335px]">
                  <p className="text-lg text-white p-5">{diabeticHealthContent?.attributes?.firstcomp?.description}</p>
                  {diabeticHealthContent?.attributes?.firstcomp?.points?.map((rec, index) => (
                    <React.Fragment key={index}>
                      <span className="text-lg text-white p-4">{rec?.points}</span><br />
                    </React.Fragment>
                  ))}
                </div>

                <div className="content-center "><img src={diabetic} className="rounded-3xl" />
                  <img src={glucose} className="rounded-3xl mt-4" /></div>
              </div>
            </div>
          </div> */}

          <div className="position-relative">
            <div className="half-bg-top package-single-bg-top sm:h-[68vw] h-[1006px]"></div>


            <div className="section hero about">
              <div className="container-default w-container">
                <div className="position-relative">
                  <div className="shape-image-container-hero">
                    <div className="inner-container _1128px center">
                      <div className="mg-bottom-64px">
                        <div data-w-id="3bd12f2c-d7e9-9289-87b4-07cb88779680"
                          className="w-layout-grid grid-2-columns _1-col-tablet title-and-buttons">
                          <div id="w-node-_3bd12f2c-d7e9-9289-87b4-07cb88779681-4d0e8e03" className="inner-container _690px">
                            <h1 className="display-1 mg-bottom-0 white mt-0" data-aos="fade-up">{diabeticHealthContent?.attributes?.name}</h1>
                          </div>
                          <div id="w-node-_3bd12f2c-d7e9-9289-87b4-07cb88779686-4d0e8e03"></div>
                        </div>
                      </div>
                      <div className="w-layout-grid grid-2-columns hero-grid-image" style={{ marginBottom: "0px" }}>
                        <div className="inner-container _460px _100---tablet">
                          <p id="w-node-_3bd12f2c-d7e9-9289-87b4-07cb8877968d-4d0e8e03" data-w-id="3bd12f2c-d7e9-9289-87b4-07cb8877968d"
                            className=" text-white white text-left mb-4" data-aos="fade-up">{diabeticHealthContent?.attributes?.firstcomp?.description}</p>
                          <div className="rich-text-block white w-richtext">
                            {diabeticHealthContent?.attributes?.SecondComp?.points?.map((rec, index) => (
                              <ul role="list"><li style={{ listStyle: "disc" }}>{rec?.points}</li></ul>
                            ))}
                          </div>
                        </div>
                        <div id="w-node-_62f914ff-393e-5110-fef7-e21aa35d4788-4d0e8e03" className="sm:w-[668px]">
                          <div>
                            <img src={diabeticHealthContent?.attributes?.firstcomp?.image_url} alt={'generalHealthImage'} className=" w-full" data-aos="fade-up" />
                          </div>
                          <div className=" flex gap-2">
                            <div>
                              <img src={diabeticHealthContent?.attributes?.firstcomp?.image_url2} alt={'generalHealthImage'} className="rounded-3xl mt-4" data-aos="fade-up" style={{ width: "100%" }} />
                            </div>
                            <div>
                              <img src={diabeticHealthContent?.attributes?.firstcomp?.image_url3} alt={'generalHealthImage'} className="rounded-3xl mt-4" data-aos="fade-up" style={{ width: "100%" }} />
                            </div>
                            <div>
                              <img src={diabeticHealthContent?.attributes?.firstcomp?.image_url4} alt={'generalHealthImage'} className="rounded-3xl mt-4" data-aos="fade-up" style={{ width: "100%" }} />
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <section className="section pay ">
            <div className="container-default w-container">
              <div className="image-rounded-corners-wrapper _02">
                <div
                  data-w-id="ca1df06d-438f-e5e9-c5b8-716b4d8377f2"
                  className="card add-to-cart-card"
                  data-aos="fade-up"
                >
                  <p className="paybutton">
                    Pay in Full
                  </p>
                  {/* <div class=" cartprice">1200.00 USD</div> */}
                  <div data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_price_%22%2C%22to%22%3A%22innerHTML%22%7D%5D" class="display-3 mg-bottom-24px text-center">1200.00&nbsp;USD</div>
                  {/* <p className=" mg-bottom-16px">Just pay for 11 months. 1 month is on us.</p> */}
                  <div className="div-block-16">
                    <a href="#" className="btn-primary" onClick={() => navigateToPlans()}>Buy Now</a>
                    <a href="#" className="btn-secondary addcartbtn" onClick={() => addToCart("yearly")}>Add to Cart</a>
                  </div>
                </div>
                <div
                  data-w-id="dbf466ca-8f17-e78b-fa11-038c0e2b26a8"
                  className="card add-to-cart-card text-center"
                  data-aos="fade-up"
                >
                  <p className="paybutton">
                    Pay Monthly
                  </p>
                  {/* <div className="text-3xl mb-24"></div> */}
                  <div data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_price_%22%2C%22to%22%3A%22innerHTML%22%7D%5D" class="display-3 mg-bottom-24px text-center">100.00&nbsp;USD</div>
                  <p className=" mg-bottom-16px">Pay $100 every month for a year</p>
                  <div className="div-block-16">
                    <a href="#" className="btn-primary" onClick={() => navigateToPlans()}>Buy Now</a>
                    <a href="#" className="btn-secondary addcartbtn" onClick={() => addToCart("monthly")}>Add to Cart</a>
                  </div>
                </div>

              </div>
            </div>
          </section>



        </div>


        <section className="section">
          <h2 className="display-2" data-aos="fade-up">Features</h2>
          <div className="container-default w-container" data-aos="fade-up">
            <div className="div-block-8" data-aos="fade-up">
              {
                diabeticHealthContent?.attributes?.FourthComp?.content?.map((rec, index) => (
                  <div
                    className="card features"
                    style={{ display: "block" }}
                  >
                    <img
                      src={rec?.image_url}
                      loading="lazy"
                      width="119"
                      alt=""
                      className="icon"
                    />
                    <div>
                      <h5 className=" mt-5">{rec?.name}</h5>
                      <div className="tab-body">
                        {rec?.description}
                      </div>
                    </div>
                  </div>

                ))
              }
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container-default w-container">
            <div
              data-w-id="c1333913-a4d1-fa51-c8cf-245a8e72fcc7"

              className="inner-container _700px center"
            >
              <div className="text-center mg-bottom-32px">
                <h2 className="display-1" data-aos="fade-up">{generalHealthContent?.attributes?.name} Frequency</h2>
              </div>
            </div>
            <div
              data-w-id="c1333913-a4d1-fa51-c8cf-245a8e72fccb"

              className="inner-container _866px center"
              data-aos="fade-up"
            >

              {/* tab */}

              <div>
                <div className="flex sm:flex-row flex-col gap-3 frequencytab">
                  <div
                    className={`tab-item badge-secondary tabs w-inline-block w-tab-link ${activeTab === 'yearly' ? 'active' : ''}`}
                    onClick={() => handleTabClick('yearly')}
                  >
                    Yearly
                  </div>
                  <div
                    className={`tab-item badge-secondary tabs w-inline-block w-tab-link ${activeTab === 'Half Yearly' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Half Yearly')}
                  >
                    Half Yearly
                  </div>
                  <div
                    className={`tab-item badge-secondary tabs w-inline-block w-tab-link ${activeTab === 'Quarterly' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Quarterly')}
                  >
                    Quarterly
                  </div>
                  <div
                    className={`tab-item badge-secondary tabs w-inline-block w-tab-link ${activeTab === 'Monthly' ? 'active' : ''}`}
                    onClick={() => handleTabClick('Monthly')}
                  >
                    Monthly
                  </div>



                  <div
                    className={`tab-item badge-secondary tabs w-inline-block w-tab-link ${activeTab === 'On Demand' ? 'active' : ''}`}
                    onClick={() => handleTabClick('On Demand')}
                  >
                    On Demand
                  </div>
                </div>

                <div className="tab-content">
                  {activeTab === 'yearly' && (
                    <div>
                      {yearlyDia?.map((rec, index) => (
                        <>


                          <Accordion title={rec.feature} content={rec.descriptions} />
                        </>

                      ))}
                    </div>
                  )}
                  {activeTab === 'Half Yearly' && (
                    <div>
                      {hYearlyDia?.map((rec, index) => (
                        <>
                          <Accordion title={rec.feature} content={rec.descriptions} />
                        </>

                      ))}
                    </div>
                  )}

                  {activeTab === 'Quarterly' && (
                    <div>
                      {quarterlyDia?.map((rec, index) => (
                        <>
                          <Accordion title={rec.feature} content={rec.descriptions} />
                        </>

                      ))}
                    </div>
                  )}

                  {activeTab === 'Monthly' && (
                    <div>
                      {monthlyDia?.map((rec, index) => (
                        <>
                          <Accordion title={rec.feature} content={rec.descriptions} />
                        </>

                      ))}
                    </div>
                  )}

                  {activeTab === 'On Demand' && (
                    <div>
                      {onDemandDia?.map((rec, index) => (
                        <>
                          <Accordion title={rec.feature} content={rec.descriptions} />
                        </>

                      ))}
                    </div>
                  )}
                </div>
              </div>
              {/* tab end */}
            </div>
          </div>
        </section>
        <div className="overflow-hidden">
          <div className="section pd-top-and-bottom-150px">
            <div className="w-layout-blockcontainer container-default w-container">
              <div className="text-center mg-bottom-40px">
                <h2 className="display-2 mg-bottom-0 center" data-aos="fade-up">
                  Additional Healthcare services
                </h2>
              </div>
              <div className="div-new" data-aos="fade-up">
                {
                  diabeticHealthContent?.attributes?.FifthComp?.content?.map((rec, index) => (
                    <div key={index} className="card features">
                      <img
                        src={rec?.icon_url}
                        loading="lazy"
                        width="119"
                        alt=""
                        className="icon"
                      />
                      <div>
                        <h5 className="">{rec?.name}</h5>
                        <div className="tab-body">
                          {rec?.description}
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
        <section className="section">
          <div className="w-layout-blockcontainer container-default w-container">
            <div
              data-w-id="37dc0cea-e3bc-f50d-3c5b-991f96e383a7"
              // style={{
              //   WebkitTransform:
              //     "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              //   MozTransform:
              //     "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              //   MsTransform:
              //     "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              //   transform:
              //     "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              //   opacity: 0,
              // }}
              className="heading-flex align-bottom"
            >
              <div
                id="w-node-_37dc0cea-e3bc-f50d-3c5b-991f96e383a8-4d0e8e03"
                className="inner-container _690px _100---tablet"
              >
                <h2 className="display-2 mg-bottom-0" data-aos="fade-up">Healthcare Plans</h2>
              </div>
              <div id="w-node-_37dc0cea-e3bc-f50d-3c5b-991f96e383ad-4d0e8e03">
                <a href={APP_ROUTES.CAREPLANNEW} className="btn-secondary w-button">
                  Explore More
                </a>
              </div>
            </div>
            <div className="div-block" data-aos="fade-up">
              {homePageContent?.data[0]?.attributes?.seccomp?.care_content?.length > 0 &&
                homePageContent?.data[0]?.attributes?.seccomp?.care_content.map((rec, index) => (
                  <div className="card blog-v1-card w-inline-block" key={index}>
                    <div className="card-picture-wrapper">
                      <img
                        src={rec?.image_url}
                        loading="lazy"
                        sizes="100vw"
                        // srcSet="images/Frame-9-p-500.png 500w, images/Frame-9.png 550w"
                        alt=""
                        className="image-3"
                      />
                    </div>
                    <div className="card-description blog-v1">
                      <h3 className="card-title-dark">{rec?.title}</h3>
                      <p className="color-neutral-600">
                        {rec?.description}
                      </p>
                      <h3 className="card-title-dark textcta cursor-pointer" onClick={() => {
                        onReadMoreClick(rec?.plan_id);
                      }}>Explore More <img src={arrowicon} loading="lazy" alt="" className="image-10" /></h3>
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </section>


      </div>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </>
  );
}

export default DiabeticCare;
