import myprofile from "../../services/myprofileservices";
import myvitalsservices from "../../services/myvitalsservices";

export const fetchPlans = async (payload) => {
  const res = await myprofile.getmyplanapi(JSON.stringify(payload));
  return res;
};


export const fetchvitals = async (payload) => {
  const res = await myvitalsservices.getmyvitalapi(JSON.stringify(payload));
  return res;
};

export const updatepswd = async (payload) => {
  const res = await myprofile.changepasswrd(JSON.stringify(payload));
  return res;
};

export const fetchRelationDetails = async () => {
  const res = await myprofile.fetchRelations();
  return res;
}

export const fetchBloodGroupDetails = async () => {
  const res = await myprofile.fetchBloodGroups();
  return res;
}

export const fetchStatesDetails = async () => {
  const res = await myprofile.fetchStates();
  return res;
}

export const fetchCountryDetails = async () => {
  const res = await myprofile.fetchCountries();
  return res;
}

export const fetchStatesByCountries = async (payload) => {
  const res = await myprofile.fetchStatesByCountries(payload);
  return res;
}

export const fetchCitiesByStates = async (payload) => {
  const res = await myprofile.fetchCitiesByStates(payload);
  return res;
}