import React, { useState, useEffect } from "react";
import phone from "./image/phone.footer.svg";
import email from "./image/email.footer.svg";
import whatsAppLogo from "./image/whatsapp.svg";
import location from "./image/location.footer.svg";
import logo from "./image/Medicare_Logo.png";
import ffb from "./image/footer fb.svg";
import iig from "./image/footer iig.svg";
import twt from "./image/footer twt.svg";
import lndn from "./image/footer lndn.svg";
import FAQphn from "./image/FAQphn.png";
import Button from "@mui/material/Button";
import playstore from "./image/play store.svg";
import appstore from "./image/app store.svg";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const navigate = useNavigate();

  const goTopBtn = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const scrollThreshold = 500;

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const redirectToPlayStore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.curebay.totalcare",
      "_blank"
    );
  };

  const redirectToAppStore = () => {
    window.open(
      "https://apps.apple.com/in/app/curebay-medicare/id6459267188",
      "_blank"
    );
  };
  return (
    <div className="flex relative lg:w-full flex-col-reverse bottom-0 font-light  bg-footer-color  ">
      {scrollPosition > scrollThreshold && (
        <div
          className="scroll-to-top-btn"
          onClick={goTopBtn}
          title="Scroll to Top"
        >
          <span>
            <i class="fa fa-angle-double-up " aria-hidden="true"></i>
          </span>
        </div>
      )}

      <div className="flex justify-center text-blacky  font-poppins font-normal md:text-[16px] text-sm bg-footer-color pt-5 pb-5  mt-1 border-t-2 w-full">
        Copyright © 2023 CureBay. All rights reserved.
      </div>

      {/* <img className='mx-2' src={lndn} alt='lndn' id='lndn' /> */}
      <div className="md:flex justify-between md:mb-6 ">
        <div className="flex ml-14">
          <a
            href="https://www.linkedin.com/company/80637697/admin/"
            target="_blank"
            className="transition-colors duration-300 hover:underline  cursor-pointer"
          >
            <img className="mx-2 " src={lndn} alt="lndn" id="lndn" />
          </a>
          {/* <img className='mx-2' src={twt} alt='twt' id='twt' /> */}
          <a
            href="https://twitter.com/cure_bay?s=11"
            target="_blank"
            className=" transition-colors duration-300 hover:underline   cursor-pointer"
          >
            <img className="mx-2" src={twt} alt="twt" id="twt" />
          </a>

          {/* <img className='mx-2' src={iig} alt='iig' id='iig' /> */}
          <a
            href="https://instagram.com/cure_bay?utm_medium=copy_link"
            target="_blank"
            className="transition-colors duration-300 hover:underline   cursor-pointer"
          >
            <img className="mx-2" src={iig} alt="iig" id="iig" />
          </a>
          {/* <img className='mx-2' src={ffb} alt='ffb' id='ffb' /> */}
          <a
            href="https://facebook.com/CureBay-106822021819382/"
            target="_blank"
            className="transition-colors duration-300 hover:underline   cursor-pointer"
          >
            <img className="mx-2" src={ffb} alt="ffb" id="ffb" />
          </a>
        </div>
        <div
          className=" flex items-end justify-end mr-7 "
          style={{ flexGrow: "3" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <a
              href={
                window.location.host === "medicare-stage.curebay.in"
                  ? "https://patients-stage.curebay.in/privacy-policy"
                  : "https://www.curebay.com/privacy-policy"
              }
              target="_blank"
              className="cursor-pointer"
            >
              <p className="pr-7 font-poppins font-normal md:text-[16px] text-sm">
                Privacy Policy
              </p>
            </a>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a
              href={
                window.location.host === "medicare-stage.curebay.in"
                  ? "https://patients-stage.curebay.in/terms-and-condition"
                  : "https://www.curebay.com/terms-and-condition"
              }
              target="_blank"
              className="cursor-pointer"
            >
              {" "}
              <p className="pr-7 font-poppins font-normal md:text-[16px] text-sm">
                Terms & Conditions
              </p>
            </a>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <a
              href={
                window.location.host === "medicare-stage.curebay.in"
                  ? "https://patients-stage.curebay.in/refund-policy"
                  : "https://www.curebay.com/refund-policy"
              }
              target="_blank"
              className="cursor-pointer"
            >
              {" "}
              <p className=" font-poppins font-normal md:text-[16px] text-sm">
                {" "}
                Return & Refund Policy
              </p>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col-reverse p-5 md:p-0  bg-footer-color  ">
        <div
          className="md:flex"
          style={{
            color: "#1A1A1A",
            fontFamily: "Open Sans",
            paddingBottom: "3%",
            fontSize: "16px",
            fontWeight: "400",
          }}
        >
          <div className="md:pl-16 md:pr-32 md:leading-10 ">
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <img className="pr-4" src={email} alt="email" id="email" />
              <p>
                <a
                  className="font-poppins font-normal text-[16px]"
                  style={{ paddingTop: 7 }}
                  href="mailto:totalcare@curebay.com"
                >
                  totalcare@curebay.com
                </a>
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img className="pr-4" src={phone} alt="phone" id="phone" />
              <p className=" font-poppins font-normal text-[16px]">
                {" "}
                +91-8335 000 999{" "}
              </p>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                className="pr-4"
                src={whatsAppLogo}
                alt="whatsAppLogo"
                id="whatsAppLogo"
              />
              <p className=" font-poppins font-normal text-[16px] ">
                {" "}
                +91-8334 000 999{" "}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                lineHeight: "26px",
                marginTop: "0.5rem",
              }}
            >
              <img
                className="pr-4 "
                src={location}
                alt="location"
                id="location"
              />
              <p
                className=" font-poppins font-normal text-[16px] "
                id="address"
              >
                <span
                  className=" font-poppins font-normal md:text-[16px] text-sm"
                  style={{ fontWeight: "bold", color: "#18406D" }}
                >
                  CureBay Technologies Pvt Ltd <br />{" "}
                </span>
                Plot No. A-98, Budha Nagar,
                <br /> Laxmisagar, Bhubaneswar, Khordha,
                <br /> Odisha 751006
              </p>
            </div>
          </div>
          <div className="md:flex md:leading-10 ">
            <div style={{ flexGrow: "3" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="font-bold font-poppins text-[16px] text-md pb-3 text-chathams-blue">
                  Services
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <p className='cursor-pointer hover:underline' onClick={() => { navigate(APP_ROUTES.ABOUTUS) }}>About Us</p> */}
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.LOCATIONSPAGE}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    Location
                  </a>
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <p className='cursor-pointer hover:underline'>our gallery</p> */}
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.DOCTORS}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    Doctors
                  </a>
                </p>
              </div>
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                                <p className='font-poppins font-normal text-[16px]'>Contact us</p>
                            </div> */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.ALLPARTNERS}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    Service Partners
                  </a>
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.REVIEWS}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    Review
                  </a>
                </p>
              </div>
            </div>
            <div className="md:mx-20" style={{ flexGrow: "3" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="font-bold font-poppins text-[16px] text-md pb-3 text-chathams-blue">
                  {" "}
                  Care Plans
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <p className='cursor-pointer hover:underline' onClick={() => { navigate(APP_ROUTES.ABOUTUS) }}>About Us</p> */}
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.GENERAL_HEALTH}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    General Health
                  </a>
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <p className='cursor-pointer hover:underline'>our gallery</p> */}
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.DIABETICS_CARE}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    Diabetes Care
                  </a>
                </p>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.HEARTHEALTH_CARE}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    Cardiac Care
                  </a>
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.WOMENSHEALTH_CARE}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    Womens' Health
                  </a>
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.ORTHOPEDICS_CARE}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    Ortho care
                  </a>
                </p>
              </div>
            </div>
            <div style={{ flexGrow: "3" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span className="font-bold font-poppins text-[16px] text-md pb-3 text-chathams-blue">
                  About
                </span>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                {/* <p className='cursor-pointer hover:underline' onClick={() => { navigate(APP_ROUTES.ABOUTUS) }}>About Us</p> */}
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.ABOUTUS}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    Explore Us
                  </a>
                </p>
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.OURTEANNEW}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    Our Team
                  </a>
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.WHYCUREBAY}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    Why CureBay
                  </a>
                </p>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="font-poppins font-normal text-[16px]">
                  <a
                    href={APP_ROUTES.MEDIA}
                    rel="noreferrer noopener"
                    className="cursor-pointer hover:underline font-poppins font-normal md:text-[16px] text-sm"
                  >
                    Article, Blogs & Press
                  </a>
                </p>
              </div>
            </div>

            <div className="md:ml-14" style={{ flexGrow: "3" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p className="font-bold font-poppins  text-[16px] text-md pb-3 text-chathams-blue">
                  {" "}
                  Consumer App
                </p>
              </div>
              {/* <div style={{ display: "flex", alignItems: "center" }}>
                                <a
                                    href={APP_ROUTES.FAQ}
                                    rel="noreferrer noopener"
                                    className='cursor-pointer hover:underline'
                                >
                                    FAQ
                                </a>
                            </div> */}
              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  href="https://www.curebay.com/medicine"
                  target="_blank"
                  className="cursor-pointer"
                >
                  <p className="font-poppins font-normal text-[16px]">
                    Order Medicine
                  </p>
                </a>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  href="https://www.curebay.com/labtests"
                  target="_blank"
                  className="cursor-pointer"
                >
                  <p className="text-md ">Book a Lab Test</p>{" "}
                </a>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  href="https://www.curebay.com/doctors"
                  target="_blank"
                  className="cursor-pointer"
                >
                  <p className="text-md ">Find Doctor </p>
                </a>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  href="https://www.curebay.com/hospital"
                  target="_blank"
                  className="cursor-pointer"
                >
                  {" "}
                  <p className="text-md ">Book a Hospital Bed </p>
                </a>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <a
                  href="https://www.curebay.com/contact-with-us"
                  target="_blank"
                  className="cursor-pointer"
                >
                  <p className="text-md ">Help & Support </p>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <img
            className="md:py-10 md:pl-16 sm:w-auto sm:h-auto "
            src={logo}
            alt="curebay"
          />

          <div className="flex justify-between px-12 md:py-6 mx-7  items-center  bg-footer-color ">
            <div className="flex ">
              <img
                src={playstore}
                alt="Play Store"
                onClick={redirectToPlayStore}
              />
              <img
                src={appstore}
                alt="App Store"
                onClick={redirectToAppStore}
              />
            </div>
          </div>
        </div>
      </div>
      {props.i !== 1 ? (
        <div className="pt-5 pb-6 flex flex-col justify-center items-center bg-white gap-3">
          <div className="font-poppins md:text-38px text-2xl font-normal flex justify-center ">
            Don’t know{" "}
            <span className="font-poppins text-newgreen pl-2">
              {" "}
              how to begin??
            </span>
          </div>
          <div className="font-medium font-poppins md:text-22px text-sm m-3 px-9 text-center leading-8">
            Feel free to contact our team at{" "}
            <span className="text-royal-blue font-semibold font-poppins">
              +91 8335 000 999{" "}
            </span>{" "}
            or email us at{" "}
            <a
              href="mailto:totalcare@curebay.com"
              className="text-royal-blue font-semibold font-poppins"
            >
              totalcare@curebay.com
            </a>{" "}
            for any assistance or guidance you may need, as we are dedicated to
            ensuring your satisfaction.
          </div>
          {/* <div className='flex pb-20 justify-center'>
                        <Button variant="outlined" sx={{ textTransform: "capitalize", fontSize: '18px', fontWeight: 600, borderRadius: '6px' }} startIcon={<img src={FAQphn} />}>+91 8335 000 999
                        </Button>
                    </div> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Footer;
