import { combineReducers } from "redux";
import HomePageReducer from "./homepagereducer";
import CarePlansReducer from "./careplansreducer";
import CommonReducer from "./commonreducer";
import AuthReducer from "./authreducer"


export default combineReducers({
    HomePageReducer: HomePageReducer,
    CarePlansReducer: CarePlansReducer,
    CommonReducer:CommonReducer,
    AuthReducer:AuthReducer
})