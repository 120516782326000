import React, { useEffect, useState } from 'react'
import { APP_ROUTES } from "../../constants/approutes";
import logoimg from "../../webflowimages/curebay_logo.png";
import Footer from '../../components/webflow/footer';
import { getMedia } from "../../store/actions/footerlinks";
import moment from 'moment/moment';
import Aos from "aos";
import 'aos/dist/aos.css'
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";

const Ourblogsnew = () => {
  const [data, setData] = useState([]);
  const [screen, setscreen] = useState(window.innerWidth);
  useEffect(() => {
    const fetchData = async () => {
      const res = await getMedia();
      console.log(res);
      setData(res?.data.data[0]);
    };

    fetchData();
  }, []);
  console.log(data, "datablog")

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  console.log(data, "datannn")
  return (
    <>


      <div className="page-wrapper">
        <>
          {/*  This site was created in Webflow. https://www.webflow.com  */}
          {/*  Last Published: Mon Nov 06 2023 10:12:51 GMT+0000 (Coordinated Universal Time)  */}
          <meta charSet="utf-8" />
          <title>Blogs - Totalcare</title>
          <meta
            content="Access to healthcare is a fundamental right! We aim to bridge this existing healthcare gap between rural and urban India. Our mission is to take quality healthcare to the last mile."
            name="description"
          />
          <meta content="Blogs - Totalcare" property="og:title" />
          <meta
            content="Access to healthcare is a fundamental right! We aim to bridge this existing healthcare gap between rural and urban India. Our mission is to take quality healthcare to the last mile."
            property="og:description"
          />
          <meta content="Blogs - Totalcare" property="twitter:title" />
          <meta
            content="Access to healthcare is a fundamental right! We aim to bridge this existing healthcare gap between rural and urban India. Our mission is to take quality healthcare to the last mile."
            property="twitter:description"
          />
          <meta property="og:type" content="website" />
          <meta content="summary_large_image" name="twitter:card" />
          <meta content="width=device-width, initial-scale=1" name="viewport" />
          <meta content="Webflow" name="generator" />
          <link href="css/normalize.css" rel="stylesheet" type="text/css" />
          <link href="css/webflow.css" rel="stylesheet" type="text/css" />
          <link
            href="css/curebay-medicare.webflow.css"
            rel="stylesheet"
            type="text/css"
          />
          <link href="https://fonts.googleapis.com" rel="preconnect" />
          <link
            href="https://fonts.gstatic.com"
            rel="preconnect"
            crossOrigin="anonymous"
          />
          <link href="images/favicon.png" rel="shortcut icon" type="image/x-icon" />
          <link href="images/webclip.png" rel="apple-touch-icon" />


          <div className="">
            <div className="bg-neutral-800">
              <div className="section hero blog-v3">
                <div className="container-default w-container">
                  <div className="z-index-1">
                    <div
                      data-w-id="2224868a-dd39-c3f5-12e2-536c7e58156c"
                      style={{
                        WebkitTransform:
                          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        MozTransform:
                          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        msTransform:
                          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        transform:
                          "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                        // opacity: 0
                      }}
                      className="text-center mg-bottom-48px"
                    >
                      <h1 className="display-1 color-neutral-100" data-aos="fade-up">
                        News &amp; Articles
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="position-relative">
              <div className="container-default z-index-1 w-container" />
              <div className="position-absolute rectangle-top-bg section-cta" />
            </div>
            <div className="overflow-hidden">
              <div className="section top-100px bottom-150px">
                <div className="container-default w-container">
                  {/* <div className="mg-bottom-40px">
            <div
              data-w-id="410786b2-a56f-3df2-4ceb-818f816ca13a"
              style={{
                WebkitTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                MozTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                msTransform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                transform:
                  "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                // opacity: 0
              }}
              className="heading-flex"
            >
              <h2 className="display-2 mg-bottom-0">Latest articles</h2>
              <div className="width-100-mbp w-dyn-list">
                <div
                  role="list"
                  className="categories-badges-wrapper w-dyn-items"
                >
                  <div
                    role="listitem"
                    className="categories-badges-item-wrapper w-dyn-item"
                  >
                    <a
                      href="blogs.html"
                      aria-current="page"
                      className="badge-secondary category-badges w-inline-block w--current"
                    >
                      <div>All</div>
                    </a>
                    <a
                      href="#"
                      className="badge-secondary category-badges w-inline-block"
                    >
                      <div />
                    </a>
                  </div>
                </div>
                <div className="empty-state w-dyn-empty">
                  <div>No items found.</div>
                </div>
              </div>
            </div>
          </div> */}
                  <div
                    data-w-id="4dc9dc44-3572-032d-6958-2b2bfb14bf7e"
                    // style={{
                    //   WebkitTransform:
                    //     "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    //   MozTransform:
                    //     "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    //   msTransform:
                    //     "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
                    //   transform:
                    //     "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",

                    // }}
                    className="w-dyn-list mt-20"
                  >
                    <div
                      role="list"
                      className="sm:grid-cols-3 grid-cols-1 gap-row-40px w-dyn-items gap-5"
                    >
                      {data?.attributes?.frstComp?.images.map((value) => (
                        <div
                          id="w-node-_4dc9dc44-3572-032d-6958-2b2bfb14bf80-4d0e8dc0"
                          role="listitem"
                          className="flex w-dyn-item relative overflow-hidden  listitemcard"
                        >
                          <a
                            data-w-id="4dc9dc44-3572-032d-6958-2b2bfb14bf81" data-aos="fade-up"
                            href="#"
                            className="card blog-v3 w-inline-block"
                          >
                            <div
                              id="w-node-_4dc9dc44-3572-032d-6958-2b2bfb14bf82-4d0e8dc0"
                              className="card-picture-wrapper"
                            >
                              <img src={value?.label}
                                alt={value?.label} className="card-image w-full h-full transition-transform transform scale-100 hover:scale-95" />
                            </div>
                            <div className="card-description blog-v3-description">
                              <h3 className="card-title-dark display-4 mg-bottom-16px"> {value?.title}</h3>
                              <p className="color-neutral-600 mg-bottom-24px" />
                              <div className="card-details-flex-container blog-v3">
                                <div className="card-details-flex-container">
                                  <div className="badge-primary" >{value?.articleornews}</div>
                                  <div className="text-200 medium color-neutral-800" >{moment(value?.Date).format('MMMM D, YYYY')}</div>
                                </div>
                                <a href={value.label_url} target="_blank">
                                  <div className="card-link color-neutral-600">

                                    <div className="link-text">Read more</div>
                                    <div className="line-rounded-icon card-arrow-right">
                                      
                                    </div>

                                  </div>
                                </a>
                              </div>
                            </div>
                          </a>
                        </div>
                      ))}
                    </div>

                    {/* <div
              role="navigation"
              aria-label="List"
              className="w-pagination-wrapper pagination"
            >
              <a
                data-w-id="4dc9dc44-3572-032d-6958-2b2bfb14bf94"
                href="#"
                aria-label="Previous Page"
                className="w-pagination-previous btn-secondary btn-pagination"
              >
                <div className="line-rounded-icon link-icon-left icon-size-16px w-inline-block">
                  
                </div>
                <div className="w-inline-block">Previous</div>
              </a>
              <a
                data-w-id="4dc9dc44-3572-032d-6958-2b2bfb14bf99"
                href="#"
                aria-label="Next Page"
                className="w-pagination-next btn-primary btn-pagination"
              >
                <div className="line-rounded-icon link-icon-right icon-size-16px w-inline-block">
                  
                </div>
              </a>
            </div> */}
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="loading-page-wrapper">
            <div
              data-w-id="2dd5b5d8-39b9-6eca-68a7-4bfd9aee24d8"
              className="loading-circle"
            />
          </div>
        </>

        {screen > 500 ? (
          <Footer />
        ) : (
          <>
            <FooterMobileVersion />
            <MobileFooter />
          </>
        )}


      </div>


    </>

  );

};
export default Ourblogsnew;


