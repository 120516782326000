import footerlinksServices from "../../services/footerlinksServices";
export const getWhyCurebay = async () => {
    const res = await footerlinksServices.getWhyCurebay();
    return res;
};
export const getOurteam = async () => {
    const res = await footerlinksServices.getOurteam();
    return res;
};
export const getMedia = async () => {
    const res = await footerlinksServices.getMedia();
    return res;
};

