import React, { useState, useEffect } from "react";
import login from "./image/Login image.png";
// import Footer from "../homepage/footer";
import { Card, Typography, Button } from "@mui/material";
import logo from "../../assets/images/Web Logo.png";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate } from "react-router";
import { checkOtp, fetchOtp, setNewPass } from "../../store/actions/forgotPass";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useRef } from "react";
import { getToken } from "../../store/actions/useractions";
import { useDispatch } from "react-redux";
import MobileFooter from "./../homepage/mobileFooter";
import loginmob from "../../assets/images/loginmob.png";
import { useLocation } from "react-router-dom";
import Footer from '../../components/webflow/postloginfooter';
import { useSnackbar } from "../common/snackbar";
import 'aos/dist/aos.css';
import Aos from "aos";


function InputWithIcon({ type, onChange, onKeyDown }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative">
      <input
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        onChange={onChange}
        onKeyDown={onKeyDown}
        required
        className="w-full py-2 pr-10 pl-3 border rounded-[132px] shadow-sm focus:outline-none focus:bg-password-gray  sm:text-sm "
      />
      {type === "password" && (
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          {showPassword ? (
            <VisibilityIcon className="h-5 w-5" />
          ) : (
            <VisibilityOffIcon className="h-5 w-5" />
          )}
        </button>
      )}
    </div>
  );
}

const Index = () => {
  const location = useLocation();
  const emailid = location.state?.customData || null;
  const navigate = useNavigate();
  const [inputValue, setInputValue] = useState(emailid);
  const [form, setForm] = useState("");
  const [userData, setUserData] = useState();
  const [passPage, setPassPage] = useState(false);
  const [password, setPassword] = useState("");
  const [cnfrmPassword, setCnfrmPassword] = useState("");
  const [passSuccess, setPassSuccess] = useState();
  const [isSent, setSent] = useState(false);
  const [err, setErr] = useState("");
  const [seconds, setTimer] = useState(60);
  const [OTPrec, setOTPrec] = useState({
    otp01: "",
    otp02: "",
    otp03: "",
    otp04: "",
    otp05: "",
    otp06: "",
  });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^[6-9]\d{9}$/;

  const [screen, setscreen] = useState(window.innerWidth);
  const [validateMessage, setValidateMessage] = useState("");
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const inputs = useRef([]);

  const handleNextPhone = () => {
    let otpvalues = Object.values(OTPrec);
    let sum = otpvalues.reduce(add);
    function add(accumulator, a) {
      return accumulator + a;
    }
    if (sum.length === 6) {
      async function fetchOTP() {
        const otpVerifiedResponse = await checkOtp({
          otp: sum,
        });

        if (
          otpVerifiedResponse.status === 200 &&
          otpVerifiedResponse?.data?.succesObject
        ) {
          setUserData(otpVerifiedResponse?.data?.succesObject);
          setPassPage(true);
        } else {
          setErr(otpVerifiedResponse?.data?.responseMessage);
        }
      }
      fetchOTP();
    }
  };

  const onOTPChange = (index, event) => {
    let data = { ...OTPrec };
    const { id, value } = event.target;
    data[id] = value;
    setOTPrec(data);
    const otp = event.target.value;
    if (otp.match(/^[0-9]$/)) {
      // If a valid number is entered, move focus to the next input field
      if (index < inputs.current.length - 1) {
        inputs.current[index + 1].focus();
      }
    } else if (otp === "") {
      // If backspace is pressed, move focus to the previous input field
      if (index > 0) {
        inputs.current[index - 1].focus();
      }
    }
  };

  const handlePassUpdate = async () => {
    async function updatePassword() {
      const passWord = await setNewPass({
        userCode: userData.userCode,
        patientCode: userData.patientCode,
        userId: userData.userId,
        passWord: password,
        confirmPassWord: cnfrmPassword,
      });
      if (passWord?.status == 200 && passWord) {
        setSnackbarMessage("Password changed successfully", "success");
        setPassSuccess(passWord);
        setTimeout(() => {
          navigate(APP_ROUTES.LOGIN);
        }, 2000);
      }
    }
    updatePassword();
  };

  const setSeconds = async () => {
    setTimer(60);
    async function fetchOTP() {
      const otp = await fetchOtp(
        form === "phone"
          ? {
            mobileNo: inputValue,
            email: null,
          }
          : form === "email"
            ? {
              mobileNo: null,
              email: inputValue,
            }
            : {
              mobileNo: null,
              email: null,
            }
      );
      if (otp.status == 200) {
        // setOTP(otp?.data?.data);
        setSent(true);
      } else {
        setSent(false);
      }
    }
    fetchOTP();
  };

  useEffect(() => {
    if (isSent) {
      if (!seconds) return;
      const interval = setInterval(() => {
        const newTime = seconds - 1;
        setTimer(newTime);
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, isSent]);

  const checkInput = () => {
    if (emailRegex.test(inputValue)) {
      setForm("email");
    } else if (phoneRegex.test(inputValue)) {
      setForm("phone");
    } else if (/^\d+$/.test(inputValue)) {
      setValidateMessage("Please enter a valid number");
    } else if (/^[a-zA-Z]+$/.test(inputValue)) {
      setValidateMessage("Please enter a valid email");
    } else {
      setValidateMessage("Please enter a valid credentials");
    }
  };

  useEffect(() => {
    if (form === "phone" || form === "email") {
      async function fetchOTP() {
        const otp = await fetchOtp(
          form === "phone"
            ? {
              mobileNo: inputValue,
              email: null,
            }
            : form === "email"
              ? {
                mobileNo: null,
                email: inputValue,
              }
              : {
                mobileNo: null,
                email: null,
              }
        );
        if (otp?.data?.responseCode === "200") {
          setSent(true);
        } else if (otp?.data?.responseCode === "500") {
          setErr(
            "Email not found. Please sign up or contact support for assistance."
          );
        }
      }
      fetchOTP();
    }
  }, [form]);

  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])


  return (
    <div className="backgroundimgdiv select-none xs-overflow-x-hidden  md:absolute md:top-10 w-full">
      {snackbarMessage}
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          maxWidth: "1400px",
          flexDirection: "column",
          // backgroundImage: `linear-gradient(175.92deg, rgba(34, 68, 119, 0.47) 6.62%,rgba(57, 97, 158, 0.06) 92.88%)`,
        }}
      >
        <div className="w-full flex flex-col items-center pt-10">
          <h1
            className="text-center sm:text-[35px] text-3xl pl-5 sm:pl-0 pr-5 sm:pr-0 leading-10 sm:leading-none font-poppins font-bold sm:mt-10 tracking-normal text-white sm:text-[#0B4881]
          
          sm:pt-10 pt-12"
          >
            Protect the hands that raised you
          </h1>
          <p className="text-white sm:text-[#0B4881] text-center sm:leading-8 sm:text-[20px] text-sm  font-poppins font-normal sm:mb-0 mb-5 hidden sm:block">
            Round the clock access to our advanced health care solutions
            <br></br> Curated for the unique needs of the elderly
          </p>
        </div>
        <div className="flex sm:justify-end justify-center sm:pr-44 sm:py-16 pr-4 pl-4 ht-unset pb-div-btm-20 mt-12 sm:mt-0">
          {!passPage ? (
            <div>
              {form == "phone" || form == "email" ? (
                <Card
                  className="sm:w-[400px] w-[363px]"
                  sx={{
                    // width: "363px",
                    height: "450px",
                    borderColor: "#396DDD52",
                    borderWidth: "0.5px",
                    borderRadius: "20px",
                    backgroundColor: "#F4F8FE"
                  }}
                >
                  <div className="grid justify-center sm:px-8 px-4">
                    <img
                      className="px-20 pt-4 h-auto"
                      src={logo}
                      alt="curebay"
                    ></img>
                    <Typography
                      sx={{
                        fontSize: "22px",
                        fontWeight: 700,
                        fontFamily: "sans-serif",
                        paddingTop: "35px",
                        color: "#0B4881",
                      }}
                    >
                      Forgot Password
                    </Typography>

                    <div className="grid ">
                      <label className=" text-brand-lightBlack pl-2 pt-[25px]">
                        {form == "phone" ? (
                          <p>Mobile No</p>
                        ) : form == "email" ? (
                          <p>Email</p>
                        ) : (
                          <></>
                        )}
                      </label>
                      <input
                        className="focus:bg-password-gray px-2 border h-10 focus:outline-none rounded-[132px]"
                        type="text"
                        value={inputValue}
                      ></input>
                    </div>
                    <div className="grid pt-2 pb-3">
                      {/* <label className=" text-brand-lightBlack pl-1 pt-1 ">
                                                {" "}
                                                <Button variant="text" onClick={sendOtp}>Send Otp</Button>

                                            </label> */}
                      <div className="flex pt-7">
                        {!err
                          ? [0, 1, 2, 3, 4, 5].map((index) => (
                            <input
                              key={index}
                              className="border-2 m-2 border-chathams-blue-50 sm:w-12 w-8 rounded-md text-center sm:h-12 h-8"
                              id={`otp${index}`}
                              type="text"
                              maxLength="1"
                              min="0"
                              max="9"
                              onChange={(e) => onOTPChange(index, e)}
                              onKeyDown={(e) => {
                                console.log(e.key);
                                if (e.key === "Enter") {
                                  handleNextPhone();
                                }
                              }}
                              ref={(input) => (inputs.current[index] = input)}
                            />
                          ))
                          : null}
                      </div>
                    </div>
                    {!err && (
                      <span
                        onClick={() => {
                          // seconds <= 0 &&
                          setSeconds();
                        }}
                        className={`${seconds === 0
                          ? "text-brand-secondary cursor-pointer "
                          : "text-gray-secondary cursor-not-allowed "
                          } text-blue-500 text-lg font-bold font-sans ml-auto capitalize cursor-pointer"`}
                      >
                        {`Resend ${seconds > 0 ? "in 00:" + seconds : ""}`}{" "}
                      </span>
                    )}
                    {err && (
                      <span
                        className={`${err === "OTP sent"
                          ? "text-green-500 "
                          : "text-red-600 "
                          }  font-normal text-sm  tracking-widest`}
                      >
                        {err}
                      </span>
                    )}

                    <button
                      variant="contained"
                      className="bg-[#004171] focus:outline-none px-2 border h-10 rounded-[132px] w-full text-white"
                      onClick={(e) => {
                        handleNextPhone();
                      }}
                    >
                      Next
                    </button>
                  </div>
                </Card>
              ) : (
                <Card
                  sx={{
                    width: "340px",
                    height: "400px",
                    borderColor: "#396DDD52",
                    borderWidth: "0.5px",
                    borderRadius: "20px",
                    backgroundColor: "#F4F8FE"
                  }}
                >
                  <div className="grid justify-center px-8 ">
                    <img
                      className="px-14 pt-4 h-auto"
                      src={logo}
                      alt="curebay"
                    ></img>
                    <Typography
                      sx={{
                        fontSize: "23px",
                        fontWeight: 700,
                        fontFamily: "poppins",
                        paddingTop: "1rem",
                        paddingBottom: "1rem",
                        color: "#0B4881",
                      }}
                    >
                      Forgot Password
                    </Typography>

                    <div className="grid pb-6 mt-20">
                      <label className=" text-brand-lightBlack font-poppins pb-1 text-[14px] ">
                        {" "}
                        Email
                      </label>
                      <input
                        className="focus:bg-password-gray focus:outline-none px-2 border h-10 rounded-[132px]"
                        type="text"
                        value={inputValue}
                        onKeyDown={(e) => {
                          console.log(e.key);
                          if (e.key === "Enter") {
                            checkInput();
                          }
                        }}
                        onChange={handleInputChange}
                      ></input>

                      {validateMessage && (
                        <span
                          className={
                            "text-red-600  font-normal text-xs  tracking-widest"
                          }
                        >
                          {validateMessage}
                        </span>
                      )}
                    </div>
                    <button
                      className="bg-[#004171] focus:outline-none px-2 border h-10 rounded-[132px] w-full text-white"

                      variant="contained"
                      onClick={checkInput}
                    >
                      Next
                    </button>

                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: 700,
                        fontFamily: "sans-serif",
                        paddingTop: "2rem",
                        textAlign: "center",
                      }}
                    >
                      Remember Password?
                      <span
                        className="text-[#215DAB] cursor-pointer "
                        onClick={() => {
                          navigate(APP_ROUTES.LOGIN);
                        }}
                      >
                        Log in
                      </span>
                    </Typography>
                  </div>
                </Card>
              )}
            </div>
          ) : (
            <Card
              sx={{
                borderColor: "#396DDD52",
                borderWidth: "0.5px",
                borderRadius: "20px",
                height: "107%",
                width: "400px",
                backgroundColor: "#F4F8FE"
              }}
            >
              <div className="grid justify-center px-8   ">
                <img
                  className="px-20 pt-2 h-auto"
                  src={logo}
                  alt="curebay"
                ></img>
                <Typography
                  sx={{
                    fontSize: "25px",
                    fontWeight: 700,
                    fontFamily: "sans-serif",
                    paddingTop: "1rem",
                    color: "#0B4881",
                  }}
                >
                  Forgot Password
                </Typography>
                {/* <div className="grid pt-1">
                                <label className=" text-brand-lightBlack pl-1 pt-3 pb-1">
                                    {" "}
                                    Email
                                </label>
                                <input className="focus:bg-password-gray px-2 focus:outline-none border h-10 rounded-sm" type="text" onChange={handleInputChange}></input>
                            </div> */}
                <div className="grid py-1">
                  <label className=" text-brand-lightBlack pl-1 pt-2 pb-1 text-[14px]">
                    {" "}
                    New Password
                  </label>
                  <InputWithIcon
                    type="password"
                    onChange={(e) => setPassword(e?.target?.value)}
                  />
                </div>
                <div className="grid py-1">
                  <label className=" text-brand-lightBlack pl-1 pt-2 pb-1 text-[14px]">
                    {" "}
                    Confirm Password
                  </label>
                  <InputWithIcon
                    type="password"
                    onChange={(e) => setCnfrmPassword(e?.target?.value)}
                    onKeyDown={(e) => {
                      console.log(e.key);
                      if (e.key === "Enter") {
                        handlePassUpdate();
                      }
                    }}
                  />
                </div>

                <button

                  className="bg-[#004171] focus:outline-none px-2 border h-10 rounded-[132px] w-full text-white mt-[27px]"
                  variant="contained"
                  onClick={handlePassUpdate}
                >
                  Next
                </button>
                <Typography
                  sx={{
                    fontSize: "16px",
                    fontWeight: 700,
                    fontFamily: "sans-serif",
                    paddingTop: "2rem",
                    textAlign: "center",
                  }}
                >
                  Already have an account?
                  <span
                    className="text-deep-blue cursor-pointer hover:text-light "
                    onClick={() => navigate(APP_ROUTES.LOGIN)}
                  >
                    Log In
                  </span>
                </Typography>
              </div>
            </Card>
          )}
        </div>
      </div>
      <img
        className="w-full sm:h-[920px] h-full sm:block hidden "
        src={login}
        alt={login}
        id="login"
        style={{}}
      />
      <img
        className="w-full h-screen sm:hidden block"
        src={loginmob}
        alt={login}
        id="login"
      />

      <div className="hidden sm:block">
        {/* {screen > 500 ? <Footer i={1} /> : <MobileFooter />} */}
        {/* <Footer i={1} /> */}
        <Footer />
      </div>
    </div>
  );
};

export default Index;
