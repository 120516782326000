// import React, { useEffect, useState } from "react";
// import logoimg from "../../webflowimages/curebay_logo.png"
// import Footer from '../../components/webflow/footer';

// import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate } from "react-router-dom";
import Footer from '../../components/webflow/footer';
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import { getPlans } from "../../store/actions/allfiveplans";
import Aos from "aos";
import 'aos/dist/aos.css';
import {
    healthCareService,
} from "../../store/actions/careplans";




const Careplannew = () => {
    const [generalHealthContent, setGeneralHealthContent] = useState([]);
    const [cardiacHealthContent, setCardiacHealthContent] = useState([]);
    const [diabeticHealthContent, setDiabeticHealthContent] = useState([]);
    const [orthopedicHealthContent, setOrthopedicHealthContent] = useState([]);
    const [Womencarecontent, setWomencarecontent] = useState([]);
    const [reviewsData, setReviewsData] = useState([]);
    const [screen, setscreen] = useState(window.innerWidth);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            const reviewResponse = await getPlans();
            if (
                reviewResponse.status === 200 &&
                Array.isArray(reviewResponse?.data?.data)
            ) {
                setReviewsData(reviewResponse.data.data[0]);
                // nloop = (reviewResponse?.data?.data[0]?.reviewsData?.attributes?.ThirdComponent?.video_content?.length)
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])





    const onReadMoreClick = (healthPackageId) => {
        const numberhealthPackageId = Number(healthPackageId);
        console.log(healthPackageId, "hbhjb");

        switch (numberhealthPackageId) {
            case 1:
                navigate(APP_ROUTES.GENERAL_HEALTH);
                break;
            case 4:
                navigate(APP_ROUTES.CANCERSCREENING_CARE);
                break;
            case 3:
                navigate(APP_ROUTES.DIABETICS_CARE);
                break;
            case 5:
                navigate(APP_ROUTES.ORTHOPEDICS_CARE);
                break;
            case 6:
                navigate(APP_ROUTES.WOMENSHEALTH_CARE);
                break;
            default:
                break;
        }

        // Scroll to the top of the page
        window.scrollTo(0, 0);
    };



    useEffect(() => {
        async function fetchHealthPlan() {
            const response = await healthCareService();
            if (response?.data?.data.length > 0) {
                setGeneralHealthContent(response?.data?.data?.filter((e) => e?.id === 1)?.[0]);
                setCardiacHealthContent(response?.data?.data?.filter((e) => e?.id === 6)?.[0]);
                setDiabeticHealthContent(response?.data?.data?.filter((e) => e?.id === 3)?.[0]);
                setOrthopedicHealthContent(response?.data?.data?.filter((e) => e?.id === 5)?.[0]);
                setWomencarecontent(response?.data?.data?.filter((e) => e?.id === 4)?.[0]);
            }
        }

        fetchHealthPlan();
    }, []);
    console.log(reviewsData?.attributes?.secnd_comp?.content, "jhjk")
    return (
        <>

            <div className="page-wrapper">
                <div className="position-relative">
                    <div className="section hero packages">
                        <div className="container-default w-container">
                            <div >
                                <div data-w-id="69bbc6b3-5849-58ac-59b8-8e2e28d2cfef"
                                    // style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}}

                                    className="inner-container _518px center">
                                    <div className="text-center mg-bottom-48px">
                                        <h1 className="display-1 color-neutral-100" data-aos="slide-up">Plans &amp; Pricing</h1>
                                    </div>
                                </div>
                                <div className="inner-container _1128px center">
                                    <div data-w-id="177e64ef-c391-49c7-1f62-9543a831a929"
                                        //   style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}}

                                        className="w-dyn-list">
                                        <div role="list" className="grid-1-column gap-row-32px w-dyn-items" >

                                            {reviewsData?.attributes?.secnd_comp?.content?.map(
                                                (value, index) => (
                                                    <div role="listitem" className="w-dyn-item" data-aos="slide-up">
                                                        <div className="card package-card">
                                                            <div className="card-description package-card-left">
                                                                <h2 className="display-2 mg-bottom-16px">  {value?.name}</h2>
                                                                {/* <h2 data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_price_%22%2C%22to%22%3A%22innerHTML%22%7D%5D"
                                                            className="subtitle">$&nbsp;69.00&nbsp;USD</h2> */}
                                                                <div className="inner-container _516px _100-tablet">
                                                                    <p className="color-neutral-600 mg-bottom-24px mg-bottom-16px-tablet"> {value?.heading}</p>
                                                                </div>
                                                                <div className="text-left">
                                                                    {value.healthPackageId == 1 ?
                                                                        <>
                                                                            {generalHealthContent?.attributes?.SecondComp?.points?.map((rec, index) => (
                                                                                <ul role="list"><li style={{ listStyle: "disc" }}>{rec?.points}</li></ul>
                                                                            ))}
                                                                        </> : ""}
                                                                    {value.healthPackageId == 4 ?
                                                                        <>
                                                                            {cardiacHealthContent?.attributes?.SecondComp?.points?.map((rec, index) => (
                                                                                <ul role="list"><li style={{ listStyle: "disc" }}>{rec?.points}</li></ul>
                                                                            ))}
                                                                        </> : ""}
                                                                    {value.healthPackageId == 3 ?
                                                                        <>
                                                                            {diabeticHealthContent?.attributes?.SecondComp?.points?.map((rec, index) => (
                                                                                <ul role="list"><li style={{ listStyle: "disc" }}>{rec?.points}</li></ul>
                                                                            ))}
                                                                        </> : ""}
                                                                    {value.healthPackageId == 5 ?
                                                                        <>
                                                                            {orthopedicHealthContent?.attributes?.SecondComp?.points?.map((rec, index) => (
                                                                                <ul role="list"><li style={{ listStyle: "disc" }}>{rec?.points}</li></ul>
                                                                            ))}
                                                                        </> : ""}
                                                                    {value.healthPackageId == 6 ?
                                                                        <>
                                                                            {Womencarecontent?.attributes?.SecondComp?.points?.map((rec, index) => (
                                                                                <ul role="list"><li style={{ listStyle: "disc" }}>{rec?.points}</li></ul>
                                                                            ))}
                                                                        </> : ""}
                                                                </div>
                                                            </div>
                                                            <div className="div-block-5">
                                                                <img alt="" loading="lazy" width="461"
                                                                    data-wf-sku-bindings="%5B%7B%22from%22%3A%22f_main_image_4dr%22%2C%22to%22%3A%22src%22%7D%5D"
                                                                    src={value?.image_url}
                                                                    //src="https://assets-global.website-files.com/6537aa75a5e08a5f4d0e8d54/653803efece9c66131fef427_Frame%2011.png"
                                                                    sizes="(max-width: 479px) 82vw, (max-width: 767px) 78vw, 461px"
                                                                    // srcset="https://assets-global.website-files.com/6537aa75a5e08a5f4d0e8d54/653803efece9c66131fef427_Frame%2011-p-500.png 500w, https://assets-global.website-files.com/6537aa75a5e08a5f4d0e8d54/653803efece9c66131fef427_Frame%2011.png 550w"
                                                                    className="image-7" />
                                                                <button onClick={() => {
                                                                    onReadMoreClick(value?.healthPackageId);
                                                                }}
                                                                    className="btn-primary w-button sm:mt-[50px]">Explore More<span className="line-rounded-icon link-icon-right"></span></button>

                                                            </div>
                                                        </div>



                                                    </div>
                                                )
                                            )}


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="half-bg-top packages-bg-top" />
                </div>
                {screen > 500 ? (
                    <Footer />
                ) : (
                    <>
                        <FooterMobileVersion />
                        <MobileFooter />
                    </>
                )}
            </div>

        </>
    );
};

export default Careplannew;
