import React, { useEffect, useState } from "react";
import {
  getHomePageContent,
  getHomePageImage,
  getHomePageIntegrative,
} from "../../store/actions/homepage";
import Footer from "../homepage/footer";
import FAQ from "../common/faq";
import Count_patients from "../../assets/images/count_patients.svg";
import Count_doctor from "../../assets/images/count_doctor.svg";
import Count_specialities from "../../assets/images/count_specialities.svg";
import PartnerHodpitals from "../../assets/images/Partner Hodpitals.svg";
import Pincodecovered from "../../assets/images/Pin code icon.svg";
import prescriptionsserved from "../../assets/images/prescriptions served.svg";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { useDispatch, useSelector } from "react-redux";
// import MediaControlCard from '../testPage01';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  IconButton as MuiIconButton,
  CardHeader,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import styled from "@emotion/styled";
import {
  getOurCareplans,
  getOurServices,
  getRecordsData,
  getSmartToolsData,
  getOurAims,
} from "../../store/actions/howitworks";
import services from "../../store/services";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate } from "react-router-dom";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import { updateCart } from "../../store/actions/login";
import loginservice from "../../store/services/loginservice";
import { COMMONCONSTANTS } from "../../store/constants/commonconstants";
import { useSnackbar } from "../common/snackbar";

const Item = styled("div")(({ theme }) => ({
  backgroundColor: "white",
}));

const HowItWorks = () => {
  const [position, setPosition] = React.useState(0);

  const homePageContent = useSelector(
    (state) => state.HomePageReducer.homePageContent
  );
  const curebayExpContent = useSelector(
    (state) => state.HomePageReducer.curebayExpContent
  );
  console.log(curebayExpContent, "ok");

  const IconButton = styled(MuiIconButton)({
    backgroundColor: "white",
    borderColor: "#66B889 !important",
    borderWidth: "1px !important",
    border: "solid",
    color: "#66B889",
    "&:hover": {
      backgroundColor: "#66B889",
      color: "white",
    },
  });

  const navigate = useNavigate();
  const [loggedin, setLoggedIn] = useState(false);
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
      ? userObj.parentCode
      : null;
  const [screen, setscreen] = useState(window.innerWidth);
  const userData = useSelector((state) => state.AuthReducer.userData);
  const [show, setShow] = useState(true);
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  console.log(userCode);
  useEffect(() => {
    if (userCode) setLoggedIn(true);
  }, [userCode]);
  const handleAddCart = () => {
    if (!loggedin) navigate(APP_ROUTES.LOGIN);
  };

  const dispatch = useDispatch();

  const [smartTools, setSmartTools] = useState([]);
  const [records, setRecords] = useState([]);
  const [ourServices, setOurServices] = useState([]);
  const [data, setData] = useState([]);

  const [ourAim, setOurAim] = useState([]);

  useEffect(() => {
    dispatch(getHomePageImage());
    dispatch(getHomePageContent());
    dispatch(getHomePageIntegrative());
    getAllData();
  }, [dispatch]);

  const getAllData = async () => {
    const res = await services.get("how-it-works-pages?populate=deep");
    setData(res?.data?.data[0].attributes);
  };
  useEffect(() => {
    async function fetchData() {
      const smartToolsResponse = await getSmartToolsData();
      // if (smartToolsResponse.status === 200 && smartToolsResponse?.data) {
      //     setSmartTools(smartToolsResponse.data);
      // };
      const recordsResponse = await getRecordsData();
      if (
        recordsResponse &&
        recordsResponse.status === 200 &&
        recordsResponse?.data
      ) {
        setRecords(recordsResponse.data?.data);
      }
      // const ourServicesResponse = await getOurServices();
      // if (ourServicesResponse.status === 200 && ourServicesResponse?.data?.data) {
      //     setOurServices(ourServicesResponse.data.data);
      // };

      const ourAimResponse = await getOurAims();
      if (ourAimResponse.status === 200 && ourAimResponse?.data?.data) {
        setOurAim(ourAimResponse.data.data);
      }
    }
    getOurAims();
    fetchData();
  }, []);

  console.log(data);

  const borderColors = {
    0: "#0B4881",
    1: "#64B789",
    2: "#0B4881",
    3: "#0B4881",
    4: "#64B789",
    5: "#0B4881",
  };

  const handlleScroll = (e, dir) => {
    if (
      dir === "rightArrow" &&
      position <
      curebayExpContent?.data[0]?.attributes?.FourthComp?.careplans?.length -
      1
    ) {
      const temp = position + 1;
      setPosition(temp);
    } else if (dir === "leftArrow" && position > 0) {
      const temp = position - 1;
      setPosition(temp);
    }
  };

  const addToCart = async (e, period) => {
    if (!loggedin) {
      const data = {
        prop1: e.name,
        prop2: e.refernce_id,
        prop3: period === "monthly" || period === "yearly" ? period : undefined,
      };
      // navigate(APP_ROUTES.LOGIN)
      navigate(APP_ROUTES.LOGIN, { state: data });
    } else {
      let data = JSON.parse(userData);
      let payload = {
        userCode: userCode,
        cartType: 1,
        cartVOList: [
          {
            userId: "",
            userCode: userCode,
            planId: e.refernce_id,
            planName: e.name,
            period: period === "monthly" || period === "yearly" ? period : undefined,
          },
        ],
      };
      console.log(payload, "dataaaaa");
      const res = await updateCart(payload);
      if (res?.status === 200 && res?.data?.responseCode) {
        let userData = {
          userCode: data?.[0]?.userCode,
        };
        loginservice
          .cartList(userData)
          .then((res) => {
            dispatch({
              type: COMMONCONSTANTS.SUCCESS_FETCHCART,
              payload: res?.data?.succesObject,
            });
          })
          .catch((error) => { });
        setSnackbarMessage("Added to the cart", "success");
      }
    }
  };

  useEffect(() => {
    if (userCode) setLoggedIn(true);
  }, [userCode]);
  const navigateToPlans = async () => {
    if (!loggedin) {
      navigate(APP_ROUTES.AllPlans);
    } else {
      navigate(APP_ROUTES.BUYCAREPLAN);
    }
  };

  return (
    <div className="select-none xs-overflow-x-hidden  md:absolute md:top-10 w-full md:w-[87.5rem]">
      {snackbarMessage}
      <div className="block md:hidden">
        <div
          className="bg-position-right"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            backgroundImage: `url('${curebayExpContent?.data[0]?.attributes?.firstcomp?.image_url}')`,
            backgroundSize: "contain",
            width: "100%",
            height: "200px",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      <div
        className="  xs-bg-white flex flex-col bg-white"
        style={{
          backgroundSize: "contain",

          // height: '386px',
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
          backgroundImage: `url('${curebayExpContent?.data[0]?.attributes?.firstcomp?.image_url}')`,
        }}
      >
        <div className="md:w-10/12 md:pl-8 pt-5  md:pt-36  mx-10">
          <h1>
            <span className="md:text-40px text-[22px] font-semibold md:leading-54px  md:w-4/12 text-newblue font-poppins">
              {curebayExpContent?.data[0]?.attributes?.firstcomp?.title?.substring(
                0,
                14
              )}
            </span>
            <br></br>

            <span className="font-light md:text-40px text-[20px] md:leading-54px font-poppins text-newblue md:w-8/12 md:pb-7 ">
              {" "}
              {curebayExpContent?.data[0]?.attributes?.firstcomp?.title?.substring(
                14,
                curebayExpContent?.data[0]?.attributes?.firstcomp?.title?.length
              )}
            </span>
          </h1>
          <p className="font-normal text-[16px]  leading-[30.5px] font-poppins md:w-6/12 md:pb-4 ">
            {curebayExpContent?.data[0]?.attributes?.firstcomp?.description}
          </p>
        </div>

        <div className="md:flex flex-col px-8 md:py-4 bg-[#F0FBFFAD] sm:mt-12 mt-0">
          <p className="md:text-[40px] pt-10 md:pt-0 text-2xl font-poppins font-[300]  md:h-12 ">
            {curebayExpContent?.data[0]?.attributes?.FifthComp?.title?.substring(
              0,
              4
            )}
          </p>
          <div className="md:flex md:w-full">
            <p className="md:text-[53px] text-2xl font-medium text-newgreen md:w-[80rem] ">
              {curebayExpContent?.data[0]?.attributes?.FifthComp?.title?.substring(
                4,
                curebayExpContent?.data[0]?.attributes?.FifthComp?.title?.length
              )}
            </p>
            <div className="pb-5 md:pb-0 w-full flex flex-col justify-center align-middle ">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
          <p className="md:text-[20px] text-16px leading-[30.5px] font-poppins font-normal md:pt-8">
            {curebayExpContent?.data[0]?.attributes?.FifthComp?.description}
          </p>
        </div>
        <div>
          {curebayExpContent?.data[0]?.attributes?.FifthComp?.content?.map(
            (value, i) => (
              <>
                {i % 2 == 0 ? (
                  <div className="md:flex  justify-center md:gap-24 items-center md:py-12 py-10 bg-[#F0FBFFAD]">
                    <div className="flex md:gap-10 gap-2 px-8 sm:px-0">
                      <img
                        src={value?.image_url1}
                        className="sm:xs-h-50 xs-h-34 sm:w-full w-2/4 md:px-4 px-2 "
                      />
                      {value?.image_url2 ? (
                        <img
                          src={value?.image_url2}
                          className="sm:xs-h-50 xs-h-34 sm:w-full w-2/4"
                        />
                      ) : (
                        <></>
                      )}
                    </div>

                    <div className="md:w-[315px] md:h-[150px] md:py-0 py-2">
                      <div className="flex justify-start  items-center px-8 md:my-3 sm:mt-0 mt-[30px]">
                        <img src={value?.icon_url}></img>
                        <hp className="md:ml-3 text-[#0272DA] font-medium font-poppins md:text-[20px] text-xl">
                          {value?.name}
                        </hp>
                      </div>
                      <p className="font-poppins md:text-[21px] text-xl font-bold  px-8 mb-3">
                        {value?.title}
                      </p>
                      <p className="font-poppins md:text-[16px] ml-8 text-16px leading-[30.5px] font-normal">
                        {value?.description}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="flex md:flex-row flex-col-reverse   justify-center md:gap-24 items-center sm:py-0 py-5 md:py-12 bg-[#CBF1FFAD]">
                    <div className="md:w-[315px] md:h-[150px]">
                      <div className="flex justify-start  items-center mx-8 my-3">
                        <img src={value?.icon_url}></img>
                        <p className="md:ml-3 text-[#0272DA] font-medium font-poppins md:text-[20px] text-xl">
                          {value?.name}
                        </p>
                      </div>
                      <p className="font-poppins md:text-[23px] text-xl font-semibold mx-8 md:mb-3">
                        {value?.title}
                      </p>
                      <p className="font-poppins text-[16px] leading-[30.5px] sm:mx-8 ml-8 font-normal">
                        {value?.description}
                      </p>
                    </div>
                    <div className="flex md:gap-10 gap-2 sm:px-0 px-8">
                      <img
                        src={value?.image_url1}
                        className="sm:h-auto xs-h-34   md:px-4 sm:w-full w-2/4"
                      />
                      {value?.image_url2 ? (
                        <img
                          src={value?.image_url2}
                          className="sm:h-auto xs-h-34  sm:w-full w-2/4"
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
              </>
            )
          )}
        </div>
      </div>

      <div id="subDiv_02" className="  max-h-max max-w-full  ">
        <div className="md:flex flex-col px-8 ">
          <h2 className="md:text-[40px] text-2xl font-poppins font-[300]  md:h-12 ">
            {" "}
            {curebayExpContent?.data[0]?.attributes?.SecondComp?.title?.substring(
              0,
              22
            )}
          </h2>
          <div className="md:flex md:w-full">
            <h2 className="md:text-[53px] md:text-16px text-xl leading-[30.5px] font-medium text-newgreen w-[34rem]  ">
              {curebayExpContent?.data[0]?.attributes?.SecondComp?.title?.substring(
                22,
                curebayExpContent?.data[0]?.attributes?.SecondComp?.title
                  ?.length
              )}
            </h2>
            <div className=" w-full flex flex-col justify-center align-middle ">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
          <p className="md:text-[18px] font-poppins font-normal pt-4">
            {curebayExpContent?.data[0]?.attributes?.SecondComp?.description}
          </p>
        </div>
        <Grid container className="flex my-10">
          <Grid item xs={12} md={12} className="">
            <img
              className="xs-w-full"
              src={
                curebayExpContent?.data[0]?.attributes?.SecondComp?.image_url
              }
              alt="img"
              style={{
                height: "517px",
                width: "986px",
                position: "flxed",
                margin: "auto",
              }}
            />
          </Grid>
        </Grid>
      </div>
      <div>
        {/* <p className='md:text-3xl mt-24 md:font-bold leading-54px text-center text-chathams-blue-500'>{data?.SecondComponent?.Heading2}</p> */}

        <div className="md:flex flex-col px-8 ">
          <h2 className="md:text-[40px] text-xl font-poppins font-[300]  md:h-12 ">
            Covering all three levels of
          </h2>
          <div className="md:flex w-full">
            <h2 className="md:text-[53px] text-xl font-medium text-newgreen w-[34rem] ">
              healthcare.
            </h2>
            <div className=" w-full flex flex-col justify-center align-middle ">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
        </div>
        <img
          src={curebayExpContent?.data[0]?.attributes?.SecondComp?.image_url2}
        />
      </div>
      <div>
        <div className="md:flex flex-col px-8 ">
          <h2 className="md:text-[40px] text-xl font-poppins font-[300] md:h-12 ">
            {curebayExpContent?.data[0]?.attributes?.FourthComp?.title?.substring(
              0,
              19
            )}
          </h2>
          <div className="md:flex w-full">
            <h2 className="md:text-[53px] text-xl font-medium text-newgreen w-[60rem] ">
              {curebayExpContent?.data[0]?.attributes?.FourthComp?.title?.substring(
                19,
                curebayExpContent?.data[0]?.attributes?.FourthComp?.title
                  ?.length
              )}
            </h2>
            <div className=" w-full flex flex-col justify-center align-middle ">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
        </div>
        <div className="md:flex justify-between m-8 h-12">
          <p className="md:text-lg text-16px font-medium md:w-[46rem] md:text-[20px] text-[15px] font-poppins">
            {curebayExpContent?.data[0]?.attributes?.FourthComp?.description}
          </p>
          <div className=" gap-2 hidden sm:flex">
            <IconButton
              size="large"
              onClick={(event) => {
                handlleScroll(event, "leftArrow");
              }}
            >
              <WestIcon />
            </IconButton>
            <IconButton
              size="large"
              onClick={(event) => {
                handlleScroll(event, "rightArrow");
              }}
            >
              <EastIcon />
            </IconButton>
          </div>
        </div>
        <div className="md:flex justify-between items-start md;mx-8 md:gap-12  md:mt-[5rem] mt-[78px]">
          <img
            className="md:w-4/12 w-11/12 md:mt-7 md:p-0 p-3 md:mx-0 mx-3 md:h-[28rem] "
            src={
              curebayExpContent?.data[0]?.attributes?.FourthComp?.careplans[
                position
              ]?.image_url
            }
          />
          <div className="md:w-8/12 px-5">
            <div className="flex justify-between items-center md:w-full  pb-2 border-b-2 sm:pb-4  flex-row">
              <div>
                <h3 className="text-[#012E40] md:text-2xl text-lg  font-poppins font-medium">
                  {
                    curebayExpContent?.data[0]?.attributes?.FourthComp
                      ?.careplans[position]?.name
                  }
                </h3>
                <p className="font-poppins font-semibold md:text-[40px] text-lg text-[#66B889]">
                  {curebayExpContent?.data[0]?.attributes?.FourthComp?.careplans[
                    position
                  ]?.price?.substring(0, 3)}
                  <span className="font-poppins font-bold md:text-[30px] text:xl text-[#C6C6C6]">
                    {curebayExpContent?.data[0]?.attributes?.FourthComp?.careplans[
                      position
                    ]?.price?.substring(
                      3,
                      curebayExpContent?.data[0]?.attributes?.FourthComp
                        ?.careplans[position]?.price?.length
                    )}
                  </span>
                </p>
              </div>
              <div className=" gap-2 flex sm:hidden">
                <IconButton
                  size="large"
                  onClick={(event) => {
                    handlleScroll(event, "leftArrow");
                  }}
                  className="w-9 h-9"
                >
                  <WestIcon />
                </IconButton>
                <IconButton
                  size="large"
                  onClick={(event) => {
                    handlleScroll(event, "rightArrow");
                  }}
                  className="w-9 h-9"
                >
                  <EastIcon />
                </IconButton>
              </div>
            </div>
            <div className="md:grid grid-cols-3 md:p-0 p-4 space-y-4 sm:space-y-0 mt-0 sm:mt-2">
              {curebayExpContent?.data[0]?.attributes?.FourthComp?.careplans[position]?.points?.map((value) => (
                <div className="flex items-start justify-start gap-4 sm:gap-0">
                  <img
                    className="sm:mt-4 sm:h-auto h-4"
                    src={
                      curebayExpContent?.data[0]?.attributes?.FourthComp
                        ?.careplans[position]?.tick_url
                    }
                  ></img>
                  <h4 className="font-poppins font-normal sm:text-base text-sm sm:m-4">
                    {value?.points_}
                  </h4>
                </div>
              ))}
            </div>
            <button
              variant="contained"
              color="primary"
              className=" md:my-8 mx-4 md:w-40 w-28 md:py-4 py-2 md:mb-16 bg-[#0272DA] rounded-md text-white text-sm font-poppins  "
              onClick={() =>
                addToCart(
                  curebayExpContent?.data[0]?.attributes?.FourthComp?.careplans[
                  position
                  ],
                  "yearly" //change it when default is monthly
                )
              }
            >
              Add to Cart
            </button>
          </div>
        </div>
      </div>

      {/* <div id='subDiv_06'
                className='  max-h-max max-w-full mt-12 '
            >
                <div className="container mx-auto bg-white px-4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div className="  h-8 bg-shakespeare bg-opacity-[8%] m-1 py-2 px-2 rounded-md">
                        <p className='text-14px md:text-sm  text-center md:font-normal text-chathams-blue-500'>{data.ThirdComponent?.name}</p>
                    </div>
                    <p className='md:text-4xl mt-5 md:font-bold leading-54px text-center text-chathams-blue-500'>{data.ThirdComponent?.heading}</p>
                </div>
                <div className="container flex flex-row mx-auto mt-14">
                    <Grid container columnSpacing={2}>

                        {data.ThirdComponent?.content && data.ThirdComponent?.content.length && data.ThirdComponent?.content.map((value, index) => (
                            <Grid item xs={4} md={4} key={index}>
                                <Card sx={{ maxWidth: '450px', height: '42rem', borderBottom: '3px solid', borderBottomColor: borderColors[value] }}  >

                                    <CardContent>
                                        <div className='flex items-center  text-left '>
                                            <img src={value.image_url} alt={value.image_url} className='w-auto h-auto'></img>

                                            <p className="text-chathams-blue-500 text-xl  px-2 leading-7 font-bold">{value?.title}</p>
                                        </div>
                                        <p className="text-royal-blue-500 text-base text-left px-2  font-bold mt-2">{value?.heading1}</p>
                                        <p className="text-chathams-blue-500 text-base px-2 leading-6 font-semibold mt-4">{value?.description1}</p>

                                        <p className="text-royal-blue-500  text-lg mt-8 px-2 leading-6 font-bold">{value?.heading2}</p>
                                        {data.ThirdComponent?.content[index].subContent && data.ThirdComponent?.content[index].subContent.length && data.ThirdComponent?.content[index].subContent.map((value, index) => (
                                            <>
                                                <p className="text-silver-tree-500  text-base mt-2 px-2  font-bold">{value?.value}</p>
                                                <p className="text-chathams-blue-500 text-base  px-2 leading-6 font-semibold">{value?.label}</p>

                                            </>
                                        ))}

                                        <p className="text-silver-tree-500 text-base  px-2 leading-6 font-semibold">{value?.answer_details?.[0]?.name}</p>
                                        <p className="text-chathams-blue-500 text-base mt-2 px-2  font-normal">{value?.answer_details?.[0]?.answer} </p>
                                        <p className="text-silver-tree-500 text-base mt-3 px-2 leading-6 font-semibold">{value?.answer_details?.[1]?.name}</p>
                                        <p className="text-chathams-blue-500 text-base mt-2 px-2  font-normal"> {value?.answer_details?.[1]?.answer} </p>

                                    </CardContent>

                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div> */}

      <div
        id="subDiv_05"
        className=" mx-5 md:mx-0 max-h-max max-w-full sm:px-5 "
      >
        {/* <div className="container mx-auto bg-white px-4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div className=" w-40 h-8 bg-shakespeare bg-opacity-[8%] m-1 py-1 mt-20 rounded-md">
                        <p className='text-sm md:text-base  text-center items-center md:font-normal text-chathams-blue-500'>{data.FourthComponent?.name}</p>
                    </div>
                    <p className='md:text-4xl mt-5 md:font-bold leading-54px text-center text-chathams-blue-500'>{data.FourthComponent?.title}</p>
                </div> */}

        <h2 className="font-poppins md:text-[46px] text-xl font-normal text-center md:mt-0 mt-4">
          {curebayExpContent?.data[0]?.attributes?.sixthComp?.title?.substring(
            0,
            5
          )}
          <span className="text-[#40B882] font-poppins md:text-[46px] text-xl font-normal">
            {curebayExpContent?.data[0]?.attributes?.sixthComp?.title?.substring(
              5,
              curebayExpContent?.data[0]?.attributes?.sixthComp?.title?.length
            )}
          </span>
        </h2>
        <div className="container flex flex-row  md:mt-14 mt-6 ">
          <Grid container columnSpacing={2} rowSpacing={3}>
            {curebayExpContent?.data[0]?.attributes?.sixthComp &&
              curebayExpContent?.data[0]?.attributes?.sixthComp?.content?.map(
                (value, index) => (
                  <Grid item xs={24} md={4} key={index}>
                    <Card
                      sx={{
                        maxWidth: "435px",
                        height: "100%",
                        borderTop: "4px solid",
                        borderTopColor: borderColors[index],
                      }}
                    >
                      <CardContent>
                        <div className="sm:justify-between gap-2 sm:gap-0 flex">
                          <img
                            src={value?.icon_url}
                            alt={value?.image_url}
                            className="w-9 h-9 block sm:hidden"
                          ></img>
                          <div>
                            <h3 className="sm:text-[20px] text-[18px] font-poppins font-semibold  px-2 leading-7 text-[#0B4881]">
                              {value?.title}
                            </h3>
                            <p className="text-[#40B882] sm:pt-3 sm:text-[16px] text-[15px] font-medium  px-2 leading-6">
                              {value?.subtitle}
                            </p>
                          </div>
                          <img
                            src={value?.icon_url}
                            alt={value?.image_url}
                            className="w-auto h-auto hidden sm:block"
                          ></img>
                        </div>
                        <Item>
                          <List
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            {value?.subpoint?.map((rec) => {
                              return (
                                <ListItem
                                  alignItems="flex-start"
                                  sx={{ paddingLeft: "0px" }}
                                  className="gap-2"
                                >
                                  <ListItemIcon>
                                    <img
                                      className="w-[1.2rem]"
                                      src={value?.tick_url}
                                      alt={value?.tick_url}
                                    ></img>
                                  </ListItemIcon>
                                  <ListItemText>
                                    <p className="font-poppins font-normal sm:text-[16px] text-[14px]">
                                      {rec?.subpoint}
                                    </p>
                                  </ListItemText>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Item>
                      </CardContent>
                    </Card>
                  </Grid>
                )
              )}
          </Grid>
        </div>
      </div>
      {/* <div className="mx-5 md:mx-10  md:pl-10 rounded-[17.4px] bg-[#FFF5EB] md:flex justify-between items-center mt-10 md:mt-24">
        <div className="flex flex-col gap-4">
          <h2 className="font-poppins font-bold md:text-[36.54px] text-xl">
            {homePageContent?.data[0]?.attributes?.eight_comp?.title}
          </h2>
          <p className="font-poppins font-normal md:w-[46rem] md:text-[19.54px]">
            {homePageContent?.data[0]?.attributes?.eight_comp?.description}
          </p>
          <button
            className="py-4 w-max px-4 before:rounded-md bg-[#FFC994] font-bold text-base"
            onClick={() => navigateToPlans()}
          >
            Buy Now
          </button>
        </div>
        <img
          src={homePageContent?.data[0]?.attributes?.eight_comp?.image_url}
          alt="xyz"
        />
      </div> */}
      <div className="sm:w-full relative mx-5  sm:mt-24  mt-8">
        <img
          className="sm:h-0 h-[171px]"
          src={homePageContent?.data[0]?.attributes?.eight_comp?.image_url}
          alt="Image"
        />
        <div className="w-full relative mx-5  flex buynowimgdesktop">
          <img
            src={homePageContent?.data[0]?.attributes?.eight_comp?.image_url}
            alt="Image"
          />

          <div className="absolute top-0 left-0 right-0 bottom-0 pr-5 pl-[47px] pb-5 pt-[70px] flex flex-col gap-4">
            <h2 className="font-poppins font-bold md:text-[36.54px] text-xl">
              {homePageContent?.data[0]?.attributes?.eight_comp?.title}
            </h2>
            <h3 className="font-poppins font-normal md:w-[46rem] md:text-[19.54px]">
              {homePageContent?.data[0]?.attributes?.eight_comp?.description}
            </h3>
            <button
              className="py-4 w-max px-4 before:rounded-md bg-[#FFC994] font-bold text-base"
              onClick={() => navigateToPlans()}
            >
              Buy Now{" "}
            </button>
          </div>
        </div>
        <div className="buynowimgmobile absolute top-0 left-0 right-0 bottom-0 pr-5 sm:pl-[47px] pl-4 pb-5 sm:pt-[70px] pt-0 flex flex-col sm:gap-4 gap-2">
          <h2 className="font-poppins font-bold md:text-[36.54px] sm:text-xl text-[15px] sm:pt-[0px] pt-[6px]">
            {homePageContent?.data[0]?.attributes?.eight_comp?.title}
          </h2>
          <h3 className="font-poppins font-normal sm:w-[46rem] w-52 md:text-[19.54px] text-[13px]">
            {homePageContent?.data[0]?.attributes?.eight_comp?.description}
          </h3>
          <button
            className="sm:py-4 py-[6px] w-max sm:px-4 px-[18px] before:rounded-md bg-[#FFC994] font-bold text-base"
            onClick={() => navigateToPlans()}
          >
            Buy Now{" "}
          </button>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center md:py-16 mb-7 sm:-mb-[85px]">
        <p className="md:mb-12 font-poppins md:text-[46px] text-xl font-normal text-center mt-[27px] sm:mt-0">
          {curebayExpContent?.data[0]?.attributes?.eigth_comp?.title?.substring(
            0,
            9
          )}
          <span className="text-[#40B882] font-poppins md:text-[46px] text-xl font-normal ">
            {curebayExpContent?.data[0]?.attributes?.eigth_comp?.title?.substring(
              9,
              curebayExpContent?.data[0]?.attributes?.eigth_comp?.title?.length
            )}
          </span>
        </p>
        {/* <img className='w-[70%]' src={curebayExpContent?.data[0]?.attributes?.eigth_comp?.img_url_web} alt='curebay' /> */}
      </div>
      <div className=" grid-cols-3 grid-rows-2 gap-x-2  justify-items-center sm:hidden block mt-5 ml-[24px] mb-[25px] mr-[17px] ">
        {homePageContent?.data[0]?.attributes?.fifthcomp?.content?.map(
          (value, i) => (
            <div key={i} className="flex flex-row border-b-2 py-4">
              <div className="w-1/5">
                <img src={value?.icon_url} alt="" className="w-12 h-12" />
              </div>
              <div className="w-4/5">
                <h2 className="font-poppins font-semibold text-base mb-2">
                  {value?.title}
                </h2>
                <p className="font-poppins font-normal  text-xs ">
                  {value?.description}
                </p>
              </div>
            </div>
          )
        )}
      </div>
      <div className="md:grid grid-cols-3 grid-rows-2 gap-x-10 gap-y-4 justify-items-center sm:block hidden">
        {homePageContent?.data[0]?.attributes?.fifthcomp?.content?.map(
          (value, i) => (
            <div
              key={i}
              className="m-5 md:w-[413px] md:h-[286px] border-2 p-6 rounded-lg"
            >
              <img src={value?.icon_url} alt="" />
              <h2 className="font-poppins font-semibold md:text-[20px] text-sm md:mt-5">
                {value?.title}
              </h2>
              <p className="font-poppins font-normal md:text-[16px] text-sm md:mt-5">
                {value?.description}
              </p>
            </div>
          )
        )}
      </div>

      {/* <div id='subDiv_06'
                className='  max-h-max max-w-full  '
            >
                <div className="container mx-auto bg-white px-4" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <p className='text-xs md:text-sm bg-shakespeare bg-opacity-[8%] w-20 h-8 m-1 py-1.5 rounded-md mt-10  text-center items-center md:font-normal text-chathams-blue-500 '>{data.FifthComponent?.name}</p>
                    <p className='md:text-4xl mt-7 md:font-bold leading-54px text-center mb-7 text-chathams-blue-500'>{data.FifthComponent?.title}</p>
                </div>
                <div className="flex flex-row justify-center w-full max-h-52  mt-20 rounded-md bg-tutu">
                    

                    <div className='flex'>
                        <div className='flex flex-col border-r items-center w-48 text-center'>
                            <img className="h-12 md:h-16 lg:h-12" src={Count_patients} alt="" />
                            <p className='text-2xl m-2 font-semibold text-chathams-blue-500'>{ourAim.totalPatients}+</p>
                            <p className='text-m m-2 font-normal text-chathams-blue-500'> Happy Patients</p>
                        </div>
                        <div className='flex flex-col border-r items-center justify-center pr-1 w-48 text-center'>
                            <img className="h-12 md:h-16 lg:h-12" src={PartnerHodpitals} alt="" />
                            <p className='text-2xl m-2 font-semibold text-chathams-blue-500'>{ourAim.totalHospitals}+</p>
                            <p className='text-m m-2 font-normal text-chathams-blue-500 '> Partner Hospitals </p>
                        </div>
                        <div className='flex flex-col border-r items-center pr-1 w-48 text-center'>
                            <img className="h-12 md:h-16 lg:h-12" src={Count_doctor} alt="" />
                            <p className='text-2xl m-2 font-semibold text-chathams-blue-500'>{ourAim.totaldoctors}+</p>
                            <p className='text-m m-2 font-normal text-chathams-blue-500'>verified doctors</p>
                        </div>
                        <div className='flex flex-col border-r items-center pr-1 w-48 text-center'>
                            <img className="h-12 md:h-16 lg:h-12" src={Count_specialities} alt="" />
                            <p className='text-2xl m-2 font-semibold text-chathams-blue-500'>{ourAim.totalSpecialities}+</p>
                            <p className='text-m m-2 font-normal text-chathams-blue-500'>Specialities</p>
                        </div>
                        <div className='flex flex-col border-r items-center pr-1 w-48 text-center'>
                            <img className="h-12 md:h-16 lg:h-12" src={Pincodecovered} alt="" />
                            <p className='text-2xl m-2 font-semibold text-chathams-blue-500'>{ourAim.totalZipCodesCovered}+</p>
                            <p className='text-m m-2 font-normal text-chathams-blue-500'>Pin Codes Covered</p>
                        </div>
                        <div className='flex flex-col items-center w-48 text-center'>
                            <img className="h-12 md:h-16 lg:h-12" src={prescriptionsserved} alt="" />
                            <p className='text-2xl m-2 font-semibold text-chathams-blue-500'>{ourAim.totalPrescriptionServed}+</p>
                            <p className='text-m m-2 font-normal text-chathams-blue-500' > Prescriptions Served</p>
                        </div>
                    </div>
                </div>
            </div> */}
      <div id="subDiv_06" className="  max-h-max max-w-full my-10 px-5 ">
        {/* <div className="container mx-auto bg-white px-4 mt-3  " style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                    <p className='md:text-4xl mt-12  md:font-bold leading-54px text-center text-chathams-blue-500'>{data?.seventhcompo?.Title}</p>
                    <p className='text-xl text-center leading-8 font-semibold mx-32 py-4  text-shuttle-gray-500'>{data?.seventhcompo?.description}</p>
                </div> */}

        <div className="md:flex flex-col px-8 py-8  md:mb-10">
          <p className="md:text-[40px] text-xl font-poppins font-[300]  md:h-12 ">
            {curebayExpContent?.data[0]?.attributes?.seventhComp?.title?.substring(
              0,
              23
            )}
          </p>
          <div className="md:flex md:w-full">
            <p className="md:text-[53px] text-xl font-medium text-newgreen w-[50rem] ">
              {curebayExpContent?.data[0]?.attributes?.seventhComp?.title?.substring(
                23,
                curebayExpContent?.data[0]?.attributes?.seventhComp?.title
                  ?.length
              )}
            </p>
            <div className=" sm:w-1/2 w-full flex flex-col justify-center align-middle ">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
          <p className="md:text-[20px] text-16 font-poppins font-normal pt-8">
            {curebayExpContent?.data[0]?.attributes?.seventhComp?.description}
          </p>
        </div>
        <div className="flex flex-row justify-between w-full max-h-full rounded-md">
          <Grid
            container
            columnSpacing={1}
            rowSpacing={2}
            justifyContent={"space-around"}
            gridColumn={4}
            sx={{ maxwidth: "100%", maxHeight: "100%" }}
          >
            {curebayExpContent?.data[0]?.attributes?.seventhComp?.image?.map(
              (value, index) => (
                <img
                  sx={{ maxWidth: "auto", maxHeight: "auto" }}
                  src={value?.image_url}
                  alt={value?.id}
                  loading="lazy"
                  className="max-h-auto max-w-300px py-2 pr-2"
                />
              )
            )}
            {/* <Grid item xs={12} md={7} sx={{ maxHeight: '100%', }} >
                            <Item>
                                <List sx={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F5FCFF' }} >
                                    {data?.SixthComponent?.content?.map((value, index) => (
                                        <ListItem alignItems='center' >
                                            <ListItemIcon sx={{}}>
                                                <Typography variant='h4' color={'#66B889'}>{value?.pointNumber}</Typography>
                                            </ListItemIcon>
                                            <ListItemText >
                                                <p className='text-lg text-left mx-8  font-normal   text-chathams-blue-500'>{value?.point}</p>
                                            </ListItemText>
                                        </ListItem>
                                    ))}
                                </List>
                            </Item>
                        </Grid> */}
          </Grid>
        </div>
      </div>

      {/* <div className='md:grid grid-cols-3 grid-rows-2 gap-x-10 gap-y-12 justify-items-center sm:block hidden'>
                {homePageContent?.data[0]?.attributes?.fifthcomp?.content?.map((value, i) => (
                    <div key={i} className='m-5 md:w-[396px] md:h-[286px] border-2 p-6 rounded-lg'>
                        <img src={value?.icon_url} alt='' />
                        <h1 className='font-poppins font-semibold md:text-[20px] text-sm md:mt-5'>{value?.title}</h1>
                        <h1 className='font-poppins font-normal md:text-[16px] text-sm md:mt-5'>{value?.description}</h1>
                    </div>
                ))}
            </div> */}

      {screen > 500 ? (
        <Footer i={1} />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </div>
  );
};

export default HowItWorks;
