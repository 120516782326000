import forgotPass from "../../services/forgotPassServices.jsx";

export const fetchOtp = async (payload) => {
    const res = await forgotPass.generateOtp(JSON.stringify(payload));
    return res;
};
export const checkOtp = async (payload) => {
    const res = await forgotPass.getCode(JSON.stringify(payload));
    return res;
};
export const setNewPass = async (payload) => {
    const res = await forgotPass.updatePass(JSON.stringify(payload));
    return res;
};