import React, { useEffect, useState } from "react";
import {
  Button,
  div,
  Icon,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  TextField,
} from "@mui/material";
import Footer from "../../components/webflow/postloginfooter";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { patientSave } from "../../store/actions/login";
import { checkIsLoggedIn } from "../../store/actions/useractions";
import { useSnackbar } from "../common/snackbar";
import {
  fetchEClinics,
  fetchEclinicsByPincode,
  fetchMapMyIndiaToken,
} from "../../store/actions/location";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import camera from "../../assets/images/camera.svg";
import CloseIcon from "@mui/icons-material/Close";
import userImage from "../../assets/images/userImage.svg";
import image from "../../assets/images/noclinics.png";
import axios from "axios";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import {
  shouldShowError,
  shouldShowErrorName2,
  shouldShowErrorAdhar,
} from "../common";
import { shouldShowErrorName } from "../common";
import {
  fetchBloodGroupDetails,
  fetchCitiesByStates,
  fetchRelationDetails,
  fetchStatesByCountries,
  fetchStatesDetails,
} from "../../store/actions/myprofile";
import mydetailsservice from "../../store/services/mydetailsservice";
// import { DateField } from '@mui/x-date-pickers';
// import dayjs from 'dayjs';
import CircularProgress from "@mui/material/CircularProgress";
import { fetchConsent, consentData } from "../../store/actions/login";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import {
  TabView as PrimereacttabView,
  TabPanel as Primereacttabpanel,
} from "primereact/tabview";
import { Dialog as Primereactdialog } from "primereact/dialog";

const AddMember = () => {
  const newField = {
    salutation: "Select",
    firstName: "",
    lastName: "",
    relation: "",
    mobile: "",
    email: null,
    gender: "",
    dob: "",
    bloodGroup: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    pinCode: "",
    emergencyContact: "",
    eClinic: [],
  };
  const navigate = useNavigate();
  const [saveAll, setSaveAll] = useState(false);

  const [isActive, setIsActive] = useState(0);
  const [value, setValue] = useState();
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const [memberDetails, setMemberDetails] = useState([
    {
      salutation: "Select",
      firstName: "",
      lastName: "",
      relation: "",
      mobile: "",
      email: "",
      gender: "",
      dob: "",
      bloodGroup: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      pinCode: "",
      eClinic: "",
      aadharId: "",
      emergencyContact: "",
    },
  ]);
  const [eClinicsData, seEclinicsData] = useState([]);
  const [eClinicsDataByPinCode, setEclinincsDataByPinCode] = useState([]);
  const [PhotoValue, setPhotoValue] = useState("");
  const [selectedPhoto, setselectedPhoto] = useState("");
  const [screen, setscreen] = useState(window.innerWidth);
  const [relations, setRelations] = useState([]);
  const [bloodGroups, setBloodGroups] = useState([]);
  const [states, setStates] = useState([]);
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const [selectedItem, setSelectedItem] = useState({});
  const userCode = userObj?.userCode ? userObj.userCode : null;
  const [loader, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [cities, setCities] = useState([]);
  const [valuenew, setValuenew] = React.useState("1");
  const [consentdata, setConsentdata] = useState();
  const [privacyPolicyConsentcompany, setprivacyPolicyConsentcompany] =
    useState("");
  const [termsAndConditionConsentcompany, settermsAndConditionConsentcompany] =
    useState("");
  const [tcPdf, setTcPdf] = useState("");
  const [privacyPdf, setPrivacyPdf] = useState("");

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  useEffect(() => {
    async function fetchConsentForm() {
      const res = await fetchConsent();
      if (res.status === 200 && res?.data) {
        setConsentdata(res?.data?.data[0]);
      }
    }
    fetchConsentForm();
  }, []);
  const getVersion = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_NEWBASEURL}ConsentVersion/list/filter?userType=PATIENT`,
        {
          method: "GET",
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(
        data?.data[0]?.versionNumber,
        data?.data[1]?.versionNumber,
        "ddffgghh"
      );
      setprivacyPolicyConsentcompany(data?.data[0]?.versionNumber);
      settermsAndConditionConsentcompany(data?.data[1]?.versionNumber);
      setTcPdf(data?.data[1]?.consentFile);
      setPrivacyPdf(data?.data[0]?.consentFile);
    } catch (e) {
      console.log("Error fetching data:", e);
    }
  };

  useEffect(() => {
    getVersion();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  }

  const handleDateChange = (date, index) => {
    let data = [...memberDetails];
    data[index] = {
      ...data[index],
      dob: date,
    };
    setMemberDetails(data);
  };

  useEffect(() => {
    async function fetchData() {
      const eClinicsResponse = await fetchEClinics();
      if (
        eClinicsResponse?.status === 200 &&
        eClinicsResponse?.data?.succesObject
      ) {
        seEclinicsData(eClinicsResponse.data.succesObject);
      }
      const relationDetailsResponse = await fetchRelationDetails();
      if (
        relationDetailsResponse?.status === 200 &&
        relationDetailsResponse?.data?.succesObject
      ) {
        setRelations(relationDetailsResponse.data.succesObject);
      }
      const bloodGroupDetailsResponse = await fetchBloodGroupDetails();
      if (
        bloodGroupDetailsResponse?.status === 200 &&
        bloodGroupDetailsResponse?.data?.succesObject
      ) {
        setBloodGroups(bloodGroupDetailsResponse.data.succesObject);
      }
      let payload = {
        countrycode: "IND",
      };
      const statesDetailsResponse = await fetchStatesByCountries(payload);
      if (
        statesDetailsResponse?.status === 200 &&
        statesDetailsResponse?.data?.succesObject
      ) {
        setStates(statesDetailsResponse.data.succesObject);
        setCities([]);
      }
    }
    fetchData();
  }, []);
  const handleChangenew = (event, newValue) => {
    setValuenew(newValue);
  };

  const onSaveMember = () => {
    // try {

    let data = memberDetails;

    data.forEach((e) => {});
    let data1 = [];
    for (let i = 0; i < data?.length; i++) {
      if (data[i].salutation == "Select") {
        setSnackbarMessage("Please select salutation", "error");
        return;
      }
      if (!data[i].firstName || data[i].firstName.trim() === "") {
        setSnackbarMessage("First name is required", "error");
        return;
      }

      if (
        data[i].firstName.length < 3 ||
        /^\s/.test(data[i].firstName.substr(0, 3))
      ) {
        setSnackbarMessage(
          // "First name must be at least 3 characters, and the first 3 characters should not include spaces",
          "First name must be at least 3 characters",
          "error"
        );
        return;
      }
      if (!data[i].lastName || data[i].lastName.trim() === "") {
        setSnackbarMessage("Last name is required", "error");
        return;
      }
      if (!data[i].relation) {
        setSnackbarMessage("Relation is required", "error");
        return;
      }
      const mobilePattern = /^[0-9]{10}$/;
      if (!data[i].mobile || !mobilePattern.test(data[i].mobile)) {
        setSnackbarMessage("Enter a valid mobile number", "error");
        return;
      }

      if (!data[i].dob) {
        setSnackbarMessage("Enter DOB", "error");
        return;
      }

      if (!data[i].address1) {
        setSnackbarMessage("Enter Address", "error");
        return;
      }
      if (!data[i].state) {
        setSnackbarMessage("Enter State", "error");
        return;
      }
      if (!data[i].city) {
        setSnackbarMessage("Enter District", "error");
        return;
      }
      const aadhaarpattern = /^\d{4}$/;
      if (!data[i].aadharId || !aadhaarpattern.test(data[i].aadharId)) {
        setSnackbarMessage(
          "Enter last four digit of your aadhaar number",
          "error"
        );
        return;
      }
      const mobilePatternnew = /^[0-9]{10}$/;
      if (
        !data[i].emergencyContact ||
        !mobilePatternnew.test(data[i].emergencyContact)
      ) {
        setSnackbarMessage("Enter a valid Emergency Contact Number", "error");
        return;
      }

      if (
        selectedItem[i] !== "I agree" &&
        selectedItem[i] !== "not available"
      ) {
        setErrorMessage("* Please select an option");
        return;
      }

      data1.push({
        userId: JSON.parse(localStorage.getItem("loginObj"))?.[0]?.userSeqId,
        salutation: data[i].salutation,
        firstName: data[i].firstName,
        lastName: data[i].lastName,
        mobile: data[i].mobile,
        relation: data[i].relation,
        gender: data[i].gender,
        dob: formatDate(data[i].dob),
        bloodGroup: data[i].bloodGroup,
        parentCode: checkIsLoggedIn(),
        address1: data[i].address1,
        address2: data[i].address2,
        pinCode: data[i].pinCode,
        city: data[i].city,
        state: data[i].state,
        country: "IND",
        email: data[i].email ? data[i].email : null,
        aadharId: data[i].aadharId,
        emergencyContact: data[i].emergencyContact,
        cheId:
          data[i].eClinic?.hospitalCode ||
          data[i]?.eClinic?.eclinicsName ||
          clinics[0]?.eclinicsName,
        cheLocationId: clinics?.[0]?.locationCode,
        // "cheLocationId": data[i]?.eClinic?.eclinicsName ? undefined : data[i].eClinic?.locationCode ? clinics[0]?.eclinicsName
        // "locationCode": data[i]?.eClinic?.eclinicsName ? data[i]?.eClinic?.locationCode : undefined,
        smartPhoneUser:
          data[i]?.smartPhoneUser === "I agree"
            ? 1
            : data[i]?.smartPhoneUser === "not available"
            ? 0
            : undefined,
        photo: PhotoValue,
      });
    }
    let params = {
      patientVOList: data1,
    };

    async function fetchData() {
      setLoader(true);
      const res = await patientSave(params);
      setLoader(false);

      const { data } = res || {};
      const responseCode = data?.responseCode;
      const responseMessage = data?.responseMessage;
      // const code =  data?.succesObject?.code;
      const isSuccess = res?.status === 200 && responseCode === "200";
      if (responseCode === "200") {
        setMemberDetails([newField]);
      }
      setSnackbarMessage(
        isSuccess
          ? responseMessage
          : responseMessage || "Please fill mandatory fields",
        isSuccess ? "success" : "error"
      );
      if (isSuccess) {
        navigate(APP_ROUTES.MEMBERDETAILS);
      } else if (responseCode === "500") {
        setSnackbarMessage(responseMessage, "error");
      }
    }

    fetchData();
    setSaveAll(true);
    // }
    // catch {
    //     setSnackbarMessage('An error occurred while saving ', 'error');
    // }
    // finally {
    //     setLoader(false);
  };
  const changeHandler = async (file) => {
    let b64File = await encodeBase64File(file);
    setPhotoValue(b64File);
    // setaddmember({ ...addmember, ['photo']: b64File })
  };
  const encodeBase64File = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        setselectedPhoto(baseURL);
        // console.log(baseURL.split(',')[1]);
        resolve(baseURL.split(",")[1]);
      };
      console.log(fileInfo);
    });
  };
  function numberToWord(num) {
    const words = [
      "zeroth",
      "first",
      "second",
      "third",
      "fourth",
      "fifth",
      "sixth",
      "seventh",
      "eighth",
      "ninth",
      "tenth",
      "eleventh",
      "twelfth",
      "thirteenth",
      "fourteenth",
      "fifteenth",
      "sixteenth",
      "seventeenth",
      "eighteenth",
      "nineteenth",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    if (num < 20) {
      return words[num];
    }
    if (num < 100) {
      const onesPlace = num % 10;
      const tensPlace = Math.floor(num / 10);
      const ordinal =
        tens[tensPlace] + (onesPlace ? "-" + words[onesPlace] : "");
      if (ordinal.endsWith("one")) {
        return ordinal + "st";
      } else if (ordinal.endsWith("two")) {
        return ordinal + "nd";
      } else if (ordinal.endsWith("three")) {
        return ordinal + "rd";
      } else {
        return ordinal + "th";
      }
    }
    return "Next";
  }

  const onActiveClick = (index) => {
    if (isActive === index) {
      setIsActive(null);
    } else {
      setIsActive(index);
    }
  };

  function parseDateFromString(dateString) {
    const [day, month, year] = dateString.split("/");
    return new Date(`${year}-${month}-${day}`);
  }

  console.log(memberDetails.length);

  const handleInputChange = async (index, event) => {
    let data = [...memberDetails];
    data[index][event.target.name] = event.target.value;
    setMemberDetails(data);
    if (event.target.name === "salutation") {
      if (event.target.value === "Mr.") {
        data[index].gender = "M";
      } else if (
        event.target.value === "Mrs." ||
        event.target.value === "Ms."
      ) {
        data[index].gender = "F";
      }
    }
    if (event.target.name === "relation") {
      if (
        event.target.value === "Mother" ||
        event.target.value === "Wife" ||
        event.target.value === "Sister" ||
        event.target.value === "Mother in law"
      ) {
        data[index].salutation = "Mrs.";
        data[index].gender = "F";
      } else if (
        event.target.value === "Father" ||
        event.target.value === "Brother" ||
        event.target.value === "Father in law"
      ) {
        data[index].salutation = "Mr.";
        data[index].gender = "M";
      }
      if (event.target.value === "Self") {
        let payload = {
          userCode: userCode,
        };
        mydetailsservice.getmyLischildapi(payload).then((res) => {
          if (res?.data?.responseCode === "200" && res?.data?.succesObject) {
            const {
              dob,
              firstName,
              lastName,
              mobile,
              email,
              bloodGroup,
              pinCode,
              state,
              city,
              aadharId,
              emergencyContact,
            } = res.data.succesObject;
            const dobDateObject = parseDateFromString(dob);

            const updatedMemberDetails = [...memberDetails];
            updatedMemberDetails[0] = {
              ...updatedMemberDetails[0], // Preserve existing fields in memberDetails[0]
              dob: dobDateObject,
              firstName: firstName,
              lastName: lastName,
              mobile: mobile,
              email: email,
              bloodGroup: bloodGroup,
              pinCode: pinCode,
              state: state,
              city: city,
              aadharId: aadharId,
              emergencyContact: emergencyContact,
            };
            setMemberDetails(updatedMemberDetails);
          }
        });
      }
    }
    if (event.target.name === "state") {
      let payload = {
        countrycode: "IND",
        statecode: event.target.value,
      };
      const citiesDetailsResponse = await fetchCitiesByStates(payload);
      if (
        citiesDetailsResponse?.status === 200 &&
        citiesDetailsResponse?.data?.succesObject
      ) {
        setCities(citiesDetailsResponse.data.succesObject);
      }
    }
    if (event.target.name === "pinCode" && event.target.value?.length === 6) {
      setEclinincsDataByPinCode([]);
      const tokenResponse = await fetchMapMyIndiaToken();
      if (
        tokenResponse?.data?.responseCode === "200" &&
        tokenResponse.data?.succesObject
      ) {
        const token = tokenResponse.data.succesObject.access_token;
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_MEDICARE_BASEURL}mapMyIndia/${event.target.value}&itemCount=1`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          let eLoc = response?.data?.copResults?.eLoc;
          if (eLoc) {
            const latLongResponse = await axios.get(
              `${process.env.REACT_APP_MEDICARE_BASEURL}mapMyIndia/O2O/entity/${eLoc}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (latLongResponse?.data) {
              let reqData = {
                lat: latLongResponse.data?.latitude,
                longt: latLongResponse.data?.longitude,
              };
              const eClinicsByPinCode = await fetchEclinicsByPincode(
                JSON.stringify(reqData)
              );
              if (
                eClinicsByPinCode?.data?.responseCode &&
                eClinicsByPinCode?.data?.succesObject?.length
              ) {
                setEclinincsDataByPinCode(eClinicsByPinCode.data.succesObject);
              }
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
    // setSelectedItem((prevSelected) => ({
    //   ...prevSelected,
    //   [index]: event.target.value,
    // }));
    if (event.target.name === "smartPhoneUser") {
      setSelectedItem((prevSelected) => ({
        ...prevSelected,
        [index]: event.target.value,
      }));
    }
  };

  const add = () => {
    if (clinics?.length) {
      const newFieldWithDetails = {
        ...newField,
        pinCode: pin,
        eClinic: clinics?.[0]?.eclinicsName,
      };

      // Add the new field to memberDetails
      setMemberDetails([...memberDetails, newFieldWithDetails]);
    } else {
      setMemberDetails([...memberDetails, newField]);
    }
  };
  const remove = (index) => {
    let data = [...memberDetails];
    data.splice(index, 1);
    setMemberDetails(data);
  };

  const datePickerStyles = {
    input: {
      width: "100%",
      padding: "0.375rem 3.75rem",
      fontSize: "0.875rem",
      lineHeight: "1.9",
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
    },
  };
  const [isActive1, setIsActive1] = useState(true);
  const [pin, setPin] = useState("");
  const [clinics, setClinics] = useState([]);
  const [eClinicsView, setEClinicsView] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const handlecheck = async () => {
  //     setClinics([])
  //     const tokenResponse = await fetchMapMyIndiaToken();
  //     if (tokenResponse?.data?.responseCode === '200' && tokenResponse.data?.succesObject) {
  //         const token = tokenResponse.data.succesObject.access_token;
  //         try {
  //             const response = await axios.get(`https://patient.curebay.com/dhp/places/geocode?address=${pin}&itemCount=1`, {
  //                 headers: {
  //                     Authorization: `Bearer ${token}`
  //                 }
  //             });
  //             let eLoc = response?.data?.copResults?.eLoc
  //             if (eLoc) {
  //                 const latLongResponse = await axios.get(`https://patient.curebay.com/dhp/O2O/entity/${eLoc}`, {
  //                     headers: {
  //                         Authorization: `Bearer ${token}`
  //                     }
  //                 });
  //                 if (latLongResponse?.data) {
  //                     let reqData = {
  //                         lat: latLongResponse.data?.latitude,
  //                         longt: latLongResponse.data?.longitude,
  //                         userCode: userObj?.userCode ? userObj.userCode : userObj?.parentCode ? userObj.parentCode : null,

  //                     }
  //                     const eClinicsByPinCode = await fetchEclinicsByPincode(JSON.stringify(reqData))
  //                     console.log(eClinicsByPinCode, "ok")
  //                     if (eClinicsByPinCode?.data?.responseCode) {
  //                         if (eClinicsByPinCode?.data?.succesObject) {
  //                             setEClinicsView(true)
  //                             setClinics(eClinicsByPinCode.data.succesObject)
  //                             for (var i = 0; i < memberDetails.length; i++) {
  //                                 // memberDetails[i]["pinCode"] = eClinicsByPinCode?.data?.succesObject[0]?.pincode
  //                                 memberDetails[i]["pinCode"] = pin
  //                                 memberDetails[i]["eClinic"] = eClinicsByPinCode?.data?.succesObject[0]?.eclinicsName

  //                             }
  //                             // setEclinincsDataByPinCode(eClinicsByPinCode?.data?.succesObject)
  //                         }

  //                         else {
  //                             // setEClinicsOnPin(true)
  //                             setOpen(true)

  //                         }
  //                     }

  //                 }
  //             }
  //         } catch (error) {
  //             console.error(error);
  //         }
  //     }
  // }
  const handlecheck = async () => {
    if (pin.length == 0) {
      setSnackbarMessage(
        "Please enter the pincode , and click on check now",
        "error"
      );
    } else {
      setLoader(true);
      try {
        setClinics([]);
        for (var j = 0; j < memberDetails.length; j++) {
          memberDetails[j]["pinCode"] = pin;
        }
        const tokenResponse = await fetchMapMyIndiaToken();
        if (
          tokenResponse?.data?.responseCode === "200" &&
          tokenResponse.data?.succesObject
        ) {
          const token = tokenResponse.data.succesObject.access_token;
          const response = await axios.get(
            `${process.env.REACT_APP_MEDICARE_BASEURL}mapMyIndia/places/geocode?address=${pin}&itemCount=1`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          let eLoc = response?.data?.copResults?.eLoc;

          if (eLoc) {
            const latLongResponse = await axios.get(
              `${process.env.REACT_APP_MEDICARE_BASEURL}mapMyIndia/O2O/entity/${eLoc}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            if (latLongResponse?.data) {
              let reqData = {
                lat: latLongResponse.data?.latitude,
                longt: latLongResponse.data?.longitude,
                userCode: userObj?.userCode
                  ? userObj.userCode
                  : userObj?.parentCode
                  ? userObj.parentCode
                  : null,
                pincode: pin,
              };
              const eClinicsByPinCode = await fetchEclinicsByPincode(
                JSON.stringify(reqData)
              );
              if (eClinicsByPinCode?.data?.responseCode) {
                if (eClinicsByPinCode?.data?.succesObject) {
                  setEClinicsView(true);
                  setClinics(eClinicsByPinCode.data.succesObject);
                  for (var i = 0; i < memberDetails.length; i++) {
                    memberDetails[i]["eClinic"] =
                      eClinicsByPinCode?.data?.succesObject[0]?.eclinicsName;
                    memberDetails[i]["pinCode"] = pin;
                  }
                } else {
                  setOpen(true);
                }
              }
            }
          } else {
            setSnackbarMessage("please enter a valid pincode", "error");
          }
        }
      } catch (error) {
        setSnackbarMessage(
          "An error occurred while checking the pincode , Please try again after some time",
          "error"
        );
      } finally {
        setLoader(false);
      }
    }
  };

  console.log(clinics, "ecl1");
  console.log(memberDetails, "cll");

  const handlePinCodeChange = (e) => {
    const inputValue = e.target.value;
    const maxLength = 6;

    if (inputValue.length > maxLength) {
      setPin(inputValue.slice(0, maxLength));
    } else {
      setPin(inputValue);
    }
  };

  return (
    <>
      {snackbarMessage}
      <div
        className="border-2 rounded-10px mx-0 sm:mx-16 my-0 sm:my-8 sm:mt-[162px] mt-[113px]"
        style={{ boxShadow: "rgba(226, 226, 226, 0.2) 12px 20px 20px" }}
      >
        <div
          className="flex cursor-pointer justify-between items-center p-3 md:p-4"
          onClick={() => setIsActive1(!isActive1)}
        >
          <h2 className={`w-11/12 text-[20px] font-medium font-poppins `}>
            Check Totalcare{" "}
            <span className="font-poppins font-medium text-[#40B882]">
              services near by your loved ones area{" "}
            </span>
          </h2>
          <div className="flex justify-end text-3xl font-semibold  text-brand-primary md:w-96">
            <IconButton size="medium" sx={{ backgroundColor: "#E4FAFF" }}>
              {isActive1 ? (
                <Icon fontSize="inherit" color="#262626">
                  keyboard_arrow_up
                </Icon>
              ) : (
                <Icon fontSize="inherit" color="#262626">
                  keyboard_arrow_down
                </Icon>
              )}
            </IconButton>
          </div>
        </div>
        {isActive1 && (
          <div>
            {loader && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
                <div className="relative">
                  <div className="w-20 h-20 border-4 border-t-4 border-gray-200 rounded-full animate-spin" />
                </div>
              </div>
            )}
            {!eClinicsView ? (
              <div>
                <div className="md:w-11/12  px-4 md:text-[18px]  font-poppins font-normal text-black ">
                  Enter Pincode{" "}
                </div>
                <div className="m-[17px] mb-4">
                  <InputLabel
                    sx={{ color: "#262626", margin: "2px" }}
                  ></InputLabel>
                  <TextField
                    required
                    name="firstName"
                    value={pin}
                    fullWidth
                    size="small"
                    type="text"
                    onChange={handlePinCodeChange}
                    onKeyPress={(e) => {
                      // Allow only numeric input and limit length to 13 characters
                      const isValidInput = /^[0-9]*$/.test(e.key);
                      if (!isValidInput || e.target.value.length >= 6) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="text-center sm:text-left">
                  <button
                    className=" border-2 px-3 py-2 rounded-md text-white bg-[#40B882] sm:mx-4 font-semibold w-[190px] sm:w-[12rem] mb-6 "
                    onClick={handlecheck}
                  >
                    Check Now
                  </button>
                </div>
              </div>
            ) : (
              <div>
                {clinics?.map((value) => (
                  <div className="flex m-8 gap-4">
                    <img
                      className="w-[8rem]"
                      src={value?.image}
                      alt={value?.city}
                    />
                    <div className=" w-full ">
                      <h1 className="text-[#18406D] font-poppins font-semibold text-[18px] pb-2 border-b-2">
                        {value?.city}
                      </h1>
                      <h1 className="font-poppins font-normal text-[16px] pt-2">
                        Zip Code - {value?.pincode}
                      </h1>
                      <h1 className="font-poppins font-normal text-[16px] pt-2">
                        eclinic Name- {value?.eclinicsName}
                      </h1>
                      <h1 className="font-poppins font-normal text-[16px] pt-2">
                        Address - {value?.address1} {value?.address2}
                      </h1>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <div className="flex flex-col p-6 sm:p-0">
        {memberDetails?.length > 0 &&
          memberDetails.map((e, index) => (
            <div
              className="border-2 rounded-10px mx-0 sm:mx-16 my-0 sm:my-8 mt-4 sm:mt-0"
              style={{ boxShadow: "rgba(226, 226, 226, 0.2) 12px 20px 20px" }}
              key={index}
            >
              <div
                className="flex cursor-pointer justify-between items-center pt-4 sm:pt-7 pl-4 sm:pl-6"
                onClick={() => onActiveClick(index)}
              >
                <h2
                  className={`sm:w-11/12 w-[386px] text-[20px]  font-medium font-poppins`}
                >
                  Add{" "}
                  <span className="font-poppins font-medium text-[#40B882]">
                    Details
                  </span>{" "}
                </h2>
                <div className="flex justify-end text-3xl font-semibold  text-brand-primary w-96">
                  <IconButton size="medium" sx={{ backgroundColor: "#E4FAFF" }}>
                    {isActive === index ? (
                      <Icon fontSize="inherit" color="#262626">
                        expand_less
                      </Icon>
                    ) : (
                      <Icon fontSize="inherit" color="#262626">
                        expand_more
                      </Icon>
                    )}
                  </IconButton>
                </div>
              </div>
              {isActive === index && (
                <div className="m-4">
                  <div className="flex justify-left flex-wrap ">
                    <div
                      className="grid sm:grid-cols-4 grid-cols-1  gap-x-10 gap-y-3"
                      // container
                      // rowSpacing={1}
                      // columnSpacing={5}
                      // sx={{ mt: 2, margin: '10px', border: 'solid', borderWidth: '1px', borderColor: 'lightgray', width: '95%',
                      //  borderStyle: 'dotted' }}
                    >
                      <div className="flex items-center  sm:ml-[118px] w-20 sm:w-44 ">
                        {/* <img className="profileimg" src={Profileimg} />  */}
                        <div className="relative">
                          {/* {props?.editmembers ? */}
                          <img
                            src={selectedPhoto ? selectedPhoto : userImage}
                            alt="userImage"
                            className="border md:w-24  h-20  rounded-full"
                          />
                          {/* // : <img src={addmember.photo ? `${'data:image;base64,'}` + addmember.photo : steth} alt="stethescope" className="lg:w-24 lg:h-24 h-20 mt-6 rounded-full" />} */}
                          <div className="-mt-4 justify-end flex6 absolute left-16 bottom-0">
                            <label className="w-6 h-6 flex justify-center cursor-pointer text-xs bg-brand-primary font-normal rounded-full ">
                              <input
                                type="file"
                                accept="image/*"
                                onChange={(e) => {
                                  changeHandler(e.target.files[0]);
                                }}
                                className="hidden"
                              />
                              <img src={camera} alt="camera" className="w-5" />
                            </label>
                          </div>
                        </div>
                      </div>

                      <div
                        sx={{
                          marginTop: ["0px", "33px"], // Responsive marginTop values for mobile and larger screens
                          marginLeft: ["0px", "30px"], // Responsive marginLeft values for mobile and larger screens
                        }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                      >
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Prefix<spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          select
                          required
                          name="salutation"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.salutation}
                          fullWidth
                          size="small"
                        >
                          <MenuItem value={"Select"}>Select</MenuItem>
                          <MenuItem value={"Mr."}>Mr.</MenuItem>
                          <MenuItem value={"Mrs."}>Mrs.</MenuItem>
                          <MenuItem value={"Ms."}>Ms.</MenuItem>
                        </TextField>
                      </div>
                      <div
                        sx={{ marginTop: "33px" }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                      >
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          First Name
                          <spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          required
                          name="firstName"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.firstName}
                          fullWidth
                          size="small"
                          error={shouldShowErrorName(
                            memberDetails[index]?.firstName
                          )}
                          helperText={
                            shouldShowErrorName(
                              memberDetails[index]?.firstName
                            ) && "Invalid Name"
                          }
                          onKeyPress={(e) => {
                            // Allow only alphabetic characters
                            const isValidInput =
                              /^[A-Za-z]+(\s[A-Za-z]*)?$/.test(
                                e.target.value + e.key
                              );
                            if (!isValidInput) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                      <div
                        sx={{ marginTop: "33px" }}
                        item
                        xs={12}
                        sm={3}
                        md={3}
                      >
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Last Name<spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          required
                          name="lastName"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.lastName}
                          fullWidth
                          size="small"
                          error={shouldShowErrorName2(
                            memberDetails[index]?.lastName
                          )}
                          helperText={
                            shouldShowErrorName2(
                              memberDetails[index]?.lastName
                            ) && "Invalid Name"
                          }
                          onKeyPress={(e) => {
                            // Allow only alphabetic characters
                            const isValidInput = /^[A-Za-z]*$/.test(e.key);
                            if (!isValidInput) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>

                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Relation<spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          select
                          required
                          name="relation"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.relation}
                          fullWidth
                          size="small"
                          className="text-base font-semibold"
                        >
                          {relations &&
                            relations?.map((value, index) => (
                              <MenuItem value={value.relationship} key={index}>
                                <span className="text-base ">
                                  {value.relationship}
                                </span>
                              </MenuItem>
                            ))}
                        </TextField>
                      </div>
                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Mobile Number{" "}
                          <spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          required
                          name="mobile"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.mobile}
                          fullWidth
                          size="small"
                          maxLength={10}
                          minLength={10}
                          error={shouldShowError(memberDetails[index]?.mobile)}
                          helperText={
                            shouldShowError(memberDetails[index]?.mobile) &&
                            "Invalid mobile number"
                          }
                          onKeyPress={(e) => {
                            // Allow only numeric input and limit length to 13 characters
                            const isValidInput = /^[0-9]*$/.test(e.key);
                            if (!isValidInput || e.target.value.length >= 10) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Email
                        </InputLabel>
                        <TextField
                          required
                          name="email"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.email}
                          fullWidth
                          size="small"
                        />
                      </div>
                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Gender<spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          select
                          required
                          name="gender"
                          disabled={true}
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.gender}
                          fullWidth
                          size="small"
                        >
                          <MenuItem value={"M"}>Male</MenuItem>
                          <MenuItem value={"F"}>Female</MenuItem>
                        </TextField>
                      </div>
                      <div item xs={12} sm={3} md={3}>
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    name="dob"
                                                    label="DOB"
                                                    value={value}

                                                    onChange={(newValue) => handleDateChange(newValue)}
                                                />
                                                <DateField defaultValue={dayjs('2022-04-17')} sx={{ height: '0.25rem' }} />
                                            </LocalizationProvider> */}
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          DOB <spam style={{ color: "#F34040" }}>*</spam>
                        </InputLabel>
                        {/* <TextField
                                                required
                                                name="dob"
                                                placeholder='mm/DD/yyyy'
                                                onChange={(e) => handleInputChange(index, e)}
                                                value={memberDetails[index]?.dob}
                                                fullWidth
                                                size='small'
                                            /> */}
                        <DatePicker
                          name="dob"
                          selected={memberDetails[index]?.dob || null}
                          onChange={(date) => handleDateChange(date, index)}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          placeholderText="DD/MM/YYYY"
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          customInput={<input style={datePickerStyles.input} />}
                        />
                      </div>
                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Blood Group
                          {/* <spam style={{ color: '#F34040' }}> * </spam> */}
                        </InputLabel>
                        <TextField
                          select
                          required
                          name="bloodGroup"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.bloodGroup}
                          fullWidth
                          size="small"
                        >
                          {bloodGroups &&
                            bloodGroups?.map((value, index) => (
                              <MenuItem value={value.code} key={index}>
                                <span className="text-base">{value.code}</span>
                              </MenuItem>
                            ))}
                        </TextField>
                      </div>
                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Address Line 1{" "}
                          <spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          required
                          name="address1"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.address1}
                          fullWidth
                          size="small"
                        />
                      </div>
                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Address Line 2
                        </InputLabel>
                        <TextField
                          name="address2"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.address2}
                          fullWidth
                          size="small"
                        />
                      </div>
                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          State <spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          select
                          required
                          name="state"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.state}
                          fullWidth
                          size="small"
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: "20rem",
                                },
                              },
                            },
                          }}
                        >
                          cities
                          {states &&
                            states?.map((value, index) => (
                              <MenuItem value={value.code} key={index}>
                                <span className="text-base">
                                  {value.description}
                                </span>
                              </MenuItem>
                            ))}
                        </TextField>
                      </div>
                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          District <spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          select
                          required
                          name="city"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.city}
                          fullWidth
                          size="small"
                          SelectProps={{
                            MenuProps: {
                              PaperProps: {
                                style: {
                                  maxHeight: "20rem",
                                },
                              },
                            },
                          }}
                        >
                          {cities &&
                            cities?.map((value, index) => (
                              <MenuItem value={value.description} key={index}>
                                <span className="text-base">
                                  {value.description}
                                </span>
                              </MenuItem>
                            ))}
                        </TextField>
                      </div>
                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Pincode <spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          required
                          name="pinCode"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.pinCode}
                          fullWidth
                          size="small"
                          disabled
                        />
                      </div>
                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          eClinic
                        </InputLabel>
                        <TextField
                          // select
                          required
                          name="eClinic"
                          onChange={(e) => handleInputChange(index, e)}
                          fullWidth
                          value={memberDetails[index]?.eClinic}
                          size="small"
                          disabled
                        >
                          {/* {!clinics?.length ? eClinicsData?.map((option, index) => (
                                                    <MenuItem key={index} value={option}>
                                                        {option.hospitalCode}
                                                    </MenuItem>
                                                )) : clinics?.map((rec, index) => (
                                                    <MenuItem key={index} value={rec}>
                                                        {rec?.eclinicsName}
                                                    </MenuItem>
                                                ))} */}
                        </TextField>
                      </div>
                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Aadhaar No. (Last 4 digit only){" "}
                          <spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          required
                          name="aadharId"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.aadharId}
                          fullWidth
                          size="small"
                          maxLength={4}
                          error={shouldShowErrorAdhar(
                            memberDetails[index]?.aadharId
                          )}
                          helperText={
                            shouldShowErrorAdhar(
                              memberDetails[index]?.aadharId
                            ) && "Please enter last 4 digit of your aadhaar no."
                          }
                          onKeyPress={(e) => {
                            // Allow only numeric input and limit length to 13 characters
                            const isValidInput = /^[0-9]*$/.test(e.key);
                            if (!isValidInput || e.target.value.length >= 4) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>

                      <div item xs={12} sm={3} md={3}>
                        <InputLabel sx={{ color: "#262626", margin: "2px" }}>
                          Emergency Contact Number{" "}
                          <spam style={{ color: "#F34040" }}> * </spam>
                        </InputLabel>
                        <TextField
                          required
                          name="emergencyContact"
                          onChange={(e) => handleInputChange(index, e)}
                          value={memberDetails[index]?.emergencyContact}
                          fullWidth
                          size="small"
                          maxLength={10}
                          minLength={10}
                          error={shouldShowError(
                            memberDetails[index]?.emergencyContact
                          )}
                          helperText={
                            shouldShowError(
                              memberDetails[index]?.emergencyContact
                            ) && "Invalid mobile number"
                          }
                          onKeyPress={(e) => {
                            // Allow only numeric input and limit length to 13 characters
                            const isValidInput = /^[0-9]*$/.test(e.key);
                            if (!isValidInput || e.target.value.length >= 10) {
                              e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="w-full mt-[13px]">
                      <span
                        style={{
                          color: "#262626",
                          margin: "1rem 2px 2px 2px",
                          overflowWrap: "break-word",
                        }}
                      >
                        Users are recommended to have
                        smartphones/tablet/laptop/desktop to access our CureBay
                        app and avail healthcare services at your doorstep.
                      </span>
                      <div
                        className="sm:flex  "
                        style={{ marginTop: "0.5rem" }}
                      >
                        <div>
                          <input
                            type="radio"
                            name="smartPhoneUser"
                            value="I agree"
                            checked={selectedItem[index] === "I agree"}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                          />
                          <span style={{ marginLeft: "5px" }}>I agree</span>
                        </div>
                        <div className="flex sm:ml-[10px] ">
                          <input
                            type="radio"
                            name="smartPhoneUser"
                            value="not available"
                            checked={selectedItem[index] === "not available"}
                            onChange={(event) =>
                              handleInputChange(index, event)
                            }
                          />
                          <span
                            className="mt-5 sm:mt-0 w-full"
                            style={{ marginLeft: "5px" }}
                          >
                            Smartphones/ tablet/ laptop/ desktop not available
                          </span>
                        </div>
                      </div>
                      {errorMessage && (
                        <span className="text-red-500 text-sm">
                          {errorMessage}
                        </span>
                      )}
                    </div>
                    {index !== 0 && (
                      <div className="flex flex-col py-2 px-2">
                        <Button
                          id="remove-button"
                          size="small"
                          fullWidth
                          onClick={() => remove(index)}
                          sx={{ margin: "4px" }}
                        >
                          Remove
                        </Button>
                      </div>
                    )}

                    <div className="flex mt-4">
                      {loader ? (
                        <CircularProgress />
                      ) : (
                        <button
                          className=" border-chathams-blue-200 border-2 px-3 py-2 rounded-md text-white bg-[#396DDD] mr-4 font-semibold w-full sm:w-[12rem] "
                          onClick={onSaveMember}
                        >
                          Save Member
                        </button>
                      )}
                      <button
                        className="text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 rounded-md w-[7rem] hover:text-white hover:bg-[#396DDD] font-semibold"
                        style={{ border: "1px solid #396DDD" }}
                        onClick={() => navigate(APP_ROUTES.BUYCAREPLAN)}
                      >
                        Buy Plan
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}

        <div className=" flex flex-col sm:flex-row">
          <div className="flex px-0 sm:px-16 pb-0 sm:pb-16 mt-4 sm:mt-2">
            <h1 className="font-poppins font-medium text-lg sm:text-[20px] ml-2 ">
              Do you want to{" "}
              <span className="font-poppins font-semibold text-lg sm:text-[20px]">
                {" "}
                add more{" "}
              </span>
              members ?
            </h1>
          </div>
          <div className="flex gap-5 mt-3 sm:mt-0 justify-center">
            <button
              className="text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 mb-20 rounded-md hover:text-white hover:border-bluish hover:bg-bluish font-semibold"
              style={{ border: "1px solid #396DDD" }}
              onClick={add}
            >
              + Add More
            </button>
            <button
              className="text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 mb-20 rounded-md hover:text-white hover:border-seegreen hover:bg-seegreen font-semibold"
              style={{ border: "1px solid #396DDD" }}
              onClick={() => navigate(APP_ROUTES.MEMBERDETAILS)}
            >
              Go Back
            </button>
            {/* <button className='text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 rounded-md hover:text-white hover:border-seegreen hover:bg-seegreen mx-4 font-semibold ' onClick={onSaveMember} >Save Details</button>
                        <button className='text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 rounded-md hover:text-white hover:border-seegreen hover:bg-seegreen font-semibold' onClick={() => navigate(APP_ROUTES.BUYCAREPLAN)}>Buyplan</button> */}
          </div>
        </div>
      </div>
      {/* <Footer i={1} /> */}
      {screen > 500 ? (
        <Footer i={1} />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
      <Modal open={open} onClose={handleClose}>
        <div className="md:fixed inset-0 md:flex items-center justify-center h-full overflow-auto">
          <div className="md:w-[56rem] md:h-[41rem] bg-white p-4  relative md:flex md:flex-col overflow-auto">
            <div className="w-full flex justify-end">
              <IconButton onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
            </div>
            <div className="w-full h-full flex flex-col justify-center items-center">
              <img src={image} alt="Sorry" />
              <h1 className="font-poppins text-[20px] font-medium text-center m-8">
                Unfortunately, no nearby eClinic services are available at the
                moment. Our team will reach out shortly to assist you. Please
                proceed with adding a member. Thank you for your patience and
                understanding.
              </h1>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddMember;
