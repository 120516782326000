import { AUTH } from '../constants/authconstants';
const CryptoJS = require("crypto-js");
const SecureStorage = require('secure-web-storage')
const SECRET_KEY = 'secureMyData';

export var secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
        key = CryptoJS.SHA256(key, SECRET_KEY);
        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = CryptoJS.AES.encrypt(data, SECRET_KEY);
        data = data.toString();
        return data;
    },
    decrypt: function decrypt(data) {
        data = CryptoJS.AES.decrypt(data, SECRET_KEY);
        data = data.toString(CryptoJS.enc.Utf8);
        return data;
    }
});

const initialState = {
    userData: {},
    token: secureStorage.getItem('token') ? secureStorage.getItem('token') : null,
    
};

const AuthReducer = (state = initialState, action) => {
    const { type, payload } = action;
    const localStorageData = localStorage.getItem('loginObj');
    const newUserData = localStorageData || payload;

    switch (type) {
        case AUTH.SETOKEN:
            return {
                ...state,
                token: payload.access_token,
                isLoading: true,
                isError: false
            };
        case AUTH.USERDATA:
            return {
                ...state,
                userData: newUserData
            };
        default:
            return state;
    }
}


export default AuthReducer;