import services from "./index";
import httpcommon from "./httpcommon"
import axios from "axios";
class myprofileservice {
  getmyplanapi(payload) {
    return httpcommon.post(
      "/subscriptions/getPlans", payload
    );
  };

  changepasswrd(payload) {
    return httpcommon.post(
      "/changePassword/update", payload
    );
  };
  fetchRelations() {
    return httpcommon.post(
      "/careplans/relationship"
    );
  };
  fetchBloodGroups() {
    return httpcommon.post(
      "/dropdown/bloodGroup")
  };
  fetchStates() {
    return httpcommon.post(
      "/dropdown/state")
  };
  fetchCountries() {
    return httpcommon.post(
      "/location/getAllCountries")
  }
  fetchStatesByCountries(payload) {
    return httpcommon.post(
      "/location/getAllStates", payload)
  }
  fetchCitiesByStates(payload) {
    return httpcommon.post(
      "/location/getAllCities", payload)
  }
}
export default new myprofileservice();
