import httpCommon from "./httpcommon";
class Login {
    generateOtp(payload) {
        return httpCommon.post("forgetPassword/forgetPasswordDetails", payload);
    }
    getCode(payload) {
        return httpCommon.post("forgetPassword/getCode", payload);
    }
    updatePass(payload) {
        return httpCommon.post("forgetPassword/updatePasswordDetails", payload);
    }
}

export default new Login();