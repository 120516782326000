import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "primereact/dialog";
import moment from "moment";
import {
  Box,
  Card,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
} from "@mui/material";
import loginservice from "../../store/services/loginservice";
import Footer from "../../components/webflow/postloginfooter";
import { useDispatch, useSelector } from "react-redux";
import { getOurCareplans } from "../../store/actions/howitworks";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import hearthealth from "../../assets/images/Heart Health.svg";
import diabatic from "../../assets/images/Diabetic Care.svg";
import general from "../../assets/images/General Health.svg";
import Womens from "../../assets/images/Womens' Health.svg";
import ortho from "../../assets/images/Ortho Care.svg";
import { useSnackbar } from "../common/snackbar";
import { updateCart } from "../../store/actions/login";
import { COMMONCONSTANTS } from "../../store/constants/commonconstants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import Img from "../../assets/images/cart.png";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { TabContext, TabPanel } from "@mui/lab";
import FooterMobileVersion from "../../components/homepage/FooterMobileVersion";
import MobileFooter from "./../homepage/mobileFooter";
import jsPDF from "jspdf";

const Cart = () => {
  const divRef = useRef(null);
  const dispatch = useDispatch();
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const [memberDetails, setMemberDetails] = useState([]);
  const [cartList, setCartList] = useState([]);
  const [careplanDetails, setCarePlanDetails] = useState([]);
  const userData = useSelector((state) => state.AuthReducer.userData);
  const [totalAmount, setTotalAmount] = useState(null);
  const [paymentDuration, setPaymentDuration] = useState({});
  const [packageDuration, setPackageDuration] = useState({});
  const [selectedMember, setSelectedMember] = useState({});
  const [selectedMemberaddress, setSelectedMemberaddress] = useState("");
  const [showAnchor, setShowAnchor] = useState(false);
  const [initializedItems, setInitializedItems] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const cbInstanceRef = useRef(null);
  const cartRef = useRef(null);
  const navigate = useNavigate();
  const [bytecode, setBytecode] = useState();
  const [usefirstName, setfirstName] = useState();
  const [responseData, setResponseData] = useState({});

  function formatDate(dateString) {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("en-GB", options);
    return formattedDate;
  }

  const datePickerStyles = {
    input: {
      width: "100%",
      padding: "0.375rem 0.75rem",
      fontSize: "0.875rem",
      lineHeight: "2",
      color: "#495057",
      backgroundColor: "#fff",
      backgroundClip: "padding-box",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      transition:
        "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out",
      justifyContent: "space-between",
      alignItems: "center",
    },
  };

  const isMobile = () => window.innerWidth <= 768;
  useEffect(() => {
    const initializeChargebee = () => {
      const isProd = window.location.hostname === "curebaytotalcare.com";
      const site = isProd ? "curebay" : "curebay-test";

      cbInstanceRef.current = window.Chargebee.init({
        site: site,
        isItemsModel: true,
      });
      cartRef.current = cbInstanceRef.current.getCart();
    };

    initializeChargebee();
  }, []);

  useEffect(() => {
    const isProd = window.location.hostname === "curebaytotalcare.com";
    const site = isProd ? "curebay" : "curebay-test";

    cbInstanceRef.current = window.Chargebee.init({
      site: site,
      isItemsModel: true,
    });
  }, []);

  useEffect(() => {
    async function fetchData() {
      const carePlansResponse = await getOurCareplans();
      if (
        carePlansResponse.status === 200 &&
        carePlansResponse?.data?.succesObject
      ) {
        setCarePlanDetails(carePlansResponse.data.succesObject);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const loginObjStr = localStorage.getItem("loginObj");
    const loginObj = JSON.parse(loginObjStr);
    const userObj = loginObj?.[0];

    let data = userData.length ? JSON.parse(userData) : userObj ? userObj : "";
    setfirstName(userObj.firstName);

    let payload = {
      code: data?.[0]?.userCode
        ? data[0].userCode
        : data.userCode
          ? data.userCode
          : null,
      email: data?.[0]?.email,
      mobile: data?.[0]?.mobile,
    };
    loginservice
      .fetchUserList(payload)
      .then((res) => {
        setMemberDetails(res?.data?.succesObject);
      })
      .catch((error) => {
        console.error("Failed to fetch user list:", error);
      });
  }, []);
  useEffect(() => {
    let totalPrice = 0;
    cartList?.forEach((rec, index) => {
      const planDetails = careplanDetails.find(
        (plan) => plan.planId === rec.planId
      );
      let price;
      if (paymentDuration[index] === "ONETIMEPAYMENT") {
        price = planDetails?.price * packageDuration[index];
      } else {
        price = planDetails?.price;
      }

      totalPrice += price;
    });
    setTotalAmount(totalPrice);
  }, [
    totalAmount,
    cartList,
    careplanDetails,
    paymentDuration,
    packageDuration,
  ]);

  useEffect(() => {
    let data = userData.length ? JSON.parse(userData) : "";
    if (cartList?.length === 0) {
      setIsLoading(true);
      let payload = {
        userCode: data?.[0]?.userCode
          ? data[0].userCode
          : data?.[1]?.patientCode
            ? data[1].patientCode
            : null,
      };
      loginservice
        .cartList(payload)
        .then((res) => {
          setCartList(res?.data?.succesObject);
          dispatch({
            type: COMMONCONSTANTS.SUCCESS_FETCHCART,
            payload: res?.data?.succesObject,
          });
        })
        .catch((error) => { })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userData, cartList.length, dispatch]);

  useEffect(() => {
    cartList.forEach((rec, index) => {
      if (rec?.period === "monthly" && !initializedItems[index]) {
        setPaymentDuration((prevState) => ({
          ...prevState,
          [index]: "MONTHLY",
        }));
        setInitializedItems((prevState) => ({ ...prevState, [index]: true }));
      } else if (rec?.period === "yearly" && !initializedItems[index]) {
        setPaymentDuration((prevState) => ({
          ...prevState,
          [index]: "ONETIMEPAYMENT",
        }));
        setInitializedItems((prevState) => ({ ...prevState, [index]: true }));
      }
    });
  }, [cartList, initializedItems, setPaymentDuration]);

  const handlePaymentDurationChange = (event, index) => {
    const { value } = event.target;
    setPaymentDuration((prevState) => ({ ...prevState, [index]: value }));
    // Update the initialized status to true for this index
    setInitializedItems((prevState) => ({ ...prevState, [index]: true }));
  };

  const handlePackageDurationChange = (event, index) => {
    const { name, value } = event.target;
    setPackageDuration((prevState) => ({ ...prevState, [index]: value }));
  };

  const handleMemberChange = (event, index) => {
    const { name, value } = event.target;
    setSelectedMember((prevState) => ({ ...prevState, [index]: value }));

    // setSelectmemberaddress
  };

  const handleDateChange = (date, index) => {
    let data = [...cartList];
    data[index] = {
      ...data[index],
      effectiveStartDate: date,
    };
    setCartList(data);
  };

  // const effectivedate = cartList.effectiveStartDate;
  // console.log(cartList[0].effectiveStartDate, cartList, 'cartList')

  // const deleteFromCart = async (e, value) => {
  //     let data = JSON.parse(userData);
  //     let payload;
  //     if (value === 'single') {
  //         payload = {
  //             "userCode": data?.[0]?.userCode,
  //             "cartType": 2,
  //             "cartVOList": [
  //                 {
  //                     "userId": data?.[0]?.userSeqId,
  //                     "userCode": data?.[0]?.userCode,
  //                     "planId": e?.planId,
  //                     "planName": e?.planName,
  //                     "cartId": e?.cartId
  //                 },
  //             ]
  //         }
  //     }
  //     else {
  //         payload = {
  //             "userCode": data?.[0]?.userCode,
  //             "cartType": 3
  //         }
  //     }
  //     const res = await updateCart(payload);
  //     if (res?.status && res?.data?.responseCode === '200') {
  //         setCartList([]);
  //     }
  // };

  // const [isLoading, setShowLoader] = useState(false);
  // useEffect(() => {
  //     if (isLoading) {

  //         setShowLoader(true);

  //         const timer = setTimeout(() => {
  //             setIsLoading(false);
  //             setShowLoader(false);
  //         }, 1000);
  //         return () => clearTimeout(timer);
  //     }
  // }, [isLoading]);


  const deleteFromCart = async (e, value) => {

    try {


      let data = JSON.parse(userData);

      let payload = []

      if (value === "single") {

        console.log(data?.[0]?.userCode, data?.[0]?.userSeqId, e?.planId, e?.planName, e?.cartId, value, "data are coming2")
        payload = {
          userCode: data?.[0]?.userCode,
          cartType: 2,
          cartVOList: [
            {
              userId: data?.[0]?.userSeqId,
              userCode: data?.[0]?.userCode,
              planId: e?.planId,
              planName: e?.planName,
              cartId: e?.cartId,
            },
          ],
        };

      } else {
        payload = {
          userCode: data?.[0]?.userCode,
          cartType: 3,
        };
      }

      const res = await updateCart(payload);
      setIsLoading(false)


      if (res?.status && res?.data?.responseCode === "200") {
        setCartList([]);

        localStorage.removeItem("responseData");
        // setTimeout(() => {
        //     setCartList([]);
        // }, 2000);
      }
    } catch (error) {
      setSnackbarMessage("An error occurred while removing the items", "error");
    }
  };

  const convertToPDFAndSendToAPI = async () => {
    const content = document.getElementById("contentContainer").innerText;
    const pdf = new jsPDF();

    let yPosition = 10;
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.width - 2 * margin;

    pdf.setFontSize(12);

    const addPage = () => {
      pdf.addPage();
      yPosition = margin;
    };

    pdf.setTextColor(0, 0, 0);

    const lines = pdf.splitTextToSize(content, pageWidth);

    lines.forEach((line) => {
      if (
        yPosition + pdf.getTextDimensions(line).h >
        pdf.internal.pageSize.height - margin
      ) {
        addPage();
      }

      pdf.setTextColor(0, 0, 0);

      pdf.text(line, margin, yPosition);
      yPosition += pdf.getTextDimensions(line).h + 2;
    });

    // pdf.save('consent.pdf');
    // const byteArray = pdf.output();

    const base64String = pdf.output("dataurlstring");
    const base64WithoutPrefix = base64String.split(",")[1];

    console.log(base64WithoutPrefix, "base64");
    setBytecode(base64WithoutPrefix);
  };
  useEffect(
    (responseData) =>
      cbInstanceRef.current.setCheckoutCallbacks(function (cart) {
        const storedResponseDataString = localStorage.getItem('responseData');
        const storedResponseData = JSON.parse(storedResponseDataString);
        return {
          success: function (hpid) {
            console.log("successssss", hpid, responseData);

            deleteFromCart(storedResponseData, "single");
            window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            window.dataLayer.push({
              event: "purchase",
              ecommerce: {
                value: 100,
                items: storedResponseData
              },
            });

            // deleteFromCart(responseData, "single");




          },
        };
      }),
    []
  );
  const openCheckout = (e, planDuration, consentFormUrl) => {
    let patientDetails, customerEmail;
    let data = userData.length ? JSON.parse(userData) : "";
    customerEmail = data[0]?.email;
    const planMappings = {
      MONTHLY: {
        1: "General-Health-Care-INR-Monthly",
        3: "Diabetes-Care-INR-Monthly",
        4: "Cardiac-Care-INR-Monthly",
        5: "Ortho-Care-INR-Monthly",
        6: "Womens-Health-Care-INR-Monthly",
      },
      ONETIMEPAYMENT: {
        1: "General-Health-Care-INR-Yearly",
        3: "Diabetes-Care-INR-Yearly",
        4: "Cardiac-Care-INR-Yearly",
        5: "Ortho-Care-INR-Yearly",
        6: "Womens-Health-Care-INR-Yearly",
      },
    };
    for (const key in selectedMember) {
      if (selectedMember[key].code === planDuration?.list?.[0]?.patientCode) {
        patientDetails = selectedMember[key];
        break;
      }
    }
    const planId =
      planMappings[planDuration?.list?.[0]?.paymentType]?.[e.planId];
    const planPriceQuantity = 1;
    const product = cbInstanceRef.current?.initializeProduct?.(
      planId,
      planPriceQuantity
    );
    product.data["cf_patient"] = patientDetails?.name;
    product.data["cf_relation"] = patientDetails?.relation;
    product.data["cf_usercode"] = planDuration?.list?.[0]?.userCode;
    product.data["cf_userid"] = planDuration?.list?.[0]?.userId;
    product.data["cf_cpseqid"] = planDuration?.list?.[0]?.cpSeqId;
    product.data["cf_patientid"] = planDuration?.list?.[0]?.patientId;
    product.data["cf_patientcode"] = planDuration?.list?.[0]?.patientCode;
    product.data["cf_subsperiod"] = planDuration?.list?.[0]?.subsPeriod;
    product.data["cf_totalamount"] = planDuration?.list?.[0]?.totalAmount;
    product.data["cf_paymenttype"] = planDuration?.list?.[0]?.paymentType;
    product.data["cf_startdate"] = planDuration?.list?.[0]?.date;
    product.data["cf_consentformflag"] = "1";
    product.data["cf_consentform"] = consentFormUrl;

    cartRef.current.replaceProduct(product);

    const customer = {
      first_name: "",
      last_name: "",
      company: "",
      email: customerEmail,
      phone: "",
    };
    cartRef.current.setCustomer(customer);
    cartRef.current.proceedToCheckout();
  };

  // const OnSaveClick = () => {
  //     const addSubscription = (e) => {
  //         const loginObjStr = localStorage.getItem('loginObj');
  //         const loginObj = JSON.parse(loginObjStr);
  //         const userObj = loginObj?.[0];
  //         const request = {
  //             list: cartList.map((rec, index) => {
  //                 return {
  //                     cpSeqId: rec?.planId,
  //                     userId: userObj?.userSeqId,
  //                     userCode: userObj?.userCode,
  //                     patientCode: selectedMember[index]?.code,
  //                     patientId: selectedMember[index]?.id,
  //                     subsPeriod: packageDuration[index],
  //                     totalAmount: totalAmount,
  //                     paymentType: paymentDuration[index],
  //                     date: selectedDates[index]?.date
  //                 };
  //             })
  //         };
  //         const errors = [];

  //         if (cartList?.length !== (Object.keys(selectedMember)?.length)) {
  //             errors.push('Please select member for all the plans');
  //         }

  //         if (cartList?.length !== (Object.keys(paymentDuration)?.length)) {
  //             errors.push('Please select payment duration for all the plans');
  //         }

  //         if (cartList?.length !== (Object.keys(packageDuration)?.length)) {
  //             errors.push('Please select package duration for all the plans');
  //         }

  //         if (cartList?.length !== (Object.keys(selectedDates)?.length)) {
  //             errors.push('Please select Date for all the plans');
  //         }

  //         if (!errors.length) {
  //         loginservice.subscriptionssave(request).then((res) => {
  //             if (res?.status && res?.data?.responseCode === '200') {
  //                 setSnackbarMessage(res?.data?.responseMessage, 'success');
  //                 deleteFromCart(null, 'all');
  //                 setShowAnchor(true);
  //             }
  //             else
  //                 setSnackbarMessage(res?.data?.responseMessage, 'error');
  //         });
  //         } else {
  //         setSnackbarMessage(errors.join(' and '), 'error');
  //         }

  //     };
  //     addSubscription();
  // };

  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [value, setValue] = useState("1");
  const [consent, setConsent] = useState();
  const [open, setOpen] = useState(false);
  const [screen, setscreen] = useState(window.innerWidth);
  const [effectivedate, setEffectivedate] = useState();

  const handleDailog = (data) => {
    const addSubscriptionnew = (e) => {
      // setEffectivedate(date);
      cartList.map((rec, index) => {
        if (rec.cartId === data.cartId) {
          const selectedPatientname = selectedMember[index];
          const selectedDate = formatDate(rec?.effectiveStartDate);
          console.log(selectedPatientname, "selectedDate");
          if (!selectedPatientname) {
            setSnackbarMessage("Please selected the member", "error");
          } else if (selectedDate === "Invalid Date") {
            setSnackbarMessage(
              "Please select the effective start date",
              "error"
            );
          } else {
            setEffectivedate(selectedDate);
            const { address1, address2, city, pincode, state } =
              selectedPatientname;
            let fullAddress = `${address1}`;

            if (address2) {
              fullAddress += `, ${address2}`;
            }

            fullAddress += `, ${city}, ${state} , ${pincode}`;
            setSelectedMemberaddress(fullAddress);
            // setSelectedMemberaddress(selectedPatientname.address1,selectedPatientname.city,selectedPatientname.pincode,selectedPatientname.state);
            setOpen(true);
          }
        }
      });
    };
    addSubscriptionnew();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = () => {
    setIsConsentChecked(true);
    setOpen(false);
    convertToPDFAndSendToAPI();
  };
  const handleDisagree = () => {
    setIsConsentChecked(false);
    setOpen(false);
  };
  const handleConsentCheck = () => {
    setIsConsentChecked(!isConsentChecked);
  };

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  // useEffect(() => {
  //   if (selectedMember[0] && selectedMember[0].address1) {
  //     setSelectedMemberaddress(selectedMember[0].address1)
  //   }
  // }, [selectedMember]);

  // useEffect(() => {
  //   if (selectedMember[0]) {
  //     const { address1, address2, city, pincode, state } = selectedMember[0];
  //     let fullAddress = `${address1}`;

  //     if (address2) {
  //       fullAddress += `, ${address2}`;
  //     }

  //     fullAddress += `, ${city}, ${state} , ${pincode}`;
  //     setSelectedMemberaddress(fullAddress);
  //   }
  // }, [selectedMember]);

  const OnSaveClick = (data) => {
    const addSubscription = (e) => {
      const loginObjStr = localStorage.getItem("loginObj");
      const loginObj = JSON.parse(loginObjStr);
      const userObj = loginObj?.[0];
      const errors = [];
      const request = cartList

        .map((rec, index) => {
          if (rec.cartId === data.cartId) {
            const paymentType = paymentDuration[index];
            const subsPeriod = "12"; // packageDuration[index] since this is the only option available
            const selectedPatient = selectedMember[index];
            const selectedDate = formatDate(rec?.effectiveStartDate);

            if (
              paymentType &&
              subsPeriod &&
              selectedPatient &&
              !(selectedDate === "Invalid Date")
            ) {
              const isInvalidPlan =
                selectedPatient?.gender === "M" &&
                rec?.planName === "Womens Health Care";
              const isEclinicPresent =
                selectedPatient?.eclinics === "Y" ? true : false;
              if (isInvalidPlan) {
                errors.push(
                  "Please ensure selected member qualifies for Women's Health Care for item "
                );
                return null;
              }
              if (!isEclinicPresent) {
                errors.push(
                  "The member you've selected doesn't have eclinics assigned. Please try again after eclinics are assigned to them."
                );
                return null;
              }

              return {
                cpSeqId: rec?.planId,
                userId: userObj?.userSeqId,
                userCode: userObj?.userCode,
                patientCode: selectedPatient.code,
                patientId: selectedPatient.id,
                subsPeriod: subsPeriod,
                totalAmount: totalAmount,
                paymentType: paymentType,
                date: selectedDate,
              };
            } else {
              if (!selectedPatient)
                errors.push("Please ensure you have selected the member ");
              if (selectedDate === "Invalid Date")
                errors.push("Please select the effective start date");
            }
          } else {
            return null;
          }
          return null;
        })
        .filter((item) => item !== null);

      // const requestObj = { list: request };
      const updatedCartList = request.map((item) => ({
        ...item, // Copy the existing properties of the item
        cf_consentform: bytecode, // Add your additional attribute here
      }));

      const requestdata = updatedCartList;
      // const requestObj = { list: request };

      const requestObj = {
        list: requestdata,
      };

      window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      window.dataLayer.push({
        currency: "USD",
        event: "begin_checkout",
        ecommerce: {
          value: totalAmount,
          items: [
            {
              cpSeqId: requestdata[0]?.cpSeqId,
              userId: requestdata[0]?.userId,
              userCode: requestdata[0]?.userCode,
              patientCode: requestdata[0]?.patientCode,
              patientId: requestdata[0]?.patientId,
              subsPeriod: requestdata[0]?.subsPeriod,
              // totalAmount: totalAmount,
              paymentType: requestdata[0]?.paymentType,
              date: requestdata[0]?.date,
            },
          ],
        },
      });

      if (isConsentChecked === false) {
        errors.push("Please Read and Sign Consent Form");
      }
      if (errors.length === 0) {
        loginservice.subscriptionssave(requestObj).then((res) => {
          if (res?.status && res?.data?.responseCode === "200") {
            const consentFormUrl = res?.data?.succesObject?.consentFormUrl;
            // deleteFromCart(data, "single");
            const responseDataString = JSON.stringify(data);
            localStorage.setItem('responseData', responseDataString);

            setResponseData(data);
            setShowAnchor(true);
            openCheckout(data, requestObj, consentFormUrl);
          } else {
            setSnackbarMessage(res?.data?.responseMessage, "error");
          }
        });
      } else {
        setSnackbarMessage(errors.join(" and "), "error");
      }
    };
    addSubscription();
  };

  const onProceedClick = () => {
    setShowAnchor(false);
  };

  return (
    <>
      {snackbarMessage}
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-900 opacity-75" />
          <div className="relative">
            <div className="w-20 h-20 border-4 border-t-4 border-gray-200 rounded-full animate-spin" />
          </div>
        </div>
      )}

      {cartList?.length !== 0 ? (
        <div className="rounded-full bg-chathams-blue-200 hover:bg-seegreen absolute top-28 cursor-pointer left-0 ml-16 p-2 sm:block hidden">
          <ChevronLeftIcon
            sx={{ color: "white" }}
            onClick={() => {
              navigate(APP_ROUTES.BUYCAREPLAN);
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="w-full ">
        <Grid
          container
          columnSpacing={3}
          rowSpacing={2}
          style={{ backgroundColor: "" }}
        >
          <Grid item xs={12} sm={6} md={8}></Grid>
        </Grid>
        {cartList?.length === 0 ? (
          <div>
            {isLoading === true ? (
              ""
            ) : (
              <div className=" rounded-md  bg-white  mx-auto flex flex-col items-center justify-center">
                <img className="w-[28rem] mt-[90px]" src={Img} alt="" />
                <div className=" text-gray-800 font-poppins text-[20px] font-semibold text-center">
                  Your cart is empty. Please add items to the cart.
                </div>
                {/* <div className="textgray-3 text-base  text-center">{message.message2}</div> */}
                <button
                  onClick={() => {
                    navigate(APP_ROUTES.BUYCAREPLAN);
                  }}
                  className={`mt-3 mb-6 py-2 px-4 text-white disabled:cursor-default rounded transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none font-poppins`}
                  style={{ background: "#66B889" }}
                >
                  {" "}
                  Continue Shopping
                </button>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Grid
              container
              rowSpacing={2}
              sx={{
                display: "block",
                backgroundColor: "",
                marginTop: "2px",
                minHeight: "50vh",
                width: "100%",
              }}
            >
              {/* <Grid item xs={12} sm={8} md={8} sx={{ width: '100%', backgroundColor: "", display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                        <Grid container={'true'} item xs={12} spacing={2} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%', backgroundColor: "" }}> */}
              <Grid item xs={12} sm={12} md={12} sx={{ width: "100rem" }}>
                <div className="  flex flex-col justify-center items-start pl-4 sm:pl-0">
                  <span className="text-[20px] pb-4 font-bold text-black text-left sm:mt-[175px] mt-[98px] sm:ml-[64px] ml-[6px] ">
                    Your Cart
                  </span>
                  {/* <p className="text-lg mt-4 font-normal text-black text-center ">Continue Shopping</p> */}
                </div>

                {cartList && cartList.length > 0 && memberDetails?.length && (
                  <div className="flex flex-col justify-center items-start pl-4 sm:pl-0">
                    <span className="text-md text-black mb-2 pb-4 font-bold sm:ml-[66px] ml-[7px]">
                      Please note that the price will be converted from USD to
                      INR during checkout in compliance with RBI guidelines.
                    </span>
                  </div>
                )}

                <div>
                  {" "}
                  {memberDetails?.length > 0 ? (
                    <></>
                  ) : (
                    <div className="sm:flex sm:float-right text-center sm:text-left">
                      <h4>
                        You have not added any Care Receiver. Please add a
                        member.
                      </h4>
                      <button
                        onClick={() => {
                          navigate(APP_ROUTES.ADDMEMBER);
                        }}
                        className={`  sm:-mt-1 ml-2 py-2 px-4 text-white disabled:cursor-default rounded transition ease-in duration-200 text-center text-sm font-semibold shadow-md focus:outline-none font-poppins`}
                        style={{ background: "#66B889" }}
                      >
                        {" "}
                        Add Member
                      </button>
                    </div>
                  )}
                </div>
                <div className="flex flex-col justify-center items-center w-full">
                  {cartList?.map((rec, index) => {
                    const planDetails = careplanDetails.find(
                      (plan) => plan.planId === rec.planId
                    );
                    return (
                      <card
                        sx={{
                          width: "87rem",
                          height: "auto",
                          marginBottom: "2rem",
                          borderRadius: "10px",
                          overflow: "visible",
                        }}
                        elevation={5}
                        variant="outlined"
                      >
                        <div
                          className="flex flex-col w-full justify-between items-center sm:p-4 sm:pt-[32px] sm:pr-[66px] pr-[10px] sm:pl-[50px] pl-[5px]"
                          style={{ zIndex: "1" }}
                          key={index}
                        >
                          <div className="flex items-center justify-between w-full mx-10 border-b-2 pb-3">
                            <div className=" sm:mx-3 flex sm:pl-1">
                              {rec?.planName === "General Health Care" ? (
                                <img src={general} alt="General Health" />
                              ) : rec?.planName === "Diabetes Care" ? (
                                <img src={diabatic} alt="Diabetics Care" />
                              ) : rec?.planName === "Cardiac Care" ? (
                                <img src={hearthealth} alt="Cardiac Care" />
                              ) : rec?.planName === "Ortho Care" ? (
                                <img src={ortho} alt="Ortho Care" />
                              ) : rec?.planName === "Womens Health Care" ? (
                                <img src={Womens} alt="Womens Health Care" />
                              ) : (
                                <></>
                              )}

                              <p className="mb-0 sm:text-[22px] text-[16px] font-bold text-left text-chathams-blue-500 sm:ml-4 ml-2">
                                {rec?.planName}
                              </p>
                            </div>
                            <p className="sm:text-[20px] text-[16px] text-left my-2  text-seegreen font-semibold ">{`Price: $${paymentDuration[index] === "ONETIMEPAYMENT"
                              ? 12 * planDetails?.price
                              : paymentDuration[index] === "MONTHLY"
                                ? planDetails?.price
                                : ""
                              }`}</p>
                          </div>
                          <div className="flex flex-col sm:flex-row justify-start w-full sm:mx-6 py-4 border-b-2 sm:px-3 ">
                            <div className="flex items-center mb-2 ">
                              <InputLabel
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "#262626",
                                  paddingRight: "0.5rem",
                                }}
                                className="w-full sm:w-auto"
                              >
                                Payment Duration
                                <spam style={{ color: "#F34040" }}> * </spam>
                              </InputLabel>
                              <div className="w-full sm:w-auto ">
                                <TextField
                                  select
                                  required
                                  name="paymentDuration"
                                  onChange={(event) =>
                                    handlePaymentDurationChange(event, index)
                                  }
                                  sx={{ minWidth: "150px", maxWidth: "150px" }}
                                  size="small"
                                  value={paymentDuration[index] || ""}
                                >
                                  <MenuItem key="MONTHLY" value="MONTHLY">
                                    Monthly
                                  </MenuItem>
                                  <MenuItem
                                    key="ONETIMEPAYMENT"
                                    value="ONETIMEPAYMENT"
                                  >
                                    One-time payment
                                  </MenuItem>
                                </TextField>
                              </div>
                            </div>
                            <div className="flex items-center mb-2 sm:mx-4">
                              <InputLabel
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "#262626",
                                  paddingRight: "0.5rem",
                                }}
                                className="w-full sm:w-auto"
                              >
                                Package Duration
                                <spam style={{ color: "#F34040" }}> * </spam>
                              </InputLabel>
                              <div className="w-full sm:w-auto ">
                                <TextField
                                  select
                                  required
                                  name="packageDuration"
                                  onChange={(event) =>
                                    handlePackageDurationChange(event, index)
                                  }
                                  sx={{ width: "10rem" }}
                                  size="small"
                                  defaultValue="12"
                                >
                                  {/* <MenuItem key={1} value="6">
                                                                    6 Months
                                                                </MenuItem> */}
                                  <MenuItem key={2} value="12">
                                    12 Months
                                  </MenuItem>
                                </TextField>
                              </div>
                            </div>
                            <div className="flex items-center mb-2 sm:ml-3 sm:mr-6">
                              <InputLabel
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "#262626",
                                  paddingRight: "0.5rem",
                                }}
                                className="w-full sm:w-auto"
                              >
                                Select Member
                                <spam style={{ color: "#F34040" }}> * </spam>
                              </InputLabel>
                              <div className="w-full sm:w-auto ">
                                <TextField
                                  select
                                  required
                                  name="members"
                                  onChange={(event) =>
                                    handleMemberChange(event, index)
                                  }
                                  sx={{ width: "10rem" }}
                                  size="small"
                                >
                                  {memberDetails?.map((member) => (
                                    <MenuItem key={member.id} value={member}>
                                      {member?.name}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </div>
                            </div>

                            <div className="flex items-center mb-2 sm:ml-3 sm:mr-6">
                              <InputLabel
                                sx={{
                                  fontSize: "16px",
                                  fontWeight: 600,
                                  color: "#262626",
                                  paddingRight: "0.5rem",
                                  minWidth: "11rem",
                                }}
                                className="w-full sm:w-auto"
                              >
                                Effective start date
                                <spam style={{ color: "#F34040" }}> *</spam>
                              </InputLabel>
                              <div className="w-full sm:w-auto ">
                                <DatePicker
                                  name="effectiveStartDate"
                                  selected={
                                    cartList[index]?.effectiveStartDate || null
                                  }
                                  onChange={(date) =>
                                    handleDateChange(date, index)
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  placeholderText="DD/MM/YYYY"
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  customInput={
                                    <input style={datePickerStyles.input} />
                                  }
                                  minDate={new Date()}
                                  maxDate={new Date().setDate(
                                    new Date().getDate() + 30
                                  )}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="flex justify-start items-start sm:pb-6 sm:pt-5 pt-4 sm:mr-[1000px] text-[#0B4881] font-semibold">
                            {isConsentChecked == true ? (
                              <input
                                className=" mr-3 mt-[3px]"
                                checked
                                type="checkbox"
                              // checked={isConsentChecked}
                              // onChange={handleConsentCheck}
                              />
                            ) : (
                              ""
                            )}
                            Read and Sign{" "}
                            <span
                              // onClick={() =>
                              //    handleDailog(cartList[index])
                              //   }
                              onClick={() => {
                                handleDailog(rec);
                              }}
                              className="text-seegreen font-bold underline cursor-pointer pl-1"
                            >
                              {" "}
                              Consent Form{" "}
                            </span>{" "}
                            {/* {consent?.attributes?.SecondComp?.name} */}
                          </div>

                          <div className=" flex sm:justify-start justify-between w-full sm:ml-6 mt-5 ">
                            <div className="mb-[50px] sm:mb-0">
                              <button
                                className="sm:w-40 w-auto px-8 sm:px-0"
                                style={{
                                  textTransform: "capitalize",
                                  backgroundColor: "#004171",
                                  color: "white",
                                  borderRadius: "20px",
                                  height: "2.5rem",
                                }}
                                onClick={() => {
                                  OnSaveClick(rec);
                                }}
                              >
                                Checkout
                              </button>
                            </div>
                            {/* <div>
                                                    <button className='sm:ml-7 sm:w-40 w-auto px-8 sm:px-0' style={{ textTransform: "capitalize", borderColor: "red", color: "red", borderWidth: 1, borderRadius: "8px", height: "2.5rem" }} onClick={() => deleteFromCart(rec, 'single')}>
                                                        Remove
                                                    </button>
                                                </div> */}

                            <div>
                              <button
                                className="sm:ml-7 sm:w-40 w-auto px-8 sm:px-0"
                                style={{
                                  textTransform: "capitalize",
                                  borderColor: "red",
                                  color: "red",
                                  borderWidth: 1,

                                  height: "2.5rem",
                                  border: "1px solid red",
                                  borderRadius: "20px",
                                }}
                                onClick={() => deleteFromCart(rec, "single")}
                                disabled={isLoading}
                              >
                                {isLoading ? "Removing..." : "Remove"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </card>
                    );
                  })}
                </div>

                {/* </Grid>
                        </Grid> */}
              </Grid>
              {/* <div className='flex gap-5 mt-3 sm:mt-0 p-7'>
<button className='text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 mb-20 rounded-md hover:text-white hover:border-seegreen hover:bg-seegreen font-semibold' onClick={() => navigate(APP_ROUTES.BUYCAREPLAN)}>Go Back</button>
                    </div> */}
              {/* <Grid item xs={12} sm={4} md={4} >
                        <Card sx={{ width: '100%', height: '100%', borderRadius: '7px', marginLeft: "rem" }} elevation={5} variant='outlined'>
                            <div className='p-4 flex flex-col '>
                                <p className='text-[20px] text-chathams-blue font-bold border-b-2 pb-4'>Payment Details</p>
                                <div className='flex flex-col'>
                                    {cartList?.map((e, index) => {
                                        const planDetails = careplanDetails.find(plan => plan.planId === e.planId);
                                        return (
                                            <div className='flex flex-row w-full items-center my-[4px] justify-between' key={index}>
                                                <p className='text-sm text-brand-lightBlack'>{e?.planName}</p>
                                                <p className='text-sm text-brand-lightBlack font-semibold'>
                                                    {paymentDuration[index] === 'ONETIMEPAYMENT' ?
                                                        `$${planDetails?.price} * ${packageDuration[index]} = $${planDetails?.price * packageDuration[index]}`
                                                        : `$${planDetails?.price}`}
                                                </p>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className='pt-6'>
                                    <div className='flex flex-row w-full items-center my-[4px] justify-between ' >
                                        <p className='text-sm text-brand-lightBlack'>Total</p>
                                        <p className='text-sm text-brand-lightBlack font-semibold'>{`$${totalAmount}`}</p>
                                    </div>
                                  
                                    <button style={{ textTransform: "capitalize", width: "100%", marginTop: "5rem", backgroundColor: "#3CBA84", color: "white", borderRadius: "8px", height: "2.5rem" }} onClick={() => { OnSaveClick() }}>
                                        Checkout
                                    </button>
                                   
                                   
                                </div>
                            </div>
                        </Card>
                    </Grid> */}
            </Grid>

            <Dialog
              visible={open}
              // position="top"
              position={isMobile() ? "top" : "center"}
              header={<div className="font-bold text-lg">Consent Form</div>}
              style={{ height: "500px" }}
              className="sm:w-4/5 w-full"
              onHide={handleClose}
              footer={
                <div className="sm:mx-10 sm:my-10 my-3 flex">
                  {/* <button
                  className="text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 rounded-md hover:text-white hover:bg-chathams-blue-200 font-semibold w-28" style={{ border: "1px solid #396DDD" }}
                  onClick={handleAgree}
                >
                  Agree
                </button>
                <button
                  className="text-chathams-blue-200 mr-4 border-chathams-blue-200 border-2 px-3 py-2 rounded-md hover:text-white hover:bg-chathams-blue-200 font-semibold w-28 " style={{ border: "1px solid #396DDD" }}
                  onClick={handleDisagree}
                >
                  Disagree
                </button> */}

                  <button
                    className="btn-primary width-100 w-button w-[119px] h-[49px] "
                    style={{ paddingTop: "11px" }}
                    onClick={handleAgree}
                  >
                    Accept
                  </button>
                  <button
                    className="btn-primary width-100 w-button w-[119px] h-[49px]"
                    style={{ paddingTop: "11px" }}
                    onClick={handleDisagree}
                  >
                    Decline
                  </button>
                </div>
              }
              draggable={false}
              resizable={false}
            >
              <div>
                <div
                  className="sm:text-justify sm:w-auto w-fit"
                  ref={divRef}
                  id="contentContainer"
                >
                  <div class="mt-4">
                    <h1 class="text-2xl font-bold">CARE CONSENT FORM</h1>
                    This CARE CONSENT FORM, together with all the exhibits
                    hereto (the “Consent Form”) is made this{" "}
                    <span className="font-bold">{effectivedate}</span>{" "}
                    (“Effective Date”) by:
                  </div>

                  <div className="pl-10 pt-4">
                    <p>
                      {" "}
                      A. CureBay Technologies Private Limited, a private limited
                      company, bearing corporate identification number
                      U85110OR2020PTC035200, having its registered office at
                      Plot No. A-98, Budha Nagar, Laxmisagar, Bhubaneswar,
                      Khordha, Odisha – 751006, India (hereinafter referred to
                      as the “Practice” which expression shall, unless it be
                      repugnant to or inconsistent with the context hereof,
                      shall mean and include its successors and permitted
                      assigns); And accepted by:
                    </p>
                    <p>
                      B. <span className="font-bold"> {usefirstName},</span>{" "}
                      presently residing at{" "}
                      <span className="font-bold">{selectedMemberaddress}</span>{" "}
                      (hereinafter referred to as the “Subscriber”). The
                      Practice and the Subscriber shall collectively be referred
                      to as the “Parties” and individually as a “Party”.
                    </p>
                  </div>

                  <div class="mt-8">
                    <p class="text-base font-bold">
                      1: MEMBERSHIP.{" "}
                      <span className="font-normal leading-7">
                        Subscriber hereby agrees to enroll as a member in the
                        Practice’s ‘CureBay Totalcare’
                        Diabetes/Ortho/Women’s/Cardio care membership program
                        (“Membership Program”) beginning on the Effective Date
                        set forth above. By virtue of being a member of the
                        Membership Program, the Subscriber shall be eligible to
                        receive certain basic medical services set out in
                        Exhibit A of this Consent Form (“Covered Services”).
                        Membership in the Practice’s Membership Program includes
                        only the Covered Services specifically described in
                        Exhibit A and the provision of such Covered Services
                        shall be subject to the conditions and limitations
                        described in this Consent Form and any ancillary
                        documents executed thereto. The Practice may revise, add
                        or discontinue any of the Covered Services at any time,
                        as it may choose in its sole discretion, by providing
                        the Subscriber at least thirty (30) days’ prior written
                        notice of the same.
                      </span>
                    </p>
                  </div>

                  <div class="mt-8">
                    <p class="text-base font-bold">
                      2: MEMBERSHIP FEES.
                      <span className="font-normal leading-7">
                        The Subscriber has the option to purchase (a) a monthly
                        Membership Program for an amount of Sixty Nine Dollars
                        ($69.00) per Subscriber For General Care Package & One
                        Hundred Dollars ($100.00) per Subscriber for Special
                        packages (Diabetic Care/Cardiac Care/Women’s Care/Ortho
                        Care), payable on a monthly basis, or (b) an annual
                        Membership Program for an amount of Eight Hundred and
                        twenty eight ($828) for General Health Package & Twelve
                        Hundred Dollars ($1200.00) for Special Package (Diabetic
                        Care/Cardiac Care/Women’s Care/Ortho Care) per
                        Subscriber, payable on an annual basis (each the
                        “Membership Fee”) in accordance with Exhibit B to this
                        Consent Form, (“Membership Fee Schedule”). The first
                        instalment of the Membership Fee is due on the Effective
                        Date and thereafter the Membership Fees shall be due in
                        on the same date of subsequent months (in the case of
                        the monthly Membership Fees) following the Effective
                        Date, and will cover the Subscriber’s Membership Program
                        for the month immediately prior (e.g. if the Effective
                        Date is May 15th, the Subscriber’s Membership Program is
                        effective from May 15th and the monthly Membership Fee
                        for the month of May is due on May 15th). Membership
                        Fees shall be pro-rated for the first month from the
                        Effective Date only. Any fees or charges that are not
                        included in the Membership Fee (i.e. fees for non-
                        covered services other than the Covered Services) shall
                        be due at the time of service. It is clarified that the
                        Subscriber shall not be reimbursed in any manner by the
                        Practice including in relation to any services including
                        the Covered Services.
                      </span>
                    </p>
                  </div>

                  <div className="pl-10">
                    <p>
                      A. NONPAYMENT. In the event the Subscriber is unable to
                      pay the monthly Membership Fees in full and within the
                      time period stipulated under this Consent Form, the
                      Practice may, in its sole discretion, terminate this
                      Consent Form in accordance with paragraph 5A of this
                      Consent Form. It is the Subscriber's responsibility to
                      maintain a correct and up-to-date credit/debit card number
                      on file.
                    </p>

                    <p>
                      B. CHANGES TO MEMBERSHIP FEE SCHEDULE. The Practice may
                      amend the Membership Fee Schedule at any time, as it may
                      determine in its sole discretion, upon providing the
                      Subscriber at least thirty (30) days’ prior written notice
                      of the same.{" "}
                    </p>
                  </div>
                  <div class="mt-8">
                    <p class="text-base font-bold">
                      3: NON-COVERED SERVICES{" "}
                      <span className="font-normal leading-7">
                        The Subscriber understands and acknowledges that the
                        Subscriber is solely responsible for any charges
                        incurred by the Subscriber for health care services
                        performed outside of the physical office space location
                        as set forth above, including, but not limited to,
                        emergency room visits, hospital and specialist care, and
                        imaging and lab tests performed by third parties.
                        Further, the Parties agree and acknowledge that the
                        Practice shall not be responsible for any charges or
                        reimbursement of any charges incurred by the Subscriber
                        other than as explicitly set out under this Consent
                        Form. The Subscriber shall also be responsible for any
                        charges incurred for health care services provided by
                        the Practice but not specifically set out in Exhibit A.
                        The Subscriber shall undertake best efforts to maintain
                        a valid health insurance policy from a reputed third
                        party insurance provider during the term of this Consent
                        Form considering the services provided by the Practice
                        are not in any way in the nature of services provided
                        under a health insurance policy. The Subscriber should
                        ensure that the health insurance shall cover, at a
                        minimum unpredictable and catastrophic expenses. The
                        Subscriber acknowledges and agrees that they are solely
                        responsible for the accuracy and completeness of all
                        information provided during the consultation, including
                        but not limited to past medical records and details of
                        previous doctor consultations. The Practice, its
                        affiliates, representatives and service providers shall
                        not be held liable for any consequences arising from
                        inaccurate or incomplete information provided by the
                        Subscriber.
                      </span>
                    </p>
                  </div>

                  <div class="mt-8">
                    <p class="text-base font-bold">
                      4: INSURANCE.{" "}
                      <span className="font-normal leading-7">
                        The Subscriber acknowledges and understands that this
                        Consent Form or Membership in the Practice does not
                        provide comprehensive health insurance coverage, nor is
                        it a contract of insurance. The Subscriber represents
                        that the Subscriber has contacted the Subscriber’s
                        insurance health insurance company to discuss any
                        limitations or restrictions that may be imposed upon the
                        Subscriber by accepting the Consent Form of self-pay
                        status attached hereto and incorporated by reference
                        herein.
                      </span>
                    </p>
                  </div>
                  <div className="pl-10">
                    <p>
                      A. INSURANCE CLAIMS. The Subscriber acknowledges and
                      understands that the Practice is not a participating
                      provider in any TPA, CGHS, Ayushman Bharat, etc. or
                      private health care plan. The Subscriber acknowledges and
                      understands that the Practice will not bill insurance
                      carriers on the Subscriber’s behalf including for Covered
                      Services provided to Subscriber and the Practice will not
                      bill any health care plan of which the Subscriber may be a
                      subscriber or beneficiary for Membership Fees due and
                      owing to the Practice under this Consent Form. Membership
                      Fees may not be submitted to any insurance companies for
                      reimbursement.
                    </p>

                    <p>
                      B. TAX-ADVANTAGED MEDICAL SAVINGS ACCOUNTS. As of the
                      Effective Date, it is unlikely that the Membership Fees
                      described in section 2 of this Consent Form shall
                      constitute eligible medical expenses that are payable or
                      reimbursable using a tax-advantaged savings account such
                      as a health savings account (“HSA”), medical savings
                      account (“MSA”), flexible spending arrangement (“FSA”),
                      health reimbursement arrangement (“HRA”), or other health
                      plans similar thereto (collectively referred to as a
                      “tax-advantaged savings account”). Every health plan is
                      uniquely different. Subscriber should consult with their
                      health benefits advisor regarding whether Membership Fees
                      may be paid using funds contained in Subscriber’s
                      tax-advantaged savings account, as may be applicable.
                    </p>
                    <p>
                      C. HEALTH PLANS. Because the Practice is not a
                      participating provider in any private health care plan,
                      third party payers may not count the Membership Fees
                      incurred pursuant to this Consent Form toward any
                      deductible Subscriber may have under a health plan. The
                      Subscriber shall be solely responsible to consult with
                      their health benefits advisor on whether Membership Fees
                      may be counted toward the Subscriber’s deductible under a
                      health plan, as may.
                    </p>
                  </div>

                  <div class="mt-8">
                    <p class="text-base font-bold">
                      5: TERMINATION OF THE CONSENT FORM{" "}
                      <span className="font-normal leading-7">
                        Termination of this Consent Form in accordance with this
                        section 5, shall cause the termination of the
                        Subscriber’s Membership Program and the provision of the
                        Covered Services described herein.
                      </span>
                    </p>
                  </div>
                  <div className="pl-10">
                    <p>
                      A. TERMINATION BY THE PRACTICE. The Practice may terminate
                      this Consent Form upon providing Subscriber advance
                      written notice and such termination will be effective
                      starting thirty (30) calendar days after notification.
                      Upon termination, the Practice shall comply with all rules
                      and regulations in relation to the provision of emergent
                      care for 30 calendar days after termination and cooperate
                      in the transfer of Subscriber’s medical records to the
                      Subscriber’s new primary care physician, upon the
                      Subscriber’s written request and direction.
                    </p>

                    <p>
                      B. TERMINATION BY SUBSCRIBER. The Subscriber may terminate
                      this Consent Form at any time and for any reason, upon
                      providing 60 (sixty) business days’ advance written notice
                      to the Practice. Such termination shall be effective on
                      the last day of the then-current calendar month. As
                      services would be pre-scheduled Membership Fees for
                      monthly and annual subscriptions when cancelled an
                      additional month would be charged extra. Monthly
                      Membership Fees will continue to accrue until Subscriber’s
                      written notice of termination is received by the Practice
                      at its office
                    </p>
                  </div>

                  <div class="mt-8">
                    <p class="text-base font-bold">
                      6: REINSTATEMENT.{" "}
                      <span className="font-normal leading-7">
                        In the event Subscriber terminates this Consent Form
                        after the Effective Date hereof, the Subscriber can
                        become a member at any time again when they wish to by
                        approaching the Practice for the same.
                      </span>
                    </p>
                  </div>

                  <div class="mt-8">
                    <p class="text-base font-bold">
                      7: INDEMNIFICATION.{" "}
                      <span className="font-normal leading-7">
                        The Subscriber agrees to indemnify and to hold the
                        Practice and its members, affiliates, subsidiaries,
                        officers, directors, agents, and employees harmless from
                        and against any and all demands, claims, actions or
                        causes of action, assessments, losses, damages,
                        liabilities, costs and expenses, including interest,
                        penalties, attorney fees, etc. which are imposed upon or
                        incurred by the Practice as a result of the Subscriber’s
                        breach of any of Subscriber’s representations,
                        warranties and/or obligations under this Consent Form or
                        as a result of any misuse of the Covered Services or any
                        fraud, gross negligence or wilful misconduct by the
                        Subscriber. Notwithstanding anything contained herein,
                        the Subscriber agrees and acknowledges that the Practice
                        operates an aggregation platform that connects third
                        party service providers (including the affiliates and/or
                        subsidiaries of the Practice) to the Subscriber in order
                        to provide the Covered Services and therefore the
                        Practice shall be excluded from any direct or indirect
                        liability arising from or in connection with the Covered
                        Services.
                      </span>
                    </p>
                  </div>
                  <div class="mt-8">
                    <p class="text-base font-bold">
                      8: ENTIRE AGREEMENT.{" "}
                      <span className="font-normal leading-7">
                        This Consent Form constitutes the entire understanding
                        between the Parties hereto relating to the matters
                        herein contained and shall not be modified or amended
                        except as mutually agreed upon by both Parties hereto.
                      </span>
                    </p>
                  </div>

                  <div class="mt-8">
                    <p class="text-base font-bold">
                      9: WAIVER.{" "}
                      <span className="font-normal leading-7">
                        The waiver of either the Practice or Subscriber of a
                        breach of any provisions of this Consent Form must be in
                        writing and signed by the waiving and non- breaching
                        Party to be effective and shall not operate or be
                        construed as a waiver of any subsequent breach by either
                        the Practice or Subscriber, as may be applicable.
                      </span>
                    </p>
                  </div>
                  <div class="mt-8">
                    <p class="text-base font-bold">
                      10: CHANGE OF LAW.{" "}
                      <span className="font-normal leading-7">
                        If there is a change of any law, regulation or rule,
                        federal, state or local, which affects this Consent
                        Form, any terms or conditions incorporated by reference
                        in this Consent Form, the activities of the Practice
                        under this Consent Form, or any change in the judicial
                        or administrative interpretation of any such law,
                        regulation or rule, and the Practice reasonably believes
                        in good faith that the change will have a substantial
                        adverse effect on the Practice’s rights, obligations or
                        operations or associated with this Consent Form, then
                        the Practice may, upon written notice, require the
                        Subscriber to enter into good faith negotiations to
                        renegotiate the terms of this Consent Form. If the
                        Parties are unable to reach an agreement concerning the
                        modification of this Consent Form within ten (10) days
                        after the effective date of change, then the Practice
                        may immediately terminate this Consent Form upon
                        providing written notice to the Subscriber.
                      </span>
                    </p>
                  </div>
                  <div class="mt-8">
                    <p class="text-base font-bold">
                      11: GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION.
                      <span className="font-normal leading-7">
                        This Consent Form and the rights and obligations of the
                        Practice and the Subscriber hereunder shall be construed
                        and enforced pursuant to the laws of India and the
                        courts at Bhubaneswar, India shall have exclusive
                        jurisdiction on the matters arising from or in
                        connection with this Consent Form, without regard to the
                        principles of conflicts of laws. Further, any dispute,
                        claim, controversy or disagreement of any kind arising
                        under or in relation to this Consent Form shall be
                        resolved by arbitration in accordance with the Indian
                        Arbitration and Conciliation Act, 1996. The seat and
                        venue of arbitration shall be Bhubaneswar, India.
                      </span>
                    </p>
                  </div>

                  <div class="mt-8">
                    <p class="text-base font-bold">
                      12: ASSIGNMENT/BINDING EFFECT.
                      <span className="font-normal leading-7">
                        . This Consent Form shall be binding upon and shall
                        inure to the benefit of both the Practice and Subscriber
                        and their respective successors, heirs and legal
                        representatives. Neither this Consent Form, nor any
                        rights hereunder, may be assigned by the Subscriber
                        without the written consent of the Practice. It is
                        hereby clarified that the Practice may assign any and
                        all of its rights and/or obligations under this Consent
                        Form to any third party.
                      </span>
                    </p>
                  </div>

                  <div class="mt-8">
                    <h2 class="text-lg font-bold text-center">
                      COVERED SERVICES WITH BASIC MEMBERSHIP
                    </h2>
                    <div class="mt-4">
                      Appointment types include wellness exams, acute and
                      chronic disease management, and multiple procedures
                      (listed below). Access to provide comprehensive primary
                      care medicine via phone. Not all conditions can be handled
                      with these indirect methods and the Subscriber may be
                      asked to make an in-person appointment.
                    </div>
                    <div class="mt-4">
                      <p class="font-bold">
                        FIXED SERVICES INCLUDED IN ANNUAL MEMBERSHIP PROGRAMS
                      </p>
                      <li>5 General Physician Home Visits</li>
                      <li>4 Specialists Virtual Consultation</li>

                      <li> 3 Times Lab Tests*</li>

                      <li>Monthly Health Educator Calls</li>

                      <li> 4 Nutritionists Virtual Sessions</li>

                      <li>4 Physiotherapists Sessions</li>

                      <li>5 Times Nurses Home Visits</li>

                      <li>2 Times Ambulance Services</li>

                      <li> Monthly Check up Calls</li>
                      <li>On Demand Services**</li>
                    </div>

                    <div class="mt-4">
                      <p class="">
                        ADDITIONAL SERVICE FOR SPECIALISED MEBERSHIP PROGRAMS{" "}
                        <span className="font-bold">
                          (Diabetic Care/Cardiac Care/Women’s Care/Ortho Care)
                        </span>{" "}
                      </p>
                      <li>2 Specialists Virtual Consultation</li>
                      <li>
                        5 Diabetologist /Cardiologist /Gynecologist/Orthopaedic
                        Consultation
                      </li>

                      <li>1 Physiotherapist Virtual Sessions</li>
                    </div>

                    <div class="mt-4">
                      <p class="font-bold">EXCEPTIONS TO ABOVE: </p>
                      <p>
                        *Diagnostic lab test fees (other than thrice a year
                        screening lab tests which are covered) are available at
                        significant discount to members if paid at the time of
                        service
                      </p>
                      <p>CBC with ESR</p>

                      <p>Thyroid tests</p>

                      <p> Urine Routine & Microscopy</p>

                      <p>Liver Function Tests</p>

                      <p>Kidney Function Tests</p>

                      <p>Lipid Profile Tests</p>

                      <p>Hba1c</p>

                      <p> Vitamin D</p>

                      <p>Vitamin B12</p>

                      <p> Iron Studies</p>

                      <p>Blood Sugar Testing</p>

                      <p>
                        **Subscriber can enquire for other On-Demand services
                        and CureBay shall provide rates accordingly.
                      </p>
                    </div>
                  </div>
                  <div class="mt-4">
                    <p class="font-bold">OTHER BENEFITS:</p>
                    <p>
                      Access to cash pay discounts we are able to negotiate on
                      the Subscriber’s behalf from various third parties.
                      Organization and review of historic and outside medical
                      records.
                    </p>
                  </div>
                  <div class="mt-4">
                    <p class="font-bold">EXCLUDED SERVICES:</p>
                    <p>
                      Anything not specifically listed as a Covered Service
                      shall be a non-covered service. Any health care services
                      not performed on or within the plans including emergency
                      room visits, hospital stays, specialist care, imaging and
                      labs, etc.
                    </p>
                  </div>
                  <div class="mt-8">
                    <h2 class="text-lg font-bold text-center">
                      MEMBERSHIP FEES
                    </h2>
                  </div>
                  <div class="mt-4">
                    <p class="font-bold">Option A (Monthly Payment)</p>

                    <li>
                      Single Adult: $100 - (Diabetic Care/Cardiac Care/Women’s
                      Care/Ortho Care)
                    </li>

                    <li> Single Adult: $69 - General Health</li>
                  </div>
                  <div class="mt-4">
                    <p class="font-bold">Option B (Annual Payment)</p>

                    <li>
                      Single Adult: $1200 - (Diabetic Care/Cardiac Care/Women’s
                      Care/Ortho Care)
                    </li>

                    <li>Single Adult: $828 - General Health</li>
                  </div>
                </div>

                <div className="text-justify">
                  {consent?.attributes?.ThirdComponent?.description2}
                </div>
              </div>
            </Dialog>
          </div>
        )}
      </div>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </>
  );
};

export default Cart;
