import React, { useEffect, useRef } from "react";
import envOutlined from "../careplans/image/envOutlined.svg";
import comprehensivevectoricon1 from "../aboutus/image/comprehensivevectoricon1.svg";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { getOurCareplans } from "../../store/actions/howitworks";
import { updateCart } from "../../store/actions/login";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import vectoricon4 from "./image/Vector (2).png";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Icon,
  IconButton,
  Stack,
} from "@mui/material";
import { useState } from "react";
import Footer from "../../components/webflow/postloginfooter";
// import ChargebeeCheckout from './chargebee';
import { useDispatch, useSelector } from "react-redux";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";
import loginservice from "../../store/services/loginservice";
import { COMMONCONSTANTS } from "../../store/constants/commonconstants";
import {
  exportComparePlan,
  fetchCarePlan,
  getBenifits,
  getHowItWorksAction,
  healtchCareService,
} from "../../store/actions/careplans";
import services from "../../store/services";
import { useSnackbar } from "../common/snackbar";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";

const Buycareplan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selected, setSelected] = useState(false);
  const [carePlans, setCarePlans] = useState(null);
  const cbInstanceRef = useRef(null);
  const cartRef = useRef(null);
  const [carePlanDetails, setCarePlanDetails] = useState([]);
  const userData = useSelector((state) => state.AuthReducer.userData);
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const [plan, setPlan] = useState();
  const [more, setMore] = useState(4);
  const [yes, setYes] = useState(true);
  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const YesNo = ({ onYesChange }) => {
    const handleClick = (value) => {
      setYes(value);
    };
    return (
      <div className="flex justify-center sm:justify-normal mt-4 sm:mt-0 mb-4 sm:mb-[15px]  ">
        <button
          className={`${yes ? "bg-seegreen text-white " : " text-seegreen "
            }  pl-4 py-2 rounded-full text-start font-semibold w-32 `}
          style={{ border: "2px solid #66b889" }}
          onClick={() => handleClick(true)}
        >
          Annual
        </button>
        <button
          className={`${!yes ? " bg-seegreen text-white " : " text-seegreen "
            } relative right-10 border-seegreen text-end pr-[12px] py-2 rounded-full  font-semibold w-32`}
          style={{ border: "2px solid #66b889" }}
          onClick={() => handleClick(false)}
        >
          Monthly
        </button>
      </div>
    );
  };

  useEffect(() => {
    const initializeChargebee = () => {
      const isProd = window.location.hostname === 'medicare.curebay.com';
      const site = isProd ? 'curebay' : 'curebay-test';

      cbInstanceRef.current = window.Chargebee.init({
        site: site,
        isItemsModel: true,
      });
      cartRef.current = cbInstanceRef.current.getCart();
    };

    initializeChargebee();
  }, []);

  useEffect(() => {
    if (carePlans === null) {
      async function fetchData() {
        const carePlansResponse = await getOurCareplans();
        if (
          carePlansResponse.status === 200 &&
          carePlansResponse?.data?.succesObject
        ) {
          setCarePlans(carePlansResponse.data.succesObject);
        }
      }
      fetchData();
    }
    async function fetchCarePlanDetails() {
      const res = await fetchCarePlan();
      if (res.status === 200 && res?.data?.data[0]) {
        setPlan(res?.data?.data[0]);
      }
    }
    fetchCarePlanDetails();
  }, []);

  const handleSelect = (index) => {
    if (selected === index) {
      setSelected(null);
    } else {
      setSelected(index);
    }
  };

  useEffect(() => {
    const getCarePlanDetails = async () => {
      try {
        const res = await services.get("home-page-medicares?populate=deep");
        setCarePlanDetails(res?.data?.data?.[0]?.attributes?.secondcomp);
      } catch (error) { }
    };
    getCarePlanDetails();
  }, [setCarePlanDetails]);

  const addToCart = async (e) => {
    let data = JSON.parse(userData);
    let payload = {
      userCode: data?.[0]?.userCode,
      cartType: 1,
      cartVOList: [
        {
          userId: data?.[0]?.userSeqId,
          userCode: data?.[0]?.userCode,
          planId: e.planId,
          planName: e.planName,
          period: yes ? "yearly" : "monthly",
        },
      ],
    };

    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "add_to_cart",
      ecommerce: {
        value: 100,
        items: [
          {
            userId: data?.[0]?.userSeqId,
            userCode: data?.[0]?.userCode,
            planId: e.planId,
            planName: e.planName,
            period: yes ? "yearly" : "monthly",
          },
        ],
      },
    });




    const res = await updateCart(payload);
    if (res?.status === 200 && res?.data?.responseCode) {
      let userData = {
        userCode: data?.[0]?.userCode,
      };
      loginservice
        .cartList(userData)
        .then((res) => {
          dispatch({
            type: COMMONCONSTANTS.SUCCESS_FETCHCART,
            payload: res?.data?.succesObject,
          });
        })
        .catch((error) => { });
      setSnackbarMessage("Added to the cart", "success");
    }
  };

  const openCheckout = (e, planDuration) => {
    const planMappings = {
      Monthly: {
        1: "general-healthcare-USD-Monthly",
        3: "diabetic-USD-Monthly",
        4: "heart-healthcare-USD-Monthly",
        5: "Orthopedics-Care-USD-Monthly",
        6: "Womens-Healthcare-USD-Monthly",
      },
      Yearly: {
        1: "general-healthcare-USD-Yearly",
        3: "diabetic-USD-Yearly",
        4: "heart-healthcare-USD-Yearly",
        5: "Orthopedics-Care-USD-Yearly",
        6: "Womens-Healthcare-USD-Yearly",
      },
    };

    const planId = planMappings[planDuration]?.[e.planId];
    const planPriceQuantity = 1;
    const product = cbInstanceRef.current?.initializeProduct?.(
      planId,
      planPriceQuantity
    );
    cartRef.current.replaceProduct(product);

    const customer = {
      first_name: "",
      last_name: "",
      company: "",
      email: "",
      phone: "",
    };
    cartRef.current.setCustomer(customer);
    cartRef.current.proceedToCheckout();
  };

  function CareplansFeatures(props) {
    let data = [];
    let data1 = [];
    data = props.features;
    for (let index = 0; index < data.length; index++) {
      if (data[index]?.priority === 1) {
        data1.push(data[index]);
      }
    }
    return (
      <>
        {data1?.length > 0 &&
          data1.map((e, index) => (
            // {props?.features?.length > 0 && props.features.map((e, index) => (
            <div className="flex" key={index}>
              <img
                className="h-3 mt-5 "
                alt={vectoricon4}
                src={vectoricon4}
              ></img>
              <span className="text-chathams-blue-500 pl-5 pt-3">{e?.feature}</span>
            </div>
          ))}
      </>
    );
  }

  return (
    <>
      {snackbarMessage}
      <div className="container-default position-relative">
        <div className="mt-[20px] rounded-full bg-chathams-blue-200 hover:bg-seegreen absolute top-28 cursor-pointer left-0 ml-8 p-2 sm:block hidden">
          <ChevronLeftIcon
            sx={{ color: "white" }}
            onClick={() => {
              navigate(APP_ROUTES.MEMBERDETAILS);
            }}
          />
        </div>
        <div className="sm:mx-5 mx-0 bg-transparent flex flex-col justify-center items-center ">
          <div className="flex flex-col sm:flex-row w-full  ">
            <div className="flex justify-start">
              <h1 className="text-xl font-medium font-poppins text-start sm:mt-[190px] mt-[90px] m-0">
                Choose health care plan for{" "}
                <span className="text-[#40B882]">your family member</span>
              </h1>
            </div>
          </div>
          <div className="flex flex-col sm:flex-row justify-between w-full ">
            <span className="text-lg mt-4 font-normal font-poppins text-black">
              Our superspeciality health plans are carefully designed for the
              unique needs of the elderly{" "}
            </span>
            <YesNo />
          </div>
        </div>

        <div className="flex-col block sm:hidden -mt-[96px]">
          <div className="container mx-auto">
            <div className="block sm:pl-5 sm:pr-5">
              {carePlans?.length &&
                carePlans.map((value, index) => (
                  <div
                    className="flex flex-col md:flex-row md:max-w-4xl max-w-sm mx-auto bg-white border border-gray-200 my-5 shadow-2xl rounded-lg overflow-hidden"
                    key={index}
                  >
                    <div className="flex justify-between bg-shakespeare bg-opacity-[10%] p-4 md:w-1/2">
                      <div>
                        <h2 className="text-xl font-extrabold text-[#0B4881]">
                          {value?.planName}
                        </h2>
                        <div className="flex gap-4 mt-3">
                          <span className="font-bold text-black text-lg ">
                            $ {!yes ? value?.price : 12 * value?.price}{" "}
                            {!yes ? " per month" : " per year"}
                          </span>
                          {yes && (
                            <span className="text-sm text-left font-normal pl-0 mt-2">
                              <span className="font-semibold ">$</span>
                              <span className=" font-bold ">
                                {yes ? value?.price : ""} * 12
                              </span>
                              {yes ? " Months" : ""}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="p-4 md:w-1/2">
                      <div className="h-full flex flex-col justify-between">
                        <p className="text-lg text-left font-poppins font-semibold text-black mt-4 mb-3">
                          {value?.packageType}
                        </p>
                        <span className="my-2 text-base text-justify text-chathams-blue">
                          {value?.description}
                        </span>
                        <div>
                          <div>
                            <span className="text-lg text-left font-poppins font-semibold text-chathams-blue mt-7">
                              What's included:
                            </span>
                          </div>
                          <div className="flex flex-col gap-2 mt-3">
                            {plan?.attributes?.SecondSub?.map((rec) => {
                              if (rec?.carePlanId === String(value?.planId)) {
                                return rec?.whatsIncluded
                                  ?.slice(0, 4)
                                  .map((res) => (
                                    <span className="inline-flex font-poppins items-start text-chathams-blue font-semibold text-[14px]">
                                      <span className="pr-1">
                                        <Icon
                                          sx={{
                                            fontSize: 16,
                                            color: "#90B6FF",
                                          }}
                                        >
                                          check
                                        </Icon>
                                      </span>
                                      {res?.label}
                                    </span>
                                  ));
                              }
                            })}
                            {/* <span className="inline-flex font-poppins items-start text-chathams-blue font-semibold text-[12px]">
                  <span className="pr-1">
                    <Icon sx={{ fontSize: 16, color: "#90B6FF" }}>check</Icon>
                  </span>
                  awsdxcfgvbhnjkm,lsxdcfgvbh njkmswxegfvbhnj
                </span> */}
                          </div>
                        </div>
                        <div className="mt-2 p-4 md:w-full">
                          <Button
                            variant="contained"
                            onClick={() => addToCart(value)}
                            className="text-[13px] w-full font-semibold text-center text-white  rounded-lg"
                            style={{ backgroundColor: "#004171", borderRadius: "74px" }}
                          >
                            Add To Cart
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>

        <div className=" justify-center hidden sm:flex mb-[55px]">
          <table className=" border rounded-lg w-max">
            <thead className="bg-shakespeare font-poppins bg-opacity-[10%]  text-[#0B4881]">
              <tr>
                {carePlans?.length &&
                  carePlans.map((value, index) => (
                    <th className="py-6" key={index}>
                      {value?.planName}

                      <div
                        className="pt-4"
                      // className='flex justify-center'
                      >
                        {/* <button className="  text-[13px] font-semibold text-center text-white  hover:bg-royal-blue-600 bg-royal-blue rounded " >ADD TO CART</button> */}
                        <Button
                          variant="contained"
                          className="text-[13px] w-40  font-semibold text-center text-white rounded-lg "
                          onClick={() => addToCart(value)}
                          style={{ backgroundColor: "#004171", borderRadius: "74px" }}
                        >
                          Add To Cart
                        </Button>
                      </div>
                    </th>
                  ))}
              </tr>
            </thead>
            <tbody className="">
              <tr className="">
                {carePlans?.length &&
                  carePlans.map((value, index) => (
                    <td
                      className="border py-4 my-24 border-slate-300 align-top "
                      key={index}
                    >
                      <div className="flex flex-col ml-7 mr-4 mb-10">
                        <div className="pt-10 h-[18rem] border-b">
                          <div className=" font-poppins  justify-center  items-center ">
                            <span className="text-sm text-left font-normal text-chathams-blue pr-1">
                              <span className="font-semibold text-brand-lightgreen">
                                $
                              </span>
                              <span className="text-2xl font-bold text-brand-lightgreen">
                                {!yes ? value?.price : 12 * value?.price}
                              </span>
                              {!yes ? " per month" : " per year"}
                            </span>
                            {yes && (
                              <p className="text-sm text-left font-normal mt-2 pl-1">
                                (
                                <span className="font-semibold ">$</span>
                                <span className=" font-bold ">
                                  {yes ? value?.price : ""} * 12
                                </span>
                                {yes ? " Months" : ""}
                                )</p>
                            )}
                          </div>
                          <p className="text-lg text-left font-poppins font-semibold text-black mt-4 mb-3">
                            {value?.packageType}
                          </p>
                          <p className=" text-sm  text-left font-poppins font-normal text-chathams-blue mb-2 ">
                            {value?.description}
                          </p>
                        </div>

                        <div>
                          <span className=" text-lg text-left font-poppins font-semibold text-chathams-blue mt-7">
                            What's included:
                          </span>
                          <Stack
                            direction="column"
                            alignItems="flex-start"
                            rowGap={1}
                            sx={{ marginTop: "8px" }}
                          >
                            {plan?.attributes?.SecondSub?.map((rec) => {
                              if (rec?.carePlanId === String(value?.planId)) {
                                return rec?.whatsIncluded
                                  ?.slice(0, 4)
                                  .map((res) => (
                                    <span className="inline-flex font-poppins items-start text-chathams-blue font-semibold text-[12px]">
                                      <span className="pr-1">
                                        <Icon
                                          sx={{
                                            fontSize: 16,
                                            color: "#90B6FF",
                                          }}
                                        >
                                          check
                                        </Icon>
                                      </span>
                                      {res?.label}
                                    </span>
                                  ));
                              }
                            })}
                          </Stack>
                        </div>
                      </div>
                    </td>
                  ))}
              </tr>
              {/* <tr className=''>
              <td colSpan={carePlans?.length}>
                <span className="my-4 text-chathams-blue-200 text-center font-bold cursor-pointer" onClick={() => setMore(more === 4 ? plan?.attributes?.SecondSub?.whatsIncluded?.length : 4)}>
                  {more === 4 ? (
                    <span className="underline underline-offset-4"> {`>> Read More`}</span>
                  ) : (
                    <span className="underline underline-offset-4"> {`<< Read Less`}</span>
                  )}
                </span>

              </td>
            </tr> */}
            </tbody>
          </table>
        </div>
        {/* <div className='flex gap-5 mt-3 sm:mt-0 pt-4 pl-6 sm:pl-0'>
<button className='text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 mb-20 rounded-md hover:text-white hover:border-seegreen hover:bg-seegreen font-semibold' onClick={() => navigate(APP_ROUTES.MEMBERDETAILS)}>Go Back</button>
                    </div> */}

        {/* <div className='flex flex-row  mt-6 mb-20 items-center justify-center  '>
        <div className=' flex  items-center justify-center  py-3 px-7 bg-[#FFFAEC]'>
          <span className='inline-flex items-start text-black font-normal text-lg '><span className='pr-4'><img src={envOutlined} alt="img"></img></span>With  &nbsp;<span className='font-semibold'>General Health &nbsp;</span>package you can include other package as well</span>
        </div>
      </div> */}
        {/* <div className='grid grid-cols-3 gap-3 '>
        {carePlans?.length && carePlans.map((e, index) => {
          const planDetails = carePlanDetails?.content?.find((item) => item.carePlanName === e.planName);

          return (
            <div className='flex flex-row items-center justify-center' key={index}>
              <Card sx={{
                width: '360px', height: '450px', borderColor: '#C4C4C4', borderWidth: '0.5px', position: 'relative'
              }}>
                <div className='flex flex-col justify-center items-center'>
                  <span className="flex py-1 px-1 pr-2 w-full justify-center">
                    <img src={comprehensivevectoricon1} alt={comprehensivevectoricon1} className='w-full h-12 pl-6'></img>
                  </span>
                  <span className="text-2xl font-bold mt-6 text-center text-chathams-blue-500">{e?.planName}</span>
                  <span style={{ color: '#262626', marginTop: '0.5rem' }}>For one Month</span>
                  <span className='font-semibold text-3xl'>${e?.price}</span>
                  <div className='text-start ml-7 w-full'>
                    {e?.careplanFeturesVos && <CareplansFeatures features={e.careplanFeturesVos} />}
                  </div>
                  <button
                    style={{
                      color: '#3CBA84',
                      width: '90%',
                      marginTop: '1rem',
                      backgroundColor: 'white',
                      borderWidth: '1px',
                      borderColor: '#3CBA84',
                      borderRadius: '2.6px',
                      height: '2rem',
                      position: 'absolute',
                      bottom: '50px'
                    }}
                    // className={`text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 rounded-md font-semibold 
                    // w-90 mt-4 bg-white border-1 border-#3CBA84 rounded-2.6 h-8 absolute bottom-4 
                    // text-center hover:text-white hover:border-seegreen hover:bg-seegreen`}
                    // onClick={() => openCheckout(e, 'Monthly')}
                    hidden={true}
                  >
                    Click for Monthly Subscription
                  </button>
                  <button
                    // className={`text-chathams-blue-200 border-chathams-blue-200 border-2 px-3 py-2 rounded-md font-semibold 
                    //  w-90 mt-4 bg-white border-1 border-#3CBA84 rounded-2.6 h-8 absolute bottom-4 
                    //  text-center hover:text-white hover:border-seegreen hover:bg-seegreen`}
                    // onClick={() => openCheckout(e, 'Yearly')}
                    onClick={() => addToCart(e)}
                    style={{
                      color: '#3CBA84',
                      width: '90%',
                      marginTop: '1rem',
                      backgroundColor: 'white',
                      borderWidth: '1px',
                      borderColor: '#3CBA84',
                      borderRadius: '2.6px',
                      height: '2rem',
                      position: 'absolute',
                      bottom: '15px'
                    }}
                  >
                    Add to Cart
                  </button>
                  <div>
                  </div>
                </div>
              </Card>
            </div>
          );
        })}


      </div> */}


      </div>
      {screen > 500 ? (
        <Footer i={1} />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </>
  );
};

export default Buycareplan;
