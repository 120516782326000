import React, { useEffect, useState } from "react";
import MyprofileSidemenu from "../homepage/myprofile-sidemenu";
import {
  fetchUserReview,
  fetchreview,
  savereview,
  updateReview,
} from "../../store/actions/myprofilereview";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

import { Button, Card, MenuItem, TextField } from "@mui/material";
import { useSnackbar } from "../common/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate } from "react-router-dom";
import mydetailsservice from "../../store/services/mydetailsservice";
import { fetchUserList } from "../../store/actions/dashboard";
import Footer from '../../components/webflow/postloginfooter';
import FooterMobileVersion from "../../components/homepage/FooterMobileVersion";
import MobileFooter from "./../homepage/mobileFooter";

function Review() {
  const navigate = useNavigate();
  const breadcrumbItems = [
    { label: "Home" },
    { label: "My Details", onClick: () => navigate(APP_ROUTES.MYDETAILS) },
    { label: "My Review", url: "/details" },
  ];

  const [patientData, setpatientData] = useState({
    // firstName: '', lastName: '', parentCode: '', salutation: '', gender: '', mobile: '', email: '', dob: '', bloodGroup: '', address1: '', address2: '', pinCode: '', state: '', city: ''
  });
  const [rating, setRating] = useState([]);
  // const userData = useSelector((state) => state.authReducer.patientData);
  const [updatedRating, setUpdatedRating] = useState([]);
  const [review, setReview] = useState([]);
  const [user, setUser] = useState();
  const [selectedUser, setSelectedUser] = useState("select");
  const [userList, setUserList] = useState();

  const [patientDatanew, setpatientDatanew] = useState();
  const [updateFeedback, setUpdateFeedback] = useState(false);
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const [screen, setscreen] = useState(window.innerWidth);
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
      ? userObj.parentCode
      : null;
  const userCodenew = userObj?.userCode ? userObj.userCode : null;
  console.log(userCode, "asdfg");
  useEffect(() => {
    if (!user) {
      async function fetchUser() {
        const user = await fetchUserReview({
          userCode: userCode,
        });
        if (user.status === 200 && user?.data) {
          setUser(user?.data);
          if (user?.data?.succesObject?.length !== 0) {
            setUpdateFeedback(true);
            const newRate = user?.data?.succesObject.map((value) => {
              return {
                rating: value.ratings,
                qNo: value.reviewQuestion,
                rType: value.ratingType,
                seqId: value.reviewSeqId,
              };
            });
            setUpdatedRating(updatedRating.concat(newRate));
            // setUpdatedRating([...updatedRating, ...newRate]);
          }
          const sortedUser = user?.data?.succesObject?.sort(
            (a, b) => a.reviewQuestion - b.reviewQuestion
          );
        }
      }
      fetchUser();
    }
  }, [user]);
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const getActivedetails = (userDetails) => {
    if (userDetails) {
      let payload = {
        code: userDetails?.code,
      };
      mydetailsservice.getmyListapi(payload).then((res) => {
        setpatientData(res?.data?.succesObject);
      });
    } else {
      if (userObj?.parentCode) {
        let payload = {
          userCode: userCode,
          code: userObj?.code,
        };
        mydetailsservice.getmyListapi(payload).then((res) => {
          setpatientData(res?.data?.succesObject);
        });
      } else {
        let payload = {
          userCode: userCode,
          code: userObj?.code,
        };
        mydetailsservice.getmyLischildapi(payload).then((res) => {
          setpatientData(res.data.succesObject);
        });
      }
    }
  };

  useEffect(() => {
    getActivedetails();
    console.log(userCode, "result");
  }, []);
  useEffect(() => {
    async function fetchUserDetails() {
      const user = await fetchUserList({
        code: userCode,
        email: userObj?.email,
        mobile: userObj?.mobile,
      });

      if (user.status === 200 && user?.data) {
        const temp = user?.data?.succesObject?.filter(
          (p) => Object.keys(p).length !== 0
        );
        setUserList(temp);
      }
    }
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (review.length === 0) {
      async function fetchAllquestionDetails() {
        const newReview = await fetchreview();
        if (newReview?.status === 200 && newReview?.data) {
          setReview(newReview.data?.succesObject);
        }
      }
      fetchAllquestionDetails();
    }
  }, [review.length]);

  const handleChange = (event, qno, rType) => {
    const index = rating.findIndex((r) => r.qNo === qno);
    if (index === -1) {
      setRating([
        ...rating,
        { rating: event.target.value, qNo: qno, type: rType },
      ]);
    } else {
      const updatedRating1 = [...rating];
      updatedRating1[index].rating = event.target.value;
      setRating(updatedRating1);
    }
  };

  const handleChange1 = (event, qno, rType, sid) => {
    const index = updatedRating.findIndex((r) => r.qNo === qno);
    if (index === -1) {
      setUpdatedRating([
        ...updatedRating,
        { rating: event.target.value, qNo: qno, type: rType, seqId: sid },
      ]);
    } else {
      const newUpdatedRating = [...updatedRating];
      newUpdatedRating[index].rating = event.target.value;
      setUpdatedRating(newUpdatedRating);
    }
  };

  const handleSubmit = () => {
    const payload = review?.map((value) => {
      return {
        userCode: userCode,
        patientCode: patientDatanew,
        reviewQuestion: value.msrSeqId,
        ratings:
          rating.findIndex((e) => e.qNo == value.msrSeqId) === -1
            ? undefined
            : rating.find((e) => e.qNo == value.msrSeqId).rating,
        ratingType: value.ratingType,
      };
    });
    async function reviewsave() {
      const saveResponse = await savereview({
        reviewList: payload,
      });
      if (saveResponse?.status === 200 && saveResponse?.data) {
        setSnackbarMessage(saveResponse?.data?.responseMessage, "success");
      } else {
        setSnackbarMessage(saveResponse?.data?.responseMessage, "error");
      }
    }
    reviewsave();
  };

  const handleUpdate = () => {
    const payload = review?.map((value) => {
      return {
        userCode: userCode,
        patientCode: patientDatanew,
        reviewQuestion: value.msrSeqId,
        ratings:
          updatedRating.findIndex((e) => e.qNo == value.msrSeqId) === -1
            ? 0
            : updatedRating.find((e) => e.qNo == value.msrSeqId).rating,
        ratingType: value.ratingType,
        reviewSeqId:
          updatedRating.findIndex((e) => e.qNo == value.msrSeqId) === -1
            ? user?.succesObject?.find((e) => e.reviewQuestion == 1).reviewSeqId
            : updatedRating.find((e) => e.qNo == value.msrSeqId).seqId,
      };
    });
    async function reviewUpdate() {
      const update = await updateReview({
        reviewList: payload,
      });
      if (update.status === 200 && update?.data) {
        setSnackbarMessage(update?.data?.responseMessage, "success");
        setReview([]);
        setUser();
      } else {
        setSnackbarMessage(update?.data?.responseMessage, "error");
      }
    }
    reviewUpdate();
  };

  const CardWithButtons = ({ question, qno, rType }) => {
    const [selected, setSelected] = useState(
      rating.find((r) => r.qNo === qno)?.rating
    );

    const Button1 = ({ value }) => {
      const handleClick = () => {
        if (selected === value) {
          setSelected(undefined);
          setRating((prevRating) => prevRating.filter((r) => r.qNo !== qno));
        } else {
          setSelected(value);
          setRating((prevRating) => [
            ...prevRating.filter((r) => r.qNo !== qno),
            { rating: value, qNo: qno, type: rType },
          ]);
        }
      };
      return (
        <button
          className={`${selected === value
            ? "bg-[#004171] text-white "
            : "bg-white hover:bg-[#004171] hover:text-white "
            } bordercolor w-6 h-6 border-2 border-bluish rounded-full text-center text-sm mx-3 first:ml-0 last:mr-0 cursor-pointer`}
          onClick={handleClick}
        >
          {value}
        </button>
      );
    };
    return (
      <div>
        <Card elevation={1} variant="outlined">
          <div className="m-5 flex flex-col customheight-70">
            <div>
              <p className="font-semibold text-base mb-2">
                Q{qno}. {question}
              </p>
            </div>

            <div className="">
              <div className="flex bg-sea-blue rounded-full justify-between ">
                {[1, 2, 3, 4, 5].map((i) => (
                  <Button1 key={i} value={i} />
                ))}
              </div>

              <div className="flex justify-between mx-0 my-2">
                <p style={{ fontSize: "12px" }}>Disagree</p>

                <p className="pr-2" style={{ fontSize: "12px" }}>
                  Agree
                </p>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  const CardWithFeedback = ({ question, qno, rType }) => {
    const [selected, setSelected] = useState(
      parseInt(updatedRating.find((r) => r.qNo === qno)?.rating)
    );

    const Button1 = ({ value }) => {
      const handleClick = () => {
        if (selected === value) {
          setSelected(undefined);
          setUpdatedRating((prevRating) =>
            prevRating.filter((r) => r.qNo !== qno)
          );
        } else {
          setSelected(value);
          setUpdatedRating((prevRating) => [
            ...prevRating.filter((r) => r.qNo !== qno),
            {
              rating: value,
              qNo: qno,
              type: rType,
              seqId: user?.succesObject.find((e) => e.reviewQuestion === qno)
                ?.reviewSeqId,
            },
          ]);
        }
      };

      return (
        <button
          className={`${selected === value
            ? "bg-bluish text-white "
            : "bg-white hover:bg-bluish hover:text-white"
            } w-6 h-6 border-2 borderclr rounded-full text-center text-sm mx-3 first:ml-0 last:mr-0 cursor-pointer`}
          onClick={handleClick}
        >
          {value}
        </button>
      );
    };
    return (
      <div>
        <Card variant="outlined">
          <div className="m-5 flex  flex-col customheight-70">
            <div>
              <p className="font-semibold text-base mb-2">
                Q{qno}. {question}
              </p>
            </div>
            <div className="mt-10">
              <div className="flex bg-sea-blue rounded-full justify-between  ">
                {[1, 2, 3, 4, 5].map((i) => (
                  <Button1 key={i} value={i} />
                ))}
              </div>

              <div className="flex justify-between mx-0 my-2">
                <p style={{ fontSize: "12px" }}>Disagree</p>

                <p className="pr-2" style={{ fontSize: "12px" }}>
                  Agree
                </p>
              </div>
            </div>
          </div>
        </Card>
      </div>
    );
  };

  const onUserChange = (event) => {
    setSelectedUser(event.target.value);
    getActivedetails(event.target.value);
    console.log(event.target.value.code, "valuedata");
    setpatientDatanew(event.target.value.code);

    async function fetchUser() {
      const user = await fetchUserReview({
        userCode: userCode,
        patientCode: event.target.value.code,
      });
      if (user.status === 200 && user?.data) {
        setUser(user?.data);
        if (user?.data?.succesObject?.length !== 0) {
          setUpdateFeedback(true);
          const newRate = user?.data?.succesObject.map((value) => {
            return {
              rating: value.ratings,
              qNo: value.reviewQuestion,
              rType: value.ratingType,
              seqId: value.reviewSeqId,
            };
          });
          setUpdatedRating(updatedRating.concat(newRate));
          // setUpdatedRating([...updatedRating, ...newRate]);
        }
        const sortedUser = user?.data?.succesObject?.sort(
          (a, b) => a.reviewQuestion - b.reviewQuestion
        );
      }
    }
    fetchUser();
  };

  return (
    <>
      {snackbarMessage}
      <div className="p-8 ">
        {/* <div className="flex items-center mb-5">
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">Home</p>
                    <ArrowForwardIosIcon sx={{ fontSize: 15 }} className="text-chathams-blue-500 mx-2" />
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">My Profile</p>
                    <ArrowForwardIosIcon sx={{ fontSize: 15 }} className="text-chathams-blue-500 mx-2" />
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">My Details</p>
                </div> */}

        <div className="flex items-center mb-5 mt-[100px]">
          {breadcrumbItems.map((item, index) => (
            <React.Fragment key={index}>
              <span className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">
                {item.onClick ? (
                  <a onClick={item.onClick}>{item.label}</a>
                ) : (
                  <a>{item.label}</a>
                )}
              </span>
              {index !== breadcrumbItems.length - 1 && (
                <ArrowForwardIosIcon
                  sx={{ fontSize: 15 }}
                  className="text-chathams-blue-500 mx-2"
                />
              )}
            </React.Fragment>
          ))}
        </div>

        <h2 className="text-xl font-bold my-5 text-chathams-blue-500">
          {/* My Profile - Review */}
        </h2>

        <div className="flex scroll-px-2">
          <div className="w-1/4 mr-4 border p-4 rounded-md hidden sm:block">
            <MyprofileSidemenu />
          </div>
          <div className="block sm:w-3/4">
            {!userCodenew && (
              <div className="sm:px-4 py-2 h-20 mb-2 flex items-center justify-start mr-[39rem] w-full rounded-md">
                <div className="block">
                  <p className="text-chathams-blue font-semibold text-base">
                    Member Profile
                  </p>
                  <TextField
                    select
                    value={selectedUser}
                    onChange={onUserChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    sx={{ height: "2 rem", width: "13rem",marginBottom:"65px"  }}
                  >
                    <MenuItem disabled key={0} value="select">
                      {" "}
                      <p className="text-base font-semibold"> Select</p>{" "}
                    </MenuItem>
                    {userList &&
                      userList?.map((value, index) => (
                        <MenuItem value={value} key={index}>
                          <p className="text-base font-semibold">
                            {value.name}
                          </p>
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
              </div>
            )}
            {updateFeedback ? (
              <div className="w-full ml-2 sm:ml-4 border p-2 sm:p-8 rounded-md items-center flex flex-col">
                <p className="w-full flex items-start mt-4 font-bold text-lg">
                  You can update your feedback
                </p>
                <div className="grid  grid-cols-1 sm:grid-cols-2 gap-3 mx-0 sm:mx-auto mt-1">
                  {review?.map((value) =>
                    value.ratingType === "T" ? (
                      <Card variant="outlined">
                        <div className="m-5">
                          <p className="font-semibold text-base h-12">
                            {" "}
                            Q{value.msrSeqId}. {value.reviewQuestions}
                          </p>

                          <TextField
                            value={
                              updatedRating.find(
                                (r) => r.qNo === value.msrSeqId
                              )?.rating ?? ""
                            }
                            onChange={(event) =>
                              handleChange1(
                                event,
                                value.msrSeqId,
                                value.ratingType,
                                user?.succesObject.filter(
                                  (e) => e.reviewQuestion === value.msrSeqId
                                ).reviewSeqId
                              )
                            }
                            sx={{ width: "100%", padding: "4%" }}
                            placeholder="Your feedback..."
                          ></TextField>
                        </div>
                      </Card>
                    ) : (
                      <CardWithFeedback
                        question={value.reviewQuestions}
                        qno={value.msrSeqId}
                        rType={value.ratingType}
                      />
                    )
                  )}
                </div>

                <Button
                  onClick={handleUpdate}
                  variant="contained"
                  sx={{ width: "20%", margin: "2rem" }}
                >
                  Update
                </Button>
              </div>
            ) : (
              <div className="w-full  ml-2  sm:ml-4 border-0 sm:border  sm:p-8 rounded-md items-center flex flex-col">
                <p className="w-full flex items-start sm:mt-4 font-bold text-lg mb-3 sm:mb-0 -mt-3 -ml-6 sm:ml-0 ">
                  Please share your feedback
                </p>
                <div className="grid  sm:grid-cols-2 gap-3 mx-0 sm:mx-auto mt-1">
                  {review?.map((value) =>
                    value.ratingType === "T" ? (
                      <Card variant="outlined">
                        <div className="m-5">
                          <p className="font-semibold text-base h-12">
                            {" "}
                            Q{value.msrSeqId}. {value.reviewQuestions}
                          </p>

                          <TextField
                            value={
                              rating.find((r) => r.qNo === value.msrSeqId)
                                ?.rating ?? ""
                            }
                            onChange={(event) =>
                              handleChange(
                                event,
                                value.msrSeqId,
                                value.ratingType
                              )
                            }
                            sx={{ width: "100%", padding: "4%" }}
                            placeholder="Your feedback..."
                          ></TextField>
                        </div>
                      </Card>
                    ) : (
                      <CardWithButtons
                        question={value.reviewQuestions}
                        qno={value.msrSeqId}
                        rType={value.ratingType}
                      />
                    )
                  )}
                </div>{" "}
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  sx={{ width: "20%", margin: "2rem" }}
                >
                  Submit
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </>
  );
}

export default Review;
