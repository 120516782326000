import services from "./index";
import httpcommon from "./httpcommon";

class HowItWorks {
    getSmartToolsData() {
        return services.get("how-it-works-smart-tools")
    };

    getRecordsData() {
        return services.get("how-it-works-record-dpts")
    };

    getOurServices() {
        return services.get("how-it-work-our-services")
    }
    getOurCareplans() {
        return httpcommon.post("/careplans/getourcarePlan")
    }

    getOurAim() {
        return httpcommon.get("https://patient.curebay.com/dhp/api/v1/reports/getDetails");
    };

};
export default new HowItWorks();