import { Button, Icon, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getHowItWorksAction,
  healtchCareService,
  exportComparePlan,
  getBenifits,
  fetchGeneralHealth,
  healthCareService,
} from "../../store/actions/careplans";
import {
  RightComponent,
  LeftComponent,
  SubTitle,
  CarePlanTable,
} from "../common/index";
import ExploreHealthCare from "../common/exploreotherhealthcare";
import "./healthcare.scss";
import image from "./image/img.jpeg";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Footer from "../homepage/footer";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import { updateCart } from "../../store/actions/login";
import loginservice from "../../store/services/loginservice";
import { COMMONCONSTANTS } from "../../store/constants/commonconstants";
import { useSnackbar } from "../common/snackbar";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const HeartHealthCare = () => {
  const userData = useSelector((state) => state.AuthReducer.userData);
  const [show, setShow] = useState(true);
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(title === "Yearly" ? true : false);
    const handleOnClick = () => {
      setIsActive(!isActive);
      // setShow(!show)
    };

    console.log(content);
    return (
      <div className="border-2 rounded-lg md:w-[80rem]  mt-3 bg-white divide-y-2 px-8  ">
        <div
          className="flex cursor-pointer justify-between items-center md:py-2"
          onClick={handleOnClick}
        >
          <p
            className={`md:w-11/12  md:text-[22px] text-sm font-poppins  font-normal `}
          >
            {title}
          </p>
          <div className="md:flex justify-end md:text-3xl text-sm font-semibold  text-brand-primary md:w-96">
            <IconButton size="medium" sx={{ backgroundColor: "#E4FAFF" }}>
              {isActive ? (
                <Icon fontSize="inherit" color="#262626">
                  keyboard_arrow_up
                </Icon>
              ) : (
                <Icon fontSize="inherit" color="#262626">
                  keyboard_arrow_down
                </Icon>
              )}
            </IconButton>
          </div>
        </div>
        {isActive &&
          content?.map((value) => (
            <div className="md:py-4">
              <div className="md:w-11/12 md:text-[20px] font-medium text-[#0B4881] font-poppins pb-3 ">
                {value?.feature}
              </div>
              <div className="md:w-11/12 md:text-[18px] font-custom font-normal text-black font-poppins md:pb-15">
                {value?.descriptions}
              </div>
            </div>
          ))}
      </div>
    );
  };

  const [value, setValue] = useState("2");
  const [generalHealthPackage, setGeneralHealthPackage] = useState([]);
  const [healthCareDetails, setHealthCareDetails] = useState([]);
  const [compareHealth, setCompareHealthCare] = useState([]);
  const [compareHealth1, setCompareHealthCare1] = useState([]);
  const [compareHealth2, setCompareHealthCare2] = useState([]);
  const [compareHealth3, setCompareHealthCare3] = useState([]);
  const [compareHealth4, setCompareHealthCare4] = useState([]);
  const [benefits, setBenefits] = useState([]);
  const [take, setTake] = useState(9);
  const [generalHealth, setGeneralHealth] = useState([]);
  const navigate = useNavigate();

  const [loggedin, setLoggedIn] = useState(false);
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
    ? userObj.parentCode
    : null;
  console.log(userCode);
  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  useEffect(() => {
    if (userCode) setLoggedIn(true);
  }, [userCode]);
  const handleAddCart = () => {
    if (!loggedin) navigate(APP_ROUTES.LOGIN);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchHelthCareData() {
      const resposne = await healtchCareService();
      if (resposne?.data?.data.length > 0) {
        setHealthCareDetails(resposne?.data?.data);
      }
    }
    async function fetchData() {
      const comparePlanResponse = await exportComparePlan(1);
      if (
        comparePlanResponse?.data?.responseCode === "200" &&
        comparePlanResponse?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare(comparePlanResponse?.data?.succesObject);
      }
      const comparePlanResponse1 = await exportComparePlan(3);
      if (
        comparePlanResponse1?.data?.responseCode === "200" &&
        comparePlanResponse1?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare1(comparePlanResponse1?.data?.succesObject);
      }
      const comparePlanResponse2 = await exportComparePlan(4);
      if (
        comparePlanResponse2?.data?.responseCode === "200" &&
        comparePlanResponse2?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare2(comparePlanResponse2?.data?.succesObject);
      }
      const comparePlanResponse3 = await exportComparePlan(6);
      if (
        comparePlanResponse3?.data?.responseCode === "200" &&
        comparePlanResponse3?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare3(comparePlanResponse3?.data?.succesObject);
      }
      const comparePlanResponse4 = await exportComparePlan(5);
      if (
        comparePlanResponse4?.data?.responseCode === "200" &&
        comparePlanResponse4?.data?.responseMessage === "Success"
      ) {
        setCompareHealthCare4(comparePlanResponse4?.data?.succesObject);
      }

      // const benefitsResponse = await getBenifits();
      // if (benefitsResponse?.status === 200 && benefitsResponse?.data?.data) {
      //   setBenefits(benefitsResponse.data.data[0]);
      // }
    }
    async function fetchGeneralHealthDetails() {
      const response = await fetchGeneralHealth();
      if (response?.data?.data) {
        setGeneralHealth(response?.data?.data);
      }
    }
    fetchData();
    fetchHelthCareData();
    fetchGeneralHealthDetails();
    dispatch(getHowItWorksAction());
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(compareHealth);
  const howItWorksDetails = useSelector(
    (state) => state.CarePlansReducer.howItWorksDetails
  );

  useEffect(() => {
    if (howItWorksDetails) {
      if (generalHealthPackage.length === 0) {
        let filterData = howItWorksDetails.data.filter(
          (element) =>
            element?.attributes?.package_name === "General Health Package"
        );
        setGeneralHealthPackage(filterData);
      }
    }
  }, [howItWorksDetails, generalHealthPackage]);

  const handleServiceChange = () => {
    if (take === 9) {
      setTake(generalHealth?.attributes?.FourthComponent[0]?.content?.length);
    } else {
      setTake(9);
    }
  };

  const [Temp, setTemp] = useState();
  useEffect(() => {
    async function fetchHelthPlan() {
      const resposne = await healthCareService();
      if (resposne?.data?.data.length > 0) {
        setTemp(resposne?.data?.data?.filter((e) => e?.id === 6)?.[0]);
      }
    }
    fetchHelthPlan();
  }, []);

  console.log(Temp, "Temp");

  const addToCart = async (e) => {
    if (!loggedin) {
      const data = {
        prop1: "Cardiac Care",
        prop2: 4,
        prop3: e === "monthly" || e === "yearly" ? e : undefined,
      };
      // navigate(APP_ROUTES.LOGIN)
      navigate(APP_ROUTES.LOGIN, { state: data });
    } else {
      let data = JSON.parse(userData);
      let payload = {
        userCode: userCode,
        cartType: 1,
        cartVOList: [
          {
            userId: "",
            userCode: userCode,
            planId: 4,
            planName: "Cardiac Care",
            period: e === "monthly" || e === "yearly" ? e : undefined,
          },
        ],
      };

      const res = await updateCart(payload);
      if (res?.status === 200 && res?.data?.responseCode) {
        let userData = {
          userCode: data?.[0]?.userCode,
        };
        loginservice
          .cartList(userData)
          .then((res) => {
            dispatch({
              type: COMMONCONSTANTS.SUCCESS_FETCHCART,
              payload: res?.data?.succesObject,
            });
          })
          .catch((error) => {});
        setSnackbarMessage("Added to the cart", "success");
      }
    }
  };

  const monthlyGen = compareHealth?.careplanFeturesVos?.filter(
    (e) => e?.monthlys === "Y"
  );
  const quarterlyGen = compareHealth?.careplanFeturesVos?.filter(
    (e) => e?.quarterlys === "Y"
  );
  const hYearlyGen = compareHealth?.careplanFeturesVos?.filter(
    (e) => e?.halfYearlys === "Y"
  );
  const yearlyGen = compareHealth?.careplanFeturesVos?.filter(
    (e) => e?.yearlys === "Y"
  );
  const onDemandGen = compareHealth?.careplanFeturesVos?.filter(
    (e) => e?.onDemands === "Y"
  );

  const monthlyDia = compareHealth1?.careplanFeturesVos?.filter(
    (e) => e?.monthlys === "Y"
  );
  const quarterlyDia = compareHealth1?.careplanFeturesVos?.filter(
    (e) => e?.quarterlys === "Y"
  );
  const hYearlyDia = compareHealth1?.careplanFeturesVos?.filter(
    (e) => e?.halfYearlys === "Y"
  );
  const yearlyDia = compareHealth1?.careplanFeturesVos?.filter(
    (e) => e?.yearlys === "Y"
  );
  const onDemandDia = compareHealth1?.careplanFeturesVos?.filter(
    (e) => e?.onDemands === "Y"
  );

  const monthlyCar = compareHealth2?.careplanFeturesVos?.filter(
    (e) => e?.monthlys === "Y"
  );
  const quarterlyCar = compareHealth2?.careplanFeturesVos?.filter(
    (e) => e?.quarterlys === "Y"
  );
  const hYearlyCar = compareHealth2?.careplanFeturesVos?.filter(
    (e) => e?.halfYearlys === "Y"
  );
  const yearlyCar = compareHealth2?.careplanFeturesVos?.filter(
    (e) => e?.yearlys === "Y"
  );
  const onDemandCar = compareHealth2?.careplanFeturesVos?.filter(
    (e) => e?.onDemands === "Y"
  );

  const monthlyWon = compareHealth3?.careplanFeturesVos?.filter(
    (e) => e?.monthlys === "Y"
  );
  const quarterlyWon = compareHealth3?.careplanFeturesVos?.filter(
    (e) => e?.quarterlys === "Y"
  );
  const hYearlyWon = compareHealth3?.careplanFeturesVos?.filter(
    (e) => e?.halfYearlys === "Y"
  );
  const yearlyWon = compareHealth3?.careplanFeturesVos?.filter(
    (e) => e?.yearlys === "Y"
  );
  const onDemandWon = compareHealth3?.careplanFeturesVos?.filter(
    (e) => e?.onDemands === "Y"
  );

  const monthlyOth = compareHealth4?.careplanFeturesVos?.filter(
    (e) => e?.monthlys === "Y"
  );
  const quarterlyOth = compareHealth4?.careplanFeturesVos?.filter(
    (e) => e?.quarterlys === "Y"
  );
  const hYearlyOth = compareHealth4?.careplanFeturesVos?.filter(
    (e) => e?.halfYearlys === "Y"
  );
  const yearlyOth = compareHealth4?.careplanFeturesVos?.filter(
    (e) => e?.yearlys === "Y"
  );
  const onDemandOth = compareHealth4?.careplanFeturesVos?.filter(
    (e) => e?.onDemands === "Y"
  );
  return (
    <div className="select-none xs-overflow-x-hidden  md:absolute md:top-10 w-full md:w-[87.5rem]">
      {snackbarMessage}
      <div className="block md:hidden">
        <div
          className="bg-position-right"
          style={{
            display: "flex",
            justifyContent: "flex-start",
            backgroundImage: `url("${Temp?.attributes?.firstcomp?.image_url}")`,
            backgroundSize: "contain",
            width: "100%",
            height: "200px",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      <div
        className="mainDiv  bg-white flex justify-start bg-cover xs-bg-white"
        style={{
          backgroundImage: `url("${Temp?.attributes?.firstcomp?.image_url}")`,
          alignItems: "center",
          paddingLeft: "20px",
          paddingRight: "20px",
          // maxWidth: '100%',
          height: "100%",
        }}
      >
        <div className="container mx-auto md:px-3 px-0">
          {/* <div className=" w-200 h-8 bg-shakespeare bg-opacity-[8%] m-1 py-2 px-2 rounded-md">
                                <p className='text-14px md:text-sm  text-center md:font-normal text-chathams-blue-500 whitespace-nowrap	'>{data && data.FirstComponent?.name || ''}</p>
                            </div> */}
          <div className="mt-10  md:mt-36  bg-transparent">
            {/* <p className='md:text-40px md:font-bold leading-54px text-chathams-blue-500'>{data && data.FirstComponent?.title || ''}</p> */}
            <h1 className="md:text-[44px] text-[22px] font-semibold md:leading-54px  md:w-6/12 w-full text-newblue font-poppins">
              {Temp?.attributes?.firstcomp?.title?.substring(0, 25)}
            </h1>
            <h2 className="font-light md:text-40px text-[20px] md:leading-54px font-poppins text-newblue md:w-5/12 w-full md:pb-5 pb-2 ">
              {Temp?.attributes?.firstcomp?.title?.substring(
                25,
                Temp?.attributes?.firstcomp?.title?.length
              )}{" "}
            </h2>
            <p className="font-normal text-[16px]  leading-[30.5px] font-poppins md:w-5/12 w-full md:mb-8 mb-2">
              {Temp?.attributes?.firstcomp?.description}
            </p>
          </div>
        </div>
      </div>

      <div className="md:px-4 px-5 md:py-8 py-4">
        <div className="md:flex flex-col md:px-8 px-2 ">
          <h2 className="md:text-[40px] text-xl font-poppins font-[300]  md:h-12 ">
            {Temp?.attributes?.SecondComp?.name?.substring(0, 6)}
          </h2>
          <div className="md:flex">
            <h3 className="md:text-[50px] text-xl font-medium  font-poppins text-newgreen md:w-[36rem]">
              {Temp?.attributes?.SecondComp?.name?.substring(
                6,
                Temp?.attributes?.SecondComp?.name?.length
              )}
            </h3>
            <div className=" w-full flex flex-col justify-center align-middle ">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
        </div>
        <div className="md:px-8 px-2 pt-6">
          <p className="font-poppins font-normal md:text-[20px] text-16px  leading-[30.5px]">
            {Temp?.attributes?.SecondComp?.description}
          </p>
          <div className="md:flex md:pt-8 pt-6 justify-start md:gap-96">
            <div className="md:flex p-5 md:px-0 ">
              <img
                className="relative md:absolute md:left-12 md:w-[24rem] w-fit"
                src={Temp?.attributes?.SecondComp?.image_url1}
              ></img>
              <img
                className="relative md:left-72 md:top-52 h-[240px] xs-spl-img"
                src={Temp?.attributes?.SecondComp?.image_url2}
              ></img>
            </div>
            <div>
              <h2 className="font-bold font-poppins md:text-[26px] text-xl ">
                {Temp?.attributes?.SecondComp?.title}
              </h2>

              {Temp?.attributes?.SecondComp?.points?.map((ele) => {
                return (
                  <div className="flex items-start gap-2 md:m-5 m-0 pt-5 md:pt-0  ">
                    <img
                      className=""
                      src={Temp?.attributes?.SecondComp?.tick_url}
                      alt="d"
                    ></img>
                    <h3
                      className="text-[16px] font-poppins font-normal"
                      key={ele.id}
                    >
                      {ele.points}
                    </h3>
                  </div>
                );
              })}

              {/* <div className='flex md:gap-8 gap-2 py-8 md:py-0'>
                                <div className='bg-[#F5FCFF] rounded-md grid  md:w-full w-2/4  border border-[#C3EBFC]'>
                                    <h1 className='text-center md:text-start font-poppins font-bold md:text-[14px] text-sm md:w-full p-5'>Pay Monthly</h1>
                                    <h1 className='text-center font-bold font-poppinsm md:text-[30px] text-sm text-[#0272DA]'>
                                        $69<span className='font-poppins md:text-[12px] text-sm fontt-normal text-[#606060]'>/mo X 12</span>
                                    </h1>
                                    <h1 className='text-center md:text-[12px] text-xs font-poppins font-semibold'>
                                        Pay $69 every month for a year.
                                    </h1>
                                    <button variant="contained" color="primary" className="   md:w-28 py-3 bg-royal-blue rounded-md text-white text-sm font-poppins relative top-6 md:ml-[5.5rem] " onClick={addToCart}>
                                        Add To Cart
                                    </button>
                                </div>
                                <div className='bg-[#F5FCFF] rounded-md grid relative md:w-full w-2/4 border border-[#C3EBFC]'>
                                    <div className='md:flex '>
                                        <h1 className='text-center md:text-start font-poppins font-bold md:text-[14px] text-sm md:w-full p-5'>Pay in Full</h1>
                                        <h1 className='absolute md:relative top-0 right-0 bg-black text-center pt-1 text-white md:h-6 w-24 md:w-32 text-[10px] md:text-[12px] font-poppins
 
                                    '>Most Popular</h1>
                                    </div>
                                    <h1 className='text-center font-bold font-poppinsm md:text-[30px] text-sm text-[#0272DA]'>
                                        $759<span className='font-poppins md:text-[12px] text-sm fontt-normal text-[#606060]'>/mo X 11</span>
                                    </h1>
                                    <h1 className='text-center md:text-[12px] text-xs font-poppins font-semibold'>
                                        Pay once for $69 x 11 and get 12th month free.
                                    </h1>
                                    <button variant="contained" color="primary" className="   md:w-28 py-3 bg-royal-blue rounded-md text-white text-sm font-poppins relative top-6 md:ml-[5.5rem]  " onClick={addToCart}>
                                        Add To Cart
                                    </button>

                                </div>
                            </div> */}

              <div className="flex md:gap-8 gap-2 py-8 md:py-0">
                <div className="bg-[#F5FCFF] rounded-md grid  md:w-full w-2/4  border border-[#C3EBFC] pt-1 sm:pt-0">
                  <p className="text-center md:text-start font-poppins font-bold md:text-[14px] text-[12px] text-sm md:w-full sm:p-5 p-2">
                    Pay Monthly
                  </p>
                  <p className="text-center font-bold font-poppinsm md:text-[30px] text-[22px] text-[#0272DA]">
                    $69
                    <span className="font-poppins md:text-[12px] text-base fontt-normal text-[#606060]">
                      /mo X 12
                    </span>
                  </p>
                  <p className="text-center md:text-[12px] text-[11px] font-poppins mt-2 sm:mt-0">
                    Pay <strong>$69 every month</strong> for a year.
                  </p>
                  <button
                    variant="contained"
                    color="primary"
                    className="   md:w-28 sm:py-3 py-1 mx-3 sm:mx-0 bg-royal-blue rounded-md text-white text-sm font-poppins relative sm:top-6 top-4 md:ml-[5.5rem]"
                    onClick={() => addToCart("monthly")}
                  >
                    Add To Cart
                  </button>
                </div>
                <div className="bg-[#F5FCFF] rounded-md grid relative md:w-full w-2/4 border border-[#C3EBFC] pt-1 sm:pt-0">
                  <div className="md:flex ">
                    <p className="text-center md:text-start font-poppins font-bold md:text-[14px] text-[12px] text-sm md:w-full sm:p-5 p-2">
                      Pay in Full
                    </p>
                    <p
                      className="hidden sm:block absolute md:relative top-0 right-0 bg-black text-center pt-1 text-white md:h-6 w-24 md:w-32 text-[10px] md:text-[12px] font-poppins
                                    "
                    >
                      Most Popular
                    </p>
                  </div>
                  <p className="text-center font-bold font-poppinsm md:text-[30px] text-[22px] text-[#0272DA]">
                    $759
                    <span className="font-poppins md:text-[12px] text-base fontt-normal text-[#606060]">
                      /year
                    </span>
                  </p>
                  <p className="text-center md:text-[12px] text-[11px] font-poppins  mt-2 sm:mt-0">
                    Pay once for<strong> $69 x 11</strong> and get{" "}
                    <strong>12th month free.</strong>
                  </p>
                  <button
                    variant="contained"
                    color="primary"
                    className="  md:w-28 sm:py-3 py-1 mx-3 sm:mx-0 bg-royal-blue rounded-md text-white text-sm font-poppins relative sm:top-6 top-4 md:ml-[5.5rem] "
                    onClick={() => addToCart("yearly")}
                  >
                    Add To Cart{" "}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='md:px-4 px-2 md:py-8 py-4 mx-12'>
                <h1 className='text-[#0B4881] font-bold font-poppins text-[20px]'>
                    General Health Frequency
                </h1>
                <div>
                    <Accordion title={"Monthly"} content={monthlyGen} />
                    <Accordion title={"Quarterly"} content={quarterlyGen} />
                    <Accordion title={"Half Yearly"} content={hYearlyGen} />
                    <Accordion title={"Yearly"} content={yearlyGen} />
                    <Accordion title={"On Demand"} content={onDemandGen} />
                </div>
            </div>
            <div className='md:px-4 px-2  mx-12'>
                <h1 className='text-[#0B4881] font-bold text-[20px]'>
                    Diabetes Care Frequency
                </h1>
                <div>
                    <Accordion title={"Monthly"} content={monthlyDia} />
                    <Accordion title={"Quarterly"} content={quarterlyDia} />
                    <Accordion title={"Half Yearly"} content={hYearlyDia} />
                    <Accordion title={"Yearly"} content={yearlyDia} />
                    <Accordion title={"On Demand"} content={onDemandDia} />
                </div>
            </div> */}

      <div className="md:px-4 px-2 md:py-8 py-4 sm:mx-12 mx-6">
        <h2 className="text-[#0B4881] font-bold text-[20px]">
          Cardiac Care Frequency
        </h2>
        <div>
          <Accordion title={"Yearly"} content={yearlyCar} />
          <Accordion title={"Half Yearly"} content={hYearlyCar} />
          <Accordion title={"Quarterly"} content={quarterlyCar} />
          <Accordion title={"Monthly"} content={monthlyCar} />
          <Accordion title={"On Demand"} content={onDemandCar} />
        </div>
      </div>

      {/* <div className='md:px-4 px-2  mx-12'>
                <h1 className='text-[#0B4881] font-bold text-[20px]'>
                    Women's Health Frequency
                </h1>
                <div>
                    <Accordion title={"Monthly"} content={monthlyWon} />
                    <Accordion title={"Quarterly"} content={quarterlyWon} />
                    <Accordion title={"Half Yearly"} content={hYearlyWon} />
                    <Accordion title={"Yearly"} content={yearlyWon} />
                    <Accordion title={"On Demand"} content={onDemandWon} />
                </div>
            </div>

            <div className='md:px-4 px-2 md:py-8 py-4 mx-12'>
                <h1 className='text-[#0B4881] font-bold text-[20px]'>
                    Ortho Care Frequency
                </h1>
                <div>
                    <Accordion title={"Monthly"} content={monthlyOth} />
                    <Accordion title={"Quarterly"} content={quarterlyOth} />
                    <Accordion title={"Half Yearly"} content={hYearlyOth} />
                    <Accordion title={"Yearly"} content={yearlyOth} />
                    <Accordion title={"On Demand"} content={onDemandOth} />
                </div>
            </div> */}
      {show ? (
        <div className="md:flex md:mx-16 mx-4 justify-center items-center bg-[#0272DA] md:py-3 text-center sm:text-left pb-4 sm:pb-0">
          <h2 className="font-poppins md:text-[20px] text-sm p-3 font-semibold text-white">
            Take care of your loved ones with a simple click
          </h2>
          <button
            variant="contained"
            color="primary"
            className="  md:w-36 w-24 md:py-2 py-1 bg-[#F0EA4F] rounded-md text-black text-sm font-poppins font-medium sm:ml-[3rem] "
            onClick={addToCart}
          >
            Add To Cart
          </button>
        </div>
      ) : (
        <></>
      )}
      <div className="md:px-4 px-5 md:py-20 py-10">
        <div className="md:flex flex-col md:px-8 px-2 ">
          <h2 className="md:text-[40px] text-xl font-poppins font-[300]  md:h-12 ">
            We blend technology, expertise, and compassion
          </h2>
          <div className="md:flex">
            <h3 className="md:text-[50px] text-xl font-medium  font-poppins text-newgreen md:w-[50rem]">
              in our care plans.
            </h3>
            <div className=" md:w-full md:flex flex-col justify-center align-middle ">
              <p className="border-b-2 w-full border-newgreen"></p>
              <></>
            </div>
          </div>
          <p className="font-poppins font-normal leading-[30.5px] md:text-[20px] text-16px  md:pt-6 pt-4">
            An extensive spectrum of services tailored to fulfill your
            healthcare needs.
          </p>
        </div>

        <div className="md:grid grid-cols-3">
          {Temp?.attributes?.FourthComp?.content?.length > 0 &&
            Temp?.attributes?.FourthComp?.content
              ?.slice(0, take)
              .map((element, index) => {
                var textResponse = element?.description;
                // textResponse = textResponse.replace(/\n/, '\n');
                //   var  textResponse = element?.description.replace(/\\n/g, '\n');
                const newText = textResponse
                  .split("\n")
                  .map((str) => <p className="mb-2">{str}</p>);
                return (
                  <div className="md:mx-8 md:my-4 " key={index}>
                    <div className="flex flex-row md:mx-2 my-4 items-center ">
                      <img
                        className="sm:min-h-[115px] sm:min-w-[115px] min-h-[70px] min-w-[70px] object-cover"
                        draggable={false}
                        src={element?.image_url}
                        alt="service Images"
                      />
                      <div className="md:pl-2">
                        <p className="text-chathams-blue-500 font-semibold md:text-lg text-xl">
                          {element?.name}
                        </p>
                        <p className="pt-2 text-chathams-blue-500 font-semibold text-sm">
                          {newText}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
        </div>
        {/* <div className='flex flex-row my-6 justify-center '>
                    <Button
                        endIcon={take === 9 ? <Icon>keyboard_arrow_right</Icon> : ''}
                        startIcon={take > 9 ? <Icon>keyboard_arrow_left</Icon> : ''}
                        onClick={() => { handleServiceChange() }}
                        variant='contained'
                        className=" text-base font-semibold text-center text-royal-blue-500 underline"
                    > {take === 9 ? 'Show All' : 'Show Less'}</Button>
                </div> */}
      </div>
      <div className="md:px-4 px-5">
        <div className="md:flex flex-col md:px-8 ">
          <h2 className="md:text-[40px] text-2xl px-2 md:px-0font-poppins font-[300]  md:h-12 ">
            Beyond basic healthcare we offer a range of
          </h2>
          <div className="md:flex">
            <h3 className="md:text-[50px] text-2xl px-2 md:px-0 font-medium  font-poppins text-newgreen md:w-[90rem]">
              additional health services.
            </h3>
            <div className="  md:w-full w-11/12 px-6 md:px-0 md:flex flex-col justify-center align-middle">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
          <p className="font-poppins font-normal leading-[30.5px] md:text-[20px] text-16 px-4 md:px-0 pt-6">
            CureBay provides holistic care, including rehabilitation, health
            education, social services, counseling, and preventive care, going
            beyond treatment and checkups.
          </p>
        </div>
        <div className="grid-cols-1 md:grid-cols-4 gap-x-5 md:gap-x-10 gap-y-6 md:gap-y-12 justify-items-center my-10 mx-8 hidden sm:grid">
          {Temp?.attributes?.FifthComp?.content?.map((value, i) => (
            <div
              key={i}
              className=" w-[300px] h-[286px] border-2 p-6 rounded-lg"
            >
              <img src={value?.icon_url} alt="" />
              <h2 className="font-poppins font-semibold text-[20px] mt-5">
                {value?.name}
              </h2>
              <h3 className="font-poppins font-normal text-[16px] mt-5">
                {value?.description}
              </h3>
            </div>
          ))}
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 gap-x-5 md:gap-x-10 gap-y-1 mt-4 justify-items-center mb-10  sm:hidden">
          {Temp?.attributes?.FifthComp?.content?.map((value, i) => (
            <div
              key={i}
              className=" md:w-[300px] md:h-[286px] border-b-2 py-4 flex flex-row"
            >
              <div className="w-1/5">
                {" "}
                <img src={value?.icon_url} alt="" />
              </div>
              <div className="w-4/5">
                <p className="font-poppins font-semibold text-[16px] mb-2">
                  {value?.name}
                </p>
                <p className="font-poppins font-normal text-[14px] md:mt-5">
                  {value?.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="">
        <p className="md:mb-14 font-poppins md:text-[46px] text-2xl font-light text-center">
          Explore other{" "}
          <span className="text-[#40B882] font-poppins md:text-[46px] text-xl font-medium">
            care plans.
          </span>
        </p>
      </div>
      {/* {<SubTitle topheading={benefits?.attributes?.title} title={benefits?.attributes?.description} desc={benefits?.attributes?.detail_description} />} */}
      {/* <div className="container mx-auto bg-transparent flex flex-col justify-center items-center">
                    <p className="bg-sky bg-opacity-[8%] text-chathams-blue-500 py-2 px-2 text-center">{generalHealth?.attributes?.ThirdComponent[0]?.name}</p>
                    <p className="text-4xl mt-5 font-bold text-chathams-blue-500 text-center">{generalHealth?.attributes?.ThirdComponent[0]?.title}</p>
                    <p className="text-lg mt-4 font-semibold text-chathams-blue-500 text-center">{generalHealth?.attributes?.ThirdComponent[0]?.description}</p>
                </div> */}

      {/* <div className='mx-8 my-4'>
                    <div className='grid grid-cols-4 my-10'>
                        {generalHealth?.attributes?.ThirdComponent[0]?.content?.length > 0
                            && generalHealth?.attributes?.ThirdComponent[0]?.content?.map((e) => (
                                <div className='inline-flex flex-col items-center mx-2 my-2 '>
                                    <img src={e?.image_url} alt='img' className='w-[60px] h-[60-px]'></img>
                                    <p className='text-chathams-blue-500 font-bold text-[22px] leading-[27px] pt-[14px]'>{e?.title}</p>
                                    <p className='text-chathams-blue-500 font-normal text-center text-lg mx-6 pt-[14px]'>{e?.short_description}</p>
                                </div>))
                        }
                    </div>
                </div> */}

      {/* {<SubTitle topheading={healthCareDetails[0]?.attributes?.title} title={healthCareDetails[0]?.attributes?.description} desc={healthCareDetails[0]?.attributes?.detail_description ? healthCareDetails[0]?.attributes?.detail_description : `Our care team is at your service 24*7. We also facilitate a 'panic button', which builds a quick response protocol like no other.`} />} */}

      {/* <div className="container mx-auto bg-transparent flex flex-col justify-center items-center">
                    <p className="bg-sky bg-opacity-[8%] text-chathams-blue-500 py-2 px-2 text-center">{generalHealth?.attributes?.FourthComponent[0]?.name}</p>
                    <p className="text-4xl mt-5 font-bold text-chathams-blue-500 text-center">{generalHealth?.attributes?.FourthComponent[0]?.title}</p>
                    <p className="text-lg mt-4 font-semibold text-chathams-blue-500 text-center">{generalHealth?.attributes?.FourthComponent[0]?.description}</p>
                </div>
                <div className='grid grid-cols-3'>
                    {
                        generalHealth?.attributes?.FourthComponent[0]?.content?.length > 0 && (
                            generalHealth?.attributes?.FourthComponent[0]?.content?.slice(0, take).map((element, index) => {
                                var textResponse = element?.description
                                // textResponse = textResponse.replace(/\n/, '\n');
                                //   var  textResponse = element?.description.replace(/\\n/g, '\n');
                                const newText = textResponse.split('\n').map(str => <p className='mb-2'>{str}</p>);
                                return (
                                    <div className='mx-8 my-4 ' key={index}>
                                        <div className='flex flex-row mx-2 my-4 items-center ' >
                                            <img className='min-h-[115px] min-w-[115px] object-cover' draggable={false} src={element?.image_url} alt='service Images' />
                                            <div className='pl-2'>
                                                <p className='text-chathams-blue-500 font-semibold text-lg'>{element?.name}</p>
                                                <p className='pt-2 text-chathams-blue-500 font-semibold text-sm'>{newText}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        )
                    }
                </div>
                <div className='flex flex-row my-6 justify-center '>
                    <Button
                        endIcon={take === 9 ? <Icon>keyboard_arrow_right</Icon> : ''}
                        startIcon={take > 9 ? <Icon>keyboard_arrow_left</Icon> : ''}
                        onClick={() => { handleServiceChange() }}
                        variant='contained'
                        className=" text-base font-semibold text-center text-royal-blue-500 underline"
                    > {take === 9 ? 'Show All' : 'Show Less'}</Button>
                </div>



                {<SubTitle title={'General Health Package Price start from  $300'} desc={'Purchase this package for Monthly'} />}
                <div className='flex flex-row mt-6 mb-20 justify-center '>
                    <Button
                        endIcon={<Icon>keyboard_arrow_right</Icon>}
                        variant='contained'
                        className=" text-base font-semibold text-center text-royal-blue-500"
                        sx={{ textTransform: 'none' }}
                    >Start Today</Button>
                </div>

                {<SubTitle topheading={"General Health Plan"} title={"Check your General Health Care Plan"} />}
                <div className='flex flex-row  mt-6 mb-20 items-center justify-center  '>
                    <div className=' flex  items-center justify-between md:w-3/6 sm:w-4/6'>
                        <p className='text-base text-chathams-blue font-semibold leading-[10px]'>Choose from our comprehensive yearly and half yearly plans</p>
                        <Button
                            variant='contained'
                            className=" text-base font-semibold text-center text-royal-blue-500 "
                            sx={{ textTransform: 'none' }} onClick={() => navigate(APP_ROUTES.LOGIN)}
                        >Add To Cart</Button>
                    </div>
                </div>

                {compareHealth?.careplanFeturesVos?.length && <CarePlanTable data={compareHealth} />}


                <div className='flex flex-row  mt-6 mb-20 items-center justify-center  '>
                    <div className=' flex  items-center justify-between md:w-3/6 sm:w-4/6 py-3 px-7 bg-[#FFFAEC]'>
                        <p className='text-base text-chathams-blue font-semibold leading-[10px]'>Take care of your loved ones with a simple click</p>
                        <Button
                            variant='contained'
                            className=" text-base font-semibold text-center text-royal-blue-500 "
                            onClick={() => navigate(APP_ROUTES.LOGIN)}
                        >Add To Cart</Button>
                    </div>
                </div> */}

      {/* {<SubTitle title={"Explore other care plans"} />} */}
      <div className="my-8 mx-4">
        <div className="block sm:hidden">
          <Carousel
            className="relative"
            infiniteLoop
            autoPlay
            showArrows={false}
            showStatus={false}
            showThumbs={false}
            // showIndicators={false}

            interval={3000}
          >
            {/* <div>
              <img src={image} alt="#" className="h-full w-full" />

            </div> */}

            {/* <figcaption className='-mt-11 text-center text-white font-poppins sm:text-[28px] text-[22px] font-semibold'>Heart Health</figcaption> */}
            <div className="carousel-slide ml-[-12px]">
              <figure className=" w-full justify-center mb-6 sm:mb-0 ">
                <img
                  className="object-cover sm:h-[390px] h-auto sm:w-[302px] w-full sm:mx-2"
                  draggable={false}
                  src={Temp?.attributes?.FifthComp?.images[0]?.image_url}
                  alt="img"
                />
                <figcaption className="-mt-11 text-center text-white font-poppins sm:text-[28px] text-[22px]  font-semibold">
                  General Health
                </figcaption>
              </figure>
            </div>

            <div className="carousel-slide ml-[-12px]">
              <figure className=" w-full justify-center mb-6 sm:mb-0 ">
                <img
                  className="object-cover sm:h-[390px] h-auto sm:w-[302px] w-full sm:mx-2"
                  draggable={false}
                  src={Temp?.attributes?.FifthComp?.images[2]?.image_url}
                  alt="img"
                />
                <figcaption className="-mt-11 text-center text-white font-poppins sm:text-[28px] text-[22px]  font-semibold">
                  Diabetes Care
                </figcaption>
              </figure>
            </div>

            <div className="carousel-slide ml-[-12px]">
              <figure className=" w-full justify-center mb-6 sm:mb-0 ">
                <img
                  className="object-cover sm:h-[390px] h-auto sm:w-[302px] w-full sm:mx-2 "
                  draggable={false}
                  src={Temp?.attributes?.FifthComp?.images[3]?.image_url}
                  alt="img"
                />
                <figcaption className="-mt-11 text-center text-white font-poppins sm:text-[28px] text-[22px]  font-semibold">
                  Ortho Care
                </figcaption>
              </figure>
            </div>
            <div className="carousel-slide ml-[-12px]">
              <figure className=" w-full justify-center mb-6 sm:mb-0 ">
                <img
                  className="object-cover sm:h-[390px] h-auto sm:w-[302px] w-full sm:mx-2 "
                  draggable={false}
                  src={Temp?.attributes?.FifthComp?.images[4]?.image_url}
                  alt="img"
                />
                <figcaption className="-mt-11 text-center text-white font-poppins sm:text-[28px] text-[22px]  font-semibold">
                  Womens' Health{" "}
                </figcaption>
              </figure>
            </div>
          </Carousel>
        </div>

        <Paper
          sx={{ boxShadow: "none" }}
          className="flex-col sm:flex-row sm:flex hidden"
        >
          <figure className=" w-full justify-center mb-6 sm:mb-0 ">
            <img
              className="object-cover sm:h-[390px] h-auto sm:w-[302px] w-full sm:mx-2"
              draggable={false}
              src={Temp?.attributes?.FifthComp?.images[0]?.image_url}
              alt="img"
            />
            <figcaption className="-mt-11 text-center text-white font-poppins sm:text-[28px] text-[22px]  font-semibold">
              General Health
            </figcaption>
          </figure>
          <figure className=" w-full justify-center mb-6 sm:mb-0 ">
            <img
              className="object-cover sm:h-[390px] h-auto sm:w-[302px] w-full sm:mx-2"
              draggable={false}
              src={Temp?.attributes?.FifthComp?.images[2]?.image_url}
              alt="img"
            />
            <figcaption className="-mt-11 text-center text-white font-poppins sm:text-[28px] text-[22px]  font-semibold">
              Diabetes Care
            </figcaption>
          </figure>

          <figure className=" w-full justify-center mb-6 sm:mb-0 ">
            <img
              className="object-cover sm:h-[390px] h-auto sm:w-[302px] w-full sm:mx-2 "
              draggable={false}
              src={Temp?.attributes?.FifthComp?.images[3]?.image_url}
              alt="img"
            />
            <figcaption className="-mt-11 text-center text-white font-poppins sm:text-[28px] text-[22px]  font-semibold">
              Ortho Care
            </figcaption>
          </figure>

          <figure className=" w-full justify-center mb-6 sm:mb-0 ">
            <img
              className="object-cover sm:h-[390px] h-auto sm:w-[302px] w-full sm:mx-2 "
              draggable={false}
              src={Temp?.attributes?.FifthComp?.images[4]?.image_url}
              alt="img"
            />
            <figcaption className="-mt-11 text-center text-white font-poppins sm:text-[28px] text-[22px]  font-semibold">
              Womens' Health{" "}
            </figcaption>
          </figure>
        </Paper>
      </div>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </div>
  );
};

export default HeartHealthCare;
