import { FormHelperText, Modal } from "@mui/material";
import { IconButton as MuiIconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useState } from "react";
import { useSnackbar } from "../common/snackbar";
import { useSnackbarerror } from "../common/snackbarerror";
import { styled } from "@mui/material/styles";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import mydetailsservice from "../../store/services/mydetailsservice";
import camera from "../../assets/images/camera.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  CalculateAge,
  formatDateToDDMMYYYY,
  shouldShowError,
  shouldShowErrorAdharedit,
} from "../common";
import userImage from "../../assets/images/userImage.svg";
import {
  fetchBloodGroupDetails,
  fetchStatesDetails,
} from "../../store/actions/myprofile";
import { shouldShowErrorName, shouldShowErrorName2 } from "../common";

const EditProfile = ({ open, onClose, props }) => {
  const [bloodGroups, setBloodGroups] = useState([]);
  const [states, setStates] = useState([]);
  const [loading, setLoading] = useState(false);
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const { snackbarerrorMessage, setSnackbarerrorMessage } = useSnackbarerror();

  const [patientData, setpatientData] = useState({});
  const [selectedPhoto, setselectedPhoto] = useState("");
  const [PhotoValue, setPhotoValue] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(null);

  const IconButton = styled(MuiIconButton)({
    backgroundColor: "white",
    borderColor: "#66B889 !important",
    borderWidth: "1px !important",
    border: "solid",
    color: "#66B889",
    "&:hover": {
      backgroundColor: "#66B889",
      color: "white",
    },
  });
  useEffect(() => {
    async function fetchData() {
      const bloodGroupDetailsResponse = await fetchBloodGroupDetails();
      if (
        bloodGroupDetailsResponse?.status === 200 &&
        bloodGroupDetailsResponse?.data?.succesObject
      ) {
        setBloodGroups(bloodGroupDetailsResponse.data.succesObject);
      }
      const statesDetailsResponse = await fetchStatesDetails();
      if (
        statesDetailsResponse?.status === 200 &&
        statesDetailsResponse?.data?.succesObject
      ) {
        setStates(statesDetailsResponse.data.succesObject);
      }
    }
    fetchData();
  }, []);

  const handleClose = () => {
    onClose();
  };

  const updateDetails = (e) => {
    if (patientData?.firstName.length < 3) {
      setSnackbarerrorMessage("First name must be at least 3 characters");
    } else if (patientData.mobile.length < 10) {
      setSnackbarerrorMessage("Mobile no. should not be less than 10 digit!");
      // return;
    } else if (patientData.emergencyContact.length < 10) {
      setSnackbarerrorMessage(
        "Emergency contact no. should not be less than 10 digit!"
      );
      // return;
    } else if (patientData.pinCode.length < 6) {
      setSnackbarerrorMessage("PinCode should not be less than 6 digit!");
      // return;
    } else if (shouldShowError(patientData.mobile) === true) {
      setSnackbarerrorMessage("Please enter valid phone number");
      // return;
    } else if (patientData.aadharId.length < 4) {
      setSnackbarerrorMessage(
        "Please enter last four digit of your aadhaar number"
      );
      // return;
    } else if (shouldShowErrorAdharedit(patientData.aadharId) === true) {
      setSnackbarerrorMessage(
        "Please enter last four digit of your aadhaar number"
      );
    } else {
      setLoading(true);
      let params = {
        salutation: patientData?.salutation ? patientData.salutation : "",
        firstName: patientData?.firstName ? patientData.firstName : "",
        lastName: patientData?.lastName ? patientData.lastName : "",
        gender: patientData?.gender ? patientData.gender : "",
        mobile: patientData?.mobile ? patientData.mobile : "",
        email: patientData?.email ? patientData.email : "",
        dob: patientData?.dob ? patientData?.dob : "",
        bloodGroup: patientData?.bloodGroup ? patientData.bloodGroup : "",
        age: patientData?.age ? patientData?.age : "",
        address1: patientData?.address1 ? patientData.address1 : "",
        address2: patientData?.address2 ? patientData.address2 : "",
        pinCode: patientData?.pinCode ? patientData.pinCode : "",
        state: patientData?.state ? patientData.state : "",
        city: patientData?.city ? patientData.city : "",
        cheId: patientData?.eClinics?.hospitalCode,
        cheLocationId: patientData?.eClinics?.locationCode,
        photo: PhotoValue ? PhotoValue : "",
        parentCode: patientData?.parentCode,
        code: patientData?.code,
        userId: 21,
        aadharId: patientData?.aadharId,
        emergencyContact: patientData?.emergencyContact
          ? patientData.emergencyContact
          : "",
      };

      mydetailsservice.updatePatientapi(params).then((res) => {
        setLoading(false);
        if (res?.data?.responseCode === "200") {
          setSnackbarMessage(res.data?.responseMessage);
          setTimeout(() => {
            handleClose();
          }, 3000);
        } else setSnackbarMessage(res.data?.responseMessage, "error");
      });
    }
  };

  useEffect(() => {
    let payload = {
      userCode: props?.userCode,
      code: props?.code,
    };

    mydetailsservice.getmyListapi(payload).then((res) => {
      setpatientData(res?.data?.succesObject);
    });
  }, []);

  console.log(patientData, "hhhhh");
  const changeHandler = async (file) => {
    let b64File = await encodeBase64File(file);
    setPhotoValue(b64File);
  };

  const encodeBase64File = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        setselectedPhoto(baseURL);
        resolve(baseURL.split(",")[1]);
      };
      console.log(fileInfo);
    });
  };

  const handleChange = (e) => {
    if (e.target.name === "salutation") {
      // Handle salutation selection
      setpatientData({
        ...patientData,
        salutation: e.target.value,
        gender: e.target.value === "Mr." ? "M" : "F",
      });
    } else if (e.target.name === "mobile") {
      let re = /^[0-9+\b]+$/;
      if (re.test(e.target.value) || e.target.value === "") {
        console.log("sasad", e.target.value);
        setpatientData({ ...patientData, [e.target.name]: e.target.value });
      }
    } else if (e.target.name === "emergencyContact") {
      let re = /^[0-9+\b]+$/;
      if (re.test(e.target.value) || e.target.value === "") {
        console.log("sasad", e.target.value);
        setpatientData({ ...patientData, [e.target.name]: e.target.value });
      }
    } else if (e.target.name === "pinCode") {
      let re = /^[0-9\b]$/;
      if (re.test(e.target.value) || e.target.value === "") {
        console.log("sasad", e.target.value);
        setpatientData({ ...patientData, [e.target.name]: e.target.value });
      }
    } else {
      setpatientData({ ...patientData, [e.target.name]: e.target.value });
    }
  };

  const handleDateChange = (selectedDate) => {
    const formattedDate = formatDateToDDMMYYYY(selectedDate);

    setDateOfBirth(selectedDate);

    setpatientData((prevData) => ({
      ...prevData,

      dob: formattedDate,
    }));
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        {snackbarMessage}
        {snackbarerrorMessage}
        <div className="fixed inset-0 flex items-center justify-center ">
          <div className="w-[79rem] h-[40rem] bg-white p-4 relative flex flex-col overflow-y-scroll">
            <div className="w-full flex justify-between items-center">
              <h2 className="text-xl font-bold text-chathams-blue-500">
                Edit Profile
              </h2>
              <IconButton onClick={handleClose} aria-label="Close">
                <CloseIcon />
              </IconButton>
            </div>
            {loading && (
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="absolute inset-0 bg-gray-900 opacity-75" />
                <div className="relative">
                  <div className="w-20 h-20 border-4 border-t-4 border-gray-200 rounded-full animate-spin" />
                </div>
              </div>
            )}

            <div>
              <div className="relative">
                <img
                  src={
                    selectedPhoto
                      ? selectedPhoto
                      : patientData?.photoName
                      ? patientData.photoName
                      : patientData?.patientPhotoName
                      ? patientData.patientPhotoName
                      : userImage
                  }
                  alt="userImage"
                  className="border md:w-24 md:h-24 h-20 mt-6 rounded-full"
                />
                <div className="-mt-4 justify-end flex6 absolute left-16 bottom-0">
                  <label className="w-8 h-8 flex justify-center cursor-pointer text-xs bg-brand-primary font-normal rounded-full mb-[6px]">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(e) => {
                        changeHandler(e.target.files[0]);
                      }}
                      className="hidden"
                    />
                    <img src={camera} alt="camera" className="w-5" />
                  </label>
                </div>
              </div>
              <h6 className="text-sm font-bold my-5 text-chathams-blue-500">
                Personal Details
              </h6>
              <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 ">
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    Salutation
                  </label>
                  <select
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1`}
                    id="salutation-select"
                    name="salutation"
                    value={patientData?.salutation}
                    onChange={handleChange}
                    style={{ fontSize: "13px" }}
                  >
                    <option value="Mr.">Mr.</option>
                    <option value="Miss">Miss</option>
                    <option value="Mrs.">Mrs.</option>
                  </select>
                  {/* <Input
                                        className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput`} onChange={handleChange}
                                        name="salutation" value={patientData?.salutation} /> */}
                </div>
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    First Name
                  </label>
                  <input
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1.5`}
                    onChange={handleChange}
                    name="firstName"
                    id="firstName"
                    aria-describedby="mobile-error-text"
                    value={patientData?.firstName}
                    error={shouldShowErrorName(patientData?.firstName)}
                    style={{ fontSize: "13px" }}
                    onKeyPress={(e) => {
                      // Allow only alphabetic characters
                      const isValidInput = /^[A-Za-z]+(\s[A-Za-z]*)?$/.test(
                        e.target.value + e.key
                      );
                      if (!isValidInput) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {shouldShowErrorName(patientData?.firstName) && (
                    <FormHelperText
                      style={{ color: "red" }}
                      id="mobile-error-text"
                    >
                      Invalid Name
                    </FormHelperText>
                  )}
                </div>

                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    Last Name
                  </label>
                  <input
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1.5`}
                    onChange={handleChange}
                    name="lastName"
                    value={patientData?.lastName}
                    style={{ fontSize: "13px" }}
                    onKeyPress={(e) => {
                      // Allow only alphabetic characters
                      const isValidInput = /^[A-Za-z]*$/.test(e.key);
                      if (!isValidInput) {
                        e.preventDefault();
                      }
                    }}
                  />
                </div>
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    Gender
                  </label>
                  <select
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1`}
                    name="gender"
                    value={patientData?.gender ? patientData.gender : ""}
                    onChange={handleChange}
                    style={{ fontSize: "13px" }}
                    disabled
                  >
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                  </select>
                </div>
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    Mobile Number
                  </label>

                  <input
                    autocomplete="off"
                    id="mobile"
                    minLength="10"
                    maxLength="10"
                    type="tel"
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1.5`}
                    onChange={handleChange}
                    name="mobile"
                    value={patientData?.mobile}
                    error={shouldShowError(patientData?.mobile)}
                    aria-describedby="mobile-error-text"
                    style={{ fontSize: "13px" }}
                  />
                  {shouldShowError(patientData?.mobile) && (
                    <FormHelperText
                      style={{ color: "red" }}
                      id="mobile-error-text"
                    >
                      Invalid mobile number
                    </FormHelperText>
                  )}
                </div>
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    Email ID
                  </label>
                  <input
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1.5`}
                    onChange={handleChange}
                    name="email"
                    value={patientData?.email}
                    style={{ fontSize: "13px" }}
                  />
                </div>
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-0">
                    Date of Birth
                  </label>
                  <DatePicker
                    locale="es"
                    id="dob"
                    name="dob"
                    dropdownMode="select"
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    value={patientData?.dob}
                    selected={dateOfBirth}
                    onChange={handleDateChange}
                    disabledKeyboardNavigation={true}
                    autoFocus={false}
                    placeholder=""
                    style={{ fontSize: "13px", color: "#666e82" }}
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput w-full  pt-1 text-gray-900 text-xs bg-transparent focus:outline-none`}
                  />
                  {/* <Input className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput`} onChange={handleChange} name="dob" value={patientData?.dob} /> */}
                </div>
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    Blood Group
                  </label>
                  {/* <Input className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput`} onChange={handleChange} name="bloodGroup"
                                     value={patientData?.bloodGroup} /> */}
                  <select
                    className={`mt-[3px] outline-none border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1`}
                    id="bloodGroup"
                    name="bloodGroup"
                    value={patientData?.bloodGroup}
                    onChange={handleChange}
                    style={{ fontSize: "13px" }}
                  >
                    {bloodGroups &&
                      bloodGroups?.map((value, index) => (
                        <option value={value.code} key={index}>
                          <p style={{ fontSize: "13px" }}>{value.code}</p>
                        </option>
                      ))}
                  </select>
                  {/* <select
                                            name="bloodGroup"
                                                className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput text-[13px]`} 
                                                onChange={handleChange} 
                                                value={patientData?.bloodGroup}
                                            >
                                                {bloodGroups && bloodGroups?.map((value, index) => (
                                                    <option value={value.code} key={index}>
                                                        <p className='text-base font-semibold'>{value.code}</p>
                                                    </option>
                                                ))}
                                            </select> */}
                </div>
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    Age
                  </label>
                  <input
                    className={` profileinput border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1.5`}
                    name="age"
                    value={CalculateAge(patientData?.dob)}
                    onChange={handleChange}
                    style={{ fontSize: "13px" }}
                    disabled
                  />
                </div>
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    eClinic
                  </label>
                  <input
                    disabled={true}
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1.5 profileinput`}
                    name="eClinic"
                    value={patientData?.eclinic}
                    style={{ fontSize: "13px" }}
                  />
                </div>

                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    Aadhaar No(Last 4 digit only)
                  </label>

                  <input
                    autocomplete="off"
                    minLength="4"
                    maxLength="4"
                    type="tel"
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1.5`}
                    onChange={handleChange}
                    name="aadharId"
                    value={patientData?.aadharId}
                    error={shouldShowErrorAdharedit(patientData?.aadharId)}
                    aria-describedby="mobile-error-text"
                    style={{ fontSize: "13px" }}
                    onKeyPress={(e) => {
                      // Allow only numeric input and limit length to 13 characters
                      const isValidInput = /^[0-9]*$/.test(e.key);
                      if (!isValidInput || e.target.value.length >= 4) {
                        e.preventDefault();
                      }
                    }}
                  />
                  {shouldShowErrorAdharedit(patientData?.aadharId) && (
                    <FormHelperText
                      style={{ color: "red" }}
                      id="mobile-error-text"
                    >
                      Please enter last four 4 of your aadhaar no.
                    </FormHelperText>
                  )}
                </div>

                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    Emergency Contact Number
                  </label>

                  <input
                    autocomplete="off"
                    id="emergencyContact"
                    minLength="10"
                    maxLength="10"
                    type="tel"
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1.5`}
                    onChange={handleChange}
                    name="emergencyContact"
                    value={patientData?.emergencyContact}
                    error={shouldShowError(patientData?.emergencyContact)}
                    aria-describedby="mobile-error-text"
                    style={{ fontSize: "13px" }}
                  />
                  {shouldShowError(patientData?.emergencyContact) && (
                    <FormHelperText
                      style={{ color: "red" }}
                      id="mobile-error-text"
                    >
                      Invalid mobile number
                    </FormHelperText>
                  )}
                </div>
              </div>
              <hr className="my-10" />
              <h6 className="text-sm font-bold my-5 text-chathams-blue-500">
                Contact Details
              </h6>
              <div className="grid grid-cols-2 sm:grid-cols-5 gap-4">
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    House No/Street Name
                  </label>
                  <input
                    className={` profileinput border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1.5`}
                    onChange={handleChange}
                    name="address1"
                    value={patientData?.address1}
                    style={{ fontSize: "13px" }}
                  />
                </div>
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    Address
                  </label>
                  <input
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1.5 profileinput`}
                    onChange={handleChange}
                    name="address2"
                    value={patientData?.address2}
                    style={{ fontSize: "13px" }}
                  />
                </div>
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    Pincode
                  </label>
                  {/* <Input
                                     className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput`} 
                                     onChange={handleChange}
                                      name="pinCode" 
                                      value={patientData?.pinCode} /> */}
                  <input
                    maxLength="8"
                    type="tel"
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1.5 profileinput`}
                    onChange={handleChange}
                    name="pinCode"
                    value={patientData?.pinCode}
                    style={{ fontSize: "13px" }}
                  />
                </div>
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    State
                  </label>
                  <select
                    name="state"
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1 profileinput`}
                    onChange={handleChange}
                    value={patientData?.state ? patientData?.state : ""}
                    fullWidth
                    size="small"
                    style={{ fontSize: "13px" }}
                  >
                    {states &&
                      states?.map((value, index) => (
                        <option value={value.code} key={index}>
                          <p style={{ fontSize: "13px" }}>{value.name}</p>
                        </option>
                      ))}
                  </select>
                </div>
                <div className="relative flex flex-col">
                  <label className="text-xs font-normal text-shuttle-gray-500 mb-[6px]">
                    District
                  </label>
                  <input
                    className={`border-b-2 border-gray-300 before:!border-0 after:!border-0 profileinput pt-1.5 profileinput`}
                    onChange={handleChange}
                    name="city"
                    value={patientData?.city}
                    style={{ fontSize: "13px" }}
                  />
                </div>
              </div>
            </div>
            <div className="flex justify-center mt-5 h-12 sm:my-6">
              <Button
                variant="outlined"
                sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  updateDetails();
                }}
              >
                Save Details
              </Button>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default EditProfile;
