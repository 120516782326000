import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import { Button, TablePagination } from "@mui/material";
import dashboardservice from "../../store/services/dashboardservice";
import mydetailsservice from "../../store/services/mydetailsservice";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import videoicon from "../../assets/images/videoicon.svg";
import phoneiconnew from "../../assets/images/phoneiconnew.svg";
import homeicon from "../../assets/images/homeicon.svg";

//   console.log( createData('India', 'IN', 1324171354, 3287263))

const PreviousAppointments = ({ user, start, end, planId }) => {
  const Chip = ({ value }) => {
    return (
      <>
        {value === "Completed" ? (
          <p className="w-24 h-6 my-2 text-base font-semibold flex justify-center items-center bg-green-100 text-green-500 rounded-lg">
            {value}
          </p>
        ) : value === "Appointment Booked" ? (
          <p className="w-52 h-6 my-2 text-base font-semibold flex justify-center items-center bg-[#8eadd196] text-[#4d9cf8] rounded-lg">
            {value}
          </p>
        ) : value === "Yet to Start" || value === "Request for Update" ? (
          <p className="w-24 h-6 my-2 text-base font-semibold flex justify-center items-center bg-yellow-100 text-yellow-300 rounded-lg">
            {value}
          </p>
        ) : value === "ReScheduled" ? (
          <p className="w-28 h-6 my-2 text-base font-semibold flex justify-center items-center bg-orange-100 text-orange-300 rounded-lg">
            {value}
          </p>
        ) : (
          <p className="w-24 h-6 my-2 text-base font-semibold flex justify-center items-center bg-red-200 text-red-500 rounded-lg">
            {value}
          </p>
        )}
      </>
    );
  };

  const filePath = `${process.env.REACT_APP_IMAGE_URL}`;

  const Button1 = ({ value }) => {
    return (
      <>
        <Button variant="text">
          <span className="underline text-bluish text-base">
            Request For Change
          </span>
        </Button>
      </>
    );
  };

  // const View = ({ value }) => {
  //     return (
  //         <>
  //             <p className='text-bluish text-base underline cursor-pointer ' >View</p>

  //         </>
  //     )
  // }
  const viewPrescription = (e) => {
    let payload = {
      appointmentId: e.appointmentId,

      visitSummary: "Y",
      status: "1",

      stampRequired: "Y",

      documentRequired: "Y",

      vitalRequired: "Y",
    };
    mydetailsservice
      .getpresDocName(payload)
      .then((res) => {
        // if (res.data.succesObject[0]?.patientDrugPrescriptionList[0]?.docName) {
        //     let docName = res.data.succesObject[0].patientDrugPrescriptionList[0].docName
        //     window.open(filePath + docName, '_blank');
        // }
        // else {
        if (res?.data?.succesObject[0]) {
          let epres = res?.data?.succesObject[0];
          navigate(
            APP_ROUTES.EPRECRIPTION,
            { state: { epres: epres, dashboard: "appointments" } },
            { replace: true }
          );
        } else {
          toast.error(
            "Prescription is not available at the moment. Please try after sometime",
            {
              position: toast.POSITION.TOP_CENTER,
            }
          );
        }
        // }
      })
      .catch((error) => {
        console.error("Failed to fetch prescription data:", error);
      });
  };

  const downloadReport = (e) => {
    let payload = {
      appointmentId: e.id,

      visitSummary: "Y",
      status: "1",

      stampRequired: "Y",

      documentRequired: "Y",

      vitalRequired: "Y",
    };
    mydetailsservice
      .getpresDocName(payload)
      .then((res) => {
        if (res.data.succesObject[0]?.prescriptionDoc) {
          let docName = res.data.succesObject[0].prescriptionDoc;
          let payload1 = {
            fileName: docName,
          };
          mydetailsservice
            .downloadLabDoc(payload1)
            .then((res) => {
              let payload2 = {
                fileData: res?.data?.succesObject.fileData,
                fileName: "Test",
              };
              // Get the base64 string from your API response
              const base64String = payload2.fileData;

              // Convert the base64 string to a Blob object
              const byteCharacters = atob(base64String);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], {
                type: "application/octet-stream",
              });

              // Create a URL object from the Blob object
              const url = window.URL.createObjectURL(blob);

              // Create a link element and programmatically click it to initiate the file download
              const a = document.createElement("a");
              a.href = url;
              a.download = payload2.fileName;
              document.body.appendChild(a);
              a.click();
              a.remove();
            })
            .catch((error) => {
              console.error("Failed to fetch lab reports:", error);
            });
        } else {
          let epres = res.data.succesObject[0];
          navigate(
            APP_ROUTES.EPRECRIPTION,
            { state: epres },
            { replace: true }
          );
        }
      })
      .catch((error) => {
        console.error("Failed to fetch prescription data:", error);
      });
  };

  const navigate = useNavigate();

  const [patientData, setpatientData] = useState();
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
    ? userObj.parentCode
    : null;
  console.log(userObj);
  useEffect(() => {
    getUpcoming();
  }, [user, start, end, planId]);
  const getUpcoming = () => {
    let payload = {
      patientCode: user,
      userCode: userCode,
      planId: planId,
      appointmentType: 2,
      startDate: start,
      endDate: end,
    };
    dashboardservice.getappointment(payload).then((res) => {
      // if (start && end) {
      //     console.log('hii')
      //     const temp = res?.data?.succesObject?.filter(item => {
      //         const date = new Date(item?.whenAppointment)

      //         if (date >= start && date <= end) {
      //             console.log(item)
      //             return item
      //         }
      //     });
      //     console.log(temp)
      //     setpatientData(temp)
      // }
      // else
      setpatientData(res?.data?.succesObject);
    });
  };
  console.log(patientData);

  const columns = [
    {
      id: "name",
      label: "Specialist Name",
    },
    {
      id: "whenAppointment",
      label: "Date",
    },
    {
      id: "combinedTime",
      label: "Scheduled Time",
    },
    {
      id: "consultationsType",
      label: "Consultation Type",
    },
    {
      id: "id",
      label: "Ref No.",
    },
    {
      id: "status",
      label: "Status",
    },
    {
      id: "prp",
      label: "Prescription",
    },
  ];
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  console.log(patientData, "patientDatanew");
  return (
    <div>
      <p className="mb-4">
        Total No Of Appointment:{" "}
        <span className="font-semibold">{patientData?.length}</span>
      </p>

      <div className="grid grid-cols-1 sm:grid-cols-3 gap-6  ">
        {patientData?.map((value) => (
          <div
            className=" border-2 px-[15px] py-[15px] rounded-md"
            style={{ boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 6px" }}
          >
            <div className="flex justify-between mb-4">
              <p className="text-base">
                {value.status == 0 ? (
                  <Chip value="Expired" />
                ) : value.status == 1 ? (
                  <Chip value="Yet to Start" />
                ) : value.status === 2 ? (
                  <Chip value="Appointment Booked" />
                ) : value.status === 3 ? (
                  <Chip value="Completed" />
                ) : value.status === 4 ? (
                  <Chip value="Request for Update" />
                ) : value.status === 5 ? (
                  <Chip value="Cancelled" />
                ) : value.status === 6 ? (
                  <Chip value="ReScheduled" />
                ) : (
                  ""
                )}
              </p>

              {value.consultationsType == "V" ? (
                <img src={videoicon} className="float-right w-6" />
              ) : value.consultationsType == "H" ? (
                <img src={homeicon} className="float-right w-6" />
              ) : value.consultationsType === "P" ? (
                <img src={phoneiconnew} className="float-right w-6" />
              ) : (
                ""
              )}
            </div>
            <div>
              <div className="flex gap-4 mb-4">
                <i
                  class="fa fa-user-md text-[35px] text-[#2380ec] "
                  aria-hidden="true"
                ></i>
                <div>
                  <p className="font-semibold text-[18px]">
                    {value.userSalutation} {""} {value.name}
                  </p>
                  {/* <p className="font-normal text-base">Experience:&nbsp;<span className="font-semibold">8 Years</span></p> */}
                </div>
              </div>

              <div>
                <p className="text-[15px]  mb-[13px]">
                  Appointment Date :&nbsp;
                  <span className="font-bold"> {value.whenAppointment}</span>
                </p>
                <p className="text-[15px]  mb-[13px]">
                  Appointment Time :&nbsp;
                  <span className="font-bold">
                    {" "}
                    {value.fromTime} - {value.toTime}
                  </span>
                </p>
                <p className="text-[15px]   mb-[13px]">
                  Reference No.:&nbsp;
                  <span className="font-bold">{value.appointmentId} </span>
                </p>
                <p className="text-[15px]  mb-[13px]">
                  Consultation Type:&nbsp;
                  <span className="font-bold">
                    {value.consultationsType == "V"
                      ? "Video Consultation"
                      : value.consultationsType == "H"
                      ? "Home Visit"
                      : value.consultationsType === "P"
                      ? "Phone Consultation"
                      : value.consultationsType}
                  </span>
                </p>
                {value.status == 3 ? (
                  <p className="flex text-[15px] mb-2  ">
                    Prescription :&nbsp;&nbsp;
                    {value.status == 3 ? (
                      <i
                        class="fa fa-eye text-[#2380ec]  mr-[15px] cursor-pointer text-xl"
                        aria-hidden="true"
                        title="View Prescription"
                        onClick={() => {
                          viewPrescription(value);
                        }}
                      ></i>
                    ) : (
                      ""
                    )}
                  </p>
                ) : (
                  ""
                )}

                <p className="text-[15px]  mb-[13px]">
                  Source Channel :&nbsp;
                  <span className="font-bold">
                    {value.sourceChannel == "Backoffice-Medicare"
                      ? "Totalcare"
                      : value.sourceChannel == "ECLINIC"
                      ? "ECLINIC"
                      : value.sourceChannel == "B2C"
                      ? "Consumer App"
                      : value.sourceChannel == "Swasthya Mitra"
                      ? "Swasthya Mitra"
                      : ""}
                  </span>
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* <Paper
        sx={{ width: "100%" }}
        className="overflow-scroll sm:overflow-hidden mb-16 sm:mb-0"
      >
        <table className="w-full sm:mb-8 ">
          <thead className="sticky top-0 bg-lily-white-50 h-12">
            <tr>
              {columns.map((column) => (
                <th className="border-x whitespace-nowrap" key={column.id}>
                  {column.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {patientData &&
              patientData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <tr key={row.code}>
                      {columns.map((column) => {
                        const value = row[column.id];
                        return (
                          <td
                            className="border-x border-b h-16"
                            align="center"
                            key={column.id}
                          >
                            {column.id === "status" && value == 3 ? (
                              <button onClick={(e) => downloadReport(row)}>
                                <Chip value="Completed" />
                              </button>
                            ) : column.id === "status" && value == 1 ? (
                              <Chip value="Yet to Start" />
                            ) : column.id === "status" && value == 2 ? (
                              <Chip value="Appointment Booked" />
                            ) : column.id === "status" && value == 4 ? (
                              <Chip value="Request for Update" />
                            ) : column.id === "status" && value == 5 ? (
                              <Chip value="Cancelled" />
                            ) : column.id === "status" && value == 0 ? (
                              <Chip value="Expired" />
                            ) :
                              // column.id === "status" && value == 8 ? (
                              //   <Chip value="Cancel by Customer Care" />
                              // ) : column.id === "status" && value == 9 ? (
                              //   <Chip value="Cancel by EClinic" />
                              // ) : column.id === "status" && value == 10 ? (
                              //   <Chip value="Cancel by Customer Care" />
                              // ) : column.id === "status" && value == 11 ? (
                              //   <Chip value="No Status" />
                              // ) : column.id === "status" && value == 12 ? (
                              //   <Chip value="Cancel by Customer Care" />
                              // ) : column.id === "status" && value == 13 ? (
                              //   <Chip value="No Update" />
                              // ) : column.id === "status" && value == 0 ? (
                              //   <Chip value="Not Paid" />
                              // ) :
                              column.id === "name" ? (
                                <p className="text-base"> {row.userSalutation} {row.name} </p>
                              ) : column.id === "whenAppointment" ? (
                                <p className="text-base">
                                  {" "}
                                  {row.whenAppointment}{" "}
                                </p>
                              ) : column.id === "combinedTime" ? (
                                <p className="text-base">
                                  {" "}
                                  {row.fromTime} - {row.toTime}{" "}
                                </p>
                              ) : column.id === "consultationsType" ? (
                                <p className="text-sm">
                                  {value == "V"
                                    ? "Video Consultation"
                                    : value == "H"
                                      ? "Home Visit"
                                      : value === "P"
                                        ? "Phone Consultation"
                                        : value}
                                </p>
                              ) : // : column.id === 'prp' ? <View value="view" /> : <p className='text-sm'>{value}</p>
                                column.id === "prp" && row?.status == "3" ? (
                                  <Button variant="text" sx={{ color: "blue" }}>
                                    <span
                                      className="underline"
                                      onClick={() => {
                                        viewPrescription(row);
                                      }}
                                    >
                                      View
                                    </span>
                                  </Button>
                                ) : (
                                  <p className="text-sm"></p>
                                )}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
          </tbody>
        </table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={patientData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
      </Paper> */}
    </div>
  );
};

export default PreviousAppointments;
