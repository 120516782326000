import axios from "axios";

var axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_MEDICARE_CMSURL,
    headers: {
        "Content-type": "application/json",
    }
});

axiosInstance.interceptors.request.use(
    config => {
        //static token
        let s_token = '6f1797939dbb00599d8896d29f4e114b257e1e252d6856294951bdcdd7be292b7b49d6a266f2319d74e024cac8a99de3ab5256b82e25d4bb931a5c44a28f8d5ddcdffc51ee8abad8f62ac14a985397c4636b7575a9709d1cd6ae4bed4419494c2ea96ed3bc6223a5bac20e6a07342f7f17fb1cc7c87dccd85c0de0930896afe1';

        if (!config.headers["Authorization"]) {
            config.headers["Authorization"] = `Bearer ${s_token}`;
        }
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response
    },
    error => {
        Promise.reject(error);
    }
)

export default axiosInstance;
