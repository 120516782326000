import React, { useEffect, useState, useRef } from "react";
import logoimg from "../../webflowimages/curebay_logo.png"

import frameImage from '../../webflowimages/Frame-1171277696.png';
import frameImage500 from '../../webflowimages/Frame-1171277696-p-500.png';
import frameImage800 from '../../webflowimages/Frame-1171277696-p-800.png';
import frameImage1080 from '../../webflowimages/Frame-1171277696-p-1080.png';
import frameImage1600 from '../../webflowimages/Frame-1171277696-p-1600.png';

import infographicImage from '../../webflowimages/Infographic-1.png';
import infographicImage500 from '../../webflowimages/Infographic-1-p-500.png';
import infographicImage800 from '../../webflowimages/Infographic-1-p-800.png';
import infographicImage1080 from '../../webflowimages/Infographic-1-p-1080.png';
import infographicImage1600 from '../../webflowimages/Infographic-1-p-1600.png';
import infographicImage22 from '../../webflowimages/Infographic-2-2.svg';

import newImage from '../../webflowimages/Infographic-3.png';
import newImage500 from '../../webflowimages/Infographic-3-p-500.png';
import newImage800 from '../../webflowimages/Infographic-3-p-800.png';
import newImage1080 from '../../webflowimages/Infographic-3-p-1080.png';
import newImage1600 from '../../webflowimages/Infographic-3-p-1600.png';

import frameImage27 from '../../webflowimages/Frame-27.png';
import frameImage27500 from '../../webflowimages/Frame-27-p-500.png';
import frameImage27800 from '../../webflowimages/Frame-27-p-800.png';
import Footer from '../../components/webflow/footer';
import { APP_ROUTES } from "../../constants/approutes";
import { useDispatch, useSelector } from "react-redux";
import Carouselnew from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import InfographicMobile1 from '../../assets/images/InfographicMobile1.png';
import InfographicMobile2 from '../../assets/images/InfographicMobile2.png';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  IconButton as MuiIconButton,
  CardHeader,
  CardMedia,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import Aos from "aos";
import 'aos/dist/aos.css'
import { getAllSpecialities } from "../../store/actions/doctors";
import axios from "axios";

import { Carousel } from 'primereact/carousel';
// import 'primereact/resources/primereact.css';
// import 'primereact/resources/themes/saga-blue/theme.css';
// import 'primeicons/primeicons.css';
import { getDoctorslist } from "../../store/actions/doctors";
import {
  fetchDoctorsData,
  getDoctorsSliderDetails,
} from "../../store/actions/doctors";
import { useNavigate } from "react-router-dom";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";


const Curebayexperiencenew = () => {

  const [doctorSpecialities, setDoctorSpecialities] = useState([]);
  const [currentSlide1, setCurrentSlide1] = useState(0);
  const [locationimg, setLocationimg] = useState("");
  const [screen, setscreen] = useState(window.innerWidth);
  const navigation = useNavigate();
  const curebayExpContent = useSelector(
    (state) => state.HomePageReducer.curebayExpContent
  );
  var nloop = null;
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };


  console.log(doctorSpecialities, "doctorSpecialitiesnew")

  // useEffect(() => {
  //   let requestBody = {};
  //   axios

  //     .post(
  //       `${process.env.REACT_APP_MEDICARE_BASEURL}location/getAll`,

  //       requestBody
  //     )

  //     .then((res) => {
  //       setLocationimg(res.data.succesObject);
  //       // setLocationimg(res?.data?.succesObject);
  //     })

  //     .catch((err) => {
  //       console.log(err, "Something went wrong1");
  //     });
  // }, []);



  useEffect(() => {
    Aos.init({ duration: 3000 })
  }, [])

  useEffect(() => {
    async function fetchData() {
      const res = await getAllSpecialities();
      if (res?.status === 200 && res?.data?.succesObject) {
        setDoctorSpecialities(res.data.succesObject);
        // nloop = 9 * Math.ceil(res.data.succesObject?.length / 9);
      }
    }
    fetchData();
  }, []);



  const [currentSlide, setCurrentSlide] = useState(0);


  const carouselRef = useRef(null);

  const handleLeftArrowClick = () => {
    if (carouselRef.current && carouselRef.current.prev) {
      carouselRef.current.prev();
    }
  };

  const handleRightArrowClick = () => {
    if (carouselRef.current && carouselRef.current.next) {
      carouselRef.current.next();
    }
  };

  const itemTemplate = (item) => {
    return (
      <div className="slide-item">
        <img src={item.images} alt={item.speciality} />
        <div className="speciality-title">{item.speciality}</div>
      </div>
    );
  };

  const slides1 = doctorSpecialities?.map((item) => {
    return {
      title: item?.title,
      description: item?.description,
      imgUrl: item?.image_url,
    };
  });

  const handleLeftArrowClick1 = () => {
    if (currentSlide1 === 0) {
      setCurrentSlide1(slides1.length - 1);
    } else {
      setCurrentSlide1(currentSlide1 - 1);
    }
  };

  const handleRightArrowClick1 = () => {
    if (currentSlide1 === slides1.length - 1) {
      setCurrentSlide1(0);
    } else {
      setCurrentSlide1(currentSlide1 + 1);
    }
  };

  const productTemplate = (e) => {
    return (
      <div role="listitem" className="w-dyn-item mr-4">
        <a
          data-w-id="d146676f-9cdc-23ae-2609-132ecbd59634"
          href=""
          onClick={() =>
            navigation(APP_ROUTES.DOCTORSPECIALITY, {
              state: { id: "docsp", name: e?.speciality },
            })
          }
          className="card card-link-icon-top w-inline-block"
        >
          <div className="card-picture-wrapper specialities-picture">
            <img
              src={e?.images}


            />
          </div>
          <div className="flex flex-col">
            <div className="">
              <span
                className="card-title-dark display-4"
              >
                {e?.speciality}
              </span>
            </div>

            <div
              className="card-link color-neutral-600"
            >
              <div className="link-text">Learn more</div>
              <div
                className="line-rounded-icon card-arrow-right"

              >
                
              </div>
            </div>
          </div>
        </a>
      </div>
    );
  };

  const responsiveOptions = [
    {
      breakpoint: '1199px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '991px',
      numVisible: 1,
      numScroll: 1
    },
    {
      breakpoint: '767px',
      numVisible: 1,
      numScroll: 1
    }
  ];

  return (
    <>
      <div>

        <div className="page-wrapper">
          <div className="w-layout-blockcontainer container-default position-relative w-container">
            <div className="section hero home-v1">
              <div className="w-layout-grid grid-2-columns home-hero-grid-2-col v1">
                <div id="w-node-f6bd597c-10f1-dc76-38f5-7ad2660c1800-4d0e8e42" data-w-id="f6bd597c-10f1-dc76-38f5-7ad2660c1800" data-aos="fade-right"
                  // style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}}

                  className="inner-container minw-480px _100---tablet">
                  <h1 className="display-1 mg-bottom-8px"> {curebayExpContent?.data[0]?.attributes?.firstcomp?.title}</h1>
                  <div className="inner-container _518px _100---tablet">
                    <p className="mg-bottom-32px mg-bottom-20px-tablet">  {curebayExpContent?.data[0]?.attributes?.firstcomp?.description} </p>
                  </div>
                  <div className="buttons-row mg-bottom-52px mg-bottom-32px-tablet">
                    <a href={APP_ROUTES.NEWAPPOINMENT} className="btn-primary button-row w-button"><span className="doctr-custom-icon btn-icon-left"></span>Book a Health Assessment</a>
                    <a href={APP_ROUTES.CAREPLANNEW} className="btn-secondary w-button"><span className="doctr-custom-icon btn-icon-left"></span>Care Plans</a>
                  </div>
                  <div>
                    <div className="mg-bottom-24px mg-bottom-16px-tablet" />
                  </div>
                </div>
                <div data-w-id="f6bd597c-10f1-dc76-38f5-7ad2660c181c" data-aos="fade-left"
                  // style={{WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)'}}

                  className="home-hero-picture v1">
                  <img
                    src={curebayExpContent?.data[0]?.attributes?.firstcomp?.image_url}
                    loading="eager"
                    sizes="(max-width: 991px) 100vw, (max-width: 1919px) 49vw, 50vw"
                    // srcSet={`${frameImage500} 500w, ${frameImage800} 800w, ${frameImage1080} 1080w, ${frameImage1600} 1600w, ${frameImage} 1653w`}
                    alt=""
                    className="fit-cover _w-h-100"
                  />
                </div>
              </div>
            </div>
          </div>
          <div id="insurance" className="section overflow-hidden">
            <div className="container-default w-container" data-aos="fade-up">
              <div className="mg-bottom-48px" data-aos="fade-up">
                <div data-w-id="f8c5f920-63bf-4acc-0c28-b2479b72dacb" style={{ WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="heading-flex" data-aos="fade-up">
                  <div id="w-node-_105bd147-f3bb-4dda-ccbe-6c896c4883bd-4d0e8e42" className="inner-container _600px _100---tablet" data-aos="slide-up">
                    <h2 className="display-2 mg-bottom-0"> {curebayExpContent?.data[0]?.attributes?.SecondComp?.title}</h2>
                  </div>
                  <div id="w-node-_105bd147-f3bb-4dda-ccbe-6c896c4883c0-4d0e8e42" className="button-row-wrap" data-aos="fade-up">
                    <a href={APP_ROUTES.CAREPLANNEW} className="btn-secondary w-button">Care Plans</a>
                  </div>
                </div>
              </div>
              <div className="text-center" data-aos="fade-up">
                {/* <img
      src={ curebayExpContent?.data[0]?.attributes?.SecondComp?.image_url}
      loading="lazy"
      sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 1439px) 96vw, 1300px"
      srcSet={`${infographicImage500} 500w, ${infographicImage800} 800w, ${infographicImage1080} 1080w, ${infographicImage1600} 1600w, ${infographicImage} 1920w`}
      alt=""
    /> */}

                <Grid item xs={12} md={12} className="hidden sm:block">
                  <img
                    className="xs-w-full" data-aos="fade-up"
                    src={
                      curebayExpContent?.data[0]?.attributes?.SecondComp?.image_url
                    }
                    alt="img"
                    style={{
                      height: "517px",
                      // width: "986px",
                      position: "flxed",
                      margin: "auto",
                    }}
                  />
                </Grid>
                <img src={curebayExpContent?.data[0]?.attributes?.SecondComp?.img1mob1_url} className="block sm:hidden" />
              </div>
            </div>
          </div>
          <section className="section" data-aos="fade-right" >
            <div className="w-layout-blockcontainer container-default w-container" />
          </section>
          <div id="insurance" className="section overflow-hidden">
            <div className="container-default w-container">
              <div className="mg-bottom-48px" data-aos="fade-up">
                <div data-w-id="ef8628e9-d60b-5055-f16f-db03a3f6582f" style={{ WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="heading-flex">
                  <div id="w-node-ef8628e9-d60b-5055-f16f-db03a3f65830-4d0e8e42" className="inner-container _600px _100---tablet">
                    <h2 className="display-2 mg-bottom-0">Covering all three levels of healthcare</h2>
                  </div>
                  <div id="w-node-ef8628e9-d60b-5055-f16f-db03a3f65833-4d0e8e42" className="button-row-wrap">
                    <a href={APP_ROUTES.CAREPLANNEW} className="btn-secondary w-button">Care Plans</a>
                  </div>
                </div>
              </div>
              <div data-aos="slide-up">
                {/* <img src={infographicImage22} loading="lazy" alt="" /> */}
                <img
                  src={curebayExpContent?.data[0]?.attributes?.ThirdComp?.image_url}
                  className="sm:block hidden"
                />

                <img src={curebayExpContent?.data[0]?.attributes?.SecondComp?.img1mob2_url} className="block sm:hidden" />
              </div>
            </div>
          </div>
          {/* <section className="section">
            <div className="w-layout-blockcontainer container-default w-container">
              <div className="div-block-3" data-aos="fade-up">
                <img
                  src={frameImage}
                  loading="lazy"
                  sizes="(max-width: 479px) 92vw, (max-width: 767px) 95vw, (max-width: 1439px) 96vw, 972px"
                  srcSet={`${frameImage27500} 500w, ${frameImage27800} 800w, ${frameImage27} 972w`}
                  alt=""
                />
              </div>
            </div>
          </section> */}
          {/*  */}
          {/* <div className="section specialities-section">

            <div className="container-default w-container grid-cols-2 "> */}
          {/* <div className="grid-2-columns grid-specialities"> */}
          {/* <div className="section specialities-section">

            <div className="container-default w-container  ">

              <div className="grid-2-columns grid-specialities"> */}
          {/* 
          <div className="grid-2-columns grid-specialities"> */}
          {/* <div className="flex"> */}
          <div className="w-full sm:flex block bg-[#004171] sm:pt-[232px] pt-[63px] sm:pb-[200px] pb-[20px]  rounded-[54px]">
            <div
              id="w-node-d146676f-9cdc-23ae-2609-132ecbd5961e-4d0e8e42"
              data-w-id="d146676f-9cdc-23ae-2609-132ecbd5961e"

              className="specialities-slider-col-left sm:w-3/12 w-full"
            >
              <div className="specialities-left-content sm:pl-[70px] pl-[15px] pr-[15px] sm:pr-[0px]">
                <div className="subtitle">Specialities</div>
                <div className="inner-container _470px _100---tablet">
                  <h2 className="display-2 color-neutral-100 mg-bottom-4px">
                    Our wide range of specialities
                  </h2>
                </div>
                <div className="inner-container _412px _100---tablet text-center" >
                  <a href={APP_ROUTES.DOCTORNEW} className="btn-secondary white w-button">
                    <span className="doctr-custom-icon btn-icon-left"></span>Browse all
                    specialities
                  </a>
                </div>
              </div>
              <div className="half-bg-left specialities-left-col-bg" />
            </div>
            <div className="sm:w-3/4 w-full mt-[43px] sm:mt-[0px]">
              <div className=" gap-4 pr-[10px] sm:pr-[0px]">
                {doctorSpecialities?.length > 0 && (
                  <Carousel value={doctorSpecialities} numVisible={3} numScroll={1} autoplay className="custom-carousel" circular
                    itemTemplate={productTemplate} responsiveOptions={responsiveOptions} autoplayInterval={3000} showIndicators={false} >

                    {doctorSpecialities?.map((e) => (
                      <div role="listitem" className="w-dyn-item">
                        <a
                          data-w-id="d146676f-9cdc-23ae-2609-132ecbd59634"
                          href=""
                          onClick={() =>
                            navigation(APP_ROUTES.DOCTORSPECIALITY, {
                              state: { id: "docsp", name: e?.speciality },
                            })
                          }
                          className="card card-link-icon-top w-inline-block"
                        >
                          <div className="card-picture-wrapper specialities-picture">
                            <img
                              src={e?.images}


                            />
                          </div>
                          <div>
                            <h3
                              className="card-title-dark display-4"
                            >
                              {e?.speciality}
                            </h3>
                            <div className="inner-container _350px _100-tablet">
                              <p className="color-neutral-600 mg-bottom-24px w-dyn-bind-empty" />
                            </div>
                            <div
                              className="card-link color-neutral-600"
                            >
                              <div className="link-text">Learn more</div>
                              <div
                                className="line-rounded-icon card-arrow-right"

                              >
                                
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </Carousel>
                )}
              </div>
            </div>
          </div>




          {/* </div>
            </div>*/}
          {/* </div> */}
          {/* </div>
          </div> */}


          <div id="insurance" className="section overflow-hidden" data-aos="fade-up">
            <div className="container-default w-container" data-aos="fade-up">
              <div className="mg-bottom-48px" data-aos="fade-up">
                <div data-w-id="a3fedbb5-796a-2047-9348-247e3360b47e" style={{ WebkitTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', MozTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', msTransform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)', transform: 'translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)' }} className="heading-flex">
                  <div id="w-node-a3fedbb5-796a-2047-9348-247e3360b47f-4d0e8e42" className="inner-container _642px _100---tablet">
                    <h2 className="display-2 mg-bottom-0"> {curebayExpContent?.data[0]?.attributes?.seventhComp?.title}</h2>
                  </div>
                  <div id="w-node-a3fedbb5-796a-2047-9348-247e3360b482-4d0e8e42" className="button-row-wrap">
                    <a href={APP_ROUTES.CAREPLANNEW} className="btn-secondary w-button">Care Plans</a>
                  </div>
                </div>
              </div>
              <div className=" sm:block hidden">
                <img
                  src={curebayExpContent?.data[0]?.attributes?.seventhComp?.image[8].image_url}
                  className="w-full"
                  alt=""
                />


              </div>
              <div className=" sm:hidden block gap-4">
                <div className="mb-[25px]">
                  <img
                    src={curebayExpContent?.data[0]?.attributes?.seventhComp?.image[5].image_url}
                    className="w-full"
                    alt=""
                  />
                </div>
                <div className="mb-[25px]">
                  <img
                    src={curebayExpContent?.data[0]?.attributes?.seventhComp?.image[2].image_url}
                    className="w-full"
                    alt=""
                  />
                </div>
                <div className="mb-[25px]">
                  <img
                    src={curebayExpContent?.data[0]?.attributes?.seventhComp?.image[1].image_url}
                    className="w-full"
                    alt=""
                  />
                </div>
                <div className="mb-[25px]">
                  <img
                    src={curebayExpContent?.data[0]?.attributes?.seventhComp?.image[0].image_url}
                    className="w-full"
                    alt=""
                  />
                </div>
                <div className="mb-[25px]">
                  <img
                    src={curebayExpContent?.data[0]?.attributes?.seventhComp?.image[3].image_url}
                    className="w-full"
                    alt=""
                  />
                </div>



              </div>
            </div>
          </div >
          <div className="border-radius-40px border-radius-16px-mbl overflow-hidden" />
          {screen > 500 ? (
            <Footer />
          ) : (
            <>
              <FooterMobileVersion />
              <MobileFooter />
            </>
          )}
        </div >
      </div >
    </>
  );
};

export default Curebayexperiencenew;
