import React from 'react';
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from '../../constants/approutes';
import notfoundpage from "../../assets/images/notfoundpage.svg";

const NotFound = () => {
    const navigate = useNavigate();
  const redirectTo = (event) => {
  
    navigate(APP_ROUTES.DASHBOARD);
  };
  return (
    <div className="h-screen w-3/4 md:w-1/2 mx-auto flex gap-2 flex-col items-center justify-center">
      <img src={notfoundpage} alt="" className="w-100 h-100" />
      <p className='text-[#004171] sm:text-2xl text-lg font-bold'>Page Not Found</p>
   
      <button
        onClick={redirectTo}
        className={`sm:my-6 my-1 py-2 px-4 bg-brand-primary text-white w-3/4 md:w-1/2 duration-150 disabled:cursor-default rounded transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none`}>
     Back to home
      </button>
    </div>
  );
};

export default NotFound;
