import React, { useEffect, useState } from "react";
import MyprofileSidemenu from "../homepage/myprofile-sidemenu";
import mydetailsservice from "../../store/services/mydetailsservice";
import { Box, MenuItem, Select, Tab, TablePagination } from "@mui/material";

// import { getReviewDetails } from '../../store/actions/reviews';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { fetchCarePlan, fetchUserList } from "../../store/actions/dashboard";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function Mylabreports() {
  const navigate = useNavigate();
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const [userCode, setuserCode] = useState(
    userObj?.code
      ? userObj.code
      : userObj?.parentCode
      ? userObj.parentCode
      : userObj?.userCode
      ? userObj.userCode
      : null
  );
  const [labData, setlabData] = useState();
  const filePath = `${process.env.REACT_APP_IMAGE_URL}`;

  const [age, setAge] = React.useState(0);
  const [age1, setAge1] = React.useState(1);
  const [care, setCare] = useState();
  const [users, setUsers] = React.useState();
  const [allowed, setAllowed] = useState(true);
  const [currentPageNu, setcurrentPageNu] = useState(1);
  const [pagecLimit, setPageLimit] = useState(1);
  const itemsPerPage = 4; // Number of items to display per

  const [currentPagenewlab, setCurrentPagenewlab] = useState(1);

  const handleChange = (event) => {
    setAge(event.target.value);
    getOverAll(event.target.value);
  };

  const handleChange2 = (event) => {
    setAge1(event.target.value);
  };

  const handlePageChangenewlab = (pageNumber) => {
    setCurrentPagenewlab(pageNumber);
  };

  useEffect(() => {
    async function fetchUserDetails() {
      const user = await fetchUserList({
        code: userObj?.userCode
          ? userObj.userCode
          : userObj?.parentCode
          ? userObj.parentCode
          : null,
        email: userObj?.email,
        mobile: userObj?.mobile,
      });

      if (user.status === 200 && user?.data) {
        const temp = user?.data?.succesObject?.filter(
          (p) => Object.keys(p).length !== 0
        );
        setUsers(temp);
        let first = temp;
        getFirstPatient(first);
      }
    }
    fetchUserDetails();
    setAge(userCode);
  }, []);

  useEffect(() => {
    async function fetchCares() {
      const res = await fetchCarePlan({
        userCode: userObj?.userCode
          ? userObj.userCode
          : userObj?.parentCode
          ? userObj.parentCode
          : null,
        patientcode: age,
      });
      if (res?.status === 200 && res?.data) {
        setCare(res?.data);
        setAge1(res?.data?.succesObject[0]?.id);
      }
    }
    fetchCares();
  }, [age]);

  useEffect(() => {
    const temp = users?.filter((p) => p.code == age);
    if (!userObj?.parentCode) {
      if (temp && temp[0]?.consentMedReport === 1) {
        setAllowed(true);
      } else setAllowed(false);
    }
  }, [age]);

  const getFirstPatient = (e) => {
    if (userObj?.userCode) {
      setAge(e[0].code);
      getOverAll(e[0].code);
    } else {
      setAge(userCode);
      getOverAll(userCode);
    }
  };
  const breadcrumbItems = [
    { label: "Home" },
    { label: "My Details", onClick: () => navigate(APP_ROUTES.MYDETAILS) },
    { label: "Health Records" },
    { label: "Lab Reports", url: "/details" },
  ];

  // const userData = useSelector((state) => state.AuthReducer.userData);
  useEffect(() => {
    getOverAll("");
  }, [currentPageNu, age1, age]);
  const getOverAll = (e) => {
    let payload;
    // let data = JSON.parse(userData);
    // console.log(data)
    if (e == "") {
      payload = {
        // "patientId": data?.[1]?.patientCode ? data[1].patientCode : null,
        patientId: age,
        docmentRequired: "Y",
        planId: age1,
        // pageLimit: 10,
        // pageNo: currentPageNu,

        //         "userCode": userCode,
        //  "patientCode": userObj.code,
      };
    } else {
      payload = {
        // "patientId": data?.[1]?.patientCode ? data[1].patientCode : null,
        patientId: e,
        docmentRequired: "Y",
        planId: age1,
        // pageLimit: 10,
        // pageNo: currentPageNu,

        //         "userCode": userCode,
        //  "patientCode": userObj.code,
      };
    }
    // if (data?.[1]?.patientCode) {
    mydetailsservice
      .getmylablist(payload)
      .then((res) => {
        setlabData(res?.data?.succesObject);
        setPageLimit(Math.ceil(res?.data?.totalRecords / 5));
      })
      .catch((error) => {
        console.error("Failed to fetch lab reports:", error);
      });
  };
  const downloadReport = (e) => {
    let payload = {
      fileName: e.labReportDoc,
    };
    mydetailsservice
      .downloadLabDoc(payload)
      .then((res) => {
        let payload2 = {
          fileData: res?.data?.succesObject.fileData,
          fileName: "Test",
        };
        // Get the base64 string from your API response
        const base64String = payload2.fileData;

        // Convert the base64 string to a Blob object

        // Convert the base64 string to a Blob object
        const base64PdfContent = base64String;
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${base64PdfContent}`;
        link.download = "document.pdf"; // Specify the desired filename for download
        link.click();
      })
      .catch((error) => {
        console.error("Failed to fetch lab reports:", error);
      });
  };
  const headerStyle = {
    backgroundColor: " rgb(230 244 253)",
    borderBottom: "1px solid #e6f4fd", // Border
  };
  console.log(labData, "labData");
  return (
    <div className="p-8 ">
      {/* <div className="flex items-center mb-5">
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">Home</p>
                    <ArrowForwardIosIcon sx={{ fontSize: 15 }} className="text-chathams-blue-500 mx-2" />
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">My Profile</p>
                    <ArrowForwardIosIcon sx={{ fontSize: 15 }} className="text-chathams-blue-500 mx-2" />
                    <p className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">My Details</p>
                </div> */}
      <div className="flex items-center mb-5 mt-[100px]">
        {breadcrumbItems.map((item, index) => (
          <React.Fragment key={index}>
            <span className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">
              {item.onClick ? (
                <a onClick={item.onClick}>{item.label}</a>
              ) : (
                <a>{item.label}</a>
              )}
            </span>
            {index !== breadcrumbItems.length - 1 && (
              <ArrowForwardIosIcon
                sx={{ fontSize: 15 }}
                className="text-chathams-blue-500 mx-2"
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <div className="flex flex-col sm:flex-row justify-between ">
        <h2 className="text-xl font-bold my-5 w-full text-chathams-blue-500">
          {/* My Profile - My Lab Reports */}
        </h2>
        <div className=" pt-[66px] sm:pt-0 sm:h-20 mb-2 flex sm:items-center justify-start sm:mr-[33rem] w-full rounded-md flex-col sm:flex-row">
          <div className="flex flex-col  sm:mr-4">
            <span className="text-chathams-blue font-semibold text-base">
              Member Profile
            </span>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "2 rem",
                width: "13rem",
                width: "100%",
                boxShadow: "0 2px 28px rgba(0,0,0,.1)",
              }}
            >
              {users &&
                users?.map((value, index) => (
                  <MenuItem value={value.code} key={index}>
                    <span className="text-base font-semibold">
                      {value.name}
                    </span>
                  </MenuItem>
                ))}
            </Select>
          </div>
          <div className="flex flex-col mt-2 sm:mt-0">
            <span className="text-chathams-blue font-semibold text-base">
              Careplans
            </span>
            <Select
              value={age1}
              onChange={handleChange2}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              sx={{
                height: "2 rem",
                width: "13rem",
                width: "100%",
                boxShadow: "0 2px 28px rgba(0,0,0,.1)",
              }}
            >
              {care &&
                care?.succesObject?.map((value, index) => (
                  <MenuItem value={value.id} key={index}>
                    <span className="text-base font-semibold">
                      {value.name}
                    </span>
                  </MenuItem>
                ))}
            </Select>
          </div>
        </div>
      </div>
      <div className="sm:flex sm:scroll-px-2">
        <div className="sm:w-1/4 mr-4 border p-4 rounded-md hidden sm:block">
          <MyprofileSidemenu value={2} />
        </div>
        {allowed ? (
          <div className="sm:w-3/4 ml-4 border sm:p-8 p-4 rounded-md items-center flex flex-col">
            <p className="w-full flex items-start mt-0 sm:text-lg font-bold my-5 text-chathams-blue-500">
              See Your Lab Reports
            </p>

            <div className="flex flex-col sm:gap-3  sm:mt-2  w-full  ">
              <DataTable
                value={labData}
                paginator
                rows={50}
                rowsPerPageOptions={[5, 10, 25, 50]}
                tableStyle={{ padding: "10px" }}
                rowClassName={(rowData) =>
                  rowData.serviceStatus === 2 ? "rowcolor" : ""
                }
              >
                <Column
                  field="labTestCode"
                  header=" Test Name"
                  sortable
                  headerStyle={headerStyle}
                  style={{ fontFamily: "poppins", fontSize: "16px" }}
                />
                <Column
                  field="name"
                  header="Lab Name"
                  sortable
                  headerStyle={headerStyle}
                  style={{ fontFamily: "poppins", fontSize: "16px" }}
                />
                <Column
                  field="createdDate"
                  header="Date"
                  sortable
                  headerStyle={headerStyle}
                  style={{ fontFamily: "poppins", fontSize: "16px" }}
                />
                <Column
                  field="orderId"
                  header=" Order ID"
                  sortable
                  headerStyle={headerStyle}
                  style={{ fontFamily: "poppins", fontSize: "16px" }}
                />

                <Column
                  field=""
                  header="View Lab Report"
                  sortable
                  headerStyle={headerStyle}
                  body={(rowData, column) => (
                    <>
                      <a
                        href={filePath + "" + rowData.labReportDoc}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Button
                          variant="text"
                          sx={{
                            marginX: "0.5rem",
                            marginY: "1rem",
                            padding: "0",
                            color: "#1976d2",
                            width: "auto",
                          }}
                        >
                          <p className=" text-sm font-semibold capitalize">
                            <i
                              class="fa fa-eye text-xl"
                              aria-hidden="true"
                              title="View Labreport"
                            ></i>
                          </p>
                        </Button>
                      </a>
                    </>
                  )}
                />

                <Column
                  field=""
                  header="Download Lab Report"
                  sortable
                  headerStyle={headerStyle}
                  body={(rowData, column) => (
                    <>
                      <Button
                        variant="text"
                        sx={{
                          marginX: "0.5rem",
                          marginY: "1rem",
                          padding: "0",
                          color: "#1976d2",
                          width: "auto",
                        }}
                        onClick={(e) => downloadReport(rowData)}
                      >
                        <p className=" text-sm font-semibold capitalize">
                          <i
                            class="fa fa-download text-xl"
                            aria-hidden="true"
                            title="Download Labreport"
                          ></i>
                        </p>
                      </Button>
                    </>
                  )}
                />

                <Column
                  field="sourceChannel"
                  header=" Source Channel"
                  sortable
                  headerStyle={headerStyle}
                  style={{ fontFamily: "poppins", fontSize: "16px" }}
                  body={(rowData) =>
                    rowData.sourceChannel === "Backoffice-Medicare"
                      ? "Totalcare"
                      : rowData.sourceChannel === "ECLINIC"
                      ? "ECLINIC"
                      : rowData.sourceChannel === "B2C"
                      ? "Consumer App"
                      : rowData.sourceChannel == "Swasthya Mitra"
                      ? "Swasthya Mitra"
                      : ""
                  }
                />
              </DataTable>
              {/* {labData &&
                labData
                  .slice(
                    (currentPagenewlab - 1) * itemsPerPage,
                    currentPagenewlab * itemsPerPage
                  )
                  .map((lab, index) => (
                    <div
                      elevation={1}
                      sx={{ boxShadow: " 0px 10px 13px rgba(0, 0, 0, 0.04)" }}
                      className="overflow-x-scroll sm:overflow-x-clip"
                    >
                      <div className="flex flex-col">
                        <div className="flex flex-col sm:flex-row  border-b-2 pb-5 sm:mx-4 justify-start pt-4">
                          <div className="flex flex-col ">
                            <p className="text-xs font-normal text-shuttle-gray-500 ">
                              Test Name
                            </p>
                            <p className="text-sm">{lab.labTestCode}</p>
                          </div>
                          <div className="flex flex-col sm:pl-16">
                            <p className="text-xs font-normal text-shuttle-gray-500 ">
                              lab Name
                            </p>
                            <p className="text-sm">{lab.name}</p>
                          </div>
                          <div className="flex flex-col sm:pl-16">
                            <p className="text-xs font-normal text-shuttle-gray-500 ">
                              Date
                            </p>
                            <p className="text-sm">{lab.createdDate}</p>
                          </div>
                          <div className="flex flex-col sm:px-16">
                            <p className="text-xs font-normal text-shuttle-gray-500 ">
                              Order ID
                            </p>
                            <p className="text-sm">{lab.orderId}</p>
                          </div>
                        </div>
                        <div sx={{ diplay: "flex" }} className="sm:ml-3">
                          <a
                            href={filePath + "" + lab.labReportDoc}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              variant="text"
                              sx={{
                                marginX: "0.5rem",
                                marginY: "1rem",
                                padding: "0",
                                color: "#1976d2",
                                width: "auto",
                              }}
                            >
                              <p className="underline text-sm font-semibold capitalize">
                                view Lab Report
                              </p>
                            </Button>
                          </a>
                          <Button
                            className="w-auto"
                            variant="text"
                            sx={{
                              width: "20%",
                              marginX: "0.5rem",
                              marginY: "1rem",
                              padding: "0",
                              color: "#1976d2",
                            }}
                          >
                            <p
                              className="underline text-sm font-semibold capitalize"
                              onClick={(e) => downloadReport(lab)}
                            >
                              Download
                            </p>
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))} */}
              {/* <div className="flex align-center justify-between px-5 sm:px-10 mt-3 ">
                <button
                  type="button"
                  className="disabled:opacity-50 border rounded-lg sm:w-20 w-10 h-12 pl-0 sm:pl-5"
                  disabled={currentPageNu == 1}
                  onClick={() => {
                    setcurrentPageNu(currentPageNu - 1);
                  }}
                >
                  <ArrowBackIosIcon className="w-8" />
                </button>
                <div>
                  <p>
                    <b className="text-xl font-medium mt-2 text-black-700">
                      {currentPageNu}
                    </b>
                  </p>
                </div>
                <button
                  type="button"
                  className="disabled:opacity-50 border rounded-lg sm:w-20 w-10 h-12 pl-0 sm:pl-5"
                  disabled={currentPageNu == pagecLimit}
                  onClick={() => {
                    setcurrentPageNu(currentPageNu + 1);
                  }}
                >
                  <ArrowForwardIosIcon className="w-8" />
                </button>
              </div> */}
              {!labData && <span> No Lab Report Found</span>}
            </div>
          </div>
        ) : (
          <span className="pt-[72px]">
            Due to parental restrictions, we regret to inform you that you are
            not currently authorized to view the lab reports.
          </span>
        )}
      </div>
      <div className="flex justify-center ">
        {labData?.length > 0 && allowed ? (
          <>
            {" "}
            {/* <div className="paginationcontainer ">
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                pageCount={Math.ceil(labData.length / itemsPerPage)}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={(selected) => {
                  handlePageChangenewlab(selected.selected + 1);
                }}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div> */}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Mylabreports;
