import services from './index.jsx';
import httpcommon from './httpcommon.jsx';
class Reviews {
    
getReviewsContent(){
    return services.get("review-pages?populate=deep")
};

getReviewScreenData(){
    return services.get("/reviews")
}
getReviewDashboad(){
    return httpcommon.post("/review/getReviewDetails")
}


}
export default new Reviews();