
export const HOMEPAGE = {
    SUCCESS_HOMEPAGEIMAGE: "SUCCESS_HOMEPAGEIMAGE",
    FAILED_HOMEPAGEIMAGE: "FAILED_HOMEPAGEIMAGE",
    SUCCESS_HOMEPAGECONTENT: "SUCCESS_HOMEPAGECONTENT",
    ERROR_HOMEPAGECONTENT: "ERROR_HOMEPAGECONTENT",
    SUCCESS_HOMEPAGEINTEGRATIVE: "SUCCESS_HOMEPAGEINTEGRATIVE",
    ERROR_HOMEPAGEINTEGRATIVE: "ERROR_HOMEPAGEINTEGRATIVE",
    SUCCESS_ESDOCTORLIST: "SUCCESS_ESDOCTORLIST",
    FAILED_ESDOCTORLIST: "FAILED_ESDOCTORLIST",
    SUCCESS_LABSLIST: "SUCCESS_LABSLIST",
    FAILED_LABSLIST: "FAILED_LABSLIST",
    FAILED_ABOUTUSCONTENT: "FAILED_ABOUTUSCONTENT",
    SUCCESS_ABOUTUSCONTENT: "SUCCESS_ABOUTUSCONTENT",
    SUCCESS_CAREPLANCONTENT: "SUCCESS_CAREPLANCONTENT",
    FAILED_CAREPLANCONTENT: "FAILED_CAREPLANCONTENT",
    SUCCESS_DOCTORPAGECONTENT: "SUCCESS_DOCTORPAGECONTENT",
    ERROR_DOCTORPAGECONTENT: "ERROR_DOCTORPAGECONTENT",
    SUCCESS_CUREBAYEXPCONTENT: "SUCCESS_CUREBAYEXPCONTENT",
    ERROR_CUREBAYEXPCONTENT: "ERROR_CUREBAYEXPCONTENT",
    STATISTICSCOUNT: "STATISTICSCOUNT",
    ERROR_STATISTICSCOUNT: "ERROR_STATISTICSCOUNT"

}