import { APP_ROUTES } from "../../constants/approutes";
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DashboardG from "../../images/dashboardicon.svg";
import Dashboard from "../../images/dashboardiconactive.svg";
import SubServiceG from "../../images/subscriptionicon.svg";
import SubService from "../../images/subscriptioniconactive.svg";
import AppointmentG from "../../images/appointmenticon.svg";
import Appointment from "../../images/appointmenticonactive.svg";
import healthG from "../../images/healthrecordicon.svg";
import HealthRec from "../../images/healthrecordiconactive.svg";
import Schedule from "../../images/seheduleiconactive.svg";
import ScheduleG from "../../images/seheduleicon.svg";

function DashboardSidemenu(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode ? userObj.userCode : null;

  return (
    <>
      <div>
        <ul className="grid px-0">
          <li
            style={{ borderBottom: "1px solid #e0e0e0" }}
            onClick={() => {
              navigate(APP_ROUTES.MAINDASHBOARD);
            }}
            className={`!inline-block relative before:absolute before:w-full before:top-16 pr-[8px] py-2 items-center text-base font-normal text-brand-lightBlack cursor-pointer 
                        
                        `}
          >
            <div
              className={`flex 
             ${location.pathname === APP_ROUTES.MAINDASHBOARD
                  ? "text-[#fff] bg-[#42BA85] rounded-20px"
                  : "text-[#000]"
                } `}
            >
              <img
                className="w-5 md:w-[30px] mr-3 pl-[8px] "
                src={
                  location.pathname === APP_ROUTES.MAINDASHBOARD
                    ? Dashboard
                    : DashboardG
                }
              />
              <span className="text-[15px] text-center  py-[10px]">Dashboard</span>
            </div>
          </li>
          {userCode && (
            <li
              style={{ borderBottom: "1px solid #e0e0e0" }}
              onClick={() => {
                navigate(APP_ROUTES.SUBSCRIPTIONSERVICES);
              }}
              className={`!inline-block relative before:absolute before:w-full before:top-16 pr-[8px] pb-2 items-center text-base font-normal text-brand-lightBlack cursor-pointer 
                         `}
            >
              <div
                className={`flex
               ${location.pathname === APP_ROUTES.SUBSCRIPTIONSERVICES
                    ? "text-[#fff] bg-[#42BA85] rounded-20px"
                    : "text-[#000]"
                  } `}>
                <img
                  className="w-5 md:w-[30px] mr-3 pl-[8px]"
                  src={
                    location.pathname === APP_ROUTES.SUBSCRIPTIONSERVICES
                      ? SubService
                      : SubServiceG
                  }
                />
                <span className="text-[15px] text-center py-[10px]">
                  Subscription Services
                </span>

              </div>
            </li>
          )}
          <li
            style={{ borderBottom: "1px solid #e0e0e0" }}
            onClick={() => {
              navigate(APP_ROUTES.APPOINTMENTS);
            }}
            className={`!inline-block relative before:absolute before:w-full before:top-16 pr-[8px] pb-2 items-center text-base font-normal text-brand-lightBlack cursor-pointer 
                         `}
          >
            <div className={`flex
               ${location.pathname === APP_ROUTES.APPOINTMENTS
                ? "text-[#fff] bg-[#42BA85] rounded-20px"
                : "text-[#000]"
              } `}>
              <img
                className="w-5 md:w-[30px] mr-3 pl-[8px]"
                src={
                  location.pathname === APP_ROUTES.APPOINTMENTS
                    ? Appointment
                    : AppointmentG
                }
              />
              <span className="text-[15px] text-center  py-[10px]">Appointments</span>
            </div>
          </li>
          <li
            style={{ borderBottom: "1px solid #e0e0e0" }}
            onClick={() => {
              navigate(APP_ROUTES.MYHEALTHRECORDS);
            }}
            className={`!inline-block relative before:absolute before:w-full before:top-16 pr-[8px] pb-2 items-center text-base font-normal text-brand-lightBlack cursor-pointer 
                         `}
          >
            <div className={`flex
               ${location.pathname === APP_ROUTES.MYHEALTHRECORDS
                ? "text-[#fff] bg-[#42BA85] rounded-20px"
                : "text-[#000]"
              } `}>
              <img
                className="w-5 md:w-[30px] mr-3 pl-[8px]"
                src={
                  location.pathname === APP_ROUTES.MYHEALTHRECORDS
                    ? HealthRec
                    : healthG
                }
              />
              <span className="text-[15px] text-center py-[10px]">
                Health Records
              </span>
            </div>
          </li>
          <li
            onClick={() => {
              navigate(APP_ROUTES.MYSCHEDULE);
            }}
            className={`!inline-block relative before:absolute before:w-full before:top-16 pr-[8px] pb-2 items-center text-base font-normal text-brand-lightBlack cursor-pointer 
                       `}
          >
            <div className={`flex
               ${location.pathname === APP_ROUTES.MYSCHEDULE
                ? "text-[#fff] bg-[#42BA85] rounded-20px"
                : "text-[#000]"
              } `}>
              <img
                className="w-5 md:w-[30px] mr-3 pl-[8px]"
                src={
                  location.pathname === APP_ROUTES.MYSCHEDULE
                    ? Schedule
                    : ScheduleG
                }
              />
              <span className="text-[15] text-center  py-[10px]">Schedule</span>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
}

export default DashboardSidemenu;
