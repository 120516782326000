import * as React from "react";
import { useEffect, useState, useCallback } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import {
  fetchCities,
  fetchEclinicsBypincode,
  fetchLocation,
  fetchMapMyIndiaToken,
} from "../../store/actions/location";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import ApartmentIcon from "@mui/icons-material/Apartment";
import MapmyIndia from "mapmyindia-react";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Footer from "../homepage/footer";
import locationI from "../homepage/image/location.footer.svg";
import MobileFooter from "./../homepage/mobileFooter";
import axios from "axios";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton, Modal } from "@mui/material";

const drawerWidth = 433;

const MapWithMarker = ({ cities }) => {
  console.log(cities)
  let marker = [];
  for (let i = 0; i < cities?.length; i++) {
    if (cities[i]?.latitude > 0 && cities[i]?.longitude > 0) {
      marker.push({
        position: [cities[i]?.latitude, cities[i]?.longitude],
        draggable: false,
        title: cities[i]?.name,
      });
    }
  }

  return <MapmyIndia key={JSON.stringify(marker)} zoom={8} markers={marker} />;
};

const MapWithMarkerEclinic = ({ location }) => {
  // const marker = location?.map((value) => {
  //     // if (value?.latitude > 0 && value?.longitude > 0) {

  //     return ({
  //         position: [value?.latitude, value?.longitude],
  //         draggable: false,
  //         title: `${value?.name}
  //               <div className='flex pl-5 font-normal'>
  //               <br/>
  //               <span>
  //               ${value?.address1 + " " + value?.address2 + " " + value?.city}
  //               <br/>
  //               Zipcode: ${value?.pinCode}
  //               </span>
  //               </div>`,
  //         //   hovering:false
  //     })
  //     // }
  // })
  let marker = [];
  for (let i = 0; i < location?.length; i++) {
    if (location[i].latitude > 0 && location[i].longitude > 0) {
      marker.push({
        position: [location[i]?.latitude, location[i]?.longitude],
        draggable: false,
        title: location[i]?.name,
        // title: `${location[i]?.name}
        //   <div className='flex pl-5 font-normal'>
        //   <br/>
        //   <span>
        //   ${location[i]?.address1 + " " + location[i]?.address2 + " " + location[i]?.city}
        //   <br/>
        //   Zipcode: ${location[i]?.pinCode}
        //   </span>
        //   </div>`,
      });
    }
  }

  return (
    <MapmyIndia
      // onDblclick={onClick}
      key={JSON.stringify(marker)}
      zoom={8}
      markers={marker}
    />
  );
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  position: "static",
  zIndex: 2,
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  position: "static",
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "wrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer() {
  const [inputValue, setInputValue] = React.useState("");
  const [filteredValues, setFilteredValues] = React.useState();

  const [location, setlocation] = useState();
  const [locationpin, setlocationpin] = useState();
  const [cities, setcities] = React.useState();
  const [total, setTotal] = React.useState();
  const [screen, setscreen] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  React.useEffect(() => {
    async function fetchEclinics() {
      if (inputValue.length === 6) {
        const tokenResponse = await fetchMapMyIndiaToken();
        if (
          tokenResponse?.data?.responseCode === "200" &&
          tokenResponse.data?.succesObject
        ) {
          const token = tokenResponse.data.succesObject.access_token;
          //  console.log(inputValue, "inputValue")

          try {
            const response = await axios.get(
              `https://patient.curebay.com/dhp/places/geocode?address=${inputValue}&itemCount=1`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );
            let eLoc = response?.data?.copResults?.eLoc;

            if (eLoc) {
              const latLongResponse = await axios.get(
                `https://patient.curebay.com/dhp/O2O/entity/${eLoc}`,
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              if (latLongResponse) {
                let reqData = {
                  lat: latLongResponse.data?.latitude,
                  longt: latLongResponse.data?.longitude,
                  pincode: inputValue,
                };
                const eClinic = await fetchEclinicsBypincode(reqData);
                if (eClinic?.status === 200 && eClinic?.data) {
                  setlocation(eClinic?.data?.succesObject);
                  setFilteredValues(eClinic?.data?.succesObject);
                }
              }
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
    fetchEclinics();
  }, [inputValue]);

  React.useEffect(() => {
    async function fetchCity() {
      const cities = await fetchCities();
      if (cities?.status === 200 && cities?.data) {
        setcities(cities?.data?.succesObject);
        setTotal(cities?.data?.succesObject);
      }
    }
    fetchCity();
  }, []);

  const handleRadioEclinic = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    async function fetchLocations() {
      const eClinincsDetails = await fetchLocation();
      if (eClinincsDetails?.status === 200 && eClinincsDetails?.data) {
        setlocation(eClinincsDetails?.data?.succesObject);
        setFilteredValues(eClinincsDetails?.data?.succesObject);
      }
    }

    fetchLocations();
  }, []);

  const [open, setOpen] = React.useState(true);

  const toggleButton = () => {
    setOpen(!open);
  };

  const [selectedOption, setSelectedOption] = React.useState("city");

  const handleRadioCity = (event) => {
    setSelectedOption(event.target.value);
  };

  React.useEffect(() => {
    const filteredItems = filteredValues?.filter((item) =>
      item?.pinCode?.startsWith(inputValue?.trim())
    );
    setlocation(filteredItems);
  }, [inputValue]);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  const [filteredCity, setFilteredCity] = React.useState();
  const [curCity, setcurCity] = React.useState();
  // console.log(total);

  const onCityChange = async (e) => {
    // console.log(e.target.value);
    setcurCity(e.target.value);
    setTotal(cities?.filter((v) => v?.city == e.target.value));
  };

  React.useEffect(() => {
    async function fetchData() {
      try {
        const locationResponse = await fetchLocation({ city: curCity });
        if (locationResponse?.status === 200 && locationResponse?.data) {
          const locationData = locationResponse.data.succesObject;
          // setlocation(locationData);
          // console.log(locationData);
          setFilteredCity(locationData);
          // setcities(locationData)

          // setFilteredValues((prevFilteredValues) => locationData);
        }
      } catch (error) {
        console.error("Error fetching locations:", error);
      }
    }
    fetchData();
  }, [curCity, cities]);

  // console.log(filteredCity, "ck");
  // console.log(cities, "cc");

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const maxLength = 6;

    if (inputValue?.length > maxLength) {
      setInputValue(inputValue.slice(0, maxLength));
    } else {
      setInputValue(inputValue);
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="md:flex h-screen">
        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          <Drawer sx={{ zIndex: 1 }} variant="permanent" open={open}>
            <Divider />
            <div
              className="md:flex md:flex-col justify-center"
              style={{ opacity: open ? 1 : 0 }}
            >
              {!(selectedOption === "city") && (
                <div className="flex justify-center w-72  md:my-8 my-2 sm:mx-0 mx-2 ">
                  <img
                    className="relative left-8 mt-3 md:mt-0 border-black"
                    src={locationI}
                    alt="l"
                  />
                  <input
                    className="md:w-full w-72 md:h-16 h-10 md:mt-0 mt-4 py-2 pr-4 pl-10 border-2 rounded-lg"
                    id="state"
                    type="text"
                    pattern="[0-9]{1,6}"
                    onChange={handleInputChange}
                    value={inputValue}
                    placeholder="Enter Pincode"
                  />
                </div>
              )}
              
              <div className="md:flex md:flex-col justify-start items-start md:px-10 px-5 md:py-4">
                <p className="font-semibold text-md text-chathams-blue">
                  Select
                </p>
                <div className="md:flex pt-2">
                  <input
                    type="radio"
                    id="clinic"
                    name="age"
                    value="eClinics"
                    onChange={handleRadioEclinic}
                    checked={selectedOption === "eClinics"}
                  />
                  <label className="pr-4 text-chathams-blue pl-2" for="age1">
                    {" "}
                    eClinics{" "}
                  </label>
                  <input
                    type="radio"
                    id="cities"
                    name="age"
                    value="city"
                    onChange={handleRadioCity}
                    checked={selectedOption === "city"}
                  />
                  <label className=" text-chathams-blue pl-2" for="age2">
                    {" "}
                    City{" "}
                  </label>
                </div>
              </div>
              {selectedOption === "city" && (
                <div className="md:flex justify-center md:my-8 my-4 md:mx-10 mx-5">
                  <select
                    className="md:w-full md:h-16 h-8  border-2 rounded-lg"
                    id="city"
                    defaultValue=""
                    onChange={(e) => onCityChange(e)}
                  >
                    <option value="" disabled>
                      Select City
                    </option>
                    {cities?.map((value, index) => (
                      <option key={index} value={value.city}>
                        {value.city}
                      </option>
                    ))}
                  </select>
                </div>
              )}

              {selectedOption === "city" ? (
                <div className="md:flex  px-5 md:flex-col ">
                  <Divider />
                  <div className="flex pl-8 py-4 items-center">
                    <div className="bg-seegreen px-1 py-1 rounded-md text-white text-sm">
                      {/* {curCity ? filteredCity?.length : total?.length} */}
                    </div>
                    <p className="pl-2"> {curCity ? "CureBay eClinics Found" : "Cities Found"}</p>
                  </div>
                  {total?.map((value) => (
                    <div>
                      <Divider />
                      <div className="pl-10 py-6 text-chathams-blue text-base font-semibold">
                        <ApartmentIcon
                          sx={{ color: "#66B889", paddingRight: "7px" }}
                        />
                        {value?.city}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="md:flex md:flex-col ">
                  <Divider />
                  <div className="md:flex md:pl-8 pl-2 md:py-4 py-2 items-center">
                    <div className="bg-seegreen md:px-1 w-6 md:py-1 rounded-md text-white text-sm">
                      {location?.length}
                    </div>
                    <p className="md:pl-2  text-chathams-blue">
                      CureBay eClinics found{" "}
                    </p>
                  </div>
                  {location?.map((value) => (
                    <div className="md:flex">
                      <Divider />
                      <img
                        src={value?.image || value?.images}
                        alt="userImage"
                        className="border px-5 md:w-24 w-96 md:h-24 h-full mt-6 rounded"
                        onClick={() => handleImageClick(value)}
                      />
                      <div className="md:pl-10 pl-5 md:py-6 py-2 text-chathams-blue text-base font-semibold">
                        {value?.name}
                        <div className="flex pl-5 font-normal">
                          <br />
                          <span>
                            {value?.address1 +
                              " " +
                              (value?.address2 ? value.address2 + " " : "") +
                              value?.city}
                            <br />
                            Zipcode:{value?.pincode || value?.pinCode}
                            <br />
                            <span>
                              {value && value.distance !== undefined && (
                                <>
                                  Distance:{" "}
                                  {Math.floor(value.distance * 100) / 100} km
                                </>
                              )}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* <div className=' flex justify-center items-center  '> */}
            {/* <div className='bg-white absolute top-96 flex flex-col justify-center items-center border-2 border-l-0 w-10 rounded-md h-20 ' onClick={toggleButton} style={open ? { left: '36rem' } : { left: '13rem' }} >

                            {open ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}

                        </div> */}
            {/* </div> */}
          </Drawer>
          {/* </div> */}
        </Box>
        <div className="sticky h-full w-full">
          {selectedOption === "eClinics" ? (
            <MapWithMarkerEclinic location={location} />
          ) : (
            <MapWithMarker cities={filteredCity} />
          )}
        </div>
      </div>
      <Modal
        open={isModalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="style">
          <div className="">
            <div className="w-full flex justify-end ">
              <IconButton
                onClick={handleCloseModal}
                aria-label="Close"
                className="float-right h-[15px] w-[15px]"
              >
                <CloseIcon className=" h-[10px] w-[10px]" />
              </IconButton>
            </div>
          </div>
          <div className="w-full flex justify-end">
            <img
              src={selectedImage?.image || selectedImage?.images}
              alt="Larger Image"
            />
          </div>
          <div className="">
            <div className="flex text-center justify-center mt-5 mb-2">
              <div className="">
                <h1 className="ml-3 text-[#0272DA] font-medium font-poppins md:text-[15px]">
                  {selectedImage?.name}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {screen > 500 ? (
        <Footer i={1} />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
      {/* <Footer i={1} /> */}
    </>
  );
}
