import React, { useEffect, useState } from 'react'
import services from '../../store/services'
import Footer from './footer';
import { Card, Icon, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { APP_ROUTES } from '../../constants/approutes';
import { useNavigate } from 'react-router-dom';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MobileFooter from './../homepage/mobileFooter';
import FooterMobileVersion from './FooterMobileVersion';


const Seeallpartners = () => {
    const [data, setData] = useState([]);
    const [screen, setscreen] = useState(window.innerWidth);


    useEffect(() => {
        const updateWindowDimensions = () => {
            const newWidth = window.innerWidth;
            setscreen(newWidth);
        };
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions);
    }, []);
    useEffect(() => {
        const getAllData = async () => {
            const res = await services.get("/service-partners?populate=deep");
            setData(res?.data?.data)
        }
        getAllData()

    }, [])
    console.log(data)
    const breadcrumbItems = [
        { label: "Home", url: APP_ROUTES.DASHBOARD },
        { label: "See All Partners", url: APP_ROUTES.ALLPARTNERS },
    ];
    const navigate = useNavigate()


    const Accordion = ({ title, content }) => {

        const [isActive, setIsActive] = useState(false);
        const handleOnClick = () => {
            setIsActive(!isActive)
            // setShow(!show)
        }
        return (
            <div className='border-2 mb-4'>

                <div className="flex cursor-pointer justify-between items-center p-4  mx-4" onClick={handleOnClick}>
                    <h2 className={`w-11/12  sm:text-[22px] text-[16px] font-poppins  text-[#0B4881]  font-semibold `}>{title}</h2>
                    <div className="flex justify-end text-3xl font-semibold  text-brand-primary"><IconButton size='medium' sx={{ backgroundColor: '#E4FAFF' }}>{isActive ? <RemoveCircleOutlineIcon /> : <AddCircleOutlineIcon />}</IconButton></div>
                </div>
                {isActive &&
                    //  <div className="w-11/12  px-4 text-[20px] font-custom font-medium text-black font-poppins pb-15">{content}
                    //  </div>

                    content &&
                    <div className=" w-full grid sm:grid-cols-7  grid-cols-2 justify-center p-5 gap-3 ">
                        {content?.map((value1) => (
                            <div className=' border-2 h-24 pt-3  sm:h-auto sm:py-1' style={{textAlign:"-webkit-center"}}>
                               <img src={value1?.image_url} className='sm:w-36 w-24 h-16 sm:h-28 '></img>
                            </div>
                        ))}
                    </div>

                }

                {/* {!show ?
                    <div className='flex mx-16 justify-center items-center bg-[#0272DA] py-3'>
                        <h1 className='font-poppins text-[20px] font-semibold text-white'>
                            Take care of your loved ones with a simple click
                        </h1>
                        <button variant="contained" color="primary" className="  w-36 py-2 bg-[#F0EA4F] rounded-md text-black text-sm font-poppins font-medium ml-[3rem] " >
                            Add To Cart
                        </button>
                    </div>
                    : <></>
                } */}
            </div>


        );
    };

    return (
        <div className='select-none xs-overflow-x-hidden  md:absolute md:top-10 w-full md:w-[87.5rem]'>
            {/* <div className="flex items-center my-3 ">
                {breadcrumbItems.map((item, index) => (
                    <React.Fragment key={index}>
                        <p className="text-base font-medium leading-7 text-chathams-blue-500 cursor-pointer hover:underline hover:underline-offset-4">
                            <a onClick={() => navigate(item.url)}>{item.label}</a>
                        </p>
                        {index !== breadcrumbItems.length - 1 && (
                            <ArrowForwardIosIcon
                                sx={{ fontSize: 15 }}
                                className="text-chathams-blue-500 mx-2"
                            />
                        )}
                    </React.Fragment>
                ))}
            </div> */}
            <div className='h-28 sm:h-auto'
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    backgroundImage: `url("${data?.[0]?.attributes?.firstcomp?.image_url}")`,
                    // position: 'sticky',
                    backgroundSize: 'cover',

                }}>
                <div className='sm:pl-14 pl-0'
                    style={{
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'center',

                        // maxWidth: '100%',
                        height: '100%'
                    }}
                >
                    <div className="container mx-auto px-8 ">
                        {/* <div className=" w-200 h-8 bg-shakespeare bg-opacity-[8%] m-1 py-2 px-2 rounded-md">
                                <p className='text-14px md:text-sm  text-center md:font-normal text-chathams-blue-500 whitespace-nowrap	'>{data && data.FirstComponent?.name || ''}</p>
                            </div> */}
                        <div className='  sm:mt-36 mt-[27rem] bg-transparent'>
                            {/* <p className='md:text-40px md:font-bold leading-54px text-chathams-blue-500'>{data && data.FirstComponent?.title || ''}</p> */}
                            <h1 className='md:text-40px sm:text-xl text-[22px] font-semibold sm:leading-54px  sm:w-5/12 text-newblue font-poppins'>Finest 
                            </h1>
                            <h2 className='sm:font-light font-normal md:text-40px sm:text-2xl text-[20px] md:leading-54px font-poppins text-newblue  sm:pb-7 pb-3 '>Service Partners.</h2>
                            <p className='font-normal sm:text-base text-[16px]  sm:leading-[30.5px] font-poppins md:w-5/12 mb-24 '>{data?.[0]?.attributes?.firstcomp?.description}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='sm:w-[89rem] w-full sm:mt-6 mt-56 mb-16'>
                <div className='flex  px-8 justify-center items-center pt-7 pb-7'>
                    <h2 className='md:text-[40px] text-xl font-poppins font-[300] '>Service</h2>

                    <h3 className='md:text-[40px] text-2xl pl-2 font-medium  font-poppins text-newgreen '>Partners.</h3>


                </div>
                <div className='mb-70 sm:px-0 px-4'>
                    {data?.[0]?.attributes?.SecondComp?.content1?.map((value) => (
                        // <div className='my-4'>
                        //     <p className='text-lg font-bold pl-8'>
                        //         {value?.name}
                        //     </p>
                        // <Card variant="outlined" sx={{ display: 'flex', borderWidth: '2px', borderRadius: '4px', margin: '1rem', flexWrap: 'wrap', }}>
                        //     {value?.label?.map((value1) => (
                        //         <img className='m-4' src={value1?.image_url}></img>
                        //     ))}
                        // </Card>
                        // </div>

                        <Accordion title={value?.name} content={value?.label} />
                    ))}
                </div>
            </div>

            {/* <Footer i={1} /> */}
            {screen > 500 ? <Footer i={1} /> :
                <>
                    <FooterMobileVersion />
                    <MobileFooter />
                </>
            }
        </div>
    )

}

export default Seeallpartners