import { COMMONCONSTANTS } from "../constants/commonconstants";

const initialState = {
    coords: null,
    cartListLength: [],
    isError: false,
    isLoading: false
};

const CommonReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case COMMONCONSTANTS.COORDS:
            return {
                ...state,
                coords: payload,
            };
        case COMMONCONSTANTS.SUCCESS_FETCHCART:
            return {
                ...state,
                isLoading: false,
                cartListLength: action.payload,
                isError: false,
            };
        default:
            return state;
    }
};

export default CommonReducer;