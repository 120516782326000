import React, { useState, useEffect } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import MyprofileSidemenu from "../homepage/myprofile-sidemenu";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Card, Chip, Paper } from "@mui/material";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate } from "react-router";
import { Typography, Button } from "@mui/material";
import { Password } from "@mui/icons-material";
import { updatepswd } from "../../store/actions/myprofile";
import { useStaticPicker } from "@mui/x-date-pickers/internals";
import FooterMobileVersion from "../../components/homepage/FooterMobileVersion";
import MobileFooter from "./../homepage/mobileFooter";
import Footer from '../../components/webflow/footer';

function InputWithIcon({ type, onChange, place, onKeyDown }) {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="relative">
      <input
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={place}
        required
        className="sm:w-96  w-[250px] py-2 text-start  pl-3 border rounded-sm shadow-sm focus:outline-none focus:bg-password-gray  sm:text-sm "
      />
      {type === "password" && (
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          {showPassword ? (
            <VisibilityIcon className="h-5 w-5" />
          ) : (
            <VisibilityOffIcon className="h-5 w-5" />
          )}
        </button>
      )}
    </div>
  );
}

const Changepswd = () => {
  const breadcrumbItems = [
    { label: "Home", onClick: () => navigate(APP_ROUTES.DASHBOARD) },
    { label: "My Details" },
    { label: "Change Password" },
  ];

  const navigate = useNavigate();
  const [pswd, setPswd] = useState();
  const [pass, setPass] = useState();
  const [passChange, setPassChange] = useState();
  const [oldPass, setOldPass] = useState();
  const [err, setErr] = useState();
  const [notSame, setNotSame] = useState();
  const [screen, setscreen] = useState(window.innerWidth);
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode
    ? userObj.userCode
    : userObj?.parentCode
      ? userObj.parentCode
      : null;
  console.log(userCode, userObj?.parentCode, userObj?.userCode, userObj);

  const handleUpdate = async () => {
    if (pass == passChange) {
      async function changepassword() {
        const ourAssisted = await updatepswd(
          userObj?.code
            ? {
              // "userCode": userObj?.code ? userObj?.code : userCode,
              patientCode: userObj?.code ? userObj?.code : userCode,
              passWordVal: pass,
              currentPasswordVal: oldPass,
            }
            : {
              userCode: userObj?.code ? userObj?.code : userCode,
              // "patientCode": userObj?.code ? userObj?.code : userCode,
              passWordVal: pass,
              currentPasswordVal: oldPass,
            }
        );
        if (ourAssisted.status === 200) {
          if (ourAssisted?.data?.responseCode == 200) {
            setPswd(ourAssisted?.data);
            localStorage.clear();
            navigate(APP_ROUTES.LOGIN);
          } else {
            setErr(ourAssisted?.data);
          }
        }
      }
      changepassword();
    } else {
      setNotSame("New password and Confirm Password Not matched");
    }
  };

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    <div className="sm:p-8">
      <div className="flex items-center mb-5 mt-[100px] sm:pl-0 pl-[12px]">
        {breadcrumbItems.map((item, index) => (
          <React.Fragment key={index}>
            <span className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">
              <a>{item.label}</a>
            </span>
            {index !== breadcrumbItems.length - 1 && (
              <ArrowForwardIosIcon
                sx={{ fontSize: 15 }}
                className="text-chathams-blue-500 mx-2"
              />
            )}
          </React.Fragment>
        ))}
      </div>
      <h2 className="text-xl font-bold my-5 text-chathams-blue-500 sm:pl-0 pl-[12px]">
        Change Password
      </h2>
      <div className="flex scroll-px-2">
        <div className="w-1/4 mr-4 border p-4 rounded-md hidden sm:block">
          <MyprofileSidemenu />
        </div>
        <div classname="w-full ml-4 border  rounded-md">
          <div className="flex w-full mt-1">
            <div className="flex w-full sm:mr-8">
              <Card
                variant="outlined"
                elevation={1}
                sx={{
                  boxShadow: " 0px 10px 13px rgba(0, 0, 0, 0.04)",
                  paddingX: "2%",
                  borderRadius: "5px",
                }}
              >
                <div className="mx-3 my-6  flex justify-start  border-b-2 ">
                  <div>
                    <p className="text-brand-lightBlack text-lg font-bold">
                      Change Your Password
                    </p>
                    <p className="text-brand-lightBlack text-base font-medium mt-1 pb-5">
                      Please fill the following details
                    </p>
                  </div>
                </div>
                <div className="flex flex-col sm:flex-row">
                  <div className="sm:w-[30rem] sm:h-[30rem] mr-2">
                    <div className="grid justify-start px-8 pl-4  ">
                      <div className="grid py-1">
                        <label className=" text-brand-lightBlack pl-1 pt-2 pb-1">
                          {" "}
                          Old Password
                        </label>
                        <InputWithIcon
                          type="password"
                          place="Type Old Password"
                          onChange={(e) => setOldPass(e?.target?.value)}
                        />
                      </div>
                      <div className="grid py-1">
                        <label className=" text-brand-lightBlack pl-1 pt-2 pb-1">
                          {" "}
                          New Password
                        </label>
                        <InputWithIcon
                          type="password"
                          place="Type New Password"
                          onChange={(e) => setPass(e?.target?.value)}
                        />
                      </div>
                      <div className="grid py-1">
                        <label className=" text-brand-lightBlack pl-1 pt-2 pb-1">
                          {" "}
                          Confirm Password
                        </label>
                        <InputWithIcon
                          type="password"
                          place="Type Confirm Password"
                          onChange={(e) => setPassChange(e?.target?.value)}
                          onKeyDown={(e) => {
                            console.log(e.key);
                            if (e.key === "Enter") {
                              handleUpdate();
                            }
                          }}
                        />
                      </div>

                      <div>
                        {pswd?.responseCode == 200 ? (
                          <p className="text-seegreen font-semibold">
                            Password Changed Successfully
                          </p>
                        ) : err?.responseCode ? (
                          <p className="text-red-600">{err?.responseMessage}</p>
                        ) : pass != passChange ? (
                          <p className="text-red-600">{notSame}</p>
                        ) : (
                          <></>
                        )}
                      </div>

                      <Button
                        sx={{ marginTop: "1rem" }}
                        variant="contained"
                        onClick={handleUpdate}
                      >
                        Next
                      </Button>
                    </div>
                  </div>

                  <Card
                    className="sm:h-[20rem] sm:w-[30rem] sm:my-0 my-4"
                    variant="outlined"
                    elevation={1}
                    sx={{
                      boxShadow: " 0px 10px 13px rgba(0, 0, 0, 0.04)",
                      paddingX: "1%",
                      borderRadius: "7px",
                    }}
                  >
                    <div>
                      <div className="mx-3 my-6  flex justify-start  border-b-2 ">
                        <div>
                          <p className="text-brand-lightBlack text-lg font-bold pb-3">
                            Password must contain:
                          </p>
                        </div>
                      </div>
                      <div className="pl-6 pb-2 pr-1">
                        <li>Must contain a min of 8 and max of 16 character</li>
                        <li className="mb-3 mt-3">
                          Must contain at least one uppercase and a numeral
                        </li>
                        <li>Must contain at least one special character</li>
                      </div>
                    </div>
                  </Card>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </div>
    
  );
};

export default Changepswd;
