import React, { useEffect, useState } from "react";
import Footer from "../homepage/footer";
import {
  Button,
  Icon,
  IconButton as MuiIconButton,
  Paper,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import doctor from "./image/269.svg";
import icon1 from "./image/Group_19967.svg";
import icon2 from "./image/Group_19961.svg";
import icon3 from "./image/Group_19955.svg";
import icon4 from "./image/Group_19965.svg";
import icon5 from "./image/Group_19959.svg";
import icon6 from "./image/Group_19953.svg";
import icon7 from "./image/Group_19963.svg";
import icon8 from "./image/Group_19957.svg";
import icon9 from "./image/Group_19951.svg";
import graph from "./image/graph 1.png";
import graph2 from "./image/graph 2.png";
import docytIcon from "../careplans/image/docIcon.svg";
import {
  fetchDoctorsData,
  getDoctorsSliderDetails,
} from "../../store/actions/doctors";
import { APP_ROUTES } from "../../constants/approutes";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllSpecialities } from "../../store/actions/doctors";
import { getDoctorslist } from "../../store/actions/doctors";
import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import Carouselnew from "react-multi-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

var nloop = null;

const SpecialityList = [
  {
    title: "Cardiology",
    icon: icon1,
  },
  {
    title: "Dentistry",
    icon: icon2,
  },
  {
    title: "Dermatology",
    icon: icon3,
  },
  {
    title: "Emergency Medicine",
    icon: icon4,
  },
  {
    title: "Endocrinology",
    icon: icon5,
  },
  {
    title: "ENT",
    icon: icon6,
  },
  {
    title: "General Surgery",
    icon: icon7,
  },
  {
    title: "Geriatrics",
    icon: icon8,
  },
  {
    title: "Opthalmology",
    icon: icon9,
  },
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 9,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const IconButton = styled(MuiIconButton)({
  backgroundColor: "white",
  borderColor: "#66B889 !important",
  borderWidth: "1px !important",
  border: "solid",
  color: "#66B889",
  "&:hover": {
    backgroundColor: "#66B889",
    color: "white",
  },
});

const Doctors = () => {
  const doctorPageContent = useSelector(
    (state) => state.HomePageReducer.doctorPageContent
  );

  console.log(doctorPageContent);

  const navigation = useNavigate();
  const [position, setPosition] = React.useState({
    skip: 0,
    take: 9,
  });

  const [doctorSliderDetails, setDoctorSliderDetails] = useState([]);
  const [doctorSpecialities, setDoctorSpecialities] = useState([]);
  const [list, setList] = useState([]);
  // const coords = { lat: 8.6130176, long: 77.2308992 }

  useEffect(() => {
    async function fetchData() {
      const res = await getAllSpecialities();
      if (res?.status === 200 && res?.data?.succesObject) {
        setDoctorSpecialities(res.data.succesObject);
        nloop = 9 * Math.ceil(res.data.succesObject?.length / 9);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const doctorsSlderResponse = await getDoctorsSliderDetails();
      if (
        doctorsSlderResponse.status === 200 &&
        doctorsSlderResponse?.data?.data
      ) {
        setDoctorSliderDetails(doctorsSlderResponse.data.data);
      }
    }
    fetchData();
  }, []);

  const [data, setData] = useState();

  useEffect(() => {
    async function docList() {
      const res = await getDoctorslist();
      if (res?.status === 200 && res?.data) {
        setList(res?.data);
      }
    }
    docList();

    async function docData() {
      const res = await fetchDoctorsData();
      if (res?.status === 200 && res?.data?.data[0]) {
        setData(res?.data?.data[0]);
      }
    }
    docData();
  }, []);
  console.log(data);

  const eliteDoc = list.filter((value) => (value.doctorType = "null"));

  const [doc, setDoc] = useState(0);
  const handlleScroll1 = (e, dir) => {
    if (dir === "rightArrow" && doc < eliteDoc.length - 1) {
      const temp = doc + 1;
      setDoc(temp);
    } else if (dir === "leftArrow" && doc > 0) {
      const temp = doc - 1;
      setDoc(temp);
    }
  };

  const handleScroll = (e, dir) => {
    if (dir === "rightArrow" && position.take < nloop) {
      let data = {
        skip: position.take,
        take: position.take + 9,
      };
      setPosition(data);
    } else if (dir === "leftArrow" && position.skip !== 0) {
      let data = {
        skip: position.skip - 9,
        take: position.take - 9,
      };
      setPosition(data);
    }
  };

  function SectionContainer(props) {
    return (
      <div className=" w-1/2 flex justify-between mb-2 items-center container mx-auto ">
        <p
          className="text-lg sm:text-lg md:text-xl lg:text-xl font-bold text-gray-700 mb-0 flex justify-center mt-4"
          style={props?.style}
        >
          {props.title}
        </p>
        <div className="flex flex-col md:flex-col  justify-between lg:mt-8">
          {props.seeAll !== "hide" && (
            <div className="flex lg:pb-3 justify-end items-center">
              {/* <div className="flex gap-2">
                <a
                  href="javascript:void(0);"
                  onClick={(event) => {
                    props.handelSroll(event, "leftArrow");
                  }}
                >
                  <IconButton size="large">
                    <WestIcon />
                  </IconButton>
                </a>
                <a
                  href="javascript:void(0);"
                  onClick={(event) => {
                    props.handelSroll(event, "rightArrow");
                  }}
                >
                  <IconButton size="large">
                    <EastIcon />
                  </IconButton>
                </a>
              </div> */}
            </div>
          )}
        </div>
      </div>
    );
  }

  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  return (
    // <div className='select-none container mx-auto' style={{ height: '100vh', width: '100%' }}></div>
    <div className="select-none xs-overflow-x-hidden  md:absolute md:top-10 w-full md:w-[87.5rem] ">
      <div className="">
        <div
          className="mainDiv "
          style={{
            backgroundImage: `url("${doctorPageContent?.data[0]?.attributes?.frst?.image_url}")`,
            backgroundSize: "contain",
            // height: '386px',
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "right",
            // backgroundColor: "#f0f4f6",
          }}
        >
          <div
            className="px-8"
            style={{
              display: "flex",
              justifyContent: "left",
              alignItems: "left",
              paddingLeft: "16px",
              // maxWidth: '100%',
              height: "100%",
            }}
          >
            <div className="container mx-auto md:px-8">
              {/* <div className=" w-200 h-8 bg-shakespeare bg-opacity-[8%] m-1 py-2 px-2 rounded-md">
                                <p className='text-14px md:text-sm  text-center md:font-normal text-chathams-blue-500 whitespace-nowrap	'>{data && data.FirstComponent?.name || ''}</p>
                            </div> */}
              <div className=" mt-36 bg-transparent ">
                {/* <p className='md:text-40px md:font-bold leading-54px text-chathams-blue-500'>{data && data.FirstComponent?.title || ''}</p> */}
                <h1 className=" md:text-40px text-[22px] font-semibold sm:leading-54px md:leading-36px w-3/12 text-newblue font-poppins">
                  {doctorPageContent?.data[0]?.attributes?.frst?.title?.substring(
                    0,
                    8
                  )}
                </h1>
                <h2 className="font-light md:text-40px text-[20px] md:leading-54px font-poppins text-newblue md:w-5/12 pb-2 sm:pb-7 ">
                  {" "}
                  {doctorPageContent?.data[0]?.attributes?.frst?.title?.substring(
                    8,
                    doctorPageContent?.data[0]?.attributes?.frst?.title?.length
                  )}
                </h2>
                <p className="text-[16px]  font-normal  sm:text-base md:leading-[30.5px] font-poppins md:w-7/12 md:mb-24 ">
                  {doctorPageContent?.data[0]?.attributes?.frst?.description}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className='mainDiv'>
                <div className=' mx-auto flex flex-row bg-white'
                    style={{
                        backgroundSize: 'contain',
                        height: '400px',
                        backgroundRepeat: 'no-repeat',
                        backgroundPositionX: 'right',
                        backgroundImage: `url(${doctorSliderDetails?.[0]?.attributes?.image})`,
                    }}
                >
                    <div className='w-10/12 pl-6  pt-28 ' style={{
                        background: `linear-gradient(90.14deg, #FFFFFF 49.97%, rgba(255, 255, 255, 0.39) 87.24%, rgba(255, 255, 255, 0) 98.95%)`,
                    }}>
                        <p className='p-2 text-base leading-54px md:text-sm md:font-normal text-center rounded-[5px] bg-shakespeare bg-opacity-[8%] inline-flex items-center text-chathams-blue-500'>Care</p>
                        <p className='p-2 text-[40px] font-bold text-chathams-blue-500 w-11/12  '> {doctorSliderDetails?.[0]?.attributes?.description} </p>
                        <p className='p-2 text-base leading-27px font-semibold  w-7/12   text-chathams-blue-500'>{doctorSliderDetails?.[0]?.attributes?.details}</p>
                    </div>
                </div>
             
            </div> */}

      {/* <div className="py-5 my-16  w-full  ml-0 flex flex-row pb-10  container mx-auto  relative" > */}
      <div className="px-4 py-8 bg-[#40B88205]">
        <div className="md:flex flex-col md:px-8 ">
          <h2 className="md:text-[40px] text-xl font-poppins font-[300]  md:h-12 ">
            Excellent team
          </h2>
          <div className="md:flex w-full">
            <h3 className="md:text-[53px] text-2xl font-medium  font-poppins text-newgreen md:w-[24rem] ">
              of doctors.
            </h3>
            <div className=" w-full flex flex-col justify-center align-middle ">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center md:mx-8">
          <p className="font-poppins font-normal md:text-[20px] text-base mt-2 sm:mt-2">
            Get the time and attention you deserve. With CureBay you get to have
            in-depth conversation with your doctor.
          </p>
          {/* <div className='flex gap-2 ' >
                        <IconButton size='medium' onClick={(event) => {
                            handlleScroll1(event, "leftArrow");
                        }}>
                            <Icon fontSize='large'>keyboard_arrow_left</Icon>
                        </IconButton>
                        <IconButton size='medium' onClick={(event) => {
                            handlleScroll1(event, "rightArrow");
                        }}>
                            <Icon fontSize='large'  >keyboard_arrow_right</Icon>
                        </IconButton>
                    </div> */}
          <div className="flex gap-2 ">
            <IconButton
              size="large"
              onClick={(event) => {
                handlleScroll1(event, "leftArrow");
              }}
            >
              <WestIcon />
            </IconButton>
            <IconButton
              size="large"
              onClick={(event) => {
                handlleScroll1(event, "rightArrow");
              }}
            >
              <EastIcon />
            </IconButton>
          </div>
        </div>
        <div className="md:flex relative">
          <div className="my-10 flex relative md:px-14 mx-10">
            <div className="relative md:bg-[#0272DA2B] md:rotate-12 rounded-md h-[250px] w-[250px] "></div>
            <img
              className="absolute md:left-14 h-[250px] md:w-[250px] w-full rounded-md "
              src={
                eliteDoc[doc]?.userPhoto
                  ? `${process.env.REACT_APP_IMAGE_URL}${eliteDoc[doc]?.userPhoto}`
                  : ""
              }
              alt="img"
            />
          </div>
          <div className="w-full md:mx-10 flex justify-center flex-col">
            <div className="md:flex justify-between w-full ">
              <h2 className="font-poppins font-bold text-[#0B4881] md:text-[26px] text-lg">
                Dr. {eliteDoc[doc]?.userName}
              </h2>
              <h3 className="font-poppins font-semibold md:text-[18px] text-base text-[#0272DA]">
                {eliteDoc[doc]?.specialities}
              </h3>
            </div>

            <p className=" text-justify font-poppins font-normal md:text-[16px] text-base sm:pt-6 pt-3 md:pb-3">
              {eliteDoc[doc]?.description}
            </p>

            {/* <Button
                            variant="outlined"
                            // onClick={() => { navigate(APP_ROUTES.) }}
                            sx={{ textTransform: "capitalize", marginY: '1rem', width: '10rem' }}
                        >
                            Read More
                        </Button> */}
          </div>
        </div>
        {/* <div className='w-7/12 m-5'>
                    <p className='bg-light-sky text-chathams-blue h-8 w-28 text-base flex justify-center items-center'>Our Doctors </p>
                    <h1 className="text-3xl py-8 sm:text-4xl font-bold" style={{ color: "rgba(29, 56, 104, 1)" }}>
                        Excellent team of doctors</h1>
                    <p className="text-lg sm:mb-5  font-semibold text-chathams-blue ">{eliteDoc[doc]?.description}</p>
                    <h1 className="pb-3 sm:text-2xl font-bold" style={{ color: "rgba(29, 56, 104, 1)" }}>
                        {eliteDoc[doc]?.userName}</h1>
                    <p className=' text-seegreen flex flex-row items-center text-base font-semibold '>
                        <span>
                            <img src={docytIcon} draggable={false} alt="img" className='h-[26px] m-1'></img>
                        </span>
                        {eliteDoc[doc]?.specialities}
                    </p>
                    <div className='flex gap-2 mt-6 ' >
                        <IconButton size='medium' onClick={(event) => {
                            handlleScroll1(event, "leftArrow");
                        }}>
                            <Icon fontSize='large'>keyboard_arrow_left</Icon>
                        </IconButton>
                        <IconButton size='medium' onClick={(event) => {
                            handlleScroll1(event, "rightArrow");
                        }}>
                            <Icon fontSize='large'  >keyboard_arrow_right</Icon>
                        </IconButton>
                    </div>
                </div> */}
        {/* <div className='w-5/12 flex justify-center'>
                    <img src={eliteDoc[doc]?.userPhoto ? `https://storage.googleapis.com/curebay-prod-application-data/${eliteDoc[doc]?.userPhoto}` : ''} alt='img' style={{ width: "300px", height: "300px", backgroundColor: 'rgba(102, 184, 137, 0.21)', borderRadius: '93px 92px 92px 0px' }} />
                    
                </div> */}
      </div>
      {/* <div className='flex flex-col items-center justify-center  container mx-auto'>
                
                <p className='flex justify-center text-[38px] w-4/5 font-bold text-justify text-chathams-blue'>Our Specialities</p>


                <div className="grid grid-cols-4  gap-4 container mx-auto">
                    <div className='col-start-4 col-end-5 '>
                        <SectionContainer
                            data={SpecialityList}
                            title={SpecialityList[position]?.title}
                            subtitle={"Consult the best General Physicians in your locality"}
                            seeAll={"Doctors"}
                            handelSroll={handleScroll}
                        />
                    </div>
                    <div className='col-start-1 col-end-2  '>
                        <img className='h-[470px]' src={doctor} alt='img' />
                    </div>
                    <div className="col-start-2 col-end-5  grid grid-cols-3 gap-x-[72px] gap-y-[14px]  " >
                        
                        {doctorSpecialities?.length > 0 && doctorSpecialities.slice(position.skip, position.take).map((e) => (
                            <Paper className='hover:border hover:border-chathams-blue-50 cursor-pointer' sx={{ minWidth: '268px', maxWidth: '280px', minHeight: '110px', maxHeight: '115px', boxShadow: 'none', border: '1px solid #FEEBE4', backgroundColor: '', position: 'relative' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', margin: '5px', minHeight: '100px', maxHeight: '100%', maxWidth: '100%', backgroundColor: '' }}>
                                    <img src={e?.images} style={{ position: 'absolute', left: '22px' }} alt={"img"}></img>
                                    <p onClick={() => navigation(APP_ROUTES.ALL_DOCTORS, { state: { id: 'docsp', name: e?.speciality } })} style={{ position: 'absolute', left: '90px', maxWidth: '100%' }} className='text-22px m-3  font-semibold leading-7 text-left text-chathams-blue-500'>{e?.speciality}</p>
                                </div>
                            </Paper>
                        ))}

                    </div>
                </div>
            </div> */}

      <div className="px-4 md:py-4  ">
        <div className="md:flex flex-col md:px-8  ">
          <p className="md:text-[40px] text-xl font-poppins font-[300] sm:h-12 h-8 ">
            Our
          </p>
          <div className="md:flex w-full">
            <p className="md:text-[53px] text-2xl font-medium font-poppins text-newgreen w-[24rem] ">
              Specialities.
            </p>
            <div className=" w-full md:flex flex-col justify-center align-middle ">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
        </div>
        <div className="md:flex justify-between items-center md:mx-8">
          <p className="font-poppins font-normal md:text-[20px] text-base sm:mt-0 mt-3">
            Experience an expansive range of specialized fields catered to your
            needs.
          </p>
          <div className="col-start-4 col-end-5 hidden sm:block ">
            <SectionContainer
              data={SpecialityList}
              title={SpecialityList[position]?.title}
              subtitle={"Consult the best General Physicians in your locality"}
              seeAll={"Doctors"}
              handelSroll={handleScroll}
            />
          </div>
        </div>

        <div className="md:mx-8 h-44  hidden sm:block gap-4 ">
          {doctorSpecialities?.length > 0 && (
            <Carouselnew
              responsive={responsive}
              autoPlay
              interval={100}
              infinite
              showStatus={false}
              showThumbs={false}
              // showDots={true}
            >
              {doctorSpecialities?.map((e) => (
                <div
                  className="w-32 text-center"
                  style={{ textAlign: "-webkit-center" }}
                >
                  <a
                    href="/seeAll"
                    onClick={() =>
                      navigation(APP_ROUTES.ALL_DOCTORS, {
                        state: { id: "docsp", name: e?.speciality },
                      })
                    }
                  >
                    <img
                      className="border-2 rounded-full w-28 cursor-pointer"
                      src={e?.images}
                      alt={"img"}
                    />
                  </a>

                  <h3 className="text-[14px] font-semibold font-poppins">
                    {e?.speciality}
                  </h3>
                </div>
              ))}
            </Carouselnew>
          )}
        </div>

        <div className="grid grid-cols-3 gap-3 block sm:hidden">
          {doctorSpecialities?.length > 0 &&
            doctorSpecialities?.map((e) => (
              <div className="text-center ">
                <div className="flex items-center justify-center">
                  <img
                    onClick={() =>
                      navigation(APP_ROUTES.ALL_DOCTORS, {
                        state: { id: "docsp", name: e?.speciality },
                      })
                    }
                    className="border-2 rounded-full cursor-pointer w-12"
                    src={e?.images}
                    alt={"img"}
                  ></img>
                </div>
                <h3 className="text-[14px] font-semibold font-poppins">
                  {e?.speciality}
                </h3>
              </div>
            ))}
        </div>
      </div>

      <div className="bg-[#f0fbff] md:pb-0 md:mt-11">
        <div className="md:flex flex-col sm:px-8 px-5">
          <h2 className="md:text-[40px] text-xl font-poppins font-[300] pt-[30px]">
            {doctorPageContent?.data[0]?.attributes?.scndcomp?.heading?.substring(
              0,
              30
            )}
          </h2>
          <div className="md:flex">
            <h3 className="md:text-[50px] text-2xl font-poppins font-medium text-newgreen w-[60rem] sm:mt-6 ">
              {doctorPageContent?.data[0]?.attributes?.scndcomp?.heading?.substring(
                30,
                doctorPageContent?.data[0]?.attributes?.scndcomp?.heading
                  ?.length
              )}
            </h3>
            <div className=" w-full flex flex-col justify-center align-middle ">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
        </div>
        <div className="sm:mx-8 mx-5 flex justify-between sm:my-10  mt-5">
          <div className="">
            <div className="w-full sm:hidden">
              <img
                src={
                  doctorPageContent?.data[0]?.attributes?.scndcomp?.image_url
                }
                className="w-full h-[330px]  rounded-tl-[80px]"
              ></img>
            </div>

            <p className="pb-5 font-poppins font-normal md:text-[18px] text-base ">
              {doctorPageContent?.data[0]?.attributes?.scndcomp?.description}
            </p>

            <div className="flex">
              <div className="border-r-2 pr-8">
                <p className="md:text-4xl text-2xl text-[#0B4881]  font-bold ">
                  {
                    doctorPageContent?.data[0]?.attributes?.scndcomp?.record[0]
                      ?.label
                  }
                </p>
                <p className="font-poppins font-normal">
                  {
                    doctorPageContent?.data[0]?.attributes?.scndcomp?.record[0]
                      ?.label1
                  }
                </p>
              </div>
              <div className="pl-8 ">
                <p className="md:text-4xl text-2xl text-[#0B4881] font-bold">
                  {
                    doctorPageContent?.data[0]?.attributes?.scndcomp?.record[1]
                      ?.label
                  }
                </p>
                <p className="font-poppins font-normal">
                  {
                    doctorPageContent?.data[0]?.attributes?.scndcomp?.record[1]
                      ?.label1
                  }
                </p>
              </div>
            </div>
            <div className="flex pt-7">
              <p className="font-poppins md:text-[34px] text-lg pr-2 sm:pt-1 pt-0">
                {doctorPageContent?.data[0]?.attributes?.scndcomp?.heading2?.substring(
                  0,
                  22
                )}
              </p>
              <p className="md:text-[43px] text-xl font-poppins  text-newgreen ">
                {doctorPageContent?.data[0]?.attributes?.scndcomp?.heading2?.substring(
                  22,
                  doctorPageContent?.data[0]?.attributes?.scndcomp?.heading2
                    ?.lentgth
                )}
              </p>
            </div>
            <div className="flex items-end py-10">
              <div className="flex flex-col pr-8 ">
                <p className="pb-3 font-semibold md:text-xl text-lg font-poppins ">
                  {" "}
                  {
                    doctorPageContent?.data[0]?.attributes?.scndcomp?.content[0]
                      ?.duration
                  }
                </p>
                <img
                  src={
                    doctorPageContent?.data[0]?.attributes?.scndcomp?.content[0]
                      ?.image_url
                  }
                  alt="graph"
                />
                <p className="pt-3 font-semibold md:text-xl text-lg  font-poppins">
                  {
                    doctorPageContent?.data[0]?.attributes?.scndcomp?.content[0]
                      ?.doctortype
                  }
                </p>
              </div>
              <div className="flex flex-col ">
                <p className="pb-3 font-semibold md:text-xl text-lg font-poppins">
                  {
                    doctorPageContent?.data[0]?.attributes?.scndcomp?.content[1]
                      ?.duration
                  }
                </p>
                <img
                  src={
                    doctorPageContent?.data[0]?.attributes?.scndcomp?.content[1]
                      ?.image_url
                  }
                  alt="graph2"
                />
                <p className="pt-3 font-semibold md:text-xl text-lg  font-poppins">
                  {
                    doctorPageContent?.data[0]?.attributes?.scndcomp?.content[1]
                      ?.doctortype
                  }
                </p>
              </div>
            </div>
          </div>
          <div className="w-7/12 hidden md:block">
            <img
              src={doctorPageContent?.data[0]?.attributes?.scndcomp?.image_url}
              className="w-full h-[330px]  rounded-tl-[80px]"
            ></img>
          </div>
        </div>
      </div>
      {screen > 500 ? (
        <Footer />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </div>
  );
};

export default Doctors;
