import { ReactChild, useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { fetchOurGallery, fetchOurgallery } from '../../store/actions/aboutus';
import { fetchOurvideos } from '../../store/actions/aboutus';
import Footer from '../homepage/footer';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import MobileFooter from './../homepage/mobileFooter';

const OurGallery = () => {
  const [value, setValue] = useState('1');
  const [gallery, setgallery] = useState();
  const [galleryImages, setGalleryImages] = useState([]);
  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  useEffect(() => {
    async function fetchOurphotos() {
      const gallery = await fetchOurgallery();
      if (gallery.status === 200 && gallery?.data) {
        setgallery(gallery?.data);
      };
    }
    async function fetchOurgallery() {
      let res = await fetchOurGallery();
      if (res?.status === 200 && res?.data?.data?.[0].attributes) {
        setGalleryImages(res.data.data[0].attributes);
      };
    }
    fetchOurgallery();
    fetchOurphotos();
  }, []);
  const handleMaxWidthChange = (event) => {
    setMaxWidth(
      // @ts-expect-error autofill of arbitrary value is not handled.
      event.target.value,
    );
  };

  const handleFullWidthChange = (event) => {
    setFullWidth(event.target.checked);
  };

  const [video, setVideo] = useState();
  useEffect(() => {
    async function fetchVideos() {
      const video = await fetchOurvideos();
      if (video.status === 200 && video?.data) {
        setVideo(video?.data);
        console.log(video)
      };

    }
    fetchVideos();
  }, []);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = useState(false);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [selectedImage, setSelectedImage] = useState(null);
  const [openvideo, setOpenvideo] = useState(false);
  const [selectedvideo, setSelectedvideo] = useState(null);
  const handleClickOpen = (value) => {
    setSelectedImage(value);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickVideo = (value) => {
    setSelectedvideo(value);
    setOpenvideo(true);
  };
  const handleClosevideo = () => {
    setOpenvideo(false);
  };
  return (
    <div className="h-screen w-full user-select-none">
      <div className='mainDiv'
        style={{
          marginTop: '0.75%',
          display: 'flex',
          justifyContent: 'flex-start',
          backgroundImage: `url(${galleryImages?.firstComp?.ImageUrl})`,
          backgroundPositionX: 'right',
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          height: '47%'
        }}>
        <div id='subDiv_01'
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            paddingLeft: '60px',
            maxWidth: '100%',
            height: '100%',
            background: `linear-gradient(90deg, rgb(255, 255, 255) 53.97%, rgba(255, 255, 255, 0.39) 64.54%, rgba(255, 255, 255, 0) 80.95%)`,
          }}
        >
          <div className="container mx-auto  px-4">
            <div className=" w-32 h-8   bg-light-sky  m-1 py-2 px-2 rounded-md">
              <p className='text-base md:text-sm  text-center md:font-normal text-chathams-blue'> {galleryImages?.firstComp?.name}</p>
            </div>
            <div className='w-7/12 p-3 my-3  '>
              <p className='md:text-4xl md:font-bold leading-54px text-chathams-blue-500 '> {galleryImages?.firstComp?.heading}</p>
            </div>
            <div className='w-7/12 h-22 m-1 p-3 '>
              <p className='text-lg font-semibold leading-7 text-chathams-blue-500 '>{galleryImages?.firstComp?.description}</p>
            </div>
          </div>

        </div>
      </div>
      <div className='flex flex-col items-center justify-center pt-16'>
        <div className='flex justify-center bg-light-sky text-chathams-blue text-base w-28 h-8 items-center   '>Our Gallery</div>
        <p className='flex justify-center text-4xl w-4/5 font-bold text-justify pt-4 text-chathams-blue'>Take a look at our e-clinic facility</p>
      </div>


      <Box sx={{ width: '100%', typography: 'body1', margin: '0rem' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', paddingLeft: '4rem ' }}>
            <TabList onChange={handleChange}
              sx={{
                '& .Mui-selected': {
                  color: '#66B889 !important',
                }

              }}
              TabIndicatorProps={{
                style: { backgroundColor: "#66B889" },
              }}
            >
              <Tab label="Images" value="1" />
              <Tab label="Video" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className='grid grid-cols-3 gap-y-8 justify-items-stretch gap-x-8 '>
              {galleryImages?.SecondComp?.Image.map((value) => (
                <div className='contents'>
                  <img className='hover:border-4 border-royal-blue' src={value?.label} alt={value?.label} onClick={() => handleClickOpen(value)}></img>
                </div>
              ))
              }
              <Dialog
                fullWidth={fullWidth}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}

                open={open}
                onClose={handleClose}
              >
                <DialogTitle><div className='flex justify-between'><p>IMAGE</p>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClose}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                </DialogTitle>
                <DialogContent>
                  <div className='flex justify-center items-stretch justify-items-stretch'>
                    <img className='w-full' src={selectedImage?.label} alt='@' />
                  </div>
                </DialogContent>
              </Dialog>
            </div>

          </TabPanel>
          <TabPanel value="2">
            <div className='grid grid-cols-3 gap-y-10 gap-x-20 justify-items-stretch'>

              {galleryImages?.SecondComp?.Video.map((item) => (
                <iframe
                  src={item?.label}
                  onClick={() => handleClickOpen(item)}
                // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                // allowFullScreen
                // title="Embedded youtube"
                />
              ))
              }
              <Dialog
                fullWidth={fullWidth}
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}

                open={openvideo}
                onClose={handleClosevideo}
              >
                <DialogTitle><div className='flex justify-between'><p>video</p>
                  <IconButton
                    edge="start"
                    color="inherit"
                    onClick={handleClosevideo}
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                </DialogTitle>
                <DialogContent>
                  <div className='flex justify-center items-stretch justify-items-stretch'>
                    <iframe
                      src={selectedvideo?.attributes?.url}
                    />
                  </div>
                </DialogContent>
              </Dialog>
            </div>

          </TabPanel>
        </TabContext>
      </Box>
      {screen >500 ?<Footer/>:<MobileFooter />}
    </div>
  )
}

export default OurGallery
