import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDoctorslist } from "../../store/actions/homepage";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  FormControl,
  Grid,
  Icon,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  SvgIcon,
  TablePagination,
  Typography,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import DoctorImage from "./image/DoctorImge.png";
import DoctorProfile from "./doctorProfile";
import DefaultDoctorImage from "../../assets/images/defaultDoctorImage.png";
import noDataFound from "../../assets/images/No data-found.svg";
import videoimg from "./image/videoimg.svg";
import docytIcon from "../careplans/image/docIcon.svg";
import Footer from "./footer";
import { APP_ROUTES } from "../../constants/approutes";
import Image from "../../assets/images/image.png";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "./FooterMobileVersion";

const SeeAllDoc = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [freeTextSearch, setFreeTextSearch] = useState(location?.state?.name);
  const [fromDoc, setFromDoc] = useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(100);

  const coords = { latitude: 20.2961, longitude: 85.8245 };
  const [doctorsData, setDoctorsData] = useState(null);
  let eliteDoc = [];
  const doctorsList = useSelector((state) => state.HomePageReducer.doctorsList);
  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    setDoctorsData(doctorsList?.length ? doctorsList : "");
  }, [doctorsList]);

  useEffect(() => {
    if (location?.state?.id === "docsp" && fromDoc) {
      setFreeTextSearch(location?.state?.name);
    } else {
      window.history.replaceState({}, "", APP_ROUTES.ALL_DOCTORS);
    }
  }, [location]);
  console.log(location?.state?.name, "location");

  useEffect(() => {
    if (freeTextSearch?.length > 2 || doctorsData === null) {
      dispatch(getDoctorslist(coords, freeTextSearch, page + 1, rowsPerPage));
    }
  }, [dispatch, freeTextSearch, rowsPerPage, page]);
  console.log(coords, freeTextSearch, page + 1, rowsPerPage, "freetextnew");

  // for (let i = 0, j = 0; i < doctorsData?.length; i++) {
  //     if (doctorsData[i]?.doctorType === 'E')
  //         eliteDoc[j++] = doctorsData[i];
  // }

  const viewprofile = (e, doct) => {
    e.preventDefault();
    navigate(`/doctors/${doct.userId}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setDoctorsData(null);
  };
  const [expand, setExpand] = useState(false);
  const handleExpand = () => {
    setExpand(!expand);
  };
  const Description = ({ value }) => {
    const [expand, setExpand] = useState(false);
    const handleExpand = () => {
      setExpand(!expand);
    };
    return (
      <div>
        <p
          className="font-semibold text-xl my-3 text-seegreen"
          onClick={handleExpand}
        >
          About
        </p>
        {/* {expand ? <p className="font-medium text-lg">{value}</p> : <></>} */}
        <p className="font-medium text-lg">{value}</p>
      </div>
    );
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    setDoctorsData(null);
  };

  const SearchChange = (e) => {
    setFromDoc(false);
    if (e?.target?.value?.length === 0) {
      setDoctorsData(null);
      setFreeTextSearch("");
    } else {
      setFreeTextSearch(e.target.value);
    }
  };

  return (
    <div className="select-none xs-overflow-x-hidden  md:absolute md:top-10 w-full md:w-[87.5rem]">
      <div
        className="mainDiv"
        style={{
          // display: 'flex',
          // justifyContent: 'flex-start',
          backgroundImage: `url("${Image}")`,
          backgroundSize: "contain",
          // height: '386px',
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
          // backgroundColor: "#f0f4f6",
        }}
      >
        <div
          id="px-8"
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "center",
            paddingLeft: "40px",
            // maxWidth: '100%',
            height: "100%",
          }}
        >
          <div className="container mx-auto sm:pr-36 sm:pt-0 pt-36">
            {/* <div className=" w-200 h-8 bg-shakespeare bg-opacity-[8%] m-1 py-2 px-2 rounded-md">
                                <p className='text-14px md:text-sm  text-center md:font-normal text-chathams-blue-500 whitespace-nowrap	'>{data && data.FirstComponent?.name || ''}</p>
                            </div> */}
            <div className="bg-transparent sm:mt-[7rem]  ">
              {/* <p className='md:text-40px md:font-bold leading-54px text-chathams-blue-500'>{data && data.FirstComponent?.title || ''}</p> */}
              <p className=" md:text-40px text-2xl font-semibold sm:leading-54px md:leading-36px sm:w-5/12 text-newblue font-poppins">
                Simplify your healthcare
              </p>
              <h1 className="font-light md:text-40px text-2xl md:leading-54px font-poppins text-newblue md:w-8/12 pb-2 sm:pb-7 ">
                {" "}
                with multiple specialities under one roof.
              </h1>
              <h1 className="md:text-16px text-base font-normal  sm:text-base md:leading-[30.5px] font-poppins md:w-7/12 md:mb-24 ">
                With CureBay, you gain access to a vast network of highly
                skilled specialists. From cardiology to orthopedics,
                Diabetologists to Gynaecology, we have assembled a team of
                experts to address your parent's unique health concerns. Whether
                you're seeking preventive care or managing a chronic condition,
                our multidisciplinary approach ensures personalized, top-notch
                care across all specialties.
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto flex flex-col items-center justify-center">
        <p className="md:text-38px mt-4 md:font-bold leading-54px text-center text-chathams-blue-500 ">
          All available doctors
        </p>

        <FormControl fullWidth sx={{ m: 1, width: "50%" }}>
          <OutlinedInput
            id="outlined-adornment-amount"
            startAdornment={
              <InputAdornment position="start">
                <Icon sx={{ color: "#414141" }}>search</Icon>
              </InputAdornment>
            }
            placeholder={"Search Speciality / Doctors"}
            value={freeTextSearch}
            onChange={(e) => SearchChange(e)}
          />
        </FormControl>
      </div>

      <div className="container mx-auto flex flex-col ">
        {doctorsData?.length > 0 &&
          doctorsData.map((value, index) => (
            <div
              className="flex flex-col justify-center items-center"
              key={index}
            >
              <Card
                sx={{ width: "95%", margin: "2rem", padding: "2rem" }}
                variant="outlined"
              >
                <div className="flex flex-col sm:flex-row sm:justify-between border-b-2 pb-6">
                  <div className="flex">
                    <img
                      className="sm:w-40 w-32 sm:rounded-lg rounded-sm"
                      src={
                        value?.userPhoto
                          ? `${process.env.REACT_APP_IMAGE_URL}${value.userPhoto}`
                          : DefaultDoctorImage
                      }
                      alt="img"
                    ></img>
                    <div className="sm:m-4 ml-4 ">
                      <p className="sm:text-xl text-base font-bold  text-start text-chathams-blue-500 sm:mb-4 mb-2 whitespace-nowrap">
                        {" "}
                        Dr. {value?.userName}
                      </p>
                      <p className=" text-seegreen flex flex-row items-center text-base font-semibold whitespace-nowrap">
                        <span>
                          <img
                            src={docytIcon}
                            draggable={false}
                            alt="img"
                            className="h-[26px] my-1 mr-1"
                          ></img>
                        </span>
                        {value?.specialities}
                      </p>
                      <p className="text-base font-medium text-start text-black my-1">
                        {value?.yearOfExperience} years of Experience
                      </p>
                      <p className="text-base font-medium text-start text-black my-1">
                        {value?.city}
                      </p>
                    </div>
                  </div>

                  <div className=" font-semibold items-end flex sm:flex-col flex-row">
                    <div className="flex -mb-3 sm:mb-0">
                      <img className="h-10 " src={videoimg}></img>
                    </div>
                    <div className="flex">
                      <p className="sm:text-base text-sm ">
                        Video Consultation: ₹{value?.videoConsultCharges}
                      </p>
                    </div>
                  </div>
                </div>
                <Description value={value?.description} />
              </Card>
            </div>
          ))}

        {doctorsData?.length === 0 && (
          <div className="w-full flex flex-col items-center justify-around my-20">
            <img
              className="h-24 lg:h-48 mt-5 lg:mt-0 "
              src={noDataFound}
              alt="No Diagnostics Appointments Available"
            />

            <h4 className="font-medium font-poppins text-brand-lightgreen text-xl">
              Sorry, we don't have a specialist right now but we will be adding
              soon.
            </h4>
          </div>
        )}
        {/* 
                <div className='container mx-auto flex flex-col items-center justify-center'>
                    <TablePagination
                        component="div"
                        count={75}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    // sx={{ "& 	.MuiTablePagination-selectLabel": { fontSize: "40px", fontWeight: 400 } }}
                    />
                </div> */}
      </div>
      {screen > 500 ? (
        <Footer i={1} />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
    </div>
  );
};

export default SeeAllDoc;
