import React, { useState, useEffect } from "react";
import { ArrowForwardIos } from "@mui/icons-material";
import DashboardSidemenu from "./dashboard-sidemenu";
import {
  Button,
  Card,
  Chip,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import location from "../homepage/image/location.footer.svg";
import { Icon, IconButton } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Footer from "../../components/webflow/postloginfooter";
import {
  fetchCarePlan,
  fetchUserList,
  schedule,
} from "../../store/actions/dashboard";
import mydetailsservice from "../../store/services/mydetailsservice";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../constants/approutes";

const Schedule = () => {
  const navigate = useNavigate();
  const [selectedDates, setSelectedDates] = useState();
  const handleDateChange = (date, index) => {
    const updatedDates = [...selectedDates];
    updatedDates[index] = { date: dayjs(date).format("YYYY-MM-DD 00:00:00") };
    setSelectedDates(updatedDates);
  };
  const loginObjStr = localStorage.getItem("loginObj");
  const loginObj = JSON.parse(loginObjStr);
  // const userObj = loginObj?.[0];
  // const userCode = userObj?.userCode
  //   ? userObj.userCode
  //   : userObj?.parentCode
  //   ? userObj.parentCode
  //   : null;
  const userObj = loginObj?.[0];
  const [userCode, setuserCode] = useState(
    userObj?.code
      ? userObj.code
      : userObj?.parentCode
        ? userObj.parentCode
        : userObj?.userCode
          ? userObj.userCode
          : null
  );

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [value, setValue] = React.useState("1");
  const [screen, setscreen] = useState(window.innerWidth);
  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  const filePath = `${process.env.REACT_APP_IMAGE_URL}`;

  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  const handleDateRange = (dateRange) => {
    setStartDate(dateRange[0]?.$d);
    setEndDate(dateRange[1]?.$d);
  };

  const [age, setAge] = React.useState(0);
  const [planId, setPlanId] = React.useState(1);
  const [users, setUsers] = React.useState();
  const [user, setUser] = useState();
  const [care, setCare] = useState();
  const [scheduledata, setScheduleData] = useState();

  const [labData, setlabData] = useState();

  const breadcrumbItems = [
    { label: "Home", onClick: () => navigate(APP_ROUTES.DASHBOARD) },
    { label: "Dashboard", onClick: () => navigate(APP_ROUTES.MAINDASHBOARD) },
    { label: "Schedule" },
  ];

  const handleChange = (event) => {
    setAge(event.target.value);
    getOverAll(event.target.value);
  };


  const handleChange2 = (event) => {
    setPlanId(event.target.value);
  };
  useEffect(() => {
    async function fetchUserDetails() {
      const user = await fetchUserList({
        // code: userCode,
        code: userObj?.userCode
          ? userObj.userCode
          : userObj?.parentCode
            ? userObj.parentCode
            : null,
        email: userObj?.email,
        mobile: userObj?.mobile,
      });

      if (user.status === 200 && user?.data) {
        const temp = user?.data?.succesObject?.filter(
          (p) => Object.keys(p).length !== 0
        );
        setUsers(temp);
        setAge(temp[0]?.code);

        let first = temp;
        getFirstPatient(first);
        // setUser(user?.data?.succesObject[age]?.code)
      }
    }
    fetchUserDetails();
  }, []);



  // useEffect(() => {
  //   // const temp = user?.data?.succesObject?.filter(p => (Object.keys(p).length !== 0))
  //   async function fetchScheduleData() {
  //     const subscriptionDetails = care?.succesObject?.find(
  //       (option) => option.id === planId
  //     );
  //     const res = await schedule({
  //       patientCode: age,
  //       subSeqId: subscriptionDetails?.subscriptionId,
  //       planId: planId,
  //     });

  //     if (res?.status === 200 && res?.data) {
  //       setScheduleData(res?.data);
  //     }
  //   }
  //   fetchScheduleData();
  // }, [age, planId,]);
  useEffect(() => {
    if (!care || !planId || !age) {
      return;
    }
    async function fetchScheduleData() {
      const subscriptionDetails = care?.succesObject?.find(
        (option) => option.id === planId
      );
      if (!subscriptionDetails) {
        return;
      }

      const res = await schedule({
        patientCode: age,
        subSeqId: subscriptionDetails.subscriptionId,
        planId: planId,
      });

      if (res?.status === 200 && res?.data) {
        setScheduleData(res?.data);
      }
    }

    fetchScheduleData();
  }, [care, planId, age]);


  const months = [
    "January",

    "February",

    "March",

    "April",

    "May",

    "June",

    "July",

    "August",

    "September",

    "October",

    "November",

    "December",
  ];

  const sortedScheduleList =
    scheduledata?.succesObject?.upcomingScheduleList.sort((a, b) => {
      if (a.transactionYear !== b.transactionYear) {
        return a.transactionYear - b.transactionYear;
      } else {
        return a.transactionMonth - b.transactionMonth;
      }
    });

  console.log(sortedScheduleList, "sirt");

  useEffect(() => {
    async function fetchCares() {
      const res = await fetchCarePlan({
        // userCode: userCode,
        userCode: userObj?.userCode
          ? userObj.userCode
          : userObj?.parentCode
            ? userObj.parentCode
            : null,
        patientcode: age,
      });
      if (res?.status === 200 && res?.data) {
        setCare(res?.data);
        setPlanId(res?.data?.succesObject[0]?.id);
      }
    }
    fetchCares();
  }, [age]);



  console.log(labData, "coming");

  const getFirstPatient = (e) => {
    if (userObj?.userCode) {
      setAge(e[0].code);
      getOverAll(e[0].code);
    } else {
      setAge(userCode);
      getOverAll(userCode);
    }
  };

  useEffect(() => {
    getOverAll("");
  }, []);
  const getOverAll = (e) => {
    let payload;
    // let data = JSON.parse(userData);
    // console.log(data)
    if (e == "") {
      payload = {
        // "patientId": data?.[1]?.patientCode ? data[1].patientCode : null,
        patientId: userCode,
        docmentRequired: "Y",

        //         "userCode": userCode,
        //  "patientCode": userObj.code,
      };
    } else {
      payload = {
        // "patientId": data?.[1]?.patientCode ? data[1].patientCode : null,
        patientId: e,
        docmentRequired: "Y",

        //         "userCode": userCode,
        //  "patientCode": userObj.code,
      };
    }
    // if (data?.[1]?.patientCode) {
    mydetailsservice
      .getmylablist(payload)
      .then((res) => {
        setlabData(res?.data?.succesObject);
      })
      .catch((error) => {
        console.error("Failed to fetch lab reports:", error);
      });
  };
  const downloadReport = (e) => {
    let payload = {
      fileName: e.labReportDoc,
    };
    mydetailsservice
      .downloadLabDoc(payload)
      .then((res) => {
        let payload2 = {
          fileData: res?.data?.succesObject.fileData,
          fileName: "Test",
        };
        // Get the base64 string from your API response
        const base64String = payload2.fileData;

        // Convert the base64 string to a Blob object

        // Convert the base64 string to a Blob object
        const base64PdfContent = base64String;
        const link = document.createElement("a");
        link.href = `data:application/pdf;base64,${base64PdfContent}`;
        link.download = "document.pdf"; // Specify the desired filename for download
        link.click();
      })
      .catch((error) => {
        console.error("Failed to fetch lab reports:", error);
      });
  };

  const groupedData = {};

  sortedScheduleList?.forEach((item) => {
    const { transactionYear, transactionMonth } = item;
    const key = `${transactionYear}-${transactionMonth}`;

    if (!groupedData[key]) {
      groupedData[key] = [];
    }

    groupedData[key].push(item);
  });

  console.log(groupedData, "ntset");

  // Convert the groupedData object into an array
  const groupedArray = Object.values(groupedData);

  console.log(groupedArray, "setas");

  const Accordion = ({ title, content }) => {
    console.log(content, "aa");

    const Accordion1 = ({ data }) => {
      const [activeIndices, setActiveIndices] = useState([]);

      const toggleAccordion = (index) => {
        if (activeIndices.includes(index)) {
          setActiveIndices(activeIndices.filter((i) => i !== index));
        } else {
          setActiveIndices([...activeIndices, index]);
        }
      };

      return (
        <>
          {data?.map((value, index) => (
            <div
              className="border-2 rounded-10px w-full m-auto mt-5 bg-white"
              key={index}
            >
              <div
                className="flex cursor-pointer justify-between items-center p-3 md:p-2"
                onClick={() => toggleAccordion(index)}
              >
                <h2 className="md:w-11/12 md:text-[18px] text-sm font-poppins text-chathams-blue-500 font-semibold">
                  {value[0]?.serviceMonth} {value[0]?.serviceYear}
                </h2>
                <div className="flex justify-end text-3xl font-semibold text-brand-primary md:w-96">
                  <IconButton size="medium" sx={{ backgroundColor: "#E4FAFF" }}>
                    <Icon fontSize="inherit" color="#262626">
                      {activeIndices.includes(index)
                        ? "keyboard_arrow_up"
                        : "keyboard_arrow_down"}
                    </Icon>
                  </IconButton>
                </div>
              </div>
              {activeIndices.includes(index) &&
                value?.map((value1, innerIndex) => (
                  <div
                    key={innerIndex}
                    variant="outlined"
                    elevation={1}
                    sx={{ width: "100%", borderRadius: "5px" }}
                    className="border mx-4 mb-3 rounded-md"
                  >
                    <div className="m-3 ">
                      <div className="flex ">
                        <div className="sm:m-5">
                          <h1 className="font-poppins text-lg font-bold">
                            Service Name:
                            <span className="pt-2 mb-2 font-semibold text-seegreen text-lg">
                              {" "}
                              {value1.serviceName}
                            </span>
                          </h1>
                          <h1 className="font-poppins text-lg font-bold">
                            Service Mode:{" "}
                            <span className="pt-2 mb-2 font-semibold text-seegreen text-lg">
                              {" "}
                              {value1.serviceMode}
                            </span>
                          </h1>

                          <div className="flex flex-col sm:flex-row mb-3">
                            <h6 className="pr-5 pt-1 font-poppins text-lg font-bold">
                              Frequency-{" "}
                              <span className="font-medium pt-1">
                                {" "}
                                {value1?.frequency}
                              </span>{" "}
                            </h6>

                            <h6 className="pt-1  font-poppins font-bold">
                              Status- &nbsp;
                              {value1.status === "Completed" ? (
                                <span className="bg-green-100 py-1 text-green-400 px-2 ml-1 text-see">
                                  {value1.status}
                                </span>
                              ) : value1.status === "Yet to Start" ? (
                                <span className="bg-orange-100 py-1 text-orange-400 px-2 ml-1 text-see">
                                  {value1.status}
                                </span>
                              ) : value1.status === "Appointment Booked" ? (
                                <span className=" py-1  px-2 ml-1 bg-[#8eadd196] text-[#4d9cf8]">
                                  {value1.status}
                                </span>
                              ) : value1.status === "Request for Update" ? (
                                <span className=" py-1  px-2 ml-1 bg-yellow-100 text-yellow-300">
                                  {value1.status}
                                </span>
                              ) : value1.status === "Cancelled" ||
                                value1.status === "Expired" ? (
                                <span className=" py-1  px-2 ml-1 bg-red-300 text-red-500">
                                  {value1.status}
                                </span>
                              ) : (
                                ""
                              )}
                            </h6>
                          </div>
                          <div
                            variant="outlined"
                            elevation={1}
                            sx={{ borderRadius: "5px" }}
                            className="border"
                          >
                            <div className="p-3 font-poppins font-medium">
                              {value1?.serviceDescription}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </>
      );
    };

    const [isActive, setIsActive] = useState(true);
    return (
      <div className="border-2 rounded-10px  w-full m-auto mt-5 bg-white  px-5 py-3">
        <div
          className="flex cursor-pointer justify-between items-center"
          onClick={() => setIsActive(!isActive)}
        >
          <h2
            className={`md:w-11/12  md:text-[22px] text-sm font-poppins  text-chathams-blue-500  font-semibold `}
          >
            {title}
          </h2>
          <div className="flex justify-end text-3xl font-semibold  text-brand-primary md:w-96">
            <IconButton size="medium" sx={{ backgroundColor: "#E4FAFF" }}>
              {isActive ? (
                <Icon fontSize="inherit" color="#262626">
                  keyboard_arrow_up
                </Icon>
              ) : (
                <Icon fontSize="inherit" color="#262626">
                  keyboard_arrow_down
                </Icon>
              )}
            </IconButton>
          </div>
        </div>
        {isActive && <Accordion1 data={content} />}
      </div>
    );
  };

  return (
    <>
      <div className="p-8">
        <div className="flex items-center mb-5 mt-[100px]">
          {breadcrumbItems.map((item, index) => (
            <React.Fragment key={index}>
              <span className="text-base font-normal leading-7 text-chathams-blue-500 cursor-pointer">
                {item.onClick ? (
                  <a onClick={item.onClick}>{item.label}</a>
                ) : (
                  <a>{item.label}</a>
                )}
              </span>
              {index !== breadcrumbItems.length - 1 && (
                <ArrowForwardIos
                  sx={{ fontSize: 15 }}
                  className="text-chathams-blue-500 mx-2"
                />
              )}
            </React.Fragment>
          ))}
        </div>

        <div className="flex scroll-px-2 ">
          <div className="w-[300px] hidden md:block h-max mr-6  border items-start justify-center rounded-md ">
            <DashboardSidemenu />
          </div>
          <div className="w-full">
            <div className="flex flex-col sm:flex-row justify-start  w-full sm:w-[]">
              <div className="flex flex-col sm:mr-4">
                <span className="text-chathams-blue font-semibold text-base">
                  Member Profile
                </span>
                <Select
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "2 rem",
                    width: "13rem",
                    width: "100%",
                    boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                  }}
                  className="sm:w-[13 rem] w-[240px]"
                >
                  {users &&
                    users?.map((value, index) => (
                      <MenuItem value={value.code} key={index}>
                        <span className="text-base font-semibold">{value.name}</span>
                      </MenuItem>
                    ))}
                </Select>




              </div>
              <div className="flex flex-col  ">
                <span className="text-chathams-blue font-semibold text-base">
                  Careplans
                </span>
                <Select
                  value={planId}
                  onChange={handleChange2}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    height: "2 rem",
                    width: "13rem",
                    width: "100%",
                    boxShadow: "0 2px 28px rgba(0,0,0,.1)",
                  }}
                  className="sm:w-[13 rem] w-[240px]"
                >
                  {care &&
                    care.succesObject?.map((value, index) => (
                      <MenuItem value={value.id} key={index}>
                        <span className="text-base font-semibold">{value.name}</span>
                      </MenuItem>
                    ))}
                </Select>
              </div>
            </div>

            <Accordion title={"Schedule for a year"} content={groupedArray} />

            {/* <div className="w-full ">
              <span> className="text-chathams-blue font-semibold text-xl mb-2 mt-4">
                Doctor Consultation
              </span>

              <div className="w-full mb-4 ">
                <Card
                  variant="outlined"
                  elevation={1}
                  sx={{
                    width: "100%",
                    padding: "2%",
                    borderRadius: "5px",
                    gap: "2rem",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  {scheduledata &&
                  scheduledata?.succesObject?.doctorAppointmentList?.length >
                    0 ? (
                    <>
                      {scheduledata &&
                        scheduledata?.succesObject?.doctorAppointmentList?.map(
                          (value, index) => (
                            <Card
                              variant="outlined"
                              elevation={1}
                              key={index}
                              sx={{ width: "100%", borderRadius: "5px" }}
                            >
                              <div className="m-3 ">
                                <h1 className="pb-3 font-poppins text-lg font-bold">
                                  Dates
                                </h1>
                                <div className="flex border-b-2 pb-3 ">
                                  <AccessTimeIcon />
                                  <h1 className="px-2 ">
                                    {value?.whenAppointment}
                                  </h1>
                                  <li>
                                    {value?.fromTime} - {value?.toTime}
                                  </li>
                                </div>

                                <div className="flex sm:flex-row flex-col sm:my-4 sm:ml-4">
                                  <img
                                    className="sm:w-32 w-full h-56"
                                    src={value?.photo}
                                    alt=""
                                  ></img>
                                  <div className="my-5 ml-5">
                                    <h1 className="pt-2 mb-2 font-bold text-seegreen text-lg">
                                      Dr. {value.firstName} {value?.lastName}
                                    </h1>
                                    <div className="flex flex-col sm:flex-row mb-3">
                                      <h1 className="sm:w-1/2 pr-5 pt-1 font-poppins text-md font-bold">
                                        Frequency-{" "}
                                        <span className="font-medium pt-1">
                                          {" "}
                                          {value?.frequency}
                                        </span>{" "}
                                      </h1>

                                      <h1 className="sm:w-1/2 pt-1  font-poppins text-md font-bold">
                                        Status-
                                        {value.status === "Completed" ? (
                                          <span className="bg-green-100 py-1 text-green-400 px-2 ml-1 text-see">
                                            {value.status}
                                          </span>
                                        ) : value.status === "Yet to Start" ? (
                                          <span className="bg-orange-100 py-1 text-orange-400 px-2 ml-1 text-see">
                                            {value.status}
                                          </span>
                                        ) : value.status ===
                                          "Appointment Booked" ? (
                                          <span className=" py-1  px-2 ml-1 bg-[#8eadd196] text-[#4d9cf8]">
                                            {value.status}
                                          </span>
                                        ) : value.status ===
                                          "Request for Update" ? (
                                          <span className=" py-1  px-2 ml-1 bg-yellow-100 text-yellow-300">
                                            {value.status}
                                          </span>
                                        ) : value.status === "Cancelled" ||
                                          value.status === "Expired" ? (
                                          <span className=" py-1  px-2 ml-1 bg-red-300 text-red-500">
                                            {value.status}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          )
                        )}
                    </>
                  ) : (
                    <h1 className="h-15 p-5"> No Records Found</h1>
                  )}
                </Card>
              </div>
            </div>
            <div className="w-full mt-10">
              <span> className="text-chathams-blue font-semibold text-xl mb-2 ">
                Nursing
              </span>

              <div className="w-full mb-4 ">
                <Card
                  variant="outlined"
                  elevation={1}
                  sx={{ width: "100%", padding: "2%", borderRadius: "5px" }}
                >
                  {scheduledata &&
                  scheduledata?.succesObject?.nurseAppointmentList?.length >
                    0 ? (
                    <>
                      {scheduledata &&
                        scheduledata?.succesObject?.nurseAppointmentList?.map(
                          (value, index) => (
                            <Card
                              key={index}
                              variant="outlined"
                              elevation={1}
                              sx={{ width: "100%", borderRadius: "5px" }}
                            >
                              <div className="m-3 ">
                                <h1 className="pb-3">Dates</h1>
                                <div className="flex border-b-2 pb-3 ">
                                  <AccessTimeIcon />
                                  <h1 className="px-2 ">
                                    {value?.whenAppointment}
                                  </h1>
                                  <li>
                                    {value?.fromTime} - {value?.toTime}
                                  </li>
                                </div>
                                <div className="flex sm:flex-row flex-col">
                                  <img src={value?.photo} alt=""></img>
                                  <div className="m-5">
                                    <h1 className="pt-2 mb-2 font-bold text-seegreen text-lg">
                                      {value.salutation} {value.firstName}{" "}
                                      {value?.lastName}
                                    </h1>
                                    <div className="flex flex-col sm:flex-row  mb-3">
                                      <h1 className="pr-5 pt-1">
                                        Frequency-{" "}
                                        <span className="font-bold pt-1">
                                          {" "}
                                          {value?.frequency}
                                        </span>{" "}
                                      </h1>
                                      <h1 className="pt-1">
                                        Status-
                                        {value.status === "Completed" ? (
                                          <span className="bg-green-100 py-1 text-green-400 px-2 ml-1 text-see">
                                            {value.status}
                                          </span>
                                        ) : value.status === "Yet to Start" ? (
                                          <span className="bg-orange-100 py-1 text-orange-400 px-2 ml-1 text-see">
                                            {value.status}
                                          </span>
                                        ) : value.status ===
                                          "Appointment Booked" ? (
                                          <span className=" py-1  px-2 ml-1 bg-[#8eadd196] text-[#4d9cf8]">
                                            {value.status}
                                          </span>
                                        ) : value.status ===
                                          "Request for Update" ? (
                                          <span className=" py-1  px-2 ml-1 bg-yellow-100 text-yellow-300">
                                            {value.status}
                                          </span>
                                        ) : value.status === "Cancelled" ||
                                          value.status === "Expired" ? (
                                          <span className=" py-1  px-2 ml-1 bg-red-300 text-red-500">
                                            {value.status}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </h1>
                                    </div>
                                    <Card
                                      variant="outlined"
                                      elevation={1}
                                      sx={{
                                        width: "100%",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      
                                    </Card>
                                  </div>
                                </div>
                              </div>
                            </Card>
                          )
                        )}
                    </>
                  ) : (
                    <h1 className="h-15 p-5"> No Records Found</h1>
                  )}
                </Card>
              </div>
            </div>

            <div className="w-full mt-10">
              <span> className="text-chathams-blue font-semibold text-xl mb-2 ">
                Lab Test- Track Results
              </span>

              <Card
                variant="outlined"
                elevation={1}
                sx={{ width: "100%", borderRadius: "5px" }}
              >
                {labData && labData.length > 0 ? (
                  <div className="flex flex-col gap-3  mt-10  w-full  ">
                    {labData &&
                      labData.map((lab, index) => (
                        <Card
                          elevation={1}
                          sx={{
                            boxShadow: " 0px 10px 13px rgba(0, 0, 0, 0.04)",
                          }}
                        >
                          <div className="flex flex-col">
                            <div className="flex px-8 border-b-2 pb-5 mx-4 justify-start pt-4">
                              <div className="flex flex-col ">
                                <span> className="text-xs font-normal text-shuttle-gray-500 ">
                                  Test Name
                                </span>
                                <span> className="text-sm">{lab.labTestCode}</span>
                              </div>
                              <div className="flex flex-col pl-16">
                                <span> className="text-xs font-normal text-shuttle-gray-500 ">
                                  lab Name
                                </span>
                                <span> className="text-sm">{lab.name}</span>
                              </div>
                              <div className="flex flex-col pl-16">
                                <span> className="text-xs font-normal text-shuttle-gray-500 ">
                                  Date
                                </span>
                                <span> className="text-sm">{lab.createdDate}</span>
                              </div>
                              <div className="flex flex-col px-16">
                                <span> className="text-xs font-normal text-shuttle-gray-500 ">
                                  Order ID
                                </span>
                                <span> className="text-sm">{lab.orderId}</span>
                              </div>
                            </div>
                            <div sx={{ diplay: "flex" }}>
                              <a
                                href={filePath + "" + lab.labReportDoc}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Button
                                  variant="text"
                                  sx={{
                                    width: "20%",
                                    marginX: "0.5rem",
                                    marginY: "1rem",
                                    padding: "0",
                                    color: "#1976d2",
                                  }}
                                >
                                  <span> className="underline text-sm font-semibold capitalize">
                                    view Lab Report
                                  </span>
                                </Button>
                              </a>
                              <Button
                                variant="text"
                                sx={{
                                  width: "20%",
                                  marginX: "0.5rem",
                                  marginY: "1rem",
                                  padding: "0",
                                  color: "#1976d2",
                                }}
                              >
                                <span>
                                  className="underline text-sm font-semibold capitalize"
                                  onClick={(e) => downloadReport(lab)}
                                >
                                  Download
                                </span>
                              </Button>
                            </div>
                          </div>
                        </Card>
                      ))}
                  </div>
                ) : (
                  <h1 className="h-15 p-5"> No Records Found</h1>
                )}
              </Card>
            </div> */}
          </div>
        </div>
      </div>
      {screen > 500 ? (
        <Footer i={1} />
      ) : (
        <>
          <FooterMobileVersion />
          <MobileFooter />
        </>
      )}
      {/* <Footer i={1} /> */}
    </>
  );
};

export default Schedule;
