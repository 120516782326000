import services from './index.jsx';
class AbouUs {
    getOurAssistedDetails() {
        return services.get("about-us-our-assisted-care-methods")
    };
    getPhotos() {
        return services.get("our-gallery-images")
    };
    getVideos() {
        return services.get("our-gallery-videos")
    };
    fetchAboutUsDetails() {
        return services.get("/about-us-medicares?populate=deep")
    }
    fetchAboutUsGallery() {
        return services.get("gallery-pages?populate=deep")
    }

}
export default new AbouUs();