import doctorsservices from "../../services/doctorsservices";


export const getDoctorsSliderDetails = async () => {
    const res = await doctorsservices.getDoctorsSliderDetails();
    return res;
};

export const getAllSpecialities = async () => {
    const res = await doctorsservices.getAllSpecialities();
    return res;
};
export const getDoctorslist = async () => {
    const res = await doctorsservices.getDoctorslistByCoOrd();
    return res;
};
export const fetchDoctorsData = async () => {
    const res = await doctorsservices.getDoctorsData();
    return res;
};
