import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { exportComparePlan, fetchCarePlan, getBenifits, getHowItWorksAction, healtchCareService } from '../../store/actions/careplans'
import envOutlined from "./image/envOutlined.svg"
import { SubTitle } from '../common/index'
import ExploreHealthCare from '../common/exploreotherhealthcare'
import { getOurCareplans } from '../../store/actions/howitworks';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Icon, IconButton, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Stack } from '@mui/system'
import Footer from '../homepage/footer'
import FAQ from '../common/faq'
import { fetchWhyOurPlans } from '../../store/actions/careplans'
import { fetchOurHealthSol } from '../../store/actions/careplans'
import { APP_ROUTES } from '../../constants/approutes';
import { useNavigate } from 'react-router-dom';

import benifit from './image/benifit.png'

const CarePlans = () => {

    const [diabeticsCarePackage, setDiabeticsCarePackage] = useState([]);
    const [compareHealth, setCompareHealthCare] = useState([]);
    const [carePlans, setCarePlans] = useState([])
    const navigate = useNavigate();
    const [benefits, setBenefits] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isLoggedIn, setIsloggedIn] = useState(null);
    const [more, setMore] = useState(4)

    const handleDialogClose = () => {
        setDialogOpen((e) => !e)
    }

    const dispatch = useDispatch()
    useEffect(() => {
        async function fetchData() {
            let CareplanId = 1
            const comparePlanResponse = await exportComparePlan(CareplanId);
            if (comparePlanResponse?.data?.responseCode === '200' && comparePlanResponse?.data?.responseMessage === 'Success') {
                setCompareHealthCare(comparePlanResponse?.data?.succesObject);
            };
            const benefitsResponse = await getBenifits();
            if (benefitsResponse?.status === 200 && benefitsResponse?.data?.data) {
                setBenefits(benefitsResponse.data.data[0]);
            }
            const carePlansResponse = await getOurCareplans();
            if (carePlansResponse.status === 200 && carePlansResponse?.data?.succesObject) {
                setCarePlans(carePlansResponse.data.succesObject);
            };

        }
        fetchData();
        dispatch(getHowItWorksAction())

    }, [dispatch])

    const howItWorksDetails = useSelector(state => state.CarePlansReducer.howItWorksDetails)
    const [plan, setPlan] = useState();



    useEffect(() => {
        if (howItWorksDetails) {
            if (diabeticsCarePackage.length === 0) {
                let filterData = howItWorksDetails.data.filter(element => element?.attributes?.package_name === 'Diabetics Care Package')
                setDiabeticsCarePackage(filterData)
            }
        }
    }, [howItWorksDetails, diabeticsCarePackage])

    const userData = useSelector((state) => state.AuthReducer.userData);

    useEffect(() => {
        if (!userData.length) return;

        let data = JSON.parse(userData);
        if (data?.[0]?.userCode || data?.[0]?.code) {
            setIsloggedIn(true);
        }
    }, [userData])
    console.log(isLoggedIn)


    const [whyOurPlans, setWhyOurPlans] = useState();
    useEffect(() => {
        async function fetchWhyOurPlansDetails() {
            const whyOurPlans = await fetchWhyOurPlans();
            if (whyOurPlans.status === 200 && whyOurPlans?.data) {
                setWhyOurPlans(whyOurPlans?.data);
            };
        }
        fetchWhyOurPlansDetails();
    }, []);

    const [healthSol, setHealthSol] = useState();
    useEffect(() => {
        async function fetchOurHealthSolDetails() {
            const healthSol = await fetchOurHealthSol();
            if (healthSol.status === 200 && healthSol?.data) {
                setHealthSol(healthSol?.data);
            };
        }
        fetchOurHealthSolDetails();


        async function fetchCarePlanDetails() {
            const res = await fetchCarePlan();
            if (res.status === 200 && res?.data?.data[0]) {
                setPlan(res?.data?.data[0]);
            };
        }
        fetchCarePlanDetails();
    }, []);
    console.log(plan)

    const handleAddCart = () => {
        if (isLoggedIn) {
            navigate(APP_ROUTES.CART)
        }
        else
            navigate(APP_ROUTES.LOGIN)
    }
    return (
        <div style={{ height: '100vh', width: '100%', userSelect: 'none' }}>
            <div className='container mx-auto flex flex-row bg-white'
                style={{
                    // backgroundImage: `url(${homePageImage?.data[0]?.attributes?.slider_image_path})`,
                    backgroundSize: 'contain',
                    height: '377px',
                    backgroundRepeat: 'no-repeat',
                    backgroundPositionX: 'right',
                    backgroundImage: `url(${plan?.attributes?.frstComp?.ImageUrl})`,
                }}
            >
                <div className='w-3/4 pl-16 pt-28 ' style={{
                    background: `linear-gradient(90.14deg, #FFFFFF 57.97%, rgba(255, 255, 255, 0.39) 87.24%, rgba(255, 255, 255, 0) 98.95%)`,
                }}>
                    <p className='p-2 text-base leading-54px md:text-sm md:font-normal text-center rounded-[5px] bg-shakespeare bg-opacity-[8%] inline-flex items-center text-chathams-blue-500'>{plan?.attributes?.frstComp?.name}</p>
                    <p className='p-2 text-[40px] font-bold text-chathams-blue-500 w-11/12  '>{plan?.attributes?.frstComp?.heading} </p>
                    <p className='p-2 text-base leading-27px font-semibold  w-7/12   text-chathams-blue-500 '>{plan?.attributes?.frstComp?.description}</p>
                </div>
            </div>

            <div className='container mx-auto'>

                <div className='mt-20'>
                    {<SubTitle title={plan?.attributes?.sec_comp?.title} desc={plan?.attributes?.sec_comp?.subTitle} />}
                </div>
                <div className='flex flex-row  mt-6 mb-20 items-center justify-center  '>
                    <div className=' flex  items-center justify-center  py-3 px-7 bg-[#FFFAEC]'>
                        {/* <p className='inline-flex items-start text-chathams-blue font-normal text-[20px] '><span className='pr-4'><img src={plan?.attributes?.sec_comp?.noti?.msg_icon} alt="img"></img></span>With  &nbsp;<span className='font-semibold'>General Health &nbsp;</span>package you can include other package as well</p> */}
                        <p className='inline-flex items-start text-chathams-blue font-normal text-[20px] '><span className='pr-4 pt-2'><img src={plan?.attributes?.sec_comp?.noti?.label2} alt="img"></img></span>{plan?.attributes?.sec_comp?.noti?.label1}</p>
                    </div>
                </div>

                <div className='flex justify-center'>
                    <table className="main-table  w-11/12 ">
                        <thead className='bg-shakespeare bg-opacity-[10%]  text-chathams-blue'>
                            <tr >
                                {carePlans?.length && carePlans.map((value, index) => (
                                    <th className='py-6' key={index}>{value?.planName}

                                        <div className='pt-4'
                                        // className='flex justify-center'
                                        >
                                            {/* <button className="  text-[13px] font-semibold text-center text-white  hover:bg-royal-blue-600 bg-royal-blue rounded " >ADD TO CART</button> */}
                                            <Button
                                                variant='contained'
                                                className="text-[13px] w-40 font-semibold text-center text-white hover:bg-royal-blue-600 bg-royal-blue rounded " onClick={handleAddCart}
                                            >Add To Cart</Button>
                                        </div>
                                    </th>

                                ))}
                            </tr>
                        </thead>
                        <tbody className='border ' >
                            <tr >
                                {carePlans?.length && carePlans.map((value, index) => (
                                    <td className="border pb-4 border-slate-300 align-top " key={index}>
                                        <div className='flex flex-col ml-7 mr-4'>
                                            <div className='pt-10 h-48'>
                                                <p className=' text-sm  text-left font-normal text-chathams-blue '><span className=' font-semibold  text-brand-lightgreen'>$</span><span className='text-[26px] font-bold text-brand-lightgreen'>{value?.price}</span> per month</p>
                                                <p className='text-lg text-left font-semibold text-black mt-4 mb-3'>{value?.packageType}</p>
                                                <p className=' text-sm  text-left  font-semibold text-chathams-blue mb-2 '>{value?.description}</p>
                                            </div>
                                            <div>
                                                <p className=' text-base text-left font-bold text-chathams-blue mt-7'>What's included:</p>
                                                <Stack direction="column" alignItems="flex-start" rowGap={1} sx={{ marginTop: '8px' }}>
                                                    {plan?.attributes?.SecondSub?.map((rec) => {
                                                        if (rec?.carePlanId === String(value?.planId)) {
                                                            return rec?.whatsIncluded?.slice(0, more).map((res) => (
                                                                <p className="inline-flex items-start text-chathams-blue font-semibold text-[12px]">
                                                                    <span className="pr-1">
                                                                        <Icon
                                                                            sx={{ fontSize: 16, color: "#90B6FF" }}
                                                                        >
                                                                            check
                                                                        </Icon>
                                                                    </span>
                                                                    {res?.label}
                                                                </p>
                                                            ));
                                                        }
                                                    })}
                                                </Stack>

                                            </div>
                                        </div>
                                    </td>

                                ))}

                            </tr>

                            <tr className=''>
                                <td colSpan={carePlans?.length}>
                                    <p className="my-4 text-chathams-blue-200 text-center  font-bold cursor-pointer" onClick={() => setMore(more == 4 ? plan?.attributes?.SecondSub?.whatsIncluded?.length : 4)} >>>{more == 4 ? <span className='underline underline-offset-4'> Read More</span> : <span>Read Less</span>}</p>
                                </td>
                            </tr>

                        </tbody>

                    </table>
                </div>
                <Dialog open={dialogOpen} onClose={() => handleDialogClose()} fullWidth={true}
                    sx={{ "& .MuiDialog-container": { "& .MuiPaper-root": { width: 10 / 12, maxWidth: '100%', }, }, }}
                >
                    <DialogTitle sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <p className='p-2 text-[40px] font-bold text-chathams-blue-500  '>Compare Plans</p>
                        <IconButton onClick={() => handleDialogClose()}>
                            <Icon sx={{ color: '#262626', fontSize: 34 }} >highlight_off</Icon>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <table className="main-table table-fixed w-full mx-auto my-8">
                            <thead>
                                <tr className='border-b border-slate-300'>
                                    <th className="border-r border-slate-300 w-[385px] py-8 px-3 text-base font-bold text-chathams-blue">Services</th>
                                    <th className="border-r border-slate-300 w-[156px] py-8 px-3 text-[14px] font-bold text-chathams-blue">General Health Care </th>
                                    <th className="border-r border-slate-300 w-[156px] py-8 px-3 text-[14px] font-bold text-chathams-blue">Diabetes Care </th>
                                    <th className="border-r border-slate-300 w-[156px] py-8 px-3 text-[14px] font-bold text-chathams-blue">Cardiac Care</th>
                                    <th className="border-r border-slate-300 w-[156px] py-8 px-3 text-[14px] font-bold text-chathams-blue">Ortho Care</th>
                                    <th className="border-slate-300  w-[156px] text-[14px] py-8 px-3 font-bold text-chathams-blue">Women's Health Care</th>
                                </tr></thead>
                            {compareHealth?.careplanFeturesVos?.length > 0 && compareHealth?.careplanFeturesVos.map((element, index) => (
                                <tbody key={index}>
                                    <tr className='border-b border-slate-300'>
                                        <td className="border border-slate-300 ">
                                            <div>
                                                <p className='mx-2 my-2 py-7 font-bold text-sm text-chathams-blue-500'>{element?.feature}</p>
                                                {/* <p className='mx-2 my-2 text-[12px] font-normal text-chathams-blue-500'>
                                                    <span className='pr-2 font-semibold text-[12px] text-chathams-blue'>Description:</span>
                                                    {element?.descriptions}</p> */}
                                            </div>
                                        </td>
                                        <td align='center' className="border border-slate-300">{element?.frequency === '1' ? <Icon sx={{ color: '#008A39', fontSize: 24 }} >check_circle</Icon> : <Icon sx={{ color: '#C5C5C5', fontSize: 24 }} >cancel</Icon>}</td>
                                        <td align='center' className="border border-slate-300 ">{element?.frequency === '4' ? <Icon sx={{ color: '#008A39', fontSize: 24 }} >check_circle</Icon> : <Icon sx={{ color: '#C5C5C5', fontSize: 24 }} >cancel</Icon>}</td>
                                        <td align='center' className="border border-slate-300 ">{element?.frequency === '12' ? <Icon sx={{ color: '#008A39', fontSize: 24 }} >check_circle</Icon> : <Icon sx={{ color: '#C5C5C5', fontSize: 24 }} >cancel</Icon>}</td>
                                        <td align='center' className="border border-slate-300 ">{element?.frequency === '0' ? <Icon sx={{ color: '#008A39', fontSize: 24 }} >check_circle</Icon> : <Icon sx={{ color: '#C5C5C5', fontSize: 24 }} >cancel</Icon>}</td>
                                        <td align='center' className="border border-slate-300 ">{element?.frequency === '0' ? <Icon sx={{ color: '#008A39', fontSize: 24 }} >check_circle</Icon> : <Icon sx={{ color: '#C5C5C5', fontSize: 24 }} >cancel</Icon>}</td>
                                    </tr>
                                </tbody>
                            ))
                            }
                        </table>
                    </DialogContent>
                    <DialogActions>
                    </DialogActions>
                </Dialog>

                <div className='mt-20'>
                    {<SubTitle title={plan?.attributes?.Thirdcomp?.heading} />}
                </div>

                <div className='grid grid-cols-3   '>
                    {
                        plan?.attributes?.Thirdcomp?.content.map((value) => {
                            return (
                                <div className='flex items-center justify-center mx-8 my-4 '>
                                    <div className='flex flex-col mx-2 my-4 items-center  w-10/12 h-[285px] ' >
                                        <img className='min-h-[115px] min-w-[115px] object-cover' draggable={false} src={value?.image_url} alt='service Images' />
                                        <div className='w-3/4 '>
                                            <p className='text-chathams-blue-500 text-center font-semibold text-lg'> {value?.heading}</p>
                                            <p className='pt-2 text-chathams-blue-500 font-semibold text-center  text-sm'>{value?.description}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                <div className=' flex-col flex items-center mb-16 '>
                    <div >
                        {<SubTitle topheading={plan?.attributes?.fourth?.name} title={plan?.attributes?.fourth?.title} />}
                    </div>
                    <div>
                        <img draggable={false} src={plan?.attributes?.fourth?.image_url} alt='service Images' />

                    </div>

                </div>
                {/* <FAQ /> */}
                <Footer />
            </div>

        </div >
    )
}

export default CarePlans