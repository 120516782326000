import services from "./index";
import httpcommon from "./httpcommon"
import { requestForChange } from "../actions/dashboard";
class RequestForChange {

    getRequestChange(payload) {
        return httpcommon.post(
            "/subscriptions/requestForUpdate", payload
        );
    }
}
export default new RequestForChange();
