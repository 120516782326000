import { HOMEPAGE } from "../../constants/homepageconstants";
import services from "../../services";
import homepageservices from "../../services/homepageservices";


export const getHomePageImage = () => async (dispatch) => {
    const res = await homepageservices.getHomePageImage();
    try {
        dispatch({
            type: HOMEPAGE.SUCCESS_HOMEPAGEIMAGE,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: HOMEPAGE.FAILED_HOMEPAGEIMAGE,
            payload: err
        });
    }
};

export const getHomePageContent = () => async (dispatch) => {
    const res = await homepageservices.getHomePageContent();
    try {
        dispatch({
            type: HOMEPAGE.SUCCESS_HOMEPAGECONTENT,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: HOMEPAGE.ERROR_HOMEPAGECONTENT,
            payload: err
        });
    }
}

export const getHomePageIntegrative = () => async (dispatch) => {
    const res = await homepageservices.getHomePageIntegrative();
    try {
        dispatch({
            type: HOMEPAGE.SUCCESS_HOMEPAGEINTEGRATIVE,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: HOMEPAGE.ERROR_HOMEPAGEINTEGRATIVE,
            payload: err
        });
    }
};

export const getHomePageHealthCareSolutions = async () => {
    const res = await homepageservices.getHealthCareSolutions();
    return res;
};

export const getHomePageHospitals = async () => {
    const res = await homepageservices.getHomepageHospitals();
    return res;
};

export const getFAQData = async () => {
    const res = await homepageservices.getFAQHomePage();
    return res;
};

export const getDoctorslist = (coords, freeTextSearch, pageNo, pageSize) => async (dispatch) => {
    const res = await homepageservices.getDoctorslistByCoOrd(coords, freeTextSearch, pageNo, pageSize)
    try {
        dispatch({
            type: HOMEPAGE.SUCCESS_ESDOCTORLIST,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: HOMEPAGE.FAILED_ESDOCTORLIST,
            payload: err
        });
    }
};



export const getLabslist = (coords) => async (dispatch) => {
    const res = await homepageservices.getLabslistByCoOrd(coords)
    try {
        dispatch({
            type: HOMEPAGE.SUCCESS_LABSLIST,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: HOMEPAGE.FAILED_LABSLIST,
            payload: err
        });
    }
};

export const fetchAim = async () => {
    const res = await homepageservices.getOurAim();
    return res;
};

export const fetchAimDetails = async () => {
    const res = await homepageservices.getOurAimDetails();
    return res;
};

export const getCarePlanContent = () => async (dispatch) => {
    const res = await homepageservices.getCarePlanContent();
    try {
        dispatch({
            type: HOMEPAGE.SUCCESS_CAREPLANCONTENT,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: HOMEPAGE.FAILED_CAREPLANCONTENT,
            payload: err
        });
    }
};

export const getDoctorsPageContent = () => async (dispatch) => {
    const res = await homepageservices.getDoctorsPageContent();
    try {
        dispatch({
            type: HOMEPAGE.SUCCESS_DOCTORPAGECONTENT,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: HOMEPAGE.ERROR_DOCTORPAGECONTENT,
            payload: err
        });
    }
};

export const getCureBayExpContent = () => async (dispatch) => {
    const res = await homepageservices.getCureBayExpContent();
    try {
        dispatch({
            type: HOMEPAGE.SUCCESS_CUREBAYEXPCONTENT,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: HOMEPAGE.ERROR_CUREBAYEXPCONTENT,
            payload: err
        });
    }
}

export const getCureBayStatistics = () => async (dispatch) => {
    const res = await homepageservices.getCureBayStatistics();
    try {
        dispatch({
            type: HOMEPAGE.STATISTICSCOUNT,
            payload: res.data
        });
    } catch (err) {
        dispatch({
            type: HOMEPAGE.ERROR_STATISTICSCOUNT,
            payload: err
        });
    }
}