import React from 'react';
import { Line,Bar } from 'react-chartjs-2';

const MultiChartLine = (props) => {
  // Filter out any non-string entries and then parse the data
  console.log(props.data,"dataaa")
  const colors = [
    "rgb(54, 162, 235, 0.2)",
    "rgb(75, 192, 192, 0.2)",
    "rgb(255, 99, 132, 0.2)",
];
const border = [
    "rgb(54, 162, 235, 1)",
    "rgb(75, 192, 192, 1)",
    "rgb(255, 99, 132, 1)",
];
  const data = props.data
    .filter(entry => typeof entry === 'string')
    .map(entry => {
      const [systolic, diastolic] = entry.split('/').map(Number);
      return { systolic, diastolic };
    });

  // Define the chart data and options
  const chartData = {
    labels: props.givendate,
    datasets: [
      {
        label: 'Systolic',
        borderColor:  "rgb(54, 162, 235, 1)",
        backgroundColor:   "rgb(54, 162, 235, 0.2)",
        data: data.map(entry => entry.systolic),
       // yAxisID: 'systolic-y-axis', // Use this to specify the y-axis
       fill: true,
       borderWidth: 2,
       borderRadius: 5,
       lineTension: 0.5,
      },
      {
        label: 'Diastolic',
        borderColor: 'rgba(64, 184, 130, 1)',
        backgroundColor: 'rgba(64, 184, 130, 0.3)',
        data: data.map(entry => entry.diastolic),
       // yAxisID: 'diastolic-y-axis', // Use this to specify the y-axis
      //  backgroundColor: colors[colors.length],
      //  borderColor: border[border.length],
       fill: true,
       borderWidth: 2,
       borderRadius: 5,
       lineTension: 0.5,
      },
    ],

   
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: false,
        min: 0,
        max: 200,
        stepSize: 10,
      },
      x: {
        grid: {
          color: "rgba(0, 0, 0, 0)",
        }
      },
    }
  };

  return (
    <div>
      {props.data?.length == 1 ?
        <Bar
          data={chartData}
          options={chartOptions}
        />
        : <Line
          data={chartData}
          options={chartOptions}
        />
      }
    </div>
  );
};

export default MultiChartLine;
