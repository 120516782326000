import React, { useEffect, useState, useRef } from "react";
import logoimg from "../../assets/images/Web Logo.png";
import checkicon from "../../webflowimages/checkicon.svg";
import loginservice from "../../store/services/loginservice";
import { useSnackbar } from "../common/snackbar";
import { styled } from "@mui/material/styles";
import { Dialog } from "primereact/dialog";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import {
  Box,
  Button,
  Card,
  // Dialog,
  // DialogActions,
  // DialogContent,
  // DialogTitle,
  Icon,
  MenuItem,
  Modal,
  IconButton as MuiIconButton,
  Tab,
  TextField,
} from "@mui/material";
import { fetchRelationDetails } from "../../store/actions/myprofile";
import { useSnackbarerror } from "../common/snackbarerror";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { APP_ROUTES } from "../../constants/approutes";

const AppointmentNew = () => {
  const divRef = useRef(null);
  const [err1, setErr1] = useState("");
  const [careReceivers, setCareReceivers] = useState([{ id: 1, formData: {} }]);
  const [loading, setLoading] = useState(false);
  const { snackbarMessage, setSnackbarMessage } = useSnackbar();
  const [showresponsedata, setShowresponsedata] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState([true]);
  const [careReceiverLimitError, setCareReceiverLimitError] = useState("");
  const [relations, setRelations] = useState([]);
  const { snackbarerrorMessage, setSnackbarerrorMessage } = useSnackbarerror();
  const [isConsentChecked, setIsConsentChecked] = useState(false);
  const [value, setValue] = useState("1");
  const [consent, setConsent] = useState();
  const [bytecode, setBytecode] = useState();
  const [isloading, setIsLoading] = useState(false);
  const todayEffectiveDate = new Date();
  const options = { day: "numeric", month: "long", year: "numeric" };
  const formattedDate = todayEffectiveDate.toLocaleDateString(
    undefined,
    options
  );
  const IconButton = styled(MuiIconButton)({
    backgroundColor: "white",
    borderColor: "#004171 !important",
    borderWidth: "1px !important",
    border: "solid",
    color: "#004171",
    "&:hover": {
      backgroundColor: "#004171",
      color: "white",
    },
  });
  const toggleIsActive = (index) => {
    setIsActive((prevIsActive) => {
      const updatedIsActive = Array(careReceivers.length).fill(false);
      updatedIsActive[index] = true;
      return updatedIsActive;
    });
  };

  const addCareReceiver = () => {
    if (careReceivers.length < 3) {
      setCareReceivers((prevCareReceivers) => [
        ...prevCareReceivers,
        { id: prevCareReceivers.length + 1, formData: {} },
      ]);
    } else {
      setCareReceiverLimitError("Maximum of 3 care receivers can be added");
      setTimeout(() => {
        setCareReceiverLimitError("");
      }, 5000);
    }
  };

  const [formData, setFormData] = useState({
    name: "",
    number: "",
    email: "",
    patientName: "",
    patientPhoneNo: "",
    relation: "",
    location: "",
    pinCode: "",
  });
  const handleChange = (event, careReceiverIndex = null) => {
    const { name, value } = event.target;

    if (careReceiverIndex !== null) {
      const updatedCareReceivers = [...careReceivers];
      updatedCareReceivers[careReceiverIndex].formData[name] = value;
      setCareReceivers(updatedCareReceivers);
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  console.log(careReceivers[0].formData.location, "careReceivers");
  const handleDailog = () => {
    if (formData.name == "") {
      setSnackbarMessage("Please fill up the mandatory fields", "error");
    } else if (careReceivers[0].formData.location === undefined) {
      setSnackbarMessage("Please fill up the mandatory fields", "error");
    } else {
      setOpen(true);
    }
  };

  const convertToPDFAndSendToAPI = async () => {
    const content = document.getElementById("contentContainer").innerText;
    const pdf = new jsPDF();

    let yPosition = 10;
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.width - 2 * margin;

    pdf.setFontSize(12);

    const addPage = () => {
      pdf.addPage();
      yPosition = margin;
    };

    pdf.setTextColor(0, 0, 0);

    const lines = pdf.splitTextToSize(content, pageWidth);

    lines.forEach((line) => {
      if (
        yPosition + pdf.getTextDimensions(line).h >
        pdf.internal.pageSize.height - margin
      ) {
        addPage();
      }

      pdf.setTextColor(0, 0, 0);

      pdf.text(line, margin, yPosition);
      yPosition += pdf.getTextDimensions(line).h + 2;
    });

    // pdf.save('consent.pdf');
    // const byteArray = pdf.output();

    const base64String = pdf.output("dataurlstring");
    const base64WithoutPrefix = base64String.split(",")[1];

    console.log(base64WithoutPrefix, "base64");
    setBytecode(base64WithoutPrefix);
  };

  const handleAgree = () => {
    setIsConsentChecked(true);
    setOpen(false);
    convertToPDFAndSendToAPI();
  };
  const handleDisagree = () => {
    setIsConsentChecked(false);
    setOpen(false);
  };
  const handleConsentCheck = () => {
    setIsConsentChecked(!isConsentChecked);

    // convertToPDFAndSendToAPI();
  };

  const initialFormData = {
    name: "",
    number: "",
    email: "",
    patientName: "",
    patientPhoneNo: "",
    relation: "",
    location: "",
    pinCode: "",
  };

  const handleSubmit = async () => {
    console.log(bytecode, "byteArray123");
    const phoneRegex =
      /^\+?\d{1,4}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{6,}(?=\D*\d\D*$)/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const PincodeRegex = /^[1-9][0-9]{5}$/;
    const careReceiversData = [];
    const careReceiverErrors = {};

    if (formData?.name == "") {
      setErr1("Please enter name");
    } else if (formData?.number == "") {
      setErr1("Please enter phone number");
    } else if (!phoneRegex.test(formData?.number)) {
      setErr1("Please enter valid phone number");
    } else if (!emailRegex.test(formData?.email) || formData?.email === "") {
      setErr1("Please enter a valid email");
    } else if (
      formData?.name !== "" &&
      emailRegex.test(formData?.email) &&
      phoneRegex.test(formData?.number)
    ) {
      careReceivers.forEach((careReceiver, index) => {
        const { patientName, relation, patientPhoneNo, location, pinCode } =
          careReceiver.formData;

        if (
          patientName !== "" &&
          patientName !== undefined &&
          relation !== "" &&
          relation !== undefined &&
          patientPhoneNo !== "" &&
          patientPhoneNo !== undefined &&
          location !== "" &&
          location !== undefined &&
          pinCode !== "" &&
          pinCode !== undefined
        ) {
          if (!PincodeRegex.test(pinCode)) {
            careReceiverErrors[index] = "Please enter a valid PIN code";
          } else if (!phoneRegex.test(patientPhoneNo)) {
            careReceiverErrors[index] = "Please enter valid phone number";
          } else {
            careReceiversData.push({
              firstName: patientName,
              relation,
              mobile: patientPhoneNo,
              address: location,
              pinCode,
              alreadyBooked: "N",
            });
          }
        } else {
          careReceiverErrors[index] =
            "Please fill in all care receiver details";
        }
      });
      if (isConsentChecked == false) {
        setErr1("Please Read and Sign Consent Form");
      } else {
        const errorKeys = Object.keys(careReceiverErrors);
        if (errorKeys.length > 0) {
          errorKeys.forEach((key) => {
            setErr1(careReceiverErrors[key]);
          });
        } else {
          setErr1("");
          setLoading(true);
          setIsLoading(true);
          convertToPDFAndSendToAPI();
          const { name, number, email } = formData;
          const payload = {
            firstName: name,
            mobile: number,
            email,
            consentForm: isConsentChecked ? bytecode : bytecode,
            consentFormFlag: isConsentChecked ? "1" : "0",
            preAssessmentParentVOList: careReceiversData,
          };

          window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
          window.dataLayer.push({
            event: "generate_lead",
            ecommerce: {
              value: 100,
              items: [
                {
                  firstName: name,
                  mobile: number,
                  email,
                  consentFormFlag: isConsentChecked ? "1" : "0",
                  preAssessmentParentVOList: careReceiversData,
                },
              ],
            },
          });

          try {
            const res = await loginservice.preAssesmentSave(payload);
            console.log(res?.data?.responseCode, res?.status, "jhjkjh");
            setLoading(false);
            handleClose();
            if (res?.status && res?.data?.responseCode === "200") {
              setSnackbarMessage(res.data?.responseMessage, "success");

              console.log("Before resetting state:", careReceivers);
              setFormData(initialFormData);
              setIsLoading(false);
              setCareReceivers([{ id: 1, formData: {} }]);
            } else if (
              res?.status === 200 &&
              res?.data?.responseCode === "500"
            ) {
              // setFormData(initialFormData);
              setIsLoading(false);
              setSnackbarMessage(res.data?.responseMessage, "error");
              // setCareReceivers([{ id: 1, formData: {} }]);
            } else {
              setSnackbarMessage(res?.data?.responseMessage, "error");
              setFormData(initialFormData);
              setIsLoading(false);
              setCareReceivers([{ id: 1, formData: {} }]);
            }
          } catch (error) {
            console.error("Failed to save", error);
          }
        }
      }
    }
  };
  const handleYes = async () => {
    setLoading(true);
    const { name, number, email } = formData;
    const careReceiversData = [];
    careReceivers.forEach((careReceiver, index) => {
      const { patientName, relation, patientPhoneNo, location, pinCode } =
        careReceiver.formData;

      careReceiversData.push({
        firstName: patientName,
        relation,
        mobile: patientPhoneNo,
        address: location,
        pinCode,
        alreadyBooked: "Y",
      });
    });

    const payload = {
      firstName: name,
      mobile: number,
      email,
      preAssessmentParentVOList: careReceiversData,
    };
    try {
      const res = await loginservice.preAssesmentSave(payload);
      setLoading(false);
      handleClose();
      if (res?.status && res?.data?.responseCode === "200") {
        setSnackbarMessage(res.data?.responseMessage, "success");
        setShowModal(false);
      } else if (res?.data?.responseCode === "500") {
        setShowresponsedata(res?.data?.responseMessage);
        setShowModal(true);
      } else {
        setSnackbarMessage(res?.data?.responseMessage, "error");
      }
      setFormData({});
    } catch (error) {
      console.error("Failed to save", error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const relationDetailsResponse = await fetchRelationDetails();
      if (
        relationDetailsResponse?.status === 200 &&
        relationDetailsResponse?.data?.succesObject
      ) {
        setRelations(relationDetailsResponse.data.succesObject);
      }
    }
    fetchData();
  }, []);
  const remove = (index) => {
    let data = [...careReceivers];
    data.splice(index, 1);
    toggleIsActive(index - 1);
    setCareReceivers(data);
  };

  const isMobile = () => window.innerWidth <= 768;
  return (
    <>
      {snackbarMessage}
      {snackbarerrorMessage}
      <div className="section hero booking">
        <div className="container-default width-100 w-container">
          <div className="w-layout-grid grid-2-columns booking-page-grid">
            <div
              data-w-id="c08f68c4-7de3-76c9-6bba-a9dba4f5fa20"
              // style={{
              //     WebkitTransform: "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              //     MozTransform: "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              //     msTransform: "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              //     transform: "translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)",
              // }}
              className="inner-container _444px _100---tablet mt-[71px] sm:mt-0"
            >
              <h1 className="display-1">
                Book a Free Health Assessment for your loved ones
              </h1>
              <div className="w-layout-grid grid-1-column gap-row-16px">
                <div class="icon-list-item-wrapper">
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      Totalcare Members get:
                    </div>
                  </div>
                </div>
                <div className="icon-list-item-wrapper">
                  <img src={checkicon} alt="" className="icon-list" />
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      Access to senior elite doctors
                    </div>
                  </div>
                </div>
                <div className="icon-list-item-wrapper">
                  <img src={checkicon} alt="" className="icon-list" />
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      Full Body Lab Tests
                    </div>
                  </div>
                </div>
                <div className="icon-list-item-wrapper">
                  <img src={checkicon} alt="" className="icon-list" />
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      Home Visits of Doctors, Nurses
                    </div>
                  </div>
                </div>

                <div className="icon-list-item-wrapper">
                  <img src={checkicon} alt="" className="icon-list" />
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      Dedicated Concierge during hospitalisation
                    </div>
                  </div>
                </div>

                <div className="icon-list-item-wrapper">
                  <img src={checkicon} alt="" className="icon-list" />
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      Ambulance Services
                    </div>
                  </div>
                </div>

                <div className="icon-list-item-wrapper">
                  <img src={checkicon} alt="" className="icon-list" />
                  <div className="text-bold">
                    <div className="line-height-1-2em mg-top-3px">
                      On Demand – Home Services (X-Ray, ECG etc.)
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              id="w-node-c08f68c4-7de3-76c9-6bba-a9dba4f5fa3b-4d0e8dc1"
              data-w-id="c08f68c4-7de3-76c9-6bba-a9dba4f5fa3b"
              className="card contact-form-card booking-form w-form sm:mt-40 mt-0"
            >
              <div>
                <h3
                  id="w-node-e82aa137-1cbb-2de1-aba7-a0b8c0b60c1f-4d0e8dc1"
                  className="heading-2 mb-5"
                >
                  Care Sponsor Details
                </h3>
                <div className="w-layout-grid grid-2-columns form">
                  <div>
                    <label htmlFor="Name">
                      Full name<spam style={{ color: "#F34040" }}>*</spam>
                    </label>
                    <div className="position-relative">
                      <input
                        type="text"
                        className="input w-input"
                        maxLength={256}
                        required
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="Enter Name"
                      />

                      <div className="floating-item icon" />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="Name-2">
                      Phone number<spam style={{ color: "#F34040" }}>*</spam>
                    </label>
                    <div className="position-relative">
                      <input
                        required
                        minLength="8"
                        maxLength="13"
                        type="tel"
                        name="number"
                        value={formData.number}
                        onChange={handleChange}
                        className="input w-input"
                        placeholder="Enter Phone Number"
                      />
                      <div className="floating-item icon" />
                    </div>
                  </div>
                  <div>
                    <label htmlFor="Phone">
                      Email ID<spam style={{ color: "#F34040" }}>*</spam>
                    </label>
                    <div className="position-relative">
                      <input
                        required
                        maxLength={256}
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className="input w-input"
                        placeholder="Enter Email ID"
                      />
                      <div className="floating-item icon" />
                    </div>
                  </div>
                </div>

                <div className="div-block-18">
                  <h3 className="heading-2 booking">Care Recipient Details</h3>
                  {/* <a href="/booking" aria-current="page" className="btn-secondary small w-button w--current">
                                Add Care Receiver</a> */}
                  <button
                    id="addCareReceiver-button"
                    className="btn-secondary small w-button w--current"
                    size="medium"
                    fullWidth
                    onClick={addCareReceiver}
                  >
                    Add Care Recipient
                  </button>
                </div>
                {careReceivers.map((careReceiver, index) => (
                  <div key={index}>
                    <div className="my-4 flex items-center justify-between">
                      <h2
                        className="sm:text-xl text-lg"
                        style={{
                          fontFamily: "Open Sans",
                          fontStyle: "normal",
                          fontWeight: "600",
                          color: "#1A1A1A",
                        }}
                      >
                        Recipient Details {index + 1}:
                      </h2>
                      <div className="flex items-center">
                        <IconButton
                          size="small"
                          sx={{ backgroundColor: "#E4FAFF" }}
                          onClick={() => toggleIsActive(index)}
                        >
                          {isActive[index] ? (
                            <Icon fontSize="inherit" color="#262626">
                              expand_less
                            </Icon>
                          ) : (
                            <Icon fontSize="inherit" color="#262626">
                              expand_more
                            </Icon>
                          )}
                        </IconButton>
                      </div>
                    </div>
                    {isActive[index] && (
                      <div className="w-layout-grid grid-2-columns form">
                        <div>
                          <label htmlFor="Name-3">
                            Full name<spam style={{ color: "#F34040" }}>*</spam>
                          </label>
                          <div className="position-relative">
                            <input
                              type="text"
                              className="input w-input"
                              maxLength={256}
                              name="patientName"
                              data-name="Name 3"
                              id="Name-3"
                              required
                              placeholder="Enter Name"
                              value={careReceiver.formData.patientName || ""}
                              onChange={(event) => handleChange(event, index)}
                            />

                            <div className="floating-item icon" />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="Name-3">
                            Phone Number
                            <spam style={{ color: "#F34040" }}>*</spam>
                          </label>
                          <div className="position-relative">
                            <input
                              required
                              minLength="8"
                              maxLength="13"
                              name="patientPhoneNo"
                              value={careReceiver.formData.patientPhoneNo || ""}
                              onChange={(event) => handleChange(event, index)}
                              className="input w-input"
                              placeholder="Enter Phone Number"
                            />
                            <div className="floating-item icon" />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="Phone">
                            Select Relationship
                            <spam style={{ color: "#F34040" }}>*</spam>
                          </label>
                          <div className="position-relative">
                            {/* <input
                                        type="text"
                                        className="input w-input"
                                        maxLength={256}
                                        name="Location"
                                        data-name="Location"
                                        id="Location"
                                        required="" /> */}
                            <div>
                              <select
                                select
                                required
                                name="relation"
                                value={careReceiver.formData.relation || ""}
                                onChange={(event) => handleChange(event, index)}
                                className="input w-input"
                              >
                                <option value="">Select Relationship</option>
                                {relations &&
                                  relations?.map((value, index) => (
                                    <option
                                      value={value.relationship}
                                      key={index}
                                    >
                                      <p className="text-base font-semibold">
                                        {value.relationship}
                                      </p>
                                    </option>
                                  ))}
                              </select>
                            </div>
                            <div className="floating-item icon" />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="Phone">
                            Pincode<spam style={{ color: "#F34040" }}>*</spam>
                          </label>
                          <div className="position-relative">
                            <input
                              required
                              maxLength="8"
                              name="pinCode"
                              placeholder="Enter Pincode"
                              value={careReceiver.formData.pinCode || ""}
                              onChange={(event) => handleChange(event, index)}
                              className="input w-input"
                            />
                            <div className="floating-item icon" />
                          </div>
                        </div>
                        <div>
                          <label htmlFor="Phone">
                            Location<spam style={{ color: "#F34040" }}>*</spam>
                          </label>
                          <div className="position-relative">
                            <input
                              required
                              name="location"
                              placeholder="Enter Location"
                              value={careReceiver.formData.location || ""}
                              onChange={(event) => handleChange(event, index)}
                              className="input w-input"
                            />
                            <div className="floating-item icon" />
                          </div>
                        </div>
                        <div>
                          {index !== 0 && (
                            <div className="flex items-end ml-auto">
                              <button
                                id="remove-button"
                                size="small"
                                className="btn-secondary small w-button w--current"
                                onClick={() => remove(index)}
                              >
                                Remove
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
                <div className="flex justify-start items-start pb-6 sm:pt-5 pt-0">
                  {isConsentChecked == true ? (
                    <input
                      className=" mr-3 mt-[3px]"
                      checked
                      type="checkbox"
                      // checked={isConsentChecked}
                      // onChange={handleConsentCheck}
                    />
                  ) : (
                    ""
                  )}
                  <span className="text-sm"> Read and Sign </span>
                  <span
                    onClick={handleDailog}
                    className="text-seegreen text-sm  font-bold underline cursor-pointer pl-1"
                  >
                    {" "}
                    Consent Form{" "}
                  </span>{" "}
                  {/* {consent?.attributes?.SecondComp?.name} */}
                </div>
                <div className="text-center mt-6">
                  <span className="text-red-600 font-medium text-base pb-4 text-center tracking-widest">
                    {err1}
                  </span>
                </div>
                <div
                  id="w-node-fcd9f6af-3df2-8075-69c9-d85998b45416-4d0e8dc1"
                  data-w-id="fcd9f6af-3df2-8075-69c9-d85998b45416"
                  className="mg-top-8px mg-top-4px-mbp"
                >
                  {isloading == true ? (
                    <input
                      type="text"
                      defaultValue="Loading.."
                      className="btn-primary width-100 w-button"
                      style={{
                        cursor: "not-allowed",
                      }}
                    />
                  ) : (
                    <input
                      type="submit"
                      defaultValue="Get a free appointment"
                      data-wait="Please wait..."
                      className="btn-primary width-100 w-button"
                      onClick={() => {
                        handleSubmit();
                      }}
                    />
                  )}
                </div>
              </div>
              <div className="success-message contact-form w-form-done">
                <div>
                  <div className="line-rounded-icon success-message-check large">
                    
                  </div>
                  <h3 className="display-3">Thank you</h3>
                  <div>
                    Thanks for reaching out. We will get back to you soon.
                  </div>
                </div>
              </div>
              <div className="error-message w-form-fail">
                <div>Oops! Something went wrong</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="footer-wrapper">
        <div className="bg-neutral-100">
          <div className="container-default w-container">
            <div
              data-w-id="59a7f434-e96a-a890-4742-603a27851dbc"
              className="footer-bottom v1"
            >
              <a
                id="w-node-_59a7f434-e96a-a890-4742-603a27851dbe-27851db9"
                href={APP_ROUTES.DASHBOARD}
                className="footer-logo-wrapper mg-bottom-0 w-inline-block"
              >
                <img src={logoimg} alt="" />
              </a>
              <p className="color-neutral-800 mg-bottom-0">
                Copyright © CureBay
              </p>
            </div>
          </div>

          <Dialog
            visible={open}
            // position="top"
            position={isMobile() ? "top" : "center"}
            header={<div className="font-bold text-lg">Consent Form</div>}
            style={{ height: "500px" }}
            className="sm:w-4/5 w-full"
            onHide={handleClose}
            footer={
              <div className="sm:mx-10 sm:my-10 my-3 flex">
                <button
                  className="btn-primary width-100 w-button w-[119px] h-[49px] "
                  style={{ paddingTop: "11px" }}
                  onClick={handleAgree}
                >
                  Accept
                </button>
                <button
                  className="btn-primary width-100 w-button w-[119px] h-[49px]"
                  style={{ paddingTop: "11px" }}
                  onClick={handleDisagree}
                >
                  Decline
                </button>
              </div>
            }
            draggable={false}
            resizable={false}
          >
            <div>
              {/* 
              <div className="sm:text-justify sm:w-auto w-fit" ref={divRef} id="contentContainer">
                This FREE ASSESSMENT CONSENT FORM, together with all the exhibits hereto (the “Consent Form”) is made this {formattedDate} (“Effective Date”) by:

                A. CureBay Technologies Private Limited, a private limited company, bearing corporate identification number U85110OR2020PTC035200, having its registered office at Plot No. A-98, Budha Nagar, Laxmisagar, Bhubaneswar, Khordha, Odisha – 751006, India (hereinafter referred to as the “Practice” which expression shall, unless it be repugnant to or inconsistent with the context hereof, shall mean and include its successors and permitted assigns);

                And accepted by:

                B. {formData.name}, presently residing at {careReceivers[0].formData.location} (hereinafter referred to as the “Subscriber”).

                The Practice and the Subscriber shall collectively be referred to as the “Parties” and individually as a “Party”.

                1. TRIAL MEMBERSHIP. Subscriber hereby agrees to enroll for a free assessment in respect of the Practice’s ‘CureBay Medicare’ General/Diabetes/Ortho/Women’s/Cardio care membership program (“Trial Membership Program”) beginning on the Effective Date set forth above and expiring within 7 business days from the Effective Date (“Term”). By virtue of being a member of the Trial Membership Program, the Subscriber shall be eligible to receive certain basic medical services set out in Exhibit A of this Consent Form (“Covered Services”) for the Term. Membership in the Practice’s Trial Membership Program includes only the Covered Services specifically described in Exhibit A and the provision of such Covered Services shall be subject to the conditions and limitations described in this Consent Form and any ancillary documents executed thereto. The Practice may revise, add or discontinue any of the Covered Services at any time, as it may choose in its sole discretion.

                2. NON-COVERED SERVICES. The Subscriber understands and acknowledges that the Subscriber is solely responsible for any charges incurred by the Subscriber for health care services performed outside of the physical office space location, including, but not limited to, emergency room visits, hospital and specialist care, and imaging and lab tests performed by

                third parties. Further, the Parties agree and acknowledge that the Practice shall not be responsible for any charges or reimbursement of any charges incurred by the Subscriber other than as explicitly set out under this Consent Form. The Subscriber shall also be responsible for any charges incurred for health care services provided by the Practice but not specifically set out in Exhibit A.

                The Subscriber shall undertake best efforts to maintain a valid health insurance policy from a reputed third party insurance provider during the Term of this Consent Form considering the services provided by the Practice are not in any way in the nature of services provided under a health insurance policy. The Subscriber should ensure that the health insurance shall cover, at a minimum unpredictable and catastrophic expenses.

                3. INSURANCE. The Subscriber acknowledges and understands that this Consent Form or Trial Membership Program in the Practice does not provide comprehensive health insurance coverage, nor is it a contract of insurance. The Subscriber represents that the Subscriber has contacted the Subscriber’s insurance health insurance company to discuss any limitations or restrictions that may be imposed upon the Subscriber by accepting the Consent Form.

                A. INSURANCE CLAIMS. The Subscriber acknowledges and understands that the Practice is not a participating provider in any TPA, CGHS, Ayushman Bharat, etc. or private health care plan. The Subscriber acknowledges and understands that the Practice will not bill insurance carriers on the Subscriber’s behalf including for Covered Services provided to Subscriber and the Practice will not bill any health care plan of which the Subscriber may be a subscriber or beneficiary for in relation to this Consent Form.

                B. HEALTH PLANS. Because the Practice is not a participating provider in any private health care plan, third party payers may not count any expenses incurred pursuant to this Consent Form toward any deductible Subscriber may have under a health plan.

                4. TERMINATION OF THE CONSENT FORM. Termination of this Consent Form in accordance with this section 5, shall cause the termination of the Subscriber’s Trial Membership Program and the provision of the Covered Services described herein.

                A. TERMINATION BY THE PRACTICE. The Practice may terminate this Consent Form automatically upon expiry of the Term or at any time after the Effective Date upon providing Subscriber advance written notice.

                5. REINSTATEMENT. In the event this Consent Form terminates after the Effective Date hereof, the Subscriber can become a member at any time again when they wish to by approaching the

                Practice for the same.

                6. INDEMNIFICATION. The Subscriber agrees to indemnify and to hold the Practice and its members, affiliates, subsidiaries, officers, directors, agents, and employees harmless from and against any and all demands, claims, actions or causes of action, assessments, losses, damages, liabilities, costs and expenses, including interest, penalties, attorney fees, etc. which are imposed upon or incurred by the Practice as a result of the Subscriber’s breach of any of Subscriber’s representations, warranties and/or obligations under this Consent Form or as a result of any misuse of the Covered Services or any fraud, gross negligence or wilful misconduct by the Subscriber.

                Notwithstanding anything contained herein, the Subscriber agrees and acknowledges that the Practice operates an aggregation platform that connects third party service providers (including the affiliates and/or subsidiaries of the Practice) to the Subscriber in order to provide the Covered Services and therefore the Practice shall be excluded from any direct or indirect liability arising from or in connection with the Covered Services.

                7. ENTIRE AGREEMENT. This Consent Form constitutes the entire understanding between the Parties hereto relating to the matters herein contained and shall not be modified or amended except as mutually agreed upon by both Parties hereto.

                8. WAIVER. The waiver of either the Practice or Subscriber of a breach of any provisions of this Consent Form must be in writing and signed by the waiving and non- breaching Party to be effective and shall not operate or be construed as a waiver of any subsequent breach by either the Practice or Subscriber, as may be applicable.

                9. CHANGE OF LAW. If there is a change of any law, regulation or rule, federal, state or local, which affects this Consent Form, any terms or conditions incorporated by reference in this Consent Form, the activities of the Practice under this Consent Form, or any change in the judicial or administrative interpretation of any such law, regulation or rule, and the Practice reasonably believes in good faith that the change will have a substantial adverse effect on the Practice’s rights, obligations or operations or associated with this Consent Form, then the Practice may, upon written notice, require the Subscriber to enter into good faith negotiations to renegotiate the terms of this Consent Form. If the Parties are unable to reach an agreement concerning the modification of this Consent Form within ten (10) days after the effective date of change, then the Practice may immediately terminate this Consent Form upon providing written notice to the Subscriber.

                10. GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION. This Consent Form and the rights and obligations of the Practice and the Subscriber hereunder shall be construed and enforced pursuant to the laws of India and the courts at Bhubaneswar, India shall have exclusive jurisdiction on the matters arising from or in connection with this Consent Form, without regard to the principles of conflicts of laws. Further, any dispute, claim, controversy or disagreement of any kind arising under or in relation to this Consent Form shall be resolved by arbitration in accordance with the Indian Arbitration and Conciliation Act, 1996. The seat and venue of arbitration shall be Bhubaneswar, India.

                11. ASSIGNMENT/BINDING EFFECT. This Consent Form shall be binding upon and shall inure to the benefit of both the Practice and Subscriber and their respective successors, heirs and legal representatives. Neither this Consent Form, nor any rights hereunder, may be assigned by the Subscriber without the written consent of the Practice. It is hereby clarified that the Practice may assign any and all of its rights and/or obligations under this Consent Form to any third party.

                COVERED SERVICES WITH THE FREE ASSESSMENT

                COVERED SERVICES:

                30 Parameters Lab Test – Uric Acid, SGOT/AST, Total Cholesterol, RBS, CBC.

                1 Doctor Consultation – Post lab report a consultation with the doctor regarding the best suited package.

                EXCLUDED SERVICES:

                Anything not specifically listed as a Covered Service shall be a non-covered service. Any health care services not performed on or within the plans including emergency room visits, hospital stays, specialist care, imaging and labs, etc.
              </div> */}
              <div
                className="sm:text-justify sm:w-auto w-fit"
                ref={divRef}
                id="contentContainer"
              >
                <div class="mt-4">
                  <h1 class="text-2xl font-bold">
                    FREE ASSESSMENT CONSENT FORM
                  </h1>
                  This FREE ASSESSMENT CONSENT FORM, together with all the
                  exhibits hereto (the “Consent Form”) is made this{" "}
                  {formattedDate} (“Effective Date”) by:
                </div>
                <div className="pl-10">
                  <p>
                    {" "}
                    A. CureBay Technologies Private Limited, a private limited
                    company, bearing corporate identification number
                    U85110OR2020PTC035200, having its registered office at Plot
                    No. A-98, Budha Nagar, Laxmisagar, Bhubaneswar, Khordha,
                    Odisha – 751006, India (hereinafter referred to as the
                    “Practice” which expression shall, unless it be repugnant to
                    or inconsistent with the context hereof, shall mean and
                    include its successors and permitted assigns); And accepted
                    by:
                  </p>

                  <p>
                    B. {formData.name}, presently residing at{" "}
                    {careReceivers[0].formData.location} (hereinafter referred
                    to as the “Subscriber”). The Practice and the Subscriber
                    shall collectively be referred to as the “Parties” and
                    individually as a “Party”.
                  </p>
                </div>

                <div class="mt-8">
                  <p class="text-base font-bold">
                    1: TRIAL MEMBERSHIP.{" "}
                    <span className="font-normal leading-7">
                      Subscriber hereby agrees to enroll for a free assessment
                      in respect of the Practice’s ‘CureBay Totalcare’
                      General/Diabetes/Ortho/Women’s/Cardio care membership
                      program (“Trial Membership Program”) beginning on the
                      Effective Date set forth above and expiring within 7
                      business days from the Effective Date (“Term”). By virtue
                      of being a member of the Trial Membership Program, the
                      Subscriber shall be eligible to receive certain basic
                      medical services set out in Exhibit A of this Consent Form
                      (“Covered Services”) for the Term. Membership in the
                      Practice’s Trial Membership Program includes only the
                      Covered Services specifically described in Exhibit A and
                      the provision of such Covered Services shall be subject to
                      the conditions and limitations described in this Consent
                      Form and any ancillary documents executed thereto. The
                      Practice may revise, add or discontinue any of the Covered
                      Services at any time, as it may choose in its sole
                      discretion.
                    </span>
                  </p>
                </div>

                <div class="mt-8">
                  <p class="text-base font-bold">
                    2: NON-COVERED SERVICES.{" "}
                    <span className="font-normal leading-7">
                      The Subscriber understands and acknowledges that the
                      Subscriber is solely responsible for any charges incurred
                      by the Subscriber for health care services performed
                      outside of the physical office space location, including,
                      but not limited to, emergency room visits, hospital and
                      specialist care, and imaging and lab tests performed by
                      third parties. Further, the Parties agree and acknowledge
                      that the Practice shall not be responsible for any charges
                      or reimbursement of any charges incurred by the Subscriber
                      other than as explicitly set out under this Consent Form.
                      The Subscriber shall also be responsible for any charges
                      incurred for health care services provided by the Practice
                      but not specifically set out in Exhibit A. The Subscriber
                      shall undertake best efforts to maintain a valid health
                      insurance policy from a reputed third party insurance
                      provider during the Term of this Consent Form considering
                      the services provided by the Practice are not in any way
                      in the nature of services provided under a health
                      insurance policy. The Subscriber should ensure that the
                      health insurance shall cover, at a minimum unpredictable
                      and catastrophic expenses.
                    </span>{" "}
                  </p>
                </div>
                <div class="mt-8">
                  <p class="text-base font-bold">
                    3: INSURANCE.{" "}
                    <span className="font-normal leading-7">
                      The Subscriber acknowledges and understands that this
                      Consent Form or Trial Membership Program in the Practice
                      does not provide comprehensive health insurance coverage,
                      nor is it a contract of insurance. The Subscriber
                      represents that the Subscriber has contacted the
                      Subscriber’s insurance health insurance company to discuss
                      any limitations or restrictions that may be imposed upon
                      the Subscriber by accepting the Consent Form.
                    </span>{" "}
                  </p>
                </div>

                <div className="pl-10">
                  <p>
                    A. INSURANCE CLAIMS. The Subscriber acknowledges and
                    understands that the Practice is not a participating
                    provider in any TPA, CGHS, Ayushman Bharat, etc. or private
                    health care plan. The Subscriber acknowledges and
                    understands that the Practice will not bill insurance
                    carriers on the Subscriber’s behalf including for Covered
                    Services provided to Subscriber and the Practice will not
                    bill any health care plan of which the Subscriber may be a
                    subscriber or beneficiary for in relation to this Consent
                    Form.
                  </p>

                  <p>
                    B. HEALTH PLANS. Because the Practice is not a participating
                    provider in any private health care plan, third party payers
                    may not count any expenses incurred pursuant to this Consent
                    Form toward any deductible Subscriber may have under a
                    health plan.
                  </p>
                </div>

                <div class="mt-8">
                  <p class="text-base font-bold">
                    4: TERMINATION OF THE CONSENT FORM{" "}
                    <span className="font-normal leading-7">
                      . Termination of this Consent Form in accordance with this
                      section 5, shall cause the termination of the Subscriber’s
                      Trial Membership Program and the provision of the Covered
                      Services described herein.
                    </span>{" "}
                  </p>
                </div>

                <div className="pl-10">
                  <p>
                    A. TERMINATION BY THE PRACTICE. The Practice may terminate
                    this Consent Form automatically upon expiry of the Term or
                    at any time after the Effective Date upon providing
                    Subscriber advance written notice
                  </p>
                </div>
                <div class="mt-8">
                  <p class="text-base font-bold">
                    5: REINSTATEMENT.{" "}
                    <span className="font-normal leading-7">
                      In the event this Consent Form terminates after the
                      Effective Date hereof, the Subscriber can become a member
                      at any time again when they wish to by approaching the
                      Practice for the same.
                    </span>{" "}
                  </p>
                </div>
                <div class="mt-8">
                  <p class="text-base font-bold">
                    6:INDEMNIFICATION.{" "}
                    <span className="font-normal leading-7">
                      The Subscriber agrees to indemnify and to hold the
                      Practice and its members, affiliates, subsidiaries,
                      officers, directors, agents, and employees harmless from
                      and against any and all demands, claims, actions or causes
                      of action, assessments, losses, damages, liabilities,
                      costs and expenses, including interest, penalties,
                      attorney fees, etc. which are imposed upon or incurred by
                      the Practice as a result of the Subscriber’s breach of any
                      of Subscriber’s representations, warranties and/or
                      obligations under this Consent Form or as a result of any
                      misuse of the Covered Services or any fraud, gross
                      negligence or wilful misconduct by the Subscriber.
                    </span>{" "}
                  </p>
                </div>
                <div class="mt-5">
                  Notwithstanding anything contained herein, the Subscriber
                  agrees and acknowledges that the Practice operates an
                  aggregation platform that connects third party service
                  providers (including the affiliates and/or subsidiaries of the
                  Practice) to the Subscriber in order to provide the Covered
                  Services and therefore the Practice shall be excluded from any
                  direct or indirect liability arising from or in connection
                  with the Covered Services.
                </div>

                <div class="mt-8">
                  <p class="text-base font-bold">
                    7:ENTIRE AGREEMENT.{" "}
                    <span className="font-normal leading-7">
                      This Consent Form constitutes the entire understanding
                      between the Parties hereto relating to the matters herein
                      contained and shall not be modified or amended except as
                      mutually agreed upon by both Parties hereto.
                    </span>{" "}
                  </p>
                </div>
                <div class="mt-8">
                  <p class="text-base font-bold">
                    8:WAIVER.{" "}
                    <span className="font-normal leading-7">
                      The waiver of either the Practice or Subscriber of a
                      breach of any provisions of this Consent Form must be in
                      writing and signed by the waiving and non- breaching Party
                      to be effective and shall not operate or be construed as a
                      waiver of any subsequent breach by either the Practice or
                      Subscriber, as may be applicable.
                    </span>{" "}
                  </p>
                </div>

                <div class="mt-8">
                  <p class="text-base font-bold">
                    9:CHANGE OF LAW.{" "}
                    <span className="font-normal leading-7">
                      If there is a change of any law, regulation or rule,
                      federal, state or local, which affects this Consent Form,
                      any terms or conditions incorporated by reference in this
                      Consent Form, the activities of the Practice under this
                      Consent Form, or any change in the judicial or
                      administrative interpretation of any such law, regulation
                      or rule, and the Practice reasonably believes in good
                      faith that the change will have a substantial adverse
                      effect on the Practice’s rights, obligations or operations
                      or associated with this Consent Form, then the Practice
                      may, upon written notice, require the Subscriber to enter
                      into good faith negotiations to renegotiate the terms of
                      this Consent Form. If the Parties are unable to reach an
                      agreement concerning the modification of this Consent Form
                      within ten (10) days after the effective date of change,
                      then the Practice may immediately terminate this Consent
                      Form upon providing written notice to the Subscriber.
                    </span>{" "}
                  </p>
                </div>

                <div class="mt-8">
                  <p class="text-base font-bold">
                    10. GOVERNING LAW, JURISDICTION AND DISPUTE RESOLUTION.{" "}
                    <span className="font-normal leading-7">
                      This Consent Form and the rights and obligations of the
                      Practice and the Subscriber hereunder shall be construed
                      and enforced pursuant to the laws of India and the courts
                      at Bhubaneswar, India shall have exclusive jurisdiction on
                      the matters arising from or in connection with this
                      Consent Form, without regard to the principles of
                      conflicts of laws. Further, any dispute, claim,
                      controversy or disagreement of any kind arising under or
                      in relation to this Consent Form shall be resolved by
                      arbitration in accordance with the Indian Arbitration and
                      Conciliation Act, 1996. The seat and venue of arbitration
                      shall be Bhubaneswar, India.
                    </span>{" "}
                  </p>
                </div>

                <div class="mt-8">
                  <p class="text-base font-bold">
                    11: ASSIGNMENT/BINDING EFFECT..{" "}
                    <span className="font-normal leading-7">
                      . This Consent Form shall be binding upon and shall inure
                      to the benefit of both the Practice and Subscriber and
                      their respective successors, heirs and legal
                      representatives. Neither this Consent Form, nor any rights
                      hereunder, may be assigned by the Subscriber without the
                      written consent of the Practice. It is hereby clarified
                      that the Practice may assign any and all of its rights
                      and/or obligations under this Consent Form to any third
                      party.
                    </span>{" "}
                  </p>
                </div>

                <div class="mt-8">
                  <h2 class="text-lg font-bold text-center">
                    COVERED SERVICES WITH THE FREE ASSESSMENT
                  </h2>
                  <div class="mt-4">
                    <p class="font-bold">COVERED SERVICES:</p>
                  </div>
                  <div class="mt-4">
                    <p class="font-bold">
                      30 Parameters Lab Test -{" "}
                      <span className="font-normal">
                        {" "}
                        Uric Acid, SGOT/AST, Total Cholesterol, RBS, CBC.
                      </span>
                    </p>
                  </div>
                  <div class="mt-4">
                    <p class="font-bold">
                      1 Doctor Consultation -{" "}
                      <span className="font-normal">
                        {" "}
                        Post lab report a consultation with the doctor regarding
                        the best suited package.{" "}
                      </span>
                    </p>
                  </div>
                  <div class="mt-4">
                    <p class="font-bold">EXCLUDED SERVICES:</p>
                    <p>
                      Anything not specifically listed as a Covered Service
                      shall be a non-covered service. Any health care services
                      not performed on or within the plans including emergency
                      room visits, hospital stays, specialist care, imaging and
                      labs, etc.
                    </p>
                  </div>
                </div>
              </div>

              <div className="text-justify">
                {consent?.attributes?.ThirdComponent?.description2}
              </div>
            </div>
          </Dialog>
        </div>
      </footer>
    </>
  );
};

export default AppointmentNew;
