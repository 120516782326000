import React, { useEffect } from 'react'
import Aos from "aos";
import 'aos/dist/aos.css'
import thankyou from "../../assets/images/thankyou.png"
import { APP_ROUTES } from '../../constants/approutes';

function Thankyou() {
  useEffect(() => {
    Aos.init({ duration: 2000 })
  }, [])
  return (
    <>
      <div className='bg-deep-blue relative overflow-hidden'>
        <div className=' absolute  -left-8 -bottom-[20.5rem] bg-[#aaeaff] rounded-full sm:h-[35rem] h-[28rem]  sm:w-[35rem] w-[27rem] ' ></div>
        <div className=' absolute -left-[28.5rem] sm:-bottom-[23px] -bottom-[55px]  bg-[#0cb9f1] rounded-full sm:h-[763px] h-[325px] sm:w-[847px] w-[600px] '></div>

        <div className="flex  flex-col items-center justify-center text-center h-screen text-white sm:ml-96 " >
          <div className='sm:w-2/3 w-full sm:px-0 px-[20px] sm:py-0 py-[20px]'>
            <div className=' hidden sm:block font-bold sm:text-5xl text-xl sm:leading-[3.5rem] sm:mt-0 -mt-[260px] ' data-aos="fade-left" data-aos-duration="3000" >Thank you for choosing CureBay Totalcare ! </div>
            <div className=' block sm:hidden font-bold sm:text-5xl text-3xl sm:leading-[3.5rem] sm:mt-0 -mt-[260px] ' data-aos="fade-left" data-aos-duration="3000" >Thank you for choosing <br />CureBay Totalcare ! </div>
            <div className=' font-bold sm:text-2xl text-base mt-8 sm:leading-[2.5rem]' data-aos="fade-right" data-aos-duration="3000">We appreciate your proactive approach towards personalized healthcare. Our team will reach out to you shortly to discuss how we can best cater to your needs and ensure the well-being of your loved ones. Stay tuned for a healthier journey ahead! </div>
            <div className=" mt-8">
              <a href={APP_ROUTES.DASHBOARD} className='text-white hover:text-white'><button className='thankubtn'>Visit Our Website</button></a>
            </div>

          </div>
        </div>
      </div>

      {/* <div className='bg-[#e8faff]  relative overflow-hidden flex h-screen'>
        <div className="flex items-center " >
          <div className='text-[#0b4881] p-10  text-center'>
            <div className=' font-bold text-5xl leading-[5rem] ' data-aos="fade-left" data-aos-duration="3000" >Thank you for choosing CureBay Medicare ! </div>
            <div className=' font-bold text-2xl mt-11 leading-[3rem]' data-aos="fade-right" data-aos-duration="3000">Your proactive step towards personalized healthcare is appreciated. Our team will reach out to you shortly to discuss how we can best cater to your needs and ensure the well-being of your loved ones. Stay tuned for a healthier journey ahead! </div>
          </div>
        </div>
        <img data-aos="fade-left" src={thankyou}></img>

      </div> */}

    </>

  )
}

export default Thankyou