import services from "./index";
import httpcommon from "./httpcommon"

class HomePage {
    getHomePageImage() {
        return services.get("homepage-sliders");
    };
    getHomePageContent() {
        return services.get("/homepage-news?populate=deep");
    };
    getDoctorsPageContent() {
        return services.get("/doctors-pages?populate=deep");
    };
    getHomePageIntegrative() {
        return services.get("homepage-integrative-approaches")
    };
    getOurAim() {
        return services.get("homepage-our-aims")
    };
    getOurAimDetails() {
        return services.get("homepage-our-aim-details")
    };
    getFAQHomePage() {
        return services.get("/faq-homepages")
    }
    getHealthCareSolutions() {
        return httpcommon.post("/careplans/getHealthCareSolutions");
    };

    getHomepageHospitals() {
        return httpcommon.get("https://patient.curebay.com/es/api/v1/hospital/search?pageNo=1&pageSize=20&freeTextSearch=&source=b2c&latitude=28.6130176&longitude=77.2308992");
    };


    getDoctorslistByCoOrd(coords, freeTextSearch, pageNo, pageSize) {
        //     return services.get(`${process.env.REACT_APP_ELASTIC_BASEURL}doctors?freeTextSearch=${freeTextSearch}&pageNo=${pageNo}&pageSize=${pageSize}&latitude=${coords?.latitude}&longitude=${coords?.longitude}`);

        return services.get(`${process.env.REACT_APP_ELASTIC_BASEURL}doctors?freeTextSearch=${freeTextSearch}&pageNo=${pageNo}&pageSize=${pageSize}&latitude=${coords?.latitude}&longitude=${coords?.longitude}`);
    }

    getLabslistByCoOrd(coords) {
        return services.get(`${process.env.REACT_APP_ELASTIC_BASEURL}labs?latitude=${coords?.latitude}&longitude=${coords?.longitude}`);
    };

    getCarePlanContent() {
        return services.get("health-packages?populate=deep");
    };
    getHealthTip() {
        return services.get("diet-health-exercises?populate=deep")
    }
    getcarePlan(data) {
        return httpcommon.post("dashboard/getcarePlans", data);
    };
    getCureBayExpContent() {
        return services.get("/curebay-exps?populate=deep");
    };
    getCureBayStatistics() {
        return httpcommon.post("/curebayCount/getAll");
    };
}

export default new HomePage();