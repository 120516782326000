import React, { useEffect } from "react";
import { useState } from "react";
import { getWhyCurebay } from "../../store/actions/footerlinks";
import Footer from "../homepage/footer";
import Why from "../homepage/image/why.png";
import leftarrow from "../homepage/image/leftarrow.png";
import rightarrow from "../homepage/image/rightarrow.png";
import MobileFooter from "./../homepage/mobileFooter";
import FooterMobileVersion from "../homepage/FooterMobileVersion";
import { classNames } from "primereact/utils";

const Whycurebay = () => {
  const [screen, setscreen] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newWidth = window.innerWidth;
      setscreen(newWidth);
    };
    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const res = await getWhyCurebay();
      console.log(res);
      setData(res?.data?.data[0]);
    };

    fetchData();
  }, []);

  console.log(data);
  return (
    <>
      <div className=" select-none xs-overflow-x-hidden  md:absolute md:top-10 w-full md:w-[87.5rem]">
        <div className="sm:pt-1" style={{ backgroundColor: "#f1f3f5" }}>
          <div
            className="mainDiv  h-28 sm:h-auto"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              backgroundImage: `url("${data?.attributes?.FirstComp?.image_url}")`,
              backgroundSize: "cover",
            }}
          >
            <div
              className="sm:pl-14 pl-0"
              id="subDiv_01"
              style={{
                display: "flex",
                justifyContent: "left",
                alignItems: "center",
                // paddingLeft: '60px',
                height: "100%",
              }}
            >
              <div className="sm:mt-36 mt-96  bg-transparent px-3">
                <h1 className="text-[22px] sm:text-40px font-semibold sm:leading-54px  sm:w-4/12 text-newblue font-poppins">
                  {data?.attributes?.FirstComp?.title?.substring(0, 19)}
                </h1>
                <h2 className="font-light  text-[20px] sm:text-40px sm:leading-54px font-poppins text-newblue sm:w-[52%] sm:pb-7  pb-4">
                  {" "}
                  {data?.attributes?.FirstComp?.title?.substring(
                    19,
                    data?.attributes?.FirstComp?.title?.length
                  )}
                </h2>
                <p className="font-normal text-[16px]  sm:w-5/12 sm:leading-[30.5px] font-poppins mb-10">
                  {data?.attributes?.FirstComp?.description}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="max-h-max max-w-full justify-center sm:mt-2 mt-52 sm:p-10 mx-4 sm:my-8 sm:py-8 pt-8">
          {/* <div className='flex flex-col px-8 '> */}
          <h2 className="sm:text-[40px] text-xl font-poppins font-[300] sm:h-12">
            {" "}
            {data?.attributes?.scndcomp?.heading?.substring(0, 33)}
          </h2>
          <div className="md:flex w-full">
            <h3 className="sm:text-[53px] text-2xl leading-3rem font-medium text-newgreen w-[80rem]">
              {data?.attributes?.scndcomp?.heading?.substring(
                33,
                data?.attributes?.scndcomp?.heading?.length
              )}
            </h3>
            <div className="md:w-4/5 md:flex flex-col justify-center align-middle">
              <p className="border-b-2 w-full border-newgreen"></p>
              <p></p>
            </div>
          </div>
          <div className="md:pt-6 pt-4">
            <p className="font-poppins font-normal md:text-[20px] text-16px leading[30.5px]">
              {data?.attributes?.scndcomp?.sub_heading}
            </p>
          </div>

          <div className="my-10 flex flex-col sm:flex-row relative sm:px-14 sm:mx-10 gap-10">
            <img
              className="xs-w-full"
              src={data?.attributes?.scndcomp?.image_url}
              alt="img"
            />
            <div className="flex flex-col items-center justify-center divide-y-2   ">
              <div className="flex items-start gap-6 pb-8">
                <img
                  src={data?.attributes?.scndcomp?.content[0]?.icon_web}
                  className=" h-6 sm:h-10 w-6 sm:w-10"
                  alt={data?.attributes?.scndcomp?.content[0]?.id}
                />
                <div className="flex flex-col gap-3">
                  <h2 className="text-[#0B4881] text-sm sm:text-[22px] font-poppins font-semibold">
                    {data?.attributes?.scndcomp?.content[0]?.title}
                  </h2>
                  <h3 className=" text-xs sm:text-[16px] font-poppins font-medium leading-5 sm:leading-8">
                    {/* {data?.attributes?.scndcomp?.content[0]?.description?.substring(0, 13)} <span className="text-[19px] text-[#40B882]"> {data?.attributes?.scndcomp?.content[0]?.description?.substring(13, 19)}</span>{data?.attributes?.scndcomp?.content[0]?.description?.substring(19, data?.attributes?.scndcomp?.content[0]?.description?.length - 6)} <span className="text-[19px] text-[#40B882]">{data?.attributes?.scndcomp?.content[0]?.description?.substring(data?.attributes?.scndcomp?.content[0]?.description?.length - 6)}</span> */}
                    The World Health Organization (WHO) recommends 1 doctor per{" "}
                    <span className="text-sm sm:text-[19px] text-[#40B882]">
                      1000
                    </span>{" "}
                    people, but in India, we observe 1 doctor per{" "}
                    <span className="text-sm sm:text-[19px] text-[#40B882]">
                      12,000{" "}
                    </span>
                    people.
                  </h3>
                </div>
              </div>
              <div className="flex items-start gap-6 pt-8 ">
                <img
                  src={data?.attributes?.scndcomp?.content[1]?.icon_web}
                  className=" h-6 sm:h-10 w-6 sm:w-10"
                  alt={data?.attributes?.scndcomp?.content[1]?.id}
                />
                <div className="flex flex-col gap-3">
                  <h2 className="text-[#0B4881] text-sm sm:text-[22px] font-poppins font-semibold leading-4 sm:leading-6">
                    {data?.attributes?.scndcomp?.content[1]?.title}
                  </h2>
                  <h3 className=" text-xs sm:text-[16px] font-poppins font-medium leading-5 sm:leading-8">
                    {/* <span className="text-[19px] text-[#40B882]">{data?.attributes?.scndcomp?.content[1]?.description?.substring(0, 3)}</span> {data?.attributes?.scndcomp?.content[1]?.description?.substring(3, 31)}
                                        <span className="text-[19px] text-[#40B882]">{data?.attributes?.scndcomp?.content[1]?.description?.substring(31, 34)}</span>
                                        {data?.attributes?.scndcomp?.content[1]?.description?.substring(34, 84)}
                                        <span className="text-[19px] text-[#40B882]">{data?.attributes?.scndcomp?.content[1]?.description?.substring(84, 87)}</span>
                                        {data?.attributes?.scndcomp?.content[1]?.description?.substring(87)} */}
                    In urban areas,
                    <span className="text-sm sm:text-[19px] text-[#40B882]">
                      {" "}
                      75%
                    </span>{" "}
                    of doctors are located. Among the top 8 metros,{" "}
                    <span className="text-sm sm:text-[19px] text-[#40B882]">
                      74%
                    </span>{" "}
                    of multi-specialty hospitals can be found. Additionally,{" "}
                    <span className="text-sm sm:text-[19px] text-[#40B882]">
                      60%
                    </span>{" "}
                    of available beds in private hospitals are present in the
                    top cities.
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:py-10 sm:px-16 px-8 bg-[#0272da17]">
          <p className="text-center font-poppins font-normal sm:text-[46px] text-[20px] ">
            {data?.attributes?.thirdcomp?.heading?.substring(0, 3)}{" "}
            <span className="font-poppins font-normal sm:text-[46px] text-[20px] text-[#40B882]">
              {data?.attributes?.thirdcomp?.heading?.substring(3)}
            </span>
          </p>
          <div className="sm:pt-20">
            <div className="flex flex-col sm:flex-row justify-between">
              <div className="w-full flex justify-center items-center sm:my-10 my-5">
                <img
                  src={data?.attributes?.thirdcomp?.content[0]?.icon_web}
                  className="h-[225px] sm:h-auto"
                />
                <img
                  src={leftarrow}
                  className="h-3 ml-[120px] hidden sm:flex"
                />
              </div>

              <div className="flex flex-col w-20 hidden sm:block">
                {/* <div className="flex flex-col hidden sm:block"> */}
                <img src={Why} />

                <div className="flex h-full">
                  <p className="border-r-2 w-full"></p>
                  <p className="w-full"></p>
                </div>
              </div>
              <div className="w-full flex flex-col justify-center items-center sm:my-10">
                <h2 className="sm:text-[36px] text-[20px] font-poppins font-normal text-start w-full mb-3 sm:mb-0">
                  {data?.attributes?.thirdcomp?.content[0]?.title}
                </h2>
                <h3 className="text-[16px] font-poppins font-normal text-justify mt-5">
                  {data?.attributes?.thirdcomp?.content[0]?.description}
                </h3>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between">
              <div className="w-full flex justify-center items-center my-5 block sm:hidden">
                <img
                  src={data?.attributes?.thirdcomp?.content[1]?.icon_web}
                  className="h-[225px] sm:h-auto"
                />
              </div>
              <div className="w-full flex flex-col justify-center items-center sm:my-10 my-5">
                <h2 className="sm:text-[36px] text-[20px] font-poppins font-normal text-start w-full sm:mb-0 mb-3">
                  {data?.attributes?.thirdcomp?.content[1]?.title}
                </h2>
                <h3 className="text-[16px] font-poppins font-normal text-justify mt-5">
                  {data?.attributes?.thirdcomp?.content[1]?.description}
                </h3>
              </div>

              <div className="flex flex-col w-20   hidden sm:block">
                <div className="flex h-[16rem]">
                  <p className="border-r-2 w-full"></p>
                  <p className="w-full"></p>
                </div>
                <img src={Why} />

                <div className="flex h-full">
                  <p className="border-r-2 w-full"></p>
                  <p className="w-full"></p>
                </div>
              </div>

              <div className="w-full flex justify-center items-center my-10 hidden sm:flex">
                <img
                  src={rightarrow}
                  className="h-3 mr-[120px] hidden sm:flex"
                />
                <img
                  src={data?.attributes?.thirdcomp?.content[1]?.icon_web}
                  className="h-[225px] sm:h-auto"
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between">
              <div className="w-full flex justify-center items-center sm:my-10 my-5">
                <img
                  src={data?.attributes?.thirdcomp?.content[2]?.icon_web}
                  className="h-[225px] sm:h-auto"
                />
                <img
                  src={leftarrow}
                  className="h-3 ml-[120px] hidden sm:flex"
                />
              </div>
              {/* <img src={leftarrow} className="h-3 ml-44"/> */}

              <div className="flex flex-col w-20 hidden sm:block">
                <div className="flex h-full">
                  <p className="border-r-2 w-full"></p>
                  <p className="w-full"></p>
                </div>
                <img src={Why} />
                <div className="flex h-[2rem]">
                  <p className="border-r-2 w-full"></p>
                  <p className="w-full"></p>
                </div>
              </div>
              <div className="w-full flex flex-col justify-center items-center sm:my-10 my-5">
                <h2 className="sm:text-[36px] text-[20px] font-poppins font-normal text-start w-full sm:mb-0 mb-3">
                  {data?.attributes?.thirdcomp?.content[2]?.title}
                </h2>
                <h3 className="text-[16px] font-poppins font-normal text-justify mt-5">
                  {data?.attributes?.thirdcomp?.content[2]?.description}
                </h3>
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between ">
              <div className="w-full flex justify-center items-center block sm:hidden my-5">
                <img
                  src={data?.attributes?.thirdcomp?.content[3]?.icon_web}
                  className="h-[225px] sm:h-auto"
                />
              </div>
              <div className="w-full flex flex-col justify-center items-center sm:my-10 my-5">
                <h2 className="sm:text-[36px] text-[20px] font-poppins font-normal text-start w-full sm:mb-0 mb-3">
                  {data?.attributes?.thirdcomp?.content[3]?.title}
                </h2>
                <h3 className="text-[16px] font-poppins font-normal text-justify mt-5">
                  {data?.attributes?.thirdcomp?.content[3]?.description}
                </h3>
              </div>
              <div className="flex flex-col w-20  hidden sm:block">
                {/* <img src={Why} /> */}
                <div className="flex h-full">
                  <p className="border-r-2 w-full"></p>
                  <p className="w-full"></p>
                </div>
              </div>

              <div className="w-full flex justify-center items-center my-10 hidden sm:flex">
                <img
                  src={rightarrow}
                  className="h-3 mr-[120px] hidden sm:flex"
                />
                <img
                  src={data?.attributes?.thirdcomp?.content[3]?.icon_web}
                  className="h-[225px] sm:h-auto"
                />
              </div>
            </div>
            <div className="flex flex-col sm:flex-row justify-between">
              <div className="w-full flex justify-center items-center sm:my-10 my-5">
                <img
                  src={data?.attributes?.thirdcomp?.content[4]?.icon_web}
                  className="h-[225px] sm:h-auto"
                />
                <img
                  src={leftarrow}
                  className="h-3 ml-[120px] hidden sm:flex"
                />
              </div>

              <div className="flex flex-col w-20 hidden sm:block">
                <div className="flex h-full">
                  <p className="border-r-2 w-full"></p>
                  <p className="w-full"></p>
                </div>
                <img src={Why} />
              </div>
              <div className="w-full flex flex-col justify-center items-center sm:my-10 my-5">
                <h2 className="sm:text-[36px] text-[20px] font-poppins font-normal text-start w-full sm:mb-0 mb-3">
                  {data?.attributes?.thirdcomp?.content[4]?.title}
                </h2>
                <h3 className="text-[16px] font-poppins font-normal text-justify mt-5">
                  {data?.attributes?.thirdcomp?.content[4]?.description}
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="sm:my-20 my-5  flex-col flex justify-center items-center">
          <div>
            <p className="text-center font-poppins font-normal sm:text-[46px] text-[20px] ">
              {data?.attributes?.fourthcomp?.heading?.substring(0, 8)}{" "}
              <span className="font-poppins font-normal sm:text-[46px] text-[20px] text-[#40B882]">
                {data?.attributes?.fourthcomp?.heading?.substring(8)}
              </span>
            </p>
            <p className="font-normal text-16px  sm:leading-[30.5px] font-poppins sm:pb-16 pb-4 sm:pt-0 pt-2 text-center mt-4 sm:mt-10 ">
              {data?.attributes?.fourthcomp?.sub_heading}
            </p>
          </div>
          {/* <video  className="w-10/12 mt-4 bg-black" controls>
              <source  src={data?.attributes?.fourthcomp?.vid_url} type="video/mp4"></source>
            </video> */}

          <video className="w-10/12 mt-4 bg-black" controls>
            <source
              type="video/mp4"
              src="https://storage.googleapis.com/curebay_cms/medicare/Why%20Curbay/CureBayFinalVideo.cdd0b6b1.mp4"
            />
          </video>
        </div>

        {/* <div
                className=""
            >

                <div className="">



                    <div className=" " >
                        <img src={data?.attributes?.FirstComp?.image_url1} alt="#" className="h-full w-full" />
                        <img src={data?.attributes?.FirstComp?.image_url2} alt="#" className="h-full w-full" />
                        <img src={data?.attributes?.FirstComp?.image_url3} alt="#" className="h-full w-full" />
                    </div>

                </div>

                <div className=" sm:mt-10">
                    <div className="flex flex-col items-center justify-items-center">
                        <h1 className="mt-5 text-medium text-black font-bold text-2xl ">
                            {data?.attributes?.scndcomp?.name}
                        </h1>
                    </div>

                    <div className=" sm:flex  flex-row  sm:m-16  sm:mt-6  mt-2 justify-around "> */}
        {/* <div className="  rounded overflow-hidden shadow-lg sm:w-5/12">
              <img src={HowYouHelp} alt="#" />

              <div className=" text-black text-md text-center  m-6">
                We are introducing a hybrid healthcare and fulfilment model and
                executing it through a robust 3-tier model of Hub, Spoke and a
                network of satellite health centres.
              </div>
            </div> */}

        {/* <div className=" rounded overflow-hidden shadow-lg sm:w-5/12">
              <img src={HowYouHelp1} alt="#" />

              <div className=" text-black text-md text-center  m-6">
                We looked at the problem, talked to people who have deep
                understanding of the challenges and pledged to solve the problem
                using technology, people and innovative processes.
              </div>
            </div> */}

        {/* {data?.attributes?.scndcomp?.content?.map(({ description, image_url }, i) => (
                            <div key={i} className="rounded overflow-hidden shadow-lg sm:w-5/12">
                                <img src={image_url} alt="#" />
                                <div className=" text-black text-md text-center m-6" >
                                    {description}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div
                    style={{ background: "rgba(225, 243, 247, 0.22)" }}
                    className=" flex justify-center mt-6   "
                >
                    <div className="  mb-5 w-full justify-center ">
                        <div className="flex flex-col items-center justify-items-center mt-4">
                            <h1 className="text-medium text-black font-bold text-2xl ">
                                {data?.attributes?.thirdcomp?.heading}
                            </h1>
                        </div> */}
        {/* <p className="px-8 md:px-36 pb-6 mt-4 text-black text-center "> */}
        {/* Healthcare is a huge challenge in India. We have a large
              population spread over large and diverse geography. There is a
              considerable shortage of healthcare providers (1 doctor per 12,000
              people in public healthcare against a WHO recommended ratio of 1
              doctor per 1,000 and for nurses it is 1:600 against 1:300
              recommended). Even more worrying is the fact that with 75% of
              doctors in urban areas, 74% of multi-speciality hospitals in top 8
              metros and 60% of available beds in private hospitals in top
              cities, availability of healthcare is skewed towards urban
              population – just 28% of Indian population. This leaves the
              remaining 72% of Indian semi-urban and rural population with very
              few reliable sources of healthcare. */}

        {/* <p className="px-8 md:px-36 pb-6 mt-4 text-black text-center ">
                            {data?.attributes?.thirdcomp?.description}
                        </p> */}

        {/* <p className="px-8 md:px-36 pb-6   text-black text-center">
              We at CureBay are on a mission to bridge this gap in the Indian
              healthcare system. And we are going to achieve our mission by
              bridging the gap that exists in the primary healthcare to the
              under served markets.
            </p> */}
        {/* <p
              className="px-8 md:px-36 pb-6   text-black text-center"
              style={{ fontFamily: "open sans" }}
            >
              Our vision is to make quality healthcare accessible to each and
              every Indian citizen, whoever they are and wherever they are.
            </p> */}
        {/* </div>
                </div>

                <div className=" flex w-full flex-col pb-5 sm:mt-14 mt-4 mb-8 justify-center items-center">
                    <div className="flex flex-col items-center justify-items-center">
                        <h1 className=" text-medium text-black font-bold text-2xl ">
                            {data?.attributes?.fourthcomp?.heading}
                        </h1>
                    </div> */}
        {/* <video className="w-10/12 mt-4 bg-black" controls>
            <source src={CureBay} type="video/mp4"></source>
          </video> */}

        {/* </div>
            </div> */}
        {screen > 500 ? (
          <Footer />
        ) : (
          <>
            <FooterMobileVersion />
            <MobileFooter />
          </>
        )}
      </div>
    </>
  );
};

export default Whycurebay;
