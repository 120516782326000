import myprofilereview from "../../services/myprofilereview";

export const fetchreview = async () => {
  const res = await myprofilereview.getreview();
  return res;
};

export const savereview = async (payload) => {
  const res = await myprofilereview.save(JSON.stringify(payload));
  return res;
};

export const fetchUserReview = async (payload) => {
  const res = await myprofilereview.getuser(JSON.stringify(payload));
  return res;
}
export const updateReview = async (payload) => {
  const res = await myprofilereview.update(JSON.stringify(payload));
  return res;
}