import services from "../../store/services/index";
import httpcommon from "../../store/services/httpcommon";
import axios from "axios";
class mydashboardservice {
  getappointment(payload) {
    return httpcommon.post("appointment/getAll", payload);
  }

  updatePatientapi(data) {
    // var queryString = Object.keys(data).map(key => key + '=' + data[key]).join('&');
    return httpcommon.post("patient/update", data);
  }

  getmypPrescriptionlist(data) {
    return httpcommon.post("prescription/getAll", data);
  }

  getmylablist(data) {
    return httpcommon.post("labReport/getAll", data);
  }

  getSubscription(data) {
    return httpcommon.post("subscriptions/history", data);
  }

  getUserList(data) {
    return httpcommon.post("dropdown/userlist", data);
  }
  getMedicineSchedule(data) {
    return httpcommon.post("dashboard/getMedicineSchedule", data);
  }
  getDashboardData() {
    return services.get("/diet-health-exercises?populate=deep");
  }
  getCarePlanList(data) {
    return httpcommon.post("/dropdown/carePlan", data);
  }
  getSchedule(data) {
    return httpcommon.post("/schedule/getAll", data);
  }
  updateEffectiveStartDate(data) {
    return httpcommon.post("/subscriptions/update", data);
  }
  userConsent(data) {
    return httpcommon.post("/userConsent/list", data);
  }
}
export default new mydashboardservice();
