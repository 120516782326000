import React from 'react'
import { useState, useEffect } from 'react';
import Dashboard from '../../../src/images/dashboard.svg';
import HealthRecords from '../../../src/images/Healthrecord.svg';
import MyAppointments from '../../../src/images/My Appointment.svg';
import MySchedule from '../../../src/images/mySchedule.svg';
import { Link } from 'react-router-dom';


function MobileFooter() {
  const loginObjStr = localStorage.getItem('loginObj');
  const loginObj = JSON.parse(loginObjStr);
  const userObj = loginObj?.[0];
  const userCode = userObj?.userCode ? userObj.userCode : userObj?.parentCode ? userObj.parentCode : null;

  console.log(userCode, "mnbvcxz")

  const bottomList = [
    {
      text: 'Subscription Service',
      type: 'My Dashboard',
      img: HealthRecords,
      nevLink: "/subscriptionservices",
    },
    {
      text: 'Health Record',
      type: 'Health Record',
      img: Dashboard,
      // nevLink: "/subscriptionservices",
      nevLink: "/myhealthrecords"
    },
    {
      text: 'Appointments',
      type: 'Medicines',
      img: MyAppointments,
      nevLink: "/appointments",
    },
    {
      text: 'Schedule',
      type: 'Schedule',
      img: MySchedule,
      nevLink: "/myschedule",
    },

  ];

  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY || window.pageYOffset;
      setIsVisible(scrollPosition > 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {userCode &&
        <footer className={`fixed bottom-0 left-0 w-full bg-white`} style={{ zIndex: "2" }}>
          <div id="tabs" className="flex mb-2 border-shuttle-gray-100" style={{ padding: "8px 11px 0px 0px" }}>
            {bottomList.map((data, i) => (
              <div key={i} className="icon-item p-2">
                <Link to={data.nevLink}>
                  <img src={data.img} className="h-5 w-5 mx-auto mb-1 flex justify-center" alt={data.text} />
                  <p className="icon-text text-xs text-green-400 font-bold text-center">{data.text}</p>
                </Link>
              </div>
            ))}
          </div>
        </footer>
      }
    </>
  );
}

export default MobileFooter
